import { TypeColumn, TypeSingleFilterValue } from "@inovua/reactdatagrid-community/types";
import { ReactElement, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { PaymentTransactionsApi } from "../../../common/api/payThemApi/paymentTransactionsApi";
import { LoadDataProps, loadPageData } from "../../../components/grid/dataGridHelpers";
import { PaymentTransaction } from "../../../models/paymentTransactions/paymentTransaction";
import {
  PaymentTransactionIdColumn,
  PaymentTransactionTypeColumn,
  PaymentTransactionStatusDateTimeColumn,
  CustomerDetailsCompanyNameColumn,
  PaymentTransactionTotalAmountColumn,
  PaymentTransactionStatusColumn,
  OptionsColumn,
} from "../../PaymentTransactions/Grid/GridColumns";
import PaymentTransactionsGrid, {
  PaymentTransactionsSortColumnName,
  PaymentTransactionsSortDirection,
} from "../../PaymentTransactions/Grid/PaymentTransactionsGrid";
import { Payer } from "../../../models/payers/payer";

/*
 * Default standard column list
 */
const Columns: TypeColumn[] = [
  PaymentTransactionIdColumn,
  PaymentTransactionTypeColumn,
  PaymentTransactionStatusDateTimeColumn,
  CustomerDetailsCompanyNameColumn,
  PaymentTransactionTotalAmountColumn,
  PaymentTransactionStatusColumn,
  OptionsColumn,
];

interface PayerPaymentTransactionsTabProps {
  payer: Payer;
}

/*
 * Tab showing payment transactions associated to the payer
 */
const PayerPaymentTransactionsTab = ({ payer }: PayerPaymentTransactionsTabProps): ReactElement => {
  // Use our own loadData source which filters by account Id
  const loadData = async ({
    skip,
    limit,
    sortInfo,
    filterValue,
  }: LoadDataProps): Promise<{ data: PaymentTransaction[]; count: number }> => {
    const accountFilter = { name: "payerId", operator: "eq", type: "string", value: payer.id } as TypeSingleFilterValue;
    const extendedFilters = filterValue ? [...filterValue, accountFilter] : [accountFilter];

    return loadPageData(
      skip,
      limit,
      sortInfo,
      PaymentTransactionsSortColumnName,
      PaymentTransactionsSortDirection,
      extendedFilters,
      async (pageParameters) => {
        const api = new PaymentTransactionsApi();
        const result = await api.getPaymentTransactions(
          pageParameters.page,
          pageParameters.itemsPerPage,
          pageParameters.sort,
          pageParameters.filter
        );
        return result;
      }
    );
  };

  const dataSource = useCallback(loadData, [payer]);

  return (
    <Row>
      <Col className='panel-column'>
        <PaymentTransactionsGrid columns={Columns} dataSource={dataSource} />
      </Col>
    </Row>
  );
};

export default PayerPaymentTransactionsTab;
