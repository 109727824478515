import {
  PaymentProviderCredentials,
  PaymentProviderSettings,
  AddPaymentProviderTypeConfiguration,
  PaymentProviderTypeConfiguration,
  initAddPaymentProviderTypeConfiguration,
} from "../paymentProviderConfiguration";
import PaymentProviderModelConsts from "../paymentProviderModelConsts";

export const GoCardlessProviderName = "GoCardless";
export const GoCardlessProviderDisplayName = "GoCardless";

export interface GoCardlessSettings extends PaymentProviderSettings {}

export interface GoCardlessCredentials extends PaymentProviderCredentials {
  // The users organisation Id
  organisationId?: string;
}

export class GoCardlessProviderModelConsts extends PaymentProviderModelConsts {}

export type GoCardlessPaymentProviderConfiguration = PaymentProviderTypeConfiguration<
  GoCardlessSettings,
  GoCardlessCredentials
>;
export type AddGoCardlessPaymentProviderConfiguration = AddPaymentProviderTypeConfiguration<
  GoCardlessSettings,
  GoCardlessCredentials
>;

/*
 * Constructor for AddGoCardlessPaymentProviderConfiguration
 */
export const initAddGoCardlessPaymentProviderConfiguration = (name: string): AddGoCardlessPaymentProviderConfiguration => {
  const data = {
    ...initAddPaymentProviderTypeConfiguration(GoCardlessProviderName, name),
    settings: { providerName: GoCardlessProviderName } as GoCardlessSettings,
    credentials: { providerName: GoCardlessProviderName } as GoCardlessCredentials,
  };
  return data;
};
