import { FormEvent, ReactElement, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import AllowLatePaymentsAlert from "../../../components/tenantSettingAlerts/AllowLatePaymentsAlert";
import { tPages } from "../../../i18n";
import { getAllLatePaymentConfigurations } from "../../../services/latePayments/latePayments.service";
import { AccountSettings } from "../../../models/accounts/accountSettings";

const NoLatePaymentOption = "noLatePaymentSelection";
const UseDefaultLatePaymentOption = "useDefaultLatePaymentSelection";

const keyPrefix = "accountPage.settings";

interface LatePaymentSelectionProps {
  accountSettings: AccountSettings;
  saving: boolean;
  onUpdate: (latePaymentId: string | undefined, disableLatePayment: boolean) => Promise<void>;
}

/*
 * The late payment configuration selection dropdown
 */
const LatePaymentSelection = ({ accountSettings, saving, onUpdate }: LatePaymentSelectionProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  // Work out state of dropdown
  let selectedState = UseDefaultLatePaymentOption;
  if (accountSettings.disableLatePayments === true) {
    selectedState = NoLatePaymentOption;
  } else if (accountSettings.latePaymentConfigurationGroupId) {
    selectedState = accountSettings.latePaymentConfigurationGroupId;
  }

  const [selectedLatePaymentGroup, setSelectedLatePaymentGroup] = useState(selectedState);

  const { data: latePaymentGroups, isLoading } = useQuery([QueryNameConsts.LatePaymentConfigurationsList], () =>
    getAllLatePaymentConfigurations()
  );

  // Saves the new settlement configuration group
  const handleChange = async (e: FormEvent<HTMLSelectElement>) => {
    const selectionValue = e.currentTarget.value;

    let newId: string | undefined;
    let disableLatePayment = false;

    if (selectionValue === NoLatePaymentOption) {
      newId = undefined;
      disableLatePayment = true;
    } else if (selectionValue === UseDefaultLatePaymentOption) {
      newId = undefined;
    } else {
      newId = selectionValue;
    }
    await onUpdate(newId, disableLatePayment);
    setSelectedLatePaymentGroup(selectionValue);
  };

  // Build the options from the available configurations
  const LatePaymentConfigurationOptions = latePaymentGroups ? (
    latePaymentGroups.map((configuration) => (
      <option key={configuration.id} value={configuration.id}>
        {configuration.name}
      </option>
    ))
  ) : (
    <></>
  );
  return (
    <Form.Group as={Col} md='6'>
      <Form.Label>{t("latePaymentSelection")}</Form.Label>
      <Form.Select disabled={saving || isLoading} size='sm' value={selectedLatePaymentGroup} onChange={(e) => handleChange(e)}>
        <option key={NoLatePaymentOption} value={NoLatePaymentOption}>
          {t(NoLatePaymentOption)}
        </option>
        <option key={UseDefaultLatePaymentOption} value={UseDefaultLatePaymentOption}>
          {t(UseDefaultLatePaymentOption)}
        </option>
        {LatePaymentConfigurationOptions}
      </Form.Select>
      <div>{t("descriptionLatePaymentSelection")}</div>
      <AllowLatePaymentsAlert />
    </Form.Group>
  );
};

export default LatePaymentSelection;
