/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Card, Form } from "react-bootstrap";
import { FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister, UseFormWatch } from "react-hook-form";
import { useFeature } from "flagged";
import { tPaymentProviders } from "../../../../i18n";
import {
  CommonProviderFormControls,
  ProviderFilterFormControl,
  ProviderSupportedMethodsFormControl,
  hasSupportedCurrencies,
  ProviderSupportedCurrenciesFormControl,
} from "../ProvidersCommon/CommonControls";
import { FillerDisplayPassword, isFillerDisplayPassword } from "../../../../utils/secureStringUtils";
import { testOpayoConnection } from "../../../../services/opayo/opayo.service";
import { AddOrEditMode } from "../ProvidersCommon/Common";
import { CurrentFormModel, CurrentPaymentProviderConfiguration } from "./opayoConfiguration";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import BooleanFormSelect from "../ProvidersCommon/BooleanFormSelect";
import { OpayoIntegrationType } from "../../../../models/paymentProviders/opayo/opayoModels";
import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";
import FeatureFlagConsts from "../../../../FeatureFlagConsts";

const keyPrefix = "opayoForm";

interface OpayoGeneralSettingsTabProps {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  itemFilterGroup: ItemFilterGroup | undefined;
  onShowFilterDialog: () => void;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

/*
 * General settings tab for Opayo
 */
const OpayoGeneralSettingsTab = ({
  mode,
  data,
  paymentProvider,
  itemFilterGroup,
  onShowFilterDialog,
  handleSubmit,
  register,
  watch,
  errors,
}: OpayoGeneralSettingsTabProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  const opayoPiEnabled = useFeature(FeatureFlagConsts.OpayoPi);

  let defaultIntegrationType = data?.settings.integrationType;
  if (defaultIntegrationType === undefined) {
    defaultIntegrationType = opayoPiEnabled ? OpayoIntegrationType.Pi : OpayoIntegrationType.Server;
  }

  const [isUsingPi, setIsUsingPi] = useState(defaultIntegrationType === OpayoIntegrationType.Pi);

  const addMode = mode === "add";

  const watchIntegrationType = watch("integrationType", defaultIntegrationType);
  const smallColumnSize = isUsingPi ? "12" : "6";

  // Called when the integration type changes
  useEffect(() => {
    setIsUsingPi(watchIntegrationType === OpayoIntegrationType.Pi);
  }, [watchIntegrationType]);

  // Tests the connection for Opayo
  const handleTestConnection = async () => {
    handleSubmit(async (formData) => {
      const model = formData as CurrentFormModel;

      if (!addMode && isFillerDisplayPassword(model.adminPassword)) {
        await testOpayoConnection(model.vendorName, model.adminUser, undefined, model.isTest, data?.id);
      } else {
        await testOpayoConnection(model.vendorName, model.adminUser, model.adminPassword, model.isTest);
      }
    })();
  };

  const defaultPassword = addMode ? undefined : FillerDisplayPassword;

  // TODO: Need to update all providers to move the useTestServer setting to the settings isTest property
  let isTest = data?.isTest ?? true;
  if (data?.settings?.useTestServer !== undefined) {
    isTest = data?.settings?.useTestServer;
  }

  // For now Opayo only supports one payment method type
  const paymentMethodTypeInfo = paymentProvider?.paymentMethodTypesInfo.find(
    (x) => x.paymentMethodType === PaymentMethodType.Card
  );

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <CommonProviderFormControls data={data} register={register} errors={errors} />
            <hr />
            <Row className='mb-2'>
              <Col>
                <ProviderFilterFormControl filterGroup={itemFilterGroup} onClick={onShowFilterDialog} />
              </Col>
              <ProviderSupportedMethodsFormControl
                supportedPaymentMethodGroups={paymentProvider?.supportedPaymentMethodGroups}
              />
              {hasSupportedCurrencies(paymentMethodTypeInfo) && (
                <>
                  <Form.Group as={Col} sm='6'>
                    {" "}
                  </Form.Group>
                  <ProviderSupportedCurrenciesFormControl supportedCurrencies={paymentMethodTypeInfo!.supportedCurrencies} />
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Card.Title>{t("subTitleCredentials")}</Card.Title>
            <Row>
              <Form.Group as={Col} sm='2'>
                <BooleanFormSelect
                  label={t("labelServer")}
                  register={register}
                  fieldName='isTest'
                  trueOption={t("optionTestServer")}
                  falseOption={t("optionLiveServer")}
                  reverseOrder
                  defaultState={isTest}
                />
              </Form.Group>
              <Form.Group as={Col} sm='2'>
                <Form.Label>{t("labelIntegrationType")}</Form.Label>
                <Form.Select {...register("integrationType")} defaultValue={defaultIntegrationType}>
                  <option value={OpayoIntegrationType.Server}>{t("optionIntegrationServer")}</option>
                  {(opayoPiEnabled || isUsingPi) && <option value={OpayoIntegrationType.Pi}>{t("optionIntegrationPi")}</option>}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Form.Group as={Col} sm={smallColumnSize}>
                    <Form.Label>{t("labelOpayoAdminUser")}</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("placeholderOpayoAdminUser")}
                      {...register("adminUser")}
                      className={`form-control ${errors?.adminUser?.message ? "is-invalid" : ""}`}
                      defaultValue={data?.credentials?.adminUser}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.adminUser?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} sm={smallColumnSize}>
                    <Form.Label>{t("labelOpayoAdminPassword")}</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder={t("placeholderOpayoAdminPassword")}
                      {...register("adminPassword")}
                      className={`form-control ${errors?.adminPassword?.message ? "is-invalid" : ""}`}
                      defaultValue={defaultPassword}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.adminPassword?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} sm={smallColumnSize}>
                    <Form.Label>{t("labelOpayoVendorName")}</Form.Label>
                    <div className='d-flex gap-2'>
                      <Col>
                        <Form.Control
                          type='text'
                          placeholder={t("placeholderOpayoVendorName")}
                          {...register("vendorName")}
                          className={`m-0 form-control ${errors?.vendorName?.message ? "is-invalid" : ""}`}
                          defaultValue={data?.credentials?.vendorName}
                        />
                        <Form.Control.Feedback type='invalid'>{errors?.vendorName?.message}</Form.Control.Feedback>
                      </Col>
                      <Button variant='secondary' size='sm' onClick={handleTestConnection}>
                        {t("buttonTestConnection")}
                      </Button>
                    </div>
                  </Form.Group>
                </Row>
              </Col>
              {isUsingPi && (
                <Col>
                  <Row>
                    <Form.Group as={Col} sm='12'>
                      <Form.Label>{t("labelIntegrationKey")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderIntegrationKey")}
                        {...register("integrationKey")}
                        className={`form-control ${errors?.integrationKey?.message ? "is-invalid" : ""}`}
                        defaultValue={data?.credentials?.integrationKey}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.integrationKey?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} sm='12'>
                      <Form.Label>{t("labelIntegrationPassword")}</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder={t("placeholderIntegrationPassword")}
                        {...register("integrationPassword")}
                        className={`form-control ${errors?.integrationPassword?.message ? "is-invalid" : ""}`}
                        defaultValue={defaultPassword}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.integrationPassword?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

OpayoGeneralSettingsTab.defaultProps = { data: undefined, paymentProvider: undefined };

export default OpayoGeneralSettingsTab;
