import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { CurrencyItem } from "../../../components/misc/CurrencyItem";
import { tPages } from "../../../i18n";
import { DateOutputType } from "../../../utils/dateFormatter";
import { DefaultBlankDateText, DefaultBlankStringText } from "../../viewCommon";
import PaymentTransactionLink from "../../PaymentTransactions/PaymentTransactionLink";
import { PaymentFeeTransaction } from "../../../models/paymentsFees/paymentFeeTransaction";
import TransactionReferenceLink from "../../Transactions/TransactionReferenceLink";
import useDateFormatter from "../../../common/hooks/useDateFormatter";

const keyPrefix = "latePaymentsTab";

export type LatePaymentDisplayMode = "transaction" | "paymentTransaction";

interface LatePaymentFeesTableProps {
  latePaymentTransactions: PaymentFeeTransaction[];
  // currency: string;
  mode: LatePaymentDisplayMode;
}

const LatePaymentFeesTable = ({ latePaymentTransactions, mode }: LatePaymentFeesTableProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateFormatter = useDateFormatter();

  return (
    <>
      <Table borderless className='panel-table'>
        <thead>
          <tr>
            <td>{t("createdOn")}</td>
            <td>{t(`${mode === "paymentTransaction" ? "paymentTransactionId" : "transactionReference"}`)}</td>
            <td>{t("feeType")}</td>
            <td align='right'>{t("feeAmount")}</td>
          </tr>
        </thead>
        <tbody>
          {latePaymentTransactions
            .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
            .map((latePaymentTransaction) =>
              latePaymentTransaction.feeDetails.map((feeDetail) => (
                <tr key={latePaymentTransaction.id + feeDetail.type + feeDetail.amount}>
                  <td>
                    {dateFormatter.dateTimeOrString(
                      latePaymentTransaction.createdOn,
                      DefaultBlankDateText,
                      DateOutputType.ShortDate
                    )}
                  </td>
                  <td>
                    {mode === "transaction" ? (
                      <TransactionReferenceLink
                        transactionId={latePaymentTransaction.transactionId ?? DefaultBlankStringText}
                      />
                    ) : (
                      <PaymentTransactionLink id={latePaymentTransaction.paymentTransactionId} />
                    )}
                  </td>
                  <td>{feeDetail.type}</td>
                  <td align='right' className='font-bold'>
                    <CurrencyItem currency={feeDetail.currency} value={feeDetail.amount} />
                  </td>
                </tr>
              ))
            )}
        </tbody>
      </Table>
    </>
  );
};

export default LatePaymentFeesTable;
