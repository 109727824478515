/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useState } from "react";
import { Form, Row, Col, Placeholder, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { useHostSystems } from "../../../common/hooks/useHostSystems";
import { Dataset } from "../../../models/datasets/dataset";
import { DatasetModelConsts } from "../../../models/datasets/datasetModelConsts";
import { HostSystemType } from "../../../models/datasets/hostSystem";
import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../../components/dialogs/AddOrEditCommonDialog";
import { tDatasets, tForms } from "../../../i18n";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getAllDatasets } from "../../../services/datasets/dataset.service";
import { DatasetsApi } from "../../../common/api/payThemApi/datasetsApi";
import StandardTooltip from "../../../components/tooltips/StandardTooltip";
import { DatasetStatus, DatasetType } from "../../../models/datasets/datasetEnums";
import "./DatasetDialog.scss";

const NoCopyFromDatasetOption = "noCopyFromDatasetSelection";

const DefaultCurrency = "GBP";

export type DatasetFormModel = {
  name: string;
  shortName: string;
  description: string;
  erp: string;
  hostId: string;
  customHostId: string;
  currency: string;
  copyFromDatasetId: number | undefined;
  demoDataset: boolean;
};

export const useValidationSchema = () => {
  const { t } = useTranslation([tDatasets.ns, tForms.ns]);
  return Yup.object({
    name: Yup.string()
      .label(t("labelDatasetName"))
      .required()
      .min(DatasetModelConsts.NameMinLength)
      .max(DatasetModelConsts.NameMaxLength),
    shortName: Yup.string().label(t("labelDatasetShortName")).max(DatasetModelConsts.ShortNameMaxLength),
    description: Yup.string().label(t("labelDatasetDescription")).max(DatasetModelConsts.DescriptionMaxLength),
    hostId: Yup.string()
      .label(t("labelDatasetHostId"))
      .required()
      .min(DatasetModelConsts.HostIdMinLength)
      .max(DatasetModelConsts.HostIdMaxLength),
    customHostId: Yup.string()
      .label(t("labelDatasetCustomId"))
      .required()
      .min(DatasetModelConsts.CustomHostIdMinLength)
      .max(DatasetModelConsts.CustomHostIdMaxLength),
    copyFromDatasetId: Yup.number(),
    demoDataset: Yup.boolean(),
    currency: Yup.string().label(t("labelDatasetCurrency")),
  }).required();
};

interface DatasetDialogProps extends AddOrEditDialogProps<Dataset, DatasetFormModel> {
  onClosed: () => void;
  onResetData: (id: number) => void;
  defaultHostId?: string;
  defaultCustomHostId?: string;
}

/*
 * The dialog for adding or editing a dataset
 */
export const DatasetDialog = ({
  show,
  mode,
  data,
  onSave,
  onClose,
  onClosed,
  onResetData,
  defaultHostId,
  defaultCustomHostId,
}: DatasetDialogProps): ReactElement => {
  const hostSystems = useHostSystems().filter((x) => x.hostSystemType !== HostSystemType.Default);
  const [resetDataStatusClicked, setResetDataStatusClicked] = useState<boolean>(false);
  const [isDemoCompany, setIsDemoCompany] = useState<boolean>(false);
  const validation = useValidationSchema();

  const { data: supportedBaseCurrencies } = useQuery(QueryNameConsts.SupportedBaseCurrencies, () => {
    const api = new DatasetsApi();
    return api.getSupportedBaseCurrencies();
  });

  const { t } = useTranslation([tDatasets.ns, tForms.ns]);
  const addMode = mode === "add";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DatasetFormModel>({ resolver: yupResolver(validation) });

  const { data: datasets } = useQuery([QueryNameConsts.DatasetList], () => (addMode ? getAllDatasets() : undefined));

  const handleSave = (formModel: DatasetFormModel) => {
    const model = { ...formModel };
    model.name = model.name.trim();
    model.shortName = model.shortName.trim();
    model.description = model.description.trim();
    model.currency = model.currency?.trim();
    if (model.copyFromDatasetId === 0) {
      model.copyFromDatasetId = undefined;
    }
    onSave(model);
  };

  const handleResetDemoData = () => {
    // Want them to click twice to confirm
    if (resetDataStatusClicked === false) {
      setResetDataStatusClicked(true);
      return;
    }
    // Super paranoid check
    if (!addMode && data && data.type === DatasetType.Demo) {
      onResetData(data.id);
    }
  };

  const canReset =
    !addMode &&
    data &&
    data.type === DatasetType.Demo &&
    (data.status === DatasetStatus.Active || data.status === DatasetStatus.Inactive);

  return (
    <AddOrEditModelDialog
      mode={mode}
      title={t(addMode ? "titleAddDataset" : "titleEditDataset")}
      show={show}
      onHide={onClose}
      onExited={onClosed}
      size='lg'
      formId='datasetForm'
    >
      <Form id='datasetForm' className='dialog-form' noValidate onSubmit={handleSubmit((submitData) => handleSave(submitData))}>
        <Row className='dialog-row'>
          <Col md='6'>
            <Form.Group>
              <Form.Label>{t("labelDatasetName")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("placeholderDatasetName")}
                {...register("name")}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.name : undefined}
              />
              <Form.Control.Feedback type='invalid'>{errors?.name?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md='6'>
            <Form.Group>
              <Form.Label>
                {t("labelDatasetShortName")}
                <span className='optional'>{t("filtersForm.labelOptional", tForms)}</span>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder={t("placeholderDatasetShortName")}
                {...register("shortName")}
                className={`form-control ${errors.shortName ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.shortName : undefined}
              />
              <Form.Control.Feedback type='invalid'>{errors?.shortName?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                {t("labelDatasetDescription")}
                <span className='optional'>{t("filtersForm.labelOptional", tForms)}</span>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder={t("placeholderDatasetDescription")}
                {...register("description")}
                className={`form-control ${errors.description ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.description : undefined}
              />
              <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          {addMode && supportedBaseCurrencies && (
            <Col>
              <Form.Group>
                <Form.Label>{t("labelDatasetCurrency")}</Form.Label>
                <Form.Select {...register("currency")} defaultValue={DefaultCurrency}>
                  {supportedBaseCurrencies?.map((item) => (
                    <option key={item.iso4217} value={item.iso4217}>
                      {item.iso4217} - {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row className={`dialog-row ${!addMode && data?.type !== DatasetType.Demo && "last"}`}>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("labelDatasetErp")}</Form.Label>
              <Form.Select {...register("erp")} defaultValue={!addMode ? data?.hostSystemName : undefined}>
                {hostSystems.map((item) => (
                  <option key={item.hostSystemName} value={item.hostSystemName}>
                    {item.displayName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}> </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("labelDatasetHostId")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("placeholderDatasetHostId")}
                {...register("hostId")}
                className={`form-control ${errors.hostId ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.hostId : defaultHostId}
              />
              <Form.Control.Feedback type='invalid'>{errors?.hostId?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("labelDatasetCustomId")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("placeholderDatasetCustomId")}
                {...register("customHostId")}
                className={`form-control ${errors.customHostId ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.customHostId : defaultCustomHostId}
              />
              <Form.Control.Feedback type='invalid'>{errors?.customHostId?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {addMode && (
          <Row className='dialog-row last'>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  {t("labelCopyFrom")}
                  <span className='optional'>{t("filtersForm.labelOptional", tForms)}</span>
                </Form.Label>
                {datasets && (
                  <Form.Select {...register("copyFromDatasetId")}>
                    <option key={NoCopyFromDatasetOption} value={0}>
                      {t(NoCopyFromDatasetOption)}
                    </option>
                    {datasets.items.map((item) => (
                      <option key={`dataset_${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
                {!datasets && <Placeholder animation='glow' />}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{t("labelDemoDataset")}</Form.Label>
                <StandardTooltip text={t("tooltipDemoDataset")} placement='left'>
                  <Form.Check
                    type='checkbox'
                    {...register("demoDataset")}
                    defaultChecked={false}
                    onChange={(e) => setIsDemoCompany(e.target.checked)}
                  />
                </StandardTooltip>
                {isDemoCompany && <div className='demo-warning'>{t("warningMessageDemoDataset")}</div>}
              </Form.Group>
            </Col>
          </Row>
        )}
        {canReset && (
          <Row className='dialog-row last'>
            <div className='reset-warning'>{t("messageResetWarning")}</div>
            <Button variant={resetDataStatusClicked ? "outline-danger" : "danger"} onClick={handleResetDemoData}>
              {t("buttonResetDemoData")}
            </Button>
            {resetDataStatusClicked && <div className='reset-confirm'>{t("messageResetConfirm")}</div>}
          </Row>
        )}
      </Form>
    </AddOrEditModelDialog>
  );
};

DatasetDialog.defaultProps = {
  defaultHostId: undefined,
  defaultCustomHostId: undefined,
};
