import { ReactElement } from "react";
import lang from "i18next";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { CustomerDetails } from "../../../models/customers/customerDetails";
import { PaymentTransaction } from "../../../models/paymentTransactions/paymentTransaction";
import { ViewItemButton } from "../../../components/buttons/CommonIconButtons";
import { MainRouteConsts } from "../../MainRoutesConsts";
import {
  AllocatedStatusArray,
  PaymentTransactionStatusArray,
  PaymentTransactionTypeArray,
} from "../../../models/paymentTransactions/paymentTransactionEnums";
import { TypeColumnRender } from "../../../components/grid/DataGrid";
import { DateItem } from "../../../components/grid/DateItem";
import { BaseOptionsColumn } from "../../../components/grid/dataGridHelpers";
import { CurrencyItem } from "../../../components/misc/CurrencyItem";
import { DateOutputType } from "../../../utils/dateFormatter";
import { PaymentTransactionStatusTag } from "../PaymentTransactionStatusTag";
import { tPages } from "../../../i18n";
import AllocatedStatusTag from "../AllocatedStatusTag";

const LangOptions = tPages;

/*
 * Component to show the customer information in the table
 */
const CustomerInfo = ({ customer }: { customer: CustomerDetails }): ReactElement => (
  <>
    <div>{customer.companyName}</div>
    <div className='small'>
      {customer.firstNames} {customer.surname}
    </div>
  </>
);

const ColumnButtons = ({ paymentTransaction }: { paymentTransaction: PaymentTransaction }): ReactElement => (
  <ViewItemButton route={MainRouteConsts.PaymentTransactions} id={paymentTransaction.id} />
);

export const PaymentTransactionIdColumn: TypeColumn = { name: "id", header: "Id", defaultWidth: 80, defaultVisible: false };
export const PayThemReferenceColumn: TypeColumn = {
  name: "payThemReference",
  header: lang.t("paymentTransactionsList.columnReference", LangOptions),
  minWidth: 90,
  defaultFlex: 1,
};
export const PaymentTransactionTypeColumn: TypeColumn = {
  name: "type",
  header: lang.t("paymentTransactionsList.columnType", LangOptions),
  minWidth: 50,
  defaultFlex: 1,
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: PaymentTransactionTypeArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
};
export const PaymentTransactionStatusDateTimeColumn: TypeColumn = {
  name: "statusDateTime",
  header: lang.t("paymentTransactionsList.columnStatusDate", LangOptions),
  type: "number",
  minWidth: 100,
  defaultFlex: 1,
  dateFormat: "YYYY-MM-DD",
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => (
    <DateItem date={data.createdOn} outputType={DateOutputType.ShortDateTime} />
  ),
};
export const CustomerDetailsCompanyNameColumn: TypeColumn = {
  name: "customerDetails.companyName",
  header: lang.t("paymentTransactionsList.columnCompanyName", LangOptions),
  minWidth: 200,
  defaultFlex: 1,
  sortable: false,
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => <CustomerInfo customer={data.customerDetails} />,
};
export const PaymentRequestCustomerNameColumn: TypeColumn = {
  name: "paymentRequest.customerName",
  header: lang.t("paymentTransactionsList.columnCustomerName", LangOptions),
  minWidth: 100,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => <div>{data.paymentRequest?.customerName}</div>,
};
export const PaymentTransactionTotalAmountColumn: TypeColumn = {
  name: "totalAmount",
  header: lang.t("paymentTransactionsList.columnTotalAmount", LangOptions),
  type: "number",
  textAlign: "end",
  minWidth: 100,
  defaultFlex: 1,
  filterEditor: NumberFilter,
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => (
    <CurrencyItem currency={data.currency} value={data.totalAmount} />
  ),
};
export const PaymentTransactionStatusColumn: TypeColumn = {
  name: "status",
  header: lang.t("paymentTransactionsList.columnStatus", LangOptions),
  defaultFlex: 1,
  textAlign: "center",
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: PaymentTransactionStatusArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PaymentTransactionStatusTag status={data.status} />{" "}
    </div>
  ),
};
export const PaymentTransactionAllocatedStatusColumn: TypeColumn = {
  name: "allocatedStatus",
  header: lang.t("paymentTransactionsList.columnAllocatedStatus", LangOptions),
  defaultFlex: 1,
  textAlign: "center",
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: AllocatedStatusArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <AllocatedStatusTag allocatedStatus={data.allocatedStatus} />{" "}
    </div>
  ),
};

export const OptionsColumn: TypeColumn = {
  ...BaseOptionsColumn,
  render: ({ data }: TypeColumnRender<PaymentTransaction>) => <ColumnButtons paymentTransaction={data} />,
};
