import { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Transaction } from '../../models/transactions/transaction';
import AccountDetails from './AccountDetails';
import ContactDetails from './ContactDetails';
import TransactionDetails from './TransactionDetails';

const TransactionInfoTab = ({
  transaction,
}: {
  transaction: Transaction;
}): ReactElement => (
  <>
    <Row>
      <Col className='panel-column'>
        <TransactionDetails transaction={transaction} />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <ContactDetails title='Contact' contact={transaction.contact} />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <AccountDetails account={transaction.account ?? undefined} />
      </Col>
    </Row>
  </>
);

export default TransactionInfoTab;
