/* eslint-disable import/prefer-default-export */

// The rebuild status of the custom host Id
export enum CustomHostIdRebuildState {
  // No rebuild is currently taking place
  None = "None",

  // // A rebuild is required but has not yet started
  QueuedForRebuild = "QueuedForRebuild",

  // A rebuild is currently taking place
  Rebuilding = "Rebuilding",

  // The previous rebuild has completed
  Completed = "Completed",
}
