/* eslint-disable class-methods-use-this */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  ResponseType,
} from "axios";
import { isProblemDetails, ProblemDetails } from "../problemDetails";
import PageResult from "./PageResults";
import CursorPageResult from "./CursorPageResult";

export abstract class BaseApi {
  protected static defaultItemsPerPage = 10;

  protected abstract getInstance(
    responseType: ResponseType | undefined
  ): AxiosInstance;

  private internalGetInstance(): AxiosInstance {
    const instance = this.getInstance(undefined);
    return instance;
  }

  /*
   * Builds the URL and the page parameters
   */
  protected buildUrlPageParams(
    page: number,
    itemsPerPage: number = BaseApi.defaultItemsPerPage,
    sort?: string | null,
    filter?: string | null
  ): URLSearchParams {
    const params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("itemsPerPage", itemsPerPage.toString());
    if (sort) params.append("sort", sort);
    if (filter) params.append("filter", filter);
    return params;
  }

  protected buildUrlCursorPageParams(
    cursor: string | null,
    itemsPerPage: number = BaseApi.defaultItemsPerPage,
    filter?: string
  ): URLSearchParams {
    const params = new URLSearchParams();
    if (cursor) params.append("cursor", cursor);
    params.append("itemsPerPage", itemsPerPage.toString());
    if (filter) params.append("filter", filter);
    return params;
  }

  /*
   * Builds the URL and the page parameters
   */
  protected buildUrlPageParams2(): URLSearchParams {
    const params = new URLSearchParams();
    return params;
  }

  /*
   * Appends the second set of URLSearchParams to the first
   */
  protected appendSearchParameters(
    firstParams: URLSearchParams,
    secondParams?: URLSearchParams
  ): URLSearchParams {
    if (!firstParams)
      throw new Error(
        "firstParams in appendSearchParameters cannot be undefined"
      );
    if (secondParams)
      secondParams.forEach((value, key) => firstParams.append(key, value));
    return firstParams;
  }

  /*
   * Creates a query string using the paramaters, if any
   */
  protected createQueryFromParameters(parameters?: URLSearchParams): string {
    return `${parameters ? `?${parameters.toString()}` : ""}`;
  }

  /*
   * Performs a get
   */
  protected async get<T>(
    endPoint: string,
    parameters?: URLSearchParams
  ): Promise<T> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    const response = await inst
      .get<T>(url)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Gets an item
   */
  protected async getItem<T, TId>(
    endPoint: string,
    itemId: TId,
    parameters?: URLSearchParams
  ): Promise<T> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}/${itemId}${this.createQueryFromParameters(
      parameters
    )}`;
    const response = await inst
      .get<T>(url)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Performs a get, else undefined if not found
   */
  protected async getOrNotFound<T>(
    endPoint: string,
    parameters?: URLSearchParams
  ): Promise<T | undefined> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    const response = await inst
      .get<T>(url)
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response?.status === 404)
          return undefined;
        this.processHttpError(error);
        return undefined;
      });
    return response?.data;
  }

  /*
   * Returns a page items
   */
  protected async getCursorPage<T>(
    endPoint: string,
    cursor: string | null,
    itemsPerPage: number,
    filter?: string,
    otherParameters?: URLSearchParams
  ): Promise<CursorPageResult<T>> {
    const inst = this.internalGetInstance();
    const params = this.appendSearchParameters(
      this.buildUrlCursorPageParams(cursor, itemsPerPage, filter),
      otherParameters
    );
    const url = `${endPoint}?${params.toString()}`;
    const response = await inst
      .get<CursorPageResult<T>>(url)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Returns a page items
   */
  protected async getPage<T>(
    endPoint: string,
    page: number,
    itemsPerPage: number,
    sort?: string | null,
    filter?: string | null,
    otherParameters?: URLSearchParams
  ): Promise<PageResult<T>> {
    const inst = this.internalGetInstance();
    const params = this.appendSearchParameters(
      this.buildUrlPageParams(page, itemsPerPage, sort, filter),
      otherParameters
    );
    const url = `${endPoint}?${params.toString()}`;
    const response = await inst
      .get<PageResult<T>>(url)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Performs a post
   */
  protected async post<T>(
    endPoint: string,
    data: T,
    parameters?: URLSearchParams
  ): Promise<void> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    await inst.post<T>(url, data).catch((error: Error | AxiosError) => {
      this.processHttpError(error);
    });
  }

  /*
   * Performs a post, returning a response
   */
  protected async postWithResponse<TIn, TOut>(
    endPoint: string,
    data?: TIn,
    parameters?: URLSearchParams
  ): Promise<TOut> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;

    const response = await inst
      .post<TIn, AxiosResponse<TOut>>(url, data)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Performs a put
   */
  protected async put<T>(
    endPoint: string,
    data: T,
    parameters?: URLSearchParams
  ): Promise<void> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    await inst.put<T>(url, data).catch((error: Error | AxiosError) => {
      this.processHttpError(error);
    });
  }

  /*
   * Performs a put, returning a response
   */
  protected async putWithResponse<TIn, TOut>(
    endPoint: string,
    data: TIn,
    parameters?: URLSearchParams
  ): Promise<TOut> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    const response = await inst
      .put<TIn, AxiosResponse<TOut>>(url, data)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Performs a patch
   */
  protected async patch<T>(
    endPoint: string,
    data: T,
    parameters?: URLSearchParams
  ): Promise<void> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    await inst.patch<T>(url, data).catch((error: Error | AxiosError) => {
      this.processHttpError(error);
    });
  }

  /*
   * Performs a patch, returning a response
   */
  protected async patchWithResponse<TIn, TOut>(
    endPoint: string,
    data: TIn,
    parameters?: URLSearchParams
  ): Promise<TOut> {
    const inst = this.internalGetInstance();
    const url = `${endPoint}${this.createQueryFromParameters(parameters)}`;
    const response = await inst
      .patch<TIn, AxiosResponse<TOut>>(url, data)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }

  /*
   * Performs a delete
   */
  protected async deleteItem<TId>(
    endPoint: string,
    itemId: TId,
    parameters?: URLSearchParams
  ): Promise<void> {
    const inst = this.internalGetInstance();
    await inst
      .delete(
        `${endPoint}/${itemId}${this.createQueryFromParameters(parameters)}`
      )
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
  }

  /*
   * Processes an http error, throwing a ProblemDetails object if exists
   */
  protected processHttpError(error: unknown): never {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      isProblemDetails(error.response?.data)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error.response.data as ProblemDetails;
    }
    throw error;
  }
}

export default BaseApi;
