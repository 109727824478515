/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Form, Row, Col, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../components/dialogs/AddOrEditCommonDialog";
import { PaymentFeeConfiguration, RegisterPaymentFeeConfiguration } from "../../models/paymentsFees/paymentFeeConfiguration";
import { FeeCalculatorType } from "../../models/paymentsFees/feeCalculatorType";
import { tForms } from "../../i18n";
import LatePaymentModelConsts from "../../models/paymentsFees/latePaymentModelConsts";
import StandardTooltip from "../../components/tooltips/StandardTooltip";
import "../viewsCommon.scss";
import { getUkLatePaymentInfo } from "../../services/latePayments/latePayments.service";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { CurrencyFormatter } from "../../utils/currencyFormatter";

const keyPrefix = "latePaymentConfigurationForm";

export type FormModel = {
  type: FeeCalculatorType;
  name: string;
  description: string;
  enabled: boolean;
  gracePeriodInDays: number;
  recurringPeriodInDays: number;
  percentage?: number;
  amount?: number;
  applyCharges?: boolean;
};

const validationSchema = Yup.object({
  name: Yup.string()
    .label("Name")
    .required()
    .min(LatePaymentModelConsts.NameMinLength)
    .max(LatePaymentModelConsts.NameMaxLength),
  description: Yup.string().label("Description").max(LatePaymentModelConsts.DescriptionMaxLength),
  gracePeriodInDays: Yup.number().label("Grace Period").required().min(0),
}).required();

const UkLatePaymentInfoPanel = (): ReactElement => {
  const { data: ukLatePaymenyInfo } = useQuery([QueryNameConsts.UkLatePaymenyInfo], () => getUkLatePaymentInfo());

  const formatCurrency = (value: number): string => {
    const formattedValue = CurrencyFormatter.getFormattedCurrencyNumber("GBP", value);
    return `£${formattedValue.replace(".00", "")}`;
  };

  return (
    <>
      <h6>Todays Annual Interest Rate</h6>
      {ukLatePaymenyInfo && (
        <>
          <p>
            Statutory interest rate: {ukLatePaymenyInfo.annualStatutoryInterestRate}% + {ukLatePaymenyInfo.currentBoEBaseRate}%
            base rate, totals <b>{ukLatePaymenyInfo.annualStatutoryInterestRate + ukLatePaymenyInfo.currentBoEBaseRate}%</b>
          </p>
          <p>
            Note: The Bank of England base rate used to calculate the interest owed, will be the rate that was in effect on the
            date the invoice was overdue.
          </p>
          <div className='item-view-section'>
            <h6>Debt Recovery Costs</h6>
            <Table borderless className='panel-table '>
              <thead>
                <tr>
                  <td>Amount of debt</td>
                  <td>What you can charge</td>
                </tr>
              </thead>
              <tbody>
                {ukLatePaymenyInfo?.charges &&
                  ukLatePaymenyInfo.charges.map((entry) => {
                    const from = entry.from === 0 ? "Up " : formatCurrency(entry.from);
                    const to = entry.to === 0 ? " or more" : ` to ${formatCurrency(entry.to)}`;
                    return (
                      <tr key={entry.from}>
                        <td>
                          {from}
                          {to}
                        </td>
                        <td>{formatCurrency(entry.charge)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

/*
 * UK late payment configuration dialig
 */
const UkLatePaymentDialog = ({
  show,
  mode,
  data,
  onSave,
  onClose,
}: AddOrEditDialogProps<PaymentFeeConfiguration, FormModel>): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const addMode = mode === "add";

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormModel>({ resolver: yupResolver(validationSchema) });

  const handleSave = (modelData: FormModel) => {
    const model = { ...modelData };
    model.type = FeeCalculatorType.Uk;
    model.name = modelData.name.trim();
    model.description = modelData.description.trim();
    model.enabled = modelData.enabled;
    onSave(model);
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <AddOrEditModelDialog
      mode={mode}
      title={t(addMode ? "titleAddUkLatePayment" : "titleEditUkLatePayment")}
      show={show}
      onHide={handleClose}
      size='lg'
      formId='latePaymentForm'
    >
      <Form
        id='latePaymentForm'
        className='dialog-form'
        noValidate
        onSubmit={handleSubmit((submitData) => handleSave(submitData))}
      >
        <div className='d-flex justify-content-between '>
          <Row className='flex-grow-1 '>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{t("labelName")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderName")}
                  {...register("name")}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  defaultValue={!addMode ? data?.name : undefined}
                />
                <Form.Control.Feedback type='invalid'>{errors?.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className=''>
            <Col md={6}>
              <Form.Label>{}</Form.Label>
              <StandardTooltip text={t("tooltipEnable")} placement='left-start'>
                <Form.Check
                  type='switch'
                  {...register("enabled")}
                  label={t("labelEnabled")}
                  defaultChecked={!addMode ? data?.enabled : true}
                />
              </StandardTooltip>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>{t("labelDescription")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("placeholderDescription")}
                {...register("description")}
                className={`form-control ${errors.description ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.description : undefined}
              />
              <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label>{t("labelGracePeriod")}</Form.Label>
              <Form.Control
                type='number'
                placeholder={t("placeholderGracePeriod")}
                {...register("gracePeriodInDays")}
                className={`form-control ${errors.gracePeriodInDays ? "is-invalid" : ""}`}
                defaultValue={!addMode ? data?.gracePeriodInDays : 0}
              />
              <Form.Control.Feedback type='invalid'>{errors?.gracePeriodInDays?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>{}</Form.Label>
            <StandardTooltip text={t("tooltipApplyCharges")} placement='left'>
              <Form.Check
                type='switch'
                {...register("applyCharges")}
                label={t("labelApplyCharges")}
                defaultChecked={!addMode ? data?.applyCharges : true}
              />
            </StandardTooltip>
          </Col>
        </Row>
      </Form>
      <hr />
      <UkLatePaymentInfoPanel />
    </AddOrEditModelDialog>
  );
};
export default UkLatePaymentDialog;
