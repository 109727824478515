export enum NotificationUserType {
  PayThemUser = "PayThemUser",
  EmailAddress = "EmailAddress",
}

export const NotificationUserTypeMap = new Map<NotificationUserType, string>([
  [NotificationUserType.PayThemUser, "PayThem User"],
  [NotificationUserType.EmailAddress, "Email Address"],
]);

export const NotificationUserTypeArray = Array.from(NotificationUserTypeMap.entries());

export default NotificationUserType;
