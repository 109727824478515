import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import QRCodeDialog from "../../components/dialogs/QrCodeDialog";
import { IconButton } from "../../components/icons/IconButton";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import { PanelItem } from "../../components/panels/Panels";
import { DateOutputType, DefaultBlankText } from "../../utils/dateFormatter";
import { AccountLink } from "../Accounts/AccountLink";
import { DefaultBlankStringText } from "../viewCommon";
import { tPages } from "../../i18n";
import { BadgeSize } from "../../models/misc/badgeSize";
import RequestStatusTag from "./RequestStatusTag";
import { textOrDefault } from "../../helpers/stringHelpers";
import EditPaymentRequestDetailsButton from "./Editing/EditPaymentRequestDetailsDialog";
import { BasePaymentRequestTabProps } from "./common";
import { PaymentRequestUsageType } from "../../models/paymentRequests/paymentRequestUsageType";
import { getUsageType } from "../../helpers/paymentRequests/paymentRequestHelper";
import useDateFormatter from "../../common/hooks/useDateFormatter";

const keyPrefix = "paymentRequestPage.details";

/*
 * Generates a test transaction status tag if needed
 */
const TestTransactionTag = ({ isTest }: { isTest: boolean }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  return isTest ? <div className='tag details-status status-badge-warning'>{t("testTransaction")}</div> : <></>;
};

/*
 * Displays the URL with optional QR Code
 */
const UrlItem = ({ url, qrcode }: { url: string; qrcode: boolean }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const [show, setShow] = useState(false);
  return (
    <>
      <a className='item-url' href={url} target='_blank' rel='noreferrer'>
        {url}
      </a>
      <CopyToClipboard text={url} />
      {qrcode && (
        <>
          <IconButton title={t("titleQRCode")} icon={faQrcode} onClick={() => setShow(true)} />
          <QRCodeDialog qrcodeValue={url} show={show} handleClose={() => setShow(false)} />
        </>
      )}
    </>
  );
};

/*
 * The main details card
 */
const RequestDetails = ({ paymentRequest, onUpdate }: BasePaymentRequestTabProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const [usage, setUsage] = useState<PaymentRequestUsageType>(PaymentRequestUsageType.Single);
  const dateFormatter = useDateFormatter();

  // Get the payment request usage type
  useEffect(() => {
    getUsageType(paymentRequest).then((result) => {
      setUsage(result);
    });
  }, [paymentRequest]);

  return (
    <Card className='custom-detail-card'>
      <Card.Body>
        <Card.Title className='details-title'>
          <span>{t("title").toUpperCase()}</span>
          <div className='tag-items'>
            <TestTransactionTag isTest={paymentRequest.test} />
            <EditPaymentRequestDetailsButton paymentRequest={paymentRequest} onUpdate={onUpdate} />
          </div>
        </Card.Title>
        <Card.Text as='div'>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className='main-item'>
                <div className='title'>{t("customerName")}</div>
                <div className='content'>{paymentRequest.customerName}</div>
              </div>
              <div className='main-item'>
                <div className='title'>{t("amountToPay")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem
                    status={paymentRequest.requestStatus}
                    currency={paymentRequest.currency}
                    value={paymentRequest.amountToPay}
                  />
                </div>
                <RequestStatusTag className='tag-end' size={BadgeSize.Small} status={paymentRequest.requestStatus} />
              </div>
            </Col>
            <Col md={12} lg={6}>
              <PanelItem title={t("createdOn")}>{dateFormatter.toShortDateTime(paymentRequest.createdOn)}</PanelItem>
              <PanelItem title={t("customerReference")}>{paymentRequest.customerReference ?? DefaultBlankStringText}</PanelItem>
              <PanelItem title={t("account")}>
                <AccountLink account={paymentRequest.account} />
              </PanelItem>
              <PanelItem title={t("validFrom")}>
                {dateFormatter.dateTimeOrString(paymentRequest.validFrom, DefaultBlankText, DateOutputType.ShortDate)}
              </PanelItem>
              <PanelItem title={t("validTo")}>
                {dateFormatter.dateTimeOrString(paymentRequest.validUntil, DefaultBlankText, DateOutputType.ShortDate)}
              </PanelItem>
              <PanelItem title={t("paymentRequestedBy")}>
                {dateFormatter.dateTimeOrString(paymentRequest.paymentRequiredBy, DefaultBlankText, DateOutputType.ShortDate)}
              </PanelItem>
              <PanelItem title={t("captureType")}>{paymentRequest.captureType}</PanelItem>
              <PanelItem title={t("usageType")}>{usage}</PanelItem>
              <div className='mb-3' />
            </Col>
          </Row>
          <Row className='g-0'>
            <hr />
            <div className='main-item'>
              <div className='title'>{t("description")}</div>
              <div>{textOrDefault(paymentRequest.description)}</div>
            </div>
            <div className='main-item'>
              <div className='title'>{t("paymentUrl")}</div>
              <UrlItem url={paymentRequest.requestUrl} qrcode />
            </div>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

RequestDetails.defaultProps = {
  onUpdate: undefined,
};

export default RequestDetails;
