import { ReactElement } from "react";
import { CurrencyFormatter } from "../../utils/currencyFormatter";
import { DefaultBlankText } from "../../common/commonConsts";
import { PaymentRequestStatus } from "../../models/paymentRequests/paymentRequestStatus";
import { PaidStatus } from "../../models/transactions/transactionEnums";
import { PaymentTransactionStatus } from "../../models/paymentTransactions/paymentTransactionEnums";
import "./CurrencyItem.scss";

interface CurrencyItemProps {
  currency: string;
  value: number | null | undefined;
  blankText?: string;
  status?: PaymentRequestStatus | PaidStatus | PaymentTransactionStatus;
  allowZero?: boolean;
}

/*
 * Formats and displays a currency and value
 */
export const CurrencyItem = ({ currency, value, blankText, status, allowZero }: CurrencyItemProps): ReactElement => {
  if ((!value && value !== 0) || (value === 0 && !allowZero)) return <div>{blankText}</div>;
  return (
    <div className={`currency-item ${status?.toLowerCase() || ""}`}>
      <span className='currency-text'>{currency}</span>
      <span className='currency-value'>{CurrencyFormatter.getFormattedCurrencyNumber(currency, value)}</span>
    </div>
  );
};

CurrencyItem.defaultProps = {
  blankText: DefaultBlankText,
  status: undefined,
  allowZero: false,
};

export default CurrencyItem;
