import { ReactElement, useEffect, useState } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import { Transaction } from "../../models/transactions/transaction";
import PaymentAllocationsTab from "../common/PaymentAllocations/PaymentAllocationsTab";
import DocumentsTab from "../Documents/DocumentsTab";
import TransactionInfoTab from "./TransactionsInfoTab";
import { deleteDocument, getDocumentData, uploadTransactionDocument } from "../../services/transactions/transactions.service";
import SettlementOffersTab from "./SettlementOffers/SettlementOffersTabs";
import { CreateLatePaymentFeesTitle } from "../common/LatePayments/LatePaymentsHelper";
import LatePaymentsTab from "../common/LatePayments/LatePaymentsTab";
import { CreatePaymentAllocationsTitle } from "../common/PaymentAllocations/PaymentAllocationHelper";

const keyPrefix = "transactionPage";

interface TransactionTabsProbs {
  transaction: Transaction;
  onTransactionChanged: () => void;
}

const TransactionTabs = ({ transaction, onTransactionChanged }: TransactionTabsProbs): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const [selectedTab, setSelectedTab] = useState<string | null>("info");
  const [documentCount, setDocumentCount] = useState(transaction.documents?.length ?? 0);
  const [settlementOffersCount, setSettlementOffersCount] = useState(transaction.settlementOffers?.length ?? 0);

  useEffect(() => {
    setSettlementOffersCount(transaction.settlementOffers?.length ?? 0);
    setDocumentCount(transaction.documents?.length ?? 0);
  }, [transaction]);

  const setTab = (tab: string | null) => {
    setSelectedTab(tab);
  };

  // Handles uploading of new document
  const handleUpload = async (file: File): Promise<void> => {
    const newDocument = await uploadTransactionDocument(transaction.id, file);
    if (newDocument) {
      onTransactionChanged();
    }
  };

  // Handles deleting of existing doucment
  const handleDeleteDocument = async (documentId: string): Promise<void> => {
    await deleteDocument(transaction.id, documentId);
    onTransactionChanged();
  };

  // Returns the documents data (actual file)
  const handleOnGetDocumentData = async (documentId: string): Promise<File | undefined> => {
    const documentData = await getDocumentData(transaction.id, documentId);
    return documentData;
  };

  const tabElementSettlementOffers = (
    <>
      {t("settlementOffersTabTitle")}
      &nbsp;
      <Badge className='tab-badge'>{settlementOffersCount}</Badge>
    </>
  );

  const tabElementDocument = (
    <>
      {t("documentsTabTitle")}
      &nbsp;
      <Badge className='tab-badge'>{documentCount}</Badge>
    </>
  );

  return (
    <Tabs
      defaultActiveKey='info'
      id='account-tab'
      className='mb-2'
      activeKey={selectedTab ?? ""}
      onSelect={(tab) => setTab(tab)}
    >
      <Tab eventKey='info' title={t("infoTabTitle")}>
        <TransactionInfoTab transaction={transaction} />
      </Tab>
      <Tab eventKey='settlementOffers' title={tabElementSettlementOffers}>
        <SettlementOffersTab transaction={transaction} onTransactionChanged={onTransactionChanged} />
      </Tab>
      <Tab eventKey='paymentAllocations' title={CreatePaymentAllocationsTitle(transaction.paymentAllocations)}>
        <PaymentAllocationsTab
          paymentAllocations={transaction.paymentAllocations}
          currency={transaction.currency}
          mode='paymentTransactionView'
        />
      </Tab>
      <Tab eventKey='latePayments' title={CreateLatePaymentFeesTitle(transaction.paymentFeeTransactions)}>
        <LatePaymentsTab latePaymentTransactions={transaction.paymentFeeTransactions} mode='paymentTransaction' />
      </Tab>
      <Tab eventKey='documents' title={tabElementDocument}>
        <DocumentsTab
          documents={transaction.documents}
          onUpload={handleUpload}
          onDeleteDocument={handleDeleteDocument}
          onGetDocumentData={handleOnGetDocumentData}
        />
      </Tab>
    </Tabs>
  );
};

export default TransactionTabs;
