import TenantSettingsApi from "../../common/api/payThemApi/tenantSettingsApi";
import TenantSettingKey from "../../models/tenantSettings/tenantSettingEnums";

const getBoolTenantSetting = async (settingKey: TenantSettingKey): Promise<boolean> => {
  const api = new TenantSettingsApi();
  const result = await api.getTenantSetting(settingKey);
  if (!result) return false;
  return result.value?.toLocaleLowerCase() === "true";
};

/*
 * Returns the status for ShowBetaPaymentProviders setting
 */
export const getShowBetaPaymentProviders = async (): Promise<boolean> =>
  getBoolTenantSetting(TenantSettingKey.ShowBetaPaymentProviders);

/*
 * Returns the status for AllowLatePayments setting
 */
export const getAllowLatePayments = async (): Promise<boolean> => getBoolTenantSetting(TenantSettingKey.AllowLatePayments);

/*
 * Returns the status for AllowInternalNotifications setting
 */
export const getAllowInternalNotifications = async (): Promise<boolean> =>
  getBoolTenantSetting(TenantSettingKey.AllowInternalNotifications);

/*
 * Returns the status for AllowCustomerNotifications setting
 */
export const getAllowCustomerNotifications = async (): Promise<boolean> =>
  getBoolTenantSetting(TenantSettingKey.AllowCustomerNotifications);
