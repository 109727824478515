import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { filterToTextDescription } from "../../../components/grid/dataGridFilterHelpers";

export const TypeFilterName = "type";
export const TotalAmountFilterName = "totalAmount";
export const CustomerFilterName = "paymentRequest.customerName";
export const StatusFilterName = "status";

export const FilterNameMap = new Map([
  [TypeFilterName, "Transaction Type"],
  [TotalAmountFilterName, "Amount"],
  [CustomerFilterName, "Customer"],
  [StatusFilterName, "Status"],
]);

/*
 * Builds text descriptions for the filters
 */
export const buildFilterDescriptions = (tableFilters?: TypeFilterValue): string[] | undefined => {
  const filterTexts: string[] = [];

  tableFilters?.forEach((filter) => {
    const filterName = FilterNameMap.get(filter.name);
    if (filterName) {
      const text = filterToTextDescription(filter, filterName);
      if (text) {
        filterTexts.push(text);
      }
    }
  });

  return filterTexts;
};
