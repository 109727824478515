import { createContext, useContext } from "react";
import { DefaultWebsiteView, WebsiteView } from "../../components/websiteComms/WebsiteView";

export type WebsiteViewContextType = WebsiteView;
export const WebsiteViewContext = createContext<WebsiteViewContextType>(DefaultWebsiteView);

/*
 * Returns the website view details
 */
const useWebsiteView = (): WebsiteView => {
  const context = useContext(WebsiteViewContext);
  if (context === undefined) {
    throw new Error("useWebsiteView must be used within a WebsiteViewProvider");
  }
  return context;
};
export default useWebsiteView;
