import { ReactElement, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { SettlementOfferStatus, SettlementType } from "../../../models/settlements/settlementEnums";
import SettlementOfferDialog, { SettlementOfferFormModel } from "./SettlementOfferDialog";
import { Transaction } from "../../../models/transactions/transaction";
import { SettlementOffer } from "../../../models/settlements/settlementOffer";
import { PaidStatus } from "../../../models/transactions/transactionEnums";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";

const keyPrefix = "settlementOffersTab";

interface SettlementTypeMenuEntry {
  type: SettlementType;
  name: string;
}

/*
 * Items to appear in our New Settlement drop down button
 */
const settlementTypesMenu = [
  { type: SettlementType.PercentageDiscount, name: "menuPercentage" },
  { type: SettlementType.AbsoluteDiscount, name: "menuAbsolute" },
  { type: SettlementType.ReplacementAmount, name: "menuReplacement" },
] as SettlementTypeMenuEntry[];

interface AddSettlementOfferButtonProps {
  sourceTransaction: Transaction;
  onSave: (settlementOffer: SettlementOffer) => Promise<void>;
}

/*
 * Button for handling adding and editing of settlement offers
 */
const AddSettlementOfferButton = ({ sourceTransaction, onSave }: AddSettlementOfferButtonProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [show, setShow] = useState(false);
  const disabledByPaidStatus = sourceTransaction.paidStatus === PaidStatus.FullyPaid;
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<SettlementType>(SettlementType.None);
  const disabledByPermission = !usePermission(UserPermission.CreateSettlementConfigurations);

  async function handleOnSave(model: SettlementOfferFormModel) {
    setShow(false);
    const newOffer = {
      type: model.type,
      transactionId: sourceTransaction.id,
      status: SettlementOfferStatus.Open,
      description: model.description,
      validFrom: model.validFrom,
      validUntil: model.validUntil,
      currency: sourceTransaction.currency,
      amount: model.amount,
      percentageDiscount: model.percentageDiscount,
      priority: 0,
    } as SettlementOffer;
    setSaving(true);
    await onSave(newOffer);
    setSaving(false);
  }

  // Menu a settlement offer type is chosen from the menu
  const handleMenuItemSelected = (type: SettlementType) => {
    setSelectedType(type);
    setShow(true);
  };

  const limitedTypes = sourceTransaction.settlementOffers?.flatMap((x) => x.type);

  return (
    <>
      <Dropdown onSelect={(eventKey) => handleMenuItemSelected(eventKey as SettlementType)}>
        <Dropdown.Toggle disabled={disabledByPaidStatus || saving || disabledByPermission} variant='primary' id='new-settlement-offer-button'>
          <Spinner as='span' animation='border' size='sm' role='status' hidden={!saving} style={{ marginRight: "5px" }} />
          {t("buttonNewSettlementOffer")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <>
            {settlementTypesMenu.map((entry) => (
              <Dropdown.Item
                key={entry.type}
                eventKey={entry.type}
                disabled={limitedTypes && limitedTypes.length > 0 && !limitedTypes.includes(entry.type)}
              >
                {t(entry.name)}
              </Dropdown.Item>
            ))}
          </>
        </Dropdown.Menu>
      </Dropdown>
      <SettlementOfferDialog
        type={selectedType}
        sourceTransaction={sourceTransaction}
        show={show}
        mode='add'
        onClose={() => setShow(false)}
        onSave={(model) => handleOnSave(model)}
      />
    </>
  );
};

export default AddSettlementOfferButton;
