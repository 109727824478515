/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosError, AxiosResponse } from "axios";
import { Transaction, TransactionAdd } from "../../../models/transactions/transaction";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";
import { Document } from "../../../models/documents/document";
import { getFilenameFromContentDisposition } from "../../../utils/httpUtilities";
import { SettlementOffer } from "../../../models/settlements/settlementOffer";
import { TransactionIncludeOptions } from "./transactionIncludeOptions";
import { buildExpandParametersString } from "./includeOptions";
import { CountResult } from "../../../models/count/countResult";

const DefaultDownloadDocumentName = "document.pdf";

export class TransactionsApi extends BasePayThemApi {
  private endPoint = "transactions";

  private documentsPoint = "documents";

  private settlementOffersPoint = "settlement-offers";

  // Return a transaction
  public async getTransaction(id: string): Promise<Transaction> {
    const result = await this.getItem<Transaction, string>(this.endPoint, id);
    return result;
  }

  // Returns a page of transaction
  public async getTransactions(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string,
    includeOptions?: TransactionIncludeOptions
  ): Promise<PageResult<Transaction>> {
    const params = new URLSearchParams();
    const expandParameter = buildExpandParametersString(includeOptions);
    if (expandParameter) params.append("expand", expandParameter);

    const result = await this.getPage<Transaction>(this.endPoint, page, itemsPerPage, sort, filter, params);
    return result;
  }

  /*
   * Returns the count of transactions matching the given filter
   */
  public async getTransactionCount(filter?: string): Promise<CountResult> {
    const params = new URLSearchParams();
    if (filter) params.append("filter", filter);
    const result = await this.get<CountResult>(`${this.endPoint}/count`, params);
    return result;
  }

  /*
   * Adds a transaction
   */
  public async addTransaction(transaction: TransactionAdd, matchExisting: boolean): Promise<Transaction> {
    const params = new URLSearchParams();
    params.append("match-existing", matchExisting.toString());
    const result = await this.postWithResponse<TransactionAdd, Transaction>(this.endPoint, transaction, params);
    return result;
  }

  /*
   * Uploads the document to transactions
   */
  public async uploadDocument(transactionId: string, file: File): Promise<Document> {
    const formData = new FormData();
    formData.append("file", file);
    const inst = this.getInstance();
    const url = `${this.endPoint}/${transactionId}/${this.documentsPoint}`;
    const response = await inst.post<FormData, AxiosResponse<Document>>(url, formData).catch((error) => {
      this.processHttpError(error);
    });
    return response.data;
  }

  /*
   * Gets the document data
   */
  public async getDocumentData(transactionId: string, documentId: string): Promise<File> {
    const url = `${this.endPoint}/${transactionId}/${this.documentsPoint}/${documentId}/data`;
    const inst = this.getInstance();
    const response = await inst.get<Blob>(url, { responseType: "blob" }).catch((error: Error | AxiosError) => {
      this.processHttpError(error);
    });

    const filename = getFilenameFromContentDisposition(response.headers["content-disposition"]) ?? DefaultDownloadDocumentName;
    const contentType = response.headers["content-type"];
    const responseData = response.data;
    const file = new File([responseData], filename, { type: contentType });
    return file;
  }

  /*
   * Deletes the document associated to the transaction
   */
  public async deleteDocument(transactionId: string, documentId: string): Promise<void> {
    const url = `${this.endPoint}/${transactionId}/${this.documentsPoint}`;
    await this.deleteItem(url, documentId);
  }

  /*
   * Adds a new settlement offer to the transaction
   */
  public async addSettlementOffer(transactionId: string, settlementOffer: SettlementOffer): Promise<Transaction> {
    const url = `${this.endPoint}/${transactionId}/${this.settlementOffersPoint}`;
    const response = await this.postWithResponse<SettlementOffer, Transaction>(url, settlementOffer);
    return response;
  }

  /*
   * Updates an existing settlement offer for the transaction
   */
  public async updateSettlementOffer(transactionId: string, settlementOffer: SettlementOffer): Promise<Transaction> {
    const url = `${this.endPoint}/${transactionId}/${this.settlementOffersPoint}`;
    const response = await this.putWithResponse<SettlementOffer, Transaction>(url, settlementOffer);
    return response;
  }

  /*
   * Deletes the settlement offer associated to the transaction
   */
  public async deleteSettlementOffer(transactionId: string, settlementOfferId: string): Promise<void> {
    const url = `${this.endPoint}/${transactionId}/${this.settlementOffersPoint}`;
    await this.deleteItem(url, settlementOfferId);
  }

  /*
   * Triggers a rebuild of the custom host id
   */
  public async rebuildCustomHostId(): Promise<void> {
    const url = `${this.endPoint}/custom-host-id/rebuild`;
    await this.post(url, undefined);
  }
}

export default TransactionsApi;
