import { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PaymentTransaction } from '../../models/paymentTransactions/paymentTransaction';
import PayeeDetails from './PayeeDetails';
import PaymentProviderDetails from './PaymentProviderDetails';
import PaymentTransactionDetails from './PaymentTransactionDetails';

const PaymentTransactionInfoTab = ({
  paymentTransaction,
  refreshData,
}: {
  paymentTransaction: PaymentTransaction;
  refreshData: () => {};
}): ReactElement => (
  <>
    <Row>
      <Col className='panel-column'>
        <PaymentTransactionDetails
          paymentTransaction={paymentTransaction}
          refreshData={refreshData}
        />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <PayeeDetails paymentTransaction={paymentTransaction} />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <PaymentProviderDetails paymentTransaction={paymentTransaction} />
      </Col>
    </Row>
  </>
);

export default PaymentTransactionInfoTab;
