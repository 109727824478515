export enum VerificationStatus {
  Unknown = "Unknown",
  Successful = "Successful",
  InReview = "InReview",
  ActionRequired = "ActionRequired",
}

export const VerificationStatusMap = new Map<VerificationStatus, string>([
  [VerificationStatus.Unknown, "Unknown"],
  [VerificationStatus.Successful, "Successful"],
  [VerificationStatus.InReview, "InReview"],
  [VerificationStatus.ActionRequired, "Action Required"],
]);

export const VerificationStatusArray = Array.from(VerificationStatusMap.entries());
