/*


9f1bc896-8c15-4383-8d53-d0c3f4d13ead
70b6e40b-fc26-411c-8e50-191ad65d1162
3b6b2e8e-94e6-4123-9267-fa1de378924c
bc58a09e-7239-4880-9b63-0f39ad2b0645
233e5ca1-f95a-43bb-abb6-99ad6b1bdbdf
7a89a345-1c95-4ef9-a6d9-55cf95bb3e4a
373500a9-7581-4221-accf-e760dc70b0c9
b796294c-075f-415f-9349-522a23aa92aa
69bb8c72-4a4a-4163-a0ae-2acac7a2de12

25b76d33-2bc5-4556-96c1-8a4ca49a1a63
b07b0d40-c904-41c1-8f02-2bab6064a4d6
9352f75a-138c-43a5-afa8-518b12de2ae6
af853685-838b-4348-b019-d897c3d54efb
ec7be192-bd0d-4d64-abe9-a957de97856d
fc3aee1c-085c-44e5-a5a5-a288d6b3d587
30400d71-aa61-4d17-b957-4cff5a9dfb8a
430534c6-46c6-40af-b0c6-369c78b89ec8
a85ad36d-e34c-4828-8f8f-fffde712c714
e7fac052-2988-4d3d-8414-5db6ae3e0965
c1360500-b30e-445e-829f-3942018b4573
cd5875ba-a2c8-4365-bf66-8b01b8cfc73e
84e0173c-1a8b-4ac5-89a9-5543783faa65
33cba85e-5f0a-4e3d-ad8b-0bf03c1f59a0
b80c0923-76b7-4ff2-8079-ffda5efc2686

*/

export class QueryNameConsts {
  public static readonly CompanySettings = "b335c58b-a880-4ab5-9352-8b9836bbc2aa";

  public static readonly CompanyAccountInfo = "624cd240-bdc3-482f-8a76-8d5933af18db";

  public static readonly CompanyPreferences = "8c86a18c-0812-414f-b485-27a5a8969624";

  public static readonly PaymentTransactionItem = "5e7f5944-a01e-4a30-bf90-7b5d477df66f";

  public static readonly PaymentRequestItem = "7fb8063f-bd00-4730-bac0-2997dcf676ea";

  public static readonly PaymentTransactionsList = "a4f16ed9-887c-4cbd-97f1-62c074e32b34";

  public static readonly SuccessfulPaymentTransactions = "2cc9abc2-8d42-47c5-b7a7-f8e4262e0b43";

  public static readonly FailedPaymentTransactions = "61036cd2-40e2-4f2a-909e-0cf48b24c12e";

  public static readonly TransactionsList = "4621e53b-2efb-4be2-bdfd-9ff8fb90aaad";

  public static readonly TransactionItem = "aadf4e91-3e36-4a75-8c16-acc3e6adce56";

  public static readonly AccountsList = "56eb30e5-9c7c-4289-b56d-e87bee2a617b";

  public static readonly AccountItem = "39cdc4de-e47f-4c38-a997-2f8a20d1ed2c";

  public static readonly AccountPaymentTransactionCount = "72c2a399-082c-4ab7-acdd-4f82afa671cb";

  public static readonly AccountTransactionCount = "e748e49d-3c1e-445f-9649-660f9108bc9b";

  public static readonly AccountPaymentRequestCount = "9476c6b2-40ab-44a9-b090-a180be0949ab";

  public static readonly AccountPayerCount = "4270c593-4581-4ea4-b697-b42b2440547b";

  public static readonly PaymentProviderGetDefault = "6c32ca70-dd75-4376-91f6-985d79cfcf31";

  public static readonly PaymentProviderIsDefault = "b6228cd9-9e68-4556-8918-a31c3b3ce860";

  public static readonly PaymentProvidersList = "03932f15-bfe0-45ce-b914-315bc2dff4ae";

  public static readonly AllPaymentProvidersList = "17176bc7-633b-43d8-8385-0665ee257c91";

  public static readonly PaymentProviderTypes = "99dfb908-a713-471f-a9ac-92661303208f";

  public static readonly BanksList = "8c8a39e0-9245-4a00-9597-a9a46cc6323a";

  public static readonly BankItem = "3d13caed-60ee-4f6e-a6c9-47b1fa6df7c2";

  public static readonly SettlementConfigurationsList = "232532d1-ca00-486a-9826-989279785187";

  public static readonly SettlementConfigurationItem = "9256bdb3-8a79-4cf7-99f3-2f7ec2365a6e";

  public static readonly DatasetCurrent = "89e41365-57cc-4022-8b92-e3f4090f4f5a";

  public static readonly DatasetList = "fc773490-d07b-4389-ba60-3f785fb23d41";

  public static readonly TenantSettingShowBetaPaymentProviders = "5b636ddd-ca4f-4b49-ad66-14d13d145471";

  public static readonly UkLatePaymenyInfo = "58c6d128-5130-461f-9ca0-4de7a790632d";

  public static readonly LatePaymentConfigurationsList = "146611b8-b1d0-473a-89c3-2a9ccc3fcf26";

  public static readonly NotificationUsersList = "542276ed-0c28-4ba9-ab05-d5eebb88a5a6";

  public static readonly CustomerNotifications = "26c129a1-9df8-4e3a-b5fa-c11e673b4b14";

  public static readonly UsersList = "f7272016-50ee-4f5d-8ec7-f09e2a844c9e";

  public static readonly AllowLatePaymentsSetting = "e8b691ea-212b-4297-b97a-8737a536f325";

  public static readonly AllowInternalNotifications = "cd0dd4c6-93c0-4de6-b0eb-110a83851b2c";

  public static readonly AllowCustomerNotifications = "9283efd2-a304-43e3-a10d-9466ab521eb8";

  public static readonly StripeAccountInformation = "155a763f-7258-4b11-8305-8400b36d1ada";

  public static readonly GoCardlessCreditorInformation = "f72d9f0d-208d-44da-95e0-7e1c56063e3b";

  public static readonly HostSystemsItems = "ad232e59-8680-49e1-82cf-52ccca6eabdf";

  public static readonly SupportedBaseCurrencies = "8a1806eb-9223-4252-85e4-1986c9aca82b";

  public static readonly PaymentRequestSettings = "774e94e7-f2b6-459b-9e25-9b2a602b5e40";

  public static readonly SvixDashboardAccess = "3adf3c60-5c34-4c3b-b04d-1a17f2966a1d";

  public static readonly UserMe = "a2244728-8413-471c-b3f8-30ce56d81ea4";

  public static readonly PayerItem = "91e4b20f-04b1-4669-97fb-34cacdeb6457";

  public static readonly PayerPaymentTransactionCount = "78bdc62e-0489-44f4-96d1-883e75dee9a2";
}

export default QueryNameConsts;
