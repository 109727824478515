import {
  ValidateUserPasswordRequest,
  ValidateUserPasswordResult,
} from "../../../models/paymentProviders/transafe/transafeApiModels";
import BasePayThemApi from "./basePayThemApi";

class TranSafeApi extends BasePayThemApi {
  private endPoint = "transafe";

  /*
   * Tests if the connection to the Opayo server using the given vendor details is valid
   */
  public async testConnection(
    userName: string,
    password: string | undefined,
    useTestServer: boolean,
    paymentProviderId?: string
  ): Promise<ValidateUserPasswordResult> {
    const url = `${this.endPoint}/admin/test-connection`;
    const body = { userName, password, useTestServer, paymentProviderId } as ValidateUserPasswordRequest;
    const result = await this.postWithResponse<ValidateUserPasswordRequest, ValidateUserPasswordResult>(url, body);
    return result;
  }
}
export default TranSafeApi;
