/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { tForms, tPages } from "../../../i18n";
import { PaymentRequest } from "../../../models/paymentRequests/paymentRequest";
import PaymentRequestUpdate from "../../../models/paymentRequests/paymentRequestUpdate";
import { updatePaymentRequest } from "../../../services/paymentRequests/paymentRequests";
import { EditItemButton } from "../../../components/buttons/CommonIconButtons";
import { DialogPrimaryButton, DialogSecondaryButton } from "../../../components/dialogs/DialogButtons";
import { getUTCDatePartFromUTCDate } from "../../../helpers/dateHelpers";
import { formatISODate } from "../../../utils/dateFormatter";
import ClearButton from "../../../components/buttons/ClearButton";
import { PaymentRequestStatus } from "../../../models/paymentRequests/paymentRequestStatus";
import { validateFromToDates } from "../../../helpers/validation/yupValidationHelpers";
import { PaymentRequestUsageType, PaymentRequestUsageTypeArray } from "../../../models/paymentRequests/paymentRequestUsageType";
import { canChangeUsageType, getUsageType } from "../../../helpers/paymentRequests/paymentRequestHelper";
import { RequestTypes } from "../../../models/paymentRequests/requestTypes";
import "./EditPaymentRequestDetailsDialog.scss";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";

const keyPrefix = "paymentRequestPage.detailsEditDialog";

interface EditFormModel {
  validFrom?: Date;
  validUntil?: Date;
  paymentRequiredBy?: Date;
  description: string;
  usageType?: PaymentRequestUsageType;
}

const dateValidationTestName = "Date";
const dateValidationTestErrorMessage = "Valid To must be less than Valid From";

// Our validation schema
const validationSchema = Yup.object({
  description: Yup.string().label("Description").max(1000),
  validFrom: Yup.date()
    .label("Valid From")
    .transform((current, original) => (original === "" ? null : current))
    .nullable()
    .notRequired(),
  validUntil: Yup.date()
    .label("Valid To")
    .transform((current, original) => (original === "" ? null : current))
    .nullable()
    .notRequired()
    .test(dateValidationTestName, dateValidationTestErrorMessage, (validUntil, items) => {
      const { validFrom }: { validFrom: Date } = items.parent;
      return validateFromToDates(validFrom, validUntil);
    }),
  paymentRequiredBy: Yup.date()
    .label("Payment Requested By")
    .transform((current, original) => (original === "" ? null : current))
    .nullable()
    .notRequired(),
}).required();

interface EditPaymentRequestDetailsDialogProps {
  paymentRequest: PaymentRequest;
  show: boolean;
  onSave: (model: EditFormModel) => void;
  onClose: () => void;
}

/*
 * Dialog for editing the payment request details
 */
const EditPaymentRequestDetailsDialog = ({
  paymentRequest,
  show,
  onSave,
  onClose,
}: EditPaymentRequestDetailsDialogProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const { t: tF } = useTranslation(tForms.ns);
  const [defaultUsage, setDefaultUsage] = useState<PaymentRequestUsageType>(PaymentRequestUsageType.Single);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<EditFormModel>({ resolver: yupResolver(validationSchema) });

  // Get the payment request usage type
  useEffect(() => {
    getUsageType(paymentRequest).then((result) => {
      setDefaultUsage(result);
    });
  }, [paymentRequest]);

  useEffect(() => {
    if (show) reset();
  }, [show, reset]);

  const handleSave = (saveData: EditFormModel) => {
    const updatedModel = { ...saveData };
    updatedModel.validFrom = getUTCDatePartFromUTCDate(updatedModel.validFrom);
    updatedModel.validUntil = getUTCDatePartFromUTCDate(updatedModel.validUntil);
    updatedModel.paymentRequiredBy = getUTCDatePartFromUTCDate(updatedModel.paymentRequiredBy);
    onSave(updatedModel);
  };

  return (
    <Modal size='lg' show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{t("titleEditPaymentRequest")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id='editPaymentRequestForm'
          className='dialog-form'
          noValidate
          onSubmit={handleSubmit((submitData) => handleSave(submitData))}
        >
          <div className='dialog-card last'>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>
                    {t("labelDescription")}
                    <span className='optional'>{t("subLabelOptional")}</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderDescription")}
                    {...register("description")}
                    className={`form-control ${errors.description ? "is-invalid" : ""}`}
                    defaultValue={paymentRequest.description}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.description?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("labelUsageType")}</Form.Label>
                  <Form.Select
                    disabled={!canChangeUsageType(paymentRequest)}
                    defaultValue={defaultUsage}
                    {...register("usageType")}
                  >
                    {PaymentRequestUsageTypeArray.map((usageType) => (
                      <option key={usageType[0]} value={usageType[0]}>
                        {usageType[1]}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("labelValidFromDate")}</Form.Label>
                  <Form.Control
                    type='date'
                    {...register("validFrom")}
                    defaultValue={formatISODate(paymentRequest?.validFrom, "date")}
                    className={`mb-0 form-control ${errors.validFrom ? "is-invalid" : ""}`}
                  />
                  <ClearButton onClick={() => setValue("validFrom", undefined)} />
                  <Form.Control.Feedback type='invalid'>{errors?.validFrom?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("labelValidToDate")}</Form.Label>
                  <Form.Control
                    type='date'
                    {...register("validUntil")}
                    defaultValue={formatISODate(paymentRequest?.validUntil, "date")}
                    className={`mb-0 form-control ${errors.validUntil ? "is-invalid" : ""}`}
                  />
                  <ClearButton onClick={() => setValue("validUntil", undefined)} />
                  <Form.Control.Feedback type='invalid'>{errors?.validUntil?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t("labelPaymentRequiredBy")}</Form.Label>
                  <Form.Control
                    type='date'
                    {...register("paymentRequiredBy")}
                    defaultValue={formatISODate(paymentRequest?.paymentRequiredBy, "date")}
                    className={`mb-0 form-control ${errors.paymentRequiredBy ? "is-invalid" : ""}`}
                  />
                  <ClearButton onClick={() => setValue("paymentRequiredBy", undefined)} />
                  <Form.Control.Feedback type='invalid'>{errors?.paymentRequiredBy?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={() => onClose()} />
        <DialogPrimaryButton form='editPaymentRequestForm' type='submit' label={tF("buttonUpdate")} />
      </Modal.Footer>
    </Modal>
  );
};

interface EditPaymentRequestDetailsButtonProps {
  paymentRequest: PaymentRequest;
  onUpdate?: () => Promise<void>;
}

/*
 * Button and dialog to trigger edit payment request details
 */
const EditPaymentRequestDetailsButton = ({ paymentRequest, onUpdate }: EditPaymentRequestDetailsButtonProps): ReactElement => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const disabled = !usePermission(UserPermission.EditPaymentRequest);

  const handleUpdate = async (model: EditFormModel) => {
    const update = {
      ...model,
      id: paymentRequest.id,
      billingContact: paymentRequest.billingContact,
      deliveryContact: paymentRequest.deliveryContact,
      options: paymentRequest.options ?? {},
    } as PaymentRequestUpdate;

    // Update the usage type only on invoice requests
    if (paymentRequest.requestType === RequestTypes.Transaction) {
      update.options!.usage = model.usageType;
    }

    setShow(false);
    await updatePaymentRequest(update);
    if (onUpdate) await onUpdate();
  };

  const status = paymentRequest.requestStatus === PaymentRequestStatus.Open;

  return (
    <>
      <EditItemButton
        disabled={!status || disabled}
        onClick={() => setShow(status)}
        title={t(status ? "titleEditPaymentRequest" : "titleCannotEditPaymentRequest")}
      />
      <EditPaymentRequestDetailsDialog
        paymentRequest={paymentRequest}
        show={show}
        onClose={() => setShow(false)}
        onSave={(model) => handleUpdate(model)}
      />
    </>
  );
};

EditPaymentRequestDetailsButton.defaultProps = {
  onUpdate: undefined,
};

export default EditPaymentRequestDetailsButton;
