import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { useQuery } from "react-query";
import { Payer } from "../../../models/payers/payer";
import { tPages } from "../../../i18n";
import PayerInfoTab from "./PayerInfoTab";
import PayerPaymentTransactionsTab from "./PayerPaymentTransactionsTab";
import TabTitleWithCount from "../../common/TabTitleWithCount";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { countPaymentTransactions } from "../../../services/paymentTransactions/paymentTransactions.service";
import { filterItemBuilder } from "../../../utils/filtering/filteringUtils";

const keyPrefix = "accountPage";
const PaymentTransactionsTabEventKey = "payment-transactions";

interface PayerTabProps {
  payer: Payer;
}

/*
 * Displays the payer tabs for a payer
 */
const PayerTab = ({ payer }: PayerTabProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [paymentTransactionsTabSelected, setPaymentTransactionsTabSelected] = useState(false);

  const { data: paymentTransactionCount } = useQuery([QueryNameConsts.PayerPaymentTransactionCount, payer.id], () =>
    countPaymentTransactions(filterItemBuilder("payerId", "eq", payer.id))
  );

  // Enable the tab the first time it's selected
  const handleTabChange = (eventKey: string) => {
    switch (eventKey) {
      case PaymentTransactionsTabEventKey:
        setPaymentTransactionsTabSelected(true);
        break;
      default:
        break;
    }
  };

  return (
    <Tabs defaultActiveKey='info' id='account-tab' className='mb-2' onSelect={(eventKey) => handleTabChange(eventKey ?? "")}>
      <Tab eventKey='info' title={t("infoTabTitle")}>
        <PayerInfoTab payer={payer} />
      </Tab>
      <Tab
        eventKey={PaymentTransactionsTabEventKey}
        title={<TabTitleWithCount title={t("paymentTransactionsTabTitle")} count={paymentTransactionCount} />}
      >
        {paymentTransactionsTabSelected && <PayerPaymentTransactionsTab payer={payer} />}
      </Tab>
    </Tabs>
  );
};

export default PayerTab;
