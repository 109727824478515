import { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";
import QRCode from "qrcode.react";

interface QrCodeDialogProps {
  show: boolean;
  qrcodeValue: string;
  handleClose: () => void;
}

const QRCodeDialog = ({ show, qrcodeValue, handleClose }: QrCodeDialogProps): ReactElement => (
  <Modal size='lg' show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Payment URL QRCode</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <QRCode value={qrcodeValue} size={256} level='L' fgColor='#394355' />
      </div>
      <div>
        <a
          className='small pt-3'
          style={{ display: "flex", justifyContent: "center" }}
          href={qrcodeValue}
          target='_blank'
          rel='noreferrer'
        >
          {qrcodeValue}
        </a>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={handleClose}>
        Ok
      </Button>
    </Modal.Footer>
  </Modal>
);

export default QRCodeDialog;
