/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import UserInitials from "./UserInitials";
import "./UserDetails.scss";

interface UserDetailsProps {
  displayName: string;
  email: string;
  showDisplayName?: boolean;
  showEmail?: boolean;
}

// Component to show the users initials, name and email address
const UserDetails = ({ displayName, email, showDisplayName, showEmail }: UserDetailsProps): ReactElement => {
  const showExtra = showDisplayName === true || showEmail === true;
  return (
    <>
      <UserInitials displayName={displayName} />
      {showExtra && (
        <div className='users-details-container'>
          {showDisplayName && displayName !== email ? <p>{displayName}</p> : <></>}
          {showEmail && <p>{email}</p>}
        </div>
      )}
    </>
  );
};

UserDetails.defaultProps = {
  showDisplayName: true,
  showEmail: true,
};

export default UserDetails;
