import { ReactElement } from "react";
import { addSpacesToSentence } from "../../helpers/stringHelpers";
import { PaymentRequestStatus } from "../../models/paymentRequests/paymentRequestStatus";
import { PaidStatus } from "../../models/transactions/transactionEnums";
import { BadgeSize } from "../../models/misc/badgeSize";
import "./RequestStatusTag.scss";

/*
 * Generates request status tag
 */
const RequestStatusTag = ({
  status,
  size,
  className,
}: {
  status: PaymentRequestStatus | PaidStatus;
  size?: BadgeSize;
  className?: string;
}): ReactElement => {
  const name = status.toString().toLowerCase();
  const text = addSpacesToSentence(status);
  let newClassName = className ? `${className} ` : "";
  newClassName += `details-status ${name ? ` ${name}-status` : ""}`;
  newClassName += `${size ? ` ${size}-size` : ""}`;
  return <div className={newClassName}>{text}</div>;
};

RequestStatusTag.defaultProps = {
  size: BadgeSize.Medium,
  className: undefined,
};
export default RequestStatusTag;
