import { ReactElement } from 'react';
import { addSpacesToSentence } from '../../helpers/stringHelpers';
import { BadgeSize } from '../../models/misc/badgeSize';
import '../../views/viewsCommon.scss';
import './BasicTag.scss';

// The above file needs to move

export function BasicTag({
  tagName,
  tagText,
  className,
  tagCssEnd,
  size,
}: {
  tagName: string;
  tagText?: string;
  className?: string;
  tagCssEnd: string;
  size?: BadgeSize;
}): ReactElement {
  const name = tagName.toLowerCase();
  const text = addSpacesToSentence(tagText ?? tagName);
  let newClassName = className ? `${className} ` : '';
  newClassName += `tag-details ${name ? ` ${name}${tagCssEnd}` : ''}`;
  newClassName += `${size ? ` ${size}-size` : ''}`;
  return <div className={newClassName}>{text}</div>;
}
BasicTag.defaultProps = {
  className: undefined,
  tagText: undefined,
  size: BadgeSize.Medium,
};
export default BasicTag;
