import { ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { PaymentTransactionsApi } from "../../common/api/payThemApi/paymentTransactionsApi";
import { DisplayLoadingOrError } from "../../components/loading/DisplayLoadingOrError";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { CurrencyFormatter } from "../../utils/currencyFormatter";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import PaymentTransactionTabs from "./PaymentTransactionTabs";
import { PaymentTransactionIncludeOptions } from "../../common/api/payThemApi/paymentTransactionIncludeOptions";

/*
 * Generates the title for the payment transaction item page
 */
const Title = ({ paymentTransaction }: { paymentTransaction: PaymentTransaction }): ReactElement => {
  const { t } = useTranslation([tPages.ns]);

  const value = CurrencyFormatter.toCurrencyStringWithoutSymbol(paymentTransaction.currency, paymentTransaction.totalAmount);
  const title = t("pageTitlePaymentTransaction", { transactionValue: value });

  return <div className='page-title'>{title}</div>;
};

/*
 * Displays the payment transaction info
 */
export const PaymentTransactionItem = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([tPages.ns]);
  const { data, isLoading, isError, refetch } = useQuery([QueryNameConsts.PaymentTransactionItem, id], () => {
    const api = new PaymentTransactionsApi();
    const includeOptions = {
      account: true,
      paymentRequest: false,
      paymentAllocations: true,
      latePaymentTransactions: true,
    } as PaymentTransactionIncludeOptions;
    return api.getPaymentTransaction(id ?? "", includeOptions);
  });

  const handleRefresh = async () => {
    await refetch();
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentTransactions")} subTitle2={id} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !data}>
        <Breadcrumb>
          <CommonBreadcrumbs.PaymentTransactions />
        </Breadcrumb>
        {data && (
          <section className='item-view-section payment-request'>
            <Title paymentTransaction={data} />
            <PaymentTransactionTabs paymentTransaction={data} refreshData={handleRefresh} />
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default PaymentTransactionItem;
