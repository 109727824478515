/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";
import { tPaymentProviders, tForms } from "../../../../i18n";
import {
  CommonProviderFormControls,
  ProviderFilterFormControl,
  ProviderSupportedMethodsFormControl,
  hasSupportedCurrencies,
  ProviderSupportedCurrenciesFormControl,
} from "../ProvidersCommon/CommonControls";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import { AddOrEditMode } from "../ProvidersCommon/Common";
import {
  CurrentFormModel,
  CurrentPaymentMethodType,
  CurrentPaymentProviderConfiguration,
} from "./totalProcessingConfiguration";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import BooleanFormSelect from "../ProvidersCommon/BooleanFormSelect";
import { FillerDisplayPassword } from "../../../../utils/secureStringUtils";

const keyPrefix = "totalProcessingForm";

interface TotalProcessingGeneralSettingsTabProps {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  itemFilterGroup: ItemFilterGroup | undefined;
  onShowFilterDialog: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

/*
 * General settings for Total Processing
 */
const TotalProcessingGeneralSettingsTab = ({
  mode,
  data,
  paymentProvider,
  itemFilterGroup,
  onShowFilterDialog,
  register,
  errors,
}: TotalProcessingGeneralSettingsTabProps): ReactElement => {
  const { t } = useTranslation([tPaymentProviders.ns, tForms.ns], { keyPrefix });

  const addMode = mode === "add";
  const defaultPassword = addMode ? undefined : FillerDisplayPassword;

  // For now Total Processing only supports one payment method type
  const paymentMethodTypeInfo = paymentProvider?.paymentMethodTypesInfo.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <CommonProviderFormControls data={data} register={register} errors={errors} />
            <hr />
            <Row className='mb-2'>
              <Col>
                <ProviderFilterFormControl filterGroup={itemFilterGroup} onClick={() => onShowFilterDialog()} />
              </Col>
              <ProviderSupportedMethodsFormControl
                supportedPaymentMethodGroups={paymentProvider?.supportedPaymentMethodGroups}
              />
              {hasSupportedCurrencies(paymentMethodTypeInfo) && (
                <>
                  <Form.Group as={Col} sm='6'>
                    {" "}
                  </Form.Group>
                  <ProviderSupportedCurrenciesFormControl supportedCurrencies={paymentMethodTypeInfo!.supportedCurrencies} />
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Card.Title>{t("titleCredentials")}</Card.Title>
            <Row>
              <Form.Group as={Col} sm='2'>
                <BooleanFormSelect
                  label={t("labelServer")}
                  register={register}
                  fieldName='useSandbox'
                  trueOption={t("optionTestServer")}
                  falseOption={t("optionLiveServer")}
                  reverseOrder
                  defaultState={data?.credentials?.useSandbox}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelEntityId")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderEntityId")}
                  {...register("entityId")}
                  className={`form-control ${errors?.entityId?.message ? "is-invalid" : ""}`}
                  defaultValue={data?.credentials?.entityId}
                />
                <Form.Control.Feedback type='invalid'>{errors?.entityId?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelAccessToken")}</Form.Label>
                <Form.Control
                  type='password'
                  placeholder={t("placeholderAccessToken")}
                  {...register("accessToken")}
                  className={`form-control ${errors?.accessToken?.message ? "is-invalid" : ""}`}
                  defaultValue={defaultPassword}
                />
                <Form.Control.Feedback type='invalid'>{errors?.accessToken?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

TotalProcessingGeneralSettingsTab.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default TotalProcessingGeneralSettingsTab;
