import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";
import { ProviderEvent } from "../../../models/events/providerEvent";

export class ProviderEventsApi extends BasePayThemApi {
  private endPoint = "provider-events";

  /*
   * Returns the events associated to the record id
   */
  public async getAllAssociatedEvents(recordId: string): Promise<PageResult<ProviderEvent>> {
    const url = `${this.endPoint}/records/${recordId}`;
    const result = await this.get<PageResult<ProviderEvent>>(url);
    return result;
  }

  /*
   * Returns the payload associated to the event
   */
  public async getAssociatedPayloadAsync(id: string): Promise<string> {
    const url = `${this.endPoint}/payloads`;
    const result = await this.getItem<string, string>(url, id);
    return result;
  }
}

export default ProviderEventsApi;
