import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DefaultDatasetId } from "../../services/auth/auth.constants";
import { DatasetCustomEventName, getDatasetIdFromStorage, setDatasetIdToStorage } from "../../helpers/storageHelpers";

type SetValue = Dispatch<SetStateAction<number>>;

/*
 * Hook for storing the currently selected dataset id
 */
export function useDatasetId(): [number, SetValue] {
  const readValue = (): number => {
    try {
      const datasetId = getDatasetIdFromStorage();
      return datasetId;
    } catch (error) {
      return DefaultDatasetId;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<number>(readValue);
  // console.log(storedValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue = (value) => {
    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      setDatasetIdToStorage(newValue);

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useDatasetId hook are notified
      window.dispatchEvent(new Event(DatasetCustomEventName));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener("storage", handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener(DatasetCustomEventName, handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener(DatasetCustomEventName, handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
}

export default useDatasetId;
