/*
 * Returns true if the current window is running in an iframe
 */
export const isRunningInIframe = () => window.self !== window.top;

/*
 * Sends a message to the parent window
 */
export const sendMessageToParent = <T>(message: T, target: string) => {
  window.parent.postMessage(message, target ?? "*");
};

/*
 * Search string for filename
 */
const filenameStartstring = "filename=";

/*
 * Extracts the filename from the content dispoition header value
 */
export const getFilenameFromContentDisposition = (contentDisposition: string): string | undefined => {
  if (!contentDisposition) return undefined;

  let startIndex = contentDisposition.indexOf(filenameStartstring);
  let endIndex = -1;
  if (startIndex === -1 || startIndex + filenameStartstring.length >= contentDisposition.length) return undefined;
  startIndex += filenameStartstring.length;

  if (contentDisposition[startIndex] === '"') {
    // eslint-disable-next-line no-plusplus
    endIndex = contentDisposition.indexOf('"', ++startIndex);
  } else {
    endIndex = contentDisposition.indexOf(";", startIndex);
    if (endIndex === -1) {
      endIndex = contentDisposition.length;
    }
  }
  if (endIndex === -1) return undefined;

  return contentDisposition.substring(startIndex, endIndex);
};
export default getFilenameFromContentDisposition;
