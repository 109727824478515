/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormState, useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../../components/dialogs/AddOrEditCommonDialog";
import { tForms } from "../../../i18n";
import { SettlementType, SettlementTypeMap } from "../../../models/settlements/settlementEnums";
import { SettlementOffer } from "../../../models/settlements/settlementOffer";
import { Transaction } from "../../../models/transactions/transaction";
import SettlementOfferModelConsts from "../../../models/settlements/settlementOfferModelConsts";
import { getOutstandingAmount } from "../../../helpers/transactions/transactionHelper";
import { convertDatePartToUtcDate, getDataModelDateForDatePicker, isGreaterThanOrEqual } from "../../../helpers/dateHelpers";

const DefaultPercentageDiscountValue = 0;
const keyPrefix = "settlementOfferForm";

export type SettlementOfferFormModel = {
  type: SettlementType;
  description: string;
  validFrom: Date | undefined;
  validUntil: Date | undefined;
  priority: number;
  amount: number | undefined;
  currency: string;
  percentageDiscount: number | undefined;
};

export interface FormControlsProps {
  sourceTransaction: Transaction;
  register: UseFormRegister<SettlementOfferFormModel>;
  formState: FormState<SettlementOfferFormModel>;
  addMode: Boolean;
  data: SettlementOffer | undefined;
}

const PercentageDiscountFormControls = ({ register, formState, addMode, data }: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;
  return (
    <Row>
      <Col md='6'>
        <Form.Group>
          <Form.Label>{t("labelPercentageDiscount")}</Form.Label>
          <Form.Control
            type='number'
            placeholder={t("placeholderPercentageDiscount")}
            {...register("percentageDiscount")}
            className={`form-control ${errors.percentageDiscount ? "is-invalid" : ""}`}
            defaultValue={!addMode ? data?.percentageDiscount : DefaultPercentageDiscountValue}
          />
          <Form.Control.Feedback type='invalid'>{errors?.percentageDiscount?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
};

const AbsoluteDiscountFormControls = ({
  sourceTransaction,
  register,
  formState,
  addMode,
  data,
}: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;
  return (
    <Row>
      <Col md='6'>
        <Form.Group>
          <Form.Label>{t("labelCurrency")}</Form.Label>
          <Col md='6' xs='3'>
            <Form.Control
              type='text'
              placeholder={t("placeholderCurrency")}
              disabled
              defaultValue={sourceTransaction.currency}
            />
          </Col>
        </Form.Group>
      </Col>
      <Col md='6'>
        <Form.Group>
          <Form.Label>{t("labelAbsoluteDiscount")}</Form.Label>
          <Form.Control
            type='number'
            placeholder={t("placeholderAbsoluteDiscount")}
            {...register("amount")}
            className={`form-control ${errors.amount ? "is-invalid" : ""}`}
            defaultValue={!addMode ? data?.amount : undefined}
          />
          <Form.Control.Feedback type='invalid'>{errors?.amount?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
};

const ReplacementAmountFormControls = ({
  sourceTransaction,
  register,
  formState,
  addMode,
  data,
}: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;
  return (
    <Row>
      <Col md='6'>
        <Form.Group>
          <Form.Label>{t("labelCurrency")}</Form.Label>
          <Form.Control type='text' placeholder={t("placeholderCurrency")} disabled defaultValue={sourceTransaction.currency} />
        </Form.Group>
      </Col>
      <Col md='6'>
        <Form.Group>
          <Form.Label>{t("labelReplacementAmount")}</Form.Label>
          <Form.Control
            type='number'
            placeholder={t("placeholderReplacementAmount")}
            {...register("amount")}
            className={`form-control ${errors.amount ? "is-invalid" : ""}`}
            defaultValue={!addMode ? data?.amount : undefined}
          />
          <Form.Control.Feedback type='invalid'>{errors?.amount?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
};

/*
 * Returns the form controls to use for the particular settlement type
 */
const getSettlementTypeFormControls = (
  type: SettlementType,
  sourceTransaction: Transaction,
  register: UseFormRegister<SettlementOfferFormModel>,
  formState: FormState<SettlementOfferFormModel>,
  addMode: Boolean,
  data: SettlementOffer | undefined
): ReactElement => {
  let formItems: ReactElement;

  switch (type) {
    case SettlementType.PercentageDiscount:
      formItems = (
        <PercentageDiscountFormControls
          sourceTransaction={sourceTransaction}
          register={register}
          formState={formState}
          addMode={addMode}
          data={data}
        />
      );
      break;
    case SettlementType.AbsoluteDiscount:
      formItems = (
        <AbsoluteDiscountFormControls
          sourceTransaction={sourceTransaction}
          register={register}
          formState={formState}
          addMode={addMode}
          data={data}
        />
      );
      break;
    case SettlementType.ReplacementAmount:
      formItems = (
        <ReplacementAmountFormControls
          sourceTransaction={sourceTransaction}
          register={register}
          formState={formState}
          addMode={addMode}
          data={data}
        />
      );
      break;
    default:
      throw Error("Unknown settlement type in settlement offer dialog");
  }
  return formItems;
};

const dateValidationTestName = "Date";
const dateValidationTestErrorMessage = "Valid From must be less than Valid To";

const getSchema = (type: SettlementType, transaction: Transaction): Yup.AnyObjectSchema => {
  const outstandingAmount = getOutstandingAmount(transaction);
  return Yup.object({
    description: Yup.string().label("Description").max(SettlementOfferModelConsts.DescriptionMaxLength),
    validFrom: Yup.date()
      .label("Valid From")
      .nullable()
      .transform((current, original) => (original === "" ? null : current))
      .notRequired(),
    validUntil: Yup.date()
      .label("Valid Until")
      .nullable()
      .transform((current, original) => (original === "" ? null : current))
      .notRequired()
      .test(dateValidationTestName, dateValidationTestErrorMessage, (toDate, items) => {
        const { validFrom }: { validFrom: Date } = items.parent;
        return isGreaterThanOrEqual(validFrom, toDate);
      }),
    percentageDiscount: Yup.lazy(() =>
      type === SettlementType.PercentageDiscount
        ? Yup.number().label("Percentage Discount").required().min(0).max(100)
        : Yup.number().notRequired()
    ),
    amount: Yup.lazy(() =>
      type !== SettlementType.PercentageDiscount
        ? Yup.number().label("Amount").required().min(0).max(outstandingAmount).typeError("An amount is required")
        : Yup.number().notRequired()
    ),
  }).required();
};

export interface SettlementOfferDialogProps extends AddOrEditDialogProps<SettlementOffer, SettlementOfferFormModel> {
  type: SettlementType;
  sourceTransaction: Transaction;
}

/*
 * Add or edit Settlement offer dialog
 */
const SettlementOfferDialog = ({
  type,
  sourceTransaction,
  show,
  data,
  mode,
  onSave,
  onClose,
}: SettlementOfferDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const addMode = mode === "add";

  const { register, handleSubmit, reset, formState } = useForm<SettlementOfferFormModel>({
    resolver: yupResolver(getSchema(type, sourceTransaction)),
  });

  // Reset the model if the data object is difference
  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!show) {
    return <></>;
  }

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSave = (model: SettlementOfferFormModel) => {
    const modelToSave = { ...model };
    modelToSave.description = model.description.trim();
    modelToSave.type = type;
    modelToSave.priority = 0;
    modelToSave.validFrom = convertDatePartToUtcDate(model.validFrom);
    modelToSave.validUntil = convertDatePartToUtcDate(model.validUntil);
    onSave(modelToSave);
    reset();
  };

  const validFromDate = addMode ? undefined : getDataModelDateForDatePicker(data?.validFrom);
  const validUntilDate = addMode
    ? getDataModelDateForDatePicker(sourceTransaction.dueDate ?? undefined)
    : getDataModelDateForDatePicker(data?.validUntil);

  const formItems = getSettlementTypeFormControls(type, sourceTransaction, register, formState, addMode, data);
  const titleType = SettlementTypeMap.get(type) ?? "";
  const { errors } = formState;
  return (
    <>
      <AddOrEditModelDialog
        mode={mode}
        title={t(addMode ? "titleAddSettlementOffer" : "titleEditSettlementOffer", { settlementTypeName: titleType })}
        show={show}
        onHide={handleClose}
        formId='settlementOfferForm'
        size={undefined}
      >
        <Form id='settlementOfferForm' noValidate onSubmit={handleSubmit((submitData) => handleSave(submitData))}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelDescription")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderDescription")}
                  {...register("description")}
                  className={`form-control ${errors.description ? "is-invalid" : ""}`}
                  defaultValue={!addMode ? data?.description : undefined}
                />
                <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <Form.Group>
                <Form.Label>{t("labelValidFrom")}</Form.Label>
                <Form.Control
                  type='date'
                  {...register("validFrom")}
                  className={`form-control ${errors.validFrom ? "is-invalid" : ""}`}
                  defaultValue={validFromDate}
                />
                <Form.Control.Feedback type='invalid'>{errors?.validFrom?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='6'>
              <Form.Group>
                <Form.Label>{t("labelValidUntil")}</Form.Label>
                <Form.Control
                  type='date'
                  {...register("validUntil")}
                  className={`form-control ${errors.validUntil ? "is-invalid" : ""}`}
                  defaultValue={validUntilDate}
                />
                <Form.Control.Feedback type='invalid'>{errors?.validUntil?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {formItems}
        </Form>
      </AddOrEditModelDialog>
    </>
  );
};

export default SettlementOfferDialog;
