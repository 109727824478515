/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { ImportResults, ImportStatusMap } from "../../../models/paymentTransactions/importResults";
import { AllocatedStatusMap } from "../../../models/paymentTransactions/paymentTransactionEnums";
import "./ImportResultsDialog.scss";

const keyPrefix = "paymentTransactionPage.importResultsDialog";

interface ImportResultsDialogProps {
  importResults: ImportResults | undefined;
  show: boolean;
  onHide: () => void;
}

const ImportResultsDialog = ({ importResults, show, onHide }: ImportResultsDialogProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });

  if (!importResults) return <></>;

  return (
    <Modal scrollable size='lg' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='summary-containter'>
          <Table size='sm' borderless className='summary-table'>
            <tbody>
              <tr>
                <td>{t("labelProcessedCount")}</td>
                <td className='item-value'>{importResults.processedCount}</td>
              </tr>
              <tr>
                <td>{t("labelImportedCount")}</td>
                <td className='item-value'>{importResults.importedCount}</td>
              </tr>
              <tr>
                <td>{t("labelNotImportedCount")}</td>
                <td className='item-value'>{importResults.notImportedCount}</td>
              </tr>
              <tr>
                <td>{t("labelDuplicatedCount")}</td>
                <td className='item-value'>{importResults.duplicatedCount}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className='results-containter'>
          <Table size='sm' className='results-table'>
            <thead>
              <tr>
                <td>{t("labelPaymentTransactionId")}</td>
                <td>{t("labelResultStatus")}</td>
                <td>{t("labelImportStatus")}</td>
                <td>{t("labelAllocatedStatus")}</td>
              </tr>
            </thead>
            <tbody>
              {importResults.results.map((result) => (
                <>
                  <tr key={result.id} className={result.message ? "noBorder" : ""}>
                    <td>{result.id}</td>
                    <td>{result.resultStatus}</td>
                    <td className={`import-status ${result.importStatus.toLocaleLowerCase()}`}>
                      {ImportStatusMap.get(result.importStatus)}
                    </td>
                    <td className={`allocated-status ${result.allocatedStatus?.toLocaleLowerCase()}`}>
                      {result.allocatedStatus ? AllocatedStatusMap.get(result.allocatedStatus) : ""}
                    </td>
                  </tr>
                  {result.message && (
                    <tr>
                      <td colSpan={4}>{result.message}</td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t("buttonOk")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportResultsDialog;
