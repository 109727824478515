/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useRef, useState } from "react";
import { Tabs, Tab, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { tForms, tPages } from "../../../i18n";
import AdvancedSettingsTab from "./Advanced/AdvancedSettingsTab";
import GeneralSettingsTab from "./General/GeneralSettingsTab";
import { TabSaveRef } from "./preferencesTypes";
import { toastSuccess } from "../../../services/notifications/toastNotification.service";
import "./SettingsTabs.scss";

const keyPrefix = "preferences";

const createTabTitle = (title: string, hasIssue: boolean) => (
  <div className='tab-title'>
    {title}
    &nbsp;
    {hasIssue && <FontAwesomeIcon className='icon ' icon={faExclamationCircle} />}
  </div>
);

/*
 * The settings tab for general and advanced
 */
const SettingsTab = (): ReactElement => {
  const { t } = useTranslation([tForms.ns], { keyPrefix });
  const [selectedTab, setSelectedTab] = useState<string | null>("general");
  const [saving, setSaving] = useState(false);
  const generalTabRef = useRef<TabSaveRef>(null);
  const advancedTabRef = useRef<TabSaveRef>(null);

  const [generalTabIssue, setGeneralTabIssue] = useState<boolean>(false);
  const [advancedTabIssue, setAdvancedTabIssue] = useState<boolean>(false);

  const handleSaveButton = async () => {
    setSaving(true);
    setGeneralTabIssue(false);
    setAdvancedTabIssue(false);

    try {
      const generalTabResult = await generalTabRef.current?.save();
      setGeneralTabIssue(!generalTabResult);
      const advancedTabResult = await advancedTabRef.current?.save();
      setAdvancedTabIssue(!advancedTabResult);

      if (generalTabResult && advancedTabResult) toastSuccess(t("settingsUpdatedSuccess"));
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey='info'
        id='settings-tab'
        className='mb-2'
        activeKey={selectedTab ?? ""}
        onSelect={(tab) => setSelectedTab(tab)}
      >
        <Tab eventKey='general' title={createTabTitle(t("generalTabTitle"), generalTabIssue)}>
          <GeneralSettingsTab ref={generalTabRef} />
        </Tab>
        <Tab eventKey='advanced' title={createTabTitle(t("advancedTabTitle"), advancedTabIssue)}>
          <AdvancedSettingsTab ref={advancedTabRef} />
        </Tab>
      </Tabs>
      <Row>
        <Col>
          <SpinnerButton title={t("buttonSave")} showSpinner={saving} onClick={handleSaveButton} />
        </Col>
      </Row>
    </>
  );
};

export default SettingsTab;
