import { ReactElement } from "react";
import { Card, ListGroup, Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { MainRouteConsts } from "../MainRoutesConsts";
import "./PaymentTransactionsInfo.scss";
import useDateFormatter from "../../common/hooks/useDateFormatter";

const PaymentInfo = ({
  paymentTransaction,
  isSuccess,
}: {
  paymentTransaction: PaymentTransaction;
  isSuccess: boolean;
}): ReactElement => {
  const { customerName } = paymentTransaction.paymentRequest;
  const transactionClass = isSuccess ? "content-transaction-success" : "content-transaction-failed";
  const dateFormatter = useDateFormatter();

  return (
    <div className='payment-item'>
      <div className='payment-item-content'>
        <div className='content-left'>
          <div className='content-customer-name'>{customerName}</div>
          <div className='content-date-time'>{dateFormatter.dateTimeOrString(paymentTransaction.statusDateTime)}</div>
        </div>
        <div className='content-right'>
          <div className={transactionClass}>
            <CurrencyItem currency={paymentTransaction.currency} value={paymentTransaction.totalAmount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PaymentTransactionsInfo = ({
  transactions,
  isSuccess,
}: {
  transactions: PaymentTransaction[];
  isSuccess: boolean;
}): ReactElement => {
  const { t } = useTranslation("forms");

  if (transactions.length === 0) {
    return <div>{t(isSuccess ? "dashboardNoSuccessfulPayments" : "dashboardNoFailedPayments")}</div>;
  }

  const listItems = transactions.map((transaction) => (
    <ListGroup.Item key={transaction.id} action href={`${MainRouteConsts.PaymentTransactions}/${transaction.id}`}>
      <PaymentInfo paymentTransaction={transaction} isSuccess={isSuccess} />
    </ListGroup.Item>
  ));
  return <ListGroup variant='flush'>{listItems}</ListGroup>;
};

/*
 * Shows a placeholder for payment transactions
 */
export const PaymentTransactionInfoPlaceholder = (
  <>
    <Placeholder as={Card.Text} animation='glow'>
      <Placeholder xs={12} />
      <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={6} /> <Placeholder xs={8} />
    </Placeholder>
  </>
);
