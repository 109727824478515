import { ReactElement } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faFileAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import UserDetails from "./UserDetails";
import UserInitials from "./UserInitials";
import { MainRouteConsts } from "../../views/MainRoutesConsts";
import LocationProvider from "../../common/providers/locationProvider";
import { useAuth } from "../../context/auth/AuthContext";
import { tCommon } from "../../i18n";

type UserNavDropDownProps = {
  name?: string;
  email: string;
};

const UserNavDropDown = ({ name, email }: UserNavDropDownProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns);
  const { authService } = useAuth();

  const usersName = name ?? email;
  const navDropdownTitle = <UserInitials displayName={usersName} />;

  const logout = async () => {
    const locationProvider = new LocationProvider();
    const redirectUrl = await authService.logout();
    locationProvider.replace(redirectUrl);
  };

  return (
    <NavDropdown id='userprofile-nav-dropdown' title={navDropdownTitle} align='end'>
      <NavDropdown.Header>
        <UserDetails displayName={usersName} email={email} showEmail showDisplayName />
      </NavDropdown.Header>
      <NavDropdown.Divider />
      <NavDropdown.Item href='#'>
        <FontAwesomeIcon icon={faFileAlt} />
        {t("termsOfService")}
      </NavDropdown.Item>
      <NavDropdown.Item href='#'>
        <FontAwesomeIcon icon={faFileAlt} />
        {t("privacyPolicy")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item href={MainRouteConsts.UserSettings}>
        <FontAwesomeIcon icon={faUser} />
        {t("mySettings")}
      </NavDropdown.Item>
      <NavDropdown.Item onClick={logout}>
        <FontAwesomeIcon icon={faSignOutAlt} />
        {t("signOut")}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

UserNavDropDown.defaultProps = {
  name: undefined,
};

export default UserNavDropDown;
