import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { tPages } from "../../../i18n";
import UserNotificationsTab from "./UserNotificationsTab";
import CustomerNotificationsTab from "./CustomerNotificationsTab";

const keyPrefix = "notificationsPage";

/*
 * Displays the user and customer notifications tabs
 */
const NotificationsTabs = (): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  return (
    <Tabs id='notifications-tab' className='mb-2'>
      <Tab eventKey='user' title={t("userTabTitle")}>
        <UserNotificationsTab />
      </Tab>
      <Tab eventKey='customer' title={t("customerTabTitle")}>
        <CustomerNotificationsTab />
      </Tab>
    </Tabs>
  );
};

export default NotificationsTabs;
