/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Form } from "react-bootstrap";
import { AddOrEditMode } from "../ProvidersCommon/Common";
import { CurrentPaymentProviderConfiguration, CurrentFormModel } from "./trueLayerConfiguration";
import { tPaymentProviders } from "../../../../i18n";

const keyPrefix = "trueLayerForm";

const DefaultPayeesReference = "{{accountReference}}";
const DefaultPayersReference = "{{myCompanyName}}";

interface TrueLayerBankSettingsTabProps {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;

  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

/*
 * Bank settings tab for TrueLayer
 */
const TrueLayerBankSettingsTab = ({ mode, data, register, errors }: TrueLayerBankSettingsTabProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  const addMode = mode === "add";
  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelAccountNumber")}</Form.Label>
                <Form.Control
                  type='number'
                  placeholder={t("placeHolderAccountNumber")}
                  {...register("accountNumber")}
                  className={`form-control ${errors?.accountNumber?.message ? "is-invalid" : ""}`}
                  defaultValue={data?.settings?.beneficiaryBankAccount?.accountNumber}
                />
                <Form.Control.Feedback type='invalid'>{errors?.accountNumber?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelBeneficiaryName")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderBeneficiaryName")}
                  {...register("beneficiaryName")}
                  className={`form-control ${errors?.beneficiaryName?.message ? "is-invalid" : ""}`}
                  defaultValue={data?.settings?.beneficiaryName}
                />
                <Form.Control.Feedback type='invalid'>{errors?.beneficiaryName?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelSortCode")}</Form.Label>
                <Form.Control
                  type='number'
                  placeholder={t("placeholderSortCode")}
                  {...register("sortCode")}
                  className={`form-control ${errors?.sortCode?.message ? "is-invalid" : ""}`}
                  defaultValue={data?.settings?.beneficiaryBankAccount?.sortCode}
                />
                <Form.Control.Feedback type='invalid'>{errors?.sortCode?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelBeneficiaryReference")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderBeneficiaryReference")}
                  {...register("beneficiaryReference")}
                  className={`form-control ${errors?.beneficiaryReference?.message ? "is-invalid" : ""}`}
                  defaultValue={addMode ? DefaultPayeesReference : data?.settings?.beneficiaryReference}
                />
                <Form.Control.Feedback type='invalid'>{errors?.beneficiaryReference?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelRemitterReference")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderRemitterReference")}
                  {...register("remitterReference")}
                  className={`form-control ${errors?.remitterReference?.message ? "is-invalid" : ""}`}
                  defaultValue={addMode ? DefaultPayersReference : data?.settings?.remitterReference}
                />
                <Form.Control.Feedback type='invalid'>{errors?.remitterReference?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

TrueLayerBankSettingsTab.defaultProps = {
  data: undefined,
};

export default TrueLayerBankSettingsTab;
