import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { MainRouteConsts } from "../MainRoutesConsts";
import { DefaultBlankText } from "../../common/commonConsts";

interface PaymentTransactionLinkProps {
  id: string | undefined;
  children?: ReactNode;
}

/*
 * Link to a payment transaction
 */
const PaymentTransactionLink = ({ id, children }: PaymentTransactionLinkProps): ReactElement =>
  id ? <Link to={`${MainRouteConsts.PaymentTransactions}/${id}`}>{children ?? id}</Link> : <>{DefaultBlankText}</>;

PaymentTransactionLink.defaultProps = {
  children: undefined,
};

export default PaymentTransactionLink;
