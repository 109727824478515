export enum PaymentRequestStatus {
  Open = "Open",
  Completed = "Completed",
  PartCompleted = "PartCompleted",
  Cancelled = "Cancelled",
  Expired = "Expired",
  Pending = "Pending",
}

export const PaymentRequestStatusMap = new Map<PaymentRequestStatus, string>([
  [PaymentRequestStatus.Open, "Open"],
  [PaymentRequestStatus.Completed, "Completed"],
  [PaymentRequestStatus.PartCompleted, "Part Completed"],
  [PaymentRequestStatus.Cancelled, "Cancelled"],
  [PaymentRequestStatus.Expired, "Expired"],
  [PaymentRequestStatus.Pending, "Pending"],
]);

export const PaymentRequestStatusArray = Array.from(PaymentRequestStatusMap.entries());
