export enum AccountEditPaymentStatus {
  Default = "Default",
  Allow = "Allow",
  Disable = "Disable",
}

export enum EditPaymentOverrideStatus {
  UseAccountStatus = "UseAccountStatus",
  UsePaymentRequest = "UsePaymentRequest",
}
