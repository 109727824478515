import { isRunningInIframe } from "../../utils/httpUtilities";
import { WebsiteView } from "./WebsiteView";

/*
 * Returns true if the websiteview allows parent redirects
 */
export const isAllowParentRedirect = (wv: WebsiteView) => isRunningInIframe() && wv.allowParentRedirects && wv.parentUrl;

/*
 * Returns true if the websiteview has a parent url
 */
export const hasParentUrl = (wv: WebsiteView) => isRunningInIframe() && wv.parentUrl;
