/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { UseFormRegister, FieldValues, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Form } from "react-bootstrap";
import { tPaymentProviders, tForms } from "../../../../i18n";
import { CurrentPaymentProviderConfiguration } from "./totalProcessingConfiguration";
import BooleanFormSelect from "../ProvidersCommon/BooleanFormSelect";
import {
  AvailableCardBrandsMap,
  TotalProcessingPaymentBrand,
} from "../../../../models/paymentProviders/totalProcessing/totalProcessingModels";

const keyPrefix = "totalProcessingForm";

interface TotalProcessingOptionsTabProps {
  data?: CurrentPaymentProviderConfiguration;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

/*
 * Options tab for Total Processing
 */
const TotalProcessingOptionsTab = ({ data, register, watch }: TotalProcessingOptionsTabProps): ReactElement => {
  const { t } = useTranslation([tPaymentProviders.ns, tForms.ns], { keyPrefix });

  const isBrandSelected = (brand: TotalProcessingPaymentBrand): boolean => {
    if (!data) return brand.defaultEnabled;
    return data?.settings?.brands?.includes(brand.key);
  };

  const defaultStorePayerPaymentMethod = data?.settings ? data?.settings.storePayerPaymentMethod : true;
  const defaultSaveCardOptionDefault = data?.settings ? data?.settings.saveCardOptionDefault : true;
  const watchtStorePayerPaymentMethod = watch("storePayerPaymentMethod", defaultStorePayerPaymentMethod) as boolean;

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <Card.Title>{t("titlePaymentMethods")}</Card.Title>
            <Row>
              {AvailableCardBrandsMap.filter((x) => x.primary)
                .slice()
                .sort((a, b) => a.priority - b.priority)
                .map((cardBrand) => (
                  <Col sm={3} key={cardBrand.key}>
                    <Form.Check
                      size={10}
                      inline
                      type='checkbox'
                      id={cardBrand.key}
                      label={t(`brands.${cardBrand.name}`)}
                      {...register(`brands.${cardBrand.key}`)}
                      defaultChecked={isBrandSelected(cardBrand)}
                    />
                  </Col>
                ))}
            </Row>
            <hr />
            <p>{t("messageNonPrimaryBrands")}</p>
            <Row>
              {AvailableCardBrandsMap.filter((x) => x.primary === false)
                .slice()
                .sort((a, b) => a.priority - b.priority)
                .map((cardBrand) => (
                  <Col sm={3} key={cardBrand.key}>
                    <Form.Check
                      size={10}
                      inline
                      type='checkbox'
                      id={cardBrand.key}
                      label={t(`brands.${cardBrand.name}`)}
                      {...register(`brands.${cardBrand.key}`)}
                      defaultChecked={isBrandSelected(cardBrand)}
                    />
                  </Col>
                ))}
            </Row>
          </Card.Body>
        </Card>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Row>
              <Form.Group as={Col} md='3'>
                <Form.Label>{t("labelStoreCardDetails")}</Form.Label>
                <Col md='1'>
                  <BooleanFormSelect
                    register={register}
                    fieldName='storePayerPaymentMethod'
                    trueOption={t("labelEnabledOption")}
                    falseOption={t("labelDisabledOption")}
                    defaultState={defaultStorePayerPaymentMethod}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col} md='3'>
                <Form.Label>{t("labelSaveCardOptionDefault")}</Form.Label>
                <Col md='1'>
                  <BooleanFormSelect
                    register={register}
                    fieldName='saveCardOptionDefault'
                    trueOption={t("labelTickedOption")}
                    falseOption={t("labelUntickedOption")}
                    defaultState={defaultSaveCardOptionDefault}
                    disabled={!watchtStorePayerPaymentMethod}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

TotalProcessingOptionsTab.defaultProps = {
  data: undefined,
};

export default TotalProcessingOptionsTab;
