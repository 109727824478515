/* eslint-disable react/jsx-no-undef */
import { ReactElement, useState } from "react";
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import byteSize from "byte-size";
import { DateOutputType } from "../../utils/dateFormatter";
import { DefaultBlankDateText } from "../viewCommon";
import { IconButton } from "../../components/icons/IconButton";
import { tForms, tPages } from "../../i18n";
import { Document } from "../../models/documents/document";
import { DeleteItemConfirmation } from "../../components/icons/DeleteItemConfirmation";
import PdfViewDialog from "./PdfViewModel";
import MediaContentTypes from "../../common/mediaTypeConsts";
import { downloadFile } from "../../utils/downloadFile";
import UserPermission from "../../models/users/userPermission";
import useDateFormatter from "../../common/hooks/useDateFormatter";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "documentsTab";
const customUnits = {
  computer: [
    { from: 0, to: 1e3, unit: "B" },
    { from: 1e3, to: 1e6, unit: "KB", long: "kilobytes" },
    { from: 1e6, to: 1e9, unit: "MB", long: "megabytes" },
    { from: 1e9, to: 1e12, unit: "GB", long: "gigabytes" },
    { from: 1e12, to: 1e15, unit: "TB", long: "terabytes" },
  ],
};

interface DocumentTableProbs {
  documents: Document[];
  onDeleteDocument: (documentId: string) => Promise<void>;
  onGetDocumentData: (documentId: string) => Promise<File | undefined>;
}

const DocumentTable = ({ documents, onDeleteDocument, onGetDocumentData }: DocumentTableProbs): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const { t: tBase } = useTranslation([tForms.ns]);
  const [show, setShow] = useState(false);
  const [documentData, setDocumentData] = useState<File>();
  const disabledDelete = !usePermission(UserPermission.DeleteTransactionDocument);
  const dateFormatter = useDateFormatter();

  const handleDelete = (documentId: string) => {
    onDeleteDocument(documentId);
  };

  const handleViewDocument = async (documentId: string) => {
    const data = await onGetDocumentData(documentId);
    if (data?.type === MediaContentTypes.ApplicationPdf) {
      setDocumentData(data);
      setShow(true);
    } else if (data) {
      downloadFile(data);
    }
  };

  return (
    <>
      <Table borderless className='panel-table'>
        <thead>
          <tr>
            <td width='120'>{t("tableColumnCreatedOn")}</td>
            <td width='100'>{t("tableColumnFileType")}</td>
            <td>{t("tableColumnFilename")}</td>
            <td align='right'>{t("tableColumnFileSize")}</td>
            <td align='right'>{t("tableColumnAction")}</td>
          </tr>
        </thead>
        <tbody>
          {documents
            .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
            .map((document) => {
              const docType = document.fileType.toLocaleUpperCase();
              const isPdf = docType === "PDF";
              return (
                <tr key={document.id}>
                  <td> {dateFormatter.dateTimeOrString(document.createdOn, DefaultBlankDateText, DateOutputType.ShortDate)}</td>
                  <td>{docType}</td>
                  <td>{document.fileName}</td>
                  <td align='right'>{byteSize(document.fileSize, { customUnits, units: "computer" }).toString()}</td>
                  <td align='right'>
                    <IconButton
                      title={tBase(isPdf ? "buttonView" : "buttonDownload")}
                      icon={isPdf ? faEye : faFileDownload}
                      onClick={() => handleViewDocument(document.id)}
                    />
                    <DeleteItemConfirmation<string>
                      item={document.id}
                      disabled={disabledDelete}
                      onDelete={handleDelete}
                      body={t("confirmDeleteMessage", { documentName: document.fileName })}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <PdfViewDialog show={show} documentData={documentData} onClose={() => setShow(false)} />
    </>
  );
};
export default DocumentTable;
