import { ReactElement, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import PaymentTransactionInfoTab from "./PaymentTransactionInfoTab";
import PaymentAllocationsTab from "../common/PaymentAllocations/PaymentAllocationsTab";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import LatePaymentsTab from "../common/LatePayments/LatePaymentsTab";
import { CreateLatePaymentFeesTitle } from "../common/LatePayments/LatePaymentsHelper";
import { CreatePaymentAllocationsTitle } from "../common/PaymentAllocations/PaymentAllocationHelper";

const keyPrefix = "paymentTransactionPage";

const PaymentTransactionTabs = ({
  paymentTransaction,
  refreshData,
}: {
  paymentTransaction: PaymentTransaction;
  refreshData: () => {};
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const [selectedTab, setSelectedTab] = useState<string | null>("info");

  const setTab = (tab: string | null) => {
    setSelectedTab(tab);
  };

  return (
    <Tabs
      defaultActiveKey='info'
      id='account-tab'
      className='mb-2'
      activeKey={selectedTab ?? ""}
      onSelect={(tab) => setTab(tab)}
    >
      <Tab eventKey='info' title={t("infoTabTitle")}>
        <PaymentTransactionInfoTab paymentTransaction={paymentTransaction} refreshData={refreshData} />
      </Tab>
      <Tab eventKey='paymentAllocations' title={CreatePaymentAllocationsTitle(paymentTransaction.paymentAllocations)}>
        <PaymentAllocationsTab
          paymentAllocations={paymentTransaction.paymentAllocations}
          currency={paymentTransaction.currency}
          mode='entityView'
        />
      </Tab>
      <Tab eventKey='latePayments' title={CreateLatePaymentFeesTitle(paymentTransaction.paymentFeeTransactions)}>
        <LatePaymentsTab latePaymentTransactions={paymentTransaction.paymentFeeTransactions} mode='transaction' />
      </Tab>
    </Tabs>
  );
};

export default PaymentTransactionTabs;
