import { ReactElement } from "react";
import Loading from "./Loading";

interface LoadingProps {
  message?: string;
}

/*
 * Just route to the loading component
 */
const PageLoading = ({ message }: LoadingProps): ReactElement => <Loading message={message} />;

PageLoading.defaultProps = {
  message: "Loading",
};

export default PageLoading;
