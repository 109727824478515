import { ReactElement, useState } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import { BasePaymentRequestTabProps } from "./common";
import PaymentRequestInfoTab from "./PaymentRequestInfoTab";
import PaymentsTab from "./PaymentsTab";
import TransactionsTab from "./TransactionsTab";
import { RequestTypes } from "../../models/paymentRequests/requestTypes";

const keyPrefix = "paymentRequestPage";

/*
 * Tabs
 */
const PaymentRequestTabs = ({ paymentRequest, onUpdate }: BasePaymentRequestTabProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const [selectedTab, setSelectedTab] = useState<string | null>("info");

  const paymentTransactionsTitle = (
    <>
      {t("paymentTransactionsTabTitle")}
      &nbsp;
      <Badge className='tab-badge'>{paymentRequest ? paymentRequest.paymentTransactions.length.toString() : "0"}</Badge>
    </>
  );

  const transactionsTitle = (
    <>
      {t("transactionsTabTitle")}
      &nbsp;
      <Badge className='tab-badge'>{paymentRequest ? paymentRequest.transactions.length.toString() : "0"}</Badge>
    </>
  );

  const setTab = (tab: string | null) => {
    setSelectedTab(tab);
  };

  const isInvoiceType = paymentRequest.requestType === RequestTypes.Transaction;

  return (
    <Tabs
      defaultActiveKey='info'
      id='account-tab'
      className='mb-2'
      activeKey={selectedTab ?? ""}
      onSelect={(tab) => setTab(tab)}
    >
      <Tab eventKey='info' title={t("infoTabTitle")}>
        <PaymentRequestInfoTab paymentRequest={paymentRequest} onUpdate={onUpdate} />
      </Tab>
      {isInvoiceType && (
        <Tab eventKey='transactions' title={transactionsTitle}>
          <TransactionsTab paymentRequest={paymentRequest} transactions={paymentRequest.transactions} />
        </Tab>
      )}
      <Tab eventKey='paymentTransactions' title={paymentTransactionsTitle}>
        <PaymentsTab paymentTransactions={paymentRequest.paymentTransactions} />
      </Tab>
    </Tabs>
  );
};

PaymentRequestTabs.defaultProps = {
  onUpdate: undefined,
};

export default PaymentRequestTabs;
