import { ReactElement, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";
import "./PdfViewModel.scss";

interface PdfViewDialogProps {
  documentData: File | undefined;
  show: boolean;
  onClose: () => void;
}

/*
 * Display the PDF in a modal dialog
 */
const PdfViewDialog = ({ show, onClose, documentData }: PdfViewDialogProps): ReactElement => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [title, setTitle] = useState("");
  const { t } = useTranslation(tForms.ns);

  useEffect(() => {
    if (documentData) {
      const url = URL.createObjectURL(documentData);
      setPdfUrl(url);
      setTitle(documentData.name);
    } else {
      setPdfUrl("");
      setTitle("");
    }
  }, [documentData]);

  const handleClose = () => {
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl("");
    }
    onClose();
  };
  return (
    <Modal dialogClassName='pdf-view' size='xl' show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {pdfUrl ? (
        <embed src={pdfUrl} className='pdf-document-view' />
      ) : (
        <div className='d-flex justify-content-center loading-document'>
          <Spinner className='float-right' animation='border' role='status' />
        </div>
      )}
      <Modal.Footer>
        <Button variant='primary' onClick={handleClose}>
          {t("buttonClose")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PdfViewDialog;
