import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { HtmlTitle } from "../../common/HtmlTitle";
import SvixPortalPage from "./SvixPortalPage";
import "./WebHooksPage.scss";

/*
 * Page for showing web hook configuration and status
 * Currently only shows the Svix portal
 */
const WebHooksPage = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleWebHooks")} />
      <SvixPortalPage />
    </>
  );
};

export default WebHooksPage;
