/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { PaymentRequestContact } from "../../../models/paymentRequests/paymentRequest";

import { tForms, tPages } from "../../../i18n";
import { DialogSecondaryButton, DialogPrimaryButton } from "../../../components/dialogs/DialogButtons";
import FormLabelOptional from "../../../components/forms/FormLabel";

const keyPrefix = "paymentRequestPage.contactEditDialog";

export interface EditFormModel {
  firstNames?: string;
  surname?: string;
  companyName?: string;
  emailAddress?: string;
  type?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
}

// Our validation schema
const validationSchema = Yup.object({
  firstNames: Yup.string().notRequired().max(255),
  surname: Yup.string().notRequired().max(255),
  companyName: Yup.string().notRequired().max(500),
  emailAddress: Yup.string().notRequired().max(1000),
  type: Yup.string().notRequired().max(255),
  address1: Yup.string().notRequired().max(255),
  address2: Yup.string().notRequired().max(255),
  address3: Yup.string().notRequired().max(255),
  address4: Yup.string().notRequired().max(255),
  city: Yup.string().notRequired().max(255),
  state: Yup.string().notRequired().max(255),
  postCode: Yup.string().notRequired().max(255),
  country: Yup.string().notRequired().max(255),
}).required();

interface EditContactDialogProps {
  title: string;
  contact: PaymentRequestContact | undefined;
  show: boolean;
  onSave: (model: EditFormModel) => void;
  onClose: () => void;
}

/*
 * Edit contact dialog
 */
const EditContactDialog = ({ title, contact, show, onSave, onClose }: EditContactDialogProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const { t: tF } = useTranslation(tForms.ns);
  const [enableComponent, setEnableComponent] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditFormModel>({ resolver: yupResolver(validationSchema) });

  // We do this so that the modal is created and show
  useEffect(() => {
    if (show) {
      reset();
      setEnableComponent(true);
    }
  }, [reset, show]);

  const handleSave = (saveData: EditFormModel) => {
    onSave(saveData);
  };

  // We do this to destory the modal component, which resets all the dialog settings
  const handleExited = () => {
    setEnableComponent(false);
  };

  if (!enableComponent) return <></>;

  return (
    <Modal size='lg' show={show} onHide={() => onClose()} onExited={handleExited}>
      <Modal.Header closeButton>
        <Modal.Title>{t(title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id='editContactDetailsForm'
          className='dialog-form'
          noValidate
          onSubmit={handleSubmit((submitData) => handleSave(submitData))}
        >
          <div className='dialog-card'>
            <Row>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelCompanyName")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderCompanyName")}
                    {...register("companyName")}
                    className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
                    defaultValue={contact?.companyName}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.companyName?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelFirstName")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderFirstName")}
                    {...register("firstNames")}
                    className={`form-control ${errors.firstNames ? "is-invalid" : ""}`}
                    defaultValue={contact?.firstNames}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.firstNames?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelSurname")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderSurname")}
                    {...register("surname")}
                    className={`form-control ${errors.surname ? "is-invalid" : ""}`}
                    defaultValue={contact?.surname}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.surname?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelEmailAddress")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderEmailAddress")}
                    {...register("emailAddress")}
                    className={`form-control ${errors.emailAddress ? "is-invalid" : ""}`}
                    defaultValue={contact?.emailAddress}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.emailAddress?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='dialog-card last'>
            <Row>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelAddress1")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderAddress1")}
                    {...register("address1")}
                    className={`form-control ${errors.address1 ? "is-invalid" : ""}`}
                    defaultValue={contact?.address?.address1}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.firstNames?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelAddress2")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderAddress2")}
                    {...register("address2")}
                    className={`form-control ${errors.surname ? "is-invalid" : ""}`}
                    defaultValue={contact?.address?.address2}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.surname?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelCity")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderCity")}
                    {...register("city")}
                    className={`form-control ${errors.city ? "is-invalid" : ""}`}
                    defaultValue={contact?.address?.city}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.city?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelState")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderState")}
                    {...register("state")}
                    className={`form-control ${errors.state ? "is-invalid" : ""}`}
                    defaultValue={contact?.address?.state}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.state}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelPostCode")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderPostCode")}
                    {...register("postCode")}
                    className={`form-control ${errors.postCode ? "is-invalid" : ""}`}
                    defaultValue={contact?.address?.postCode}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.postCode?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <FormLabelOptional label={t("labelCountry")} />
                  <Form.Control
                    type='text'
                    placeholder={t("placeHolderCountry")}
                    {...register("country")}
                    className={`form-control ${errors.country ? "is-invalid" : ""}`}
                    defaultValue={contact?.address?.country}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.country?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>{" "}
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={() => onClose()} />
        <DialogPrimaryButton form='editContactDetailsForm' type='submit' label={tF("buttonUpdate")} />
      </Modal.Footer>
    </Modal>
  );
};

export default EditContactDialog;
