import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentRequest, TransactionAllocationInfo } from "../../../models/paymentRequests/paymentRequest";
import { EditItemButton } from "../../../components/buttons/CommonIconButtons";
import { tPages } from "../../../i18n";
import EditTransactionAllocationInfoDialog, { EditFormModel } from "./EditTransactionAllocationInfoDialog";
import { findValidTransactionAllocationInfo } from "../../../helpers/paymentRequests/paymentRequestHelper";
import { updateTransactionAllocationInfo } from "../../../services/paymentRequests/paymentRequests";
import { PaymentRequestStatus } from "../../../models/paymentRequests/paymentRequestStatus";
import { Transaction } from "../../../models/transactions/transaction";

const keyPrefix = "paymentRequestPage.transactionEditDialog";

interface EditTransactionAllocationInfoButtonProps {
  paymentRequest: PaymentRequest;
  transactionAllocationInfo: TransactionAllocationInfo;
  transaction: Transaction;
  onUpdate?: () => Promise<void>;
}

/*
 * Button to edit the transaction allocation info
 */
const EditTransactionAllocationInfoButton = ({
  paymentRequest,
  transactionAllocationInfo,
  transaction,
  onUpdate,
}: EditTransactionAllocationInfoButtonProps): ReactElement => {
  const [show, setShow] = useState<boolean>(false);
  const [enableDialog, setEnableDialog] = useState<boolean>(false);
  const { t } = useTranslation(tPages.ns, { keyPrefix });

  const handleUpdate = async (model: EditFormModel) => {
    const result = findValidTransactionAllocationInfo(transactionAllocationInfo.transactionId, paymentRequest);

    // Update the transaction info
    result.amount = model.amount;
    result.required = model.required;
    result.canEditAmount = model.canEditAmount;
    result.minimumAmount = model.minimumAmount;
    result.maximumAmount = model.maximumAmount;

    await updateTransactionAllocationInfo(paymentRequest.id, paymentRequest.transactionAllocations);

    setShow(false);
    if (onUpdate) await onUpdate();
  };

  const status = paymentRequest.requestStatus === PaymentRequestStatus.Open;

  const handleShow = () => {
    setShow(status);
    setEnableDialog(status);
  };

  return (
    <>
      <EditItemButton
        disabled={!status}
        onClick={handleShow}
        title={t(status ? "titleEditTransactionAllocationInfo" : "titleCannotEditPaymentRequest")}
      />
      {enableDialog && (
        <EditTransactionAllocationInfoDialog
          transactionAllocationInfo={transactionAllocationInfo}
          transaction={transaction}
          show={show}
          onClose={() => setShow(false)}
          onClosed={() => setEnableDialog(false)}
          onSave={(model) => handleUpdate(model)}
        />
      )}
    </>
  );
};

EditTransactionAllocationInfoButton.defaultProps = {
  onUpdate: undefined,
};

export default EditTransactionAllocationInfoButton;
