import { SettlementConfiguration } from "../../../models/settlements/settlementConfiguration";
import { SettlementConfigurationGroup } from "../../../models/settlements/settlementConfigurationGroup";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";

class SettlementConfigurationsApi extends BasePayThemApi {
  private endPoint = "settlement-configuration-groups";

  private configurationEndPoint = "configurations";

  /*
   * Returns a single settlement configuration group
   */
  public async getSettlementConfigurationGroup(id: string): Promise<SettlementConfigurationGroup> {
    const result = await this.getItem<SettlementConfigurationGroup, string>(this.endPoint, id);
    return result;
  }

  /*
   * Returns a page of settlement configuration groups
   */
  public async getSettlementConfigurationGroups(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string
  ): Promise<PageResult<SettlementConfigurationGroup>> {
    const result = await this.getPage<SettlementConfigurationGroup>(this.endPoint, page, itemsPerPage, sort, filter);
    return result;
  }

  /*
   * Adds a new settlement configuration group
   */
  public async addSettlementConfigurationGroup(item: SettlementConfigurationGroup): Promise<SettlementConfigurationGroup> {
    const result = await this.postWithResponse<SettlementConfigurationGroup, SettlementConfigurationGroup>(this.endPoint, item);
    return result;
  }

  /*
   * Updates an existing settlement configuration group
   */
  public async updateSettlementConfigurationGroup(item: SettlementConfigurationGroup): Promise<SettlementConfigurationGroup> {
    const result = await this.putWithResponse<SettlementConfigurationGroup, SettlementConfigurationGroup>(this.endPoint, item);
    return result;
  }

  /*
   * Deletes an existing settlement configuration group
   */
  public async deleteSettlementConfigurationGroup(groupId: string): Promise<void> {
    await this.deleteItem(this.endPoint, groupId);
  }

  private buildSettlementConfigurationUrl(groupId: string): string {
    const url = `${this.endPoint}/${groupId}/${this.configurationEndPoint}`;
    return url;
  }

  /*
   * Adds a new settlement configuration to a settlement configuration group
   */
  public async addSettlementConfigurationToSettlementConfigurationGroup(
    groupId: string,
    item: SettlementConfiguration
  ): Promise<SettlementConfigurationGroup> {
    const url = this.buildSettlementConfigurationUrl(groupId);
    const result = await this.postWithResponse<SettlementConfiguration, SettlementConfigurationGroup>(url, item);
    return result;
  }

  /*
   * Updates an existing settlement configuration configuration associated to a settlement configuration group
   */
  public async updateSettlementConfiguration(
    groupId: string,
    item: SettlementConfiguration
  ): Promise<SettlementConfigurationGroup> {
    const url = this.buildSettlementConfigurationUrl(groupId);
    const result = await this.putWithResponse<SettlementConfiguration, SettlementConfigurationGroup>(url, item);
    return result;
  }

  /*
   * Deletes an existing settlement configuration from a settlement configuration group
   */
  public async deleteSettlementConfiguration(groupId: string, configurationId: string): Promise<void> {
    const url = this.buildSettlementConfigurationUrl(groupId);
    await this.deleteItem(url, configurationId);
  }
}

export default SettlementConfigurationsApi;
