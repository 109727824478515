/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEventHandler, ReactElement } from "react";
import { Form } from "react-bootstrap";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";

interface BooleanFormSelectProps<TFormModel extends FieldValues> {
  register: UseFormRegister<TFormModel>;
  fieldName: Path<TFormModel>;
  label?: string;
  trueOption: string;
  falseOption: string;
  defaultState?: boolean;
  disabled?: boolean;
  reverseOrder?: boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement> | undefined;
}

/*
 * The payment provider enabled check box
 */
const BooleanFormSelect = <TFormModel extends FieldValues>({
  register,
  defaultState,
  fieldName,
  label,
  trueOption,
  falseOption,
  disabled,
  reverseOrder,
  onChange,
}: BooleanFormSelectProps<TFormModel>): ReactElement => {
  const defaultValue = defaultState ?? true;

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        disabled={disabled}
        {...register(fieldName, {
          setValueAs: (v) => {
            if (v === "true") return true;
            return false;
          },
          onChange: (e) => onChange?.(e),
        })}
        defaultValue={defaultValue.toString()}
      >
        {reverseOrder ? (
          <>
            <option value='false'>{falseOption}</option>
            <option value='true'>{trueOption}</option>
          </>
        ) : (
          <>
            <option value='true'>{trueOption}</option>
            <option value='false'>{falseOption}</option>
          </>
        )}
      </Form.Select>
    </>
  );
};

BooleanFormSelect.defaultProps = {
  defaultState: true,
  label: undefined,
  disabled: false,
  reverseOrder: false,
  onChange: undefined,
};

export default BooleanFormSelect;
