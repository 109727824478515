import { ChangeEvent, ReactElement } from "react";
import { ListGroup, Form, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { NotificationItem } from "./NotificationItems";

const keyPrefix = "notificationsPage";

interface NotificationItemSwitchListProps {
  notificationItems: NotificationItem[];
  onNotificationChanged: (notificationName: string, enabled: boolean) => Promise<void>;
}

/*
 * Switch list for turning notifications on and off
 */
const NotificationSwitchList = ({
  notificationItems,
  onNotificationChanged,
}: NotificationItemSwitchListProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });

  // Handles a notification check box changing
  const handleNotificationCheck = async (e: ChangeEvent<HTMLInputElement>, notificationName: string): Promise<void> => {
    const isEnabled = e.target.checked;
    await onNotificationChanged(notificationName, isEnabled);
  };

  return (
    <>
      {notificationItems?.map((item) => (
        <ListGroup.Item key={item.notificationName} className='d-flex justify-content-start'>
          <Form.Check
            type='switch'
            checked={item.enabled}
            onChange={(e) => handleNotificationCheck(e, item.notificationName)}
          />
          <Col>
            <div className='toggle-label'>{t(item.displayName)}</div>
            <div className='small'>{t(item.description)}</div>
          </Col>
        </ListGroup.Item>
      ))}
    </>
  );
};

export default NotificationSwitchList;
