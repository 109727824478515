/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Payer } from "../../../models/payers/payer";
import { PanelItem } from "../../../components/panels/Panels";
import { CustomerFormatter } from "../../../utils/customerFormatter";
import { textOrDefault } from "../../../helpers/stringHelpers";
import { Address } from "../../../models/contacts/address";
import { CopyToClipboard } from "../../../components/icons/CopyToClipboard";
import { tPages } from "../../../i18n";

const keyPrefix = "payerPage.details";

interface PayerInfoTabProps {
  payer: Payer;
}

// eslint-disable-next-line arrow-body-style
const PayerInfoTab = ({ payer }: PayerInfoTabProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const { details } = payer;
  const address = CustomerFormatter.fullAddressToString({
    address1: details.address1,
    address2: details.address2,
    address3: details.address3,
    address4: details.address4,
    city: details.city,
    state: details.state,
    postCode: details.postCode,
    country: details.country,
  } as Address);

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Card.Title className='details-title'>
              <div>{t("title")}</div>
            </Card.Title>
            <Card.Text as='div'>
              <Row>
                <Col md={12} lg={12}>
                  <div className='main-item'>
                    <div className='content'>{details.companyName}</div>
                  </div>
                  <PanelItem title={t("fullName")}>
                    {CustomerFormatter.getFullName(details.firstNames, details.surname)}
                  </PanelItem>
                  <PanelItem title={t("emailAddress")}>
                    {textOrDefault(details.emailAddress)}
                    {details.emailAddress && <CopyToClipboard text={details.emailAddress} />}
                  </PanelItem>
                  <PanelItem title={t("address")}>
                    {textOrDefault(address)}
                    {address && <CopyToClipboard text={address} />}
                  </PanelItem>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PayerInfoTab;
