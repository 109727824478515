import { ReactElement } from "react";
import useWebsiteView from "../context/websiteView/WebsiteViewContext";
import { SidePanelMenu } from "./sidePanel/SidePanelMenu";
import { useAuth } from "../context/auth/AuthContext";

/*
 * Left hand side menu for the application
 */
const LeftHandSide = (): ReactElement => {
  const wv = useWebsiteView();
  const { authService } = useAuth();

  // Disable the menu if the user is not logged in or if the menu is disabled by the website view option
  if (wv?.removeMenu === true || authService.isPending()) {
    return <></>;
  }

  return (
    <div className='d-none d-md-block'>
      <SidePanelMenu />
    </div>
  );
};
export default LeftHandSide;
