import { ReactElement, useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import lang from "i18next";
import { useTranslation } from "react-i18next";
import CursorPageResult from "../../common/api/CursorPageResult";
import PageLoading from "../../components/loading/PageLoading";
import LogEntry from "../../models/logging/logEntry";
import { HtmlTitle } from "../common/HtmlTitle";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { tPages } from "../../i18n";
import { TypeColumnRender } from "../../components/grid/DataGrid";
import { toSentence } from "../../utils/stringUtils";
import { DateItem } from "../../components/grid/DateItem";
import { DateOutputType } from "../../utils/dateFormatter";
import { AuditLogsApi } from "../../common/api/payThemApi/auditLogsApi";

type ActivityScope = "Dataset" | "Tenant";

const defaultColumns = [
  {
    name: "operation",
    header: lang.t("activitiesList.columnAction", tPages),
    defaultFlex: 3,
    render: ({ data }: TypeColumnRender<LogEntry>) =>
      toSentence(data.operation),
  },
  {
    name: "userName",
    header: lang.t("activitiesList.columnUser", tPages),
    defaultFlex: 1,
  },
  {
    name: "timestamp",
    header: lang.t("activitiesList.columnTimestamp", tPages),
    defaultFlex: 1,
    render: ({ data }: TypeColumnRender<LogEntry>) => (
      <DateItem
        date={data.timestamp}
        outputType={DateOutputType.ShortDateTime}
      />
    ),
  },
  {
    name: "status",
    header: lang.t("activitiesList.columnStatus", tPages),
    defaultFlex: 1,
  },
  {
    name: "ipAddress",
    header: lang.t("activitiesList.columnIpAddress", tPages),
    defaultFlex: 1,
  },
];

const defaultGridStyle = {
  minHeight: 650,
  minWidth: 800,
};

const PageSize = 15;
const cursors: (string | null)[] = [];
const api = new AuditLogsApi();

interface ActivitiesListProps {
  scope: ActivityScope;
}

/**
 * Activity data grid
 */
const ActivityList = ({ scope }: ActivitiesListProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(tPages.ns);
  const [dataSource, setDataSource] = useState<
    CursorPageResult<LogEntry> | undefined
  >(undefined);

  const getSafeResponse = (
    result: CursorPageResult<LogEntry>
  ): CursorPageResult<LogEntry> => {
    const json = JSON.stringify(result);
    const response = JSON.parse(json);
    return response;
  };

  const loadResults = (cursor: string | null): void => {
    setLoading(true);
    if (scope === "Tenant") {
      api.getRecentTenantActivities(cursor, PageSize).then((response) => {
        setDataSource(getSafeResponse(response));
        setLoading(false);
      });
    } else {
      api.getRecentActivities(cursor, PageSize).then((response) => {
        setDataSource(getSafeResponse(response));
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (scope === "Tenant") {
      api.getRecentTenantActivities(null, PageSize).then((response) => {
        setDataSource(getSafeResponse(response));
      });
    } else {
      api.getRecentActivities(null, PageSize).then((response) => {
        setDataSource(getSafeResponse(response));
      });
    }
  }, [scope]);

  return (
    <>
      <HtmlTitle subTitle1={t("activitiesList.title", tPages)} />
      {dataSource !== undefined ? (
        <div className="activity-list">
          <ReactDataGrid
            idProperty="id"
            columns={defaultColumns}
            dataSource={dataSource.items}
            style={defaultGridStyle}
            loading={loading}
            defaultLimit={PageSize}
          />
          <div className="pt-2">
            <SpinnerButton
              className="order-1"
              title={t("activitiesList.buttonPrevious", tPages)}
              disabled={cursors.length === 0}
              showSpinner={false}
              onClick={() => {
                cursors.pop();
                loadResults(cursors[cursors.length - 1]);
              }}
            />
            <span className="p-1" />
            <SpinnerButton
              className="order-2"
              title={t("activitiesList.buttonNext", tPages)}
              disabled={!dataSource.next}
              showSpinner={false}
              onClick={() => {
                cursors.push(dataSource.next);
                loadResults(dataSource.next);
              }}
            />
          </div>
        </div>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default ActivityList;
