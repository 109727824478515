import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { ProSidebar, SidebarHeader, SidebarContent, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuEntries, MenuEntry } from "../menuItems";
import { tCommon } from "../../i18n";
import "./SidePanelMenu.scss";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "sideMenu";

interface SidePanelMenuItemProps {
  entry: MenuEntry;
  index: number;
}

const SidePanelMenuItem = ({ entry, index }: SidePanelMenuItemProps): ReactElement => {    
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const location = useLocation();
  const features = useFeatures();
  const hasPermission = usePermission(entry.requiredPermissions);

  if (entry.subEntries) {
    return (
      <SubMenu key={index} popperarrow title={t(entry.name)} icon={entry.icon && <FontAwesomeIcon icon={entry.icon} />}>
        {entry.subEntries.map((subEntry, subIndex) => <SidePanelMenuItem key={subEntry.name} entry={subEntry} index={subIndex} />)}
      </SubMenu>
    );
  }

  if (entry.featureFlagName && !features[entry.featureFlagName]) {
    return <></>;
  }

  if (!hasPermission) {
    return <></>;
  }

  return (
    <MenuItem
      key={index}
      icon={entry.icon && <FontAwesomeIcon icon={entry.icon} />}
      className={location.pathname === entry.link ? "active" : ""}
    >
      {t(entry.name)}
      {entry.link && <Link to={entry.link} />}
    </MenuItem>
  );
};

/*
 Renders the side menu
*/
export const SidePanelMenu = (): ReactElement => (
    <ProSidebar collapsed={false}>
      <SidebarHeader />
      <SidebarContent>
        <Menu iconShape='circle' popperArrow>
          {MenuEntries.map((entry, index) => <SidePanelMenuItem key={entry.name} entry={entry} index={index} />)}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );

export default SidePanelMenu;
