import ItemFilterConfiguration from "./itemFilterConfiguration";
import ItemFilteringType from "./itemFilteringType";
import TransactionAmountFilterType from "./transactionAmountFilterType";

interface TransactionAmountFilterConfiguration extends ItemFilterConfiguration {
  amountType: TransactionAmountFilterType;
  minimumTransactionValue?: number;
  maximumTransactionValue?: number;
}

export const initTransactionAmountFilterConfiguration = (
  amountType: TransactionAmountFilterType,
  minimumTransactionValue?: number,
  maximumTransactionValue?: number
): TransactionAmountFilterConfiguration => {
  const configuration: TransactionAmountFilterConfiguration = {
    filterType: ItemFilteringType.TransactionAmount,
    isEnabled: true,
    amountType,
    minimumTransactionValue,
    maximumTransactionValue,
  };
  return configuration;
};

export default TransactionAmountFilterConfiguration;
