export enum Role {
  Administrator = "Administrator",
  Standard = "Standard",
  Viewer = "Viewer"
}

export const RoleMap = new Map<Role, string>([
  [Role.Administrator, "Administrator"],
  [Role.Standard, "Standard"],
  [Role.Viewer, "Viewer"]
]);

export const RoleArray = Array.from(RoleMap.entries());
