export interface IncludeOptions {}

/*
 * Builds any expand string from the include options
 */
export const buildExpandParametersString = (options: IncludeOptions | undefined): string | undefined => {
  if (!options) return undefined;

  // Only select boolean properties that have been set to true
  const includeProperties = Object.entries(options)
    .filter(([, v]) => typeof v === "boolean" && v === true)
    .map(([k]) => k.toLowerCase());

  return includeProperties.join(",");
};

/*
 * Builds any expand string from the include options and returns a URLSearchParams object
 */
export const buildExpandParameter = (options: IncludeOptions | undefined, currentParams?: URLSearchParams): URLSearchParams => {
  const params = currentParams || new URLSearchParams();
  const expandParameter = buildExpandParametersString(options);
  if (expandParameter) params.append("expand", expandParameter);
  return params;
};
