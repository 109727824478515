import { TypeGuard } from "./typeGuards";

export const toSnakeCase = (str: string): string =>
  str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();

const toUrlFormat = (key: string, val: string): string => `${encodeURIComponent(toSnakeCase(key))}=${encodeURIComponent(val)}`;

export const toUrlEncoded = (obj: Record<string, unknown>): string =>
  Object.keys(obj)
    .map((k) => {
      const value = obj[k];
      if (TypeGuard.isString(value)) {
        return toUrlFormat(k, value);
      }
      if (TypeGuard.isNumber(value)) {
        return toUrlFormat(k, value.toString());
      }
      if (TypeGuard.isBigInt(value)) {
        return toUrlFormat(k, value.toString());
      }
      if (TypeGuard.isBoolean(value)) {
        return toUrlFormat(k, value.toString());
      }
      throw new Error("Invalid Data Type.");
    })
    .join("&");

export class UrlUtils {
  /*
   *  Get the current url
   */
  public static getUrl(): string {
    return window.location.href;
  }

  /*
   *  Get the current absolute url
   */
  public static getAbsoluteUrl(): string {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  }

  /*
   * Get the current relative url e.g. "/path1/path2"
   */
  public static getRelativeUrl(): string {
    return window.location.pathname.replace(/\/$/, "");
  }

  /*
   * Get the domain url from the given windo, e.g "https://subdomain.domain.com"
   */
  public static getAbsoluteDomainUrlFromWindow(win: Window): string {
    return `${win.location.protocol}//${win.location.host}`;
  }

  /*
   * Get the current domain url, e.g "https://subdomain.domain.com"
   */
  public static getAbsoluteDomainUrl(): string {
    return UrlUtils.getAbsoluteDomainUrlFromWindow(window);
  }

  /*
   * Get the composed url with path
   */
  public static getComposedUrlWithPath(path:string): string {    
    return `${UrlUtils.getAbsoluteDomainUrlFromWindow(window)}/${path}`;
  }
}
