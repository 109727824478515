export enum PaymentAllocationType {
  // A payment has been closed and cannot be changed
  Closed = "Closed",

  // A payment that is still being processed (e.g. a bank transfer). This could be completed, fail or be cancelled
  Processing = "Processing",

  // A payment has been reserved (on a card) but not yet paid. This could be completed, fail or be cancelled
  Reserved = "Reserved",

  // A payment has been scheduled to be paid in the future (e.g. Direct Debit), but not yet paid. This could be completed or cancelled
  Scheduled = "Scheduled",
}

export const PaymentAllocationTypeMap = new Map<PaymentAllocationType, string>([
  [PaymentAllocationType.Closed, "Closed"],
  [PaymentAllocationType.Processing, "Processing"],
  [PaymentAllocationType.Reserved, "Reserved"],
  [PaymentAllocationType.Scheduled, "Scheduled"],
]);

export const PaymentAllocationTypeArray = Array.from(PaymentAllocationTypeMap.entries());
