import { ReactElement, useCallback, useState } from "react";
import { TypeColumn, TypeSingleFilterValue } from "@inovua/reactdatagrid-community/types";
import { Col, Row } from "react-bootstrap";
import { Account } from "../../../models/accounts/account";
import { PaymentRequest } from "../../../models/paymentRequests/paymentRequest";

import { DataGridReadyItem, LoadDataProps, loadPageData } from "../../../components/grid/dataGridHelpers";
import PaymentRequestGrid, { DefaultSortColumnName, DefaultSortDirection } from "../../PaymentRequests/Grid/PaymentRequestGrid";
import { PaymentRequestsApi } from "../../../common/api/payThemApi/paymentRequestsApi";
import {
  optionsColumn,
  paymentCaptureTypeColumn,
  paymentRequestAmountToPayColumn,
  paymentRequestCreatedOnColumn,
  paymentRequestStatusColumn,
  paymentRequestTypeColumn,
} from "../../PaymentRequests/Grid/GridColumns";
import "./PaymentRequestsTab.scss";
import AddPaymentRequestButton from "./AddPaymentRequestButton";

/*
 * Default standard column list
 */
const GridColumns: TypeColumn[] = [
  paymentRequestTypeColumn,
  paymentRequestCreatedOnColumn,
  paymentRequestAmountToPayColumn,
  paymentCaptureTypeColumn,
  paymentRequestStatusColumn,
  optionsColumn,
];

interface PaymentRequestsTabProps {
  account: Account; // The account to show payment requests for
}

/*
 * The payment requests list for an account
 */
const PaymentRequestsTab = ({ account }: PaymentRequestsTabProps): ReactElement => {
  const [gridRef, setGridRef] = useState<DataGridReadyItem>();

  // Use our own loadData source which filters by account Id
  const loadData = async ({
    skip,
    limit,
    sortInfo,
    filterValue,
  }: LoadDataProps): Promise<{ data: PaymentRequest[]; count: number }> => {
    const accountFilter = { name: "accountId", operator: "eq", type: "string", value: account.id } as TypeSingleFilterValue;
    const extendedFilters = filterValue ? [...filterValue, accountFilter] : [accountFilter];

    return loadPageData(
      skip,
      limit,
      sortInfo,
      DefaultSortColumnName,
      DefaultSortDirection,
      extendedFilters,
      async (pageParameters) => {
        const api = new PaymentRequestsApi();
        const result = await api.getPaymentRequests(
          pageParameters.page,
          pageParameters.itemsPerPage,
          pageParameters.sort,
          pageParameters.filter
        );
        return result;
      }
    );
  };
  const dataSource = useCallback(loadData, [account]);
  return (
    <>
      <Row>
        <Col className='panel-column'>
          <PaymentRequestGrid columns={GridColumns} dataSource={dataSource} onReady={setGridRef} />
        </Col>
      </Row>
      <div className='buttons'>
        <AddPaymentRequestButton account={account} onAdd={async () => gridRef?.current?.reload()} />
      </div>
    </>
  );
};

export default PaymentRequestsTab;
