import { getDatasetIdFromStorage } from "../../helpers/storageHelpers";
import { StripeRedirectMessage, StripeRedirectMessageId } from "./websiteMessages";

class PayThemMessageFactory {
  public static createStripeRedirectMessage(stripeRedirectUrl: string, payThemRedirectUrl: string): StripeRedirectMessage {
    const message = {
      id: StripeRedirectMessageId,
      dataset: getDatasetIdFromStorage(),
      data: {
        stripeRedirectUrl,
        payThemRedirectUrl,
      },
    } as StripeRedirectMessage;

    return message;
  }
}

export default PayThemMessageFactory;
