import { ReactElement, useCallback } from "react";
import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import lang from "i18next";
import { useTranslation } from "react-i18next";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { DataGrid, TypeColumnRender } from "../../components/grid/DataGrid";
import {
  SortAscending,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  BaseOptionsColumn,
  defaultDataGridPagination,
} from "../../components/grid/dataGridHelpers";
import { Account } from "../../models/accounts/account";
import { PageTitle } from "../common/PageTitle";
import { AccountsApi } from "../../common/api/payThemApi/accountsApi";
import { ViewItemButton } from "../../components/buttons/CommonIconButtons";
import { MainRouteConsts } from "../MainRoutesConsts";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";
import GridNamesConsts from "../GridNamesConsts";
import PageLoading from "../../components/loading/PageLoading";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";

const LangOptions = tPages;

const columns: TypeColumn[] = [
  {
    name: "accountReference",
    header: lang.t("accountsList.columAccountReference", LangOptions),
    minWidth: 200,
    defaultFlex: 1,
  },
  {
    name: "name",
    header: lang.t("accountsList.columName", LangOptions),
    minWidth: 200,
    defaultFlex: 1,
  },
  {
    name: "balance",
    header: lang.t("accountsList.columBalance", LangOptions),
    textAlign: "end",
    minWidth: 100,
    defaultFlex: 1,
    filterEditor: NumberFilter,
    render: ({ data }: TypeColumnRender<Account>) => <CurrencyItem currency={data.currency} value={data.balance} />,
  },
  {
    name: "creditLimit",
    header: lang.t("accountsList.columCreditLimit", LangOptions),
    textAlign: "end",
    minWidth: 100,
    defaultFlex: 1,
    filterEditor: NumberFilter,
    render: ({ data }: TypeColumnRender<Account>) => <CurrencyItem currency={data.currency} value={data.creditLimit} />,
  },
  {
    ...BaseOptionsColumn,
    render: ({ data }: TypeColumnRender<Account>) => <ViewItemButton route={MainRouteConsts.Accounts} id={data.id} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [
  { name: "accountReference", operator: "contains", type: "string", value: "" },
  { name: "name", type: "string", operator: "contains", value: "" },
  // { name: "balance", operator: "gte", type: "number", value: "" },
  // { name: "creditLimit", operator: "eq", type: "number", value: "" },
];

/*
 * Default sort column
 */
const DefaultSortColumnName = "accountReference";
const DefaultSortDirection = SortAscending;

const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

/*
 * Callback function for loading in a page of data
 */
const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps): Promise<LoadDataResult<Account>> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, filterValue, async (pageParameters) => {
    const api = new AccountsApi();
    const result = await api.getAccounts(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter
    );
    return result;
  });

/*
 * Our grid name
 */
const GridName = GridNamesConsts.AccountGrid;

/*
 * The list
 */
export const AccountList = (): ReactElement => {
  const dataSource = useCallback(loadData, []);
  const { t } = useTranslation(tPages.ns);
  const { dataGridState, setFilters, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleAccounts", tPages)} />
      {dataGridState ? (
        <>
          <PageTitle title='pageTitleAccounts' />
          <section className='data-grid-page'>
            <DataGrid<Account>
              idProperty='id'
              columns={columns}
              dataSource={dataSource}
              defaultFilterValue={dataGridState.filters}
              enableFiltering
              onFilterValueChange={(filterValue) => setFilters(filterValue)}
              defaultSortInfo={dataGridState.sortInfo}
              onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
              defaultSkip={dataGridState.pagination?.skip}
              onSkipChange={(skip) => setPaginationSkip(skip)}
              defaultLimit={dataGridState.pagination?.limit}
              onLimitChange={(limit) => setPaginationLimit(limit)}
            />
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default AccountList;
