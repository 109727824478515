/* eslint-disable import/prefer-default-export */
import { Transaction } from "../../models/transactions/transaction";

/*
 * Returns the outstanding amount remaining on the transaction
 */
export const getOutstandingAmount = (transaction: Transaction): number => {
  // TODO Take into account paymentAllocationAmount amd allocatedAmount
  let result = transaction.transactionAmount - transaction.paymentAllocationAmount;
  if (transaction.discountedAmount) {
    result -= transaction.discountedAmount;
  }
  return result;
};

/*
 * Returns the allocated amount on the transaction
 */
// eslint-disable-next-line arrow-body-style
export const getAllocatedAmount = (transaction: Transaction): number => {
  // TODO Take into account paymentAllocationAmount amd allocatedAmount
  return transaction.paymentAllocationAmount;
};
