/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Form } from "react-bootstrap";
import {
  CurrentFormModel,
  CurrentPaymentMethodTypes,
  CurrentPaymentProviderConfiguration,
} from "./goCardlessConfiguration";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import {
  CommonProviderFormControls,
  ProviderFilterFormControl,
  ProviderSupportedMethodsFormControl,
  ProviderSupportedCurrenciesFormControl,
} from "../ProvidersCommon/CommonControls";
import GoCardlessCreditorDetails from "./GoCardlessCreditorDetails";

interface GoCardlessGeneralSettingsTabProps {
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  itemFilterGroup: ItemFilterGroup | undefined;
  onShowFilterDialog: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

const GoCardlessGeneralSettingsTab = ({
  data,
  paymentProvider,
  itemFilterGroup,
  onShowFilterDialog,
  register,
  errors,
}: GoCardlessGeneralSettingsTabProps): ReactElement => {
  const [currencies, setCurrencies] = useState<string[]>([]);

  useEffect(() => {
    // Show supported currencies from each payment method
    if (paymentProvider !== undefined) {
      const temp = [] as Array<string>;
      paymentProvider.paymentMethodTypesInfo.forEach((x) => {
        (x.supportedCurrencies ?? []).forEach((currency) => {
          if (temp.indexOf(currency) === -1) {
            temp.push(currency);
          }
        });
      });
      setCurrencies(temp);
    }
  }, [paymentProvider]);

  return (
    <>
      <Card className="custom-detail-card mb-3">
        <Card.Body>
          <CommonProviderFormControls
            data={data}
            register={register}
            errors={errors}
          />
          <hr />
          <Row className="mb-2">
            <Col>
              <ProviderFilterFormControl
                filterGroup={itemFilterGroup}
                onClick={onShowFilterDialog}
              />
            </Col>
            <ProviderSupportedMethodsFormControl
              supportedPaymentMethodGroups={
                paymentProvider?.supportedPaymentMethodGroups
              }
            />

            <Form.Group as={Col} sm="6">
              {" "}
            </Form.Group>
            <ProviderSupportedCurrenciesFormControl
              supportedCurrencies={currencies}
            />
          </Row>
        </Card.Body>
      </Card>
      <GoCardlessCreditorDetails paymentProvider={data} />
    </>
  );
};

GoCardlessGeneralSettingsTab.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default GoCardlessGeneralSettingsTab;
