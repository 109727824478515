import {
  IconDefinition,
  faHome,
  faFileInvoiceDollar,
  faFileInvoice,
  faUsers,
  faCog,
  faPiggyBank,
  faClock,
  faPoundSign,
  faPercent,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import FeatureFlagConsts from "../FeatureFlagConsts";
import { MainRouteConsts } from "../views/MainRoutesConsts";
import { SettingsRouteConsts } from "../views/SettingsRouteConsts";
import UserPermission from "../models/users/userPermission";

export interface MenuEntry {
  name: string;
  link?: string;
  icon?: IconDefinition;
  subEntries?: MenuEntry[];
  featureFlagName?: string;
  requiredPermissions: UserPermission[];
}

// Menu items used to build nav or side menu panels
export const MenuEntries: MenuEntry[] = [
  {
    name: "home",
    link: MainRouteConsts.Home,
    icon: faHome,
    requiredPermissions: []
  },
  {
    name: "paymentRequests",
    link: MainRouteConsts.PaymentRequests,
    icon: faFileInvoiceDollar,
    requiredPermissions: []
  },
  {
    name: "paymentTransactions",
    link: MainRouteConsts.PaymentTransactions,
    icon: faFileInvoiceDollar,
    requiredPermissions: []
  },
  {
    name: "accounts",
    link: MainRouteConsts.Accounts,
    icon: faUsers,
    requiredPermissions: []
  },
  {
    name: "transactions",
    link: MainRouteConsts.Transactions,
    icon: faFileInvoice,
    requiredPermissions: []
  },
  {
    name: "banks",
    link: MainRouteConsts.Banks,
    icon: faPiggyBank,
    requiredPermissions: []
  },
  {
    name: "settlements",
    link: MainRouteConsts.Settlements,
    icon: faPercent,
    requiredPermissions: []
  },
  {
    name: "latePayments",
    link: MainRouteConsts.LatePayments,
    icon: faClock,
    requiredPermissions: []
  },
  {
    name: "funding",
    link: MainRouteConsts.Funding,
    icon: faPoundSign,
    featureFlagName: FeatureFlagConsts.Capitalise,
    requiredPermissions: []
  },
  {
    name: "activities",
    link: MainRouteConsts.Activities,
    icon: faUserClock,
    requiredPermissions: [UserPermission.ViewAuditLogs]
  },
  {
    name: "settings",
    icon: faCog,
    subEntries: [
      {
        name: "companyDetails",
        link: SettingsRouteConsts.Details,
        requiredPermissions: []
      },
      {
        name: "customisation",
        link: SettingsRouteConsts.Customisation,
        requiredPermissions: [UserPermission.ConfigureSystemSettings]
      },
      {
        name: "preferences", link:
          SettingsRouteConsts.Preferences,
        requiredPermissions: [UserPermission.ConfigureSystemSettings]
      },
      {
        name: "paymentProviders",
        link: SettingsRouteConsts.PaymentProviders,
        requiredPermissions: [UserPermission.ConfigurePaymentProvider, UserPermission.DeletePaymentProvider]
      },
      {
        name: "notifications",
        link: SettingsRouteConsts.Notifications,
        requiredPermissions: [UserPermission.ConfigureSystemSettings]
      },
      {
        name: "accessKeys",
        link: SettingsRouteConsts.AccessKeys,
        requiredPermissions: [UserPermission.ManageSubscriptionKeys]
      },
      {
        name: "users",
        link: SettingsRouteConsts.Users,
        requiredPermissions: [UserPermission.ConfigureSystemSettings]
      },
      {
        name: "companies",
        link: SettingsRouteConsts.Datasets,
        requiredPermissions: []
      },
      {
        name: "webHooks",
        link: SettingsRouteConsts.WebHooks,
        requiredPermissions: [UserPermission.ConfigureSystemSettings]
      },
    ],
    requiredPermissions: []
  },
];
