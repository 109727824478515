import lang from "i18next";
import { PaymentTransactionsApi } from "../../common/api/payThemApi/paymentTransactionsApi";
import { tCommon } from "../../i18n";
import ExportOptions from "../../models/paymentTransactions/exportOptions";
import { ImportResults } from "../../models/paymentTransactions/importResults";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { AllocatedStatus } from "../../models/paymentTransactions/paymentTransactionEnums";
import { toastError, toastSuccess } from "../notifications/toastNotification.service";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";
import { PaymentTransactionIncludeOptions } from "../../common/api/payThemApi/paymentTransactionIncludeOptions";

const LangOptions = tCommon;

/*
 * Returns recent successful payment transactions
 */
export async function getRecentSuccessfulPayments(numberOfItems = 10): Promise<PaymentTransaction[]> {
  try {
    const api = new PaymentTransactionsApi();
    const includeOptions = { paymentRequest: true } as PaymentTransactionIncludeOptions;
    const result = await api.getPaymentTransactions(1, numberOfItems, "-StatusDateTime", "Status eq Paid", includeOptions);
    return result.items;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.paymentTransactionsGet");
    throw error;
  }
}

/*
 * Returns recent failed payment transactions
 */
export async function getRecentFailedPayments(numberOfItems = 10): Promise<PaymentTransaction[]> {
  try {
    const api = new PaymentTransactionsApi();
    const result = await api.getFailedStatusPaymentTransactions(1, numberOfItems, "-StatusDateTime");
    return result.items;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.paymentTransactionsGet");
    throw error;
  }
}

/*
 * Exports the payment transactions as a CSV file
 */
export async function exportPaymentTransactions(options: ExportOptions): Promise<File> {
  try {
    const api = new PaymentTransactionsApi();
    const result = await api.exportPaymentTransactions(options);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.export");
    throw error;
  }
}

/*
 * Imports the payment transaction allocation statuses
 */
export const importAllocationStatus = async (file: File): Promise<ImportResults> => {
  try {
    const api = new PaymentTransactionsApi();
    const result = await api.importAllocationStatus(file);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.import");
    throw error;
  }
};

/*
 * Updates the allocated status of the payment transaction
 */
export const updateAllocatedStatus = async (id: string, status: AllocatedStatus): Promise<void> => {
  try {
    const api = new PaymentTransactionsApi();
    await api.updateAllocatedStatus(id, status);
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.allocatedStatusUpdate");
    throw error;
  }
};

/*
 * Captures a pending payment transaction
 */
export const capturePaymentTransaction = async (id: string): Promise<void> => {
  try {
    const api = new PaymentTransactionsApi();
    const result = await api.capturePaymentTransaction(id);
    if (result.isError) {
      toastError(result.errorMessage);
    } else {
      toastSuccess(lang.t("toastMessages.paymentTransactions.capturePendingSuccess", LangOptions));
    }
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.capturePending");
  }
};

/*
 * Cancels a pending payment transaction
 */
export const cancelPaymentTransaction = async (id: string): Promise<void> => {
  try {
    const api = new PaymentTransactionsApi();
    const result = await api.cancelPaymentTransaction(id);
    if (result.isError) {
      toastError(result.errorMessage);
    } else {
      toastSuccess(lang.t("toastMessages.paymentTransactions.cancelPendingSuccess", LangOptions));
    }
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentTransactions.cancelPending");
  }
};

/*
 * Returns the number of payment transactions matching the given filter
 */
export const countPaymentTransactions = async (filter: string): Promise<number> => {
  try {
    const api = new PaymentTransactionsApi();
    const result = await api.getPaymentTransactionCount(filter);
    return result.totalItems;
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    throw error;
  }
};
