import axios, { AxiosError } from "axios";
import { Dataset } from "../../../models/datasets/dataset";
import { RegisterDataset } from "../../../models/datasets/registerDataset";
import { HostSystem } from "../../../models/datasets/hostSystem";

import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";
import { CurrencyInfo, getCurrencyCodes } from "../../../utils/currencyCodeUtils";

const supportedBaseCurrencies: string[] = ["GBP", "USD", "EUR", "CAD", "SGD", "THB", "ZAR"];

export class DatasetsApi extends BasePayThemApi {
  private endPoint = "data-sets";

  /*
   * Checks if a dataset exists by id, returning true if it does
   */
  public async existsById(id: number): Promise<boolean> {
    const url = `${this.endPoint}/${id}/exists`;
    const inst = this.getInstance();
    const result = await inst
      .get(url)
      .then(() => true)
      // eslint-disable-next-line consistent-return
      .catch((error: Error | AxiosError) => {
        // return false if the dataset doesn't exist (404 not found)
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          return false;
        }
        this.processHttpError(error);
      });
    return result;
  }

  // Return a dataset
  public async getDatasetById(id: number): Promise<Dataset> {
    const result = await this.getItem<Dataset, number>(this.endPoint, id);
    return result;
  }

  // Returns a page of datasets
  public async getDatasets(page: number, itemsPerPage: number, sort?: string, filter?: string): Promise<PageResult<Dataset>> {
    const result = await this.getPage<Dataset>(this.endPoint, page, itemsPerPage, sort, filter);
    return result;
  }

  // Returns all of the datasets
  public async getAllDatasets(): Promise<PageResult<Dataset>> {
    const result = await this.get<PageResult<Dataset>>(`${this.endPoint}/all`);
    return result;
  }

  // Registers a new dataset
  public async registerDataset(datasetDetails: RegisterDataset): Promise<Dataset> {
    const result = await this.postWithResponse<RegisterDataset, Dataset>(this.endPoint, datasetDetails);
    return result;
  }

  // Updates the dataset
  public async updateDataset(dataset: Dataset): Promise<Dataset> {
    const result = await this.putWithResponse<Dataset, Dataset>(this.endPoint, dataset);
    return result;
  }

  // Deletes the dataset with the id
  public async deleteDataset(id: number): Promise<void> {
    await this.deleteItem(this.endPoint, id);
  }

  // Checks if a dataset with the given id exists
  public async datasetExists(id: number): Promise<boolean> {
    const url = `${this.endPoint}/${id}/exists`;
    const result = await this.get<boolean>(url);
    return result;
  }

  /*
   * Informs the API to reset the dataset with the given id
   */
  public async resetDataset(id: number): Promise<void> {
    const url = `${this.endPoint}/${id}/reset`;
    await this.post(url, null);
  }

  /*
   * Returns array of available host systems
   */
  public async getHostSystems(): Promise<HostSystem[]> {
    const url = `${this.endPoint}/host-systems`;
    const result = await this.get<HostSystem[]>(url);
    return result;
  }

  /*
   * Returns an array of currencies we support for base datasets
   */
  // eslint-disable-next-line class-methods-use-this
  public async getSupportedBaseCurrencies(): Promise<CurrencyInfo[]> {
    const allCurrencies = getCurrencyCodes();

    const ci = supportedBaseCurrencies.map((baseCurrency) => {
      const foundCurrency = allCurrencies.find((x) => x.iso4217 === baseCurrency);
      if (!foundCurrency) throw Error(`Invalid currency ${baseCurrency}`);
      return foundCurrency;
    });
    return ci;
  }
}
export default DatasetsApi;
