import ConnectResult from "../../../models/paymentProviders/goCardless/connectResult";
import CreditorInfo from "../../../models/paymentProviders/goCardless/creditorInfo";
import BasePayThemApi from "./basePayThemApi";

class GoCardlessWebApi extends BasePayThemApi {
  private endPoint = "gocardless";

  /*
   * Completes the authorisation and returns the payment provider id
   */
  public async completeAuthorisation(code: string): Promise<ConnectResult> {
    const params = new URLSearchParams();
    params.append("code", code);
    const result = await this.postWithResponse<undefined, ConnectResult>(
      `${this.endPoint}/authorise/complete?${params.toString()}`,
      undefined
    );
    return result;
  }

  /*
   * Returns information on the creditor associated to the payment provider
   */
  public async getCreditorInfoFromProvider(paymentProviderId: string): Promise<CreditorInfo> {
    const result = await this.get<CreditorInfo>(`${this.endPoint}/payment-provider/${paymentProviderId}/creditor`);
    return result;
  }
}

export default GoCardlessWebApi;
