import lang from "i18next";
import { TodoResult } from "./todoResult";
import { SeverityLevel } from "../../common/severityLevel";
import { SettingsRouteConsts } from "../../views/SettingsRouteConsts";
import { tTodos } from "../../i18n";
import UserPermission from "../../models/users/userPermission";
import { SubscriptionKeysApi } from "../../common/api/payThemApi/subscriptionKeysApi";

/*
 * Creates any required subscription todos
 */
export async function subscriptionKeysTodo(): Promise<TodoResult[]> {
  const api = new SubscriptionKeysApi();
  const count = await api.countKeys();
  const results: TodoResult[] = [];
  if (count === 0) {
    results.push({
      severity: SeverityLevel.Informational,
      title: lang.t("todos.accessKeys.titleCreateAccessKeys", tTodos),
      message: lang.t("todos.accessKeys.messageCreateAccessKeys", tTodos),
      url: SettingsRouteConsts.AccessKeys,
      requiredPermissionToFix: UserPermission.ManageSubscriptionKeys
    });
  }
  return results;
}
export default subscriptionKeysTodo;
