import { ReactElement } from "react";
import lang from "i18next";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { Transaction } from "../../../models/transactions/transaction";
import { ViewItemButton } from "../../../components/buttons/CommonIconButtons";
import { MainRouteConsts } from "../../MainRoutesConsts";
import { tPages } from "../../../i18n";
import { PaidStatusArray, TransactionTypeArray } from "../../../models/transactions/transactionEnums";
import { TypeColumnRender } from "../../../components/grid/DataGrid";
import { DateItem } from "../../../components/grid/DateItem";
import { DateOutputType } from "../../../utils/dateFormatter";
import { CurrencyItem } from "../../../components/misc/CurrencyItem";
import OverdueDate from "../../../components/OverdueDate/OverdueDate";
import { DefaultBlankDateText } from "../../viewCommon";
import { PaidStatusTag } from "../PaidStatusTag";
import { BaseOptionsColumn } from "../../../components/grid/dataGridHelpers";

const LangOptions = tPages;

const ColumnButtons = ({ transaction }: { transaction: Transaction }): ReactElement => (
  <ViewItemButton route={MainRouteConsts.Transactions} id={transaction.id} />
);

export const TransactionReferenceColumn: TypeColumn = {
  name: "transactionReference",
  header: lang.t("transactionsList.columTransactionReference", LangOptions),
  minWidth: 100,
  defaultFlex: 1,
};
export const TransactionTypeColumn: TypeColumn = {
  name: "transactionType",
  header: lang.t("transactionsList.columTransactionType", LangOptions),
  minWidth: 100,
  defaultFlex: 1,
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: TransactionTypeArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
};
export const TransactionDateColumn: TypeColumn = {
  name: "transactionDate",
  header: lang.t("transactionsList.columTransactionDate", LangOptions),
  type: "number",
  minWidth: 100,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<Transaction>) => (
    <DateItem date={data.transactionDate} outputType={DateOutputType.ShortDate} />
  ),
};
export const CustomerNameColumn: TypeColumn = {
  name: "account.name",
  header: lang.t("transactionsList.columAccountName", LangOptions),
  minWidth: 200,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<Transaction>) => <div>{data.account?.name}</div>,
};
export const TransactionAmountColumn: TypeColumn = {
  name: "transactionAmount",
  header: lang.t("transactionsList.columTransactionAmount", LangOptions),
  type: "number",
  textAlign: "end",
  minWidth: 50,
  defaultFlex: 1,
  filterEditor: NumberFilter,
  render: ({ data }: TypeColumnRender<Transaction>) => <CurrencyItem currency={data.currency} value={data.transactionAmount} />,
};
export const DueDateColumn: TypeColumn = {
  name: "dueDate",
  header: lang.t("transactionsList.columDueDate", LangOptions),
  type: "number",
  minWidth: 50,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<Transaction>) => (
    <OverdueDate date={data.dueDate} blankText={DefaultBlankDateText} outputType={DateOutputType.ShortDate} />
  ),
};
export const PaidStatusColumn: TypeColumn = {
  name: "paidStatus",
  header: lang.t("transactionsList.columPaidStatus", LangOptions),
  defaultFlex: 1,
  textAlign: "center",
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: PaidStatusArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
  render: ({ data }: TypeColumnRender<Transaction>) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PaidStatusTag status={data.paidStatus} />
    </div>
  ),
};
export const OptionsColumn: TypeColumn = {
  ...BaseOptionsColumn,
  render: ({ data }: TypeColumnRender<Transaction>) => <ColumnButtons transaction={data} />,
};
