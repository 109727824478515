/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useState } from "react";
import { useQuery } from "react-query";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { withFeature } from "flagged";
import { AccountsApi } from "../../common/api/payThemApi/accountsApi";
import { EntityCreationService } from "../../services/entityCreation/entityCreation.service";
import "react-datepicker/dist/react-datepicker.css";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

registerLocale("en-GB", enGB);

/*
 * Used only in debugging/developer mode
 */

type PaymentRequestFormModel = {
  accountId: string;
  currency: string;
  amount: number;
  transactionDate: Date;
  dueDate: Date;
  description: string;
  paymentTerms: string;
};

// Our validation schema
const validationSchema = Yup.object({
  accountId: Yup.string().label("Account").required(),
  currency: Yup.string().label("Currency").required(),
  amount: Yup.number().label("Transaction Amount").required(),
  description: Yup.string().label("Description").max(100),
  paymentTerms: Yup.string().label("Payment Terms").max(100),
}).required();

// Test selection of currencies
const currencySelection: string[] = ["GBP", "USD", "EUR", "THB", "CAD", "YEN"];

const NewPaymentRequestDialog = ({ show, onSave, onClose }: NewPaymentRequestDialogProps): ReactElement => {
  const { t } = useTranslation("forms");

  const { data, isLoading, isError } = useQuery(
    ["accounts-list-npr"],
    () => {
      const api = new AccountsApi();
      return api.getAccounts(1, 20);
    },
    { enabled: show }
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<PaymentRequestFormModel>({ resolver: yupResolver(validationSchema) });

  if (isLoading || isError || !data) return <></>;

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSave = (saveData: PaymentRequestFormModel) => {
    onSave(saveData);
    reset();
  };

  const accounts = data.items;
  const dToday = new Date();
  const dDue = new Date();
  dDue.setDate(dToday.getDate() + 30);

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Payment Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id='paymentRequestForm' noValidate onSubmit={handleSubmit((submitData) => handleSave(submitData))}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>{t("Account")}</Form.Label>
                <Form.Select {...register("accountId")} defaultValue={accounts.length > 0 ? accounts[0].id : undefined}>
                  {accounts.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{t("Currency")}</Form.Label>
                <Form.Select {...register("currency")} defaultValue={currencySelection[0]}>
                  {currencySelection.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md='6'>
              <Form.Group>
                <Form.Label>{t("Transaction Amount")}</Form.Label>
                <Form.Control
                  type='number'
                  {...register("amount")}
                  className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                  defaultValue='100.00'
                />
                <Form.Control.Feedback type='invalid'>{errors?.amount?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{t("Transaction Date")}</Form.Label>
                <Controller
                  control={control}
                  name='transactionDate'
                  defaultValue={dToday}
                  render={({ field }) => (
                    <DatePicker
                      dateFormat='yyyy/MM/dd'
                      placeholderText='Select transaction date'
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{t("Due Date")}</Form.Label>
                <Controller
                  control={control}
                  name='dueDate'
                  defaultValue={dDue}
                  render={({ field }) => (
                    <DatePicker
                      dateFormat='yyyy/MM/dd'
                      placeholderText='Select due date'
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>{t("Description")}</Form.Label>
                <Form.Control
                  type='text'
                  {...register("description")}
                  className={`form-control ${errors.description ? "is-invalid" : ""}`}
                  defaultValue='Goods and/or services.'
                />
                <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>{t("Payment Terms")}</Form.Label>
                <Form.Control
                  type='text'
                  {...register("paymentTerms")}
                  className={`form-control ${errors.paymentTerms ? "is-invalid" : ""}`}
                  defaultValue='30 days from transaction date'
                />
                <Form.Control.Feedback type='invalid'>{errors?.paymentTerms?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {t("buttonCancel")}
        </Button>
        <Button form='paymentRequestForm' type='submit'>
          {t("buttonAdd")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface NewPaymentRequestDialogProps {
  show: boolean;
  onSave: (model: PaymentRequestFormModel) => void;
  onClose: () => void;
}

const AddNewPaymentRequestButton = (): ReactElement => {
  const [show, setShow] = useState<boolean>(false);
  const disabled = !usePermission(UserPermission.EditPaymentRequest);

  async function handleOnSave(model: PaymentRequestFormModel) {
    setShow(false);

    const service = new EntityCreationService();
    await service.createPaymentRequest(
      model.accountId,
      model.currency,
      model.amount,
      model.transactionDate,
      model.dueDate,
      model.description,
      model.paymentTerms
    );
  }
  return (
    <>
      <Button variant='primary' disabled={disabled} onClick={() => setShow(true)}>
        New Request
      </Button>
      <NewPaymentRequestDialog show={show} onClose={() => setShow(false)} onSave={(model) => handleOnSave(model)} />
    </>
  );
};

export default withFeature("newPaymentRequest")(AddNewPaymentRequestButton);
