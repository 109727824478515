import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { tCommon, tForms } from "../../../i18n";
import { toastSuccess } from "../../../services/notifications/toastNotification.service";
import { exportPaymentTransactions } from "../../../services/paymentTransactions/paymentTransactions.service";
import { downloadFile } from "../../../utils/downloadFile";
import ExportDialog from "./ExportDialog";
import ExportDialogOptions from "./ExportDialogOptions";
import ExportOptions from "../../../models/paymentTransactions/exportOptions";
import { CustomerFilterName, StatusFilterName, TotalAmountFilterName, TypeFilterName } from "./tableFiltersHelper";
import { processSingleFilter } from "../../../components/grid/dataGridFilterHelpers";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";
import useExportOption from "../../../common/hooks/useExportOption";

interface ExportButtonProps {
  tableFilters?: TypeFilterValue;
}

/*
 * Converts the ExportDialogOptions to ExportOptions
 */
const convertToExportOptions = (options: ExportDialogOptions, tableFilters?: TypeFilterValue): ExportOptions => {
  const exportOptions: ExportOptions = { ...options };
  if (options.includeTableFilters) {
    const filters: string[] = [];

    let filter = tableFilters?.find((x) => x.name === CustomerFilterName);
    let filterString = processSingleFilter(filter);
    if (filterString) filters.push(filterString);

    filter = tableFilters?.find((x) => x.name === TotalAmountFilterName);
    filterString = processSingleFilter(filter);
    if (filterString) filters.push(filterString);

    if (options.status) {
      filters.push(`${StatusFilterName} eq ${options.status}`);
    }

    if (options.transactionType) {
      filters.push(`${TypeFilterName} eq ${options.transactionType}`);
    }

    exportOptions.filters = filters.length > 0 ? filters : undefined;
  }
  return exportOptions;
};

/*
 * Display an export button which display the add provider dialog
 */
const ExportButton = ({ tableFilters }: ExportButtonProps): ReactElement => {
  const { t } = useTranslation([tForms.ns]);
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const { storeExportOptions, getExportOptions } = useExportOption();
  const [defaultOptions, setDefaultOptions] = useState<ExportDialogOptions>(getExportOptions());
  const disabled = !usePermission(UserPermission.ExportPaymentTransactions);

  const handleExport = (options: ExportDialogOptions) => {
    const exportOptions = convertToExportOptions(options, tableFilters);
    setShow(false);
    setSaving(true);
    exportPaymentTransactions(exportOptions)
      .then((response) => {
        downloadFile(response);
        storeExportOptions(options);
        setDefaultOptions(options);
        toastSuccess(t("messageFileExportedSuccessfully", tCommon));
      })
      .finally(() => setSaving(false));
  };

  const handleShowDialog = () => {
    setShow(true);
  };

  return (
    <>
      <SpinnerButton title={t("buttonExport")} showSpinner={saving} onClick={handleShowDialog} disabled={disabled} />
      <ExportDialog
        show={show}
        defaultOptions={defaultOptions}
        tableFilters={tableFilters}
        onHide={() => setShow(false)}
        onExport={(options) => handleExport(options)}        
      />
    </>
  );
};

ExportButton.defaultProps = {
  tableFilters: undefined,
};

export default ExportButton;
