import { createContext, ReactElement, useCallback, useContext, useState } from "react";
import lang from "i18next";
import { useTranslation } from "react-i18next";
import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { useQuery } from "react-query";
import {
  BaseOptionsColumn,
  DataGridReadyItem,
  defaultDataGridPagination,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  SortAscending,
} from "../../components/grid/dataGridHelpers";
import { DataGrid, DataGridContextCustomDataType, TypeColumnRender } from "../../components/grid/DataGrid";
import { tPages } from "../../i18n";
import { HtmlTitle } from "../common/HtmlTitle";
import { PageTitle } from "../common/PageTitle";
import { DeleteItemConfirmation } from "../../components/icons/DeleteItemConfirmation";
import { CompanyPreferences } from "../../models/companySettings/companySettingsModels";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getPreferences } from "../../services/settings/settings.service";
import { PaymentFeeConfiguration } from "../../models/paymentsFees/paymentFeeConfiguration";
import LatePaymentConfigurationsApi from "../../common/api/payThemApi/latePaymentConfigurationsApi";
import AddLatePaymentButton from "./AddLatePaymentButton";
import {
  deleteLatePaymentConfiguration,
  updateLatePaymentConfiguration,
} from "../../services/latePayments/latePayments.service";
import EditLatePaymentButton from "./EditLatePaymentButton";
import DefaultTag from "../../components/tags/DefaultTag";
import EnabledTag from "../../components/tags/EnabledTag";
import AllowLatePaymentsAlert from "../../components/tenantSettingAlerts/AllowLatePaymentsAlert";
import GridNamesConsts from "../GridNamesConsts";
import PageLoading from "../../components/loading/PageLoading";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";

const LangOptions = tPages;

type MyLoadDataResult = LoadDataResult<PaymentFeeConfiguration>;
const DataGridContext = createContext<DataGridContextCustomDataType<CompanyPreferences | undefined>>(undefined);

/*
 * Column buttons to edit/delete
 */
const ColumnButtons = ({ configuration }: { configuration: PaymentFeeConfiguration }): ReactElement => {
  const context = useContext(DataGridContext);
  const { t } = useTranslation(tPages.ns);
  const disabledEdit = !usePermission(UserPermission.EditLatePayments);
  const disabledDelete = !usePermission(UserPermission.DeleteLatePayments);

  // Deletes a configuration
  const handleDeleteConfiguration = async (id: string): Promise<void> => {
    await deleteLatePaymentConfiguration(id);
    await context?.refreshDataSource();
  };

  // Updates a configuration
  const handleUpdateConfiguration = async (updated: PaymentFeeConfiguration): Promise<void> => {
    await updateLatePaymentConfiguration(updated);
    await context?.refreshDataSource();
  };

  return (
    <>
      <EditLatePaymentButton disabled={disabledEdit} configuration={configuration} onSave={handleUpdateConfiguration} />
      <DeleteItemConfirmation<string>
        disabled={disabledDelete}
        item={configuration.id}
        onDelete={handleDeleteConfiguration}
        body={t("latePaymentConfigurationPage.confirmDeleteMessage")}
      />
    </>
  );
};

/*
 * Creates the name plus shows if it's the default
 */
const ItemName = ({ configuration }: { configuration: PaymentFeeConfiguration }): ReactElement => {
  const context = useContext(DataGridContext);
  const isDefault = context?.customData?.defaultLatePaymentConfigurationId === configuration.id;
  return (
    <div className='d-flex justify-content-start align-items-center'>
      <div className='pe-1'>{configuration.name}</div>
      {isDefault && <DefaultTag />}
    </div>
  );
};

const columns: TypeColumn[] = [
  {
    name: "name",
    header: lang.t("paymentFeesList.columName", LangOptions),
    minWidth: 100,
    defaultFlex: 2,
    render: ({ data }: TypeColumnRender<PaymentFeeConfiguration>) => <ItemName configuration={data} />,
  },
  {
    name: "type",
    header: lang.t("paymentFeesList.columType", LangOptions),
    sortable: false,
  },
  {
    name: "description",
    header: lang.t("paymentFeesList.columDescription", LangOptions),
    minWidth: 200,
    defaultFlex: 3,
    sortable: false,
  },
  {
    name: "gracePeriodInDays",
    header: lang.t("paymentFeesList.columGracePeriod", LangOptions),
    sortable: false,
  },
  {
    name: "enabled",
    header: lang.t("paymentFeesList.columEnabled", LangOptions),
    textAlign: "center",
    defaultFlex: 1,
    sortable: false,

    render: ({ data }: TypeColumnRender<PaymentFeeConfiguration>) => <EnabledTag enabled={data.enabled} />,
  },
  {
    ...BaseOptionsColumn,
    maxWidth: 75,
    render: ({ data }: TypeColumnRender<PaymentFeeConfiguration>) => <ColumnButtons configuration={data} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [];

/*
 * Default sort column
 */
const DefaultSortColumnName = "name";
const DefaultSortDirection = SortAscending;
const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

const loadData = async ({ skip, limit, sortInfo }: LoadDataProps): Promise<MyLoadDataResult> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, undefined, async (pageParameters) => {
    const api = new LatePaymentConfigurationsApi();
    const result = await api.getLatePaymentConfigurations(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter
    );
    return result;
  });

/*
 * Our grid name
 */
const GridName = GridNamesConsts.LatePaymentGrid;

/*
 * The list
 */
const LatePaymentConfigurationsList = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const dataSource = useCallback(loadData, []);
  const { dataGridState, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );

  const [gridRef, setGridRef] = useState<DataGridReadyItem>();
  const { data: preferences } = useQuery([QueryNameConsts.CompanyPreferences], () => getPreferences());

  const readLoad = async () => gridRef?.current?.reload();

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleLatePayments", tPages)} />
      {dataGridState ? (
        <>
          <PageTitle title='pageTitleLatePayments' />
          <AllowLatePaymentsAlert />
          <section className='data-grid-page'>
            <DataGridContext.Provider value={{ refreshDataSource: readLoad, customData: preferences }}>
              <DataGrid<PaymentFeeConfiguration>
                idProperty='id'
                columns={columns}
                dataSource={dataSource}
                defaultSortInfo={dataGridState.sortInfo}
                onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
                defaultSkip={dataGridState.pagination?.skip}
                onSkipChange={(skip) => setPaginationSkip(skip)}
                defaultLimit={dataGridState.pagination?.limit}
                onLimitChange={(limit) => setPaginationLimit(limit)}
                onReady={setGridRef}
              />
            </DataGridContext.Provider>
            <div className='bottom-button-group'>
              <AddLatePaymentButton onAdd={readLoad} />
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default LatePaymentConfigurationsList;
