/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { PaymentProviderConfiguration } from "../../../../models/paymentProviders/paymentProviderConfiguration";
import { FormGroupControl, FormGroupTextArea } from "../../../../components/forms/FormGroupControl";
import { tPaymentProviders } from "../../../../i18n";
import EditFiltersButton from "./EditFiltersButton";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { PaymentMethodGroup, PaymentMethodGroupMap } from "../../../../models/paymentProviders/paymentMethodGroup";
import CommonBadge from "./CommonBadge";
import { PaymentMethodTypeInfo } from "../../../../models/paymentProviders/paymentProvider";

const keyPrefix = "paymentProviders";

interface ProviderFormControlProps {
  register: UseFormRegister<FieldValues>;
  data?: PaymentProviderConfiguration;
  errors: FieldErrors<PaymentProviderConfiguration>;
}

/*
 * The payment provider user name form control
 */
export function ProviderNameFormControl({ register, data, errors }: ProviderFormControlProps): ReactElement {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return (
    <FormGroupControl
      labelName={t("labelProviderName")}
      controlPlaceholder={t("placeholderProviderName")}
      controlDefaultValue={data?.name}
      registerReturn={register("name")}
      errorMessage={errors?.name?.message}
      as={Col}
    />
  );
}

ProviderNameFormControl.defaultProps = {
  data: undefined,
};

/*
 * The payment provider description name form control
 */
export function ProviderDescriptionFormControl({ register, data, errors }: ProviderFormControlProps): ReactElement {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return (
    <FormGroupTextArea
      labelName={t("labelDescription")}
      subLabelName={t("subLabelOptional")}
      controlPlaceholder={t("placeholderDescription")}
      controlDefaultValue={data?.description}
      registerReturn={register("description")}
      errorMessage={errors?.description?.message}
      sm='12'
    />
  );
}

ProviderDescriptionFormControl.defaultProps = {
  data: undefined,
};

/*
 * The payment provider enabled check box
 */
export function ProviderEnabledFormControl({ register, data }: ProviderFormControlProps): ReactElement {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  const defaultEnabled = data ? data.enabled : true;
  return (
    <Form.Group as={Col} sm='1'>
      <Form.Label>{t("labelStatus")}</Form.Label>
      <Form.Select
        {...register("enabled", {
          setValueAs: (v) => {
            if (v === "true") return true;
            return false;
          },
        })}
        defaultValue={defaultEnabled.toString()}
      >
        <option value='true'>{t("labelStatusEnabled")}</option>
        <option value='false'>{t("labelStatusDisabled")}</option>
      </Form.Select>
    </Form.Group>
  );
}

ProviderEnabledFormControl.defaultProps = {
  data: undefined,
};

/*
 * The payment provider display priority form control
 */
export const ProviderDisplayPriorityFormControl = ({ register, data, errors }: ProviderFormControlProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return (
    <Form.Group as={Col} sm='4'>
      <Form.Label>{t("labelProviderDisplayPriority")}</Form.Label>
      <Form.Control
        type='number'
        placeholder={t("placeholderProviderDisplayPriority")}
        {...register("displayPriority")}
        defaultValue={data?.displayPriority ?? 0}
        className={`form-control ${errors?.displayPriority?.message ? "is-invalid" : ""}`}
      />
      <Form.Control.Feedback type='invalid'>{errors?.displayPriority?.message}</Form.Control.Feedback>
    </Form.Group>
  );
};

ProviderDisplayPriorityFormControl.defaultProps = {
  data: undefined,
};

interface ProviderFilterFormControlProps {
  filterGroup: ItemFilterGroup | undefined;
  onClick: () => void;
}

/*
 * Filter edit button form control
 */
export const ProviderFilterFormControl = ({ filterGroup, onClick }: ProviderFilterFormControlProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return (
    <>
      <Form.Label>{t("labelFilters")}</Form.Label>
      <div>
        <EditFiltersButton filterGroup={filterGroup} onClick={onClick} />
      </div>
    </>
  );
};

interface ProviderSupportedMethodsFormControlProps {
  supportedPaymentMethodGroups: PaymentMethodGroup[] | undefined;
}

/*
 * Supported methods form control
 */
export const ProviderSupportedMethodsFormControl = ({
  supportedPaymentMethodGroups,
}: ProviderSupportedMethodsFormControlProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return (
    <Form.Group as={Col} sm='6'>
      <Form.Label>{t("labelSupportedMethods")}</Form.Label>
      <div className='d-flex gap-1'>
        {supportedPaymentMethodGroups
          ? supportedPaymentMethodGroups.map((pm) => <CommonBadge key={pm} text={PaymentMethodGroupMap.get(pm)} />)
          : null}
      </div>
    </Form.Group>
  );
};

interface ProviderSupportedCurrenciesFormControlProps {
  supportedCurrencies: string[] | undefined;
}

/*
 * Supported methods form control
 */
export const ProviderSupportedCurrenciesFormControl = ({
  supportedCurrencies,
}: ProviderSupportedCurrenciesFormControlProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return (
    <Form.Group as={Col} sm='6'>
      <Form.Label>{t("labelSupportedCurrencies")}</Form.Label>
      <div className='d-flex gap-1'>
        {supportedCurrencies ? supportedCurrencies.map((cur) => <CommonBadge key={cur} text={cur.toUpperCase()} />) : null}
      </div>
    </Form.Group>
  );
};

/*
 * True if there are any supported currencies to show
 */
export const hasSupportedCurrencies = (paymentMethodTypeInfo: PaymentMethodTypeInfo | undefined) =>
  paymentMethodTypeInfo && paymentMethodTypeInfo.supportedCurrencies && paymentMethodTypeInfo.supportedCurrencies.length > 0;

/*
 * The provider name and description form controls
 */
export const CommonProviderFormControls = (props: ProviderFormControlProps): ReactElement => (
  <>
    <Row className='d-flex flex-row justify-content-center'>
      <Col md='6'>
        <ProviderNameFormControl {...props} />
      </Col>
      <Col md='3'>
        <ProviderDisplayPriorityFormControl {...props} />
      </Col>
      <Col md='3' className='ms-auto'>
        <ProviderEnabledFormControl {...props} />
      </Col>
    </Row>
    <Row>
      <Col md='6'>
        <ProviderDescriptionFormControl {...props} />
      </Col>
    </Row>
  </>
);

CommonProviderFormControls.defaultProps = {
  data: undefined,
};
