/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Form, Col } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";
import { ItemFilterControlProps, MaxAmountFieldType, MinAmountFieldType } from "./ItemFilterFormModel";
import "./MinMaxAmountFilterControl.scss";

interface MinMaxAmountFilterControlProps extends ItemFilterControlProps {
  minAmountFieldName: MinAmountFieldType;
  amountLabel: string;
  minAmountPlaceholder?: string;
  minAmountDefaultValue?: number;
  minAmountFieldError?: FieldError;
  maxAmountFieldName: MaxAmountFieldType;
  maxAmountPlaceholder?: string;
  maxAmountDefaultValue?: number;
  maxAmountFieldError?: FieldError;
}

/*
 * Base control for handling min/max amount
 */
const MinMaxAmountFilterControl = ({
  minAmountFieldName,
  amountLabel,
  minAmountPlaceholder,
  minAmountDefaultValue,
  minAmountFieldError,
  maxAmountFieldName,
  maxAmountPlaceholder,
  maxAmountDefaultValue,
  maxAmountFieldError,
  register,
  formState,
}: MinMaxAmountFilterControlProps): ReactElement => {
  const { errors } = formState;
  const { t } = useTranslation([tForms.ns]);

  return (
    <>
      <Col md='6' className='pr-2'>
        <Form.Group className='min-max-amount-control'>
          <Form.Label>
            {`${amountLabel}`}
            <span className='optional'>{t("filtersForm.labelOptional")}</span>
          </Form.Label>
          <Form.Control
            type='number'
            placeholder={minAmountPlaceholder}
            {...register(minAmountFieldName)}
            className={`form-control ${minAmountFieldError ? "is-invalid" : ""}`}
            defaultValue={minAmountDefaultValue}
          />
          <Form.Control.Feedback type='invalid'>{minAmountFieldError?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md='6' className='ps-2'>
        <Form.Group>
          <Form.Label>&nbsp;</Form.Label>
          <Form.Control
            type='number'
            placeholder={maxAmountPlaceholder}
            {...register(maxAmountFieldName)}
            className={`form-control ${maxAmountFieldError ? "is-invalid" : ""}`}
            defaultValue={maxAmountDefaultValue}
          />
          <Form.Control.Feedback type='invalid'>{maxAmountFieldError?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </>
  );
};

MinMaxAmountFilterControl.defaultProps = {
  minAmountPlaceholder: undefined,
  minAmountDefaultValue: undefined,
  minAmountFieldError: undefined,
  maxAmountPlaceholder: undefined,
  maxAmountDefaultValue: undefined,
  maxAmountFieldError: undefined,
};

export default MinMaxAmountFilterControl;
