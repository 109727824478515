/*
 * Downloads the file
 */
export const downloadFile = (file: File): void => {
  const fileUrl = URL.createObjectURL(file);

  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.download = file.name;
  link.click();

  URL.revokeObjectURL(fileUrl);
  link.remove();
};

export default downloadFile;
