import { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";

const keyPrefix = "dialogs";

interface ConfirmationDialogProps<TData> {
  show: boolean;
  data?: TData;
  title: string;
  body: string | ReactElement;
  isPositiveConfirmation?: boolean;
  handleClose: () => void;
  handleConfirmation: (data?: TData) => void;
}

export const ConfirmationDialog = <TData,>({
  title,
  body,
  show,
  data,
  isPositiveConfirmation,
  handleClose,
  handleConfirmation,
}: ConfirmationDialogProps<TData>): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {t("buttonNo")}
        </Button>
        <Button variant={isPositiveConfirmation ? "success" : "danger"} onClick={() => handleConfirmation(data)}>
          {t("buttonYes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationDialog.defaultProps = {
  data: null,
  isPositiveConfirmation: false,
};
export default ConfirmationDialog;
