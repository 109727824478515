import * as currencyInfo from "../assets/data/currency-codes.json";

export interface CurrencyInfo {
  iso4217: string;
  name: string;
  decimalPlaces: number;
  roundingDecimalPlaces: number;
  isCurrency: boolean;
  priority: number;
}

/*
 * Returns an array of all available currency codes
 */
export const getCurrencyCodes = (): CurrencyInfo[] => currencyInfo.currencyCodes as CurrencyInfo[];

/*
 * Finds and returns the currency code
 */
export const findCurrencyCode = (iso4217: string): CurrencyInfo | undefined => {
  const result = currencyInfo.currencyCodes.find((x) => x.iso4217 === iso4217);
  return result ? (result as CurrencyInfo) : undefined;
};
/*
 * Returns array of real, priority sorted currency codes
 */
export const getSortedRealCurrencies = (): CurrencyInfo[] => {
  const result = getCurrencyCodes().filter((x) => x.isCurrency === true);
  const x = result.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  return x;
};
