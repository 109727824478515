import { AxiosError } from "axios";
import LogEntry from "../../../models/logging/logEntry";
import CursorPageResult from "../CursorPageResult";
import PayThemApiClientType from "../payThemApiClientType";
import BasePayThemApi from "./basePayThemApi";
import { getPayThemClient } from "./payThemClient.instance";

/**
 * An API for audit logs
 */
export class AuditLogsApi extends BasePayThemApi {
  private endPoint = "audit-logs";

  /**
   * Gets resource activities by identifier
   * @param id 
   * @returns 
   */
  public async getResourceActivities(id: string): Promise<LogEntry[]> {
    const result = await this.get<CursorPageResult<LogEntry>>(
      `${this.endPoint}/resources/${id}?itemsPerPage=100`
    );
    return result.items;
  }

  /**
   * Gets recent dataset activities
   * @param cursor 
   * @param itemsPerPage 
   * @returns 
   */
  public async getRecentActivities(
    cursor: string | null,
    itemsPerPage: number
  ): Promise<CursorPageResult<LogEntry>> {
    const result = await this.getCursorPage<LogEntry>(
      `${this.endPoint}`,
      cursor,
      itemsPerPage
    );
    return result;
  }

  /**
   * Gets recent tenant activities
   * @param cursor 
   * @param itemsPerPage 
   * @returns 
   */
  public async getRecentTenantActivities(
    cursor: string | null,
    itemsPerPage: number
  ): Promise<CursorPageResult<LogEntry>> {
    const client = getPayThemClient(PayThemApiClientType.Tenant);
    const params = this.appendSearchParameters(
      this.buildUrlCursorPageParams(cursor, itemsPerPage)
    );
    const url = `${this.endPoint}?${params.toString()}`;
    const response = await client
      .get<CursorPageResult<LogEntry>>(url)
      .catch((error: Error | AxiosError) => {
        this.processHttpError(error);
      });
    return response.data;
  }
}
export default AuditLogsApi;
