import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useFeatures } from "flagged";
import { useQuery } from "react-query";
import PaymentProviderSelectionCard from "./PaymentProviderSelectionCard";
import { PaymentProvider } from "../../../models/paymentProviders/paymentProvider";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getShowBetaPaymentProviders } from "../../../services/tenantSettings/tenantSettings.service";
import "./PaymentProviderSelectionCard.scss";

// Prefix for the feature flags on payment providers
const PaymentProviderFeaturePrefix = "paymentProvider";

interface PaymentProvideGroupProps {
  paymentProviders: PaymentProvider[];
  onSelected: (provider: PaymentProvider) => void;
}

const PaymentProvideGroup = ({ paymentProviders, onSelected }: PaymentProvideGroupProps): ReactElement => {
  const features = useFeatures();

  const { data: showBeta } = useQuery(QueryNameConsts.TenantSettingShowBetaPaymentProviders, () =>
    getShowBetaPaymentProviders()
  );

  // Filter the providers depending on feature flags
  const filteredProviders = paymentProviders.filter((item) => {
    const enabled = features[`${PaymentProviderFeaturePrefix}${item.providerName}`];
    if (enabled || enabled === undefined || (enabled === false && showBeta === true)) {
      return true;
    }
    return false;
  });

  return (
    <Container className='payment-provider-card-wrapper'>
      {filteredProviders.map((item) => (
        <PaymentProviderSelectionCard key={item.providerName} paymentProvider={item} onSelected={onSelected} />
      ))}
    </Container>
  );
};
export default PaymentProvideGroup;
