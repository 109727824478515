import { useQuery } from "react-query";
import { UserMe } from "../../models/users/user";
import { UsersApi } from "../api/payThemApi/usersApi";
import { QueryNameConsts } from "../queryNameConsts";
import { mapUserToUserMe } from "../../services/users/user.services";
import { useAuth } from "../../context/auth/AuthContext";

interface MeContext {
  me: UserMe | undefined;
  isLoading: boolean;
  refetch: Function;
}

/*
 * Returns the 'Me' user details
 */
const useMe = (): MeContext => {
  const { authService } = useAuth();
  const isAuthenticated = authService.isAuthenticated();

  const { data, isLoading, refetch } = useQuery(QueryNameConsts.UserMe, () => {
    const api = new UsersApi();
    return api.getMe();
  }, {
    staleTime: Infinity,
    enabled: isAuthenticated
  });

  return { 
    me: data ? mapUserToUserMe(data) : undefined, 
    isLoading,
    refetch
  };
}

export default useMe;
