/* eslint-disable import/prefer-default-export */
import lang from "i18next";
import TranSafeApi from "../../common/api/payThemApi/transafeApi";
import { tCommon } from "../../i18n";
import { toastError, toastSuccess } from "../notifications/toastNotification.service";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

/*
 * Tests the connection to Transafe
 */
export const testTransafeConnection = async (
  user: string,
  password: string | undefined,
  useTestServer: boolean,
  providerId?: string
): Promise<void> => {
  try {
    const api = new TranSafeApi();
    const result = await api.testConnection(user, password, useTestServer, providerId);

    if (result.success) {
      toastSuccess(lang.t("toastMessages.transafe.testConnectionSuccess", { ...LangOptions }));
    } else {
      const detail = lang.t(result.message, LangOptions);
      toastError(lang.t("toastMessages.transafe.testConnectionError", { ...LangOptions, detail }));
    }
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    throw error;
  }
};
