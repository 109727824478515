/* eslint-disable react/jsx-props-no-spreading */
import { FormEvent, ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegister, FormState, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, ColProps, Form, Row } from "react-bootstrap";
import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../components/dialogs/AddOrEditCommonDialog";
import { tForms } from "../../i18n";
import { SettlementConfiguration } from "../../models/settlements/settlementConfiguration";
import { SettlementType, SettlementTypeMap } from "../../models/settlements/settlementEnums";
import TransactionDateType from "../../models/transactions/transactionDateTypeEnum";
import { getSortedRealCurrencies } from "../../utils/currencyCodeUtils";
import { FormControlsProps, SettlementConfigurationFormModel } from "./settlementConfigurationFormModel";
import {
  MinMaxTransactionWithCurrencySelectionFilterControls,
  MinMaxTransactionWithFixedCurrencyFilterControls,
} from "./FilterControls";
import SettlementConfigurationModelConsts from "../../models/settlements/settlementConfigurationModelConsts";

const DefaultPercentageDiscountValue = 0;
const DefaultDateTypeValue = TransactionDateType.DueDate;
const keyPrefix = "settlementConfigurationForm";
const DefaultCurrency = "GBP";

const WhenDateTypeArray: [TransactionDateType, string][] = [
  [TransactionDateType.DueDate, "beforeDueDate"],
  [TransactionDateType.TransactionDate, "afterTransactionDate"],
];

interface NumberOfDaysFormControlsProps extends FormControlsProps, ColProps {}

/*
 * Part controls for number of days and transaction date type
 */
const NumberOfDaysFormControls = ({ register, formState, addMode, data, md }: NumberOfDaysFormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;
  return (
    <>
      <Col md={md}>
        <Form.Group>
          <Form.Label>{t("labelNumberOfDays")}</Form.Label>
          <Form.Control
            type='number'
            {...register("numberOfDays")}
            className={`form-control ${errors.numberOfDays ? "is-invalid" : ""}`}
            defaultValue={!addMode ? data?.numberOfDays : 0}
          />
          <Form.Control.Feedback type='invalid'>{errors?.numberOfDays?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={md}>
        <Form.Group>
          <Form.Label>{t("labelTransactionDateType")}</Form.Label>
          <Form.Select {...register("dateType")} defaultValue={!addMode ? data?.dateType : DefaultDateTypeValue}>
            {WhenDateTypeArray.map(([key, value]) => (
              <option key={key} value={key}>
                {t(value)}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type='invalid'>{errors?.dateType?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </>
  );
};

/*
 * Add/edit controls for percentage discount settlement configuration
 */
const PercentageDiscountFormControls = ({ register, formState, addMode, data }: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;
  return (
    <>
      <Row className='dialog-row'>
        <Col md='4'>
          <Form.Group>
            <Form.Label>{t("labelPercentageDiscount")}</Form.Label>
            <Form.Control
              type='number'
              {...register("percentageDiscount")}
              className={`form-control ${errors.percentageDiscount ? "is-invalid" : ""}`}
              defaultValue={!addMode ? data?.percentageDiscount : DefaultPercentageDiscountValue}
            />
            <Form.Control.Feedback type='invalid'>{errors?.percentageDiscount?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <NumberOfDaysFormControls md='4' register={register} formState={formState} addMode={addMode} data={data} />
      </Row>
      <MinMaxTransactionWithCurrencySelectionFilterControls
        register={register}
        formState={formState}
        addMode={addMode}
        data={data}
      />
    </>
  );
};

/*
 * Add/edit controls for absolute discount settlement configuration
 */
const AbsoluteDiscountFormControls = ({ register, formState, addMode, data }: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const currencyList = useCallback(() => getSortedRealCurrencies(), []);
  const [currency, setCurrency] = useState<string>(data?.currency || DefaultCurrency);
  const { errors } = formState;

  const handleCurrencyChange = (e: FormEvent<HTMLSelectElement>) => {
    const newCurrency = e.currentTarget.value;
    setCurrency(newCurrency);
  };

  return (
    <>
      <Row className='dialog-row'>
        <Col md='3'>
          <Form.Group>
            <Form.Label>{t("labelCurrency")}</Form.Label>
            <Form.Select
              {...register("currency")}
              defaultValue={!addMode ? data?.currency : DefaultCurrency}
              onChange={(e) => handleCurrencyChange(e)}
            >
              {currencyList().map((currencyInfo) => (
                <option key={currencyInfo.iso4217} value={currencyInfo.iso4217}>
                  {currencyInfo.iso4217}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>{errors?.currency?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>{t("labelAbsoluteAmount")}</Form.Label>
            <Form.Control
              type='number'
              {...register("amount")}
              className={`form-control ${errors.amount ? "is-invalid" : ""}`}
              defaultValue={!addMode ? data?.amount : DefaultPercentageDiscountValue}
            />
            <Form.Control.Feedback type='invalid'>{errors?.amount?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <NumberOfDaysFormControls md='3' register={register} formState={formState} addMode={addMode} data={data} />
      </Row>
      <MinMaxTransactionWithFixedCurrencyFilterControls
        currency={currency}
        register={register}
        formState={formState}
        addMode={addMode}
        data={data}
      />
    </>
  );
};

/*
 * Returns the form controls to use for the particular settlement type
 */
const getSettlementTypeFormControls = (
  type: SettlementType,
  register: UseFormRegister<SettlementConfigurationFormModel>,
  formState: FormState<SettlementConfigurationFormModel>,
  addMode: Boolean,
  data: SettlementConfiguration | undefined
): ReactElement => {
  let formItems: ReactElement;

  switch (type) {
    case SettlementType.PercentageDiscount:
      formItems = <PercentageDiscountFormControls register={register} formState={formState} addMode={addMode} data={data} />;
      break;
    case SettlementType.AbsoluteDiscount:
      formItems = <AbsoluteDiscountFormControls register={register} formState={formState} addMode={addMode} data={data} />;
      break;
    default:
      throw Error("Unsupported settlement type in settlement configuration dialog");
  }
  return formItems;
};

const getSchema = (type: SettlementType): Yup.AnyObjectSchema =>
  Yup.object({
    description: Yup.string().label("Description").max(SettlementConfigurationModelConsts.DescriptionMaxLength),
    dateType: Yup.string().required(),
    percentageDiscount: Yup.lazy(() =>
      type === SettlementType.PercentageDiscount
        ? Yup.number().label("Percentage Discount").required().min(0).max(100).typeError("Percentage Discount is required")
        : Yup.number().notRequired()
    ),
    numberOfDays: Yup.lazy(() =>
      type === SettlementType.PercentageDiscount
        ? Yup.number().label("Number of Days").required().min(0).typeError("Number of Days is required")
        : Yup.number().notRequired()
    ),
    amount: Yup.lazy(() =>
      type !== SettlementType.PercentageDiscount
        ? Yup.number().label("Amount").required().min(0).typeError("An amount is required")
        : Yup.number().notRequired()
    ),
    currency: Yup.lazy(() =>
      type === SettlementType.PercentageDiscount ? Yup.string().label("Currency").notRequired() : Yup.string().notRequired()
    ),
    filterCurrency: Yup.lazy(() =>
      type === SettlementType.PercentageDiscount ? Yup.string().label("Currency").notRequired() : Yup.string().notRequired()
    ),
    minimumTransactionValue: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    maximumTransactionValue: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
  }).required();

interface SettlementConfigurationDialogProps
  extends AddOrEditDialogProps<SettlementConfiguration, SettlementConfigurationFormModel> {
  type: SettlementType;
}

/*
 * Add or edit Settlement offer dialog
 */
export const SettlementConfigurationDialog = ({
  type,
  show,
  data,
  mode,
  onSave,
  onClose,
}: SettlementConfigurationDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const addMode = mode === "add";

  const { register, handleSubmit, reset, formState } = useForm<SettlementConfigurationFormModel>({
    resolver: yupResolver(getSchema(type)),
  });

  // Reset the model if the data object is difference
  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!show) {
    return <></>;
  }

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSave = (model: SettlementConfigurationFormModel) => {
    const modelToSave = { ...model };
    if (addMode) {
      modelToSave.type = type;
    }
    modelToSave.description = model.description.trim();
    onSave(modelToSave);
    reset();
  };

  const formItems = getSettlementTypeFormControls(type, register, formState, addMode, data);

  const titleType = SettlementTypeMap.get(type) ?? "";
  const { errors } = formState;
  return (
    <>
      <AddOrEditModelDialog
        mode={mode}
        title={t(addMode ? "titleAddSettlemenConfiguration" : "titleEditSettlementConfiguration", {
          settlementTypeName: titleType,
        })}
        show={show}
        onHide={handleClose}
        formId='settlementConfigurationForm'
        size='lg'
      >
        <Form
          id='settlementConfigurationForm'
          className='dialog-form'
          noValidate
          onSubmit={handleSubmit((submitData) => handleSave(submitData))}
        >
          <Row className='dialog-row'>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelDescription")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderDescription")}
                  {...register("description")}
                  className={`form-control ${errors.description ? "is-invalid" : ""}`}
                  defaultValue={!addMode ? data?.description : undefined}
                />
                <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {formItems}
        </Form>
      </AddOrEditModelDialog>
    </>
  );
};

export default SettlementConfigurationDialog;
