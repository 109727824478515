import { ReactElement, useCallback } from "react";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { Col, Row } from "react-bootstrap";
import { Account } from "../../../models/accounts/account";
import { LoadDataProps, loadPageData } from "../../../components/grid/dataGridHelpers";
import { Payer } from "../../../models/payers/payer";
import { AccountsApi } from "../../../common/api/payThemApi/accountsApi";
import PayersGrid from "./PayersGrid";
import {
  OptionsColumn,
  payerAddressColumn,
  payerCompanyNameColumn,
  payerCreatedOnColumn,
  payerEmailAddressColumn,
  payerFullNameColumn,
  payerPostCodeColumn,
} from "./GridColumns";

const DefaultSortColumnName = "createdOn";
const DefaultSortDirection = -1;

/*
 * Default standard column list
 */
const GridColumns: TypeColumn[] = [
  payerCompanyNameColumn,
  payerFullNameColumn,
  payerEmailAddressColumn,
  payerAddressColumn,
  payerPostCodeColumn,
  payerCreatedOnColumn,
  OptionsColumn,
];

interface AccountPayersTabProps {
  account: Account;
}

/*
 * Displays the account payers tab
 */
const AccountPayersTab = ({ account }: AccountPayersTabProps): ReactElement => {
  const loadData = async ({ skip, limit, sortInfo }: LoadDataProps): Promise<{ data: Payer[]; count: number }> =>
    loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, null, async (pageParameters) => {
      const api = new AccountsApi();
      const result = await api.getAccountPayers(
        account.id,
        pageParameters.page,
        pageParameters.itemsPerPage,
        pageParameters.sort
      );
      return result;
    });

  const dataSource = useCallback(loadData, [account]);

  return (
    <Row>
      <Col className='panel-column'>
        <PayersGrid columns={GridColumns} dataSource={dataSource} />
      </Col>
    </Row>
  );
};

export default AccountPayersTab;
