import { ReactElement } from "react";
import lang from "i18next";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { TypeColumnRender } from "../../../components/grid/DataGrid";
import { DateItem } from "../../../components/grid/DateItem";
import { BaseOptionsColumn } from "../../../components/grid/dataGridHelpers";
import { CurrencyItem } from "../../../components/misc/CurrencyItem";
import { PaymentRequestStatusArray } from "../../../models/paymentRequests/paymentRequestStatus";
import { DateOutputType } from "../../../utils/dateFormatter";
import { capitaliseFirstLetter } from "../../../utils/stringUtils";
import { PaymentRequest } from "../../../models/paymentRequests/paymentRequest";
import RequestStatusTag from "../RequestStatusTag";

import { ViewItemButton } from "../../../components/buttons/CommonIconButtons";
import { MainRouteConsts } from "../../MainRoutesConsts";
import { RequestCaptureTypeArray } from "../../../models/paymentRequests/requestCaptureType";
import { tPages } from "../../../i18n";

const ColumnButtons = ({ paymentRequest }: { paymentRequest: PaymentRequest }): ReactElement => (
  <ViewItemButton route={MainRouteConsts.PaymentRequests} id={paymentRequest.id} />
);

const LangOptions = tPages;

export const paymentCaptureTypeColumn: TypeColumn = {
  name: "captureType",
  header: lang.t("paymentRequestsList.columCaptureType", LangOptions),
  minWidth: 150,
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: RequestCaptureTypeArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
  render: ({ data }: TypeColumnRender<PaymentRequest>) => capitaliseFirstLetter(data.captureType),
};
export const paymentRequestTypeColumn: TypeColumn = {
  name: "requestType",
  header: lang.t("paymentRequestsList.columnRequestType", LangOptions),
  minWidth: 200,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<PaymentRequest>) => capitaliseFirstLetter(data.requestType),
};
export const paymentRequestCreatedOnColumn: TypeColumn = {
  name: "createdOn",
  header: lang.t("paymentRequestsList.columCreatedOn", LangOptions),
  type: "number",
  minWidth: 200,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<PaymentRequest>) => (
    <DateItem date={data.createdOn} outputType={DateOutputType.ShortDateTime} />
  ),
};
export const paymentRequestCustomerNameColumn: TypeColumn = {
  name: "customerName",
  header: lang.t("paymentRequestsList.columCustomerName", LangOptions),
  minWidth: 200,
  defaultFlex: 1,
};
export const paymentRequestAmountToPayColumn: TypeColumn = {
  name: "amountToPay",
  header: lang.t("paymentRequestsList.columAmountToPay", LangOptions),
  type: "number",
  textAlign: "end",
  minWidth: 100,
  defaultFlex: 1,
  filterEditor: NumberFilter,
  render: ({ data }: TypeColumnRender<PaymentRequest>) => <CurrencyItem currency={data.currency} value={data.amountToPay} />,
};
export const paymentRequestStatusColumn: TypeColumn = {
  name: "requestStatus",
  header: lang.t("paymentRequestsList.columRequestStatus", LangOptions),
  minWidth: 170,
  textAlign: "center",
  filterEditor: SelectFilter,
  filterEditorProps: {
    placeholder: "All",
    dataSource: PaymentRequestStatusArray.map((c) => ({
      id: c[0],
      label: c[1],
    })),
  },
  render: ({ data }: TypeColumnRender<PaymentRequest>) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <RequestStatusTag status={data.requestStatus} />
    </div>
  ),
};
export const optionsColumn: TypeColumn = {
  ...BaseOptionsColumn,
  render: ({ data }: TypeColumnRender<PaymentRequest>) => <ColumnButtons paymentRequest={data} />,
};
