export const removeTrailingSlashes = (input: string): string => {
  if (input[input.length - 1] === "/")
    return input.substring(0, input.length - 1);
  return input;
};

/*
 * Capitalises the first letter of a string
 */
export const capitaliseFirstLetter = (input: string): string => {
  if (!input || input.length === 0) return input;
  return input.charAt(0).toUpperCase() + input.slice(1);
};

/**
 * Converts an input to a sentence. A space will be added between upper case letters
 */
export const toSentence = (input: string): string => {
  // Add a space before each capital letter and convert to lowercase
  const sentence = input
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase();

  // Capitalize the first letter
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

/*
 * Checks if input string is valid GUID
 */
export const isValidGUID = (str: string): boolean => {
  // Regex to check valid
  // GUID
  const regex = new RegExp(
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
  );

  // if it's empty, return false
  if (str == null) {
    return false;
  }

  // Return true if the str
  // matched the ReGex
  return regex.test(str) === true;
};

export default removeTrailingSlashes;
