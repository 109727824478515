import { TodoResult } from "./todoResult";
import { accessKeysTodo } from "./accessKeysTodos";
import { paymentProvidersTodo } from "./paymentProvidersTodos";
import { companySettingsTodo } from "./companySettingsTodo";
import { subscriptionKeysTodo } from "./subscriptionKeysTodo";

export async function buildTodoResults(enabledNewAccessKeyPage: boolean): Promise<TodoResult[]> {
  const results: TodoResult[] = [];
  const keysTodo = enabledNewAccessKeyPage ? await accessKeysTodo() : await subscriptionKeysTodo();

  results.push(...keysTodo);
  results.push(...(await paymentProvidersTodo()));
  results.push(...(await companySettingsTodo()));

  return results;
}
export default buildTodoResults;
