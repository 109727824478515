import { ReactElement } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuEntries, MenuEntry } from "../menuItems";
import { tCommon } from "../../i18n";
import "./TopNavigationMenu.scss";

const keyPrefix = "sideMenu";

type CollapseNavItemsProps = {
  className: string;
};

/*
 * Component to display extra menu items when the nav menu is collapsed
 */
export const CollapseNavItems = ({ className }: CollapseNavItemsProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const features = useFeatures();

  const createNavItem = (entry: MenuEntry, index: number, dropdownItem: boolean): ReactElement => {
    if (entry.subEntries) {
      return (
        <NavDropdown key={index} title={t(entry.name)}>
          {entry.subEntries.map((subEntry, subIndex) => createNavItem(subEntry, subIndex, true))}
        </NavDropdown>
      );
    }

    if (entry.featureFlagName && !features[entry.featureFlagName]) {
      return <div key={index}/>;
    }

    return dropdownItem ? (
      <NavDropdown.Item key={index} href={entry.link ? entry.link : "#"}>
        {entry.icon && <FontAwesomeIcon icon={entry.icon} />}
        {t(entry.name)}
      </NavDropdown.Item>
    ) : (
      <Nav.Link key={index} href={entry.link ? entry.link : "#"}>
        {entry.icon && <FontAwesomeIcon icon={entry.icon} />}
        {t(entry.name)}
      </Nav.Link>
    );
  };

  return <div className={className}>{MenuEntries.map((entry, index) => createNavItem(entry, index, false))}</div>;
};

export default CollapseNavItems;
