export enum ExportType {
  PaymentsAndAllocations = "PaymentsAndAllocations",

  Allocations = "Allocations",
}
interface ExportOptions {
  exportType: ExportType;
  fromDate?: Date;
  toDate?: Date;
  allocatedStatuses: {
    waitingToBeAllocated: boolean;
    unallocated: boolean;
    autoAllocated: boolean;
    manuallyAllocated: boolean;
    notApplicable: boolean;
    error: boolean;
  };
  paymentProvider?: string;
  filters?: string[];
}

export default ExportOptions;
