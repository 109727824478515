import { IncludeOptions } from "./includeOptions";

export interface NotificationUserIncludeOptions extends IncludeOptions {
  notificationSettings: boolean;
}

export const notificationsUserIncludeAll: NotificationUserIncludeOptions = {
  notificationSettings: true,
};

export const notificationsUserIncludeNone: NotificationUserIncludeOptions = {
  notificationSettings: true,
};
