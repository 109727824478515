import { ReactElement } from "react";
import "./UserInitials.scss";

interface UserInitialProps {
  displayName: string;
}

// Returns the initials from a name (stolen from SDC Cloud!)
const getInitials = (displayName: string): string => {
  if (!displayName) {
    return "";
  }

  const parts = displayName.split(" ");
  const characterLimit = 2;
  let initials = "";

  // eslint-disable-next-line no-restricted-syntax
  for (const part of parts) {
    if (initials.length === characterLimit) {
      break;
    }

    if (part) {
      initials += part[0];
    }
  }
  return initials;
};

// Component to show the users initials
const UserInitials = ({ displayName }: UserInitialProps): ReactElement => (
  <div className='initials-icon-container'>
    <span className='user-initials'>{getInitials(displayName)}</span>
  </div>
);

export default UserInitials;
