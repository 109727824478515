export enum TransactionType {
  None = "None",
  Invoice = "Invoice",
  Receipt = "Receipt",
  CreditNote = "CreditNote",
  Payment = "Payment",
  BalanceIncreasingTransaction = "BalanceIncreasingTransaction",
  BalanceReducingTransaction = "BalanceReducingTransaction",
  Quotation = "Quotation",
  ProformaInvoice = "ProformaInvoice",
  OrderAcknowledgement = "OrderAcknowledgement",
  GoodsDespatchNote = "GoodsDespatchNote",
}

export const TransactionTypeMap = new Map<TransactionType, string>([
  [TransactionType.None, "None"],
  [TransactionType.Invoice, "Invoice"],
  [TransactionType.Receipt, "Receipt"],
  [TransactionType.CreditNote, "Credit Note"],
  [TransactionType.Payment, "Payment"],
  [TransactionType.BalanceIncreasingTransaction, "Balance Increasing Transaction"],
  [TransactionType.BalanceReducingTransaction, "Balance Reducing Transaction"],
  [TransactionType.Quotation, "Quotation"],
  [TransactionType.ProformaInvoice, "Proforma Invoice"],
  [TransactionType.OrderAcknowledgement, "Order Acknowledgement"],
  [TransactionType.GoodsDespatchNote, "Goods Despatch Note"],
]);

export const TransactionTypeArray = Array.from(TransactionTypeMap.entries());

export enum PaidStatus {
  Unpaid = "Unpaid",
  FullyPaid = "FullyPaid",
  PartPaid = "PartPaid",
}

export const PaidStatusMap = new Map<PaidStatus, string>([
  [PaidStatus.Unpaid, "Unpaid"],
  [PaidStatus.FullyPaid, "Fully Paid"],
  [PaidStatus.PartPaid, "Part Paid"],
]);

export const PaidStatusArray = Array.from(PaidStatusMap.entries());
