/* eslint-disable import/prefer-default-export */

/*
 * Returns true if fromDate is before toDate
 */
export const validateFromToDates = (fromDate?: Date | null, toDate?: Date | null): boolean => {
  if (!fromDate || !toDate) {
    return true;
  }
  return fromDate <= toDate;
};
