import { ReactElement } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomerFormatter, getFirstAddress, getFirstEmailAddress } from "../../utils/customerFormatter";
import { PanelItem } from "../../components/panels/Panels";
import { Account } from "../../models/accounts/account";
import { textOrDefault } from "../../helpers/stringHelpers";
import { AccountLink } from "../Accounts/AccountLink";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import { tPages } from "../../i18n";

const keyPrefix = "transactionPage.account";

/*
 * Account details card
 */
const AccountDetails = ({ title, account }: { title?: string | undefined; account: Account | undefined }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const address = CustomerFormatter.fullAddressToString(getFirstAddress(account?.addresses));
  const emailAddress = getFirstEmailAddress(account?.emailAddresses)?.emailAddress;

  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title>{title ?? t("accountTitle")}</Card.Title>
          <Card.Text as='div'>
            {account && (
              <Row>
                <Col md={6}>
                  <div className='main-item'>
                    <div className='title'>{t("name")}</div>
                    <div className='content'>{textOrDefault(account.name)}</div>
                  </div>
                </Col>
                <Col md={6}>
                  <PanelItem title={t("accountReference")}>
                    <AccountLink account={account} />
                  </PanelItem>
                  <PanelItem title={t("email")}>
                    {textOrDefault(emailAddress)}
                    {emailAddress && <CopyToClipboard text={emailAddress} />}
                  </PanelItem>
                  <PanelItem title={t("address")}>
                    {textOrDefault(address)}
                    {address && <CopyToClipboard text={address} />}
                  </PanelItem>
                </Col>
              </Row>
            )}
            {!account && <div>N/A</div>}
            <div>{}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

AccountDetails.defaultProps = {
  title: undefined,
};

export default AccountDetails;
