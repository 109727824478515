/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BasicTag } from '../../../components/tags/BasicTag';
import {
  isDefaultPaymentProvider,
  setDefaultPaymentProvider,
} from '../../../services/paymentProviders/paymentProviders.service';
import { QueryNameConsts } from '../../../common/queryNameConsts';
import './DefaultProvider.scss';
import '../../viewsCommon.scss';
import { tPaymentProviders } from '../../../i18n';
import { BadgeSize } from '../../../models/misc/badgeSize';

/*
 * Generates default payment provider tag
 */
const DefaultProviderTag = (): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);
  return (
    <BasicTag
      tagName='default-payment-proivder'
      tagText={t('paymentProviders.labelIsDefault')}
      tagCssEnd=''
      size={BadgeSize.Small}
    />
  );
};

/*
 * Displays set as default text
 */
const SetAsDefault = ({ ...rest }): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);
  return (
    <div {...rest} className='provider-select-default'>
      {t('paymentProviders.buttonSetDefaultProvider')}
    </div>
  );
};

interface DefaultProviderProps {
  paymentProviderId?: string;
  onSetAsDefault?: (providerId: string) => void;
  allowSetDefault?: boolean;
  children: ReactNode;
}

/*
 * Display a default provider tag or set it as default
 * Display a button which sets the current provider as the default
 */
export function DefaultProvider({
  children,
  paymentProviderId,
  allowSetDefault,
  onSetAsDefault,
}: DefaultProviderProps): ReactElement {
  const [requiresUpdate, setRequiresUpdate] = useState(false);
  const { data: isDefault } = useQuery<boolean>(
    [QueryNameConsts.PaymentProviderIsDefault, paymentProviderId, requiresUpdate],
    () => {
      if (paymentProviderId === undefined) return false;
      return isDefaultPaymentProvider(paymentProviderId);
    }
  );

  const handleSetDefault = async () => {
    if (paymentProviderId !== undefined) {
      await setDefaultPaymentProvider(paymentProviderId);
      setRequiresUpdate(true);
      if (onSetAsDefault !== undefined) onSetAsDefault(paymentProviderId);
    }
  };
  const displayElement =
    paymentProviderId !== undefined && isDefault ? (
      <DefaultProviderTag />
    ) : (
      <>{allowSetDefault && <SetAsDefault onClick={() => handleSetDefault()} />}</>
    );

  return (
    <div className='provider-title'>
      {children}
      {displayElement && <div className='provider-spacer-left'>{displayElement}</div>}
    </div>
  );
}
DefaultProvider.defaultProps = {
  paymentProviderId: undefined,
  onSetAsDefault: undefined,
  allowSetDefault: false,
};

export default DefaultProvider;
