import { ReactElement, useCallback, useState, createContext, useContext } from "react";
import lang from "i18next";
import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../common/PageTitle";
import { DatasetsApi } from "../../../common/api/payThemApi/datasetsApi";
import { Dataset } from "../../../models/datasets/dataset";
import { DataGrid, TypeColumnRender, DataGridContextType } from "../../../components/grid/DataGrid";
import {
  SortAscending,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  BaseOptionsColumn,
  defaultDataGridPagination,
  DataGridReadyItem,
} from "../../../components/grid/dataGridHelpers";
import { DateItem } from "../../../components/grid/DateItem";
import { DateOutputType } from "../../../utils/dateFormatter";
import { AddDatasetButton } from "./AddDataset";
import { EditDatasetButton } from "./EditDataset";
import { HostSystemType } from "../../../models/datasets/hostSystem";
import { useHostSystems } from "../../../common/hooks/useHostSystems";
import { DeleteItemConfirmation } from "../../../components/icons/DeleteItemConfirmation";
import {
  deleteDataset,
  updateDataset,
  isProcessingDatasetStatus,
  resetDataset,
} from "../../../services/datasets/dataset.service";
import { HtmlTitle } from "../../common/HtmlTitle";
import { tPages } from "../../../i18n";
import { DatasetTitle } from "../../../components/datasets/DatasetTitle";
import GridNamesConsts from "../../GridNamesConsts";
import PageLoading from "../../../components/loading/PageLoading";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";
import useDataGridStorage from "../../../common/hooks/useDataGridStorage";

const LangOptions = tPages;

const DataGridContext = createContext<DataGridContextType>(undefined);

const ColumnButtons = ({ dataset }: { dataset: Dataset }): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const context = useContext(DataGridContext);
  const defaultHostSystem = useHostSystems().find((x) => x.hostSystemType === HostSystemType.Default);
  const isProcessing = isProcessingDatasetStatus(dataset.status);
  const disabledEditButton = !usePermission(UserPermission.EditCompanies);
  const disabledDeleteButton = !usePermission(UserPermission.DeleteCompanies);

  const handleDeleteDataset = async (id: number): Promise<void> => {
    await deleteDataset(id);
    await context?.refreshDataSource();
  };

  const handleUpdateDataset = async (updatedDataset: Dataset): Promise<void> => {
    await updateDataset(updatedDataset);
    await context?.refreshDataSource();
  };

  const handleResetDataset = async (id: number): Promise<void> => {
    await resetDataset(id);
    await context?.refreshDataSource();
  };

  return dataset.hostSystemName !== defaultHostSystem?.hostSystemName ? (
    <>
      <EditDatasetButton
        dataset={dataset}
        onSave={handleUpdateDataset}
        onResetData={handleResetDataset}
        disabled={isProcessing || disabledEditButton}
      />
      <DeleteItemConfirmation<number>
        item={dataset.id}
        onDelete={() => handleDeleteDataset(dataset.id)}
        body={t("datasetList.confirmDeleteConfiguration", { name: dataset.name })}
        disabled={isProcessing || disabledDeleteButton}
      />
    </>
  ) : (
    <></>
  );
};

const columns: TypeColumn[] = [
  {
    name: "id",
    header: lang.t("datasetList.columnId", LangOptions),
    type: "number",
    minWidth: 58,
    maxWidth: 58,
    defaultFlex: 1,
  },
  {
    name: "name",
    header: lang.t("datasetList.columnName", LangOptions),
    minWidth: 50,
    defaultFlex: 2,
  },
  {
    name: "type",
    header: lang.t("datasetList.columnType", LangOptions),
    minWidth: 50,
    defaultFlex: 1,
  },
  {
    name: "hostSystemName",
    header: lang.t("datasetList.columnHostSystemName", LangOptions),
    minWidth: 150,
    defaultFlex: 2,
  },
  {
    name: "hostId",
    header: lang.t("datasetList.columnHostId", LangOptions),
    minWidth: 50,
    defaultFlex: 2,
  },
  {
    name: "customHostId",
    header: lang.t("datasetList.columnCustomHostId", LangOptions),
    minWidth: 50,
    defaultFlex: 2,
  },
  {
    name: "currency",
    header: lang.t("datasetList.columnCurrency", LangOptions),
    minWidth: 50,
    defaultFlex: 1,
  },
  {
    name: "rebuildStatus",
    header: lang.t("datasetList.columnRebuildStatus", LangOptions),
    minWidth: 50,
    defaultFlex: 1,
    textAlign: "center",
  },
  {
    name: "status",
    header: lang.t("datasetList.columnStatus", LangOptions),
    minWidth: 50,
    defaultFlex: 1,
    textAlign: "center",
    render: ({ data }: TypeColumnRender<Dataset>) => <DatasetTitle dataset={data} showStatus />,
  },
  {
    name: "lastSynced",
    header: lang.t("datasetList.columnLastSynced", LangOptions),
    minWidth: 100,
    defaultFlex: 1,
    type: "number",
    sortable: false,
    render: ({ data }: TypeColumnRender<Dataset>) => (
      <DateItem date={data.lastSync} outputType={DateOutputType.ShortDateTime} />
    ),
  },
  {
    ...BaseOptionsColumn,
    maxWidth: 70,
    render: ({ data }: TypeColumnRender<Dataset>) => <ColumnButtons dataset={data} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [];

/*
 * Default sort column
 */
const DefaultSortColumnName = "id";
const DefaultSortDirection = SortAscending;
const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

type MyLoadDataProps = LoadDataResult<Dataset>;

/*
 * Callback function for loading in a page of data
 */
const loadData = async ({ skip, limit, sortInfo }: LoadDataProps): Promise<MyLoadDataProps> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, undefined, async (pageParameters) => {
    const api = new DatasetsApi();
    const result = await api.getDatasets(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter
    );
    return result;
  });

/*
 * Our grid name
 */
const GridName = GridNamesConsts.CompaniesGrid;

/*
 * The dataset list
 */
export const DatasetsList = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const dataSource = useCallback(loadData, []);

  const { dataGridState, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );
  const [gridRef, setGridRef] = useState<DataGridReadyItem>();

  const readLoad = async () => gridRef?.current?.reload();

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleDatasets", tPages)} />
      {dataGridState ? (
        <>
          <PageTitle title='pageTitleDatasets' />
          <section className='data-grid-page'>
            <DataGridContext.Provider value={{ refreshDataSource: readLoad }}>
              <DataGrid<Dataset>
                idProperty='id'
                columns={columns}
                dataSource={dataSource}
                defaultSortInfo={dataGridState.sortInfo}
                onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
                defaultSkip={dataGridState.pagination?.skip}
                onSkipChange={(skip) => setPaginationSkip(skip)}
                defaultLimit={dataGridState.pagination?.limit}
                onLimitChange={(limit) => setPaginationLimit(limit)}
                onReady={setGridRef}
              />
            </DataGridContext.Provider>
            <div className='bottom-button-group'>
              <AddDatasetButton onAdd={readLoad} />
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};
export default DatasetsList;
