import { ReactElement, ReactNode } from "react";
import { TabRefs } from "../../helpers/hookForms/hookFormHelpers";

interface TabRefProps {
  index: number;
  eventKey: string;
  tabRefs: TabRefs;
  children: ReactNode;
}

/*
 * The tab component with a ref to allow selection of the tab from validation errors
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabRef = ({ index, eventKey, tabRefs, children }: TabRefProps): ReactElement => (
  <div
    ref={(ref) => {
      // eslint-disable-next-line no-param-reassign
      tabRefs.current[index] = ref;
    }}
    id={eventKey}
  >
    {children}
  </div>
);
export default TabRef;
