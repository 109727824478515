export enum FeeGroupType {
  LatePayment = "LatePayment",
  EarlyPayment = "EarlyPayment",
  Surchange = "Surchange",
}

export const FeeGroupTypeMap = new Map<FeeGroupType, string>([
  [FeeGroupType.LatePayment, "Late Payment"],
  [FeeGroupType.EarlyPayment, "Early Payment"],
  [FeeGroupType.Surchange, "Surchange"],
]);

export const FeeGroupTypeArray = Array.from(FeeGroupTypeMap.entries());
