import ItemFilterConfiguration from "./itemFilterConfiguration";
import ItemFilteringType from "./itemFilteringType";

interface CurrenciesFilterConfiguration extends ItemFilterConfiguration {
  currencies: string[];
}

export const initCurrenciesFilterConfiguration = (currencies: string[] | undefined): CurrenciesFilterConfiguration => {
  const configuration: CurrenciesFilterConfiguration = {
    filterType: ItemFilteringType.Currencies,
    isEnabled: true,
    currencies: currencies ?? [],
  };
  return configuration;
};

export default CurrenciesFilterConfiguration;
