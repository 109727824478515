/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { Card, Form, Spinner, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useLocation } from "react-router-dom";
import { StripeConnectService, StripeConnectType } from "../../../../services/stripe/stripeConnect.service";
import { SettingsRouteConsts } from "../../../SettingsRouteConsts";
import { tCommon, tPaymentProviders } from "../../../../i18n";
import { toastError, toastSuccess } from "../../../../services/notifications/toastNotification.service";
import useWebsiteView from "../../../../context/websiteView/WebsiteViewContext";
import { isAllowParentRedirect } from "../../../../components/websiteComms/WebsiteViewHelper";
import { SpinnerButton } from "../../../../components/buttons/SpinnerButton";
import Loading from "../../../../components/loading/Loading";
import StripeConnectionErrorPage from "./StripeConnectionErrorPage";

const keyPrefix = "stripeForm";

/*
 * Starts the Stripe connection process by redirecting to the Stripe connect website
 */
export const StripeStartConnect = ({ configurationId }: { configurationId?: string }): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  const [spinning, setSpinning] = useState(false);
  const wv = useWebsiteView();

  const handleConnect = async () => {
    setSpinning(true);
    if (isAllowParentRedirect(wv)) {
      await StripeConnectService.startParentStripeConnection(wv.parentUrl!, StripeConnectType.Add, configurationId);
    } else {
      await StripeConnectService.startLocalStripeConnection(StripeConnectType.Add, configurationId);
    }
  };

  return (
    <div>
      <Card className='custom-detail-card mb-3' style={{ width: "50rem" }}>
        <Card.Body>
          <Card.Title>{t("titleStripeConnect")}</Card.Title>
          <div>{t("descriptionStripeConnection")}</div>
          <div className='mt-2'>
            <SpinnerButton title={t("buttonConnectToStripe")} showSpinner={spinning} onClick={handleConnect} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

StripeStartConnect.defaultProps = {
  configurationId: undefined,
};

/*
 * Completes the Stripe connection process by creating/updating the payment provider
 */
export const StripeCompleteConnect = (): ReactElement => {
  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { t } = useTranslation([tPaymentProviders.ns, tCommon.ns]);

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const service = new StripeConnectService();
    const error = params.get("error");

    if (error) {
      const description = params.get("error_description") ?? t("messageUnknownError", tCommon);
      setErrorMessage(description);
    } else {
      const code = params.get("code") ?? "";
      const state = params.get("state") ?? "";
      service
        .completeStripeConnection(state, code)
        .then((result) => {
          toastSuccess(t("stripeForm.toastMessageStipeConnectionSuccess"));
          setProviderId(result);
        })
        .catch((apiError) => {
          toastError(t("stripeForm.toastMessageStipeConnectionFailed"));
          setErrorMessage(apiError.toString());
        });
    }
  }, [search, t]);

  return (
    <div>
      {errorMessage ? (
        <StripeConnectionErrorPage errorMessage={errorMessage} />
      ) : (
        <Loading message={t("stripeForm.messageStripeCompletingConnection")} />
      )}
      {providerId && <Redirect to={`${SettingsRouteConsts.PaymentProviders}/${providerId}`} />}
    </div>
  );
};
