import lang from "i18next";
import { PaymentRequestsApi } from "../../common/api/payThemApi/paymentRequestsApi";
import { AccountsApi } from "../../common/api/payThemApi/accountsApi";
import { TransactionsApi } from "../../common/api/payThemApi/transactionsApi";
import { PaymentRequest, PaymentRequestAdd } from "../../models/paymentRequests/paymentRequest";
import { TransactionAdd } from "../../models/transactions/transaction";
import { TransactionType, PaidStatus } from "../../models/transactions/transactionEnums";
import { toastSuccess } from "../notifications/toastNotification.service";
import { Account } from "../../models/accounts/account";
import { RequestCaptureType } from "../../models/paymentRequests/requestCaptureType";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const InvoiceType = "Invoice";

export class EntityCreationService {
  private tOptions = { ns: "common" };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public static async createAccount(
    reference: string,
    name: string,
    shortName: string,
    currency: string,
    creditLimit?: number
  ): Promise<Account> {
    /* Doesnt just yet create a new account; just returns the first existing account */
    const accountsApi = new AccountsApi();
    const accounts = await accountsApi.getAccounts(1, 1);
    return accounts.items[0];
  }

  public async createPaymentRequest(
    accountId: string,
    currency: string,
    amount: number,
    transactionDate: Date,
    dueDate: Date,
    description: string,
    paymentTerms: string
  ): Promise<PaymentRequest> {
    const accountsApi = new AccountsApi();
    const account = await accountsApi.getAccount(accountId);

    const transactionAdd: TransactionAdd = {
      transactionType: TransactionType.Invoice,
      transactionTypeName: TransactionType.Invoice,
      accountId,
      currency,
      transactionAmount: amount,
      transactionReference: EntityCreationService.createRandomTransactionReference(),
      transactionDate,
      dueDate,
      description,
      paymentTermsDescription: paymentTerms,
      allocatedAmount: null,
      discountedAmount: 0,
      paidStatus: PaidStatus.Unpaid,
    };

    try {
      const transactionApi = new TransactionsApi();
      const transaction = await transactionApi.addTransaction(transactionAdd, false);

      const paymentRequest: PaymentRequestAdd = {
        requestType: InvoiceType,
        captureType: RequestCaptureType.Immediate,
        customerName: account.name,
        customerReference: null,
        currency,
        billingContact: {
          companyName: account.name,
          emailAddress: account.emailAddresses?.find((x) => x)?.emailAddress,
          firstNames: account.contacts?.find((x) => x)?.firstNames,
          surname: account.contacts?.find((x) => x)?.surname,
          address: account.addresses?.find((x) => x),
        },
        deliveryContact: null,
        test: true,
        transactionAllocations: [
          {
            transactionId: transaction.id,
            amount: null,
          },
        ],
      };

      const paymentRequestApi = new PaymentRequestsApi();
      const result = await paymentRequestApi.createPaymentRequest(paymentRequest);
      toastSuccess(lang.t("New payment request created", this.tOptions));
      return result;
    } catch (error) {
      handleApiErrorWithToast(error, true, "paymentRequests.paymentRequestAdd");
      throw error;
    }
  }

  private static createRandomTransactionReference(): string {
    return `INV${Math.random() * 1000000000}`;
  }
}

export default EntityCreationService;
