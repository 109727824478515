import { ReactElement } from "react";
import { PaymentProviderConfiguration } from "../../../models/paymentProviders/paymentProviderConfiguration";
import { DefaultProvider } from "./DefaultProvider";
import { buildProviderImageUrl } from "../../../helpers/assetsHelper";
/*
 * Displays the logo as the provider name
 */
export const ProviderLogo = ({
  configuration,
  height,
}: {
  configuration: PaymentProviderConfiguration;
  height: string | number;
}): ReactElement => {
  const imageUrl = buildProviderImageUrl(configuration.providerName);
  return (
    <div>
      <DefaultProvider paymentProviderId={configuration.id} allowSetDefault={false}>
        <div>
          <img src={imageUrl} height={height} alt={configuration.providerName} />
        </div>
      </DefaultProvider>
    </div>
  );
};

export default ProviderLogo;
