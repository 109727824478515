import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { useQuery } from "react-query";
import PageLoading from "../../../components/loading/PageLoading";
import { tPages } from "../../../i18n";
import { PaymentProviderConfiguration } from "../../../models/paymentProviders/paymentProviderConfiguration";
import { OpayoProviderName, OpayoPaymentProviderConfiguration } from "../../../models/paymentProviders/opayo/opayoModels";
import { StripePaymentProviderConfiguration, StripeProviderName } from "../../../models/paymentProviders/stripe/stripeModels";
import {
  TranSafeProviderName,
  TransafePaymentProviderConfiguration,
} from "../../../models/paymentProviders/transafe/transafeModels";
import { getConfiguration, getPaymentProviders } from "../../../services/paymentProviders/paymentProviders.service";
import { HtmlTitle } from "../../common/HtmlTitle";
import { SettingsRouteConsts } from "../../SettingsRouteConsts";
import StripeItem from "./Stripe/StripeItem";
import TranSafeItem from "./TranSafe/TranSafeItem";
import { OpayoItem } from "./Opayo/OpayoItem";
import { CommonBreadcrumbs } from "../../common/Breadcrumbs/Breadcrumbs";
import {
  TrueLayerPaymentProviderConfiguration,
  TrueLayerProviderName,
} from "../../../models/paymentProviders/trueLayer/trueLayerModels";
import { TrueLayerItem } from "./TrueLayer/TrueLayer";
import {
  TotalProcessingPaymentProviderConfiguration,
  TotalProcessingProviderName,
} from "../../../models/paymentProviders/totalProcessing/totalProcessingModels";
import TotalProcessingItem from "./TotalProcessing/TotalProcessingItem";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { PaymentProvider } from "../../../models/paymentProviders/paymentProvider";
import {
  GoCardlessProviderName,
  GoCardlessPaymentProviderConfiguration,
} from "../../../models/paymentProviders/goCardless/goCardlessModels";
import GoCardlessItem from "./GoCardless/GoCardlessItem";
import { paymentProviderConfigurationIncludeAll } from "../../../common/api/payThemApi/paymentProviderConfigurationIncludeOptions";

export const PaymentProviderEditItem = (): ReactElement => {
  const { data } = useQuery(QueryNameConsts.PaymentProviderTypes, () => getPaymentProviders());
  const { id } = useParams<{ id: string }>();
  const [configuration, setConfiguration] = useState<PaymentProviderConfiguration>();
  const [paymentProviderStripe, setPaymentProviderStripe] = useState<PaymentProvider>();
  const [paymentProviderTranSafe, setPaymentProviderTranSafe] = useState<PaymentProvider>();
  const [paymentProviderOpayo, setPaymentProviderOpayo] = useState<PaymentProvider>();
  const [paymentProviderTrueLayer, setPaymentProviderTrueLayer] = useState<PaymentProvider>();
  const [paymentProviderTotalProcessing, setPaymentProviderTotalProcessing] = useState<PaymentProvider>();
  const [paymentProviderGoCardless, setPaymentProviderGoCardless] = useState<PaymentProvider>();

  const history = useHistory();
  const { t } = useTranslation([tPages.ns]);

  const getQueryPaymentProviderType = useCallback(() => {
    const providerStripe = data?.find((d) => d.providerName === StripeProviderName);
    setPaymentProviderStripe(providerStripe);
    const providerTranSafeType = data?.find((d) => d.providerName === TranSafeProviderName);
    setPaymentProviderTranSafe(providerTranSafeType);
    const providerOpayoType = data?.find((d) => d.providerName === OpayoProviderName);
    setPaymentProviderOpayo(providerOpayoType);
    const providerTrueLayerType = data?.find((d) => d.providerName === TrueLayerProviderName);
    setPaymentProviderTrueLayer(providerTrueLayerType);
    const providerTotalProcessingType = data?.find((d) => d.providerName === TotalProcessingProviderName);
    setPaymentProviderTotalProcessing(providerTotalProcessingType);
    const providerGoCardlessType = data?.find((d) => d.providerName === GoCardlessProviderName);
    setPaymentProviderGoCardless(providerGoCardlessType);
  }, [data]);

  useEffect(() => {
    getConfiguration(id ?? "", paymentProviderConfigurationIncludeAll).then((result) => setConfiguration(result));
    getQueryPaymentProviderType();
  }, [id, getQueryPaymentProviderType]);

  // Build the provider component
  const ProviderComponent = useMemo(() => {
    const handleOnSuccessful = () => {
      history.push(SettingsRouteConsts.PaymentProviders);
    };

    switch (configuration?.providerName) {
      case StripeProviderName:
        return (
          <StripeItem
            mode='edit'
            data={configuration as StripePaymentProviderConfiguration}
            paymentProvider={paymentProviderStripe}
            onSave={handleOnSuccessful}
          />
        );

      case TranSafeProviderName:
        return (
          <TranSafeItem
            mode='edit'
            data={configuration as TransafePaymentProviderConfiguration}
            paymentProvider={paymentProviderTranSafe}
            onSave={handleOnSuccessful}
          />
        );

      case OpayoProviderName:
        return (
          <OpayoItem
            mode='edit'
            data={configuration as OpayoPaymentProviderConfiguration}
            paymentProvider={paymentProviderOpayo}
            onSave={handleOnSuccessful}
          />
        );

      case TrueLayerProviderName:
        return (
          <TrueLayerItem
            mode='edit'
            data={configuration as TrueLayerPaymentProviderConfiguration}
            paymentProvider={paymentProviderTrueLayer}
            onSave={handleOnSuccessful}
          />
        );

      case TotalProcessingProviderName:
        return (
          <TotalProcessingItem
            mode='edit'
            data={configuration as TotalProcessingPaymentProviderConfiguration}
            paymentProvider={paymentProviderTotalProcessing}
            onSave={handleOnSuccessful}
          />
        );

      case GoCardlessProviderName:
        return (
          <GoCardlessItem
            mode='edit'
            data={configuration as GoCardlessPaymentProviderConfiguration}
            paymentProvider={paymentProviderGoCardless}
            onSave={handleOnSuccessful}
          />
        );

      default:
        return <p>{t("paymentProvidersPage.messageUnknownProvider")}</p>;
    }
  }, [
    configuration,
    history,
    paymentProviderGoCardless,
    paymentProviderOpayo,
    paymentProviderStripe,
    paymentProviderTotalProcessing,
    paymentProviderTranSafe,
    paymentProviderTrueLayer,
    t,
  ]);

  if (!configuration) return <PageLoading />;

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentProviders", tPages)} subTitle2={configuration.name} />
      <Breadcrumb>
        <CommonBreadcrumbs.PaymentProviders />
      </Breadcrumb>
      {ProviderComponent}
    </>
  );
};

export default PaymentProviderEditItem;
