/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, ReactNode, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getPreferences, updatePreferencesDefaultSettlementConfigurationGroup } from "../../services/settings/settings.service";
import { tPages } from "../../i18n";
import StandardTooltip from "../../components/tooltips/StandardTooltip";
import "./DefaultSettlement.scss";
import DefaultTag from "../../components/tags/DefaultTag";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "settlementConfigurationGroupPage";

interface SetAsDefaultProps {
  disabled?: boolean;
  onClick: () => void;
}

/*
 * Displays set as default text
 */
const SetAsDefault = ({ disabled, onClick }: SetAsDefaultProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });

  return (
    <StandardTooltip text={t("tooltipSetAsDefault")}>
      <button type='button' onClick={onClick} className='settlement-select-default' disabled={disabled}>
        {t("buttonSetDefault")}
      </button>
    </StandardTooltip>
  );
};

SetAsDefault.defaultProps = {
  disabled: false,
};

interface DefaultProps {
  disabled?: boolean;
  onRemove: () => void;
}

const Default = ({ disabled, onRemove }: DefaultProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  return (
    <>
      <DefaultTag />
      <StandardTooltip text={t("tooltipRemoveDefault")}>
        <button type='button' onClick={onRemove} className='settlement-select-default' disabled={disabled}>
          {t("buttonRemoveDefault")}
        </button>
      </StandardTooltip>
    </>
  );
};

Default.defaultProps = {
  disabled: false,
};

interface DefaultSettlementProps {
  settlementConfigurationGroupId: string;
  children: ReactNode;
}

/*
 * Show either set as default option of a Default tag
 */
const DefaultSettlement = ({ children, settlementConfigurationGroupId }: DefaultSettlementProps): ReactElement => {
  const disabled = !usePermission(UserPermission.EditSettlementConfigurations);
  const [isDefault, setIsDefault] = useState<Boolean>();
  const { data: preferences } = useQuery([QueryNameConsts.CompanyPreferences], async () => {
    const result = await getPreferences();
    setIsDefault(result?.defaultSettlementConfigurationGroupId === settlementConfigurationGroupId);
    return result;
  });

  const handleSetAsDefault = async (id?: string): Promise<void> => {
    await updatePreferencesDefaultSettlementConfigurationGroup(id);
    setIsDefault(!!id);
  };

  if (!preferences) return <></>;

  return (
    <div className='settlement-title'>
      <div className='pe-1'>{children}</div>
      {isDefault ? (
        <Default disabled={disabled} onRemove={() => handleSetAsDefault()} />
      ) : (
        <SetAsDefault disabled={disabled} onClick={() => handleSetAsDefault(settlementConfigurationGroupId)} />
      )}
    </div>
  );
};

export default DefaultSettlement;
