import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Role } from "../../../models/users/role";
import { IconButton } from "../../../components/icons/IconButton";
import { User } from "../../../models/users/user";
import { UserDialog, UserFormModel } from "./UserDialog";
import { tForms } from "../../../i18n";

interface EditUserButtonProps {
  user: User;
  disableRole?: boolean;
  onSave: (id: string, name: string, role: Role) => void;
}

/*
 * Display an edit user button which display the edit user dialog
 */
export function EditUserButton({ user, disableRole, onSave }: EditUserButtonProps): ReactElement {
  const { t } = useTranslation([tForms.ns]);
  const [show, setShow] = useState<boolean>(false);

  function handleOnSave(model: UserFormModel) {
    setShow(false);
    onSave(user.id, model.name, model.role);
  }

  return (
    <>
      <IconButton title={t("titleEdit")} icon={faEdit} onClick={() => setShow(true)} />
      <UserDialog
        show={show}
        mode='edit'
        data={user}
        disableRole={disableRole}
        onClose={() => setShow(false)}
        onSave={(model) => handleOnSave(model)}
      />
    </>
  );
}

EditUserButton.defaultProps = {
  disableRole: true,
};

export default EditUserButton;
