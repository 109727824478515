import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { UserDialog, UserFormModel } from "./UserDialog";
import { createUser } from "../../../services/users/user.services";
import { tUsers } from "../../../i18n";

interface AddUserButtonProps {
  onAdd: () => Promise<void>;
}

/*
 * Display an add user button which display the add user dialog
 */
export const AddUserButton = ({ onAdd }: AddUserButtonProps): ReactElement => {
  const { t } = useTranslation(tUsers.ns);
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleOnSave = async (model: UserFormModel) => {
    setShow(false);
    setSaving(true);
    try {
      await createUser(model.name, model.emailAddress, model.role);
    } finally {
      setSaving(false);
    }
    await onAdd();
  };

  return (
    <>
      <SpinnerButton variant='primary' title={t("buttonAddUser")} showSpinner={saving} onClick={() => setShow(true)} />
      <UserDialog show={show} mode='add' onClose={() => setShow(false)} onSave={(model) => handleOnSave(model)} />
    </>
  );
};
export default AddUserButton;
