/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Transaction } from "../../models/transactions/transaction";
import { DateOutputType } from "../../utils/dateFormatter";
import { DefaultBlankDateText, DefaultBlankStringText } from "../viewCommon";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import { addSpacesToSentence } from "../../helpers/stringHelpers";
import { TransactionLink } from "./TransactionLink";
import { tPages } from "../../i18n";
import OverdueDate from "../../components/OverdueDate/OverdueDate";
import { getAllocatedAmount } from "../../helpers/transactions/transactionHelper";
import useDateFormatter from "../../common/hooks/useDateFormatter";

const keyPrefix = "transactionPage.transactionsTable";

const TransactionTableEntry = ({ transaction }: { transaction: Transaction }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateFormatter = useDateFormatter();
  return (
    <>
      <Table borderless className='panel-table'>
        <thead>
          <tr>
            <td>{t("transactionType")}</td>
            <td>{t("transactionReference")}</td>
            <td>{t("transactionDate")}</td>
            <td>{t("dueDate")}</td>
            <td>{t("transactionAmount")}</td>
            <td>{t("discountedAmount")}</td>
            <td>{t("allocatedAmount")}</td>
            <td>{t("status")}</td>
            <td />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{transaction.transactionTypeName ?? transaction.transactionType}</td>
            <td>
              <TransactionLink id={transaction.id}>{transaction.transactionReference ?? transaction.id} </TransactionLink>
            </td>
            <td>{dateFormatter.toShortDate(transaction.transactionDate)}</td>
            <td>
              <OverdueDate date={transaction.dueDate} blankText={DefaultBlankDateText} outputType={DateOutputType.ShortDate} />
            </td>
            <td className='font-bold'>
              <CurrencyItem currency={transaction.currency} value={transaction.transactionAmount} />
            </td>
            <td className='font-bold'>
              <CurrencyItem currency={transaction.currency} value={transaction.discountedAmount} />
            </td>
            <td className='font-bold'>
              <CurrencyItem currency={transaction.currency} value={getAllocatedAmount(transaction)} />
            </td>
            <td>{addSpacesToSentence(transaction.paidStatus)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default TransactionTableEntry;
