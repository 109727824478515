import { ReactElement } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomerFormatter } from "../../utils/customerFormatter";
import { PanelItem } from "../../components/panels/Panels";
import { textOrDefault } from "../../helpers/stringHelpers";
import { tPages } from "../../i18n";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import EditContactButton from "./Editing/EditContactButton";
import { BasePaymentRequestTabProps } from "./common";

const keyPrefix = "paymentRequestPage.paymentContactDetails";

interface PaymentContactDetailsProps extends BasePaymentRequestTabProps {
  type: "billing" | "delivery";
}

/*
 * Contact details card
 */
const PaymentContactDetails = ({ type, paymentRequest, onUpdate }: PaymentContactDetailsProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const title = `${type}Contact`;
  const contact = type === "billing" ? paymentRequest.billingContact : paymentRequest.deliveryContact;

  return (
    <Card className='custom-detail-card'>
      <Card.Body>
        <Card.Title className='details-title'>
          <span>{t(title).toUpperCase()}</span>
          <EditContactButton type={type} paymentRequest={paymentRequest} onUpdate={onUpdate} />
        </Card.Title>
        <Card.Text as='div'>
          {contact && (
            <Row>
              <Col sm={12} md={12} lg={6}>
                <div className='main-item'>
                  <div className='title'>{t("companyName")}</div>
                  <div className='content'>{textOrDefault(contact.companyName)}</div>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <PanelItem title={t("contactName")}>
                  {textOrDefault(CustomerFormatter.getFullName(contact.firstNames, contact.surname))}
                </PanelItem>
                <PanelItem title={t("emailAddress")}>
                  {textOrDefault(contact.emailAddress)}
                  <CopyToClipboard text={textOrDefault(contact.emailAddress)} />
                </PanelItem>
                <PanelItem title={t("address")}>
                  {textOrDefault(CustomerFormatter.fullAddressToString(contact.address))}
                  <CopyToClipboard text={textOrDefault(CustomerFormatter.fullAddressToString(contact.address))} />
                </PanelItem>
              </Col>
            </Row>
          )}
          {!contact && <div>{t("noAddressFound")}</div>}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
export default PaymentContactDetails;
