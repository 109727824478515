import { PaymentRequest, TransactionAllocationInfo } from "../../models/paymentRequests/paymentRequest";
import { PaymentRequestStatus } from "../../models/paymentRequests/paymentRequestStatus";
import { PaymentRequestUsageType } from "../../models/paymentRequests/paymentRequestUsageType";
import { RequestCaptureType } from "../../models/paymentRequests/requestCaptureType";
import { RequestTypes } from "../../models/paymentRequests/requestTypes";
import { getPaymentRequestSettings } from "../../services/settings/settings.service";

/*
 * Returns the transaction allocation info for the given transaction id
 */
export const findValidTransactionAllocationInfo = (
  transactionId: string,
  paymentRequest?: PaymentRequest
): TransactionAllocationInfo => {
  const transactionAllocationInfo = paymentRequest?.transactionAllocations?.find((x) => x.transactionId === transactionId);
  if (!transactionAllocationInfo) throw Error("Transaction Allocation not found in Payment Request");
  return transactionAllocationInfo;
};

/*
 * Returns the usage type of the payment request, use the default if not set
 */
export const getUsageType = async (paymentRequest: PaymentRequest): Promise<PaymentRequestUsageType> => {
  if (paymentRequest.options?.usage) {
    return paymentRequest.options.usage;
  }
  const settings = await getPaymentRequestSettings();

  return settings?.defaultUsageType ?? PaymentRequestUsageType.Single;
};

/*
 * Returns true if the usage type can be changed
 */
export const canChangeUsageType = (paymentRequest: PaymentRequest): boolean =>
  !(
    paymentRequest.requestStatus !== PaymentRequestStatus.Open ||
    paymentRequest.requestType !== RequestTypes.Transaction ||
    paymentRequest.captureType !== RequestCaptureType.Immediate ||
    paymentRequest.paymentTransactions?.length > 0
  );
