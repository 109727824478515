enum TransactionDateType {
  // Actions should be based on due date
  DueDate = "DueDate",

  // Actions should be based on transaction date
  TransactionDate = "TransactionDate",
}

export const TransactionDateTypeMap = new Map<TransactionDateType, string>([
  [TransactionDateType.DueDate, "Due Date"],
  [TransactionDateType.TransactionDate, "Transaction Date"],
]);

export const TransactionDateTypeArray = Array.from(TransactionDateTypeMap.entries());

export default TransactionDateType;
