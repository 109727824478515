import { ReactElement } from "react";
import { useAuth } from "../context/auth/AuthContext";
import useWebsiteView from "../context/websiteView/WebsiteViewContext";
import TopNavigationMenu from "./navigation/TopNavigationMenu";

/*
 * Header for the application
 */
const Header = (): ReactElement => {
  const { authService } = useAuth();
  const wv = useWebsiteView();

  if (wv?.removeHeader === true) {
    return <></>;
  }

  const isAuthenticated = authService.isAuthenticated();
  return isAuthenticated ? <TopNavigationMenu /> : <></>;
};

export default Header;
