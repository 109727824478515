import { randomBytes, createHash } from 'crypto';

export type PKCECodePair = {
  codeVerifier: string;
  codeChallenge: string;
  state: string;
  createdAt: Date;
};

export const base64URLEncode = (str: Buffer): string => str
  .toString('base64')
  .replace(/\+/g, '-')
  .replace(/\//g, '_')
  .replace(/=/g, '');

export const sha256 = (buffer: Buffer): Buffer => createHash('sha256').update(buffer).digest();

export const createPKCECodes = (): PKCECodePair => {
  const state = randomBytes(8).toString('hex');
  const codeVerifier = base64URLEncode(randomBytes(64));
  const codeChallenge = base64URLEncode(sha256(Buffer.from(codeVerifier)));
  const createdAt = new Date();
  const codePair = {
    codeVerifier,
    codeChallenge,
    state,
    createdAt,
  };
  return codePair;
};
