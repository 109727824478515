import { Bank } from "../../../models/banking/bankModels";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";

export class BankingApi extends BasePayThemApi {
  private endPoint = "banks";

  /*
   * Returns a single bank account
   */
  public async getBank(id: string): Promise<Bank> {
    const result = await this.getItem<Bank, string>(this.endPoint, id);
    return result;
  }

  /*
   * Returns a page of bank accounts
   */
  public async getBanks(page: number, itemsPerPage: number, sort?: string, filter?: string): Promise<PageResult<Bank>> {
    const result = await this.getPage<Bank>(this.endPoint, page, itemsPerPage, sort, filter);
    return result;
  }

  /*
   * Adds a new bank account
   */
  public async addBank(bank: Bank): Promise<Bank> {
    const result = await this.postWithResponse<Bank, Bank>(this.endPoint, bank);
    return result;
  }

  /*
   * Updates an existing bank account
   */
  public async updateBank(bank: Bank): Promise<Bank> {
    const result = await this.putWithResponse<Bank, Bank>(this.endPoint, bank);
    return result;
  }
}

export default BankingApi;
