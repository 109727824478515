import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import "./IconButton.scss";

interface IconButtonProps extends FontAwesomeIconProps {
  disabled?: boolean;
}

export const IconButton = ({ disabled, ...rest }: IconButtonProps): ReactElement => {
  const className = disabled === undefined || !disabled ? "icon-button-enabled" : "icon-button-disabled";
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FontAwesomeIcon {...rest} className={className} />;
};

IconButton.defaultProps = {
  disabled: false,
};

export default IconButton;
