import { PaymentProvider } from "../../../models/paymentProviders/paymentProvider";
import {
  PaymentProviderConfiguration,
  AddPaymentProviderConfiguration,
} from "../../../models/paymentProviders/paymentProviderConfiguration";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";
import { buildExpandParameter } from "./includeOptions";
import { PaymentProviderConfigurationIncludeOptions } from "./paymentProviderConfigurationIncludeOptions";

export class PaymentProvidersApi extends BasePayThemApi {
  private endPoint = "providers";

  /*
   * Returns the payment provider with the given name
   */
  public async getConfigurationByName(
    name: string,
    includeOptions?: PaymentProviderConfigurationIncludeOptions
  ): Promise<PaymentProviderConfiguration[]> {
    const params = buildExpandParameter(includeOptions);
    params.append("filter", `name eq ${name}`);
    const result = await this.get<PageResult<PaymentProviderConfiguration>>(this.endPoint, params);
    return result?.items;
  }

  /*
   * Returns a payment provider
   */
  public async getConfiguration(
    id: string,
    includeOptions?: PaymentProviderConfigurationIncludeOptions
  ): Promise<PaymentProviderConfiguration> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.getItem<PaymentProviderConfiguration, string>(this.endPoint, id, params);
    return result;
  }

  /*
   * Returns a page of payment providers
   */
  public async getConfigurations(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string,
    includeOptions?: PaymentProviderConfigurationIncludeOptions
  ): Promise<PageResult<PaymentProviderConfiguration>> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.getPage<PaymentProviderConfiguration>(this.endPoint, page, itemsPerPage, sort, filter, params);
    return result;
  }

  /*
   * Adds a new payment provider configuration record
   */
  public async addConfiguration(configuration: AddPaymentProviderConfiguration): Promise<PaymentProviderConfiguration> {
    const result = await this.postWithResponse<AddPaymentProviderConfiguration, PaymentProviderConfiguration>(
      this.endPoint,
      configuration
    );
    return result;
  }

  /*
   * Updates an existing new payment provider configuration record
   */
  public async updateConfiguration(configuration: PaymentProviderConfiguration): Promise<void> {
    const result = await this.put<PaymentProviderConfiguration>(this.endPoint, configuration);
    return result;
  }

  /*
   * Deletes a payment provider configuration record
   */
  public async deleteConfiguration(id: string): Promise<void> {
    await this.deleteItem(this.endPoint, id);
  }

  /*
   * Returns a list of Payment Providers
   */
  public async getPaymentProviders(): Promise<PaymentProvider[]> {
    const url = `${this.endPoint}/types`;
    const result = await this.get<PaymentProvider[]>(url);
    return result;
  }
}

export default PaymentProvidersApi;
