/* eslint-disable react/jsx-props-no-spreading */
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonProps } from "react-bootstrap";
import { IconButton } from "../icons/IconButton";

export interface RouteIconButtonProps {
  route: string;
  title: string;
  icon: IconDefinition;
  disabled?: boolean;
}

/*
 * Icon button that set the route
 */
export function RouteIconButton({ title, icon, route, disabled }: RouteIconButtonProps): ReactElement {
  const history = useHistory();
  return (
    <IconButton
      title={title}
      icon={icon}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        if (!disabled){
          history.push(route);
        }
      }}
    />
  );
}

RouteIconButton.defaultProps = {
  disabled: false
}

export interface IdRouteIconButtonProps extends RouteIconButtonProps {
  id: string;
}

/*
 * Icon button that set the route with and Id
 */
export function IdRouteIconButton({ route, id, disabled, ...rest }: IdRouteIconButtonProps): ReactElement {
  return <RouteIconButton disabled={disabled} {...rest} route={`${route}/${id}`} />;
}

IdRouteIconButton.defaultProps = {
  disabled: false
}

export interface RouteButtonProps extends ButtonProps {
  route: string;
}

/*
 * Button that set the route
 */
export function RouteButton({ route, children, ...rest }: RouteButtonProps): ReactElement {
  const history = useHistory();
  return (
    <Button {...rest} onClick={() => history.push(route)}>
      {children}
    </Button>
  );
}
