import { ReactElement } from "react";
import { DateOutputType } from "../../utils/dateFormatter";
import useDateFormatter from "../../common/hooks/useDateFormatter";

/*
 * Value to display when the date time string is empty
 */
const emptyDateTimeString = "-";

interface DateItemProps {
  date: string | Date | undefined | null;
  outputType: DateOutputType;
  emptyDate?: string;
}

/*
 * Formats and displays a date and/or time
 */
export function DateItem({ date, outputType, emptyDate }: DateItemProps): ReactElement {
  const dateFormatter = useDateFormatter();
  const value = date ? dateFormatter.toOutputString(date, outputType) : emptyDate;
  return <div>{value}</div>;
}

DateItem.defaultProps = {
  emptyDate: emptyDateTimeString,
};

export default DateItem;
