/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Breadcrumb } from "react-bootstrap";
import { Payer } from "../../../models/payers/payer";
import { tPages } from "../../../i18n";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getAccountPayer } from "../../../services/accounts/accounts.service";
import { HtmlTitle } from "../../common/HtmlTitle";
import { DisplayLoadingOrError } from "../../../components/loading/DisplayLoadingOrError";
import { CommonBreadcrumbs } from "../../common/Breadcrumbs/Breadcrumbs";
import PayerTab from "./PayerTabs";

/*
 * Displays the payer item
 */
const PayerItem = (): ReactElement => {
  const { accountId, id } = useParams<{ accountId: string; id: string }>();

  const { t } = useTranslation([tPages.ns]);

  const { data, isLoading, isError } = useQuery([QueryNameConsts.PayerItem, accountId, id], () =>
    getAccountPayer(accountId, id)
  );

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePayer", tPages)} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !data}>
        <Breadcrumb>
          <CommonBreadcrumbs.Account accountId={accountId} />
        </Breadcrumb>
        {data && (
          <section className='item-view-section transaction common-form'>
            <div className='page-title'>{t("pageTitlePayer")}</div>
            <PayerTab payer={data} />
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default PayerItem;
