/*
 * Returns due if the date it overdue
 */
export function isOverdue(date: Date | null | undefined, comparisonDate: Date | null | undefined = undefined): boolean {
  if (!date) return false;
  return date < (comparisonDate ?? new Date());
}

export function isExpired(date: Date | null | undefined, comparisonDate: Date | null | undefined = undefined): boolean {
  if (!date) return false;
  return date < (comparisonDate ?? new Date());
}

/*
 * Converts the date(y/m/d) part of a local date to a UTC date without any timezone conversion issues
 */
export const convertDatePartToUtcDate = (date: Date | undefined): Date | undefined => {
  if (!date) return undefined;
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

/*
 * Returns the date part from a Date object in UTC format
 */
export const getUTCDatePartFromUTCDate = (date: Date | undefined): Date | undefined => {
  if (!date) return undefined;
  const dateObject = new Date(date);
  return new Date(Date.UTC(dateObject.getUTCFullYear(), dateObject.getUTCMonth(), dateObject.getUTCDate()));
};

/*
 * Converts the date(y/m/d) part of a utc date, to a local date without any timezone conversion issues
 */
export const convertUtcDatePartToLocalDate = (date: Date | undefined): Date | undefined => {
  if (!date) return undefined;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

// Return true if this is a valid date object
export const isDateObject = (date: Date | undefined): boolean =>
  date !== null && date !== undefined && typeof date === "object" && "toISOString" in date;

export const getValidDate = (date: Date | undefined): Date | undefined => {
  if (isDateObject(date)) {
    return date;
  }
  if (date && date !== null) {
    try {
      return new Date(date);
    } catch (error) {
      // Do nothing
    }
  }
  return undefined;
};

export const isGreaterThanOrEqual = (fromDate?: Date | null, toDate?: Date | null): boolean => {
  if (!fromDate || !toDate) {
    return true;
  }
  return fromDate <= toDate;
};

// Returns the correct date to display in the date picker
export const getDataModelDateForDatePicker = (date: Date | undefined): string | undefined => {
  const validDate = getValidDate(date);
  if (!validDate) return undefined;
  const localDate = convertDatePartToUtcDate(validDate);
  return localDate?.toISOString().substring(0, 10);
};

export default isOverdue;

