import { ReactElement } from "react";
import { HtmlTitle } from "./common/HtmlTitle";
import { PageTitle } from "./common/PageTitle";

const ComingSoon = (): ReactElement => (
  <>
    <HtmlTitle subTitle1='Coming Soon' />
    <section className='data-grid-page'>
      <PageTitle title='Coming Soon' />
    </section>
  </>
);

export default ComingSoon;
