import { ReactElement } from "react";
import { Card, Placeholder } from "react-bootstrap";

const GeneralCardPlaceholder = (): ReactElement => (
  <Card>
    <Card.Body>
      <Card.Title>
        <Placeholder as={Card.Text} animation='glow'>
          <Placeholder xs={12} />
        </Placeholder>
      </Card.Title>
      <Placeholder as={Card.Text} animation='glow'>
        <Placeholder xs={12} />
        <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={6} /> <Placeholder xs={8} />
      </Placeholder>
    </Card.Body>
  </Card>
);

export default GeneralCardPlaceholder;
