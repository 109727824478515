export enum SettlementType {
  None = "None",

  // Apply the percentage given as a discount to the overall amount of the <see cref="PaymentRequest"/>

  PercentageDiscount = "PercentageDiscount",

  // Apply the absolute value as a discount to the overall amount of the <see cref="PaymentRequest"/>
  AbsoluteDiscount = "AbsoluteDiscount",

  // Pay the amount supplied in the settlement record
  ReplacementAmount = "ReplacementAmount",
}

export const SettlementTypeMap = new Map<SettlementType, string>([
  [SettlementType.None, "None"],
  [SettlementType.PercentageDiscount, "Percentage Discount"],
  [SettlementType.AbsoluteDiscount, "Absolute Discount"],
  [SettlementType.ReplacementAmount, "Replacement Amount"],
]);

export const SettlementTypeArray = Array.from(SettlementTypeMap.entries());

export enum SettlementOfferStatus {
  // This offer is open
  Open = "Open",

  // This offer was cancelled
  Cancelled = "Cancelled",

  // This offer was used
  Used = "Used",
}

export const SettlementOfferStatusMap = new Map<SettlementOfferStatus, string>([
  [SettlementOfferStatus.Open, "Open"],
  [SettlementOfferStatus.Cancelled, "Cancelled"],
  [SettlementOfferStatus.Used, "Used"],
]);

export const SettlementOfferStatusArray = Array.from(SettlementOfferStatusMap.entries());
