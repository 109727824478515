import { ReactElement } from 'react';
import { BasicTag } from '../../components/tags/BasicTag';
import { AccountType } from '../../models/accounts/accountEnums';
import './AccountTypeTag.scss';

/*
 * Generates Account Type tag
 */
const AccountTypeTag = ({
  type,
  name,
  className,
}: {
  type: AccountType;
  name?: string;
  className?: string;
}): ReactElement => (
  <BasicTag
    tagName={type}
    tagText={name?.toUpperCase()}
    className={className}
    tagCssEnd='-type'
  />
);

AccountTypeTag.defaultProps = {
  className: undefined,
  name: undefined,
};
export default AccountTypeTag;
