import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MainRouteConsts } from "../../MainRoutesConsts";
import { SettingsRouteConsts } from "../../SettingsRouteConsts";

const CommonBreadcrumb = ({ route, title }: { route: string; title: string }): ReactElement => {
  const { t } = useTranslation(["pages"]);
  return <Breadcrumb.Item href={route}>{t(title)}</Breadcrumb.Item>;
};

export const CommonBreadcrumbs = {
  PaymentTransactions: function PaymentTransactions(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={MainRouteConsts.PaymentTransactions} title='pageTitlePaymentTransactions' />
      </>
    );
  },

  PaymentRequests: function PaymentRequests(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={MainRouteConsts.PaymentRequests} title='pageTitlePaymentRequests' />
      </>
    );
  },

  Accounts: function Accounts(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={MainRouteConsts.Accounts} title='pageTitleAccounts' />
      </>
    );
  },

  Account: function Account({ accountId }: { accountId: string }): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={`${MainRouteConsts.Accounts}/${accountId}`} title='pageTitleAccount' />
      </>
    );
  },

  Transactions: function Transactions(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={MainRouteConsts.Transactions} title='pageTitleTransactions' />
      </>
    );
  },

  Banks: function Banks(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={MainRouteConsts.Banks} title='pageTitleBanks' />
      </>
    );
  },

  PaymentProviders: function PaymentProviders(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={SettingsRouteConsts.PaymentProviders} title='pageTitlePaymentProviders' />
      </>
    );
  },

  SettlementConfigurations: function SettlementConfigurations(): ReactElement {
    return (
      <>
        <FontAwesomeIcon icon={faArrowLeft} />
        <CommonBreadcrumb route={MainRouteConsts.Settlements} title='pageTitleSettlements' />
      </>
    );
  },
};

export default CommonBreadcrumbs;
