import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { TypeColumn, TypeDataSource, TypeFilterValue, TypeSortInfo } from "@inovua/reactdatagrid-community/types";
import { TypeOnSelectionChangeArg } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import { ReactElement, ReactNode } from "react";
import "./DataGrid.scss";
import DataGridLoadingMask from "./DataGridLoadingMask";
import { DataGridReadyItem, defaultPageSize } from "./dataGridHelpers";

export const defaultGridStyle = {
  minHeight: 482,
  minWidth: 800,
};

export const defaultFilterGridStype = {
  minHeight: 523,
  minWidth: 800,
};

export declare type TypeColumnRender<T> = {
  data: T;
  cellProps: unknown;
  rowIndex: number;
  rowSelected: boolean;
};

export declare type TypeI18n = {
  [key: string]: string | ReactNode;
};

/*
 * Data grid context props
 */
export type DataGridContextProps = {
  refreshDataSource: () => Promise<void>;
};

export type DataGridContextCustomDataProps<TData> = {
  refreshDataSource: () => Promise<void>;
  customData: TData;
};

export type DataGridContextType = DataGridContextProps | undefined;
export type DataGridContextCustomDataType<T> = DataGridContextCustomDataProps<T> | undefined;

interface DataGridProps<T> {
  idProperty: string;
  columns: TypeColumn[];
  style?: {
    [key: string]: string | number;
  };
  dataSource: TypeDataSource;
  defaultSortInfo?: TypeSortInfo;
  defaultFilterValue?: TypeFilterValue;
  defaultSkip?: number | undefined;
  defaultLimit?: number | undefined;
  enableFiltering?: boolean | undefined;
  i18n?: TypeI18n;
  onReady?: (computedPropsRef: DataGridReadyItem) => void;
  onSelectionChange?: (selection: T) => void;
  onFilterValueChange?: (filterValue: TypeFilterValue) => void;
  onSortInfoChange?: (sortInfo: TypeSortInfo) => void;
  onSkipChange?: (skip: number) => void;
  onLimitChange?: (limit: number) => void;
}

export const DataGrid = <T,>({
  idProperty,
  columns,
  style,
  dataSource,
  defaultSortInfo,
  defaultFilterValue,
  defaultSkip,
  defaultLimit,
  enableFiltering,
  i18n,
  onReady,
  onSelectionChange,
  onFilterValueChange,
  onSortInfoChange,
  onSkipChange,
  onLimitChange,
  ...rest
}: DataGridProps<T>): ReactElement => {
  const handleSelectionChanged = (selection: TypeOnSelectionChangeArg) => {
    if (onSelectionChange && selection.data) {
      onSelectionChange(selection.data as unknown as T);
    }
  };

  const gridStyle = style || enableFiltering ? defaultFilterGridStype : defaultGridStyle;

  return (
    <ReactDataGrid
      i18n={i18n}
      onReady={onReady}
      idProperty={idProperty}
      columns={columns}
      style={gridStyle}
      pagination
      defaultLimit={defaultLimit ?? defaultPageSize}
      dataSource={dataSource}
      enableSelection={!!onSelectionChange}
      onSelectionChange={handleSelectionChanged}
      enableFiltering={enableFiltering}
      defaultFilterValue={defaultFilterValue}
      onFilterValueChange={onFilterValueChange}
      defaultSortInfo={defaultSortInfo}
      onSortInfoChange={onSortInfoChange}
      onSkipChange={onSkipChange}
      defaultSkip={defaultSkip}
      onLimitChange={onLimitChange}
      renderLoadMask={DataGridLoadingMask}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

DataGrid.defaultProps = {
  style: undefined,
  defaultSkip: undefined,
  defaultLimit: undefined,
  defaultSortInfo: undefined,
  defaultFilterValue: undefined,
  enableFiltering: undefined,
  onReady: undefined,
  onSelectionChange: undefined,
  onFilterValueChange: undefined,
  onSortInfoChange: undefined,
  onSkipChange: undefined,
  onLimitChange: undefined,
  i18n: undefined,
};
