import { AllocatedStatus } from "./paymentTransactionEnums";

export enum ImportStatus {
  NotImported = "NotImported",
  Imported = "Imported",
  Duplicate = "Duplicate",
}

export const ImportStatusMap = new Map<ImportStatus, string>([
  [ImportStatus.NotImported, "Not Imported"],
  [ImportStatus.Imported, "Imported"],
  [ImportStatus.Duplicate, "Duplicate"],
]);

export const ImportStatusArray = Array.from(ImportStatusMap.entries());

export enum ImportResultStatus {
  Error = "Error",
  Warning = "Warning",
  Ok = "Ok",
}

export interface ImportResult {
  importStatus: ImportStatus;
  resultStatus: ImportResultStatus;
  id: string;
  allocatedStatus?: AllocatedStatus | undefined;
  message?: string | undefined;
}

export interface ImportResults {
  processedCount: number;
  importedCount: number;
  notImportedCount: number;
  duplicatedCount: number;
  results: ImportResult[];
}
