import { TypeColumn, TypeSingleFilterValue } from "@inovua/reactdatagrid-community/types";
import { ReactElement, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { TransactionsApi } from "../../../common/api/payThemApi/transactionsApi";
import { LoadDataProps, loadPageData } from "../../../components/grid/dataGridHelpers";
import { Account } from "../../../models/accounts/account";
import { Transaction } from "../../../models/transactions/transaction";
import {
  DueDateColumn,
  OptionsColumn,
  PaidStatusColumn,
  TransactionAmountColumn,
  TransactionDateColumn,
  TransactionReferenceColumn,
  TransactionTypeColumn,
} from "../../Transactions/Grid/GridColumns";
import TransactionsGrid, {
  TransactionsSortColumnName,
  TransactionsSortDirection,
} from "../../Transactions/Grid/TransactionsGrid";

interface AccountTransactionsTabProps {
  account: Account;
}

/*
 * Default standard column list
 */
const Columns: TypeColumn[] = [
  TransactionReferenceColumn,
  TransactionTypeColumn,
  TransactionDateColumn,
  TransactionAmountColumn,
  DueDateColumn,
  PaidStatusColumn,
  OptionsColumn,
];

/*
 * Control for the accounts transaction tabs
 */
const AccountTransactionsTab = ({ account }: AccountTransactionsTabProps): ReactElement => {
  // Use our own loadData source which filters by account Id
  const loadData = async ({
    skip,
    limit,
    sortInfo,
    filterValue,
  }: LoadDataProps): Promise<{ data: Transaction[]; count: number }> => {
    const accountFilter = { name: "accountId", operator: "eq", type: "string", value: account.id } as TypeSingleFilterValue;
    const extendedFilters = filterValue ? [...filterValue, accountFilter] : [accountFilter];

    return loadPageData(
      skip,
      limit,
      sortInfo,
      TransactionsSortColumnName,
      TransactionsSortDirection,
      extendedFilters,
      async (pageParameters) => {
        const api = new TransactionsApi();
        const result = await api.getTransactions(
          pageParameters.page,
          pageParameters.itemsPerPage,
          pageParameters.sort,
          pageParameters.filter
        );
        return result;
      }
    );
  };

  const dataSource = useCallback(loadData, [account]);

  return (
    <Row>
      <Col className='panel-column'>
        <TransactionsGrid columns={Columns} dataSource={dataSource} />
      </Col>
    </Row>
  );
};

export default AccountTransactionsTab;
