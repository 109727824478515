/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { AuthService } from "../../../services/auth/auth.service";
import getAuthInfo from "../../../services/auth/authInfo";
import AuthStorage from "../../../services/auth/authStorage";
import AuthServiceProps from "../../../services/auth/models/authServiceProps";
import AbortControllerProvider from "../../providers/abortControllerProvider";
import HistoryProvider from "../../providers/historyProvider";
import LocationProvider from "../../providers/locationProvider";
import StorageProvider from "../../providers/storageProvider";
import getApiUrl from "./apiUrl";
import { getDatasetIdFromStorage } from "../../../helpers/storageHelpers";
import { jsonParseWithDate } from "../../../utils/jsonUtils";
import PayThemApiClientType from "../payThemApiClientType";

const { clientId, provider, audience, redirectUri } = getAuthInfo();
const authServiceBody: AuthServiceProps = {
  clientId,
  provider,
  audience,
  redirectUri,
};
const storageProvider = new StorageProvider();
const authStorage = new AuthStorage(storageProvider);
const locationProvider = new LocationProvider();
const historyProvider = new HistoryProvider();

const authService = new AuthService(authServiceBody, authStorage, locationProvider, historyProvider);

/*
 * Checks if the auth cookie we currently have is valid
 */
const isCookieValid = (): boolean => {
  if (!authStorage.isAuthenticated()) return false;
  if (authStorage.isExpired()) return false;
  return true;
};

let paythemClientInstance: AxiosInstance | null = null;
let paythemTenantOnlyClientInstance: AxiosInstance | null = null;
let paythemNoAuthenicationClientInstance: AxiosInstance | null = null;

const abortControllerInstance = new AbortControllerProvider();

/*
 * Creates the interceptor function for the axios instance
 */
const createInterceptorFunction =
  (clientType: PayThemApiClientType) =>
  (config: AxiosRequestConfig): AxiosRequestConfig<any> => {
    const requiresDataset = clientType === PayThemApiClientType.DatasetAndTeanant;
    const requiresAuthentication =
      clientType === PayThemApiClientType.DatasetAndTeanant || clientType === PayThemApiClientType.Tenant;

    // Allow cookie assign in request header
    // eslint-disable-next-line no-param-reassign
    config.withCredentials = true;

    if (!config.signal) {
      // eslint-disable-next-line no-param-reassign
      config.signal = abortControllerInstance.signal();
    }

    // Replace json conversion with our own
    config.transformResponse = (response) => {
      if (typeof response === "string") return jsonParseWithDate(response);
      return response;
    };

    // Add a dataset id if required
    if (requiresDataset) {
      const datasetId = getDatasetIdFromStorage();
      // eslint-disable-next-line no-param-reassign
      config.headers!["Dataset-Id"] = `${datasetId}`;
    }

    // Redirect to login if we're not authenticated and we need to be
    if (requiresAuthentication && !isCookieValid()) {
      abortControllerInstance.abort();
      const redirectUrl = authService.authorize();
      locationProvider.replace(redirectUrl);
      return config;
    }
    return config;
  };

const interceptorForErrorHandling = (error: unknown) => {
  if (!abortControllerInstance.signal()?.aborted) Promise.reject(error);
};

const createAxiosInstance = (): AxiosInstance =>
  axios.create({
    baseURL: getApiUrl(),
    signal: abortControllerInstance.signal(),
    xsrfHeaderName: "X-XSRF-PAYMENT",
    xsrfCookieName: "XSRF-PAYMENT",
  });

/*
 * Returns the PayThem api client instance
 */
export const getPayThemClient = (clientType: PayThemApiClientType = PayThemApiClientType.DatasetAndTeanant): AxiosInstance => {
  // Requires authentication and dataset

  let paythemInstance: AxiosInstance;

  switch (clientType) {
    case PayThemApiClientType.NoAuthentication:
      if (paythemNoAuthenicationClientInstance === null) {
        paythemNoAuthenicationClientInstance = createAxiosInstance();
      }
      paythemInstance = paythemNoAuthenicationClientInstance;
      break;
    case PayThemApiClientType.Tenant:
      if (paythemTenantOnlyClientInstance === null) {
        paythemTenantOnlyClientInstance = createAxiosInstance();
      }
      paythemInstance = paythemTenantOnlyClientInstance;
      break;
    case PayThemApiClientType.DatasetAndTeanant:
      if (paythemClientInstance === null) {
        paythemClientInstance = createAxiosInstance();
      }
      paythemInstance = paythemClientInstance;
      break;
    default:
      throw new Error("Invalid PayThemApiClientType");
  }

  const interceptorFunc = createInterceptorFunction(clientType);
  paythemInstance.interceptors.request.use(interceptorFunc, interceptorForErrorHandling);
  return paythemInstance;
};

/*
 * Clears down the PayThem client instance
 */
export const resetPayThemClient = (): void => {
  paythemClientInstance = null;
};
