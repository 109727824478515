const authConstants = {
  CODE: "code",
  PKCE: "pkce",
  AUTH: "auth",
  VALID_TO: "valid_to",
  DATASET_ID: "dataset-id",
  PRE_AUTH_URI: "preAuthUri",
  STATE: "state",
  RESPONSE_TYPE_CODE: "code",
  CODE_CHALLENGE_METHOD_S256: "S256",
  GRANT_TYPE: "authorization_code",
  FORM_URL_ENCODED: "application/x-www-form-urlencoded",
};

export default authConstants;

/*
 * The default dataset id
 */
export const DefaultDatasetId = 1;
