/* eslint-disable import/prefer-default-export */
import lang from "i18next";
import OpayoApi from "../../common/api/payThemApi/opayoApi";
import { tCommon } from "../../i18n";
import { GeneralResult } from "../../models/paymentProviders/opayo/opayoApiModels";
import { toastError, toastSuccess } from "../notifications/toastNotification.service";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

/*
 * Error codes we translate
 */
const errorCodeMap = new Map<string, string>([
  ["0008", "toastMessages.opayo.userOrVendorError"],
  ["0010", "toastMessages.opayo.passwordError"],
  ["0011", "toastMessages.opayo.adminPrivilegesRequiredError"],
  ["3002", "toastMessages.opayo.invalidVendorName"],
]);

const getErrorMessage = (result: GeneralResult): string => {
  if (result.errorCode === "3002") {
    if (result.detail.includes("<username>")) return "toastMessages.opayo.invalidUserName";
    if (result.detail.includes("<vendor>")) return "toastMessages.opayo.invalidVendorName";
  }
  const message = errorCodeMap.get(result.errorCode) ?? result.detail;
  return message;
};

/*
 * Tests the connection to Opayo
 */
export const testOpayoConnection = async (
  vendor: string,
  user: string,
  password: string | undefined,
  useTestServer: boolean,
  providerId?: string
): Promise<void> => {
  try {
    const api = new OpayoApi();
    const result = await api.testConnection(vendor, user, password, useTestServer, providerId);

    if (result.success) {
      toastSuccess(lang.t("toastMessages.opayo.testConnectionSuccess", { ...LangOptions, vendor }));
    } else {
      const message = getErrorMessage(result);
      const detail = lang.t(message, LangOptions);
      toastError(lang.t("toastMessages.opayo.testConnectionError", { ...LangOptions, vendor, detail }));
    }
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    throw error;
  }
};

/*
 * Checks the connection to Opayo by attempting to register the IP address
 */
export const checkAndRegisterIpAddress = async (
  vendor: string,
  user: string,
  password: string | undefined,
  useTestServer: boolean,
  providerId?: string
): Promise<boolean> => {
  try {
    const api = new OpayoApi();
    const result = await api.registerIpAddress(vendor, user, password, useTestServer, providerId);
    if (result.success) {
      return true;
    }
    const message = getErrorMessage(result);
    toastError(lang.t(message, LangOptions));
    return false;
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    return false;
  }
};
