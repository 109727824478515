import { ReactElement, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FeatureFlags, FlagsProvider } from "flagged";
import AuthProvider from "./components/auth/AuthProvider";
import { AuthService } from "./services/auth/auth.service";
import { Layout } from "./layout/Layout";
import { getHtmlPageTitle } from "./helpers/titleHelpers";
import "./i18n";
import AuthServiceProps from "./services/auth/models/authServiceProps";
import getAuthInfo from "./services/auth/authInfo";
import StorageProvider from "./common/providers/storageProvider";
import AuthStorage from "./services/auth/authStorage";
import LocationProvider from "./common/providers/locationProvider";
import HistoryProvider from "./common/providers/historyProvider";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import { getFeatureFlagsSettingsAsync } from "./helpers/featureFlagHelper";
import { initialiseAuthLogin } from "./services/auth/authInitialise.service";
import "./App.scss";
import "./assets/scss/main.scss";
import AppInsightsProvider from "./components/AppInsights/AppInsightsProvider";
import { reactPlugin } from './appInsights';
import RouteLoader from "./components/route/RouteLoader";

const { clientId, provider, audience, redirectUri } = getAuthInfo();
const authServiceBody: AuthServiceProps = {
  clientId,
  provider,
  audience,
  redirectUri,
};
const storageProvider = new StorageProvider();
const authStorage = new AuthStorage(storageProvider);
const locationProvider = new LocationProvider();
const historyProvider = new HistoryProvider();
const authService = new AuthService(authServiceBody, authStorage, locationProvider, historyProvider);
const queryClient = new QueryClient();

async function checkAuthorization() {
  const { redirectUrl, isAuthenticated } = await authService.initialize();

  if (redirectUrl) {
    // We're redirecting and just authenticated, so we need to initialise the login
    if (isAuthenticated) {
      await initialiseAuthLogin();
    }
    locationProvider.replace(redirectUrl);
  }
}

if (!locationProvider.pathName().includes("health")) {
  checkAuthorization();
}

/*
 * The App component is the root component of the application.
 */
const App = (): ReactElement => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();

  // Retrieve feature flags from the API
  useEffect(() => {
    getFeatureFlagsSettingsAsync().then((flags) => {
      setFeatureFlags(flags);
    });
  }, []);

  if (!featureFlags) {
    return <RouteLoader/>;
  }

  return (
    <AppInsightsProvider reactPlugin={reactPlugin}>
      <HelmetProvider>
        <Helmet>
          <title>{getHtmlPageTitle()}</title>
        </Helmet>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <FlagsProvider features={featureFlags}>
            <AuthProvider authService={authService}>
              <QueryClientProvider client={queryClient}>
                <Layout />
                <Toaster />
              </QueryClientProvider>
            </AuthProvider>
          </FlagsProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </AppInsightsProvider>
  );
};
export default App;
