import { ReactElement } from "react";
import { PaymentTransactionType } from "../../models/paymentTransactions/paymentTransactionEnums";
import { addSpacesToSentence } from "../../helpers/stringHelpers";
import "./PaymentTransactionTypeTag.scss";
import { BadgeSize } from "../../models/misc/badgeSize";

/*
 * Generates payment transaction type tag
 */
export const PaymentTransactionTypeTag = ({
  type,
  className,
  size,
}: {
  type: PaymentTransactionType;
  className?: string;
  size?: BadgeSize;
}): ReactElement => {
  const name = type.toLowerCase();
  const text = addSpacesToSentence(type).toUpperCase();
  let newClassName = className ? `${className} ` : "";
  newClassName += `details-status ${name ? ` ${name}-type` : ""}`;
  newClassName += `${size ? ` ${size}-size` : ""}`;
  return <div className={newClassName}>{text}</div>;
};

PaymentTransactionTypeTag.defaultProps = {
  size: BadgeSize.Medium,
  className: undefined,
};
export default PaymentTransactionTypeTag;
