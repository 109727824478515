export enum PaymentAllocationStatus {
  // The amount has been allocated to the transaction or account
  Allocated = "Allocated",
  // The amount has been provisionally reserved for transaction/account, but not yet allocated as it could be cancelled
  Provisional = "Provisional",
  // This item has been cancelled from an <see cref="PaymentAllocationStatus.Provisional"/> status
  Cancelled = "Cancelled",
}

export const PaymentAllocationStatusMap = new Map<PaymentAllocationStatus, string>([
  [PaymentAllocationStatus.Allocated, "Allocated"],
  [PaymentAllocationStatus.Provisional, "Provisional"],
  [PaymentAllocationStatus.Cancelled, "Cancelled"],
]);

export const PaymentAllocationStatusArray = Array.from(PaymentAllocationStatusMap.entries());
