import { ReactElement } from "react";
import { BadgeSize } from "../../models/misc/badgeSize";
import { AllocatedStatus } from "../../models/paymentTransactions/paymentTransactionEnums";
import { BasicTag } from "../../components/tags/BasicTag";
import "./AllocatedStatusTag.scss";

interface AllocatedStatusTagProps {
  allocatedStatus: AllocatedStatus;
  className?: string;
  size?: BadgeSize;
}

/*
 * Generates allocated status tag
 */
const AllocatedStatusTag = ({ allocatedStatus, className, size }: AllocatedStatusTagProps): ReactElement => (
  <BasicTag tagName={allocatedStatus} className={className} tagCssEnd='-status' size={size} />
);

AllocatedStatusTag.defaultProps = {
  className: undefined,
  size: BadgeSize.Medium,
};

export default AllocatedStatusTag;
