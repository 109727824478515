import { PaymentProviderConfiguration } from "../paymentProviders/paymentProviderConfiguration";
import { EditPaymentStatus } from "./editPaymentStatus";
import { UpdateTransactionChangeStatus } from "./updateTransactionChangeStatus";

export interface CompanyAccountInfo {
  companyId: string;
}

/* eslint-disable @typescript-eslint/no-inferrable-types */
export interface CompanyAddress {
  firstNames?: string;
  surname?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  telephone?: string;
  email?: string;
}

export interface CompanyDetails {
  name?: string;
  taxCode?: string;
  mainAddress: CompanyAddress;
  website?: string;
  logoFileName?: string;
  logoUrl?: string;
}

export interface CompanyPreferences {
  // Default country to select on the customer website if no country is supplied
  defaultCountryId: number;

  // Default settlement configuration group to use against new transactions
  defaultSettlementConfigurationGroupId?: string;

  // The default LatePaymentConfiguration to use
  defaultLatePaymentConfigurationId?: string;

  /// The default option for CAN customer edit the payment amounts on payment request transactions
  defaultEditPaymentStatus: EditPaymentStatus;

  // Option to allow the transaction currency to be changed on an update
  allowTransactionCurrencyChange: UpdateTransactionChangeStatus;
}

export interface CompanySettings {
  name?: string;
  companyDetails: CompanyDetails;
  preferences: CompanyPreferences;
  paymentProvider: PaymentProviderConfiguration;
}

export class CompanySettingsConsts {
  public static readonly CompanyNameMinLength: number = 1;

  public static readonly CompanyNameMaxLength: number = 100;

  public static readonly TaxCodeMaxLength: number = 50;

  public static readonly WebsiteMaxLength: number = 100;

  public static readonly FirstNamesMaxLength: number = 100;

  public static readonly SurnameMaxLength: number = 100;

  public static readonly Address1MaxLength: number = 100;

  public static readonly Address2MaxLength: number = 100;

  public static readonly CityMaxLength: number = 100;

  public static readonly StateMaxLength: number = 100;

  public static readonly PostCodeMaxLength: number = 100;

  public static readonly CountryMaxLength: number = 100;

  public static readonly TelephoneMaxLength: number = 100;

  public static readonly EmailAddressMaxLength: number = 500;
}
