import lang from "i18next";
import { Dataset } from "../../models/datasets/dataset";
import { DatasetsApi } from "../../common/api/payThemApi/datasetsApi";
import { RegisterDataset } from "../../models/datasets/registerDataset";
import { toastSuccess, toastError } from "../notifications/toastNotification.service";
import { DatasetStatus } from "../../models/datasets/datasetEnums";
import PageResult from "../../common/api/PageResults";
import { tCommon } from "../../i18n";
import { DatasetCustomEventName, getDatasetIdFromStorage, setDatasetIdToStorage } from "../../helpers/storageHelpers";
import { DefaultDatasetId } from "../auth/auth.constants";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;
const keyPrefix = "toastMessages.datasets";

const t = (s: string) => lang.t(`${keyPrefix}.${s}`, LangOptions);

/*
 * Checks if the dataset id exists, if not resets to the default
 */
export const checkAndUpdateCurrentDataset = async (): Promise<void> => {
  const ds = getDatasetIdFromStorage();
  if (ds === DefaultDatasetId) return;

  const api = new DatasetsApi();
  if ((await api.existsById(ds)) === true) {
    return;
  }
  // We need to reset the dataset to the default
  setDatasetIdToStorage(DefaultDatasetId);
  window.dispatchEvent(new Event(DatasetCustomEventName));
};

/*
 * Validates the user details before updating
 */
const validateUpdateDataset = (dataset: Dataset, datasets: Dataset[]): boolean => {
  let result = true;
  if (datasets.some((x) => x.hostId === dataset.hostId && x.id !== dataset.id)) {
    toastError(t("datasetHostIdExists"));
    result = false;
  }
  if (datasets.some((x) => x.customHostId === dataset.customHostId && x.id !== dataset.id)) {
    toastError(t("datasetCustomHostIdExists"));
    result = false;
  }
  return result;
};

/*
 * Gets the current dataset
 */
export const getCurrentDataset = async (): Promise<Dataset> => {
  const dsId = getDatasetIdFromStorage();
  const api = new DatasetsApi();
  try {
    const result = await api.getDatasetById(dsId);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "datasets.datasetsGetCurrent");
    throw error;
  }
};

/*
 * Registers a new dataset
 */
export const registerDataset = async (datasetDetails: RegisterDataset): Promise<Dataset | undefined> => {
  const api = new DatasetsApi();
  try {
    const result = await api.registerDataset(datasetDetails);
    toastSuccess(t("datasetAddSuccess"));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "datasets.datasetAdd");
    throw error;
  }
};

/*
 * Returns a page result of all datasets
 */
export const getAllDatasets = async (): Promise<PageResult<Dataset> | undefined> => {
  const api = new DatasetsApi();
  try {
    const result = await api.getAllDatasets();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "datasets.datasetsGet");
    throw error;
  }
};

/*
 * Updates the dataset
 */
export const updateDataset = async (dataset: Dataset): Promise<Dataset> => {
  const api = new DatasetsApi();
  try {
    const datasets = await api.getAllDatasets();
    if (!validateUpdateDataset(dataset, datasets.items)) return dataset;

    const result = await api.updateDataset(dataset);
    toastSuccess(t("datasetUpdateSuccess"));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "datasets.datasetUpdate");
    throw error;
  }
};

/*
 * Deletes the dataset with the id
 */
export const deleteDataset = async (id: number): Promise<void> => {
  const api = new DatasetsApi();
  try {
    const result = await api.deleteDataset(id);
    toastSuccess(t("datasetDeleteSuccess"));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "datasets.datasetDelete");
    throw error;
  }
};

export const resetDataset = async (id: number): Promise<void> => {
  const api = new DatasetsApi();
  try {
    const result = await api.resetDataset(id);
    toastSuccess(t("datasetResetSuccess"));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "datasets.datasetReset");
    throw error;
  }
};

/*
 * Returns true if the dataset status is a type of processing status
 */
export const isProcessingDatasetStatus = (status: DatasetStatus): boolean => {
  switch (status) {
    case DatasetStatus.Initialise:
    case DatasetStatus.Provisioning:
    case DatasetStatus.Deleting:
    case DatasetStatus.Migrating:
    case DatasetStatus.Resetting:
      return true;
    default:
      return false;
  }
};

/*
 * Returns true if the dataset status is a type of error status
 */
export const isErrorDatasetStatus = (status: DatasetStatus): boolean => status === DatasetStatus.Error;
