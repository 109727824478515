import { ReactElement } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getAllowInternalNotifications } from "../../services/tenantSettings/tenantSettings.service";
import TenantSettingAlert from "./TenantSettingAlert";
import { tPages } from "../../i18n";

const keyPrefix = "notificationsPage";

/*
 * Displays an alert if internal notifications are not enabled
 */
const AllowInternalNotificationsAlert = (): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });

  const { data: allow, isLoading } = useQuery([QueryNameConsts.AllowInternalNotifications], () =>
    getAllowInternalNotifications()
  );

  return (
    <TenantSettingAlert allow={isLoading || allow}>
      <p>{t("userNotificationsAlert")}</p>
      <p>{t("contractDraycirAlert")}</p>
    </TenantSettingAlert>
  );
};

export default AllowInternalNotificationsAlert;
