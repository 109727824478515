import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../loading/Loading";
import { tCommon } from "../../i18n";

/*
 * Displays a loading spinner while the route is loading
 */
const RouteLoader = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns);
  return (
    <Container>
      <Loading message={t("loadingPayThem")} />
    </Container>
  );
};

export default RouteLoader;
