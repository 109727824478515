import { TypeDataSource } from "@inovua/reactdatagrid-community/types";
import { TypeColumn } from "@inovua/reactdatagrid-community/types/TypeColumn";
import { ReactElement, useState } from "react";
import { DataGrid } from "../../../components/grid/DataGrid";
import { Payer } from "../../../models/payers/payer";
import { DataGridReadyItem } from "../../../components/grid/dataGridHelpers";

interface PayersGridProps {
  columns: TypeColumn[];
  dataSource: TypeDataSource;
  onReady?: (computedPropsRef: DataGridReadyItem) => void;
}

/*
 * Displays the payers grid
 */
const PayersGrid = ({ columns, dataSource, onReady }: PayersGridProps): ReactElement => {
  const [gridColumns] = useState(columns);

  return (
    <DataGrid<Payer> idProperty='id' columns={gridColumns} dataSource={dataSource} enableFiltering={false} onReady={onReady} />
  );
};

PayersGrid.defaultProps = {
  onReady: undefined,
};

export default PayersGrid;
