import { useFeature } from "flagged";
import { ReactElement } from "react";
import PrivateRoute from "./PrivateRoute";
import { PaymentRequestList } from "../../views/PaymentRequests/PaymentRequestList";
import { PaymentRequestItem } from "../../views/PaymentRequests/PaymentRequestItem";
import { PaymentTransactionsList } from "../../views/PaymentTransactions/PaymentTransactionsList";
import { PaymentTransactionItem } from "../../views/PaymentTransactions/PaymentTransactionItem";
import { AccountList } from "../../views/Accounts/AccountList";
import { AccountItem } from "../../views/Accounts/AccountItem";
import { TransactionsList } from "../../views/Transactions/TransactionList";
import { TransactionItem } from "../../views/Transactions/TransactionItem";
import UserSettings from "../../views/CurrentUser/UserSettings";
import { BankList } from "../../views/Banks/BankList";
import { BankItem } from "../../views/Banks/BankItem";
import { MainRouteConsts } from "../../views/MainRoutesConsts";
import ComingSoon from "../../views/ComingSoon";

import SettlementConfigurationsList from "../../views/Settlements/SettlementsList";
import SettlementConfigurationItem from "../../views/Settlements/SettlementItem";
import NewSettlementConfigurationItem from "../../views/Settlements/NewSettlementItem";
import LatePaymentConfigurationsList from "../../views/PaymentFees/LatePaymentConfigurationList";
import PayerItem from "../../views/Accounts/Payers/PayerItem";
import ActivitiesTabs from "../../views/Activities/ActivitiesTabs";
import UserPermission from "../../models/users/userPermission";

const PaymentPlansWithFeatureFlag = (): ReactElement => {
  const hasFeature = useFeature("paymentPlans");
  return hasFeature ? <ComingSoon /> : <ComingSoon />;
};

const SettlementConfigurationWithFeatureFlag = (): ReactElement => {
  const hasFeature = useFeature("settlementConfigurations");
  return hasFeature ? <SettlementConfigurationsList /> : <ComingSoon />;
};

export default [
  <PrivateRoute
    key={MainRouteConsts.PaymentRequests}
    path={MainRouteConsts.PaymentRequests}
    exact
    component={PaymentRequestList}
    requiredPermissions={[]}
    fullWidth
  />,
  <PrivateRoute
    key={`${MainRouteConsts.PaymentRequests}id`}
    path={MainRouteConsts.PaymentRequests}
    parameters=':id'
    component={PaymentRequestItem}
    requiredPermissions={[]}
  />,
  <PrivateRoute
    key={MainRouteConsts.PaymentTransactions}
    path={MainRouteConsts.PaymentTransactions}
    exact
    component={PaymentTransactionsList}
    requiredPermissions={[]}
    fullWidth
  />,
  <PrivateRoute
    key={`${MainRouteConsts.PaymentTransactions}id`}
    path={MainRouteConsts.PaymentTransactions}
    parameters=':id'
    component={PaymentTransactionItem}
    requiredPermissions={[]}
  />,
  <PrivateRoute
    key={MainRouteConsts.Accounts}
    path={MainRouteConsts.Accounts}
    exact
    component={AccountList}
    requiredPermissions={[]}
    fullWidth
  />,
  <PrivateRoute
    key={`${MainRouteConsts.Accounts}accountId${MainRouteConsts.Payers}id`}
    path={`${MainRouteConsts.Accounts}/:accountId${MainRouteConsts.Payers}/:id`}
    component={PayerItem}
    requiredPermissions={[]}
  />,
  <PrivateRoute
    key={`${MainRouteConsts.Accounts}id`}
    path={MainRouteConsts.Accounts}
    parameters=':id'
    component={AccountItem}
    requiredPermissions={[]}
  />,
  <PrivateRoute
    key={MainRouteConsts.Transactions}
    path={MainRouteConsts.Transactions}
    exact
    component={TransactionsList}
    requiredPermissions={[]}
    fullWidth
  />,
  <PrivateRoute
    key={`${MainRouteConsts.Transactions}id`}
    path={MainRouteConsts.Transactions}
    parameters=':id'
    component={TransactionItem}
    requiredPermissions={[]}
  />,
  <PrivateRoute
    key={MainRouteConsts.Settlements}
    path={MainRouteConsts.Settlements}
    exact
    component={SettlementConfigurationWithFeatureFlag}
    requiredPermissions={[]}
    fullWidth
  />,

  <PrivateRoute
    key={`${MainRouteConsts.Settlements}new`}
    path={`${MainRouteConsts.Settlements}/new`}
    component={NewSettlementConfigurationItem}
    requiredPermissions={[]}
  />,

  <PrivateRoute
    key={`${MainRouteConsts.Settlements}id`}
    path={MainRouteConsts.Settlements}
    parameters=':id'
    component={SettlementConfigurationItem}
    requiredPermissions={[]}
  />,

  <PrivateRoute
    key={MainRouteConsts.LatePayments}
    path={MainRouteConsts.LatePayments}
    exact
    component={LatePaymentConfigurationsList}
    requiredPermissions={[]}
    fullWidth
  />,

  <PrivateRoute
    key={MainRouteConsts.Banks}
    path={MainRouteConsts.Banks}
    exact
    component={BankList}
    requiredPermissions={[]}
    fullWidth
  />,

  <PrivateRoute
    key={`${MainRouteConsts.Banks}id`}
    path={MainRouteConsts.Banks}
    parameters=':id'
    component={BankItem}
    requiredPermissions={[]}
  />,

  <PrivateRoute
    key={MainRouteConsts.UserSettings}
    path={MainRouteConsts.UserSettings}
    component={UserSettings}
    requiredPermissions={[]}
  />,

  <PrivateRoute 
    key={MainRouteConsts.Activities} 
    path={MainRouteConsts.Activities} 
    exact 
    component={ActivitiesTabs} 
    requiredPermissions={[UserPermission.ViewAuditLogs]}
    fullWidth />,

  <PrivateRoute
    key={MainRouteConsts.PaymentPlans}
    path={MainRouteConsts.PaymentPlans}
    exact
    component={PaymentPlansWithFeatureFlag}
    requiredPermissions={[]}
  />,
];
