/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from "react";
import { Accordion, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import { FieldErrors, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { DialogPrimaryButton, DialogSecondaryButton } from "../../../components/dialogs/DialogButtons";
import { CurrencyInfo, getSortedRealCurrencies } from "../../../utils/currencyCodeUtils";
import { Account } from "../../../models/accounts/account";
import { getCurrentDataset } from "../../../services/datasets/dataset.service";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { validateFromToDates } from "../../../helpers/validation/yupValidationHelpers";
import { tForms } from "../../../i18n";
import ClearButton from "../../../components/buttons/ClearButton";
import FormLabelOptional from "../../../components/forms/FormLabel";
import { RequestCaptureType, RequestCaptureTypeArray } from "../../../models/paymentRequests/requestCaptureType";
import "./NewPaymentRequestDialog.scss";

const keyPrefix = "newPaymentRequestDialog";

/*
 * Used only in debugging/developer mode
 */

export type PaymentRequestFormModel = {
  captureType: RequestCaptureType;
  currency: string;
  amount: number;
  paymentRequiredBy?: Date;
  validFrom?: Date;
  validUntil?: Date;
  description: string;
  billingFirstName: string;
  billingLastName: string;
  billingCompanyName: string;
  billingEmailAddress: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingPostCode: string;
  billingCountry: string;
};

const dateValidationTestName = "Date";
const dateValidationTestErrorMessage = "Valid To must be less than Valid From";

// Our validation schema
const validationSchema = Yup.object({
  captureType: Yup.string().label("Capture Type").required(),
  currency: Yup.string().label("Currency").required(),
  amount: Yup.number()
    .label("Amount")
    .typeError("Amount must be a number")
    .moreThan(0)
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(),
  description: Yup.string().label("Description").max(1000),
  validFrom: Yup.date()
    .label("Valid From")
    .transform((current, original) => (original === "" ? null : current))
    .nullable()
    .notRequired(),
  validUntil: Yup.date()
    .label("Valid To")
    .transform((current, original) => (original === "" ? null : current))
    .nullable()
    .notRequired()
    .test(dateValidationTestName, dateValidationTestErrorMessage, (validUntil, items) => {
      const { validFrom }: { validFrom: Date } = items.parent;
      return validateFromToDates(validFrom, validUntil);
    }),
  paymentRequiredBy: Yup.date()
    .label("Payment Required By")
    .transform((current, original) => (original === "" ? null : current))
    .nullable()
    .notRequired(),

  billingFirstName: Yup.string().label("First Name").max(100),
  billingLastName: Yup.string().label("Last Name").max(100),
  billingCompanyName: Yup.string().label("Company Name").max(100),
  billingEmailAddress: Yup.string().label("Email Address").max(200),
  billingAddress1: Yup.string().label("Address 1").max(100),
  billingAddress2: Yup.string().label("Address 2").max(100),
  billingCity: Yup.string().label("City").max(100),
  billingState: Yup.string().label("State").max(100),
  billingPostCode: Yup.string().label("Post Code").max(20),
  billingCountry: Yup.string().label("Country").max(100),
}).required();

interface NewPaymentRequestDialogProps {
  account: Account;
  show: boolean;
  onSave: (formModel: PaymentRequestFormModel) => Promise<void>;
  onHide: () => void;
}

const NewPaymentRequestDialog = ({ account, show, onSave, onHide }: NewPaymentRequestDialogProps): ReactElement => {
  const [enableComponent, setEnableComponent] = useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] = useState<CurrencyInfo>();
  const { t } = useTranslation(tForms.ns, { keyPrefix });

  const { data: dataset, isLoading } = useQuery([QueryNameConsts.DatasetCurrent], () => getCurrentDataset());

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PaymentRequestFormModel>({ resolver: yupResolver(validationSchema) });

  const handleExited = () => {
    setEnableComponent(false);
    reset();
  };

  const handleSave = (formModel: PaymentRequestFormModel) => {
    const model = { ...formModel };
    model.currency = model.currency?.trim();
    model.description = model.description.trim();
    model.billingFirstName = model.billingFirstName.trim();
    model.billingLastName = model.billingLastName.trim();
    model.billingCompanyName = model.billingCompanyName.trim();
    model.billingEmailAddress = model.billingEmailAddress.trim();
    model.billingAddress1 = model.billingAddress1.trim();
    model.billingAddress2 = model.billingAddress2.trim();
    model.billingCity = model.billingCity.trim();
    model.billingState = model.billingState.trim();
    model.billingPostCode = model.billingPostCode.trim();
    model.billingCountry = model.billingCountry.trim();

    onSave(model);
  };

  const handleInvalid = (fieldErrors: FieldErrors<PaymentRequestFormModel>) => {
    // console.log("Invalid", fieldErrors);
    // Deal with showing the correct location
  };

  // We do this so that the modal is created and show
  useEffect(() => {
    if (show) setEnableComponent(true);
  }, [show]);

  // Find the correct default currency to use
  useEffect(() => {
    if (dataset && !isLoading) {
      const currencies = getSortedRealCurrencies();
      const findIso4217 = account.currency ?? dataset.currency ?? currencies[0].iso4217;
      const result = currencies.find((currency) => currency.iso4217 === findIso4217);
      setDefaultCurrency(result);
    }
  }, [account, dataset, isLoading]);

  if (!enableComponent || !defaultCurrency) return <></>;

  const handleSelectionChange = (event: AccordionEventKey) => {};

  return (
    <Modal show={show} onHide={onHide} onExited={handleExited}>
      <Modal.Header closeButton>
        <Modal.Title>{t("dialogTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-with-accordion'>
        <Form
          id='paymentRequestForm'
          noValidate
          onSubmit={handleSubmit(
            (submitData) => handleSave(submitData),
            (err) => handleInvalid(err)
          )}
        >
          <Accordion flush defaultActiveKey='0' className='full-accordion' onSelect={handleSelectionChange}>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>{t("sectionMainInfo")}</Accordion.Header>
              <Accordion.Body>
                <Row className='dialog-row'>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>{t("labelCaptureType")}</Form.Label>
                      <Form.Select {...register("captureType")} defaultValue={defaultCurrency.iso4217}>
                        {RequestCaptureTypeArray.map((captureType) => (
                          <option key={captureType[0]} value={captureType[0]}>
                            {captureType[1]}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>{t("labelRequiredBy")}</Form.Label>
                      <Form.Control
                        type='date'
                        {...register("paymentRequiredBy")}
                        className={`mb-0 form-control ${errors.paymentRequiredBy ? "is-invalid" : ""}`}
                      />
                      <ClearButton onClick={() => setValue("paymentRequiredBy", undefined)} />
                      <Form.Control.Feedback type='invalid'>{errors?.paymentRequiredBy?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='dialog-row'>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>{t("labelCurrency")}</Form.Label>
                      <Form.Select {...register("currency")} defaultValue={defaultCurrency.iso4217}>
                        {getSortedRealCurrencies().map((currency) => (
                          <option key={currency.iso4217} value={currency.iso4217}>
                            {currency.iso4217}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelAmount")}</Form.Label>
                      <Form.Control
                        type='number'
                        placeholder={t("placeholderAmount")}
                        {...register("amount")}
                        className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.amount?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <FormLabelOptional label={t("labelDescription")} />
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderDescription")}
                        {...register("description")}
                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>{t("sectionBillingContact")}</Accordion.Header>
              <Accordion.Body>
                <Row className='dialog-row last'>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelFirstName")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderFirstName")}
                        {...register("billingFirstName")}
                        className={`form-control ${errors.billingFirstName ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingFirstName?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelLastName")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderLastName")}
                        {...register("billingLastName")}
                        className={`form-control ${errors.billingLastName ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingLastName?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>{t("labelEmailAddress")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderEmailAddress")}
                        {...register("billingEmailAddress")}
                        className={`form-control ${errors.billingEmailAddress ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingEmailAddress?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>{t("labelCompanyName")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderCompanyName")}
                        {...register("billingCompanyName")}
                        defaultValue={account.name}
                        className={`form-control ${errors.billingCompanyName ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingCompanyName?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>{t("labelAddress1")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderAddress1")}
                        {...register("billingAddress1")}
                        className={`form-control ${errors.billingAddress1 ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingAddress1?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderAddress2")}
                        {...register("billingAddress2")}
                        className={`form-control ${errors.billingAddress2 ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingAddress2?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelCity")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderCity")}
                        {...register("billingCity")}
                        className={`form-control ${errors.billingCity ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingCity?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelState")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderState")}
                        {...register("billingState")}
                        className={`form-control ${errors.billingState ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingState?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelPostCode")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderPostCode")}
                        {...register("billingPostCode")}
                        className={`form-control ${errors.billingPostCode ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingPostCode?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("labelCountry")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderCountry")}
                        {...register("billingCountry")}
                        className={`form-control ${errors.billingCountry ? "is-invalid" : ""}`}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.billingCountry?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>{t("sectionOptions")}</Accordion.Header>
              <Accordion.Body>
                <Row className='dialog-row xvalid-dates-row'>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>{t("labelValidFrom")}</Form.Label>
                      <Form.Control
                        type='date'
                        {...register("validFrom")}
                        className={`mb-0 form-control ${errors.validFrom ? "is-invalid" : ""}`}
                      />
                      <ClearButton onClick={() => setValue("validFrom", undefined)} />
                      <Form.Control.Feedback type='invalid'>{errors?.validFrom?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>{t("labelValidUntil")}</Form.Label>
                      <Form.Control
                        type='date'
                        {...register("validUntil")}
                        className={`mb-0 form-control ${errors.validUntil ? "is-invalid" : ""}`}
                      />
                      <ClearButton onClick={() => setValue("validUntil", undefined)} />
                      <Form.Control.Feedback type='invalid'>{errors?.validUntil?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={onHide} />
        <DialogPrimaryButton form='paymentRequestForm' type='submit' label={t("buttonCreate")} />
      </Modal.Footer>
    </Modal>
  );
};

export default NewPaymentRequestDialog;
