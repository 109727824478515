import { Country } from "../../../models/geoData/Country";
import { State } from "../../../models/geoData/State";
import BasePayThemApi from "./basePayThemApi";

export class GeoDataApi extends BasePayThemApi {
  private countryEndPoint = "geo-data/countries";

  private stateEndPoint = "geo-data/states";

  // Returns the country with the given id
  public async getCountry(countryId: number): Promise<Country | unknown> {
    const result = await this.getItem<Country, number>(this.countryEndPoint, countryId);
    return result;
  }

  // Returns an array of all countries
  public async getAllCountries(): Promise<Country[]> {
    const result = await this.get<Country[]>(this.countryEndPoint);
    return result;
  }

  /// Returns an array of states associated to the country id
  public async getStatesByCountry(countryId: number): Promise<State[]> {
    const result = await this.get<State[]>(`${this.countryEndPoint}/${countryId}/states`);
    return result;
  }

  // Returns the state with the given id
  public async getState(stateId: number): Promise<State | unknown> {
    const result = await this.getItem<State, number>(this.stateEndPoint, stateId);
    return result;
  }
}
export default GeoDataApi;
