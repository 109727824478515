import { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import { PanelItem } from "../../components/panels/Panels";
import { textOrDefault } from "../../helpers/stringHelpers";
import { tPages } from "../../i18n";
import { Account } from "../../models/accounts/account";
import AccountTypeTag from "./AccountTypeTag";

const keyPrefix = "accountPage.details";

/*
 * The main details card
 */
const AccountDetails = ({ account }: { account: Account }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  return (
    <Card className='custom-detail-card'>
      <Card.Body>
        <Card.Title className='details-title'>
          <span>{t("title")}</span>
          <div className='tag-items'>
            <AccountTypeTag className='tag-end' type={account.accountType} name={account.accountType.toString()} />
          </div>
        </Card.Title>
        <Card.Text as='div'>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className='main-item'>
                <div className='title'>{t("accountName")}</div>
                <div className='content'>{account.name}</div>
              </div>
              <div className='main-item'>
                <div className='title'>{t("balance")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem currency={account.currency} value={account.balance} />
                </div>
              </div>
              <div className='main-item'>
                <div className='title'>{t("overdueAmount")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem currency={account.currency} value={account.overdueAmount} />
                </div>
              </div>
              <div className='main-item'>
                <div className='title'>{t("disputedAmount")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem currency={account.currency} value={account.disputedAmount} />
                </div>
              </div>
              <div className='main-item'>
                <div className='title'>{t("creditLimit")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem currency={account.currency} value={account.creditLimit} />
                </div>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <PanelItem title={t("accountReference")}>{textOrDefault(account.accountReference)}</PanelItem>
              <PanelItem title={t("accountShortName")}>{textOrDefault(account.shortName)}</PanelItem>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
export default AccountDetails;
