import { ReactElement, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";
import { DialogSecondaryButton, DialogPrimaryButton } from "../dialogs/DialogButtons";

interface ItemFiltersDialogProps {
  show: boolean;
  title: string;
  formId: string;
  children: ReactNode;
  onClose: () => void;
  onExited?: () => void;
  onShow?: () => void;
}

/*
 * Display standard items filter dialog
 */
const ItemFiltersDialog = ({
  show,
  title,
  formId,
  children,
  onClose,
  onExited,
  onShow,
}: ItemFiltersDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);

  return (
    <Modal show={show} onHide={onClose} onExited={onExited} onShow={onShow}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0'>{children}</Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={onClose} />
        <DialogPrimaryButton form={formId} type='submit' label={t("buttonUpdate")} />
      </Modal.Footer>
    </Modal>
  );
};

ItemFiltersDialog.defaultProps = {
  onExited: undefined,
  onShow: undefined,
};

export default ItemFiltersDialog;
