import { Account } from "../../../models/accounts/account";
import { AccountSettings } from "../../../models/accounts/accountSettings";
import { CountResult } from "../../../models/count/countResult";
import { Payer } from "../../../models/payers/payer";
import PageResult from "../PageResults";
import { AccountIncludeOptions } from "./accountIncludeOptions";
import BasePayThemApi from "./basePayThemApi";
import { buildExpandParameter } from "./includeOptions";
import { PayerIncludeOptions } from "./payerIncludeOptions";

export class AccountsApi extends BasePayThemApi {
  private endPoint = "accounts";

  // Return an account
  public async getAccount(id: string, includeOptions?: AccountIncludeOptions): Promise<Account> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.getItem<Account, string>(this.endPoint, id, params);
    return result;
  }

  // Returns a page of accounts
  public async getAccounts(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string,
    includeOptions?: AccountIncludeOptions
  ): Promise<PageResult<Account>> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.getPage<Account>(this.endPoint, page, itemsPerPage, sort, filter, params);
    return result;
  }

  /*
   * Updates the account settings for the given account
   */
  public async updateAccountSettings(accountId: string, accountSettings: AccountSettings): Promise<void> {
    const url = `${this.endPoint}/${accountId}/settings`;
    await this.put<AccountSettings>(url, accountSettings);
  }

  /*
   * Returns a page of payers associated to the account
   */
  public async getAccountPayers(
    accountId: string,
    page: number,
    itemsPerPage: number,
    sort?: string | null,
    includeOptions?: PayerIncludeOptions
  ): Promise<PageResult<Payer>> {
    const params = buildExpandParameter(includeOptions);
    const url = `${this.endPoint}/${accountId}/payers`;
    const result = await this.getPage<Payer>(url, page, itemsPerPage, sort, null, params);
    return result;
  }

  /*
   * Returns the count of payers attached to the account
   */
  public async getAccountPayerCount(accountId: string): Promise<CountResult> {
    const url = `${this.endPoint}/${accountId}/payers/count`;
    const result = await this.get<CountResult>(url);
    return result;
  }

  /*
   * Returns a payer that is associated to the account
   */
  public async getAccountPayer(accountId: string, payerId: string, includeOptions?: PayerIncludeOptions): Promise<Payer> {
    const params = buildExpandParameter(includeOptions);
    const url = `${this.endPoint}/${accountId}/payers`;
    const result = await this.getItem<Payer, string>(url, payerId, params);
    return result;
  }

  // Commented out until we're happy with the API
  // // Updates the settlement configuration group id in the account
  // public async updateAccountSettlementConfigurationGroup(
  //   accountId: string,
  //   disableAutoApplySettlements: boolean,
  //   settlementConfigurationGroupId: string | null | undefined
  // ): Promise<Account> {
  //   const url = `${this.endPoint}/${accountId}/settings/settlement-configuration-group`;
  //   const body = { disableAutoApplySettlements, settlementConfigurationGroupId };
  //   const result = await this.putWithResponse<unknown, Account>(url, body);
  //   return result;
  // }

  // // Updates the late payment configuration group id in the account
  // public async updateAccountLatePaymentConfiguration(
  //   accountId: string,
  //   disableLatePayment: boolean,
  //   latePaymentConfigurationId?: string
  // ): Promise<Account> {
  //   const url = `${this.endPoint}/${accountId}/settings/late-payment-group`;
  //   const body = { disableLatePayment, latePaymentConfigurationGroupId: latePaymentConfigurationId };
  //   const result = await this.putWithResponse<unknown, Account>(url, body);
  //   return result;
  // }
}

export default AccountsApi;
