/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../common/PageTitle";
import { HtmlTitle } from "../../common/HtmlTitle";
import { tPages } from "../../../i18n";
import SettingsTabs from "./SettingsTabs";
import "../../viewsCommon.scss";

export const Preferences = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  return (
    <section className='item-view-section'>
      <HtmlTitle subTitle1={t("pageTitlePreferences")} />
      <PageTitle title='pageTitlePreferences' />
      <SettingsTabs />
    </section>
  );
};

export default Preferences;
