import { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";

interface ClearButtonProps {
  onClick: () => void;
}

const ClearButton = ({ onClick }: ClearButtonProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  return (
    <Button size='sm' variant='link' className='clear-button' onClick={() => onClick()}>
      {t("buttonClear")}
    </Button>
  );
};

export default ClearButton;
