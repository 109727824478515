/* eslint-disable react/no-danger */
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyItem } from '../../components/misc/CurrencyItem';
import { PanelItem } from '../../components/panels/Panels';
import { tPages } from '../../i18n';
import { SettlementConfiguration } from '../../models/settlements/settlementConfiguration';
import { SettlementType } from '../../models/settlements/settlementEnums';
import TransactionDateType, {
  TransactionDateTypeMap,
} from '../../models/transactions/transactionDateTypeEnum';

const keyPrefix = 'settlementConfigurationGroupPage';

/*
 * Shows the percent discount
 */
const PercentageDiscountEntry = ({
  configuration,
}: {
  configuration: SettlementConfiguration;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateType = configuration.dateType ?? TransactionDateType.DueDate;

  const dateTypeLabel =
    dateType === TransactionDateType.TransactionDate
      ? 'labelDateTypeAfter'
      : 'labelDateTypeBefore';

  return (
    <>
      <PanelItem title={t('labelNumberOfDays')}>
        {configuration.numberOfDays}
      </PanelItem>
      <PanelItem title={t(dateTypeLabel)}>
        {TransactionDateTypeMap.get(dateType)}
      </PanelItem>
      <PanelItem title={t('labelPercentageDiscount')}>
        {configuration.percentageDiscount}%
      </PanelItem>
    </>
  );
};

const AbsoluteDiscountEntry = ({
  configuration,
}: {
  configuration: SettlementConfiguration;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateType = configuration.dateType ?? TransactionDateType.DueDate;
  const currency = configuration.currency ?? '';

  return (
    <>
      <PanelItem title={t('labelNumberOfDays')}>
        {configuration.numberOfDays}
      </PanelItem>
      <PanelItem title={t('labelDateTypeBefore')}>
        {TransactionDateTypeMap.get(dateType)}
      </PanelItem>
      <PanelItem title={t('labelAmountDiscount')}>
        <CurrencyItem currency={currency} value={configuration.amount} />
      </PanelItem>
    </>
  );
};

const SettlementConfigurationDiscountEntry = ({
  configuration,
}: {
  configuration: SettlementConfiguration;
}): ReactElement => {
  let typeDiscount: ReactElement;
  switch (configuration.type) {
    case SettlementType.PercentageDiscount:
      typeDiscount = <PercentageDiscountEntry configuration={configuration} />;
      break;
    case SettlementType.AbsoluteDiscount:
      typeDiscount = <AbsoluteDiscountEntry configuration={configuration} />;
      break;
    case SettlementType.ReplacementAmount:
      typeDiscount = <>ReplacementAmount</>;
      break;
    default:
      typeDiscount = <></>;
  }
  return typeDiscount;
};

export default SettlementConfigurationDiscountEntry;
