/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tPages } from '../../i18n';
import { SettlementConfiguration } from '../../models/settlements/settlementConfiguration';
import { SettlementConfigurationGroup } from '../../models/settlements/settlementConfigurationGroup';
import {
  deleteSettlementConfiguration,
  getSettlementConfigurationGroup,
  addSettlementConfiguration,
  updateSettlementConfiguration,
} from '../../services/settlements/settlementConfiguration.service';
import AddSettlementConfigurationButton from './AddSettlementConfigurationButton';
import SettlementConfigurationEntry from './SettlementConfigurationEntry';
import './SettlementConfigurationList.scss';

const keyPrefix = 'settlementConfigurationGroupPage';

interface SettlementConfigurationListProps {
  configurationGroup: SettlementConfigurationGroup;
}

const SettlementConfigurationList = ({
  configurationGroup,
}: SettlementConfigurationListProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [configurations, setConfigurations] = useState(
    configurationGroup?.configurations
  );

  const handleSave = async (
    settlementConfiguration: SettlementConfiguration
  ) => {
    const result = await addSettlementConfiguration(
      configurationGroup.id,
      settlementConfiguration
    );
    setConfigurations(result.configurations);
  };

  const handleEdit = async (
    settlementConfiguration: SettlementConfiguration
  ) => {
    const result = await updateSettlementConfiguration(
      configurationGroup.id,
      settlementConfiguration
    );
    setConfigurations(result.configurations);
  };

  const handleDelete = async (id: string) => {
    await deleteSettlementConfiguration(configurationGroup.id, id);
    const result = await getSettlementConfigurationGroup(configurationGroup.id);
    setConfigurations(result.configurations);
  };

  return (
    <div>
      <AddSettlementConfigurationButton
        onSave={(newConfiguration) => handleSave(newConfiguration)}
      />
      <Row>
        <Col className='panel-column'>
          <Card className='--scrollable-div custom-detail-card'>
            <Card.Body>
              {configurations && configurations.length > 0
                ? configurations.map((configuration, index) => (
                    <div key={configuration.id}>
                      {index > 0 ? <hr /> : <></>}
                      <SettlementConfigurationEntry
                        configuration={configuration}
                        onDelete={(id) => handleDelete(id)}
                        onEdit={(settlementConfiguration) =>
                          handleEdit(settlementConfiguration)
                        }
                      />
                    </div>
                  ))
                : t('noConfigurationsFound')}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SettlementConfigurationList;
