/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Form, Row } from "react-bootstrap";
import { UseFormRegister, FieldValues, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { tPaymentProviders } from "../../../../i18n";
import BooleanFormSelect from "../ProvidersCommon/BooleanFormSelect";
import { CurrentPaymentProviderConfiguration } from "./stripeConfiguration";

const keyPrefix = "stripeForm";

interface StripeOptionsTabsProps {
  data?: CurrentPaymentProviderConfiguration;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

/*
 * Options tab for Stripe
 */
const StripeOptionsTabs = ({ data, register, watch, setValue }: StripeOptionsTabsProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  const defaultUseStripeCustomerStatus = data?.settings ? data?.settings.useStripeCustomer : false;
  const defaultStorePayerPaymentMethod = data?.settings ? data?.settings.storePayerPaymentMethod : false;
  const defaultSaveCardOptionDefault = data?.settings ? data?.settings.saveCardOptionDefault : false;

  // Watch this status so we can enable/disable storePayerPaymentMethod selection
  const watchUseStripeCustomer = watch("useStripeCustomer", defaultUseStripeCustomerStatus);
  const watchtStorePayerPaymentMethod = watch("storePayerPaymentMethod", defaultStorePayerPaymentMethod) as boolean;

  // Updates the storePayerPaymentMethod status based on useStripeCustomer
  useEffect(() => {
    if (!watchUseStripeCustomer) {
      setValue("storePayerPaymentMethod", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchUseStripeCustomer, setValue]);

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group as={Col} sm='6'>
                  <Form.Label>{t("labelStoreCustomers")}</Form.Label>
                  <Col xs='1'>
                    <BooleanFormSelect
                      register={register}
                      fieldName='useStripeCustomer'
                      trueOption={t("labelEnabledOption")}
                      falseOption={t("labelDisabledOption")}
                      defaultState={defaultUseStripeCustomerStatus}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} sm='6'>
                  <Form.Label>{t("labelStoreCardDetails")}</Form.Label>
                  <Col xs='1'>
                    <BooleanFormSelect
                      register={register}
                      fieldName='storePayerPaymentMethod'
                      trueOption={t("labelEnabledOption")}
                      falseOption={t("labelDisabledOption")}
                      defaultState={defaultStorePayerPaymentMethod}
                      disabled={!watchUseStripeCustomer}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} sm='6'>
                  <Form.Label>{t("labelSaveCardOptionDefault")}</Form.Label>
                  <Col xs='1'>
                    <BooleanFormSelect
                      register={register}
                      fieldName='saveCardOptionDefault'
                      trueOption={t("labelTickedOption")}
                      falseOption={t("labelUntickedOption")}
                      defaultState={defaultSaveCardOptionDefault}
                      disabled={!watchtStorePayerPaymentMethod}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

StripeOptionsTabs.defaultProps = {
  data: undefined,
};

export default StripeOptionsTabs;
