import i18next from "i18next";
import { ReactElement } from "react";
import { Badge } from "react-bootstrap";
import { tPages } from "../../../i18n";
import { PaymentFeeTransaction } from "../../../models/paymentsFees/paymentFeeTransaction";
/*
 * Creates the Late Payment Fees title
 */
export const CreateLatePaymentFeesTitle = (latePaymentTransactions: PaymentFeeTransaction[] | undefined): ReactElement => {
  const lateTransactions = latePaymentTransactions;
  const value = lateTransactions
    ? lateTransactions.reduce((total, currentTransaction) => total + currentTransaction.feeDetails.length, 0)
    : "";
  const title = i18next.t("latePaymentsTab.latePaymentsTabTitle", {
    numberOfFees: value,
    ...tPages,
  });
  return (
    <>
      {title}
      &nbsp;
      <Badge className='tab-badge'>{value}</Badge>
    </>
  );
};

CreateLatePaymentFeesTitle.defaultProps = {
  latePaymentTransactions: undefined,
};

export default CreateLatePaymentFeesTitle;
