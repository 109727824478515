import { ReactElement } from "react";
import { useQuery } from "react-query";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getAllowLatePayments } from "../../services/tenantSettings/tenantSettings.service";
import TenantSettingAlert from "./TenantSettingAlert";

const AllowLatePaymentsAlert = (): ReactElement => {
  const { data: allow, isLoading } = useQuery([QueryNameConsts.AllowLatePaymentsSetting], () => getAllowLatePayments());
  return (
    <TenantSettingAlert allow={isLoading || allow}>
      Late Payments is currently in beta and not enabled on your site. You will be able to create configurations but they will
      not be applied to transactions. Please contact Draycir if you wish to become a beta site for this feature.
    </TenantSettingAlert>
  );
};

export default AllowLatePaymentsAlert;
