export class DatasetModelConsts {
  public static readonly NameMinLength: number = 1;

  public static readonly NameMaxLength: number = 100;

  public static readonly ShortNameMaxLength: number = 100;

  public static readonly DescriptionMaxLength: number = 500;

  public static readonly HostIdMinLength: number = 1;

  public static readonly HostIdMaxLength: number = 100;

  public static readonly CustomHostIdMinLength: number = 1;

  public static readonly CustomHostIdMaxLength: number = 100;
}
export default DatasetModelConsts;
