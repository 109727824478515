/*
 * Password that should hopefully be unique and not used. Contains a Thai character, Devanagari character and a Burmese character
 */
export const FillerDisplayPassword = "ฒyap|औeh~tၼ£PAYTHEM";

/*
 * True if the value matches the FillerDisplayPassword
 */
export const isFillerDisplayPassword = (value: string) => value === FillerDisplayPassword;

/*
 * Returns the password that passed in if it doesnt equal FillerDisplayPassword, otherwise returns undefined if it does
 */
export const getNonFillerDisplayPasswordOrUndefined = (value: string): string | undefined =>
  value !== FillerDisplayPassword ? value : undefined;
