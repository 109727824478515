import { AxiosResponse } from "axios";
import { AccessKey } from "../../../models/accessKeys/accessKey";
import { AccessKeyAdd } from "../../../models/accessKeys/accessKeyAdd";
import { NewAccessKey } from "../../../models/accessKeys/newAccessKey";
import BasePayThemApi from "./basePayThemApi";
import { AccessKeyUpdate } from "../../../models/accessKeys/accessKeyUpdate";
import { getPayThemClient } from "./payThemClient.instance";
import PayThemApiClientType from "../payThemApiClientType";

interface MaximumNumberOfKeysResult {
  maximumKeyCount: number;
}

interface CountResult {
  count: number;
}

export class AccessKeysApi extends BasePayThemApi {
  private endPoint = "access-keys";

  // Returns all of the access keys
  public async getAccessKeys(): Promise<AccessKey[]> {
    const result = await this.get<AccessKey[]>(this.endPoint);
    return result;
  }

  // Returns the access key with the matching id
  public async getAccessKey(key: string): Promise<AccessKey> {
    const result = await this.getItem<AccessKey, string>(this.endPoint, key);
    return result;
  }

  // Creates a new access key
  public async createAccessKey(accessKeyAdd: AccessKeyAdd): Promise<NewAccessKey> {
    const inst = getPayThemClient(PayThemApiClientType.Tenant);
    const response = await inst.post<AccessKeyAdd, AxiosResponse<NewAccessKey>>(this.endPoint, accessKeyAdd);
    return response.data;
  }

  // Updates the access key
  public async updateAccessKey(id: string, name: string, validUntil?: Date): Promise<void> {
    const inst = this.getInstance();
    const url = `${this.endPoint}/${id}`;
    const accessKeyUpdate = {
      name,
      validUntil,
    };
    await inst.put<AccessKeyUpdate>(url, accessKeyUpdate);
  }

  // Deletes the access key
  public async deleteAccessKey(key: string): Promise<void> {
    await this.deleteItem(this.endPoint, key);
  }

  // Returns the maximum number of keys that can be created
  public async maximumNumberOfKeys(): Promise<number> {
    const result = await this.get<MaximumNumberOfKeysResult>(`${this.endPoint}/maximum`);
    return result.maximumKeyCount;
  }

  // Returns the number of access keys
  public async countKeys(): Promise<number> {
    const result = await this.get<CountResult>(`${this.endPoint}/count`);
    return result.count;
  }
}

export default AccessKeysApi;
