enum TenantSettingKey {
  // Use the Payer Single Page Application site rather than the MVC site for accepting payments
  UsePayerSpaSite = "UsePayerSpaSite",

  // Allows the use of multiple payment providers on the Payer site. If not, only the default is accepted
  AllowMultiplePaymentProviders = "AllowMultiplePaymentProviders",

  // Allow payment providers in beta to be selected in the admin portal
  ShowBetaPaymentProviders = "ShowBetaPaymentProviders",

  // Allow late payments to be applied
  AllowLatePayments = "AllowLatePayments",

  // Allow internal email notifications to be sent
  AllowInternalNotifications = "AllowInternalNotifications",

  // Allow customer email notifications to be sent
  AllowCustomerNotifications = "AllowCustomerNotifications",
}

export default TenantSettingKey;
