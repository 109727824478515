import { FieldErrors } from "react-hook-form";

export type TabArray = (HTMLDivElement | null)[];
export type TabRefs = React.MutableRefObject<(HTMLDivElement | null)[]>;

/*
 * This function will select the tab that contains the contain with the first error in errorData
 */
export const selectTabFromValidationErrors = <TFormModel extends FieldErrors>(
  tabRefs: TabRefs,
  errorData: FieldErrors<TFormModel>,
  onSelectTab: (eventKey: string) => void
): void => {
  // Get outta here if nothing to do
  if (Object.keys(errorData).length === 0) return;

  const firstKey = Object.keys(errorData)[0];
  const firstError = errorData[firstKey as keyof FieldErrors<TFormModel>];

  // Cycle through the tabs and find the first one that has control with errors
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < tabRefs.current.length; i++) {
    const table = tabRefs.current[i];
    if (table) {
      // Lets see if this tab controls the control with the error
      const control = table.querySelector(`[name="${firstError?.ref?.name}"]`);
      if (control) {
        onSelectTab(table.id);
        break;
      }
    }
  }
};
