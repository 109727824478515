import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import NewPaymentRequestDialog, { PaymentRequestFormModel } from "./NewPaymentRequestDialog";
import { Account } from "../../../models/accounts/account";
import { PaymentRequestAdd, PaymentRequestContact } from "../../../models/paymentRequests/paymentRequest";
import { Address } from "../../../models/contacts/address";
import { RequestTypes } from "../../../models/paymentRequests/requestTypes";
import { createPaymentRequest } from "../../../services/paymentRequests/paymentRequests";
import { tPages } from "../../../i18n";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";

const keyPrefix = "accountPage";

interface AddPaymentRequestButtonProps {
  account: Account;
  onAdd: () => Promise<void>;
}

/*
 * Displays and handles the add payment request button for an account
 */
const AddPaymentRequestButton = ({ account, onAdd }: AddPaymentRequestButtonProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const disabled = !usePermission(UserPermission.EditPaymentRequest);

  const handleSave = async (model: PaymentRequestFormModel) => {
    setShow(false);
    setSaving(true);

    const requestModel: PaymentRequestAdd = {
      requestType: RequestTypes.Account,
      captureType: model.captureType,
      currency: model.currency,
      amountToPay: model.amount,
      description: model.description,
      customerName: account.name,
      customerReference: account.accountReference,
      accountId: account.id,
      validFrom: model.validFrom,
      validUntil: model.validUntil,
      paymentRequiredBy: model.paymentRequiredBy,
      billingContact: {
        firstNames: model.billingFirstName,
        surname: model.billingLastName,
        companyName: model.billingCompanyName,
        emailAddress: model.billingEmailAddress,
        address: {
          address1: model.billingAddress1,
          address2: model.billingAddress2,
          city: model.billingCity,
          state: model.billingState,
          postCode: model.billingPostCode,
          country: model.billingCountry,
        } as Address,
      } as PaymentRequestContact,
      test: false,
    };

    await createPaymentRequest(requestModel);
    await onAdd();
    setSaving(false);
  };

  return (
    <>
      <SpinnerButton
        variant='primary'
        title={t("buttonNewPaymentRequest")}
        showSpinner={saving}
        disabled={disabled}
        onClick={() => setShow(true)}
      />

      <NewPaymentRequestDialog account={account} show={show} onSave={handleSave} onHide={() => setShow(false)} />
    </>
  );
};

export default AddPaymentRequestButton;
