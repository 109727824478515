import { ReactElement } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import { PanelItem } from "../../components/panels/Panels";
import { textOrDefault } from "../../helpers/stringHelpers";
import { tPages } from "../../i18n";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { CustomerFormatter } from "../../utils/customerFormatter";

const keyPrefix = "paymentTransactionPage.payee";

/*
 * Payee details
 */
const PayeeDetails = ({ paymentTransaction }: { paymentTransaction: PaymentTransaction }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const { customerDetails } = paymentTransaction;
  const { emailAddress } = customerDetails;
  const address = CustomerFormatter.fullAddressToStringFromCustomerDetails(customerDetails);

  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title>{t("title")}</Card.Title>
          <Card.Text as='div'>
            <Row>
              <Col md={12} lg={6}>
                <div className='main-item'>
                  <div className='title'>{t("companyName")}</div>
                  <div className='content'>{customerDetails.companyName}</div>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <PanelItem title={t("fullName")}>
                  {CustomerFormatter.getFullName(customerDetails.firstNames, customerDetails.surname)}
                </PanelItem>
                <PanelItem title={t("emailAddress")}>
                  {textOrDefault(emailAddress)}
                  {emailAddress && <CopyToClipboard text={emailAddress} />}
                </PanelItem>
                <PanelItem title={t("address")}>
                  {textOrDefault(address)}
                  {address && <CopyToClipboard text={address} />}
                </PanelItem>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default PayeeDetails;
