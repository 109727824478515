import { StripeAccountInformation } from "../../../models/paymentProviders/stripe/stripeModels";
import { StripePublicKeys } from "../../../models/paymentProviders/stripe/stripePublicKeys";
import BasePayThemApi from "./basePayThemApi";

export class StripeWebApi extends BasePayThemApi {
  private endPoint = "stripe";

  /*
   * Returns the public keys for the stripe account
   */
  public async getPublicKeys(): Promise<StripePublicKeys> {
    const result = await this.get<StripePublicKeys>(`${this.endPoint}/keys`);
    return result;
  }

  /*
   * Returns the account information for the given payment provider id
   */
  public async getAccountInformationFromProviderConfiguration(paymentProviderId: string): Promise<StripeAccountInformation> {
    const url = `${this.endPoint}/payment-provider/${paymentProviderId}/account`;
    const result = await this.get<StripeAccountInformation>(url);
    return result;
  }

  /*
   * Connects to a client account, returning the connected client id
   */
  public async connectToClientAccount(code: string): Promise<StripeAccountInformation> {
    const params = new URLSearchParams();
    params.append("code", code);
    const result = await this.postWithResponse<undefined, StripeAccountInformation>(
      `${this.endPoint}/account/connect?${params.toString()}`
    );
    return result;
  }

  /*
   * Disconnects the Stripe client from PayThem
   */
  public async disconnectFromClientAccount(paymentProviderId: string): Promise<string> {
    const url = `${this.endPoint}/payment-provider/${paymentProviderId}/account/disconnect`;
    const result = await this.postWithResponse<undefined, string>(url);
    return result;
  }
}

export default StripeWebApi;
