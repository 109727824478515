/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useLocation } from "react-router-dom";
import { tCommon, tPaymentProviders } from "../../../../i18n";
import { GoCardlessAuthService, GoCardlessConnectType } from "../../../../services/goCardless/goCardlessConnect.service";
import { toastError, toastSuccess } from "../../../../services/notifications/toastNotification.service";
import { SettingsRouteConsts } from "../../../SettingsRouteConsts";

/*
 *  Starts the GoCardless authorisation process by redirecting to the GoCardless Oauth site
 */
const GoCardlessAuthorise = ({ configurationId }: { configurationId?: string }): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);

  GoCardlessAuthService.startAuthorise(GoCardlessConnectType.Add);
  return (
    <div>
      <h3>
        <Spinner animation='border' />
        {t("goCardlessForm.messageAuthRedirecting")}
      </h3>
    </div>
  );
};

GoCardlessAuthorise.defaultProps = {
  configurationId: undefined,
};

/*
 * Completes the GoCardless connection process by creating/updating the payment provider
 */
export function GoCardlessCompleteConnect(): ReactElement {
  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { t } = useTranslation([tPaymentProviders.ns, tCommon.ns]);

  const { search } = useLocation();

  console.log("go cardless");
  console.log(search);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const service = new GoCardlessAuthService();
    const error = params.get("error");
    if (error) {
      const description = params.get("error_description") ?? t("messageUnknownError", tCommon);
      setErrorMessage(description);
    } else {
      const code = params.get("code") ?? "";
      const state = params.get("state") ?? "";
      service
        .completeConnection(state, code)
        .then((result) => {
          if (result.success) {
            toastSuccess(t("goCardlessForm.toastMessageGoCardlessAuthorisationSuccess"));
            setProviderId(result.paymentProviderId);
          } else {
            toastError(t("goCardlessForm.toastMessageGoCardlessAuthorisationFailed"));
            setErrorMessage(result.errorMessage ?? "Unknown error");
          }
        })
        .catch((apiError) => {
          toastError(t("goCardlessForm.toastMessageGoCardlessAuthorisationFailed"));
          setErrorMessage(apiError.toString());
        });
    }
  }, [search, t]);

  return (
    <div>
      {errorMessage ? (
        <>
          <h3>{errorMessage}</h3>
          <Link to={SettingsRouteConsts.PaymentProviders}>{t("paymentProviders.labelBackToProvider")}</Link>
        </>
      ) : (
        <h3>
          <Spinner animation='border' />
          {t("goCardlessForm.messageGoCardlessCompletingAuthorisation")}
        </h3>
      )}
      {providerId && <Redirect to={`${SettingsRouteConsts.PaymentProviders}/${providerId}`} />}
    </div>
  );
}

export default GoCardlessAuthorise;
