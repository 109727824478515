import { FieldValues } from "react-hook-form";
import {
  TranSafeProviderName,
  TranSafeProviderDisplayName,
  TranSafeProviderModelConsts,
  TranSafeSettings,
  TranSafeCredentials,
  TransafePaymentProviderConfiguration,
  AddTransafePaymentProviderConfiguration,
  TranSafeTestSiteUserName,
  TranSafeTestSitePassword,
} from "../../../../models/paymentProviders/transafe/transafeModels";
import { getNonFillerDisplayPasswordOrUndefined } from "../../../../utils/secureStringUtils";
import {
  ProviderFormModel,
  mapToAddPaymentProviderConfiguration,
  mapToPaymentProviderConfiguration,
} from "../ProvidersCommon/Common";
import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";

export interface TranSafeFormModel extends ProviderFormModel, FieldValues {
  userName: string;
  password: string;
  isTest: boolean;
}

export const CurrentProviderName = TranSafeProviderName;
export const CurrentProviderDisplayName = TranSafeProviderDisplayName;
export const CurrentPaymentMethodType = PaymentMethodType.Card;
export const CurrentProviderModelConsts = TranSafeProviderModelConsts;
export type CurrentSettings = TranSafeSettings;
export type CurrentCredentials = TranSafeCredentials;
export type CurrentPaymentProviderConfiguration = TransafePaymentProviderConfiguration;
export type CurrentAddPaymentProviderConfiguration = AddTransafePaymentProviderConfiguration;
export type CurrentFormModel = TranSafeFormModel;

export const mapToAddConfiguration = (model: CurrentFormModel): CurrentAddPaymentProviderConfiguration => {
  const configuration = mapToAddPaymentProviderConfiguration<CurrentAddPaymentProviderConfiguration>(
    model,
    CurrentProviderName
  );
  configuration.isTest = model.isTest;
  configuration.settings = { useTestSite: model.isTest, providerName: CurrentProviderName } as CurrentSettings;
  configuration.credentials = {
    userName: model.isTest ? TranSafeTestSiteUserName : model.userName,
    password: model.isTest ? TranSafeTestSitePassword : model.password,
    providerName: CurrentProviderName,
  } as CurrentCredentials;
  return configuration;
};

export const mapToUpdateConfiguration = (
  data: CurrentPaymentProviderConfiguration,
  model: CurrentFormModel
): CurrentPaymentProviderConfiguration => {
  const configuration = mapToPaymentProviderConfiguration(data, model);
  configuration.isTest = model.isTest;
  configuration.settings.useTestSite = model.isTest;
  if (!configuration.credentials) {
    configuration.credentials = { providerName: CurrentProviderName } as CurrentCredentials;
  }
  configuration.credentials.userName = model.isTest ? TranSafeTestSiteUserName : model.userName;

  const password = getNonFillerDisplayPasswordOrUndefined(model.password);
  configuration.credentials.password = model.isTest ? TranSafeTestSitePassword : password;
  return configuration;
};
