import { ReactElement } from "react";
import DefaultTag from "../tags/DefaultTag";

interface TextWithDefaultTagProps {
  text: string;
  isDefault: boolean;
}

/*
 * Creates a text string with an optional default tag
 */
const TextWithDefaultTag = ({ text, isDefault }: TextWithDefaultTagProps): ReactElement => (
  <div className='d-flex justify-content-start align-items-center'>
    <div className='pe-1'>{text}</div>
    {isDefault && <DefaultTag />}
  </div>
);

export default TextWithDefaultTag;
