/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Card, Row, Form, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { tForms } from "../../../../i18n";
import { LinkSize, LinkSizeArray } from "../../../../models/companySettings/linkSize";
import { PaymentRequestSettings } from "../../../../models/companySettings/paymentRequestSettings";
import { TimeUnitType } from "../../../../models/companySettings/timeUnitType";
import { getPaymentRequestSettings, updatePaymentRequestSettings } from "../../../../services/settings/settings.service";
import PaymentRequestSettingsConsts from "../../../../models/companySettings/paymentRequestSettingsConsts";
import { TypeGuard } from "../../../../utils/typeGuards";
import { FormSaveRef } from "../preferencesTypes";
import GeneralCardPlaceholder from "../../../../components/placeholders/GeneralCardPlaceholder";
import {
  PaymentRequestUsageType,
  PaymentRequestUsageTypeArray,
} from "../../../../models/paymentRequests/paymentRequestUsageType";

export const keyPrefix = "preferences.paymentRequestSettings";

const NoValidUntilOption = "noValidUntilOption";

type FormModel = {
  linkSize: LinkSize;
  validUntilUnit?: TimeUnitType;
  validUntilQuantity?: number;
  parseValue?: string;
  defaultUsageType: PaymentRequestUsageType;
};

const ValidUntilUnits: { label: string; value: TimeUnitType }[] = [
  { label: "Days", value: TimeUnitType.Day },
  { label: "Weeks", value: TimeUnitType.Week },
  { label: "Months", value: TimeUnitType.Month },
  { label: "Years", value: TimeUnitType.Year },
];

const isTimeUnitType = (value: TimeUnitType): boolean => Object.values(TimeUnitType).includes(value);

const validationSchema = Yup.object({
  validUntilUnit: Yup.mixed<TimeUnitType>().notRequired(),
  validUntilQuantity: Yup.number()
    .label("Quantity")
    .when("validUntilUnit", (validUntilUnit, field) => {
      if (!isTimeUnitType(validUntilUnit)) return field.optional();

      let max = 1;
      switch (validUntilUnit) {
        case TimeUnitType.Day:
          max = PaymentRequestSettingsConsts.ValidUntilMaxDayMax;
          break;
        case TimeUnitType.Week:
          max = PaymentRequestSettingsConsts.ValidUntilMaxMonthMax;
          break;
        case TimeUnitType.Month:
          max = PaymentRequestSettingsConsts.ValidUntilMaxMonthMax;
          break;
        case TimeUnitType.Year:
          max = PaymentRequestSettingsConsts.ValidUntilMaxYearMax;
          break;
        default:
          throw Error("Unknown TimeUnitType");
      }

      return isTimeUnitType(validUntilUnit)
        ? field
            .integer()
            .min(PaymentRequestSettingsConsts.ValidUntilMin)
            .max(max)
            .required()
            .typeError("Quantity must be a number")
        : field.optional();
    }),
  defaultUsageType: Yup.mixed<PaymentRequestUsageType>().required(),
});

export const savePaymentRequestSettings = async (model: FormModel): Promise<void> => {
  const settings: PaymentRequestSettings = {
    linkSize: model.linkSize,
    defaultUsageType: model.defaultUsageType,
  };
  if (
    model.validUntilQuantity &&
    model.validUntilQuantity !== 0 &&
    model.validUntilUnit &&
    isTimeUnitType(model.validUntilUnit)
  ) {
    settings.validUntilAddition = {
      unit: model.validUntilUnit,
      quantity: model.validUntilQuantity,
    };
  }
  await updatePaymentRequestSettings(settings, true);
};

/*
 * Settings for payment requests
 */
const PaymentRequestSettingsForm = forwardRef<FormSaveRef>((props, ref) => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const [paymentRequestSettings, setPaymentRequestSettings] = useState<PaymentRequestSettings>();
  const [quantityDisabled, setQuantityDisabled] = useState<boolean>();
  const [expireUnit, setExpireUnit] = useState<TimeUnitType>();
  const [expireQuantity, setExpireQuantity] = useState<number>(0);

  const {
    register,
    trigger,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getPaymentRequestSettings().then((result) => {
      setPaymentRequestSettings(result);
      if (result) {
        setQuantityDisabled(!result.validUntilAddition);
        setExpireUnit(result.validUntilAddition?.unit);
        setExpireQuantity(result.validUntilAddition?.quantity ?? 0);
      }
    });
  }, []);

  useImperativeHandle(ref, () => ({
    validate: () => trigger(),
    save: async () => {
      await handleSubmit(async (data) => {
        await savePaymentRequestSettings(data);
      })();
    },
  }));

  const watchUnit = watch("validUntilUnit");
  const watchQuantity = watch("validUntilQuantity");

  // Called when the unit type dropdown changes
  useEffect(() => {
    if (watchUnit) {
      const isValid = isTimeUnitType(watchUnit);
      setQuantityDisabled(!isValid);
      setExpireUnit(watchUnit);
      if (!isValid) {
        setExpireQuantity(0);
      }
    }
  }, [watchUnit, setValue]);

  useEffect(() => {
    if (watchQuantity) {
      const value = TypeGuard.isNumber(watchQuantity) ? watchQuantity : parseInt(watchQuantity, 10);
      setExpireQuantity(value);
    }
  }, [watchQuantity]);

  const pluralise = (singular: string, plural: string, amount: number): string => (amount === 1 ? singular : plural);
  const expires = quantityDisabled || expireQuantity === undefined || expireQuantity <= 0;
  const defaultLinkSize = LinkSize.Long; // TODO paymentRequestSettings.linkSize;

  if (!paymentRequestSettings) {
    return <GeneralCardPlaceholder />;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t("titlePaymentRequest")}</Card.Title>
        <Card.Text as='div'>
          <Form noValidate>
            <Row>
              <Col md='8' sm='12'>
                <Row>
                  <Form.Group as={Col} xs='4' sm='4' md='4' lg='4'>
                    <Form.Label>{t("labelLinkUrlSize")}</Form.Label>
                    <Form.Select defaultValue={defaultLinkSize} {...register("linkSize")} disabled>
                      {LinkSizeArray?.map((link) => (
                        <option key={link[1]} value={link[0]}>
                          {link[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <h6 className='mt-2'>{t("subTitleValidUntil")}</h6>
                  <Form.Group as={Col} xs='4' sm='4' md='4' lg='4'>
                    <Form.Label>{t("labelDefaultValidUntilUnit")}</Form.Label>
                    <Form.Select defaultValue={paymentRequestSettings.validUntilAddition?.unit} {...register("validUntilUnit")}>
                      <option key={NoValidUntilOption} value={NoValidUntilOption}>
                        -
                      </option>
                      {ValidUntilUnits?.map((unit) => (
                        <option key={unit.value} value={unit.value}>
                          {unit.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs='4' sm='4' md='3' lg='2'>
                    <Form.Label>{t("labelDefaultValidUntilQuantity")}</Form.Label>
                    <Form.Control
                      type='number'
                      {...register("validUntilQuantity")}
                      className={`form-control ${errors?.validUntilQuantity?.message ? "is-invalid" : ""}`}
                      disabled={quantityDisabled}
                      defaultValue={paymentRequestSettings.validUntilAddition?.quantity ?? 0}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.validUntilQuantity?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className='item-description'>
                  {!expires && expireUnit && expireQuantity !== undefined && (
                    <p>
                      {t("descriptionLinkExpires", {
                        quantity: expireQuantity,
                        interval: pluralise(
                          expireUnit.toLocaleLowerCase(),
                          `${expireUnit?.toLocaleLowerCase()}s`,
                          expireQuantity
                        ),
                      })}
                    </p>
                  )}
                  {expires && <p>{t("descriptionLinkDoesNotExpire")}</p>}
                </Row>
                <Row>
                  <Form.Group as={Col} xs='4' sm='4' md='4' lg='4'>
                    <Form.Label>{t("labelDefaultUsageType")}</Form.Label>
                    <Form.Select defaultValue={paymentRequestSettings.defaultUsageType} {...register("defaultUsageType")}>
                      {PaymentRequestUsageTypeArray.map((usageType) => (
                        <option key={usageType[0]} value={usageType[0]}>
                          {usageType[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <Row className='item-description'>
                    <p>{t("descriptionDefaultUsageType")}</p>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card.Text>
      </Card.Body>
    </Card>
  );
});

export default PaymentRequestSettingsForm;
