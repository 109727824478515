import { useMemo } from "react";
import { DateFormatter } from "../../utils/dateFormatter";
import useMe from "./useMe";

const useDateFormatter = (): DateFormatter => {
  const { me } = useMe();
  const dateFormatter = useMemo(() => new DateFormatter(me), [me]);

  return dateFormatter;
}

export default useDateFormatter;