import { TypeColumn, TypeFilterValue, TypeSortInfo } from "@inovua/reactdatagrid-community/types";
import LocationProvider from "../../common/providers/locationProvider";
import { LocalStorageProvider } from "../../common/storage/localStorageProvider";
import { DataGridPagination, DataGridState } from "./dataGridHelpers";

const storageProvider = new LocalStorageProvider();
const locationProvider = new LocationProvider();

/*
 * We need to filter out from the stored filters, any changes we've made to the grids filters
 * otherwise we can get up with the grid not working due to being sent invalid (i.e. old) data
 */
const processFilters = (defaultFilters: TypeFilterValue, filters?: TypeFilterValue): TypeFilterValue | undefined =>
  defaultFilters?.map(
    (defaultFilter) => filters?.find((x) => x.name === defaultFilter.name && x.type === defaultFilter.type) ?? defaultFilter
  );

/*
 * Removes any sort info items that relate to columns that no longer exist. If we don't filter these
 * out then it could cause the grid to hang
 */
const processSortInfo = (columns: TypeColumn[], defaultSortInfo: TypeSortInfo, sortInfo?: TypeSortInfo): TypeSortInfo => {
  if (!sortInfo) return defaultSortInfo;

  if (Array.isArray(sortInfo)) {
    // Make sure the sortInfo items contains valid columns
    return sortInfo.filter((x) => columns.find((column) => column.name === x.name));
  }

  // Make sure the sortInfo is from a valid column
  return columns.find((column) => column.name === sortInfo?.name) ? sortInfo : null;
};

const ClearGridParamaterName = "clearGrid";

/*
 * True if there is a ?clearGrid parameter present on the query string
 */
const isClearGridParameterPresent = (): boolean => new URLSearchParams(locationProvider.search()).has(ClearGridParamaterName);

/*
 * Just returns the stored pagination of the default pagination
 */
const processPagination = (
  defaultPagination: DataGridPagination | undefined,
  pagination?: DataGridPagination | undefined
): DataGridPagination | undefined => pagination ?? defaultPagination;

/*
 * Loads the data grid state from the storage name
 */
export const loadDataGridState = (
  name: string,
  storageName: string,
  defaultFilters: TypeFilterValue,
  defaultSortInfo: TypeSortInfo,
  defaultPagination: DataGridPagination | undefined | null,
  columns: TypeColumn[]
): DataGridState => {
  // A quick way for support to clear the settings
  if (isClearGridParameterPresent()) {
    storageProvider.removeItem(storageName);
  }

  const state = <DataGridState>storageProvider.getItem(storageName);

  // Returns an default state if no storage was found
  if (!state) {
    return <DataGridState>{
      name,
      filters: defaultFilters,
      sortInfo: defaultSortInfo,
      pagination: defaultPagination,
    };
  }

  // Make sure we return all filters, even ones that have been added or types/names changed
  const filters = processFilters(defaultFilters, state.filters);

  // Validate sort info by making sure they match valid columns, removing anyones that have been renamed
  const sortInfo = processSortInfo(columns, defaultSortInfo, state.sortInfo);

  const pagination = processPagination(defaultPagination ?? undefined, state.pagination);

  return <DataGridState>{
    name,
    filters,
    sortInfo,
    pagination,
  };
};

/*
 * Saves the data grid state to local storage
 */
export const saveDataGridState = (storageName: string, state: DataGridState) => {
  storageProvider.setItem(storageName, state);
};
