/* eslint-disable class-methods-use-this */
import { StorageProvider } from "./storageProvider";

export class LocalStorageProvider implements StorageProvider {
  public setItem<T>(key: string, item: T): void {
    window.localStorage.setItem(key, JSON.stringify(item));
  }

  public getItem<T>(key: string): T | null {
    const storedValue = window.localStorage.getItem(key);
    if (!storedValue) {
      return null;
    }
    return JSON.parse(storedValue);
  }

  public removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }
}

export default LocalStorageProvider;
