import { ReactElement, useState } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import CustomToggle from "../../components/misc/CustomToggle";
import { PanelItem } from "../../components/panels/Panels";
import { tPages } from "../../i18n";
import { SettlementConfiguration } from "../../models/settlements/settlementConfiguration";
import { SettlementType, SettlementTypeMap } from "../../models/settlements/settlementEnums";
import TransactionDateType from "../../models/transactions/transactionDateTypeEnum";
import { CurrencyFormatter } from "../../utils/currencyFormatter";
import { SettlementConfigurationDialog } from "./SettlementConfigurationDialog";
import SettlementConfigurationDiscountEntry from "./SettlementConfigurationDiscountEntries";
import { SettlementConfigurationFormModel } from "./settlementConfigurationFormModel";
import { mapModelToExistingConfiguration } from "./settlementUtils";
import ShowTranslation from "./ShowTranslation";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "settlementConfigurationGroupPage";

interface TripleDotMenuProps {
  disabledEdit?: boolean;
  disabledDelete?: boolean;
  configuration: SettlementConfiguration;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}
/*
 * Triple dot menu options
 */
const TripleDotItemMenu = ({ disabledEdit, disabledDelete, configuration, onEdit, onDelete }: TripleDotMenuProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    onDelete(configuration.id);
  };

  return (
    <>
      <Dropdown align='end' className='settlement-offer-menu actionmenu-dropdown-title'>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu title=''>
          <Dropdown.Item disabled={disabledEdit} onClick={() => onEdit(configuration.id)}>{t("menuItemEdit")}</Dropdown.Item>
          <Dropdown.Item disabled={disabledDelete} onClick={() => setShowDeleteConfirmation(true)}>{t("menuItemDelete")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmationDialog
        show={showDeleteConfirmation}
        title={t("titleDelete")}
        body={t("confirmDeleteMessage")}
        handleClose={() => setShowDeleteConfirmation(false)}
        handleConfirmation={handleDeleteConfirmation}
      />
    </>
  );
};

TripleDotItemMenu.defaultProps = {
  disabledEdit: false,
  disabledDelete: false
}

interface SettlementConfigurationEntryProps {
  configuration: SettlementConfiguration;
  onEdit: (settlementConfiguration: SettlementConfiguration) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

const SettlementConfigurationEntry = ({ configuration, onEdit, onDelete }: SettlementConfigurationEntryProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const dateType = configuration.dateType ?? TransactionDateType.DueDate;
  const currency = configuration.currency ?? "";
  const isPercentageDiscount = configuration.type === SettlementType.PercentageDiscount;
  const disabledEdit = !usePermission(UserPermission.EditSettlementConfigurations);
  const disabledDelete = !usePermission(UserPermission.DeleteSettlementConfigurations);

  const translation = (
    <ShowTranslation
      translation={isPercentageDiscount ? "translationPercentageDiscount" : "translationAbsoluteDiscount"}
      numberOfDays={configuration.numberOfDays ?? 0}
      dateType={dateType}
      discount={
        isPercentageDiscount
          ? configuration.percentageDiscount!.toString()
          : CurrencyFormatter.currencyOrString(currency, configuration.amount)
      }
      filterGroup={configuration.filterGroup}
    />
  );

  const handleEdit = () => {
    setShowEditDialog(true);
  };

  async function handleSaveEdit(model: SettlementConfigurationFormModel) {
    const newConfiguration = mapModelToExistingConfiguration(configuration, model);
    setShowEditDialog(false);
    onEdit(newConfiguration);
  }

  return (
    <div className='d-flex justify-content-between'>
      <div className='flex-grow-1'>
        <Row className='mb-3'>
          <Col md={6}>
            <PanelItem title={t("labelType")}>{SettlementTypeMap.get(configuration.type)}</PanelItem>
            {configuration.description && <PanelItem title={t("labelDescription")}>{configuration.description}</PanelItem>}
          </Col>
          <Col md={6}>
            <SettlementConfigurationDiscountEntry configuration={configuration} />
          </Col>
        </Row>
        <Row className='g-0'>
          <div className='main-item'>{translation}</div>
        </Row>
      </div>
      <div className='ms-auto'>
        <TripleDotItemMenu configuration={configuration} disabledEdit={disabledEdit} disabledDelete={disabledDelete} onEdit={() => handleEdit()} onDelete={(id) => onDelete(id)} />
      </div>
      <SettlementConfigurationDialog
        data={configuration}
        type={configuration.type}
        show={showEditDialog}
        mode='edit'
        onClose={() => setShowEditDialog(false)}
        onSave={(model) => handleSaveEdit(model)}
      />
    </div>
  );
};

export default SettlementConfigurationEntry;
