/* eslint-disable class-methods-use-this */
import { AxiosInstance } from "axios";
import { getPayThemClient } from "./payThemClient.instance";
import { BaseApi } from "../baseApi";
import PayThemApiClientType from "../payThemApiClientType";
import { FeatureFlagResponse } from "../../../models/featureFlags/featureFlagResponse";

/*
 * Access to the info endpoints
 */
class InfoApi extends BaseApi {
  private endPoint = "info";

  protected getInstance(): AxiosInstance {
    // We don't need a dataset or authentication for this endpoint
    const instance = getPayThemClient(PayThemApiClientType.NoAuthentication);
    return instance;
  }

  /*
   * Returns the feature flags
   */
  public async getFeatureFlags(target: string): Promise<FeatureFlagResponse> {
    const params = new URLSearchParams();
    params.append("target", target);
    const result = await this.get<FeatureFlagResponse>(`${this.endPoint}/feature-flags`, params);
    return result;
  }
}

export default InfoApi;
