import {
  faExclamationTriangle,
  faInfoCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SeverityLevel } from "../../common/severityLevel";
import ThumbsUpImg from "../../assets/images/icons/icon-circle-thumb-up.png";
import "./InformationPanel.scss";

export type VarientStyle = {
  style: string,
  icon: IconDefinition
}

export const getVarientStyle = (severityLevel: SeverityLevel) => {
  let varient = "";
  let icon: IconDefinition = faInfoCircle;
  switch (severityLevel) {
    case SeverityLevel.Informational:
      varient = "info";
      break;
    case SeverityLevel.Critical:
      varient = "danger";
      icon = faExclamationTriangle;
      break;
    case SeverityLevel.Warning:
      varient = "warning";
      icon = faExclamationTriangle;
      break;
    default:
      varient = "primary";
  }

  return {
    style: varient,
    icon
  }
}

type InformationPanelProps = {
  severityLevel: SeverityLevel,
  title: string,
  message: string,
  children?: ReactElement
}

const InformationPanel = ({ severityLevel, title, message, children }: InformationPanelProps): ReactElement => {
  const varient = getVarientStyle(severityLevel);

  return (
    <div className={`item ${varient.style}`}>
      <div className="content">
        <div className="content-icon">
          {severityLevel === SeverityLevel.Informational ? (
            <img alt="thumbup_icon" src={ThumbsUpImg} />
          ) : (
            <FontAwesomeIcon icon={varient.icon} />
          )}
        </div>
        <div className="content-left">
          <div className="title">{title}</div>
          <div className="message">{message}</div>
        </div>
        {children}
      </div>
    </div>
  );
}

InformationPanel.defaultProps = {
  children: undefined
}

export default InformationPanel;