/* eslint-disable import/prefer-default-export */
import PageResult from "../common/api/PageResults";

/*
 * Loops through page result call to return all items available items
 */
export const getAllPageItems = async <TType>(
  getPage: (page: number, itemsPerPage: number) => Promise<PageResult<TType>>
): Promise<TType[]> => {
  const items = new Array<TType>();
  let page = 1;
  const itemsPerPage = 20;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result = await getPage(page, itemsPerPage);
    if (result.items?.length > 0) {
      items.push(...result.items);
      if (result.items.length < itemsPerPage) {
        break;
      }
      page += 1;
    } else {
      break;
    }
  } while (page !== 0);
  return items;
};
