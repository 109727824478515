import { forwardRef, useImperativeHandle, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { FormSaveRef, TabSaveRef } from "../preferencesTypes";
import CustomHostIdSettingsForm from "./CustomHostIdSettingsForm";

/*
 * Advanced settings
 */
const AdvancedSettingsTab = forwardRef<TabSaveRef>((props, ref) => {
  const customHostIdSettingsRef = useRef<FormSaveRef>(null);

  useImperativeHandle(ref, () => ({
    save: async () => {
      if (!(await customHostIdSettingsRef.current?.validate())) return false;
      await customHostIdSettingsRef.current?.save();
      return true;
    },
  }));

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <CustomHostIdSettingsForm ref={customHostIdSettingsRef} />
        </Col>
      </Row>
    </>
  );
});

export default AdvancedSettingsTab;
