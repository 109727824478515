import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { NewAccessKey } from "../../../models/accessKeys/newAccessKey";
import { CopyToClipboard } from "../../../components/icons/CopyToClipboard";
import { tForms } from "../../../i18n";
import "./CopyAccessKeyDialog.scss";
import InformationPanel from "../../common/InformationPanel";
import { SeverityLevel } from "../../../common/severityLevel";

const keyPrefix = "copyAccessKeyForm";

interface CopyAccessKeyDialogProps {
  newAccessKey: NewAccessKey;
  show: boolean;
  handleClose: () => void;
}

/*
 * A dialog to display Access key for copying by users
*/
const CopyAccessKeyDialog = ({ newAccessKey, show, handleClose }: CopyAccessKeyDialogProps):ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  return (
    <Modal className='copy-access-key-dialog-container' size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("title", { name: newAccessKey.name })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>        
        <Row>
          <Col>
            <InformationPanel severityLevel={SeverityLevel.Warning} title={t("secureAccessKeyTitle")} message={t("secureAccessKeyDescription")} />
          </Col>
        </Row>
        <Row>
          <Col className="key-col">
            <div className="access-key-body">
              <span>{newAccessKey.accessKey}</span>
              <CopyToClipboard text={newAccessKey.accessKey} />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {t("buttonClose")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CopyAccessKeyDialog;