import lang from "i18next";
import { TodoResult } from "./todoResult";
import { SeverityLevel } from "../../common/severityLevel";
import { getCompanySettings } from "../settings/settings.service";
import { SettingsRouteConsts } from "../../views/SettingsRouteConsts";
import { CompanySettings } from "../../models/companySettings/companySettingsModels";

import { tTodos } from "../../i18n";
import UserPermission from "../../models/users/userPermission";

const isCompanySettingsValid = (settings: CompanySettings | undefined): boolean => {
  if (!settings) return false;
  if (!settings.name) return false;
  if (!settings.companyDetails) return false;
  if (!settings.companyDetails.name) return false;
  if (!settings.companyDetails.mainAddress) return false;

  const address = settings.companyDetails.mainAddress;
  if (!address.email) return false;
  if (!address.firstNames) return false;
  if (!address.surname) return false;
  if (!address.address1) return false;
  if (!address.city) return false;
  if (!address.country) return false;
  if (!address.postCode) return false;
  if (!address.country) return false;
  return true;
};

export async function companySettingsTodo(): Promise<TodoResult[]> {
  const results: TodoResult[] = [];

  const companySettings = await getCompanySettings();

  if (!isCompanySettingsValid(companySettings)) {
    results.push({
      severity: SeverityLevel.Critical,
      title: lang.t("todos.companyDetails.titleCompanyDetailsNotComplete", tTodos),
      message: lang.t("todos.companyDetails.messageCompanyDetailsNotComplete", tTodos),
      url: SettingsRouteConsts.Details,
      requiredPermissionToFix: UserPermission.ConfigureSystemSettings,
    });
  }

  if (!companySettings?.companyDetails?.logoUrl) {
    results.push({
      severity: SeverityLevel.Warning,
      title: lang.t("todos.companyDetails.titleUploadLogo", tTodos),
      message: lang.t("todos.companyDetails.messageUploadLogo", tTodos),
      url: SettingsRouteConsts.Customisation,
      requiredPermissionToFix: UserPermission.ConfigureSystemSettings,
    });
  }

  if (!companySettings?.companyDetails?.website) {
    results.push({
      severity: SeverityLevel.Informational,
      title: lang.t("todos.companyDetails.titleAddWebsiteUrl", tTodos),
      message: lang.t("todos.companyDetails.messageAddWebsiteUrl", tTodos),
      url: SettingsRouteConsts.Details,
      requiredPermissionToFix: UserPermission.ConfigureSystemSettings,
    });
  }

  return results;
}

export default companySettingsTodo;
