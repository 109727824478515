import { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateOutputType } from "../../../utils/dateFormatter";
import { PaymentAllocation } from "../../../models/paymentAllocations/paymentAllocation";
import { DefaultBlankDateText } from "../../viewCommon";
import { CurrencyItem } from "../../../components/misc/CurrencyItem";
import { tPages } from "../../../i18n";
import PaymentTransactionLink from "../../PaymentTransactions/PaymentTransactionLink";
import TransactionReferenceLink from "../../Transactions/TransactionReferenceLink";
import { PaymentEntityType } from "../../../models/paymentDetails/paymentEntityType";
import AccountIdLink from "../../Accounts/AccountIdLink";
import useDateFormatter from "../../../common/hooks/useDateFormatter";
import StandardTooltip from "../../../components/tooltips/StandardTooltip";
import { PaymentAllocationStatusArray } from "../../../models/paymentAllocations/paymentAllocationStatus";
import { PaymentAllocationTypeArray } from "../../../models/paymentAllocations/paymentAllocationType";
import { ReactComponent as ToolTipIcon } from "../../../assets/svg/ToolTipIcon.svg";
import "./PaymentAllocationTable.scss";

const keyPrefix = "paymentAllocations";

export type PaymentAllocationDisplayMode = "entityView" | "paymentTransactionView";

interface EntityLinkProps {
  paymentAllocation: PaymentAllocation;
}

const EntityLink = ({ paymentAllocation }: EntityLinkProps): ReactElement => {
  if (paymentAllocation.entityType === PaymentEntityType.Transaction) {
    return <TransactionReferenceLink transactionId={paymentAllocation.transactionId} />;
  }
  if (paymentAllocation.entityType === PaymentEntityType.Account) {
    return <AccountIdLink accountId={paymentAllocation.accountId} />;
  }
  return <></>;
};

/*
 * A table which displays payment allocations
 */
const PaymentAllocationTable = ({
  paymentAllocations,
  currency,
  mode,
}: {
  paymentAllocations: PaymentAllocation[];
  currency: string;
  mode: PaymentAllocationDisplayMode;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateFormatter = useDateFormatter();

  return (
    <>
      <Table borderless className='panel-table'>
        <thead>
          <tr>
            <td>{t("createdOn")}</td>
            <td>
              {t("allocationType")}
              <StandardTooltip
                popoverClass='w-50 mw-100'
                text={
                  <>
                    <div>{t("allocationTypeToolTip")}</div>
                    <Table borderless>
                      <tbody>
                        {PaymentAllocationTypeArray.map(([type]) => (
                          <tr key={type} className='align-top'>
                            <td>
                              <b>{t(`${type}Title`)}: </b>
                            </td>
                            <td>{t(`${type}Description`)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              >
                <ToolTipIcon className='tool-tip-icon' />
              </StandardTooltip>
            </td>
            <td>
              {t("status")}
              <StandardTooltip
                popoverClass='w-50 mw-100'
                text={
                  <>
                    <div>{t("statusToolTip")}</div>
                    <Table borderless>
                      <tbody>
                        {PaymentAllocationStatusArray.map(([status]) => (
                          <tr key={status} className='align-top'>
                            <td>
                              <b>{t(`${status}Title`)}: </b>
                            </td>
                            <td>{t(`${status}Description`)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              >
                <ToolTipIcon className='tool-tip-icon' />
              </StandardTooltip>
            </td>
            <td>{t("entityType")}</td>
            <td>{t(`${mode === "paymentTransactionView" ? "paymentTransactionId" : "entityReference"}`)}</td>
            <td>{t("settlementOfferUsed")}</td>
            <td align='right'>{t("discountedAmount")}</td>
            <td align='right'>{t("allocatedAmount")}</td>
          </tr>
        </thead>
        <tbody>
          {paymentAllocations
            .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
            .map((paymentAllocation) => (
              <tr key={paymentAllocation.id}>
                <td>
                  {dateFormatter.dateTimeOrString(paymentAllocation.createdOn, DefaultBlankDateText, DateOutputType.ShortDate)}
                </td>
                <td className={`payment-allocation-type ${paymentAllocation.type.toLowerCase()}`}>
                  {t(`${paymentAllocation.type}Title`)}
                </td>
                <td className={`payment-allocation-status ${paymentAllocation.status.toLowerCase()}`}>
                  {t(`${paymentAllocation.status}Title`)}
                </td>
                <td>{paymentAllocation.entityType}</td>
                <td>
                  {mode === "entityView" ? (
                    <EntityLink paymentAllocation={paymentAllocation} />
                  ) : (
                    <PaymentTransactionLink id={paymentAllocation.paymentTransactionId} />
                  )}
                </td>
                <td>{paymentAllocation.settlementOfferId ? t("yes") : t("no")}</td>
                <td align='right' className='font-bold'>
                  <CurrencyItem currency={currency} value={paymentAllocation.discountedAmount} />
                </td>
                <td align='right' className='font-bold'>
                  <CurrencyItem currency={currency} value={paymentAllocation.allocatedAmount} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default PaymentAllocationTable;
