/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useRef, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FieldErrors, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddOrEditItemProps,
  ProviderFormValidationCommon,
  AddOrEditMode,
  mapToAddPaymentMethodConfiguration,
  mapToPaymentMethodConfiguration,
} from "../ProvidersCommon/Common";
import { PageTitle } from "../../../common/PageTitle";
import { addConfiguration, updateConfiguration } from "../../../../services/paymentProviders/paymentProviders.service";
import { DefaultProvider } from "../DefaultProvider";
import { tPaymentProviders } from "../../../../i18n";
import { checkAndRegisterIpAddress } from "../../../../services/opayo/opayo.service";
import PaymentProviderFiltersDialog from "../ProvidersCommon/PaymentProviderFiltersDialog";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { isFillerDisplayPassword } from "../../../../utils/secureStringUtils";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import ProviderButtonToolbar from "../ProvidersCommon/ProviderButtonToolbar";
import {
  CurrentFormModel,
  CurrentPaymentMethodType,
  CurrentPaymentProviderConfiguration,
  CurrentProviderDisplayName,
  CurrentProviderModelConsts,
  mapToAddConfiguration,
  mapToUpdateConfiguration,
} from "./opayoConfiguration";
import OpayoGeneralSettingsTab from "./OpayoGeneralSettingsTab";
import { TabArray, selectTabFromValidationErrors } from "../../../../helpers/hookForms/hookFormHelpers";
import TabRef from "../../../../components/Tabs/TabWithReference";
import { OpayoIntegrationType } from "../../../../models/paymentProviders/opayo/opayoModels";
import "../ProvidersCommon/Connect.scss";
import OpayoOptionsTab from "./OpayoOptionsTab";

const EventKeyGeneralSettings = "general";
const EventKeyOptions = "options";

interface CurrentItemProps extends AddOrEditItemProps<CurrentPaymentProviderConfiguration, CurrentFormModel> {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  onSave: () => void;
  paymentProvider?: PaymentProvider;
}

const opayoKeyPrefix = "opayoForm";

/*
 * Form validation schema
 */
export const useValidationSchema = () => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix: opayoKeyPrefix });
  return Yup.object({
    ...ProviderFormValidationCommon,
    vendorName: Yup.string()
      .label(t("labelOpayoVendorName"))
      .required()
      .min(CurrentProviderModelConsts.VendorMinLength)
      .max(CurrentProviderModelConsts.VendorMaxLength),
    adminUser: Yup.string()
      .label(t("labelOpayoAdminUser"))
      .required()
      .min(CurrentProviderModelConsts.UserMinLength)
      .max(CurrentProviderModelConsts.UserMaxLength),
    adminPassword: Yup.string()
      .label(t("labelOpayoAdminPassword"))
      .required()
      .min(CurrentProviderModelConsts.PasswordMinLength)
      .max(CurrentProviderModelConsts.PasswordMaxLength),
    useTestServer: Yup.boolean(),
    integrationType: Yup.string().required(),
    integrationKey: Yup.string()
      .label(t("labelIntegrationKey"))
      .when("integrationType", {
        is: (integrationType: OpayoIntegrationType) => integrationType === OpayoIntegrationType.Pi,
        then: Yup.string()
          .required()
          .min(CurrentProviderModelConsts.IntegrationKeyMinLength)
          .max(CurrentProviderModelConsts.IntegrationKeyMaxLength),
        otherwise: Yup.string().notRequired(),
      }),
    integrationPassword: Yup.string()
      .label(t("labelIntegrationPassword"))
      .when("integrationType", {
        is: (integrationType: OpayoIntegrationType) => integrationType === OpayoIntegrationType.Pi,
        then: Yup.string()
          .required()
          .min(CurrentProviderModelConsts.IntegrationPasswordMinLength)
          .max(CurrentProviderModelConsts.IntegrationPasswordMaxLength),
        otherwise: Yup.string().notRequired(),
      }),
    vendorTxCodeType: Yup.string().label(t("labelVendorCodeGeneration")).required(),
  }).required();
};

/*
 * The Opayo add/edit component. Supports both Pi and Server integrations for Opayo
 * For Pi see https://developer-eu.elavon.com/docs/opayo, Server see https://developer-eu.elavon.com/docs/opayo-server
 */
export const OpayoItem = ({ mode, data, paymentProvider, onSave }: CurrentItemProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);
  const [selectedTab, setSelectedTab] = useState<string | null>(EventKeyGeneralSettings);

  const validation = useValidationSchema();

  // For now Opayo only supports one payment method type (cards)
  const paymentMethodConfiguration = data?.paymentMethodConfigurations.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  const [filterGroup, setFilterGroup] = useState<ItemFilterGroup | undefined>(paymentMethodConfiguration?.filterGroup);
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const tabRefs = useRef<TabArray>([]);

  const addMode = mode === "add";
  const providerId = data?.id;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validation) });

  // Saves the form data ONLY if the user details are valid
  const handleSave = async (model: CurrentFormModel): Promise<void> => {
    setSaving(true);

    // Make sure we can connect to Opayo with these details
    const result =
      !addMode && isFillerDisplayPassword(model.adminPassword)
        ? await checkAndRegisterIpAddress(model.vendorName, model.adminUser, undefined, model.isTest, data?.id)
        : await checkAndRegisterIpAddress(model.vendorName, model.adminUser, model.adminPassword, model.isTest);

    if (!result) {
      // Failed to register IP address, it's not entirely needed for Pi but it's a good idea
      if (model.integrationType === OpayoIntegrationType.Server) {
        setSaving(false);
        return;
      }
    }

    if (!data) {
      const addConfig = mapToAddConfiguration(model);
      const addPaymentMethod = mapToAddPaymentMethodConfiguration(CurrentPaymentMethodType, model.enabled, filterGroup);
      addConfig.paymentMethodConfigurations = [addPaymentMethod];
      if (await addConfiguration(addConfig).finally(() => setSaving(false))) {
        onSave();
      }
    } else {
      const configuration = mapToUpdateConfiguration(data, model);
      const paymentMethod = mapToPaymentMethodConfiguration(paymentMethodConfiguration!, model.enabled, filterGroup);
      configuration.paymentMethodConfigurations = [paymentMethod];
      await updateConfiguration(configuration).finally(() => setSaving(false));
      onSave();
    }
  };

  // Handles validation errors on the form, and selects the tab with the first error
  const handleValidationErrors = (errorData: FieldErrors<CurrentFormModel>): void => {
    selectTabFromValidationErrors(tabRefs, errorData, setSelectedTab);
  };

  return (
    <section className='custom-connect-page'>
      <DefaultProvider paymentProviderId={providerId} allowSetDefault>
        <PageTitle
          title={t(addMode ? "paymentProviders.titleAddConfiguration" : "paymentProviders.titleEditConfiguration", {
            providerName: CurrentProviderDisplayName,
          })}
        />
      </DefaultProvider>
      <Form
        id='opayo-configuration-form'
        className='mt-2'
        noValidate
        onSubmit={handleSubmit(
          (submitData) => handleSave(submitData as CurrentFormModel),
          (errorData) => handleValidationErrors(errorData)
        )}
      >
        <Tabs
          defaultActiveKey={EventKeyGeneralSettings}
          className='mb-2'
          activeKey={selectedTab ?? EventKeyGeneralSettings}
          onSelect={(tab) => setSelectedTab(tab)}
        >
          <Tab eventKey={EventKeyGeneralSettings} title={t("commonTableTitles.generalSettingsTabTitle")}>
            <TabRef eventKey={EventKeyGeneralSettings} index={0} tabRefs={tabRefs}>
              <OpayoGeneralSettingsTab
                mode={mode}
                data={data}
                paymentProvider={paymentProvider}
                itemFilterGroup={filterGroup}
                onShowFilterDialog={() => setShowFilterDialog(true)}
                register={register}
                watch={watch}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </TabRef>
          </Tab>
          <Tab eventKey={EventKeyOptions} title={t("commonTableTitles.optionsTabTitle")}>
            <TabRef eventKey='paymentMethods' index={1} tabRefs={tabRefs}>
              <OpayoOptionsTab data={data} register={register} />
            </TabRef>
          </Tab>
        </Tabs>
      </Form>
      <ProviderButtonToolbar formId='opayo-configuration-form' saving={saving} />
      <PaymentProviderFiltersDialog
        filterGroup={filterGroup}
        show={showFilterDialog}
        onUpdate={(group) => {
          setShowFilterDialog(false);
          setFilterGroup(group);
        }}
        onClose={() => setShowFilterDialog(false)}
      />
    </section>
  );
};

OpayoItem.defaultProps = { data: undefined, paymentProvider: undefined };

export default OpayoItem;
