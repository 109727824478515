import { ReactElement } from 'react';
import { BasicTag } from '../../components/tags/BasicTag';
import { BadgeSize } from '../../models/misc/badgeSize';
import {
  PaidStatus,
  PaidStatusMap,
} from '../../models/transactions/transactionEnums';
import './PaidStatusTag.scss';

/*
 * Generates transaction paid status tag
 */
export const PaidStatusTag = ({
  status,
  className,
  size,
}: {
  status: PaidStatus;
  className?: string;
  size?: BadgeSize;
}): ReactElement => {
  const text = PaidStatusMap.get(status);
  return (
    <BasicTag
      tagName={status}
      tagText={text}
      className={className}
      tagCssEnd='-status'
      size={size}
    />
  );
};
PaidStatusTag.defaultProps = {
  className: undefined,
  size: BadgeSize.Medium,
};
export default PaidStatusTag;
