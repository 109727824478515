import { AccountType, LegalEntityType } from "../accounts/accountEnums";
import { TransactionType } from "../transactions/transactionEnums";
import { CustomHostIdRebuildState } from "./customHostIdRebuildState";

export enum CustomHostIdCreateOption {
  CreateIfNull = "CreateIfNull",
  AlwaysCreate = "AlwaysCreate",
  NeverCreate = "NeverCreate",
}

export const CustomHostIdCreateOptionMap = new Map<CustomHostIdCreateOption, string>([
  [CustomHostIdCreateOption.CreateIfNull, "If not set"],
  [CustomHostIdCreateOption.AlwaysCreate, "Always"],
  [CustomHostIdCreateOption.NeverCreate, "Never"],
]);

export const CustomHostIdCreateOptionTextIndexMap = new Map<CustomHostIdCreateOption, string>([
  [CustomHostIdCreateOption.CreateIfNull, "createOptionIfNull"],
  [CustomHostIdCreateOption.AlwaysCreate, "createOptionAlways"],
  [CustomHostIdCreateOption.NeverCreate, "createOptionNever"],
]);

export const CustomHostIdUpdateOptionTextIndexMap = new Map<CustomHostIdCreateOption, string>([
  [CustomHostIdCreateOption.CreateIfNull, "updateOptionIfNull"],
  [CustomHostIdCreateOption.AlwaysCreate, "updateOptionAlways"],
  [CustomHostIdCreateOption.NeverCreate, "updateOptionNever"],
]);

export const CustomHostIdCreateOptionArray = Array.from(CustomHostIdCreateOptionMap.entries());

export interface CustomHostIdSettings {
  id: string;
  createOption: CustomHostIdCreateOption;
  updateOption: CustomHostIdCreateOption;
  trimWhiteSpace: boolean;
  parseValue: string;
  lastRebuildDateTime?: Date;
  rebuildState: CustomHostIdRebuildState;
}

export interface CustomHostParserAccount {
  hostId: string;
  accountReference: string;
  name: string;
  shortName: string;
  accountType: AccountType;
  legalEntityType: LegalEntityType;
  currency: string;
}

export interface CustomHostParserTransaction {
  hostId: string;
  transactionType: TransactionType;
  transactionTypeName: string;
  transactionReference: string;
  transactionSecondReference: string;
  orderReference: string;
  transactionDate: string;
  dueDate: string | null;
  currency: string;
  transactionAmount: number;
  baseTransactionAmount: number | null;
  description: string;
  paymentTermsDescription: string;
}

export interface ExampleParseResult {
  isValid: boolean;
  errorMessage: string;
  parseValue: string;
  exampleAccount: CustomHostParserAccount;
  exampleTransaction: CustomHostParserTransaction;
  customHostId: string;
}
