/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { Form } from "react-bootstrap";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";

interface NullBooleanFormSelectProps<TFormModel extends FieldValues> {
  register: UseFormRegister<TFormModel>;
  fieldName: Path<TFormModel>;
  label?: string;
  nullOption: string;
  trueOption: string;
  falseOption: string;
  defaultState?: boolean;
  disabled?: boolean;
}

/*
 * Allows selection of true, false or  null (undefined)
 */
const NullBooleanFormSelect = <TFormModel extends FieldValues>({
  register,
  defaultState,
  fieldName,
  label,
  nullOption,
  trueOption,
  falseOption,
  disabled,
}: // eslint-disable-next-line arrow-body-style
NullBooleanFormSelectProps<TFormModel>): ReactElement => {
  const defaultValue = defaultState?.toString() ?? "null";
  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        disabled={disabled}
        {...register(fieldName, {
          setValueAs: (v) => {
            if (v === "true") return true;
            if (v === "false") return false;
            return null;
          },
        })}
        defaultValue={defaultValue}
      >
        <option value='null'>{nullOption}</option>
        <option value='true'>{trueOption}</option>
        <option value='false'>{falseOption}</option>
      </Form.Select>
    </>
  );
};

NullBooleanFormSelect.defaultProps = {
  defaultState: true,
  label: undefined,
  disabled: false,
};

export default NullBooleanFormSelect;
