/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { tPages } from "../../../i18n";
import { CreateNotificationUser, NotificationUser } from "../../../models/notifications/notificationUser";
import { NotificationUserType } from "../../../models/notifications/notificationUserType";
import { User } from "../../../models/users/user";
import NewUserDialog, { FormModel } from "./NewUserDialog";

const keyPrefix = "notificationsPage";

interface AddUserButtonProps {
  users: User[];
  notificationUsers: NotificationUser[];
  onSave: (newUser: CreateNotificationUser) => Promise<void>;
}

const AddUserButton = ({ users, notificationUsers, onSave }: AddUserButtonProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleOnSave = async (model: FormModel): Promise<void> => {
    setShow(false);
    setSaving(true);

    const newUser: CreateNotificationUser = {
      type: model.type,
    };
    if (model.type === NotificationUserType.EmailAddress) {
      newUser.emailAddress = model.emailAddress;
    } else {
      newUser.userId = model.userId;
    }
    onSave(newUser).finally(() => setSaving(false));
  };

  const availableUsers = users.filter((x) => !notificationUsers.some((nUser) => nUser.userId === x.id));

  return (
    <>
      <SpinnerButton
        size='sm'
        variant='primary'
        title={t("buttonAddUser")}
        showSpinner={saving}
        onClick={() => setShow(true)}
      />
      {show && (
        <NewUserDialog
          users={availableUsers}
          show={show}
          mode='add'
          onClose={() => setShow(false)}
          onSave={(model) => handleOnSave(model)}
        />
      )}
    </>
  );
};

export default AddUserButton;
