import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditItemButton } from "../../../components/buttons/CommonIconButtons";
import PaymentRequestUpdate from "../../../models/paymentRequests/paymentRequestUpdate";
import { PaymentRequest, PaymentRequestContact } from "../../../models/paymentRequests/paymentRequest";

import { updatePaymentRequest } from "../../../services/paymentRequests/paymentRequests";
import EditContactDialog, { EditFormModel } from "./EditContactDialog";
import { tPages } from "../../../i18n";
import { PaymentRequestStatus } from "../../../models/paymentRequests/paymentRequestStatus";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";

const keyPrefix = "paymentRequestPage.contactEditDialog";

const mapToContact = (type: string | undefined, model: EditFormModel): PaymentRequestContact =>
  ({
    firstNames: model.firstNames,
    surname: model.surname,
    companyName: model.companyName,
    emailAddress: model.emailAddress,
    address: {
      type,
      address1: model.address1,
      address2: model.address2,
      address3: model.address3,
      address4: model.address4,
      city: model.city,
      state: model.state,
      postCode: model.postCode,
      country: model.country,
    },
  } as PaymentRequestContact);

interface EditContactButtonButtonProps {
  type: "billing" | "delivery";
  paymentRequest: PaymentRequest;
  onUpdate?: () => Promise<void>;
}

/*
 * Button and dialog to trigger edit payment billing contact
 */
const EditContactButton = ({ type, paymentRequest, onUpdate }: EditContactButtonButtonProps): ReactElement => {
  const [show, setShow] = useState<boolean>(false);
  const [contact, setContact] = useState<PaymentRequestContact | undefined>();
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const disabled = !usePermission(UserPermission.EditPaymentRequest);

  useEffect(() => {
    setContact(type === "billing" ? paymentRequest.billingContact : paymentRequest.deliveryContact);
  }, [type, paymentRequest]);

  const handleUpdate = async (model: EditFormModel) => {
    const update = {
      ...model,
      id: paymentRequest.id,
      billingContact: paymentRequest.billingContact,
      deliveryContact: paymentRequest.deliveryContact,
    } as PaymentRequestUpdate;

    const contactUpdate = mapToContact(contact?.address?.type ?? type, model);
    if (type === "billing") {
      update.billingContact = contactUpdate;
    } else {
      update.deliveryContact = contactUpdate;
    }

    setShow(false);
    await updatePaymentRequest(update);
    if (onUpdate) await onUpdate();
  };

  const status = paymentRequest.requestStatus === PaymentRequestStatus.Open;

  const dialogTitle = status ? `titleEdit${type}Contact` : "titleCannotEditPaymentRequest";

  return (
    <>
      <EditItemButton disabled={!status || disabled} onClick={() => setShow(status)} title={t(dialogTitle)} />
      <EditContactDialog
        title={dialogTitle}
        contact={contact ?? undefined}
        show={show}
        onClose={() => setShow(false)}
        onSave={(model) => handleUpdate(model)}
      />
    </>
  );
};

EditContactButton.defaultProps = {
  onUpdate: undefined,
};

export default EditContactButton;
