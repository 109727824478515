import { FormEvent, ReactElement, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { Account } from "../../../models/accounts/account";
import LatePaymentSelection from "./LatePaymentSelection";
import SettlementConfigurationSelection from "./SettlementConfigurationSelection";
import { EditPaymentOverrideStatus, AccountEditPaymentStatus } from "../../../models/accounts/accountSettingEnums";
import { enumToArray } from "../../../utils/enumUtils";
import { updateAccountSettings } from "../../../services/accounts/accounts.service";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";

const keyPrefix = "accountPage.settings";

/*
 * Tab showing various settings for the account
 */
const AccountSettingsTab = ({ account }: { account: Account }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const [saving, setSaving] = useState(false);
  const [accountSettings, setAccountSettings] = useState(account.accountSettings);
  const disabled = !usePermission(UserPermission.EditAccountSettings);

  const handleUpdateSettlementConfiguration = async (
    settlementId: string | undefined,
    disableAutoApplySettlements: boolean
  ) => {
    accountSettings.disableAutoApplySettlements = disableAutoApplySettlements;
    accountSettings.settlementConfigurationGroupId = settlementId ?? null;
    setAccountSettings(accountSettings);
  };

  const handleLatePaymentConfiguration = async (latePaymentId: string | undefined, disableLatePayment: boolean) => {
    accountSettings.disableLatePayments = disableLatePayment;
    accountSettings.latePaymentConfigurationGroupId = latePaymentId;
    setAccountSettings(accountSettings);
  };

  const handleEditPaymentChange = async (e: FormEvent<HTMLSelectElement>) => {
    accountSettings.editPaymentStatus = e.currentTarget.value as AccountEditPaymentStatus;
    setAccountSettings(accountSettings);
  };

  const handleEditPaymentOverrideStatus = async (e: FormEvent<HTMLSelectElement>) => {
    accountSettings.editPaymentOverrideStatus = e.currentTarget.value as EditPaymentOverrideStatus;
    setAccountSettings(accountSettings);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await updateAccountSettings(account.id, accountSettings, true);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title>{t("titleAccountOptions")}</Card.Title>
          <Row>
            <SettlementConfigurationSelection
              accountSettings={accountSettings}
              saving={saving || disabled}
              onUpdate={handleUpdateSettlementConfiguration}
            />
            <LatePaymentSelection accountSettings={accountSettings} saving={saving || disabled} onUpdate={handleLatePaymentConfiguration} />
          </Row>
          <Row className='mt-4'>
            <Card.Title>{t("titlePaymentEditingOptions")}</Card.Title>
          </Row>
          <Row>
            <Form.Group as={Col} sm='6'>
              <Form.Label>{t("labelEditPayment")}</Form.Label>
              <Col xs='6'>
                <Form.Select
                  size='sm'
                  defaultValue={accountSettings.editPaymentStatus}
                  disabled={saving || disabled}
                  onChange={handleEditPaymentChange}
                >
                  {enumToArray(AccountEditPaymentStatus).map((item) => (
                    <option key={item} value={item}>
                      {t(`editPaymentStatus${item}`)}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <div>{t("descriptionEditPayment")}</div>
            </Form.Group>
            <Form.Group as={Col} sm='6'>
              <Form.Label>{t("labelEditPaymentOverride")}</Form.Label>
              <Col xs='6'>
                <Form.Select
                  size='sm'
                  defaultValue={accountSettings.editPaymentStatus}
                  disabled={saving || disabled}
                  onChange={handleEditPaymentOverrideStatus}
                >
                  {enumToArray(EditPaymentOverrideStatus).map((item) => (
                    <option key={item} value={item}>
                      {t(`editPaymentOverrideStatus${item}`)}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <div>{t("descriptionEditPaymentOverride")}</div>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <Row className='mt-3'>
        <Col>
          <SpinnerButton title={t("buttonSave")} showSpinner={saving} onClick={handleSave} disabled={disabled} />
        </Col>
      </Row>
    </>
  );
};

export default AccountSettingsTab;
