import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "react-bootstrap";
import { useQuery } from "react-query";
import { tPages } from "../../../i18n";
import NotificationSwitchList from "./NotificationSwitchList";
import { NotificationItem, getDefaultCustomerNotificationItems } from "./NotificationItems";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getCustomerNotifications, updateNotificationUser } from "../../../services/notifications/notification.service";
import { NotificationUser } from "../../../models/notifications/notificationUser";
import { NotificationSetting } from "../../../models/notifications/notificationSetting";
import AllowCustomerNotificationsAlert from "../../../components/tenantSettingAlerts/AllowCustomerNotificationsAlert";

const keyPrefix = "notificationsPage";

/*
 * Tab for updating the customer notification settings
 */
const CustomerNotificationsTab = (): ReactElement => {
  const [notificationItems, setNotificationItems] = useState<NotificationItem[]>(getDefaultCustomerNotificationItems());
  const [customerNotificationUser, setCustomerNotificationUser] = useState<NotificationUser>();

  const { t } = useTranslation(tPages.ns, { keyPrefix });

  // Reads in the customer notification user and settings
  const { isLoading: isCustomerNotificationLoading, refetch: refetchCustomerNotification } = useQuery(
    [QueryNameConsts.CustomerNotifications],
    async () => {
      const customer = await getCustomerNotifications(true);
      setCustomerNotificationUser(customer);

      const defaults = getDefaultCustomerNotificationItems();

      // Lets check that the customer has all of the default notification settings we are expecting, and if not add them
      let hasAll = true;
      defaults.forEach((defaultItem) => {
        const found = customer.notificationSettings.find((y) => y.notificationName === defaultItem.notificationName);
        if (!found) {
          hasAll = false;
          const setting = {
            notificationUserId: customer.id,
            notificationName: defaultItem.notificationName,
            isEnabled: defaultItem.enabled,
          } as NotificationSetting;
          customer.notificationSettings.push(setting);
        }
      });

      // Rebuilds the stored notification switches
      const items = defaults.map((defaultItem) => {
        const item: NotificationItem = { ...defaultItem };
        const found = customer.notificationSettings?.find((y) => y.notificationName === item.notificationName);
        if (found) {
          item.enabled = found.isEnabled;
        }
        return item;
      });
      setNotificationItems(items);

      // If we added any new settings, update the customer
      if (hasAll) {
        await updateNotificationUser(customer);
      }
      return customer;
    }
  );

  // Handles a notification check box changing
  const handleNotificationCheck = async (notificationName: string, isEnabled: boolean): Promise<void> => {
    // Enable it in the customer notification user
    if (customerNotificationUser) {
      const setting = customerNotificationUser.notificationSettings.find((x) => x.notificationName === notificationName);
      if (setting) {
        setting.isEnabled = isEnabled;
      }
      await updateNotificationUser(customerNotificationUser);
      await refetchCustomerNotification();
    }

    // Update the local notification items
    setNotificationItems((items) => {
      const item = items.find((x) => x.notificationName === notificationName);
      if (item) {
        item.enabled = isEnabled;
      }
      return items.map((x) => x);
    });
  };
  const panelEnabled = !isCustomerNotificationLoading && customerNotificationUser;

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card className='' aria-disabled>
            <Card.Title>
              <AllowCustomerNotificationsAlert />
            </Card.Title>
            <Card.Body>
              <Row>
                <p>{t("labelCustomerInstructions1")} </p>
                <p>{t("labelCustomerInstructions2")} </p>
              </Row>
              <Row className={`${panelEnabled ? "" : "panel-disabled"}`}>
                <Col>
                  <NotificationSwitchList
                    notificationItems={notificationItems}
                    onNotificationChanged={handleNotificationCheck}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomerNotificationsTab;
