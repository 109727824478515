import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";
import {
  TrueLayerProviderName,
  TrueLayerProviderDisplayName,
  TrueLayerProviderModelConsts,
  TrueLayerSettings,
  TrueLayerCredentials,
  TrueLayerPaymentProviderConfiguration,
  AddTrueLayerPaymentProviderConfiguration,
  BankAccountDetails,
} from "../../../../models/paymentProviders/trueLayer/trueLayerModels";
import { getNonFillerDisplayPasswordOrUndefined } from "../../../../utils/secureStringUtils";
import {
  ProviderFormModel,
  mapToAddPaymentProviderConfiguration,
  mapToPaymentProviderConfiguration,
} from "../ProvidersCommon/Common";

// Only supports GBP for now
const DefaultCurrency = "GBP";

export interface TrueLayerFormModel extends ProviderFormModel {
  clientId: string;
  clientSecret: string;
  useSandbox: boolean;
  beneficiaryName?: string;
  beneficiaryReference?: string;
  remitterReference?: string;
  accountName: string;
  accountNumber: string;
  sortCode: string;
  iban?: string;
  currency: string;
}

export const CurrentProviderName = TrueLayerProviderName;
export const CurrentProviderDisplayName = TrueLayerProviderDisplayName;
export const CurrentPaymentMethodType = PaymentMethodType.BankTransfer;
export const CurrentProviderModelConsts = TrueLayerProviderModelConsts;
export type CurrentSettings = TrueLayerSettings;
export type CurrentCredentials = TrueLayerCredentials;
export type CurrentPaymentProviderConfiguration = TrueLayerPaymentProviderConfiguration;
export type CurrentAddPaymentProviderConfiguration = AddTrueLayerPaymentProviderConfiguration;
export type CurrentFormModel = TrueLayerFormModel;

export const mapToAddConfiguration = (model: CurrentFormModel): CurrentAddPaymentProviderConfiguration => {
  const configuration = mapToAddPaymentProviderConfiguration<CurrentAddPaymentProviderConfiguration>(
    model,
    CurrentProviderName
  );

  configuration.isTest = model.useSandbox;

  configuration.settings = {
    providerName: CurrentProviderName,
    beneficiaryName: model.beneficiaryName,
    beneficiaryReference: model.beneficiaryReference,
    remitterReference: model.remitterReference,
    beneficiaryBankAccount: {
      accountName: model.accountName,
      accountNumber: model.accountNumber,
      sortCode: model.sortCode,
      iban: undefined,
      currency: DefaultCurrency,
    } as BankAccountDetails,
  } as CurrentSettings;

  configuration.credentials = {
    providerName: CurrentProviderName,
    clientId: model.clientId,
    clientSecret: model.clientSecret,
    useSandbox: model.useSandbox,
  } as CurrentCredentials;

  return configuration;
};

export const mapToUpdateConfiguration = (
  data: CurrentPaymentProviderConfiguration,
  model: CurrentFormModel
): CurrentPaymentProviderConfiguration => {
  const configuration = mapToPaymentProviderConfiguration(data, model);
  configuration.isTest = model.useSandbox;
  configuration.credentials = { providerName: CurrentProviderName } as CurrentCredentials;
  configuration.credentials.clientId = model.clientId;
  configuration.credentials.clientSecret = getNonFillerDisplayPasswordOrUndefined(model.clientSecret);
  configuration.credentials.useSandbox = model.useSandbox;
  configuration.settings.beneficiaryName = model.beneficiaryName;
  configuration.settings.beneficiaryReference = model.beneficiaryReference;
  configuration.settings.remitterReference = model.remitterReference;
  configuration.settings.beneficiaryBankAccount.accountName = model.accountName;
  configuration.settings.beneficiaryBankAccount.accountNumber = model.accountNumber;
  configuration.settings.beneficiaryBankAccount.sortCode = model.sortCode;
  configuration.settings.beneficiaryBankAccount.currency = DefaultCurrency;
  return configuration;
};
