import { SubscriptionKey } from "../../../models/subscriptionKeys/subscriptionKey";
import BasePayThemApi from "./basePayThemApi";

interface MaximumNumberOfKeysResult {
  maximumKeyCount: number;
}

interface CountResult {
  count: number;
}

export class SubscriptionKeysApi extends BasePayThemApi {
  private endPoint = "subscription-keys";

  // Returns all of the subscription keys
  public async getSubscriptionKeys(): Promise<SubscriptionKey[]> {
    const result = await this.get<SubscriptionKey[]>(this.endPoint);
    return result;
  }

  // Returns the subscription key with the matching id
  public async getSubscriptionKey(key: string): Promise<SubscriptionKey> {
    const result = await this.getItem<SubscriptionKey, string>(this.endPoint, key);
    return result;
  }

  // Creates a new subscription key
  public async createSubscriptionKey(noDataset = true): Promise<SubscriptionKey> {
    const url = noDataset ? `${this.endPoint}?noDataset=${encodeURIComponent("true")}` : this.endPoint;
    const inst = this.getInstance();
    const response = await inst.post<SubscriptionKey>(url);
    return response.data;
  }

  // Deletes the subscription key
  public async deleteSubscriptionKey(key: string): Promise<void> {
    await this.deleteItem(this.endPoint, key);
  }

  // Returns the maximum number of keys that can be created
  public async maximumNumberOfKeys(): Promise<number> {
    const result = await this.get<MaximumNumberOfKeysResult>(`${this.endPoint}/maximum`);
    return result.maximumKeyCount;
  }

  // Returns the number of subscription keys
  public async countKeys(): Promise<number> {
    const result = await this.get<CountResult>(`${this.endPoint}/count`);
    return result.count;
  }
}

export default SubscriptionKeysApi;
