import { TypeColumn, TypeDataSource, TypeFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { ReactElement, useCallback } from "react";
import { PaymentTransactionIncludeOptions } from "../../../common/api/payThemApi/paymentTransactionIncludeOptions";
import { PaymentTransactionsApi } from "../../../common/api/payThemApi/paymentTransactionsApi";
import { DataGrid } from "../../../components/grid/DataGrid";
import {
  DataGridState,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  SortDesending,
} from "../../../components/grid/dataGridHelpers";
import { PaymentTransaction } from "../../../models/paymentTransactions/paymentTransaction";
import GridNamesConsts from "../../GridNamesConsts";
import { DataGridStorage } from "../../../common/hooks/useDataGridStorage";

export const PaymentTransactionsFilters: TypeFilterValue = [
  { name: "type", operator: "eq", type: "select", value: "" },
  { name: "payThemReference", operator: "contains", type: "string", value: "" },
  { name: "paymentRequest.customerName", type: "string", operator: "contains", value: "" },
  { name: "totalAmount", operator: "gte", type: "number", value: null },
  { name: "status", operator: "eq", type: "select", value: null },
  { name: "allocatedStatus", operator: "eq", type: "select", value: null },
];

/*
 * Default sort column
 */
export const PaymentTransactionsSortColumnName = "statusDateTime";
export const PaymentTransactionsSortDirection = SortDesending;

export const PaymentTransactionsSortInfo = {
  name: PaymentTransactionsSortColumnName,
  dir: PaymentTransactionsSortDirection,
} as TypeSingleSortInfo;

/*
 * Callback function for loading in a page of data
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps): Promise<LoadDataResult<PaymentTransaction>> =>
  loadPageData(
    skip,
    limit,
    sortInfo,
    PaymentTransactionsSortColumnName,
    PaymentTransactionsSortDirection,
    filterValue,
    async (pageParameters) => {
      const api = new PaymentTransactionsApi();
      const result = await api.getPaymentTransactions(
        pageParameters.page,
        pageParameters.itemsPerPage,
        pageParameters.sort,
        pageParameters.filter,
        { paymentRequest: true } as PaymentTransactionIncludeOptions
      );
      return result;
    }
  );

/*
 * Our grid name
 */
export const GridName = GridNamesConsts.PaymentTransactionGrid;

interface PaymentTransactionsGridProps {
  columns: TypeColumn[];
  dataSource?: TypeDataSource;
  gridStorage?: DataGridStorage;
  style?: {
    [key: string]: string | number;
  };
  onFilterValueChange?: (filterValue: TypeFilterValue | undefined) => void;
}

/*
 * The payment transactions grid, used by various list components
 */
const PaymentTransactionsGrid = ({
  columns,
  dataSource,
  gridStorage,
  style,
  onFilterValueChange,
}: PaymentTransactionsGridProps): ReactElement => {
  const defaultDataSource = useCallback(loadData, []);

  const handleFilterChange = (grid: DataGridState, filterValue: TypeFilterValue | undefined) => {
    gridStorage?.setFilters(filterValue);
    if (onFilterValueChange) onFilterValueChange(filterValue);
  };

  // Grid state is optional
  const gridState = gridStorage?.dataGridState;
  const haveGrid = gridState !== undefined;

  return (
    <DataGrid<PaymentTransaction>
      idProperty='id'
      columns={columns}
      dataSource={dataSource ?? defaultDataSource}
      style={style}
      defaultFilterValue={gridState?.filters ?? PaymentTransactionsFilters}
      enableFiltering
      onFilterValueChange={(filterValue) => (haveGrid ? handleFilterChange(gridState, filterValue) : true)}
      defaultSortInfo={gridState?.sortInfo}
      onSortInfoChange={(sortInfo) => (haveGrid ? gridStorage?.setSortInfo(sortInfo) : true)}
      defaultSkip={gridState?.pagination?.skip}
      onSkipChange={(skip) => (haveGrid ? gridStorage?.setPaginationSkip(skip) : true)}
      defaultLimit={gridState?.pagination?.limit}
      onLimitChange={(limit) => (haveGrid ? gridStorage?.setPaginationLimit(limit) : true)}
    />
  );
};

PaymentTransactionsGrid.defaultProps = {
  dataSource: undefined,
  style: undefined,
  gridStorage: undefined,
  onFilterValueChange: undefined,
};
export default PaymentTransactionsGrid;
