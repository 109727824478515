import { CountResult } from "../../../models/count/countResult";
import { PaymentRequest, PaymentRequestAdd, TransactionAllocationInfo } from "../../../models/paymentRequests/paymentRequest";
import PaymentRequestUpdate from "../../../models/paymentRequests/paymentRequestUpdate";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";

export class PaymentRequestsApi extends BasePayThemApi {
  private endPoint = "payment-requests";

  /*
   * Returns a page of payment requests
   */
  public async getPaymentRequests(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string,
    includeTransactions = false
  ): Promise<PageResult<PaymentRequest>> {
    const params = new URLSearchParams();
    params.append("includeTransactions", includeTransactions.toString());
    const result = await this.getPage<PaymentRequest>(this.endPoint, page, itemsPerPage, sort, filter, params);
    return result;
  }

  /*
   * Returns a payment request with the given id
   */
  public async getPaymentRequest(id: string, includeTransactions = false): Promise<PaymentRequest> {
    const params = new URLSearchParams();
    if (includeTransactions) params.append("expand", "transactions");
    const result = await this.getItem<PaymentRequest, string>(this.endPoint, id, params);
    return result;
  }

  /*
   * Returns the count of payment requests matching the given filter
   */
  public async getPaymentRequestCount(filter?: string): Promise<CountResult> {
    const params = new URLSearchParams();
    if (filter) params.append("filter", filter);
    const result = await this.get<CountResult>(`${this.endPoint}/count`, params);
    return result;
  }

  /*
   * Creates a new payment request, returning the payment request record
   */
  public async createPaymentRequest(paymentRequest: PaymentRequestAdd): Promise<PaymentRequest> {
    const result = await this.postWithResponse<PaymentRequestAdd, PaymentRequest>(this.endPoint, paymentRequest);
    return result;
  }

  /*
   * Updates an existing payment request
   */
  public async updatePaymentRequest(paymentRequest: PaymentRequestUpdate): Promise<void> {
    await this.patch(this.endPoint, paymentRequest);
  }

  /*
   * Updates the TransactionAllocationInfo items associated to a payment request
   */
  public async updateTransactionAllocationInfo(
    paymentRequestId: string,
    transactionAllocationInfo: TransactionAllocationInfo[]
  ): Promise<PaymentRequest> {
    const url = `${this.endPoint}/${paymentRequestId}/transaction-allocation-info`;

    const result = await this.patchWithResponse<TransactionAllocationInfo[], PaymentRequest>(url, transactionAllocationInfo);
    return result;
  }
}

export default PaymentRequestsApi;
