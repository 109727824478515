import { ReactElement } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomerFormatter, getFirstAddress, getFirstEmailAddress } from "../../utils/customerFormatter";
import { PanelItem } from "../../components/panels/Panels";
import { Contact } from "../../models/contacts/contact";
import { textOrDefault } from "../../helpers/stringHelpers";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import { tPages } from "../../i18n";

const keyPrefix = "transactionPage.contact";

/*
 * Contact details card
 */
const ContactDetails = ({ title, contact }: { title: string; contact: Contact | null | undefined }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const address = CustomerFormatter.fullAddressToString(getFirstAddress(contact?.addresses));
  const emailAddress = getFirstEmailAddress(contact?.emailAddresses)?.emailAddress;

  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text as='div'>
            {contact && (
              <Row>
                <Col md={6}>
                  <div className='main-item'>
                    <div className='title'>{t("contactName")}</div>
                    <div className='content'>
                      {textOrDefault(CustomerFormatter.getFullName(contact.firstNames, contact.surname))}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <PanelItem title={t("jobTitle")}>{textOrDefault(contact.jobTitle)}</PanelItem>
                  <PanelItem title={t("email")}>
                    {textOrDefault(emailAddress)}
                    {emailAddress && <CopyToClipboard text={emailAddress} />}
                  </PanelItem>
                  <PanelItem title={t("address")}>
                    {textOrDefault(address)}
                    {address && <CopyToClipboard text={textOrDefault(address)} />}
                  </PanelItem>
                </Col>
              </Row>
            )}
            {!contact && <div>{t("noContactExists")}</div>}
            <div>{}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
export default ContactDetails;
