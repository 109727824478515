import { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tForms } from '../../../../i18n';
import ItemFilterGroup from '../../../../models/itemFiltering/itemFilterGroup';

interface EditFiltersButtonProps {
  onClick: () => void;
  filterGroup?: ItemFilterGroup;
}

/*
 * Display the providers edit filters button
 */
const EditFiltersButton = ({ filterGroup, onClick }: EditFiltersButtonProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  const count = filterGroup?.filterConfigurations?.length ?? 0;
  return (
    <Button size='sm' onClick={onClick} variant='secondary'>
      {`${t('buttonEditFilters')} ${count > 0 ? `(${count})` : ''}`}
    </Button>
  );
};

EditFiltersButton.defaultProps = {
  filterGroup: undefined,
};

export default EditFiltersButton;
