import lang from "i18next";
import { PaymentRequestsApi } from "../../common/api/payThemApi/paymentRequestsApi";
import { tCommon } from "../../i18n";
import { PaymentRequest, PaymentRequestAdd, TransactionAllocationInfo } from "../../models/paymentRequests/paymentRequest";
import PaymentRequestUpdate from "../../models/paymentRequests/paymentRequestUpdate";
import { toastSuccess } from "../notifications/toastNotification.service";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

/*
 * Returns the number of payment requests matching the given filter
 */
export const countPaymentRequests = async (filter: string): Promise<number> => {
  try {
    const api = new PaymentRequestsApi();
    const result = await api.getPaymentRequestCount(filter);
    return result.totalItems;
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    throw error;
  }
};

/*
 * Updates an existing payment request
 */
export const updatePaymentRequest = async (paymentRequest: PaymentRequestUpdate): Promise<void> => {
  try {
    const api = new PaymentRequestsApi();
    await api.updatePaymentRequest(paymentRequest);
    toastSuccess(lang.t("toastMessages.paymentRequests.paymentRequestUpdateSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentRequests.paymentRequestUpdate");
    throw error;
  }
};

/*
 * Updates the TransactionAllocationInfo items associated to a payment request
 */
export const updateTransactionAllocationInfo = async (
  paymentRequestId: string,
  transactionAllocationInfo: TransactionAllocationInfo[]
): Promise<PaymentRequest> => {
  try {
    const api = new PaymentRequestsApi();
    const result = await api.updateTransactionAllocationInfo(paymentRequestId, transactionAllocationInfo);
    toastSuccess(lang.t("toastMessages.paymentRequests.paymentRequestUpdateSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentRequests.paymentRequestUpdate");
    throw error;
  }
};

/*
 * Creates a new payment request
 */
export const createPaymentRequest = async (paymentRequest: PaymentRequestAdd): Promise<PaymentRequest> => {
  try {
    const api = new PaymentRequestsApi();
    const result = await api.createPaymentRequest(paymentRequest);
    toastSuccess(lang.t("toastMessages.paymentRequests.paymentRequestAddSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentRequests.paymentRequestAdd");
    throw error;
  }
};
