import { DefaultBlankText } from '../common/commonConsts';

export class CurrencyFormatter {
  public static getFormattedCurrencyNumber(
    currency: string,
    value: number
  ): string {
    const style = { style: 'currency', currency };

    const numFormat = new Intl.NumberFormat([], style);
    const formattedNumber = value.toLocaleString([], {
      minimumFractionDigits: numFormat.resolvedOptions().minimumFractionDigits,
      maximumFractionDigits: numFormat.resolvedOptions().maximumFractionDigits,
    });

    return formattedNumber;
  }

  /*
   * Returns currency with value
   */
  public static toCurrencyString(currency: string, value: number): string {
    return CurrencyFormatter.getFormattedCurrencyNumber(currency, value);
  }

  /*
   * Returns currency with value
   */
  public static toCurrencyStringWithoutSymbol(
    currency: string,
    value: number,
    includeSpace = true
  ): string {
    const formattedNumber = CurrencyFormatter.getFormattedCurrencyNumber(
      currency,
      value
    );
    return includeSpace
      ? `${currency} ${formattedNumber}`
      : currency + formattedNumber;
  }

  /*
   * Returns the currency with value or given text if value is null
   */
  public static currencyOrString(
    currency: string,
    value: number | undefined | null,
    blankText: string = DefaultBlankText
  ): string {
    if (!value) return blankText;
    return this.toCurrencyStringWithoutSymbol(currency, value);
  }
}
export default CurrencyFormatter;
