import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import { buildFilterDescriptions } from "./tableFiltersHelper";
import "./TableFiltersBox.scss";

interface TableFiltersBoxProps {
  filtersSelected: boolean;
  tableFilters?: TypeFilterValue;
}

const TableFiltersBox = ({ filtersSelected, tableFilters }: TableFiltersBoxProps): ReactElement => {
  const tableFilterDescriptions = buildFilterDescriptions(tableFilters);

  return (
    <Card className='table-filters-box'>
      <Card.Body className='pt-0 pb-0'>
        <Table borderless className={`${!filtersSelected ? "filters-disabled" : ""}`}>
          <tbody>
            <tr>
              <td>{tableFilterDescriptions && tableFilterDescriptions.map((item) => <div key={item}>{item}</div>)}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

TableFiltersBox.defaultProps = {
  tableFilters: undefined,
};

export default TableFiltersBox;
