enum UserPermission {
  ConfigureSystemSettings = "ConfigureSystemSettings",
  ViewAuditLogs = "ViewAuditLogs",
  ManageAccessKeys = "ManageAccessKeys",
  ManageSubscriptionKeys = "ManageSubscriptionKeys",
  ConfigurePaymentProvider = "ConfigurePaymentProvider",
  DeletePaymentProvider = "DeletePaymentProvider",
  CreateSettlementConfigurations = "CreateSettlementConfigurations",
  EditSettlementConfigurations = "EditSettlementConfigurations",
  DeleteSettlementConfigurations = "DeleteSettlementConfigurations",
  CreateLatePayments = "CreateLatePayments",
  EditLatePayments = "EditLatePayments",
  DeleteLatePayments = "DeleteLatePayments",
  CreateCompanies = "CreateCompanies",
  EditCompanies = "EditCompanies",
  DeleteCompanies = "DeleteCompanies",
  EditAccountSettings = "EditAccountSettings",
  UploadTransactionDocument = "UploadTransactionDocument",
  DeleteTransactionDocument = "DeleteTransactionDocument",
  EditTransactionSettlementOffer = "EditTransactionSettlementOffer",
  EditPaymentRequest = "EditPaymentRequest",
  ExportPaymentTransactions = "ExportPaymentTransactions",
  ImportAllocatedStatus = "ImportAllocatedStatus",
  ChangeAllocatedStatus = "ChangeAllocatedStatus",
  CancelPendingPayment = "CancelPendingPayment",
  CapturePendingPayment = "CapturePendingPayment",
}

export default UserPermission;
