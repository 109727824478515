/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useCallback, useRef, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FieldErrors, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddOrEditMode,
  AddOrEditItemProps,
  ProviderFormValidationCommon,
  mapToAddPaymentMethodConfiguration,
  mapToPaymentMethodConfiguration,
} from "../ProvidersCommon/Common";
import { PageTitle } from "../../../common/PageTitle";
import { hasSupportedCurrencies } from "../ProvidersCommon/CommonControls";
import { addConfiguration, updateConfiguration } from "../../../../services/paymentProviders/paymentProviders.service";
import { DefaultProvider } from "../DefaultProvider";
import { tPaymentProviders } from "../../../../i18n";
import PaymentProviderFiltersDialog from "../ProvidersCommon/PaymentProviderFiltersDialog";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { CurrencyInfo, findCurrencyCode } from "../../../../utils/currencyCodeUtils";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import ProviderButtonToolbar from "../ProvidersCommon/ProviderButtonToolbar";
import {
  CurrentFormModel,
  CurrentPaymentProviderConfiguration,
  CurrentProviderDisplayName,
  CurrentProviderModelConsts,
  CurrentPaymentMethodType,
  mapToAddConfiguration,
  mapToUpdateConfiguration,
} from "./trueLayerConfiguration";
import TrueLayerGeneralSettingsTab from "./TrueLayerGeneralSettingsTab";
import TrueLayerBankSettingsTab from "./TrueLayerBankSettingsTab";
import { TabArray, selectTabFromValidationErrors } from "../../../../helpers/hookForms/hookFormHelpers";
import "../ProvidersCommon/Connect.scss";
import TabRef from "../../../../components/Tabs/TabWithReference";

const EventKeyGeneralSettings = "general";
const EventKeyBankDetails = "bankDetails";

interface CurrentItemProps extends AddOrEditItemProps<CurrentPaymentProviderConfiguration, CurrentFormModel> {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  onSave: () => void;
}

/*
 * Form validation schema
 */
const validationSchema = Yup.object({
  ...ProviderFormValidationCommon,

  clientId: Yup.string()
    .label("Client ID")
    .required()
    .min(CurrentProviderModelConsts.ClientIdNameMinLength)
    .max(CurrentProviderModelConsts.ClientIdNameMaxLength),

  clientSecret: Yup.string()
    .label("Client Secret")
    .required()
    .min(CurrentProviderModelConsts.ClientSecretMinLength)
    .max(CurrentProviderModelConsts.ClientSecretMaxLength),

  useSandbox: Yup.boolean().label("Use Test Server"),

  // accountName: Yup.string()
  //   .label("Account Name")
  //   .required()
  //   .min(CurrentProviderModelConsts.AccountNameMinLength)
  //   .max(CurrentProviderModelConsts.AccountNameMaxLength),

  accountNumber: Yup.string()
    .label("Account Number")
    .required()
    .min(CurrentProviderModelConsts.AccountNumberMinLength)
    .max(CurrentProviderModelConsts.AccountNumberMaxLength),

  sortCode: Yup.string()
    .label("Sort Code")
    .required()
    .min(CurrentProviderModelConsts.SortCodeMinLength)
    .max(CurrentProviderModelConsts.SortCodeMaxLength),

  beneficiaryName: Yup.string()
    .label("Payees Name")
    .required()
    .min(CurrentProviderModelConsts.BeneficiaryNameMinLength)
    .max(CurrentProviderModelConsts.BeneficiaryNameMaxLength),

  beneficiaryReference: Yup.string()
    .label("Payees Reference")
    .required()
    .min(CurrentProviderModelConsts.BeneficiaryReferenceMinLength)
    .max(CurrentProviderModelConsts.BeneficiaryReferenceMaxLength),

  remitterReference: Yup.string()
    .label("Payees Reference")
    .required()
    .min(CurrentProviderModelConsts.RemitterReferenceMinLength)
    .max(CurrentProviderModelConsts.RemitterReferenceMaxLength),
}).required();

/*
 * The TrueLayer add/edit component
 */
export const TrueLayerItem = ({ mode, data, paymentProvider, onSave }: CurrentItemProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);
  const [selectedTab, setSelectedTab] = useState<string | null>(EventKeyGeneralSettings);

  // For now TrueLayer only supports one payment method type
  const paymentMethodConfiguration = data?.paymentMethodConfigurations.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  const [filterGroup, setFilterGroup] = useState<ItemFilterGroup | undefined>(paymentMethodConfiguration?.filterGroup);
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const tabRefs = useRef<TabArray>([]);

  // For now TrueLayer only supports one payment method type
  const paymentMethodTypeInfo = paymentProvider?.paymentMethodTypesInfo.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  const currencyList = useCallback(() => {
    if (hasSupportedCurrencies(paymentMethodTypeInfo)) {
      const currencies = paymentMethodTypeInfo!.supportedCurrencies!;
      const codes = currencies
        .map((currency) => findCurrencyCode(currency))
        .filter((entry) => entry !== undefined) as CurrencyInfo[];
      return codes;
    }
    return [];
  }, [paymentMethodTypeInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  // Called when the user clicks the save button
  const handleSave = async (model: CurrentFormModel): Promise<void> => {
    setSaving(true);
    if (!data) {
      const addConfig = mapToAddConfiguration(model);
      const addPaymentMethod = mapToAddPaymentMethodConfiguration(CurrentPaymentMethodType, model.enabled, filterGroup);
      addConfig.paymentMethodConfigurations = [addPaymentMethod];
      if (await addConfiguration(addConfig).finally(() => setSaving(false))) {
        onSave();
      }
    } else {
      const configuration = mapToUpdateConfiguration(data, model);
      const paymentMethod = mapToPaymentMethodConfiguration(paymentMethodConfiguration!, model.enabled, filterGroup);
      configuration.paymentMethodConfigurations = [paymentMethod];
      await updateConfiguration(configuration).finally(() => setSaving(false));
      onSave();
    }
  };

  const addMode = mode === "add";
  const providerId = data?.id;

  // Handles validation errors on the form, and selects the tab with the first error
  const handleValidationErrors = (errorData: FieldErrors<CurrentFormModel>): void => {
    selectTabFromValidationErrors(tabRefs, errorData, setSelectedTab);
  };

  return (
    <section className='custom-connect-page'>
      <DefaultProvider paymentProviderId={providerId} allowSetDefault>
        <PageTitle
          title={t(addMode ? "paymentProviders.titleAddConfiguration" : "paymentProviders.titleEditConfiguration", {
            providerName: CurrentProviderDisplayName,
          })}
        />
      </DefaultProvider>
      <Form
        className='mt-2'
        noValidate
        onSubmit={handleSubmit(
          (submitData) => handleSave(submitData as CurrentFormModel),
          (errorData) => handleValidationErrors(errorData)
        )}
      >
        <Tabs
          defaultActiveKey={EventKeyGeneralSettings}
          className='mb-2'
          activeKey={selectedTab ?? EventKeyGeneralSettings}
          onSelect={(tab) => setSelectedTab(tab)}
        >
          <Tab eventKey={EventKeyGeneralSettings} title={t("commonTableTitles.generalSettingsTabTitle")}>
            <TabRef eventKey={EventKeyGeneralSettings} index={0} tabRefs={tabRefs}>
              <TrueLayerGeneralSettingsTab
                mode={mode}
                data={data}
                paymentProvider={paymentProvider}
                itemFilterGroup={filterGroup}
                onShowFilterDialog={() => setShowFilterDialog(true)}
                register={register}
                errors={errors}
              />
            </TabRef>
          </Tab>
          <Tab eventKey={EventKeyBankDetails} title={t("trueLayerForm.bankDetailsTabTitle")}>
            <TabRef eventKey={EventKeyBankDetails} index={1} tabRefs={tabRefs}>
              <TrueLayerBankSettingsTab mode={mode} data={data} register={register} errors={errors} />
            </TabRef>
          </Tab>
        </Tabs>
        <ProviderButtonToolbar saving={saving} />
      </Form>
      <PaymentProviderFiltersDialog
        filterGroup={filterGroup}
        show={showFilterDialog}
        onUpdate={(group) => {
          setShowFilterDialog(false);
          setFilterGroup(group);
        }}
        onClose={() => setShowFilterDialog(false)}
        currencyOptions={currencyList()}
      />
    </section>
  );
};
TrueLayerItem.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default TrueLayerItem;
