import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeColumn, TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { PageTitle } from "../common/PageTitle";
import { HtmlTitle } from "../common/HtmlTitle";
import ExportButton from "./Export/ExportButton";
import ImportAllocatedStatusButton from "./Import/ImportAllocatedStatusButton";
import { tPages } from "../../i18n";
import PaymentTransactionsGrid, {
  PaymentTransactionsFilters,
  PaymentTransactionsSortInfo,
  GridName,
} from "./Grid/PaymentTransactionsGrid";
import { defaultDataGridPagination } from "../../components/grid/dataGridHelpers";
import {
  CustomerDetailsCompanyNameColumn,
  OptionsColumn,
  PayThemReferenceColumn,
  PaymentRequestCustomerNameColumn,
  PaymentTransactionAllocatedStatusColumn,
  PaymentTransactionIdColumn,
  PaymentTransactionStatusColumn,
  PaymentTransactionStatusDateTimeColumn,
  PaymentTransactionTotalAmountColumn,
  PaymentTransactionTypeColumn,
} from "./Grid/GridColumns";
import PageLoading from "../../components/loading/PageLoading";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";

/*
 * Default standard column list
 */
const gridColumns: TypeColumn[] = [
  PaymentTransactionIdColumn,
  PaymentTransactionTypeColumn,
  PayThemReferenceColumn,
  PaymentTransactionStatusDateTimeColumn,
  CustomerDetailsCompanyNameColumn,
  PaymentRequestCustomerNameColumn,
  PaymentTransactionTotalAmountColumn,
  PaymentTransactionStatusColumn,
  PaymentTransactionAllocatedStatusColumn,
  OptionsColumn,
];

/*
 * The payment transactions list
 */
export const PaymentTransactionsList = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const [gridFilters, setGridFilters] = useState<TypeFilterValue>();
  const gridData = useDataGridStorage(
    GridName,
    PaymentTransactionsFilters,
    PaymentTransactionsSortInfo,
    defaultDataGridPagination,
    gridColumns
  );

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentTransactions", tPages)} />
      {gridData?.dataGridState ? (
        <>
          <PageTitle title='pageTitlePaymentTransactions' />
          <section className='data-grid-page'>
            <PaymentTransactionsGrid
              columns={gridColumns}
              gridStorage={gridData}
              onFilterValueChange={(filterValue) => setGridFilters(filterValue)}
            />
            <div className='bottom-button-group'>
              <ExportButton tableFilters={gridFilters} />
              <ImportAllocatedStatusButton />
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default PaymentTransactionsList;
