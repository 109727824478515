import React, { ReactElement, useState } from "react";
import PropTypes from "prop-types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { IconButton } from "./IconButton";
import { tForms } from "../../i18n";

interface DeleteItemConfirmationProps<T> {
  item: T;
  // eslint-disable-next-line react/require-default-props
  icon: IconProp;
  title: string;
  body: string;
  disabled?: boolean;
  onDelete: (item: T) => void;
}

/*
 * Display a delete icon, confirms deletion and calls back to the delete handler
 */
export function DeleteItemConfirmation<T>({
  item,
  title,
  body,
  disabled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  icon,
  onDelete,
}: DeleteItemConfirmationProps<T>): ReactElement {
  const { t } = useTranslation([tForms.ns]);
  const [show, setShow] = useState<boolean>(false);

  const handleDeleteConfirmation = () => {
    setShow(false);
    onDelete(item);
  };

  const handleClick = () => {
    if (!disabled) {
      setShow(true);
    }
  };

  return (
    <>
      <IconButton title={t("titleDelete")} icon={icon} disabled={disabled} onClick={handleClick} />
      <ConfirmationDialog
        show={show}
        title={title}
        body={body}
        handleClose={() => setShow(false)}
        handleConfirmation={handleDeleteConfirmation}
      />
    </>
  );
}

DeleteItemConfirmation.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

DeleteItemConfirmation.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  icon: faTrashAlt,
  disabled: false,
  title: "Confirm Delete",
  body: "Are you sure?",
};

export default DeleteItemConfirmation;
