import {
  AddPaymentProviderTypeConfiguration,
  initAddPaymentProviderTypeConfiguration,
  PaymentProviderCredentials,
  PaymentProviderSettings,
  PaymentProviderTypeConfiguration,
} from "../paymentProviderConfiguration";
import PaymentProviderModelConsts from "../paymentProviderModelConsts";

export const StripeProviderName = "Stripe";
export const StripeProviderDisplayName = "Stripe";

export interface StripeSettings extends PaymentProviderSettings {
  // True to create and store a Stripe customer record and store it against the account
  useStripeCustomer: boolean;

  // Stores the payment method (cards) when using the payer website
  storePayerPaymentMethod: boolean;

  // True that save card details is on by default
  saveCardOptionDefault: boolean;

  // Api url to use (overrides default if not blank)
  apiUrl?: string;
}

export interface StripeCredentials extends PaymentProviderCredentials {
  // Stripe Account Id
  stripeAccountId?: string;

  // Stripe connect client id when this account was connected
  connectClientId: string;
}

export class StripeProviderModelConsts extends PaymentProviderModelConsts {}

export type StripePaymentProviderConfiguration = PaymentProviderTypeConfiguration<StripeSettings, StripeCredentials>;
export type AddStripePaymentProviderConfiguration = AddPaymentProviderTypeConfiguration<StripeSettings, StripeCredentials>;

export interface StripeAccountInformation {
  // Unique identifier for this account
  id: string;

  // Display name of the company
  displayName: string;

  // Default currency on this account
  defaultCurrency: string;

  // True if this is running on the Stripe legacy platform
  isLegacyAccount: boolean;

  // Payment capabilities
  capabilities: StripeAccountCapabilities;
}

export interface StripeAccountCapabilities {
  // True if this account supports card payments
  cardPayments: boolean;
}

/*
 * Constructor for AddStripePaymentProviderConfiguration
 */
export const initAddStripePaymentProviderConfiguration = (name: string): AddStripePaymentProviderConfiguration => {
  const data = {
    ...initAddPaymentProviderTypeConfiguration(StripeProviderName, name),
    settings: { providerName: StripeProviderName } as StripeSettings,
    credentials: { providerName: StripeProviderName } as StripeCredentials,
  };
  return data;
};
