/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Form } from "react-bootstrap";
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";
import { tPaymentProviders } from "../../../../i18n";
import {
  CommonProviderFormControls,
  ProviderFilterFormControl,
  ProviderSupportedMethodsFormControl,
  hasSupportedCurrencies,
  ProviderSupportedCurrenciesFormControl,
} from "../ProvidersCommon/CommonControls";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import { CurrentPaymentProviderConfiguration, CurrentFormModel, CurrentPaymentMethodType } from "./trueLayerConfiguration";
import { AddOrEditMode } from "../ProvidersCommon/Common";
import { FillerDisplayPassword } from "../../../../utils/secureStringUtils";

const keyPrefix = "trueLayerForm";

interface TrueLayerGeneralSettingsTabProps {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  itemFilterGroup: ItemFilterGroup | undefined;
  onShowFilterDialog: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

/*
 * General settings tab for TrueLayer
 */
const TrueLayerGeneralSettingsTab = ({
  mode,
  data,
  paymentProvider,
  itemFilterGroup,
  onShowFilterDialog,
  register,
  errors,
}: TrueLayerGeneralSettingsTabProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  const addMode = mode === "add";
  const defaultPassword = addMode ? undefined : FillerDisplayPassword;

  // For now TranSafe only supports one payment method type
  const paymentMethodTypeInfo = paymentProvider?.paymentMethodTypesInfo.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <CommonProviderFormControls data={data} register={register} errors={errors} />
            <hr />
            <Row className='mb-2'>
              <Col>
                <ProviderFilterFormControl filterGroup={itemFilterGroup} onClick={onShowFilterDialog} />
              </Col>
              <ProviderSupportedMethodsFormControl
                supportedPaymentMethodGroups={paymentProvider?.supportedPaymentMethodGroups}
              />
              {hasSupportedCurrencies(paymentMethodTypeInfo) && (
                <>
                  <Form.Group as={Col} sm='6'>
                    {" "}
                  </Form.Group>
                  <ProviderSupportedCurrenciesFormControl supportedCurrencies={paymentMethodTypeInfo!.supportedCurrencies} />
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Card.Title>{t("titleCredentials")}</Card.Title>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelServer")}</Form.Label>
                <Form.Select
                  {...register("useSandbox", {
                    setValueAs: (v) => {
                      if (v === "true") return true;
                      return false;
                    },
                  })}
                  defaultValue={data?.credentials?.useSandbox.toString()}
                >
                  <option value='false'>{t("optionLiveServer")}</option>
                  <option value='true'>{t("optionTestServer")}</option>
                </Form.Select>
              </Form.Group>
              <Form.Control.Feedback type='invalid'>{errors?.useSandbox?.message}</Form.Control.Feedback>
            </Row>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelClientId")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeHolderClientId")}
                  {...register("clientId")}
                  className={`form-control ${errors?.clientId?.message ? "is-invalid" : ""}`}
                  defaultValue={data?.credentials?.clientId}
                />
                <Form.Control.Feedback type='invalid'>{errors?.clientId?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm='6'>
                <Form.Label>{t("labelClientSecret")}</Form.Label>
                <Form.Control
                  type='password'
                  placeholder={t("placeHolderClientSecret")}
                  {...register("clientSecret")}
                  className={`form-control ${errors?.clientSecret?.message ? "is-invalid" : ""}`}
                  defaultValue={defaultPassword}
                />
                <Form.Control.Feedback type='invalid'>{errors?.clientSecret?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

TrueLayerGeneralSettingsTab.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default TrueLayerGeneralSettingsTab;
