export class MainRouteConsts {
  public static readonly Home = "/";

  public static readonly PaymentRequests = "/payment-requests";

  public static readonly PaymentTransactions = "/payment-transactions";

  public static readonly Transactions = "/transactions";

  public static readonly Accounts = "/accounts";

  public static readonly Banks = "/banks";

  public static readonly Settlements = "/settlement-configurations";

  public static readonly PaymentPlans = "/payment-plans";

  public static readonly UserSettings = "/my-settings";

  public static readonly LatePayments = "/late-payments";

  public static readonly Funding = "/funding";

  public static readonly Payers = "/payers";

  public static readonly Activities = "/activities";
}

export default MainRouteConsts;
