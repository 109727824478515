/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { tForms } from "../../i18n";
import { getSortedRealCurrencies } from "../../utils/currencyCodeUtils";
import { FormControlsProps } from "./settlementConfigurationFormModel";
import { getCurrenciesFilterConfiguration, getTransactionAmountFilterConfiguration } from "../../utils/itemFilterUtils";

const NoCurrencyOption = "anyCurrencySelection";
const keyPrefix = "settlementConfigurationForm";

export const MinMaxTransactionAmountFilter = ({ register, formState, addMode, data }: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;

  const transactionFilterConfiguration = getTransactionAmountFilterConfiguration(data?.filterGroup?.filterConfigurations);

  return (
    <>
      <Col md='4'>
        <Form.Group>
          <Form.Label>{t("labelMinimumTransactionAmount")}</Form.Label>
          <Form.Control
            type='number'
            placeholder={t("placeholderMinimumTransactionAmount")}
            {...register("minimumTransactionValue")}
            className={`form-control ${errors.minimumTransactionValue ? "is-invalid" : ""}`}
            defaultValue={!addMode ? transactionFilterConfiguration?.minimumTransactionValue : undefined}
          />
          <Form.Control.Feedback type='invalid'>{errors?.minimumTransactionValue?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md='4'>
        <Form.Group>
          <Form.Label>{t("labelMaximumTransactionAmount")}</Form.Label>
          <Form.Control
            type='number'
            placeholder={t("placeholderMaximumTransactionAmount")}
            {...register("maximumTransactionValue")}
            className={`form-control ${errors.maximumTransactionValue ? "is-invalid" : ""}`}
            defaultValue={!addMode ? transactionFilterConfiguration?.maximumTransactionValue : undefined}
          />
          <Form.Control.Feedback type='invalid'>{errors?.maximumTransactionValue?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </>
  );
};

export const MinMaxTransactionWithCurrencySelectionFilterControls = ({
  register,
  formState,
  addMode,
  data,
}: FormControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;
  const currencyList = useCallback(() => getSortedRealCurrencies(), []);

  const currencyFilterConfiguration = getCurrenciesFilterConfiguration(data?.filterGroup?.filterConfigurations);
  const selectedCurrency =
    currencyFilterConfiguration?.currencies?.length && currencyFilterConfiguration?.currencies?.length > 0
      ? currencyFilterConfiguration?.currencies?.[0]
      : undefined;

  return (
    <Row className='dialog-row last'>
      <h6>{t("labelFilter")}</h6>
      <Col md='4'>
        <Form.Group>
          <Form.Label>{t("labelTransactionCurrency")}</Form.Label>
          <Form.Select {...register("filterCurrency")} defaultValue={!addMode ? selectedCurrency : undefined}>
            {currencyList().map((currencyInfo, index) => {
              const optionElement = (
                <option key={currencyInfo.iso4217} value={currencyInfo.iso4217}>
                  {currencyInfo.iso4217}
                </option>
              );
              return index === 0 ? (
                <>
                  <option key={NoCurrencyOption} value=''>
                    {t(NoCurrencyOption)}
                  </option>
                  {optionElement}
                </>
              ) : (
                optionElement
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type='invalid'>{errors?.dateType?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <MinMaxTransactionAmountFilter register={register} formState={formState} addMode={addMode} data={data} />
    </Row>
  );
};

interface MinMaxTransactionWithFixedCurrencyFilterControlsProps extends FormControlsProps {
  currency: string;
}

export const MinMaxTransactionWithFixedCurrencyFilterControls = ({
  currency,
  register,
  formState,
  addMode,
  data,
}: MinMaxTransactionWithFixedCurrencyFilterControlsProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;

  return (
    <>
      <Row className='dialog-row last'>
        <h6>{t("labelFilter")}</h6>
        <Col md='4'>
          <Form.Group>
            <Form.Label>{t("labelTransactionCurrency")}</Form.Label>
            <Form.Control type='text' value={currency} readOnly />
            <Form.Control.Feedback type='invalid'>{errors?.dateType?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <MinMaxTransactionAmountFilter register={register} formState={formState} addMode={addMode} data={data} />
      </Row>
    </>
  );
};
