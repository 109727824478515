import { useState } from "react";
import { TypeColumn, TypeFilterValue, TypeSortInfo } from "@inovua/reactdatagrid-community/types";
import { DataGridPagination, DataGridState } from "../../components/grid/dataGridHelpers";
import { loadDataGridState, saveDataGridState } from "../../components/grid/dataGridStorage";
import useDatasetStorageName from "./useDatasetStorageName";


// The info we return from the hook
export interface DataGridStorage {
  dataGridState: DataGridState;
  setFilters: (filters?: TypeFilterValue) => void;
  setSortInfo: (sortInfo?: TypeSortInfo) => void;
  setPaginationSkip: (skip?: number) => void;
  setPaginationLimit: (limit?: number) => void;
}

/*
 * Hook to store the data grid state in local storage
 */
const useDataGridStorage = (
  name: string,
  defaultFilters: TypeFilterValue,
  defaultSortInfo: TypeSortInfo,
  defaultPagination: DataGridPagination | undefined | null,
  columns: TypeColumn[]
): DataGridStorage => {
  // Build the name from the dataset id and the name passed in
  const storageName = useDatasetStorageName(name);

  const [dataGridState, setDataGridState] = useState<DataGridState>(() => {
    const storedState = loadDataGridState(name, storageName, defaultFilters, defaultSortInfo, defaultPagination, columns);
    return storedState;
  });

  // Stores the filters on the grid
  const setFilters = (filters?: TypeFilterValue): void => {
    setDataGridState((prevState) => {
      const updatedState = prevState ? { ...prevState, filters } : ({ filters } as DataGridState);
      saveDataGridState(storageName, updatedState);
      return updatedState;
    });
  };

  // Stores the sort info in the grid
  const setSortInfo = (sortInfo?: TypeSortInfo): void => {
    setDataGridState((prevState) => {
      const updatedState = prevState ? { ...prevState, sortInfo } : ({ sortInfo } as DataGridState);
      saveDataGridState(storageName, updatedState);
      return updatedState;
    });
  };

  // Sets the pagination skip on the grid
  const setPaginationSkip = (skip?: number): void => {
    setDataGridState((prevState) => {
      const pagination = prevState?.pagination ? { ...prevState.pagination, skip } : ({ skip } as DataGridPagination);
      const updatedState = prevState ? { ...prevState, pagination } : ({ pagination } as DataGridState);
      saveDataGridState(storageName, updatedState);
      return updatedState;
    });
  };

  // Sets the pagination limit on the grid
  const setPaginationLimit = (limit?: number): void => {
    setDataGridState((prevState) => {
      const pagination = prevState?.pagination ? { ...prevState.pagination, limit } : ({ limit } as DataGridPagination);
      const updatedState = prevState ? { ...prevState, pagination } : ({ pagination } as DataGridState);
      saveDataGridState(storageName, updatedState);
      return updatedState;
    });
  };

  return { dataGridState, setFilters, setSortInfo, setPaginationSkip, setPaginationLimit };
};

export default useDataGridStorage;
