import { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";
import { DialogSecondaryButton, DialogPrimaryButton } from "./DialogButtons";

export type AddOrEditMode = "add" | "edit";

type DialogSize = undefined | "sm" | "lg" | "xl";

export interface AddOrEditDialogProps<TData, TModel> {
  show: boolean;
  mode: AddOrEditMode;
  data?: TData;
  onSave: (model: TModel) => void;
  onClose: () => void;
}

interface AddOrEditModelDialogProps {
  show: boolean;
  title: string;
  formId: string;
  mode: AddOrEditMode;
  size: DialogSize;
  onHide: () => void;
  onExited?: () => void;
  children: React.ReactNode;
}

/*
 * The controls for a standard add/edit modal dialog
 */
export const AddOrEditModelDialog = ({
  show,
  title,
  formId,
  mode,
  size,
  onHide,
  onExited,
  children,
}: AddOrEditModelDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  return (
    <Modal size={size} show={show} onHide={onHide} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={onHide} />
        <DialogPrimaryButton form={formId} type='submit' label={t(mode === "add" ? "buttonAdd" : "buttonUpdate")} />
      </Modal.Footer>
    </Modal>
  );
};

AddOrEditModelDialog.defaultProps = {
  onExited: undefined,
};
