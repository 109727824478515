import React, { ReactElement, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PanelItem } from '../../../components/panels/Panels';
import { isExpired } from '../../../helpers/dateHelpers';
import { textOrDefault } from '../../../helpers/stringHelpers';
import { tPages } from '../../../i18n';
import {
  SettlementOfferStatus,
  SettlementOfferStatusMap,
} from '../../../models/settlements/settlementEnums';
import { SettlementOffer } from '../../../models/settlements/settlementOffer';
import SettlementTypeDiscountEntry from './SettlementTypeDiscountEntry';
import { ConfirmationDialog } from '../../../components/dialogs/ConfirmationDialog';
import SettlementOfferDialog, {
  SettlementOfferFormModel,
} from './SettlementOfferDialog';
import { Transaction } from '../../../models/transactions/transaction';
import CustomToggle from '../../../components/misc/CustomToggle';
import './SettlementOffersEntry.scss';
import { DateOutputType } from '../../../utils/dateFormatter';
import { DefaultBlankText } from '../../../common/commonConsts';
import UserPermission from '../../../models/users/userPermission';
import useDateFormatter from '../../../common/hooks/useDateFormatter';
import usePermission from '../../../common/hooks/usePermission';

const keyPrefix = 'settlementOffersTab';

interface SettlementOfferItemMenuProps {
  settlementOffer: SettlementOffer;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const ValidDetails = ({
  settlementOffer,
}: {
  settlementOffer: SettlementOffer;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateFormatter = useDateFormatter();
  return (
    <>
      <PanelItem title={t('validFrom')}>
        {dateFormatter.dateTimeOrString(
          settlementOffer.validFrom,
          DefaultBlankText,
          DateOutputType.ShortDate
        )}
      </PanelItem>
      <PanelItem title={t('validUntil')}>
        {dateFormatter.dateTimeOrString(
          settlementOffer.validUntil,
          DefaultBlankText,
          DateOutputType.ShortDate
        )}
      </PanelItem>
    </>
  );
};

/*
 * Triple dot menu options
 */
const SettlementOfferItemMenu = ({
  settlementOffer,
  onEdit,
  onDelete,
}: SettlementOfferItemMenuProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    onDelete(settlementOffer.id);
  };
  const disabledEdit = !usePermission(UserPermission.EditSettlementConfigurations);
  const disabledDelete = !usePermission(UserPermission.DeleteSettlementConfigurations);

  return (
    <>
      <Dropdown
        align='end'
        className='settlement-offer-menu actionmenu-dropdown-title'
      >
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu title=''>
          <Dropdown.Item
            disabled={settlementOffer.status === SettlementOfferStatus.Used || disabledEdit}
            onClick={() => onEdit(settlementOffer.id)}
          >
            {t('menuItemEdit')}
          </Dropdown.Item>
          <Dropdown.Item
            disabled={settlementOffer.status === SettlementOfferStatus.Used || disabledDelete}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            {t('menuItemDelete')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmationDialog
        show={showDeleteConfirmation}
        title={t('titleDelete')}
        body={t('confirmDeleteMessage')}
        handleClose={() => setShowDeleteConfirmation(false)}
        handleConfirmation={handleDeleteConfirmation}
      />
    </>
  );
};

interface SettlementOffersEntryProps {
  settlementOffer: SettlementOffer;
  transaction: Transaction;
  onEdit: (settlementOffer: SettlementOffer) => void;
  onDelete: (id: string) => void;
}

const SettlementOffersEntry = ({
  settlementOffer,
  transaction,
  onEdit,
  onDelete,
}: SettlementOffersEntryProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const handleEdit = () => {
    setShowEditDialog(true);
  };

  async function handleSaveEdit(model: SettlementOfferFormModel) {
    const newOffer = { ...settlementOffer };

    newOffer.description = model.description;
    newOffer.validFrom = model.validFrom;
    newOffer.validUntil = model.validUntil;
    newOffer.amount = model.amount;
    newOffer.percentageDiscount = model.percentageDiscount;
    newOffer.priority = model.priority;
    setShowEditDialog(false);
    onEdit(newOffer);
  }

  const hasExpired = isExpired(settlementOffer.validUntil);
  const idTitle = `${t('id')}${hasExpired ? ` - ${t('expired')}` : ''}`;

  return (
    <div className='d-flex justify-content-between'>
      <div className={`flex-grow-1 ${hasExpired ? 'settlement-expired' : ''}`}>
        <Row>
          <Col md={6}>
            <PanelItem title={idTitle}>{settlementOffer.id}</PanelItem>
            <SettlementTypeDiscountEntry settlementOffer={settlementOffer} />
          </Col>
          <Col md={6}>
            <PanelItem title={t('status')}>
              {SettlementOfferStatusMap.get(settlementOffer.status)}
            </PanelItem>
            <ValidDetails settlementOffer={settlementOffer} />
          </Col>
        </Row>
        {settlementOffer.description && (
          <PanelItem title={t('description')}>
            {textOrDefault(settlementOffer.description)}
          </PanelItem>
        )}
      </div>
      <div className='ms-auto'>
        <div>
          <SettlementOfferItemMenu
            settlementOffer={settlementOffer}
            onDelete={(id) => onDelete(id)}
            onEdit={handleEdit}
          />
        </div>
      </div>
      <SettlementOfferDialog
        type={settlementOffer.type}
        data={settlementOffer}
        sourceTransaction={transaction}
        show={showEditDialog}
        mode='edit'
        onClose={() => setShowEditDialog(false)}
        onSave={(model) => handleSaveEdit(model)}
      />
    </div>
  );
};

export default SettlementOffersEntry;
