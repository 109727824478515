import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationCommonEN from "./assets/translations/en/common-en.json";
import translationFormsEN from "./assets/translations/en/forms-en.json";
import translationPagesEN from "./assets/translations/en/pages-en.json";
import translationUsersEN from "./assets/translations/en/users-en.json";
import translationDatasetsEN from "./assets/translations/en/datasets-en.json";
import translationPaymentProvidersEN from "./assets/translations/en/paymentProviders-en.json";
import translationTodosEN from "./assets/translations/en/todos-en.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ["common", "forms", "pages", "users", "datasets", "paymentProviders", "todos"],
    resources: {
      en: {
        common: translationCommonEN,
        forms: translationFormsEN,
        pages: translationPagesEN,
        users: translationUsersEN,
        datasets: translationDatasetsEN,
        paymentProviders: translationPaymentProvidersEN,
        todos: translationTodosEN,
      },
    },
    defaultNS: "common",
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export const tCommon = { ns: "common" };
export const tForms = { ns: "forms" };
export const tPages = { ns: "pages" };
export const tUsers = { ns: "users" };
export const tDatasets = { ns: "datasets" };
export const tPaymentProviders = { ns: "paymentProviders" };
export const tTodos = { ns: "todos" };
