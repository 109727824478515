import { ReactElement } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tPages } from '../../i18n';
import { PaymentTransaction } from '../../models/paymentTransactions/paymentTransaction';
import PaymentTransactionTable from '../PaymentTransactions/PaymentTransactionTable';

const keyPrefix = 'paymentRequestPage.paymentTransactions';

const PaymentsTab = ({
  paymentTransactions,
}: {
  paymentTransactions: PaymentTransaction[];
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const elements =
    !paymentTransactions || paymentTransactions.length === 0 ? (
      <p>{t('noPaymentAttemptsMessage')}</p>
    ) : (
      <PaymentTransactionTable paymentTransactions={paymentTransactions} />
    );

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card className='custom-detail-card'>
            <Card.Body>{elements}</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='panel-column'> </Col>
      </Row>
    </>
  );
};

export default PaymentsTab;
