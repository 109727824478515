import { useCallback, useMemo } from "react";
import { useFeature } from "flagged";
import UserPermission from "../../models/users/userPermission";
import useMe from "./useMe";
import FeatureFlagConsts from "../../FeatureFlagConsts";
import { UserMe } from "../../models/users/user";

const hasPermission = (
  user: UserMe | undefined,
  permission: UserPermission
): boolean => {
  if (!user) return false;
  if (!permission) return true;

  const { permissions } = user;
  return !!permissions?.includes(permission);
};

const hasPermissions = (
  user: UserMe | undefined,
  requiredPermissions: UserPermission[]
): boolean => {
  if (!user) return false;
  if (!requiredPermissions) return true;

  const { permissions } = user;

  let result = true;
  requiredPermissions.forEach(permission => {
    if (!!permissions?.includes(permission) === false)
      result = false;
  });
  return result;
}

/*
 * Checks if current user has required permissions
*/
const usePermission = (requiredPermission : UserPermission | UserPermission[]): boolean => {
  const { me, isLoading } = useMe();
  const allowPermissionPolicy = useFeature(FeatureFlagConsts.PermissionPolicy);
  
  const checkPermission = useCallback(():boolean => {
    if (isLoading)
      return false;

    if (Array.isArray(requiredPermission))
      return hasPermissions(me, requiredPermission)

    return hasPermission(me, requiredPermission)
  }, [isLoading, me, requiredPermission]);

  const result = useMemo(() => checkPermission(), [checkPermission]);

  // When permission policy is disabled, it always allow every required permissions
  if (!allowPermissionPolicy)
    return true;

  return result;
}

export default usePermission;