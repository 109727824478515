import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "../../components/icons/IconButton";
import { tForms } from "../../i18n";
import { PaymentFeeConfiguration } from "../../models/paymentsFees/paymentFeeConfiguration";
import UkLatePaymentDialog, { FormModel } from "./UkLatePaymentDialog";

interface EditLatePaymentButtonProps {
  configuration: PaymentFeeConfiguration;
  disabled?: boolean;
  onSave: (configuration: PaymentFeeConfiguration) => void;
}

const MapModel = (model: FormModel, current: PaymentFeeConfiguration): PaymentFeeConfiguration => {
  const configuration = { ...current };
  configuration.name = model.name;
  configuration.description = model.description;
  configuration.enabled = model.enabled;
  configuration.gracePeriodInDays = model.gracePeriodInDays;
  configuration.recurringPeriodInDays = model.recurringPeriodInDays;
  configuration.percentage = model.percentage;
  configuration.amount = model.amount;
  configuration.applyCharges = model.applyCharges;
  return configuration;
};

const EditLatePaymentButton = ({ configuration, disabled, onSave }: EditLatePaymentButtonProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  const [show, setShow] = useState<boolean>(false);

  const handleOnSave = (model: FormModel) => {
    setShow(false);
    onSave(MapModel(model, configuration));
  };

  const handleClick = () => {
    if (!disabled) {
      setShow(true);
    }
  };

  return (
    <>
      <IconButton title={t("titleEdit")} icon={faEdit} disabled={disabled} onClick={handleClick} />
      <UkLatePaymentDialog
        show={show}
        mode='edit'
        data={configuration}
        onClose={() => setShow(false)}
        onSave={(model) => handleOnSave(model)}
      />
    </>
  );
};

EditLatePaymentButton.defaultProps = {
  disabled: false,
};

export default EditLatePaymentButton;
