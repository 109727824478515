import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { MainRouteConsts } from "../MainRoutesConsts";
import { DefaultBlankText } from "../../common/commonConsts";

export const PaymentRequestLink = ({ id, children }: { id: string | undefined; children?: ReactNode }): ReactElement =>
  id ? <Link to={`${MainRouteConsts.PaymentRequests}/${id}`}>{children ?? id}</Link> : <>{DefaultBlankText}</>;

PaymentRequestLink.defaultProps = {
  children: undefined,
};
export default PaymentRequestLink;
