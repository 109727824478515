import ItemFilterConfiguration from "./itemFilterConfiguration";
import ItemFilteringType from "./itemFilteringType";

interface PaymentAmountFilterConfiguration extends ItemFilterConfiguration {
  minimumPaymentValue?: number;
  maximumPaymentValue?: number;
}

export const initPaymentAmountFilterConfiguration = (
  minimumPaymentValue?: number,
  maximumPaymentValue?: number
): PaymentAmountFilterConfiguration => {
  const configuration: PaymentAmountFilterConfiguration = {
    filterType: ItemFilteringType.PaymentAmount,
    isEnabled: true,
    minimumPaymentValue,
    maximumPaymentValue,
  };
  return configuration;
};

export default PaymentAmountFilterConfiguration;
