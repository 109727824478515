import { ReactElement } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useFeature } from "flagged";
import Header from "./Header";
import LeftHandSide from "./LeftHandSide";
import { HomePage } from "../views/HomePage";
import { MainRouteConsts } from "../views/MainRoutesConsts";
import ErrorPage from "../views/Errors/ErrorPage";
import PrivateRoute from "../components/route/PrivateRoute";
import MainRoutes from "../components/route/MainRoutes";
import { StripeCompleteConnect } from "../views/Settings/PaymentProviders/Stripe/StripeConnect";
import HttpStatusCode from "../common/httpStatusCode";
import RedirectUrlPage from "../views/Redirect/RedirectUrlPage";
import { StripeOAuthCallbackRoute } from "../services/stripe/stripeConnect.service";
import { GoCardlessCompleteConnect } from "../views/Settings/PaymentProviders/GoCardless/GoCardlessAuthorise";
import { GoCardlessOAuthCallbackRoute } from "../services/goCardless/goCardlessConnect.service";
import DatasetQueryParameter from "../views/Redirect/DatasetQueryParameter";
import FundingPage from "../views/Funding/FundingPage";
import FeatureFlagConsts from "../FeatureFlagConsts";
import WebsiteViewProvider from "../context/websiteView/WebsiteViewProvider";
import { WebViewCrossTenantValidator } from "../views/Redirect/WebViewCrossTenantValidator";
import "./Layout.scss";
import { CompanyDetails } from "../views/Settings/CompanyDetails";
import { SettingsRouteConsts } from "../views/SettingsRouteConsts";
import { Customisation } from "../views/Settings/Customisation";
import UserPermission from "../models/users/userPermission";
import { PaymentProviders } from "../views/Settings/PaymentProviders/PaymentProvidersList";
import PaymentProviderNewItem from "../views/Settings/PaymentProviders/PaymentProviderNewItem";
import { PaymentProviderEditItem } from "../views/Settings/PaymentProviders/PaymentProviderEditItem";
import { AccessKeys } from "../views/Settings/AccessKeys/AccessKeys";
import { SubscriptionKeys } from "../views/Settings/SubscriptionKeys";
import { UsersList } from "../views/Settings/Users/UsersList";
import { DatasetsList } from "../views/Settings/Datasets/DatasetsList";
import { DatasetItem } from "../views/Settings/Datasets/DatasetItem";
import NotificationSettingsPage from "../views/Settings/Notifications/NotificationsSettingsPage";
import WebHooksPage from "../views/Settings/WebHooks/WebHooksPage";
import { Preferences } from "../views/Settings/Preferences/Preferences";
import { HealthPage } from "../views/HealthPage";

export const Layout = (): ReactElement => {
  const allowCapitalise = useFeature(FeatureFlagConsts.Capitalise);
  const allowNewAccessKeyPage = useFeature(FeatureFlagConsts.NewAccessKeyPage);

  return (
    <BrowserRouter>
      <WebsiteViewProvider>
        <Header />
        <WebViewCrossTenantValidator />
        <DatasetQueryParameter />
        <div className='flex-container ' id='wrapper'>
          <div className='d-flex flex-row main-page'>
            <LeftHandSide />
            <Switch>
              <Route key='/health' path='/health' component={HealthPage} />
              <PrivateRoute key='/rd' path='/rd' exact component={RedirectUrlPage} requiredPermissions={[]} />
              <PrivateRoute path={MainRouteConsts.Home} exact component={HomePage} requiredPermissions={[]} />
              <PrivateRoute key={SettingsRouteConsts.Details} path={SettingsRouteConsts.Details} component={CompanyDetails} requiredPermissions={[]} />
              <PrivateRoute key={SettingsRouteConsts.Customisation} path={SettingsRouteConsts.Customisation} component={Customisation} requiredPermissions={[UserPermission.ConfigureSystemSettings]} />
              <PrivateRoute key={SettingsRouteConsts.Preferences} path={SettingsRouteConsts.Preferences} component={Preferences} requiredPermissions={[UserPermission.ConfigureSystemSettings]} />
              <PrivateRoute key={SettingsRouteConsts.PaymentProviders} path={SettingsRouteConsts.PaymentProviders} exact component={PaymentProviders} requiredPermissions={[UserPermission.ConfigurePaymentProvider]} fullWidth />
              <PrivateRoute key={SettingsRouteConsts.PaymentProvidersNew} path={SettingsRouteConsts.PaymentProvidersNew} parameters=':type' component={PaymentProviderNewItem} requiredPermissions={[UserPermission.ConfigurePaymentProvider]} />
              <PrivateRoute key={SettingsRouteConsts.PaymentProviders} path={SettingsRouteConsts.PaymentProviders} parameters=':id' component={PaymentProviderEditItem} requiredPermissions={[UserPermission.ConfigurePaymentProvider]} />
              {allowNewAccessKeyPage
                ? <PrivateRoute key={SettingsRouteConsts.AccessKeys} path={SettingsRouteConsts.AccessKeys} component={AccessKeys} requiredPermissions={[UserPermission.ManageAccessKeys]} />
                : <PrivateRoute key={SettingsRouteConsts.AccessKeys} path={SettingsRouteConsts.AccessKeys} component={SubscriptionKeys} requiredPermissions={[UserPermission.ManageSubscriptionKeys]} />}
              <PrivateRoute key={SettingsRouteConsts.Users} path={SettingsRouteConsts.Users} component={UsersList} requiredPermissions={[UserPermission.ConfigureSystemSettings]} />
              <PrivateRoute key={SettingsRouteConsts.Datasets} path={SettingsRouteConsts.Datasets} exact component={DatasetsList} requiredPermissions={[]} fullWidth />
              <PrivateRoute key={SettingsRouteConsts.Datasets} path={SettingsRouteConsts.Datasets} parameters=':id' component={DatasetItem} requiredPermissions={[UserPermission.EditCompanies]} />
              <PrivateRoute key={SettingsRouteConsts.Notifications} path={SettingsRouteConsts.Notifications} component={NotificationSettingsPage} requiredPermissions={[UserPermission.ConfigureSystemSettings]} />
              <PrivateRoute key={SettingsRouteConsts.WebHooks} path={SettingsRouteConsts.WebHooks} component={WebHooksPage} requiredPermissions={[UserPermission.ConfigureSystemSettings]} noContainer />
              {MainRoutes}
              {allowCapitalise && (
                <PrivateRoute
                  key={MainRouteConsts.Funding}
                  path={MainRouteConsts.Funding}
                  component={FundingPage}
                  requiredPermissions={[]}
                  exact
                  noContainer
                />
              )}
              <PrivateRoute
                key={StripeOAuthCallbackRoute}
                path={StripeOAuthCallbackRoute}
                component={StripeCompleteConnect}
                requiredPermissions={[]}
              />
              <PrivateRoute
                key={GoCardlessOAuthCallbackRoute}
                path={GoCardlessOAuthCallbackRoute}
                component={GoCardlessCompleteConnect}
                requiredPermissions={[]}
              />
              <Route path='/error/:code' component={ErrorPage} />
              <Redirect to={`/error/${HttpStatusCode.NotFound.toString()}`} />
            </Switch>
          </div>
        </div>
      </WebsiteViewProvider>
    </BrowserRouter>
  );
};

export default Layout;
