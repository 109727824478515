import { ReactElement, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import { RegisterPaymentFeeConfiguration } from "../../models/paymentsFees/paymentFeeConfiguration";
import { FeeCalculatorType } from "../../models/paymentsFees/feeCalculatorType";
import UkLatePaymentDialog, { FormModel } from "./UkLatePaymentDialog";
import { FeeGroupType } from "../../models/paymentsFees/feeGroupType";
import { addLatePaymentConfiguration } from "../../services/latePayments/latePayments.service";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "latePaymentConfigurationPage";

interface AddLatePaymentButtonProps {
  onAdd: () => Promise<void>;
}

interface MenuEntry {
  type: FeeCalculatorType;
  name: string;
}

/*
 * Items to appear in our drop down button
 */
const menu = [
  { type: FeeCalculatorType.Uk, name: "menuItemUk" },
  { type: FeeCalculatorType.Custom, name: "menuItemCustom" },
] as MenuEntry[];

/*
 * Display an add late payment configuration button which displays the add late payment dialog
 */
const AddLatePaymentButton = ({ onAdd }: AddLatePaymentButtonProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [show, setShow] = useState<boolean>(false);
  const disabled = !usePermission(UserPermission.CreateLatePayments);

  const handleOnSave = async (model: FormModel) => {
    setShow(false);
    const data: RegisterPaymentFeeConfiguration = {
      feeGroupType: FeeGroupType.LatePayment,
      type: model.type,
      name: model.name,
      description: model.description,
      enabled: model.enabled,
      gracePeriodInDays: model.gracePeriodInDays,
      recurringPeriodInDays: model.recurringPeriodInDays,
      percentage: model.percentage,
      amount: model.amount,
      applyCharges: model.applyCharges,
    };
    await addLatePaymentConfiguration(data);
    onAdd();
  };

  // Menu a settlement offer type is chosen from the menu
  const handleMenuItemSelected = (type: FeeCalculatorType) => {
    if (type === FeeCalculatorType.Uk) setShow(true);
  };

  return (
    <>
      <Dropdown onSelect={(eventKey) => handleMenuItemSelected(eventKey as FeeCalculatorType)} className='add-button'>
        <Dropdown.Toggle variant='primary' id='new-latepayment-button' disabled={disabled}>
          {t("buttonAddLatePayment")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <>
            {menu.map((entry) => (
              <Dropdown.Item key={entry.type} eventKey={entry.type}>
                {t(entry.name)}
              </Dropdown.Item>
            ))}
          </>
        </Dropdown.Menu>
      </Dropdown>
      <UkLatePaymentDialog show={show} mode='add' onClose={() => setShow(false)} onSave={(model) => handleOnSave(model)} />
    </>
  );
};

export default AddLatePaymentButton;
