export interface ProblemDetails {
  type: string | undefined;
  title: string | undefined;
  status?: number;
  detail: string | undefined;
  instance: string | undefined;
  extensions: Record<string, unknown>;
  errorCode: number;
}

/*
 * True if data is a ProblemDetails object (best guess)
 */
export function isProblemDetails(data: unknown): data is ProblemDetails {
  if (typeof data !== "object") return false;
  const record = data as Record<string, unknown>;
  return typeof data === "object" && "type" in record && "title" in record && "status" in record && "detail" in record;
}

/*
 * Returns data as ProblemDetails type, else undefined
 */
export function getProblemDetails(data: unknown): ProblemDetails | undefined {
  if (isProblemDetails(data)) {
    return data as ProblemDetails;
  }
  return undefined;
}
