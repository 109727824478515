/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Form, Row } from "react-bootstrap";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { CurrentPaymentProviderConfiguration } from "./opayoConfiguration";
import { tPaymentProviders } from "../../../../i18n";
import { VendorTxCodeType } from "../../../../models/paymentProviders/opayo/opayoModels";

const keyPrefix = "opayoForm";

interface Props {
  data?: CurrentPaymentProviderConfiguration;
  register: UseFormRegister<FieldValues>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OpayoOptionsTab = ({ data, register }: Props): ReactElement => {
  const { t } = useTranslation([tPaymentProviders.ns], { keyPrefix });

  const defaultCendorTxCodeType = data?.settings?.vendorTxCodeType ?? VendorTxCodeType.PaymentRequest;

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <Row>
              <Form.Group as={Col} md='4'>
                <Form.Label>{t("labelVendorCodeGeneration")}</Form.Label>
                <Col md='8'>
                  <Form.Select {...register("vendorTxCodeType")} defaultValue={defaultCendorTxCodeType}>
                    <option value={VendorTxCodeType.Guid}>{t("optionGuid")}</option>
                    <option value={VendorTxCodeType.PaymentRequest}>{t("optionPaymentReference")}</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

OpayoOptionsTab.defaultProps = {
  data: undefined,
};

export default OpayoOptionsTab;
