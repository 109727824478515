import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RouteButton } from "../../../components/route/RouteButtons";
import { TodoResult } from "../../../services/todos/todoResult";
import "./TodoItem.scss";
import usePermission from "../../../common/hooks/usePermission";
import InformationPanel, { getVarientStyle } from "../../common/InformationPanel";

interface TodoItemProps {
  todoResult: TodoResult
}

/*
 * Renders a single todo item
 */
const TodoItem = ({ todoResult }: TodoItemProps): ReactElement => {
  const { t } = useTranslation("forms");
  const showFixThis = usePermission(todoResult.requiredPermissionToFix);
  const varient = getVarientStyle(todoResult.severity); 

  return <InformationPanel severityLevel={todoResult.severity} title={todoResult.title} message={todoResult.message} >
    {showFixThis ?
      <div className="todo-content-right">
        <RouteButton
          variant={varient.style}
          size="sm"
          route={todoResult.url}
          className="link-btn"
        >
          {t("buttonFix")}
        </RouteButton>
      </div> : <></>}
  </InformationPanel>;
};

export default TodoItem;
