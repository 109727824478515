import { ReactElement, ReactNode } from "react";
import Loading from "./Loading";
import { LoadingError } from "./LoadingError";

interface LoadingOrErrorProps {
  isLoading: boolean;
  isError: boolean;
  loadingMessage?: string;
  errorMessage?: string;
  children: ReactNode;
}

export function DisplayLoadingOrError({
  isLoading,
  isError,
  loadingMessage,
  errorMessage,
  children,
}: LoadingOrErrorProps): ReactElement {
  if (isLoading) return <Loading message={loadingMessage} />;
  if (isError) return <LoadingError message={errorMessage} />;
  return <>{children}</>;
}

DisplayLoadingOrError.defaultProps = {
  loadingMessage: undefined,
  errorMessage: undefined,
};

export default DisplayLoadingOrError;
