import {
  PaymentProviderCredentials,
  PaymentProviderSettings,
  AddPaymentProviderTypeConfiguration,
  PaymentProviderTypeConfiguration,
  initAddPaymentProviderTypeConfiguration,
} from "../paymentProviderConfiguration";
import PaymentProviderModelConsts from "../paymentProviderModelConsts";

export const OpayoProviderName = "Opayo";
export const OpayoProviderDisplayName = "Opayo";

/*
 * Type of integration with Opayo
 */
export enum OpayoIntegrationType {
  Server = "Server",
  Pi = "Pi",
}

export enum VendorTxCodeType {
  Guid = "Guid",
  PaymentRequest = "PaymentRequest",
}

export interface OpayoSettings extends PaymentProviderSettings {
  // Type of integration we are configured for
  integrationType: OpayoIntegrationType;

  // TODO move to base class
  useTestServer: boolean;

  // The type of vendor txt code to generate
  vendorTxCodeType: VendorTxCodeType;
}

export interface OpayoCredentials extends PaymentProviderCredentials {
  // Opayo vendor (required for all integrations)
  vendorName: string;

  // Opayo user with admin rights (required for all integrations)
  adminUser: string;

  // Opayo user password (required for all integrations)
  adminPassword?: string;

  // Key required for Pi integration
  integrationKey?: string;

  // Password required for Pi integration
  integrationPassword?: string;
}

export class OpayoProviderModelConsts extends PaymentProviderModelConsts {
  public static readonly VendorMinLength: number = 1;

  public static readonly VendorMaxLength: number = 100;

  public static readonly UserMinLength: number = 1;

  public static readonly UserMaxLength: number = 255;

  public static readonly PasswordMinLength: number = 1;

  public static readonly PasswordMaxLength: number = 255;

  public static readonly IntegrationKeyMinLength: number = 1;

  public static readonly IntegrationKeyMaxLength: number = 255;

  public static readonly IntegrationPasswordMinLength: number = 1;

  public static readonly IntegrationPasswordMaxLength: number = 255;
}

export type OpayoPaymentProviderConfiguration = PaymentProviderTypeConfiguration<OpayoSettings, OpayoCredentials>;
export type AddOpayoPaymentProviderConfiguration = AddPaymentProviderTypeConfiguration<OpayoSettings, OpayoCredentials>;

/*
 * Constructor for AddOpayoPaymentProviderConfiguration
 */
export const initAddOpayoPaymentProviderConfiguration = (name: string): AddOpayoPaymentProviderConfiguration => {
  const data = {
    ...initAddPaymentProviderTypeConfiguration(OpayoProviderName, name),
    settings: { providerName: OpayoProviderName } as OpayoSettings,
    credentials: { providerName: OpayoProviderName } as OpayoCredentials,
  };
  return data;
};
