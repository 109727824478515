import { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { PaymentAllocation } from "../../../models/paymentAllocations/paymentAllocation";
import PaymentAllocationTable, { PaymentAllocationDisplayMode } from "./PaymentAllocationTable";

const keyPrefix = "paymentAllocations";

const PaymentAllocationsTab = ({
  paymentAllocations,
  currency,
  mode,
}: {
  paymentAllocations: PaymentAllocation[] | undefined;
  currency: string;
  mode: PaymentAllocationDisplayMode;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const elements =
    !paymentAllocations || paymentAllocations.length === 0 ? (
      <p>{t("noPaymentAllocationsMessage")}</p>
    ) : (
      <PaymentAllocationTable paymentAllocations={paymentAllocations} currency={currency} mode={mode} />
    );

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card>
            <Card.Body>{elements}</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='panel-column'> </Col>
      </Row>
    </>
  );
};
export default PaymentAllocationsTab;
