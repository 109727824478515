/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDatasetId } from "../../common/hooks/useDatasetId";
import { useAuth } from "../../context/auth/AuthContext";

const DatasetParam = "ptdataset";

/*
 * Monitors for the dataset query command and changes the dataset accordingly
 */
const DatasetQueryParameter = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const [currentDatasetId, setDatasetId] = useDatasetId();
  const { authService } = useAuth();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      // Check if we have the datset command on the url
      const params = new URLSearchParams(location.search);
      const datasetIdAsString = params.get(DatasetParam);

      if (datasetIdAsString) {
        const convertedDatasetId = parseInt(datasetIdAsString || "", 10);

        // Remove the dataset parameter
        params.delete(DatasetParam);
        history.replace({
          search: params.toString(),
        });

        // Only update it if it's different from what we currently have
        if (convertedDatasetId !== currentDatasetId) {
          setDatasetId(convertedDatasetId);
          window.location.reload();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, authService, currentDatasetId]);

  return <></>;
};

export default DatasetQueryParameter;
