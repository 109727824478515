import { ReactElement } from "react";
import { Badge } from "react-bootstrap";

interface TabTitleWithCountProps {
  title: string;
  count?: number;
}

/*
 * Tab title with optional count badge
 */
const TabTitleWithCount = ({ title, count }: TabTitleWithCountProps): ReactElement => (
  <>
    {title}
    &nbsp;
    {count !== undefined && <Badge className='tab-badge'>{count}</Badge>}
  </>
);

TabTitleWithCount.defaultProps = {
  count: undefined,
};

export default TabTitleWithCount;
