import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { PageTitle } from "../common/PageTitle";
import { tPages } from "../../i18n";
import { HtmlTitle } from "../common/HtmlTitle";
import TransactionsGrid, { GridName, TransactionsFilters, TransactionsSortInfo } from "./Grid/TransactionsGrid";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";
import {
  CustomerNameColumn,
  DueDateColumn,
  OptionsColumn,
  PaidStatusColumn,
  TransactionAmountColumn,
  TransactionDateColumn,
  TransactionReferenceColumn,
  TransactionTypeColumn,
} from "./Grid/GridColumns";
import { defaultDataGridPagination } from "../../components/grid/dataGridHelpers";

/*
 * Default standard column list
 */
const gridColumns: TypeColumn[] = [
  TransactionReferenceColumn,
  TransactionTypeColumn,
  TransactionDateColumn,
  CustomerNameColumn,
  TransactionAmountColumn,
  DueDateColumn,
  PaidStatusColumn,
  OptionsColumn,
];

/*
 * The transactions list
 */
export const TransactionsList = (): ReactElement => {
  const { t } = useTranslation([tPages.ns]);

  const gridData = useDataGridStorage(
    GridName,
    TransactionsFilters,
    TransactionsSortInfo,
    defaultDataGridPagination,
    gridColumns
  );

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleTransactions", tPages)} />
      <PageTitle title='pageTitleTransactions' />
      <section className='data-grid-page'>
        <TransactionsGrid columns={gridColumns} gridStorage={gridData} />
      </section>
    </>
  );
};

export default TransactionsList;
