/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { tForms } from "../../../../i18n";
import keyPrefix from "../../../../components/itemFiltering/keyPrefixConst";
import { ItemFilterFormModel } from "../../../../components/itemFiltering/ItemFilterFormModel";
import MinMaxPaymentAmountFilterControl from "../../../../components/itemFiltering/MinMaxPaymentAmountFilterAmount";
import ItemFiltersDialog from "../../../../components/itemFiltering/ItemFiltersDialog";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { getCurrenciesFilterConfiguration } from "../../../../utils/itemFilterUtils";
import { initPaymentAmountFilterConfiguration } from "../../../../models/itemFiltering/paymentAmountFilterConfiguration";
import { initCurrenciesFilterConfiguration } from "../../../../models/itemFiltering/currenciesFilterConfiguration";
import MultiCurrencySelectionFilterControl from "../../../../components/itemFiltering/MultiCurrencyFilterControl";
import { CurrencyInfo } from "../../../../utils/currencyCodeUtils";
import "./PaymentProviderFiltersDialog.scss";

/*
 * Creates the filter group
 */
const buildFilterGroup = (
  model: ItemFilterFormModel,
  currencies?: string[],
  currentGroup?: ItemFilterGroup
): ItemFilterGroup => {
  const group: ItemFilterGroup = currentGroup ? { ...currentGroup } : { filterConfigurations: [] };

  // Clear all the current configurations as we're going to rebuild them
  group.filterConfigurations = [];

  if (model.maximumPaymentValue || model.minimumPaymentValue) {
    group.filterConfigurations.push(initPaymentAmountFilterConfiguration(model.minimumPaymentValue, model.maximumPaymentValue));
  }

  if (currencies && currencies.length > 0) {
    group.filterConfigurations.push(initCurrenciesFilterConfiguration(currencies));
  }

  return group;
};

const getSchema = (): Yup.AnyObjectSchema =>
  Yup.object({
    minimumPaymentValue: Yup.number()
      .label("Minimum Payment Amount")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0),
    maximumPaymentValue: Yup.number()
      .label("Maximum Payment Amount")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0)
      .moreThan(Yup.ref("minimumPaymentValue")),
  }).required();

interface WrappedPaymentProviderFiltersDialogProps {
  filterGroup: ItemFilterGroup | undefined;
  show: boolean;
  currencyOptions?: CurrencyInfo[];
  onUpdate: (filterGroup: ItemFilterGroup) => void;
  onClose: () => void;
  onShow?: () => void;
  onExited?: () => void;
}

/*
 * Display standard items filter dialog
 */
const WrappedPaymentProviderFiltersDialog = ({
  filterGroup,
  show,
  onUpdate,
  onClose,
  onShow,
  onExited,
  currencyOptions,
}: WrappedPaymentProviderFiltersDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const filterConfiguration = getCurrenciesFilterConfiguration(filterGroup?.filterConfigurations);
  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>(filterConfiguration?.currencies ?? []);

  const { register, handleSubmit, reset, formState } = useForm<ItemFilterFormModel>({
    resolver: yupResolver(getSchema()),
  });

  const handleClose = () => {
    // Reset to whatever it currently was
    setSelectedCurrencies(filterConfiguration?.currencies ?? []);
    onClose();
  };

  // Called when Update button pressed
  const handleUpdate = (model: ItemFilterFormModel) => {
    const newFilterGroup = buildFilterGroup(model, selectedCurrencies, filterGroup);
    onUpdate(newFilterGroup);
  };

  const formId = "providerFilterForm";

  return (
    <>
      <ItemFiltersDialog
        title={t("dialogTitle")}
        formId={formId}
        onClose={handleClose}
        onExited={onExited}
        onShow={onShow}
        show={show}
      >
        <Form
          id={formId}
          noValidate
          onSubmit={handleSubmit((submitData) => handleUpdate(submitData))}
          className='filter-dialog dialog-form'
        >
          <Row className='dialog-row g-0 mt-3'>
            <MinMaxPaymentAmountFilterControl register={register} formState={formState} filterGroup={filterGroup} />
          </Row>
          <Row className='dialog-row g-0'>
            <MultiCurrencySelectionFilterControl
              label={t("labelAllowedCurrencies")}
              message={t("descriptionAllowedCurrencies")}
              register={register}
              formState={formState}
              filterGroup={filterGroup}
              onSelectionChange={(currencies) => setSelectedCurrencies(currencies)}
              currencyOptions={currencyOptions}
            />
          </Row>
        </Form>
      </ItemFiltersDialog>
    </>
  );
};

WrappedPaymentProviderFiltersDialog.defaultProps = {
  currencyOptions: undefined,
  onExited: undefined,
  onShow: undefined,
};

interface PaymentProviderFiltersDialogProps {
  filterGroup: ItemFilterGroup | undefined;
  show: boolean;
  currencyOptions?: CurrencyInfo[];
  onUpdate: (filterGroup: ItemFilterGroup) => void;
  onClose: () => void;
}

/*
 * Display standard items filter dialog
 */
const PaymentProviderFiltersDialog = ({
  filterGroup,
  show,
  onUpdate,
  onClose,
  currencyOptions,
}: PaymentProviderFiltersDialogProps): ReactElement => {
  const [allowRender, setAllowRender] = useState<boolean>(true);

  const handleExited = () => {
    setAllowRender(false);
  };

  const handleShow = () => {
    setAllowRender(true);
  };

  // This may look odd but the reason why is that I was having a lot of trouble trying to reset the
  // form when cancelled was pressed and you when back in to the form.
  // This below code completely removes the dialog from the DOM completely, therefore resetting all
  // variables when it's recreated. A bit of a mess but I've spent too much time trying to correct
  // it and this little hack works for now

  return (
    <>
      {(show || allowRender) && (
        <WrappedPaymentProviderFiltersDialog
          show={show}
          filterGroup={filterGroup}
          onUpdate={onUpdate}
          onClose={onClose}
          onShow={handleShow}
          onExited={handleExited}
          currencyOptions={currencyOptions}
        />
      )}
    </>
  );
};

PaymentProviderFiltersDialog.defaultProps = {
  currencyOptions: undefined,
};

export default PaymentProviderFiltersDialog;
