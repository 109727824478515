import CurrenciesFilterConfiguration from "../models/itemFiltering/currenciesFilterConfiguration";
import ItemFilterConfiguration from "../models/itemFiltering/itemFilterConfiguration";
import ItemFilteringType from "../models/itemFiltering/itemFilteringType";
import PaymentAmountFilterConfiguration from "../models/itemFiltering/paymentAmountFilterConfiguration";
import TransactionAmountFilterConfiguration from "../models/itemFiltering/transactionAmountFilterConfiguration";

/*
 * Attempts to find a TransactionAmountFilterConfiguration in the passed configurations array
 */
export const getTransactionAmountFilterConfiguration = (
  filterConfigurations: ItemFilterConfiguration[] | undefined
): TransactionAmountFilterConfiguration | undefined => {
  const result = filterConfigurations?.find((x) => x.filterType === ItemFilteringType.TransactionAmount);
  return result ? (result as TransactionAmountFilterConfiguration) : undefined;
};

/*
 * Attempts to find a PaymentAmountFilterConfiguration in the passed configurations array
 */
export const getPaymentAmountFilterConfiguration = (
  filterConfigurations: ItemFilterConfiguration[] | undefined
): PaymentAmountFilterConfiguration | undefined => {
  const result = filterConfigurations?.find((x) => x.filterType === ItemFilteringType.PaymentAmount);
  return result ? (result as PaymentAmountFilterConfiguration) : undefined;
};

/*
 * Attempts to find a CurrenciesFilterConfiguration in the passed configurations array
 */
export const getCurrenciesFilterConfiguration = (
  filterConfigurations: ItemFilterConfiguration[] | undefined
): CurrenciesFilterConfiguration | undefined => {
  const result = filterConfigurations?.find((x) => x.filterType === ItemFilteringType.Currencies);
  return result ? (result as CurrenciesFilterConfiguration) : undefined;
};
