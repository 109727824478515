import { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import { Document } from "../../models/documents/document";
import DocumentTable from "./DocumentTable";

import UploadDocument from "./UploadDocumentButton";

const keyPrefix = "documentsTab";

interface DocumentsTabProps {
  documents: Document[] | undefined;
  onUpload: (file: File) => Promise<void>;
  onDeleteDocument: (documentId: string) => Promise<void>;
  onGetDocumentData: (documentId: string) => Promise<File | undefined>;
}

const DocumentsTab = ({ documents, onUpload, onDeleteDocument, onGetDocumentData }: DocumentsTabProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card>
            <Card.Body>
              {documents && documents.length > 0 ? (
                <DocumentTable
                  documents={documents}
                  onDeleteDocument={onDeleteDocument}
                  onGetDocumentData={onGetDocumentData}
                />
              ) : (
                t("noDocumentsFound")
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UploadDocument onUpload={onUpload} />
    </>
  );
};

export default DocumentsTab;
