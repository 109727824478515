import { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PaymentProvider } from "../../../models/paymentProviders/paymentProvider";
import { SettingsRouteConsts } from "../../SettingsRouteConsts";
import PaymentProviderSelectionDialog from "./PaymentProviderSelectionDialog";

/*
 * Display an add provider button which display the add provider dialog
 */
const AddProviderButton = (): ReactElement => {
  const { t } = useTranslation(["paymentProviders"]);
  const [show, setShow] = useState<boolean>(false);
  const history = useHistory();

  const handleSelected = (provider: PaymentProvider) => {
    setShow(false);
    history.replace(`${SettingsRouteConsts.PaymentProvidersNew}/${provider.providerName}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Button variant='primary' onClick={() => setShow(true)}>
        {t("paymentProviders.buttonAddProvider")}
      </Button>
      <PaymentProviderSelectionDialog show={show} onHide={handleClose} onSelected={handleSelected} />
    </>
  );
};

export default AddProviderButton;
