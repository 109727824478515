import { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { Account } from "../../models/accounts/account";
import AccountDetails from "./AccountDetails";
import { EmailAddressesPanel } from "./EmailAddressesPanel";

const AccountInfoTab = ({ account }: { account: Account }): ReactElement => (
  <>
    <Row>
      <Col className='panel-column'>
        <AccountDetails account={account} />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <EmailAddressesPanel emailContacts={account.emailAddresses} />
      </Col>
    </Row>
  </>
);

export default AccountInfoTab;
