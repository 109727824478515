/* eslint-disable @typescript-eslint/no-unused-vars */
import { paymentProviderConfigurationIncludeAll } from "../../common/api/payThemApi/paymentProviderConfigurationIncludeOptions";
import { StripeWebApi } from "../../common/api/payThemApi/stripeWebApi";
import PayThemMessageFactory from "../../components/websiteComms/PayThemMessageFactory";
import {
  initAddStripePaymentProviderConfiguration,
  StripeAccountInformation,
  StripeCredentials,
  StripeProviderName,
} from "../../models/paymentProviders/stripe/stripeModels";
import { StripePublicKeys } from "../../models/paymentProviders/stripe/stripePublicKeys";
import { sendMessageToParent } from "../../utils/httpUtilities";
import { UrlUtils } from "../../utils/urlUtils";
import {
  addConfiguration,
  getConfiguration,
  updateConfiguration,
  duplicateExists,
} from "../paymentProviders/paymentProviders.service";

/*
 * The Stripe connect redirect URL
 */
const StripeConnectUrl = "https://connect.stripe.com/oauth/authorize";

/*
 * Our callback URL we pass to the StripeConnectUrl
 */
export const StripeOAuthCallbackRoute = "/stripe-connect/connect/oauth";

/*
 * Type of connect we're performing
 */
export enum StripeConnectType {
  Add,
  Edit,
}

/*
 * Service for working with the Stipe PayThem api
 */
export class StripeConnectService {
  private api = new StripeWebApi();

  protected static createBaseStripeConnectionUrl(connectClientId: string, state: string, redirectUrl: string): string {
    const params = new URLSearchParams();
    params.append("client_id", connectClientId);
    params.append("state", state);
    params.append("scope", "read_write");
    params.append("response_type", "code");
    params.append("redirect_uri", redirectUrl);
    return `${StripeConnectUrl}?${params.toString()}`;
  }

  /*
   * Creates the URL to start the Stripe connection processes
   */
  protected static async createStripeConnectionUrl(url: string, type: StripeConnectType, state?: string): Promise<string> {
    const keys = await StripeConnectService.getPublicKeys();

    const redirectUrl = url.replace(/\/?$/, "") + StripeOAuthCallbackRoute;
    const combinedState = `${state ?? ""}:${type}:${keys.isTestMode ? "test" : "live"}`;

    return StripeConnectService.createBaseStripeConnectionUrl(keys.connectClientId, combinedState, redirectUrl);
  }

  /*
   * Starts the Stripe connection process by redirecting to Stripe Connect
   */
  public static async startLocalStripeConnection(type: StripeConnectType, configurationId?: string): Promise<void> {
    const redirectUrl = await StripeConnectService.createStripeConnectionUrl(
      UrlUtils.getAbsoluteDomainUrl(),
      type,
      configurationId
    );
    window.location.assign(redirectUrl);
  }

  public static async startParentStripeConnection(
    parentUrl: string,
    type: StripeConnectType,
    configurationId?: string
  ): Promise<void> {
    const redirectUrl = await StripeConnectService.createStripeConnectionUrl(parentUrl, type, configurationId);
    const message = PayThemMessageFactory.createStripeRedirectMessage(redirectUrl, parentUrl);
    sendMessageToParent(message, parentUrl);
  }

  /*
   * Completes the Stripe connection process by creating a new Payment Provider or updating a current one
   */
  public async completeStripeConnection(state: string, code: string): Promise<string> {
    const accountInformation = await this.api.connectToClientAccount(code);
    const stateItems = state.split(":");
    if (stateItems.length < 2) throw new Error("State should contain 2 items");

    const keys = await StripeConnectService.getPublicKeys();

    const isTest = stateItems[2].toLowerCase() === "test";

    // Test mode returned from Stripe must match the test mode in the keys returned by the PayThem API
    if (isTest !== keys.isTestMode) throw new Error("Test mode does not match");

    let id: string;
    const connectType: StripeConnectType = parseInt(stateItems[1], 10);
    if (connectType === StripeConnectType.Add) {
      id = await this.createConfigurationForStripeAccount(accountInformation, keys.connectClientId, isTest);
    } else {
      // eslint-disable-next-line prefer-destructuring
      id = stateItems[0];
      await StripeConnectService.updateConfigurationForStripeAccount(id, accountInformation.id, keys.connectClientId);
    }
    return id;
  }

  /*
   * Creates a new Payment Provider configuration from the Stripe Account number
   */
  // eslint-disable-next-line class-methods-use-this
  private async createConfigurationForStripeAccount(
    accountInformation: StripeAccountInformation,
    connectClientId: string,
    useTest: boolean
  ): Promise<string> {
    if (!accountInformation) throw new Error("Account information not found");

    const accountName = accountInformation.displayName?.trim();
    const name = await StripeConnectService.buildUniqueAccountName(accountName);

    const configuration = initAddStripePaymentProviderConfiguration(name);
    configuration.enabled = true;
    configuration.settings.storePayerPaymentMethod = true;
    configuration.settings.useStripeCustomer = true;
    configuration.settings.saveCardOptionDefault = true;
    configuration.credentials.stripeAccountId = accountInformation.id;
    configuration.credentials.connectClientId = connectClientId;
    configuration.isTest = useTest;

    const result = await addConfiguration(configuration);
    if (!result) throw new Error("Unable to add Stripe configuration");
    return result.id;
  }

  /*
   * Updates an existing Payment Provider configuration with the Stripe Account number
   */
  private static async updateConfigurationForStripeAccount(
    paymentProviderId: string,
    stripeAccountId?: string,
    connectClientId?: string
  ): Promise<void> {
    const configuration = await getConfiguration(paymentProviderId, paymentProviderConfigurationIncludeAll);

    if (!configuration || configuration.providerName !== StripeProviderName) throw new Error("Invalid payment provider type");

    configuration.credentials = {
      stripeAccountId,
      connectClientId,
    } as StripeCredentials;

    await updateConfiguration(configuration);
  }

  /*
   * Creates a unique name for the provider
   */
  private static async buildUniqueAccountName(accountName: string): Promise<string> {
    const checkAccountName = accountName ?? StripeProviderName;

    let name = checkAccountName;
    let count = 0;

    // eslint-disable-next-line no-await-in-loop
    while (await duplicateExists(name)) {
      // eslint-disable-next-line no-plusplus
      name = `${checkAccountName} ${++count}`;
    }
    return name;
  }

  /*
   * Disconnects the provider from the Stripe account
   */
  public async disconnectStripeAccount(paymentProviderId: string): Promise<void> {
    await this.api.disconnectFromClientAccount(paymentProviderId);
    await StripeConnectService.updateConfigurationForStripeAccount(paymentProviderId);
  }

  /*
   * Gets the public keys for the Stripe account
   */
  public static async getPublicKeys(): Promise<StripePublicKeys> {
    const api = new StripeWebApi();
    return api.getPublicKeys();
  }
}
