import { PaymentRequestUsageType } from "../paymentRequests/paymentRequestUsageType";

class PaymentRequestSettingsConsts {
  public static readonly ValidUntilMin: number = 0;

  public static readonly ValidUntilMaxDayMax: number = 500;

  public static readonly ValidUntilMaxWeekMax: number = 104;

  public static readonly ValidUntilMaxMonthMax: number = 24;

  public static readonly ValidUntilMaxYearMax: number = 5;

  public static readonly DefaultUsageType: PaymentRequestUsageType.Single;
}

export default PaymentRequestSettingsConsts;
