import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { tCommon } from "../../../i18n";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getDashboardUrl } from "../../../services/svix/svix.service";
import PageLoading from "../../../components/loading/PageLoading";
import "./SvixPortalPage.scss";

const keyPrefix = "svixPortal";

/*
 * Embeds the Svix dashboard in an iframe
 */
const SvixPortalPage = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });

  // Get access to the current tenants portal url
  const { data: dashboardAccess, isLoading } = useQuery(QueryNameConsts.SvixDashboardAccess, () => getDashboardUrl(), {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Container>
        <PageLoading />
      </Container>
    );

  return (
    <div className='svix-container'>
      <iframe
        className='svix-frame'
        title={t("iframeTitle")}
        src={dashboardAccess?.url}
        allow='clipboard-write'
        loading='lazy'
      />
    </div>
  );
};

export default SvixPortalPage;
