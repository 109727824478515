export enum TimeUnitType {
  Second = "Second",
  Minute = "Minute",
  Hour = "Hour",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Year = "Year",
}

export const TimeUnitTypeMap = new Map<TimeUnitType, string>([
  [TimeUnitType.Second, "Second"],
  [TimeUnitType.Minute, "Minute"],
  [TimeUnitType.Hour, "Hour"],
  [TimeUnitType.Day, "Day"],
  [TimeUnitType.Week, "Week"],
  [TimeUnitType.Month, "Month"],
  [TimeUnitType.Year, "Year"],
]);

export const TimeUnitTypeArray = Array.from(TimeUnitTypeMap.entries());
