export enum PaymentRequestUsageType {
  Single = "Single",
  Multiple = "Multiple",
}

export const PaymentRequestUsageTypeMap = new Map<PaymentRequestUsageType, string>([
  [PaymentRequestUsageType.Single, "Single"],
  [PaymentRequestUsageType.Multiple, "Multiple"],
]);

export const PaymentRequestUsageTypeArray = Array.from(PaymentRequestUsageTypeMap.entries());
