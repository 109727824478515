import { ReactElement } from "react";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IconButton } from "./IconButton";
import { toastSuccess } from "../../services/notifications/toastNotification.service";
import { tForms } from "../../i18n";

/*
 * Displays clipboard button which copies the given text to the clipboard
 */
export const CopyToClipboard = ({ text }: { text: string }): ReactElement => {
  const { t } = useTranslation([tForms.ns]);
  return (
    <ReactCopyToClipboard text={text} onCopy={() => toastSuccess(t("messageCopiedToClipboard"))}>
      <IconButton title={t("titleCopyToClipboard")} icon={faCopy} />
    </ReactCopyToClipboard>
  );
};
export default CopyToClipboard;
