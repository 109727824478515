import { ReactElement, useCallback } from "react";
import { TypeColumn, TypeDataSource, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { TransactionsApi } from "../../../common/api/payThemApi/transactionsApi";
import { Transaction } from "../../../models/transactions/transaction";
import { DataGrid } from "../../../components/grid/DataGrid";
import {
  SortDesending,
  LoadDataProps,
  loadPageDataWithOptions,
  LoadDataResult,
} from "../../../components/grid/dataGridHelpers";
import GridNamesConsts from "../../GridNamesConsts";
import { TransactionIncludeOptions } from "../../../common/api/payThemApi/transactionIncludeOptions";
import { DataGridStorage } from "../../../common/hooks/useDataGridStorage";

export const TransactionsFilters: TypeSingleFilterValue[] = [
  { name: "transactionReference", operator: "contains", type: "string", value: "" },
  { name: "transactionType", operator: "eq", type: "select", value: "" },
  { name: "account.name", type: "string", operator: "contains", value: "" },
  { name: "transactionAmount", operator: "gte", type: "number", value: "" },
  { name: "paidStatus", operator: "eq", type: "select", value: "" },
];

/*
 * Default sort column
 */
export const TransactionsSortColumnName = "transactionDate";
export const TransactionsSortDirection = SortDesending;

export const TransactionsSortInfo = { name: TransactionsSortColumnName, dir: TransactionsSortDirection } as TypeSingleSortInfo;

/*
 * Callback function for loading in a page of data
 */
const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps): Promise<LoadDataResult<Transaction>> =>
  loadPageDataWithOptions(
    skip,
    limit,
    sortInfo,
    TransactionsSortColumnName,
    TransactionsSortDirection,
    filterValue,
    async (pageParameters) => {
      const api = new TransactionsApi();
      const result = await api.getTransactions(
        pageParameters.page,
        pageParameters.itemsPerPage,
        pageParameters.sort,
        pageParameters.filter,
        { account: true } as TransactionIncludeOptions
      );
      return result;
    }
  );

interface TransactionsGridProps {
  columns: TypeColumn[];
  dataSource?: TypeDataSource;
  gridStorage?: DataGridStorage;
  style?: {
    [key: string]: string | number;
  };
}

/*
 * The name of our grid
 */
export const GridName = GridNamesConsts.TransactionGrid;

/*
 * The transactions grid, used by various list components
 */
const TransactionsGrid = ({ columns, dataSource, gridStorage, style }: TransactionsGridProps): ReactElement => {
  const defaultDataSource = useCallback(loadData, []);

  // Grid state is optional
  const gridState = gridStorage?.dataGridState;
  const haveGrid = gridState !== undefined;

  return (
    <DataGrid<Transaction>
      idProperty='id'
      columns={columns}
      dataSource={dataSource ?? defaultDataSource}
      style={style}
      defaultFilterValue={haveGrid ? gridState?.filters : TransactionsFilters}
      enableFiltering
      onFilterValueChange={(filterValue) => (haveGrid ? gridStorage?.setFilters(filterValue) : true)}
      defaultSortInfo={gridState?.sortInfo}
      onSortInfoChange={(sortInfo) => (haveGrid ? gridStorage?.setSortInfo(sortInfo) : true)}
      defaultSkip={gridState?.pagination?.skip}
      onSkipChange={(skip) => (haveGrid ? gridStorage?.setPaginationSkip(skip) : true)}
      defaultLimit={gridState?.pagination?.limit}
      onLimitChange={(limit) => (haveGrid ? gridStorage?.setPaginationLimit(limit) : true)}
    />
  );
};

TransactionsGrid.defaultProps = {
  dataSource: undefined,
  gridStorage: undefined,
  style: undefined,
};
export default TransactionsGrid;
