import { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { tPages } from "../../i18n";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "documentsTab";

interface UploadDocumentButtonProps {
  onUpload: (file: File) => Promise<void>;
}

const UploadDocumentButton = ({ onUpload }: UploadDocumentButtonProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const [uploading, setUploading] = useState<boolean>(false);
  const [fileSelection, setFileSelection] = useState<string>("");
  const fileUpload = useRef<HTMLInputElement>(null);
  const disabled = !usePermission(UserPermission.UploadTransactionDocument);

  // On file select (from the pop up)
  const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    const file = event.currentTarget.files?.item(0);
    if (file) {
      await onUpload(file);
    }
    setFileSelection("");
    setUploading(false);
  };

  return (
    <>
      <SpinnerButton
        title={t("buttonUploadDocument")}
        disabled={disabled}
        showSpinner={uploading}
        onClick={() => {
          fileUpload?.current?.click();
        }}
      />
      <input type='file' ref={fileUpload} onChange={handleUploadFile} value={fileSelection} style={{ display: "none" }} />
    </>
  );
};

export default UploadDocumentButton;
