import { ReactElement } from "react";
import { ButtonToolbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../../../components/buttons/SpinnerButton";
import { RouteButton } from "../../../../components/route/RouteButtons";
import { tForms } from "../../../../i18n";
import { SettingsRouteConsts } from "../../../SettingsRouteConsts";

interface ProviderButtonToolbarProps {
  saving: boolean;
  formId?: string;
}

/*
 * Consistant button toolbar for all providers
 */
const ProviderButtonToolbar = ({ saving, formId }: ProviderButtonToolbarProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  return (
    <ButtonToolbar>
      <SpinnerButton form={formId} type='submit' title={t("buttonSaveConfig")} showSpinner={saving} />
      <RouteButton className='mx-2' variant='secondary' route={SettingsRouteConsts.PaymentProviders}>
        {t("buttonCancel")}
      </RouteButton>
    </ButtonToolbar>
  );
};

ProviderButtonToolbar.defaultProps = {
  formId: undefined,
};

export default ProviderButtonToolbar;
