import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tCommon } from "../../i18n";
import { BasicTag } from "./BasicTag";
import "./DefaultTag.scss";

/*
 * Generates default tag
 */
const DefaultTag = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns);
  return <BasicTag tagName='default-tag' tagText={t("commonTags.isDefaultTag")} tagCssEnd='' />;
};

export default DefaultTag;
