/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { tForms } from "../../../i18n";
import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../../components/dialogs/AddOrEditCommonDialog";
import { NotificationUserType, NotificationUserTypeArray } from "../../../models/notifications/notificationUserType";
import { NotificationUser } from "../../../models/notifications/notificationUser";
import { User } from "../../../models/users/user";
import NotificationModelConsts from "../../../models/notifications/notificationModelConsts";

const keyPrefix = "notificationUserForm";

export type FormModel = {
  type: NotificationUserType;
  userId?: string;
  emailAddress?: string;
};

interface NewUserDialogProps extends AddOrEditDialogProps<NotificationUser, FormModel> {
  users: User[];
}
const getSchema = (type: NotificationUserType): Yup.AnyObjectSchema =>
  Yup.object({
    type: Yup.string().label("Type").required(),
    userId: Yup.string().label("UserId").notRequired(),
    emailAddress: Yup.lazy(() =>
      type === NotificationUserType.EmailAddress
        ? Yup.string().label("Email address").required().email().max(NotificationModelConsts.EmailMaxLength)
        : Yup.string().notRequired()
    ),
  }).required();

/*
 * Displays the new notification user dialog
 */
const NewUserDialog = ({ users, show, mode, onSave, onClose }: NewUserDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const [selectedType, setSelectedType] = useState(
    users.length > 0 ? NotificationUserType.PayThemUser : NotificationUserType.EmailAddress
  );
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormModel>({ resolver: yupResolver(getSchema(selectedType)) });

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    const subscription = watch((value) => {
      if (value.type && value.type !== selectedType) {
        setSelectedType(value.type);
      }
    });
    return () => subscription.unsubscribe();
  }, [selectedType, watch]);

  const handleSave = (modelData: FormModel) => {
    const model = { ...modelData };

    if (model.type === NotificationUserType.PayThemUser) {
      model.emailAddress = undefined;
    } else {
      model.emailAddress = modelData.emailAddress?.trim();
      model.userId = undefined;
    }
    reset();
    onSave(model);
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <AddOrEditModelDialog
      mode={mode}
      title={t("titleAddNotificationUser")}
      show={show}
      size={undefined}
      onHide={handleClose}
      formId='notificationUserForm'
    >
      <Form
        id='notificationUserForm'
        className='dialog-form'
        noValidate
        onSubmit={handleSubmit((submitData) => handleSave(submitData))}
      >
        <Row className='dialog-row last'>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("labelType")}</Form.Label>
              <Form.Select {...register("type")}>
                {NotificationUserTypeArray.map(([key, value]) =>
                  users.length > 0 || (users.length === 0 && key === NotificationUserType.EmailAddress) ? (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ) : (
                    <></>
                  )
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          {users.length > 0 && (
            <Col md={12}>
              <Form.Group>
                <Form.Label>{t("labelPayThemUser")}</Form.Label>
                <Form.Select
                  //   defaultValue={users[0].id}
                  disabled={selectedType !== NotificationUserType.PayThemUser}
                  {...register("userId")}
                >
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.displayName} ({user.emailAddress})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          <Col md={12}>
            <Form.Group>
              <Form.Label>{t("labelEmailAddress")}</Form.Label>
              <Form.Control
                disabled={selectedType !== NotificationUserType.EmailAddress}
                type='text'
                placeholder={t("placeholderEmailAddress")}
                {...register("emailAddress")}
                className={`form-control ${errors.emailAddress ? "is-invalid" : ""}`}
              />
              <Form.Control.Feedback type='invalid'>{errors?.emailAddress?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </AddOrEditModelDialog>
  );
};

export default NewUserDialog;
