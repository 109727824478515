import {
  PaymentProviderCredentials,
  PaymentProviderSettings,
  AddPaymentProviderTypeConfiguration,
  PaymentProviderTypeConfiguration,
  initAddPaymentProviderTypeConfiguration,
} from "../paymentProviderConfiguration";
import PaymentProviderModelConsts from "../paymentProviderModelConsts";

export const TotalProcessingProviderName = "TotalProcessing";
export const TotalProcessingProviderDisplayName = "Total Processing";

const AmexBrand = "AMEX";
const VisaBrand = "VISA";
const VisaDebitBrand = "VISADEBIT";
const VisaElectronBrand = "VISAELECTRON";
const MasterBrand = "MASTER";
const MasterDebitBrand = "MASTERDEBIT";
const MaestroBrand = "MAESTRO";
const DinersBrand = "DINERS";
const DiscoverBrand = "DISCOVER";
const JcbBrand = "JCB";
const UnionPayBrand = "UNIONPAY";

export interface TotalProcessingPaymentBrand {
  // The key is the value that identifies the brand in the API
  key: string;

  // The translation id of the value that is displayed to the user
  name: string;

  // True if this is the primary brand for the provider
  primary: boolean;

  // Display order priority
  priority: number;

  // True if the brand is enabled by default
  defaultEnabled: boolean;
}

export const AvailableCardBrandsMap: TotalProcessingPaymentBrand[] = [
  { key: AmexBrand, name: "brandAmex", primary: false, priority: 0, defaultEnabled: false },
  { key: VisaBrand, name: "brandVisa", primary: true, priority: 0, defaultEnabled: true },
  { key: VisaDebitBrand, name: "brandVisaDebit", primary: true, priority: 0, defaultEnabled: true },
  { key: VisaElectronBrand, name: "brandVisaElectron", primary: false, priority: 0, defaultEnabled: false },
  { key: MasterBrand, name: "brandMastercard", primary: true, priority: 0, defaultEnabled: true },
  { key: MasterDebitBrand, name: "brandMastercardDebit", primary: true, priority: 0, defaultEnabled: true },
  { key: MaestroBrand, name: "brandMaestro", primary: false, priority: 0, defaultEnabled: false },
  { key: DinersBrand, name: "brandDiners", primary: false, priority: 0, defaultEnabled: false },
  { key: DiscoverBrand, name: "brandDiscover", primary: false, priority: 0, defaultEnabled: false },
  { key: JcbBrand, name: "brandJCB", primary: false, priority: 0, defaultEnabled: false },
  { key: UnionPayBrand, name: "brandUnionPay", primary: false, priority: 0, defaultEnabled: false },
];

export interface TotalProcessingSettings extends PaymentProviderSettings {
  brands: string[];

  // Stores the payment method (cards) when using the payer website
  storePayerPaymentMethod: boolean;

  // True that save card details is on by default
  saveCardOptionDefault: boolean;
}

export interface TotalProcessingCredentials extends PaymentProviderCredentials {
  entityId: string;
  accessToken?: string;
  useSandbox: boolean;
}

export class TotalProcessingProviderModelConsts extends PaymentProviderModelConsts {
  public static readonly EntityIdMinLength: number = 1;

  public static readonly EntityIdMaxLength: number = 100;

  public static readonly AccessTokenMinLength: number = 1;

  public static readonly AccessTokenMaxLength: number = 100;
}

export type TotalProcessingPaymentProviderConfiguration = PaymentProviderTypeConfiguration<
  TotalProcessingSettings,
  TotalProcessingCredentials
>;
export type AddTotalProcessingPaymentProviderConfiguration = AddPaymentProviderTypeConfiguration<
  TotalProcessingSettings,
  TotalProcessingCredentials
>;

/*
 * Constructor for AddTotalProcessingPaymentProviderConfiguration
 */
export const initAddTotalProcessingPaymentProviderConfiguration = (
  name: string
): AddTotalProcessingPaymentProviderConfiguration => {
  const data = {
    ...initAddPaymentProviderTypeConfiguration(TotalProcessingProviderName, name),
    settings: { providerName: TotalProcessingProviderName } as TotalProcessingSettings,
    credentials: { providerName: TotalProcessingProviderName } as TotalProcessingCredentials,
  };
  return data;
};
