/* eslint-disable no-plusplus */
import { TypeFilterValue, TypeSingleFilterValue } from "@inovua/reactdatagrid-community/types";

const stringFilterOperators: { [key: string]: string } = {
  contains: "ct",
  notContains: "nct",
  eq: "eq",
  neq: "neq",
  empty: "",
  notEmpty: "",
  startsWith: "sw",
  endsWith: "ew",
};

const numberFilterOperators: { [key: string]: string } = {
  eq: "eq",
  neq: "neq",
  gt: "gt",
  gte: "ge",
  lt: "lt",
  lte: "le",
  inrange: "",
  notinrange: "",
};

const booleanFilterOperators: { [key: string]: string } = {
  eq: "eq",
  neq: "neq",
  inlist: "",
  notinlist: "",
};

const selectFilterOperators: { [key: string]: string } = {
  eq: "eq",
  neq: "neq",
};

const dateFilterOperators: { [key: string]: string } = {
  eq: "eq",
  neq: "neq",
  after: "gt",
  afterOrOn: "ge",
  before: "lt",
  beforeOrOn: "le",
  inrange: "",
  notinrange: "",
};

const filterOperatorNames: { [key: string]: string } = {
  eq: "equals",
  neq: "does not equal",
  gt: "greater than",
  gte: "greater than or equal to",
  after: "after",
  afterOrOn: "after or on",
  before: "before",
  beforeOrOn: "before or on",
  inrange: "in range",
  notinrange: "no in range",
  contains: "contains",
  notContains: "does not contain",
  empty: "is empty",
  notEmpty: "is not empty",
  startsWith: "starts with",
  endsWith: "ends with",
  inlist: "is in list",
  notinlist: "is not in list",
};

export const filterTypes = new Map([
  ["string", stringFilterOperators],
  ["number", numberFilterOperators],
  ["boolean", booleanFilterOperators],
  ["select", selectFilterOperators],
  ["date", dateFilterOperators],
]);

export function processSingleFilter(filter: TypeSingleFilterValue | undefined): string | undefined {
  if (!filter || !filter.value || filter.value === "") return undefined;

  const filterOperators = filterTypes.get(filter.type);
  if (!filterOperators) return undefined;

  const operator = filterOperators[filter.operator];
  if (!operator) return undefined;

  return `${filter.name} ${operator} ${filter.value}`;
}

/*
 * Creates the Draycir api compatible filter
 */
export const createFilterString = (filterInfo: TypeFilterValue): string => {
  let filterString = "";

  if (!filterInfo) return filterString;

  for (let index = 0; index < filterInfo.length; ++index) {
    const filterResult = processSingleFilter(filterInfo[index]);
    if (filterResult) {
      if (filterString) {
        filterString += ",";
      }
      filterString += filterResult;
    }
  }
  return filterString;
};

/*
 * True if there are any active filters
 */
export const hasActiveFilters = (filters?: TypeFilterValue): boolean =>
  !!(filters && filters.some((x) => x.operator === "empty" || x.operator === "notEmpty" || x.value));

/*
 * Converts a filter to a text description
 */
export const filterToTextDescription = (
  filterInfo: TypeSingleFilterValue,
  nameOverride?: string | undefined
): string | undefined => {
  if (!filterInfo) return undefined;

  const operater = filterOperatorNames[filterInfo.operator];

  if (filterInfo.operator === "empty" || filterInfo.operator === "notEmpty" || !filterInfo.value) {
    return undefined;
  }

  return filterInfo.type === "number"
    ? `${nameOverride ?? filterInfo.name} ${operater} ${filterInfo.value}`
    : `${nameOverride ?? filterInfo.name} ${operater} '${filterInfo.value}'`;
};

export default createFilterString;
