/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LocalStorageProvider } from "../../common/storage/localStorageProvider";
import { DefaultWebsiteView, WebsiteView } from "../../components/websiteComms/WebsiteView";
import { isRunningInIframe } from "../../utils/httpUtilities";
import { WebsiteViewContext } from "./WebsiteViewContext";

interface ProviderProps {
  children: ReactNode;
}

const WebsiteViewStorageName = "WebsiteView";
const LimitViewParam = "limitview";
const ParentUrlParam = "parenturl";
const storageProvider = new LocalStorageProvider();

/*
 * Provides the WebsiteView object. Defines if the website should be limited to a specific view
 */
const WebsiteViewProvider = ({ children }: ProviderProps): ReactElement => {
  const [websiteView, setWebsiteView] = useState<WebsiteView>(
    storageProvider.getItem<WebsiteView>(WebsiteViewStorageName) ?? DefaultWebsiteView
  );
  const location = useLocation();

  useEffect(() => {
    // Check if we have the datset command on the url
    const params = new URLSearchParams(location.search);
    const limitViewParamAsString = params.get(LimitViewParam);
    if (!limitViewParamAsString) {
      return;
    }
    const state = limitViewParamAsString === "true";
    const parentUrl = params.get(ParentUrlParam);
    if (!parentUrl) throw new Error("Parent url is not defined");

    const wv: WebsiteView = {
      allowParentRedirects: state,
      removeMenu: state,
      removeHeader: state,
      removeTitle: state,
      parentUrl: parentUrl ?? undefined,
    };

    setWebsiteView(wv);
    storageProvider.setItem(WebsiteViewStorageName, wv);
  }, [location.search]);

  // If we are running in an iframe, we use the websiteView from the state, otherwise we use the default
  // we do this because we want to only override the websiteView from the parent window
  const context = isRunningInIframe() ? websiteView ?? DefaultWebsiteView : DefaultWebsiteView;

  return <WebsiteViewContext.Provider value={context}>{children}</WebsiteViewContext.Provider>;
};

export default WebsiteViewProvider;
