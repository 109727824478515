import { ReactElement } from "react";
import { BadgeSize } from "../../models/misc/badgeSize";
import { PaymentTransactionStatus } from "../../models/paymentTransactions/paymentTransactionEnums";
import { BasicTag } from "../../components/tags/BasicTag";
import "./PaymentTransactionStatusTag.scss";

/*
 * Generates payment transaction status tag
 */
export const PaymentTransactionStatusTag = ({
  status,
  className,
  size,
}: {
  status: PaymentTransactionStatus;
  className?: string;
  size?: BadgeSize;
}): ReactElement => (
  <BasicTag
    tagName={status}
    className={className}
    tagCssEnd="-status"
    size={size}
  />
);

PaymentTransactionStatusTag.defaultProps = {
  className: undefined,
  size: BadgeSize.Medium,
};

export default PaymentTransactionStatusTag;
