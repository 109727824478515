export enum FeeCalculatorType {
  Custom = "Custom",
  Uk = "Uk",
}

export const FeeCalculatorTypeMap = new Map<FeeCalculatorType, string>([
  [FeeCalculatorType.Custom, "Custom"],
  [FeeCalculatorType.Uk, "UK"],
]);

export const FeeCalculatorTypeArray = Array.from(FeeCalculatorTypeMap.entries());
