import { ReactElement, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { tPages } from "../../i18n";
import { Account } from "../../models/accounts/account";
import AccountInfoTab from "./AccountInfoTab";
import AccountSettingsTab from "./Settings/AccountSettingsTab";
import AccountTransactionsTab from "./Transactions/AccountTransactionsTab";
import AccountPaymentTransactionsTab from "./PaymentTransactions/AccountPaymentTransactionsTab";
import PaymentRequestsTab from "./PaymentRequests/PaymentRequestsTab";
import AccountPayersTab from "./Payers/AccountPayersTab";
import { filterItemBuilder } from "../../utils/filtering/filteringUtils";
import { countPaymentTransactions } from "../../services/paymentTransactions/paymentTransactions.service";
import { QueryNameConsts } from "../../common/queryNameConsts";
import TabTitleWithCount from "../common/TabTitleWithCount";
import { countTransactions } from "../../services/transactions/transactions.service";
import { countPaymentRequests } from "../../services/paymentRequests/paymentRequests";
import { countAccountPayers } from "../../services/accounts/accounts.service";

const keyPrefix = "accountPage";

const TransactionsTabEventKey = "transactions";
const SettingsTabEventKey = "settings";
const PaymentTransactionsTabEventKey = "payment-transactions";
const PaymentRequestsTabEventKey = "payment-requests";
const PayersTabEventKey = "payers";

const AccountIdProperty = "accountId";

/*
 * Displays the accounts tabs
 */
const AccountTabs = ({ account }: { account: Account }): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [transactionTabSelected, setTransactionTabSelected] = useState(false);
  const [settingsTabSelected, setSettingsTabSelected] = useState(false);
  const [paymentTransactionsTabSelected, setPaymentTransactionsTabSelected] = useState(false);
  const [paymentReqestsTabSelected, setPaymentReqestsTabSelected] = useState(false);
  const [payersTabSelected, setPayersTabSelected] = useState(false);

  const { data: paymentTransactionCount } = useQuery([QueryNameConsts.AccountPaymentTransactionCount, account.id], () =>
    countPaymentTransactions(filterItemBuilder(AccountIdProperty, "eq", account.id))
  );

  const { data: transactionCount } = useQuery([QueryNameConsts.AccountTransactionCount, account.id], () =>
    countTransactions(filterItemBuilder(AccountIdProperty, "eq", account.id))
  );

  const { data: paymentReqestCount } = useQuery([QueryNameConsts.AccountPaymentRequestCount, account.id], () =>
    countPaymentRequests(filterItemBuilder(AccountIdProperty, "eq", account.id))
  );

  const { data: payerCount } = useQuery([QueryNameConsts.AccountPayerCount, account.id], () => countAccountPayers(account.id));

  // Enable the tab the first time it's selected
  const handleTabChange = (eventKey: string) => {
    switch (eventKey) {
      case TransactionsTabEventKey:
        setTransactionTabSelected(true);
        break;
      case SettingsTabEventKey:
        setSettingsTabSelected(true);
        break;
      case PaymentTransactionsTabEventKey:
        setPaymentTransactionsTabSelected(true);
        break;
      case PaymentRequestsTabEventKey:
        setPaymentReqestsTabSelected(true);
        break;
      case PayersTabEventKey:
        setPayersTabSelected(true);
        break;
      default:
        break;
    }
  };

  return (
    <Tabs defaultActiveKey='info' id='account-tab' className='mb-2' onSelect={(eventKey) => handleTabChange(eventKey ?? "")}>
      <Tab eventKey='info' title={t("infoTabTitle")}>
        <AccountInfoTab account={account} />
      </Tab>
      <Tab
        eventKey={TransactionsTabEventKey}
        title={<TabTitleWithCount title={t("transactionsTabTitle")} count={transactionCount} />}
      >
        {transactionTabSelected && <AccountTransactionsTab account={account} />}
      </Tab>
      <Tab
        eventKey={PaymentTransactionsTabEventKey}
        title={<TabTitleWithCount title={t("paymentTransactionsTabTitle")} count={paymentTransactionCount} />}
      >
        {paymentTransactionsTabSelected && <AccountPaymentTransactionsTab account={account} />}
      </Tab>
      <Tab
        eventKey={PaymentRequestsTabEventKey}
        title={<TabTitleWithCount title={t("paymentRequestsTabTitle")} count={paymentReqestCount} />}
      >
        {paymentReqestsTabSelected && <PaymentRequestsTab account={account} />}
      </Tab>
      <Tab eventKey={PayersTabEventKey} title={<TabTitleWithCount title={t("payersTabTitle")} count={payerCount} />}>
        {payersTabSelected && <AccountPayersTab account={account} />}
      </Tab>
      <Tab eventKey={SettingsTabEventKey} title={t("settingsTabTitle")}>
        {settingsTabSelected && <AccountSettingsTab account={account} />}
      </Tab>
    </Tabs>
  );
};

export default AccountTabs;
