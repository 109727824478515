/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from 'react';
import { Form, FormGroupProps } from 'react-bootstrap';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface FormGroupControlProps extends FormGroupProps {
  labelName: string;
  subLabelName?: string;
  controlPlaceholder?: string;
  controlDefaultValue?: string | number | readonly string[];
  controlType?: string;
  errorMessage?: string;
  registerReturn: UseFormRegisterReturn;
  disabled?: boolean;
  [x: string]: unknown;
}

export function FormGroupControl({
  labelName,
  subLabelName,
  controlPlaceholder,
  controlDefaultValue,
  controlType,
  errorMessage,
  registerReturn,
  disabled,
  ...rest
}: FormGroupControlProps): ReactElement {
  
  return (
    <Form.Group {...rest}>
      <Form.Label>
        {labelName}
        {subLabelName ? <span className='mx-2'>{subLabelName}</span> : ''}
      </Form.Label>
      <Form.Control
        type={controlType ?? 'text'}
        placeholder={controlPlaceholder}
        {...registerReturn}
        className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
        defaultValue={controlDefaultValue}
        disabled={disabled}
      />
      <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
}
FormGroupControl.defaultProps = {
  subLabelName: undefined,
  controlPlaceholder: undefined,
  controlDefaultValue: undefined,
  controlType: undefined,
  errorMessage: undefined,
  disabled: false
};

export function FormGroupTextArea({
  labelName,
  subLabelName,
  controlPlaceholder,
  controlDefaultValue,
  controlType,
  errorMessage,
  registerReturn,
  disabled,
  ...rest
}: FormGroupControlProps): ReactElement {
  return (
    <Form.Group {...rest}>
      <Form.Label>
        {labelName}
        {subLabelName ? <span className='mx-2'>{subLabelName}</span> : ''}
      </Form.Label>
      <Form.Control
        type={controlType ?? 'text'}
        as='textarea'
        placeholder={controlPlaceholder}
        {...registerReturn}
        className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
        defaultValue={controlDefaultValue}
        disabled={disabled}
      />
      <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
}

FormGroupTextArea.defaultProps = {
  subLabelName: undefined,
  controlPlaceholder: undefined,
  controlDefaultValue: undefined,
  controlType: undefined,
  errorMessage: undefined,
  disabled: false
};
