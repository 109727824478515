import lang from "i18next";
import SettlementConfigurationsApi from "../../common/api/payThemApi/settlementConfigurations";
import { tCommon } from "../../i18n";
import { SettlementConfiguration } from "../../models/settlements/settlementConfiguration";
import { SettlementConfigurationGroup } from "../../models/settlements/settlementConfigurationGroup";
import { toastSuccess } from "../notifications/toastNotification.service";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

/*
 * Returns all settlement configuration groups
 */
export const getAllSettlementConfigurationGroups = async (): Promise<SettlementConfigurationGroup[]> => {
  const groups = new Array<SettlementConfigurationGroup>();
  const api = new SettlementConfigurationsApi();
  let page = 1;
  const itemsPerPage = 20;

  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      const result = await api.getSettlementConfigurationGroups(page, itemsPerPage);
      if (result.items?.length > 0) {
        groups.push(...result.items);
        if (result.items.length < itemsPerPage) {
          break;
        }
        page += 1;
      } else {
        break;
      }
    } while (page !== 0);
    return groups;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationGroupsGet");
    throw error;
  }
};

/*
 * Returns a single settlement configuration group
 */
export const getSettlementConfigurationGroup = async (id: string): Promise<SettlementConfigurationGroup> => {
  try {
    const api = new SettlementConfigurationsApi();
    return await api.getSettlementConfigurationGroup(id);
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationGroupGet");
    throw error;
  }
};

/*
 * Adds a new settlemt configuration group
 */
export const addSettlementConfigurationGroup = async (
  settlementConfiguration: SettlementConfigurationGroup
): Promise<SettlementConfigurationGroup> => {
  try {
    const api = new SettlementConfigurationsApi();
    const result = await api.addSettlementConfigurationGroup(settlementConfiguration);
    toastSuccess(lang.t("toastMessages.settlementConfigurations.configurationGroupAddSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationGroupAdd");
    throw error;
  }
};

/*
 * Updates the settlement configuration group
 */
export const updateSettlementConfigurationGroup = async (
  settlementConfiguration: SettlementConfigurationGroup
): Promise<SettlementConfigurationGroup> => {
  try {
    const api = new SettlementConfigurationsApi();
    const result = await api.updateSettlementConfigurationGroup(settlementConfiguration);
    toastSuccess(lang.t("toastMessages.settlementConfigurations.configurationGroupUpdateSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationGroupUpdate");
    throw error;
  }
};

/*
 * Deletes an existing settlemt configuration group
 */
export const deleteSettlementConfigurationGroup = async (id: string): Promise<void> => {
  try {
    const api = new SettlementConfigurationsApi();
    const result = await api.deleteSettlementConfigurationGroup(id);
    toastSuccess(lang.t("toastMessages.settlementConfigurations.configurationGroupDeleteSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationGroupDelete");
    throw error;
  }
};

/*
 * Adds a new settlemt configuration to the settlement configuration group
 */
export const addSettlementConfiguration = async (
  groupId: string,
  settlementConfiguration: SettlementConfiguration
): Promise<SettlementConfigurationGroup> => {
  try {
    const api = new SettlementConfigurationsApi();
    const result = await api.addSettlementConfigurationToSettlementConfigurationGroup(groupId, settlementConfiguration);
    toastSuccess(lang.t("toastMessages.settlementConfigurations.configurationAddSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationAdd");
    throw error;
  }
};

/*
 * Updates the settlement configuration in a settlement configuration group
 */
export const updateSettlementConfiguration = async (
  groupId: string,
  settlementConfiguration: SettlementConfiguration
): Promise<SettlementConfigurationGroup> => {
  try {
    const api = new SettlementConfigurationsApi();
    const result = await api.updateSettlementConfiguration(groupId, settlementConfiguration);
    toastSuccess(lang.t("toastMessages.settlementConfigurations.configurationUpdateSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationUpdate");
    throw error;
  }
};

/*
 * Deletes an existing settlemt configuration from a settlement configuration group
 */
export const deleteSettlementConfiguration = async (groupId: string, configurationId: string): Promise<void> => {
  try {
    const api = new SettlementConfigurationsApi();
    const result = await api.deleteSettlementConfiguration(groupId, configurationId);
    toastSuccess(lang.t("toastMessages.settlementConfigurations.configurationDeleteSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "settlementConfigurations.configurationDelete");
    throw error;
  }
};
