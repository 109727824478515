export const StripeRedirectMessageId = "stripeRedirect";

export interface PayThemMessage {
  id: string;
  dataset: number;
  data: unknown;
}

export interface PayThemMessageType<T> extends PayThemMessage {
  data: T;
}

export interface StripeRedirectData {
  stripeRedirectUrl: string;
  payThemRedirectUrl: string;
}

export interface StripeRedirectMessage extends PayThemMessageType<StripeRedirectData> {
  id: typeof StripeRedirectMessageId;
  data: StripeRedirectData;
}
