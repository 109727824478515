/*
 * Status of whether the transaction property can be changed on an update
 */
// eslint-disable-next-line import/prefer-default-export
export enum UpdateTransactionChangeStatus {
  // Dont allow the property to be changed on an update
  Disable = "Disable",

  // Allow the property to be changed on an update
  Allow = "Allow",
}
