import { ReactElement, useEffect } from "react";
import LocationProvider from "../../common/providers/locationProvider";
import ErrorCode from "../../common/errorCode";
import { getCompanyAccountInfo } from "../../services/settings/settings.service";
import useWebsiteView from "../../context/websiteView/WebsiteViewContext";
import { isAllowParentRedirect } from "../../components/websiteComms/WebsiteViewHelper";

const TenantIdParam = "tenantId";
const location = new LocationProvider();

export const WebViewCrossTenantValidator = (): ReactElement => {
  const params = new URLSearchParams(location.search());
  const tenantId = params.get(TenantIdParam);
  const wv = useWebsiteView();

  useEffect(() => {
    if (tenantId && isAllowParentRedirect(wv)) {
      getCompanyAccountInfo().then((response) => {
        if (!response) {
          return;
        }

        // Case-insensitive compare without dashes
        if (
          tenantId.toLowerCase().replaceAll("-", "") !==
          response.companyId.toLowerCase()?.replaceAll("-", "")
        ) {
          location.replace(`/error/${ErrorCode.CrossTenantAccess.toString()}`);
        }
      })     
    }

  }, [tenantId, wv]);

  return <></>;
};

export default WebViewCrossTenantValidator;
