import { ReactElement } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { DisplayLoadingOrError } from "../../components/loading/DisplayLoadingOrError";
import { PanelItem } from "../../components/panels/Panels";
import { textOrDefault } from "../../helpers/stringHelpers";
import { tPages } from "../../i18n";
import { Bank } from "../../models/banking/bankModels";
import { getBank } from "../../services/banking/banking.service";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import { HtmlTitle } from "../common/HtmlTitle";

const createTitle = (bank: Bank): string => `${bank.accountName}`;

/*
 * The main details card
 */
const BankDetails = ({ bank }: { bank: Bank }): ReactElement => {
  const { t } = useTranslation(["forms"]);
  return (
    <Card>
      <Card.Body>
        <Card.Title className='details-title'>
          <span>{t("Details")}</span>
          <div className='tag-items'>
            {/* <BasicTag className='tag' tagName={bank.source} tagText={"the text"} tagCssEnd='' />
            <BasicTag className='tag-end' tagName={bank.source} tagText={"the text"} tagCssEnd='' /> */}
          </div>
        </Card.Title>
        <Card.Text as='div'>
          <Row>
            <Col md={6} sm={12}>
              <PanelItem title='Account Name'>{bank.accountName}</PanelItem>
            </Col>
            <Col md={6}>
              <PanelItem title='Currency'>{textOrDefault(bank.currency)}</PanelItem>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <PanelItem title='Description'>{bank.description}</PanelItem>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <PanelItem title='Account Number'>{textOrDefault(bank.accountNumber)}</PanelItem>
            </Col>
            <Col md={6} sm={12}>
              <PanelItem title='Sort Code'>{textOrDefault(bank.sortCode)}</PanelItem>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PanelItem title='IBAN'>{textOrDefault(bank.iban)}</PanelItem>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

/*
 * Generates the title for the account item page
 */
const Title = ({ bank }: { bank: Bank }): ReactElement => <div className='page-title'>{createTitle(bank)}</div>;

export const BankItem = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["forms", "pages"]);

  const { data, isLoading, isError } = useQuery([QueryNameConsts.BankItem, id], () => getBank(id ?? ""));

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleBank", tPages)} subTitle2={id} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !data}>
        <Breadcrumb>
          <CommonBreadcrumbs.Banks />
        </Breadcrumb>
        {data && (
          <section className='item-view-section transaction'>
            <HtmlTitle subTitle1={t("pageTitleBank", tPages)} subTitle2={createTitle(data)} />
            <Title bank={data} />
            <Row>
              <Col className='panel-column'>
                <BankDetails bank={data} />
              </Col>
            </Row>
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default BankItem;
