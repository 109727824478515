export default class LocationProvider {
  private location: Location;

  constructor() {
    this.location = window.location;
  }

  public href(): string {
    return this.location.href;
  }

  public pathName(): string {
    return this.location.pathname;
  }

  public search(): string {
    return this.location.search;
  }

  public toString(): string {
    return this.location.toString();
  }

  public replace(url: string): void {
    this.location.replace(url);
  }
}
