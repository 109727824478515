export interface DefaultNotificationItem {
  // The display name of the notification.
  displayName: string;

  // The description of the notification.
  description: string;

  // The name of the notification.
  notificationName: string;

  // True if the notification is enabled by default for the tenant.
  userEnabled: boolean;

  // True if the notification is enabled by default for the customer
  customerEnabled: boolean;
}

export interface NotificationItem {
  // The display name of the notification.
  displayName: string;

  // The description of the notification.
  description: string;

  // The name of the notification.
  notificationName: string;

  // True if the notification is enabled by default
  enabled: boolean;
}

const notificationItems: DefaultNotificationItem[] = [
  {
    displayName: "notificationLabelPaymentSuccessful",
    description: "notificationDescriptionPaymentSuccessful",
    notificationName: "TenantPaymentSuccessful",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentFailed",
    description: "notificationDescriptionPaymentFailed",
    notificationName: "TenantPaymentFailed",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentProcessing",
    description: "notificationDescriptionPaymentProcessing",
    notificationName: "TenantPaymentProcessing",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentPending",
    description: "notificationDescriptionPaymentPending",
    notificationName: "PaymentPending",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentPendingCancelled",
    description: "notificationDescriptionPaymentPendingCancelled",
    notificationName: "PaymentPendingCancelled",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentPendingCompleted",
    description: "notificationDescriptionPaymentPendingCompleted",
    notificationName: "PaymentPendingCompleted",
    userEnabled: false,
    customerEnabled: true,
  },
];

/*
 * Returns the default notification items for a tenant user
 */
export const getDefaultUserNotificationItems = (): NotificationItem[] =>
  notificationItems.map(
    (item) =>
      ({
        displayName: item.displayName,
        description: item.description,
        notificationName: item.notificationName,
        enabled: item.userEnabled,
      } as NotificationItem)
  );

/*
 * Returns the default notification items for a customer
 */
export const getDefaultCustomerNotificationItems = (): NotificationItem[] =>
  notificationItems.map(
    (item) =>
      ({
        displayName: item.displayName,
        description: item.description,
        notificationName: item.notificationName,
        enabled: item.customerEnabled,
      } as NotificationItem)
  );
