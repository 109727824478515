import { ReactElement, createContext, useContext } from "react";
import lang from "i18next";
import { useTranslation } from "react-i18next";
import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { useQuery } from "react-query";
import { PageTitle } from "../../common/PageTitle";
import { User } from "../../../models/users/user";
import { Role } from "../../../models/users/role";
import { DeleteItemConfirmation } from "../../../components/icons/DeleteItemConfirmation";
import { AddUserButton } from "./AddUser";
import { EditUserButton } from "./EditUser";
import { getUsers, updateUser, deleteUser } from "../../../services/users/user.services";
import { DataGrid, TypeColumnRender, DataGridContextType } from "../../../components/grid/DataGrid";
import { HtmlTitle } from "../../common/HtmlTitle";
import { tPages } from "../../../i18n";
import { BaseOptionsColumn, defaultDataGridPagination, SortAscending } from "../../../components/grid/dataGridHelpers";
import GridNamesConsts from "../../GridNamesConsts";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import PageLoading from "../../../components/loading/PageLoading";
import useMe from "../../../common/hooks/useMe";
import useDataGridStorage from "../../../common/hooks/useDataGridStorage";
import "./Users.scss";

const LangOptions = tPages;
const keyPrefix = "usersLists";

const DataGridContext = createContext<DataGridContextType>(undefined);

/*
 * Column buttons to edit/delete
 */
const ColumnButtons = ({ user }: { user: User }): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const { me } = useMe();

  const context = useContext(DataGridContext);

  // True if this user is 'me'
  const isMe = (userToCheck: User) => userToCheck.id === me?.id;

  // Updates a user
  const handleEditUser = async (id: string, name: string, role: Role): Promise<void> => {
    await updateUser(id, name, role);
    await context?.refreshDataSource();
  };

  // Deletes a user
  const handleDeleteUser = async (id: string): Promise<void> => {
    await deleteUser(id);
    await context?.refreshDataSource();
  };

  return (
    <>
      {me && (
        <>
          <EditUserButton user={user} disableRole={isMe(user)} onSave={handleEditUser} />
          {!isMe(user) && (
            <DeleteItemConfirmation<string>
              item={user.id}
              onDelete={handleDeleteUser}
              body={t("confirmDeleteMessage", { name: user.displayName })}
            />
          )}
        </>
      )}
    </>
  );
};

const columns: TypeColumn[] = [
  {
    name: "displayName",
    header: lang.t("usersLists.columnDisplayName", LangOptions),
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    name: "emailAddress",
    header: lang.t("usersLists.columnEmailAddress", LangOptions),
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    name: "role",
    header: lang.t("usersLists.columnRole", LangOptions),
    minWidth: 50,
    defaultFlex: 1,
    render: ({ data }: TypeColumnRender<User>) => <div>{Role[data.role]}</div>,
  },
  {
    ...BaseOptionsColumn,
    maxWidth: 70,
    textAlign: "left",
    render: ({ data }: TypeColumnRender<User>) => <ColumnButtons user={data} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [];

/*
 * Default sort column
 */
const DefaultSortColumnName = "name";
const DefaultSortDirection = SortAscending;
const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

/*
 * Our grid name
 */
const GridName = GridNamesConsts.UsersGrid;

/*
 * The user page component
 */
export const UsersList = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const { dataGridState, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );
  const { data: users, refetch: refreshUsers } = useQuery([QueryNameConsts.UsersList], () => getUsers());

  const readLoad = async () => {
    refreshUsers();
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleUsers", tPages)} />
      {dataGridState && users ? (
        <>
          <PageTitle title='pageTitleUsers' />
          <section className='data-grid-page'>
            <DataGridContext.Provider value={{ refreshDataSource: readLoad }}>
              <DataGrid<User>
                idProperty='id'
                columns={columns}
                dataSource={users}
                defaultSortInfo={dataGridState.sortInfo}
                onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
                defaultSkip={dataGridState.pagination?.skip}
                onSkipChange={(skip) => setPaginationSkip(skip)}
                defaultLimit={dataGridState.pagination?.limit}
                onLimitChange={(limit) => setPaginationLimit(limit)}
              />
            </DataGridContext.Provider>
            <div className='bottom-button-group'>
              <AddUserButton onAdd={readLoad} />
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default UsersList;
