import { ReactElement, useEffect, useMemo, useState } from "react";
import { Table, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../common/PageTitle";
import { SubscriptionKey } from "../../models/subscriptionKeys/subscriptionKey";
import { SubscriptionKeysApi } from "../../common/api/payThemApi/subscriptionKeysApi";
import PageLoading from "../../components/loading/PageLoading";
import { DeleteItemConfirmation } from "../../components/icons/DeleteItemConfirmation";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";

const keyPrefix = "accessKeys";

const keyWithPrefix = (key:string): string => `${keyPrefix}.${key}`; 

export const SubscriptionKeys = (): ReactElement => {  
  const { t } = useTranslation(tPages.ns);
  const [keys, setKeys] = useState<SubscriptionKey[]>([]);
  const [maxKeys, setMaxKeys] = useState<number>(0);
  const [creating, setCreating] = useState(false);

  const api = new SubscriptionKeysApi();

  // Store the maximum number of keys we can create
  useMemo(() => {
    api.maximumNumberOfKeys().then((result) => {
      setMaxKeys(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get all of the keys currently existing
  useEffect(() => {
    api.getSubscriptionKeys().then((result) => {
      setKeys(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!keys) {
    return <PageLoading />;
  }

  // Creates a new key
  const handleNewKey = () => {
    setCreating(true);
    api
      .createSubscriptionKey(true)
      .then((newKey) => {
        setKeys((current) => [...current, newKey]);
      })
      .finally(() => setCreating(false));
  };

  // Deletes a key
  const handleDeleteKey = (key: string) => {
    api.deleteSubscriptionKey(key).then(() => setKeys(keys.filter((x) => x.key !== key)));
  };

  return (
    <section className='item-view-section'>
      <HtmlTitle subTitle1={t("pageTitleAccessKeys", tPages)} />
      <PageTitle title='pageTitleAccessKeys' />
      <Row>
        <Col className='panel-column'>
          <Card>
            <Card.Body>
              <p>{t(keyWithPrefix("descriptionAccessKeys"))}</p>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <td>{t(keyWithPrefix("headerKey"))}</td>
                    <td width='75px' align='left' />
                  </tr>
                </thead>
                <tbody>
                  {keys.map((sk) => (
                    <tr key={sk.key}>
                      <td className='small'>{sk.key}</td>
                      <td>
                        <CopyToClipboard text={sk.key} />
                        <DeleteItemConfirmation<string> item={sk.key} onDelete={() => handleDeleteKey(sk.key)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div>
                {/* Show a create button if we can still generate keys, else a message if we're at our max */}
                {keys.length < maxKeys ? (
                  <SpinnerButton title={t(keyWithPrefix("buttonCreateKey"))} variant='primary' showSpinner={creating} onClick={handleNewKey} />
                ) : (
                  <p className='text-info'>{t(keyWithPrefix("descriptionMaximumKeysReached"))}</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default SubscriptionKeys;
