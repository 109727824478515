export enum PaymentTransactionStatus {
  // Transaction is being processed
  Processing = "Processing",

  // Transaction is paid
  Paid = "Paid",

  // Transaction has failed, a retry is possible
  Failed = "Failed",

  // Transaction was not authorised and rejected
  NotAuthorised = "NotAuthorised",

  // The transaction was rejected, a retry will probably not work
  Rejected = "Rejected",

  // The transaction has been refunded
  Refunded = "Refunded",

  // The transaction was disputed
  Disputed = "Disputed",

  // The transaction was cancelled
  Cancelled = "Cancelled",

  // A duplicated transaction
  Duplicated = "Duplicated",

  // The transaction is pending action (such as when reserving funds)
  Pending = "Pending",
}

export const PaymentTransactionStatusMap = new Map<PaymentTransactionStatus, string>([
  [PaymentTransactionStatus.Processing, "Processing"],
  [PaymentTransactionStatus.Paid, "Paid"],
  [PaymentTransactionStatus.Failed, "Failed"],
  [PaymentTransactionStatus.NotAuthorised, "Not Authorised"],
  [PaymentTransactionStatus.Rejected, "Rejected"],
  [PaymentTransactionStatus.Refunded, "Refunded"],
  [PaymentTransactionStatus.Disputed, "Disputed"],
  [PaymentTransactionStatus.Cancelled, "Cancelled"],
  [PaymentTransactionStatus.Duplicated, "Duplicated"],
  [PaymentTransactionStatus.Pending, "Pending"],
]);

export const PaymentTransactionStatusArray = Array.from(PaymentTransactionStatusMap.entries());

export enum PaymentTransactionType {
  // This is a payment (positive impact)
  Payment = "Payment",

  // This is a refund (negative impact)
  Refund = "Refund",
}

export const PaymentTransactionTypeMap = new Map<PaymentTransactionType, string>([
  [PaymentTransactionType.Payment, "Payment"],
  [PaymentTransactionType.Refund, "Refund"],
]);

export const PaymentTransactionTypeArray = Array.from(PaymentTransactionTypeMap.entries());

export enum AllocatedStatus {
  // Waiting for the payment to be allocated
  WaitingToBeAllocated = "WaitingToBeAllocated",

  // PayThem auto allocated this payment back into the ERP
  AutoAllocated = "AutoAllocated",

  // Marked as allocated manually
  ManuallyAllocated = "ManuallyAllocated",

  // Payment is unallocated (due to transactions not existing)
  Unallocated = "Unallocated",

  // An error occurred and payment should be allocated manually
  Error = "Error",

  // This type of payment transaction does not need to be allocated
  NotApplicable = "NotApplicable",
}

export const AllocatedStatusMap = new Map<AllocatedStatus, string>([
  [AllocatedStatus.WaitingToBeAllocated, "Waiting To Be Allocated"],
  [AllocatedStatus.AutoAllocated, "Auto Allocated"],
  [AllocatedStatus.ManuallyAllocated, "Manually Allocated"],
  [AllocatedStatus.Unallocated, "Unallocated"],
  [AllocatedStatus.Error, "Error"],
  [AllocatedStatus.NotApplicable, "Not Applicable"],
]);

export const AllocatedStatusArray = Array.from(AllocatedStatusMap.entries());
