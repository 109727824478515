/* eslint-disable @typescript-eslint/no-unused-vars */
import { PaymentFeeConfiguration, RegisterPaymentFeeConfiguration } from "../../../models/paymentsFees/paymentFeeConfiguration";
import { FeeCalculatorType } from "../../../models/paymentsFees/feeCalculatorType";
import { UkLatePaymentInfo } from "../../../models/paymentsFees/ukLatePaymentInfo";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";
import { FeeGroupType } from "../../../models/paymentsFees/feeGroupType";

const configuration: PaymentFeeConfiguration = {
  id: "the-id",
  name: "UK Late Payments",
  description: "Default UK late payment configuration",
  feeGroupType: FeeGroupType.LatePayment,
  type: FeeCalculatorType.Uk,
  enabled: true,
  gracePeriodInDays: 0,
  recurringPeriodInDays: 0,
  isSystemDefined: false,
};

const configurations: PaymentFeeConfiguration[] = [configuration];

class LatePaymentConfigurationsApi extends BasePayThemApi {
  private endPoint = "late-payment-configurations";

  /*
   * Returns a page of late payment configurations
   */
  public async getLatePaymentConfigurations(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string
  ): Promise<PageResult<PaymentFeeConfiguration>> {
    const result = await this.getPage<PaymentFeeConfiguration>(this.endPoint, page, itemsPerPage, sort, filter);
    return result;
  }

  /*
   * Returns a single late payment configuration
   */
  public async getLatePaymentConfiguration(id: string): Promise<PaymentFeeConfiguration> {
    const result = await this.getItem<PaymentFeeConfiguration, string>(this.endPoint, id);
    return result;
  }

  /*
   * Adds a new late payment configuration
   */
  public async addLatePaymentConfiguration(item: RegisterPaymentFeeConfiguration): Promise<PaymentFeeConfiguration> {
    const result = await this.postWithResponse<RegisterPaymentFeeConfiguration, PaymentFeeConfiguration>(this.endPoint, item);
    return result;
  }

  /*
   * Updates an existing late payment configuration
   */
  public async updateLatePaymentConfiguration(item: PaymentFeeConfiguration): Promise<PaymentFeeConfiguration> {
    const result = await this.putWithResponse<PaymentFeeConfiguration, PaymentFeeConfiguration>(this.endPoint, item);
    return result;
  }

  /*
   * Deletes an existing late payment configuration
   */
  public async deleteLatePaymentConfiguration(groupId: string): Promise<void> {
    await this.deleteItem(this.endPoint, groupId);
  }

  /*
   * Returns information on uk late payments
   */
  public async getUkLatePaymentInfo(): Promise<UkLatePaymentInfo> {
    const url = `${this.endPoint}/uk/info`;
    const result = await this.get<UkLatePaymentInfo>(url);
    return result;
  }
}

export default LatePaymentConfigurationsApi;
