import * as Yup from "yup";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import {
  AddPaymentProviderConfiguration,
  PaymentProviderConfiguration,
} from "../../../../models/paymentProviders/paymentProviderConfiguration";
import PaymentProviderModelConsts from "../../../../models/paymentProviders/paymentProviderModelConsts";
import {
  AddPaymentMethodConfiguration,
  PaymentMethodConfiguration,
} from "../../../../models/paymentProviders/paymentMethodConfiguration";
import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";

export interface ProviderFormModel {
  name: string;
  description?: string;
  enabled: boolean;
  displayPriority: number;
}

export type AddOrEditMode = "add" | "edit";

export interface AddOrEditItemProps<TData, TModel extends ProviderFormModel> {
  mode: AddOrEditMode;
  data?: TData;
  onSave: (model: TModel) => void;
}

/*
 * Performs the mapping of the common fields in AddPaymentProviderConfiguration from ProviderFormModel
 */
export function mapToAddPaymentProviderConfiguration<T extends AddPaymentProviderConfiguration>(
  model: ProviderFormModel,
  providerName: string
): T {
  const configuration = {
    providerName,
    name: model.name,
    description: model.description,
    enabled: model.enabled,
    displayPriority: model.displayPriority,
  } as T;
  return configuration;
}

/*
 * Performs the mapping of the common fields in PaymentProviderConfiguration from ProviderFormModel
 */
export function mapToPaymentProviderConfiguration<T extends PaymentProviderConfiguration>(
  currentConfiguration: T,
  model: ProviderFormModel
): T {
  const configuration = { ...currentConfiguration };
  configuration.name = model.name;
  configuration.description = model.description;
  configuration.enabled = model.enabled;
  configuration.displayPriority = model.displayPriority;
  return configuration;
}

/*
 * Performs the mapping of the common fields in AddPaymentMethodConfiguration
 */
export const mapToAddPaymentMethodConfiguration = (
  paymentMethodType: PaymentMethodType,
  enabled: boolean,
  filterGroup?: ItemFilterGroup
): AddPaymentMethodConfiguration => {
  const configuration = {
    paymentMethodType,
    enabled,
    filterGroup,
  } as AddPaymentMethodConfiguration;
  return configuration;
};

/*
 * Performs the mapping of the common fields in PaymentMethodConfiguration
 */
export const mapToPaymentMethodConfiguration = (
  currentConfiguration: PaymentMethodConfiguration,
  enabled: boolean,
  filterGroup?: ItemFilterGroup
): PaymentMethodConfiguration => {
  const configuration = { ...currentConfiguration };
  configuration.enabled = enabled;
  configuration.filterGroup = filterGroup;
  return configuration;
};

/*
 * Validation
 */

export const ProviderFormValidationName = Yup.string()
  .label("Name")
  .required()
  .min(PaymentProviderModelConsts.NameMinLength)
  .max(PaymentProviderModelConsts.NameMaxLength);

export const ProviderFormValidationDescription = Yup.string()
  .label("Description")
  .max(PaymentProviderModelConsts.DescriptionMaxLength);

export const ProviderFormValidationDisplayPriority = Yup.number()
  .label("Priority")
  .required()
  .min(0)
  .max(100)
  .typeError("Priority is required");

export const ProviderFormValidationEnabled = Yup.boolean().required();

export const ProviderFormValidationCommon = {
  name: ProviderFormValidationName,
  description: ProviderFormValidationDescription,
  displayPriority: ProviderFormValidationDisplayPriority,
  enabled: ProviderFormValidationEnabled,
};
