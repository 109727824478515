import { ReactElement, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import ActivityList from "./ActivityList";
import { tPages, tForms } from "../../i18n";
import { PageTitle } from "../common/PageTitle";
import { useDatasetId } from "../../common/hooks/useDatasetId";
import { DatasetsApi } from "../../common/api/payThemApi/datasetsApi";

/**
 * A container for activities that supports a tab control
 */
const ActivitiesTabs = (): ReactElement => {
  const { t } = useTranslation([tForms.ns]);
  const [selectedTab, setSelectedTab] = useState<string>("dataset");
  const [datasetId] = useDatasetId();
  const { data } = useQuery("datasetDropdown", () => {
    const api = new DatasetsApi();
    return api.getAllDatasets();
  });
  const selectedDataset = data?.items.find((x) => x.id === datasetId)?.name;
  return (
    <>
      <PageTitle title={t("activitiesList.title", tPages)} />
      <Tabs
        defaultActiveKey="dataset"
        id="settings-tab"
        className="mb-2"
        activeKey={selectedTab ?? ""}
        onSelect={(tab) => {
          if (tab) setSelectedTab(tab);
        }}
        title={t("activitiesList.title", tPages)}
      >
        {selectedDataset !== undefined ? (
          <Tab eventKey="dataset" title={selectedDataset}>
            <ActivityList scope="Dataset" />
          </Tab>
        ) : null}
        <Tab
          eventKey="tenant"
          title={t("activitiesList.tenantTabTitle", tPages)}
        >
          <ActivityList scope="Tenant" />
        </Tab>
      </Tabs>
    </>
  );
};

export default ActivitiesTabs;
