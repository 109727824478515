export interface WebsiteView {
  allowParentRedirects: boolean;
  removeMenu: boolean;
  removeHeader: boolean;
  removeTitle: boolean;
  parentUrl?: string;
}

export const DefaultWebsiteView: WebsiteView = {
  allowParentRedirects: false,
  removeMenu: false,
  removeHeader: false,
  removeTitle: false,
  parentUrl: undefined,
};
