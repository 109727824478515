/* eslint-disable class-methods-use-this */
import { AxiosInstance } from "axios";
import { BaseApi } from "../baseApi";
import { getPayThemClient } from "./payThemClient.instance";
import PayThemApiClientType from "../payThemApiClientType";

export default abstract class BasePayThemApi extends BaseApi {
  protected getInstance(): AxiosInstance {
    const instance = getPayThemClient(PayThemApiClientType.DatasetAndTeanant);
    return instance;
  }
}
