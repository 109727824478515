import { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpStatusCode from "../../common/httpStatusCode";
import getProductInfo from "../../common/productInfo";
import { imgError403401, imgError404 } from "../../assets/images";
import "./ErrorPage.scss";
import ErrorCode from "../../common/errorCode";

type ErrorParams = {
  code: string;
};

const ErrorPage = (): ReactElement => {
  const { code } = useParams<ErrorParams>();
  const { t } = useTranslation();
  const { productShortName: productName } = getProductInfo();

  let imageError = null;
  let errorMessage = null;
  let detailMessage = null;
  let actionMessage = null;
  let goHome = false;

  switch (code) {
    case HttpStatusCode.NotFound.toString():
      imageError = imgError404;
      errorMessage = t("errors.httpError404NotFound.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError404NotFound.actionMessage");
      goHome = true;
      break;

    case HttpStatusCode.Forbidden.toString():
      imageError = imgError403401;
      errorMessage = t("errors.httpError403Forbidden.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError403Forbidden.actionMessage");
      goHome = true;
      break;

    case HttpStatusCode.BadGateway.toString():
      errorMessage = t("errors.httpError502BadGateway.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError502BadGateway.actionMessage");
      goHome = true;
      break;

    case HttpStatusCode.ServiceUnavailable.toString():
      errorMessage = t("errors.httpError503ServiceUnavailable.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError503ServiceUnavailable.actionMessage");
      goHome = true;
      break;

    case ErrorCode.CrossTenantAccess.toString():
      errorMessage = t("errors.error1000CrossTenantAccess.errorMessage");
      detailMessage = t("errors.error1000CrossTenantAccess.detailMessage");
      goHome = false;
      break;

    default:
      errorMessage = t("errors.defaultUnhandledError.errorMessage");
      detailMessage = t("errors.defaultUnhandledError.detailMessage");
      actionMessage = t("defaultUnhandledError.actionMessage");
      goHome = true;
      break;
  }

  return (
    <Container fluid>
      <Row>
        <Col className='p-0'>
          <div className='d-flex flex-column align-items-center error-container page-container'>
            {imageError ? <img alt='' src={imageError} /> : null}
            {errorMessage ? <span className='error-message'>{errorMessage}</span> : null}
            {detailMessage ? <span className='detail-message'>{detailMessage}</span> : <></>}
            {goHome ? (
              <Link className='go-home' to='/'>
                {actionMessage}
              </Link>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ErrorPage;
