import toast from "react-hot-toast";
import lang from "i18next";
import { ProblemDetails, isProblemDetails } from "../../common/problemDetails";
import { tCommon } from "../../i18n";

const DefaultUnknownErrorMessage = "An unknown error occurred";
const getDefaultUnknownErrorMessage = () => lang.t(DefaultUnknownErrorMessage, tCommon);

export function toastError(message: string): void {
  toast.error(message);
}

export function toastSuccess(message: string): void {
  toast.success(message);
}

export function toastProblemDetails(problemDetails: ProblemDetails, defaultMessage?: string): void {
  let errorMessage = defaultMessage;

  if (problemDetails.status === 404) {
    errorMessage = lang.t("Item could not be found", tCommon);
  } else if (problemDetails.detail && problemDetails.detail !== "") {
    errorMessage = problemDetails.detail;
  } else if (problemDetails.type) {
    errorMessage = getDefaultUnknownErrorMessage();
  }

  toast.error(errorMessage || getDefaultUnknownErrorMessage());
}

export function toastErrorObject(error: unknown, defaultMessage?: string): void {
  if (isProblemDetails(error)) {
    toastProblemDetails(error as ProblemDetails, defaultMessage);
  } else if (error instanceof Error) {
    toast.error(error.message || getDefaultUnknownErrorMessage());
  } else {
    toast.error(defaultMessage || getDefaultUnknownErrorMessage());
  }
}
