import { ReactElement } from "react";
import { Spinner } from "react-bootstrap";
import { Dataset } from "../../models/datasets/dataset";
import { isErrorDatasetStatus, isProcessingDatasetStatus } from "../../services/datasets/dataset.service";

/*
 * Returns the Dataset title based on it's status (with spinner if required)
 */
export const DatasetTitle = ({ dataset, showStatus }: { dataset: Dataset; showStatus: boolean }): ReactElement => {
  const title = showStatus ? dataset.status : dataset.name;

  if (isProcessingDatasetStatus(dataset.status)) {
    return (
      <div>
        <span>{title}</span>
        <Spinner animation='border' role='status' size='sm' className='mx-2' />
      </div>
    );
  }

  if (isErrorDatasetStatus(dataset.status)) {
    return <div className='text-danger'>{title}</div>;
  }

  return <>{title}</>;
};
export default DatasetTitle;
