import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { DatasetDialog, DatasetFormModel } from "./DatasetDialog";
import { Dataset } from "../../../models/datasets/dataset";
import { IconButton } from "../../../components/icons/IconButton";
import { tForms } from "../../../i18n";

interface EditDatasetButtonProps {
  dataset: Dataset;
  disabled?: boolean;
  onSave: (dataset: Dataset) => void;
  onResetData: (id: number) => void;
}

const MapModelToDataset = (model: DatasetFormModel, currentDataset: Dataset): Dataset => {
  const dataset = { ...currentDataset };
  dataset.name = model.name;
  dataset.shortName = model.shortName;
  dataset.description = model.description;
  dataset.hostSystemName = model.erp;
  dataset.hostId = model.hostId;
  dataset.customHostId = model.customHostId;
  return dataset;
};

/*
 * Display an edit user button which display the edit user dialog
 */
export const EditDatasetButton = ({ dataset, disabled, onSave, onResetData }: EditDatasetButtonProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  const [show, setShow] = useState<boolean>(false);
  const [enableDialog, setEnableDialog] = useState<boolean>(false);

  const handleOnSave = (model: DatasetFormModel) => {
    setShow(false);
    onSave(MapModelToDataset(model, dataset));
  };

  const handleClick = () => {
    if (!disabled) {
      setShow(true);
      setEnableDialog(true);
    }
  };

  const handleResetData = (id: number) => {
    setShow(false);
    onResetData(id);
  };

  return (
    <>
      <IconButton title={t("titleEdit")} icon={faEdit} disabled={disabled} onClick={handleClick} />
      {enableDialog && (
        <DatasetDialog
          show={show}
          mode='edit'
          data={dataset}
          onClose={() => setShow(false)}
          onClosed={() => setEnableDialog(false)}
          onSave={(model) => handleOnSave(model)}
          onResetData={(id) => handleResetData(id)}
        />
      )}
    </>
  );
};

EditDatasetButton.defaultProps = {
  disabled: false,
};

export default EditDatasetButton;
