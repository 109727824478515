import { GeneralRequest, GeneralResult } from "../../../models/paymentProviders/opayo/opayoApiModels";
import BasePayThemApi from "./basePayThemApi";

class OpayoApi extends BasePayThemApi {
  private endPoint = "opayo";

  /*
   * Tests if the connection to the Opayo server using the given vendor details is valid
   */
  public async testConnection(
    vendor: string,
    user: string,
    password: string | undefined,
    useTestServer: boolean,
    paymentProviderId?: string
  ): Promise<GeneralResult> {
    const url = `${this.endPoint}/admin/test-connection`;
    const body = { vendor, user, password, useTestServer, paymentProviderId } as GeneralRequest;
    const result = await this.postWithResponse<GeneralRequest, GeneralResult>(url, body);
    return result;
  }

  /*
   * Attempts to register the PayThem IP address with Sage Pay
   */
  public async registerIpAddress(
    vendor: string,
    user: string,
    password: string | undefined,
    useTestServer: boolean,
    paymentProviderId?: string
  ): Promise<GeneralResult> {
    const url = `${this.endPoint}/admin/register-ip`;
    const body = { vendor, user, password, useTestServer, paymentProviderId } as GeneralRequest;
    const result = await this.postWithResponse<GeneralRequest, GeneralResult>(url, body);
    return result;
  }
}
export default OpayoApi;
