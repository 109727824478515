export class SettingsRouteConsts {
  public static readonly Details = "/settings/details";

  public static readonly Customisation = "/settings/customisation";

  public static readonly Preferences = "/settings/preferences";

  public static readonly PaymentProviders = "/settings/payment-providers";

  public static readonly PaymentProvidersNew = "/settings/payment-providers/new";

  public static readonly AccessKeys = "/settings/access-keys";

  public static readonly Users = "/settings/users";

  public static readonly Datasets = "/settings/companies";

  public static readonly Notifications = "/settings/notifications";

  public static readonly WebHooks = "/settings/web-hooks";
}
export default SettingsRouteConsts;
