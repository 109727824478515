import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { MainRouteConsts } from "../MainRoutesConsts";
import { DefaultBlankText } from "../../common/commonConsts";

export const TransactionLink = ({ id, children }: { id: string | undefined; children?: ReactNode }): ReactElement =>
  id ? <Link to={`${MainRouteConsts.Transactions}/${id}`}>{children ?? id}</Link> : <>{DefaultBlankText}</>;

TransactionLink.defaultProps = {
  children: undefined,
};
export default TransactionLink;
