import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconButton } from "../../components/icons/IconButton";
import { PanelItem } from "../../components/panels/Panels";
import { tPages, tForms } from "../../i18n";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { SettingsRouteConsts } from "../SettingsRouteConsts";
import ProviderDataDialog from "./ProviderDataDialog";
import PaymentMethodBadge from "./PaymentMethodBadge";

/*
 * Payment Provider details
 */
const PaymentProviderDetails = ({ paymentTransaction }: { paymentTransaction: PaymentTransaction }): ReactElement => {
  const { t } = useTranslation([tPages.ns, tForms.ns]);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title>{t("paymentTransactionPage.paymentProvider.title")}</Card.Title>
          <Card.Text as='div'>
            <Row>
              <Col md={6}>
                <div className='main-item'>
                  <div className='title'>{t("paymentTransactionPage.paymentProvider.providerName")}</div>
                  <div className='content'>
                    {paymentTransaction.paymentProvider}
                    <Link to={`${SettingsRouteConsts.PaymentProviders}/${paymentTransaction.paymentProviderId}`}>
                      <IconButton icon={faExternalLinkAlt} />
                    </Link>
                    <PaymentMethodBadge className='mt-1' paymentMethodType={paymentTransaction.paymentMethodType} />
                  </div>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <PanelItem title={t("paymentTransactionPage.paymentProvider.providerReference")}>
                  {paymentTransaction.paymentProviderReference}
                </PanelItem>
                <PanelItem title={t("paymentTransactionPage.paymentProvider.providerData")}>
                  <Button variant='secondary' size='sm' onClick={() => setShowDialog(true)}>
                    {t("buttonView", tForms)}
                  </Button>
                </PanelItem>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
        <ProviderDataDialog show={showDialog} paymentTransaction={paymentTransaction} onHide={() => setShowDialog(false)} />
      </Card>
    </>
  );
};

export default PaymentProviderDetails;
