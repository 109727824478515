import { ReactElement } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getRecentSuccessfulPayments } from "../../services/paymentTransactions/paymentTransactions.service";
import {
  PaymentTransactionsInfo,
  PaymentTransactionInfoPlaceholder,
} from "./PaymentTransactionsInfo";

const DefaultNumberSuccessfulPayments = 8;

export const RecentPayments = (): ReactElement => {
  const { t } = useTranslation(["forms"]);

  const { data, isLoading, isError } = useQuery(
    QueryNameConsts.SuccessfulPaymentTransactions,
    () => getRecentSuccessfulPayments(DefaultNumberSuccessfulPayments)
  );

  return (
    <Card>
      <Card.Body>
        <Card.Title className="heading-title-20 font-bold">
          {t("titleSuccessfulPaymentAttempts")}
        </Card.Title>
        {isLoading && PaymentTransactionInfoPlaceholder}
        {!isLoading && !isError && (
          <Card.Text as="div">
            {data && <PaymentTransactionsInfo transactions={data} isSuccess />}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};
export default RecentPayments;
