import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { PageTitle } from "../common/PageTitle";
import AddNewPaymentRequestButton from "./NewPaymentRequestDialog";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";
import PaymentRequestGrid, { PaymentRequestFilters, PaymentRequestSortInfo } from "./Grid/PaymentRequestGrid";
import {
  paymentRequestTypeColumn,
  paymentRequestCreatedOnColumn,
  paymentRequestCustomerNameColumn,
  paymentRequestAmountToPayColumn,
  paymentRequestStatusColumn,
  optionsColumn,
  paymentCaptureTypeColumn,
} from "./Grid/GridColumns";
import { defaultDataGridPagination } from "../../components/grid/dataGridHelpers";
import GridNamesConsts from "../GridNamesConsts";
import PageLoading from "../../components/loading/PageLoading";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";

/*
 * Default standard column list
 */
const gridColumns: TypeColumn[] = [
  paymentRequestTypeColumn,
  paymentRequestCreatedOnColumn,
  paymentRequestCustomerNameColumn,
  paymentRequestAmountToPayColumn,
  paymentCaptureTypeColumn,
  paymentRequestStatusColumn,
  optionsColumn,
];

/*
 * Our grid name
 */
const GridName = GridNamesConsts.PaymentRequestGrid;

/*
 * The list
 */
export const PaymentRequestList = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const gridData = useDataGridStorage(
    GridName,
    PaymentRequestFilters,
    PaymentRequestSortInfo,
    defaultDataGridPagination,
    gridColumns
  );
  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentRequests", tPages)} />
      {gridData.dataGridState ? (
        <>
          <PageTitle title='pageTitlePaymentRequests' />
          <section className='data-grid-page'>
            <PaymentRequestGrid columns={gridColumns} gridStorage={gridData} />
            <div className='bottom-button-group'>
              <AddNewPaymentRequestButton />
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};
export default PaymentRequestList;
