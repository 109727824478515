import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import "./Loading.scss";

interface LoadingErrorProps {
  message?: string;
}

export const LoadingError = ({ message }: LoadingErrorProps): ReactElement => (
  <div className=''>
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <p>{message}</p>
  </div>
);

LoadingError.defaultProps = {
  message: "Loading Error",
};

export default LoadingError;
