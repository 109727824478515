/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";

interface DialogButtonProps extends ButtonProps {
  label?: string;
}

export const DialogPrimaryButton = ({ label, ...rest }: DialogButtonProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);

  return (
    <Button {...rest} variant='primary'>
      {label || t("buttonOk")}
    </Button>
  );
};

DialogPrimaryButton.defaultProps = {
  label: undefined,
};

export const DialogSecondaryButton = ({ label, ...rest }: DialogButtonProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);

  return (
    <Button {...rest} variant='link'>
      {label || t("buttonCancel")}
    </Button>
  );
};

DialogSecondaryButton.defaultProps = {
  label: undefined,
};
