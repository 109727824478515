import { ReactElement } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getAllowCustomerNotifications } from "../../services/tenantSettings/tenantSettings.service";
import TenantSettingAlert from "./TenantSettingAlert";
import { tPages } from "../../i18n";

const keyPrefix = "notificationsPage";

/*
 * Displays an alert if customer notifications are not enabled
 */
const AllowCustomerNotificationsAlert = (): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const { data: allow, isLoading } = useQuery([QueryNameConsts.AllowCustomerNotifications], () =>
    getAllowCustomerNotifications()
  );
  return (
    <TenantSettingAlert allow={isLoading || allow}>
      <p>{t("customerNotificationsAlert")}</p>
      <p>{t("contractDraycirAlert")}</p>
    </TenantSettingAlert>
  );
};

export default AllowCustomerNotificationsAlert;
