import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "react-bootstrap";
import { useQuery } from "react-query";
import { SettingsRouteConsts } from "../../SettingsRouteConsts";
import { OpayoProviderName } from "../../../models/paymentProviders/opayo/opayoModels";
import { TranSafeProviderName } from "../../../models/paymentProviders/transafe/transafeModels";
import { StripeProviderName } from "../../../models/paymentProviders/stripe/stripeModels";
import { StripeStartConnect } from "./Stripe/StripeConnect";
import TranSafeItem from "./TranSafe/TranSafeItem";
import { OpayoItem } from "./Opayo/OpayoItem";
import { HtmlTitle } from "../../common/HtmlTitle";
import { tPages, tPaymentProviders } from "../../../i18n";
import { CommonBreadcrumbs } from "../../common/Breadcrumbs/Breadcrumbs";
import { TrueLayerProviderName } from "../../../models/paymentProviders/trueLayer/trueLayerModels";
import { TrueLayerItem } from "./TrueLayer/TrueLayer";
import { TotalProcessingProviderName } from "../../../models/paymentProviders/totalProcessing/totalProcessingModels";
import TotalProcessingItem from "./TotalProcessing/TotalProcessingItem";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getPaymentProviders } from "../../../services/paymentProviders/paymentProviders.service";
import { PaymentProvider } from "../../../models/paymentProviders/paymentProvider";
import { GoCardlessProviderName } from "../../../models/paymentProviders/goCardless/goCardlessModels";
import GoCardlessAuthorise from "./GoCardless/GoCardlessAuthorise";

const PaymentProviderNewItem = (): ReactElement => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  const { t } = useTranslation([tPaymentProviders.ns, tPages.ns]);
  const { data } = useQuery(QueryNameConsts.PaymentProviderTypes, () => getPaymentProviders());
  const [paymentProviderTranSafe, setPaymentProviderTranSafe] = useState<PaymentProvider>();
  const [paymentProviderOpayo, setPaymentProviderOpayo] = useState<PaymentProvider>();
  const [paymentProviderTrueLayer, setPaymentProviderTrueLayer] = useState<PaymentProvider>();
  const [paymentProviderTotalProcessing, setPaymentProviderTotalProcessing] = useState<PaymentProvider>();

  const getQueryPaymentProviderType = useCallback(() => {
    const providerTranSafeType = data?.find((d) => d.providerName === "TranSafe");
    setPaymentProviderTranSafe(providerTranSafeType);
    const providerOpayoType = data?.find((d) => d.providerName === "Opayo");
    setPaymentProviderOpayo(providerOpayoType);
    const providerTrueLayerType = data?.find((d) => d.providerName === "TrueLayer");
    setPaymentProviderTrueLayer(providerTrueLayerType);
    const providerTotalProcessingType = data?.find((d) => d.providerName === "TotalProcessing");
    setPaymentProviderTotalProcessing(providerTotalProcessingType);
  }, [data]);

  useEffect(() => {
    getQueryPaymentProviderType();
  }, [getQueryPaymentProviderType]);

  // Build the provider component
  const ProviderComponent = useMemo(() => {
    const handleOnSuccessful = () => {
      history.push(SettingsRouteConsts.PaymentProviders);
    };
    switch (type) {
      case StripeProviderName:
        return <StripeStartConnect />;

      case GoCardlessProviderName:
        return <GoCardlessAuthorise />;

      case TranSafeProviderName:
        return <TranSafeItem mode='add' paymentProvider={paymentProviderTranSafe} onSave={handleOnSuccessful} />;

      case OpayoProviderName:
        return <OpayoItem mode='add' paymentProvider={paymentProviderOpayo} onSave={handleOnSuccessful} />;

      case TrueLayerProviderName:
        return <TrueLayerItem mode='add' paymentProvider={paymentProviderTrueLayer} onSave={handleOnSuccessful} />;

      case TotalProcessingProviderName:
        return <TotalProcessingItem mode='add' paymentProvider={paymentProviderTotalProcessing} onSave={handleOnSuccessful} />;

      default:
        return <p>{t("paymentProvidersPage.messageUnknownProvider", tPages.ns)}</p>;
    }
  }, [
    history,
    paymentProviderOpayo,
    paymentProviderTotalProcessing,
    paymentProviderTranSafe,
    paymentProviderTrueLayer,
    t,
    type,
  ]);

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentProviders", tPages)} subTitle2={t("paymentProviders.titleNewConfiguration")} />
      <Breadcrumb>
        <CommonBreadcrumbs.PaymentProviders />
      </Breadcrumb>
      {ProviderComponent}
    </>
  );
};

export default PaymentProviderNewItem;
