import { ReactElement } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DefaultBlankText } from "../../common/commonConsts";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import OverdueDate from "../../components/OverdueDate/OverdueDate";
import { PanelItem } from "../../components/panels/Panels";
import { textOrDefault } from "../../helpers/stringHelpers";
import { getOutstandingAmount } from "../../helpers/transactions/transactionHelper";
import { tPages } from "../../i18n";
import { BadgeSize } from "../../models/misc/badgeSize";
import { Transaction } from "../../models/transactions/transaction";
import { DateOutputType } from "../../utils/dateFormatter";
import { PaidStatusTag } from "./PaidStatusTag";
import TransactionTypeTag from "./TransactionTypeTag";
import useDateFormatter from "../../common/hooks/useDateFormatter";
import useMe from "../../common/hooks/useMe";

const keyPrefix = "transactionPage.details";
/*
 * The main details card
 */
const TransactionDetails = ({ transaction }: { transaction: Transaction }): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const { me } = useMe();
  const dateFormatter = useDateFormatter();

  return (
    <Card className='custom-detail-card'>
      <Card.Body>
        <Card.Title className='details-title'>
          <span>{t("title")}</span>
          <div className='tag-items'>
            <TransactionTypeTag className='tag' type={transaction.transactionType} name={transaction.transactionTypeName} />
          </div>
        </Card.Title>
        <Card.Text as='div'>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className='main-item'>
                <div className='title'>{t("outstandingAmount")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem
                    status={transaction.paidStatus}
                    currency={transaction.currency}
                    value={getOutstandingAmount(transaction)}
                    allowZero
                  />
                </div>
                <PaidStatusTag className='tag-end' status={transaction.paidStatus} size={BadgeSize.Small} />
              </div>
              <div className='main-item'>
                <div className='title'>{t("transactionAmount")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem currency={transaction.currency} value={transaction.transactionAmount} />
                </div>
              </div>
              <div className='main-item'>
                <div className='title'>{t("discountedAmount")}</div>
                <div className='amount-to-pay'>
                  <CurrencyItem currency={transaction.currency} value={transaction.discountedAmount} />
                </div>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <PanelItem title={t("transactionDate")}>{dateFormatter.toShortDate(transaction.transactionDate)}</PanelItem>
              <PanelItem title={t("dueDate")}>
                <OverdueDate date={transaction.dueDate} outputType={DateOutputType.ShortDate} blankText={DefaultBlankText} />
              </PanelItem>
              <PanelItem title={t("transactionReference")}>{transaction.transactionReference}</PanelItem>
              <PanelItem title={t("secondReference")}>{textOrDefault(transaction.transactionSecondReference)}</PanelItem>
              <PanelItem title={t("orderReference")}>{textOrDefault(transaction.orderReference)}</PanelItem>
            </Col>
          </Row>
          <Row className='g-0'>
            <hr />
            <div className='main-item'>
              <div className='title'>{t("description")}</div>
              <div>{textOrDefault(transaction.description)}</div>
            </div>
            <div className='main-item'>
              <div className='title'>{t("paymentTerms")}</div>
              <div>{textOrDefault(transaction.paymentTermsDescription)}</div>
            </div>
            <div className='main-item'>
              <div className='title'>{t("postingApplication")}</div>
              <div>{textOrDefault(transaction.postingApplication)}</div>
            </div>
            {me?.userSetting.showAdvancedInfo && (
              <div className='main-item'>
                <div className='title'>{t("customHostId")}</div>
                <div>{textOrDefault(transaction.customHostId)}</div>
              </div>
            )}
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TransactionDetails;
