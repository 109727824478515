import { ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { tPages } from "../../i18n";
import { SettlementConfigurationGroup } from "../../models/settlements/settlementConfigurationGroup";
import { addSettlementConfigurationGroup } from "../../services/settlements/settlementConfiguration.service";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import { HtmlTitle } from "../common/HtmlTitle";
import { PageTitle } from "../common/PageTitle";
import { MainRouteConsts } from "../MainRoutesConsts";
import SettlementConfigurationGroupForm, { SettlementConfigurationGroupFormModel } from "./SettlementConfigurationGroupForm";

/*
 * Handles a new settlement configuration group request
 */
const NewSettlementConfigurationItem = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const configurationGroup = {} as SettlementConfigurationGroup;
  configurationGroup.customerEnabled = true;

  // Creates a new configuration group record before redirecting to the edit form
  const handleFormSave = async (model: SettlementConfigurationGroupFormModel): Promise<void> => {
    const newConfigurationGroup = {
      name: model.name.trim(),
      description: model.description.trim(),
      customerEnabled: model.customerEnabled,
      transactionEnabled: model.transactionEnabled,
    } as SettlementConfigurationGroup;
    const result = await addSettlementConfigurationGroup(newConfigurationGroup);
    history.replace(`${MainRouteConsts.Settlements}/${result.id}`);
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleSettlement", tPages)} />
      <Breadcrumb>
        <CommonBreadcrumbs.SettlementConfigurations />
      </Breadcrumb>
      <section className='item-view-section'>
        <PageTitle title='pageTitleSettlement' />
        <SettlementConfigurationGroupForm configurationGroup={configurationGroup} onSave={handleFormSave} />
      </section>
    </>
  );
};

export default NewSettlementConfigurationItem;
