import i18next from 'i18next';
import { ReactElement } from 'react';
import { Badge } from 'react-bootstrap';
import { tPages } from '../../../i18n';
import { PaymentAllocation } from '../../../models/paymentAllocations/paymentAllocation';

/*
 * Creates the Payment Allocations title
 */
export const CreatePaymentAllocationsTitle = (
  paymentAllocations: PaymentAllocation[] | undefined
): ReactElement => {
  const pa = paymentAllocations;
  const value = pa ? pa.length.toString() : '';
  const title = i18next.t('paymentAllocations.paymentAllocationsTabTitle', {
    numberOfAllocations: value,
    ...tPages,
  });
  return (
    <>
      {title}
      &nbsp;
      <Badge className='tab-badge'>{value}</Badge>
    </>
  );
};

CreatePaymentAllocationsTitle.defaultProps = {
  latePaymentTransactions: undefined,
};

export default CreatePaymentAllocationsTitle;
