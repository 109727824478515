// contains: "ct",
// notContains: "nct",
// eq: "eq",
// neq: "neq",
// gt: "gt",
// gte: "ge",
// lt: "lt",
// lte: "le",
// startsWith: "sw",
// endsWith: "ew",

export type FilteringOperator = "ct" | "nct" | "eq" | "neq" | "gt" | "ge" | "lt" | "le" | "sw" | "ew";

/*
 *  Builds a filter string for the query
 */
export const filterItemBuilder = (name: string, operation: FilteringOperator, condition: string): string =>
  `${name} ${operation} ${condition}`;
