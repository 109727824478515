import { LocalStorageProvider } from "../common/storage/localStorageProvider";
import authConstants, { DefaultDatasetId } from "../services/auth/auth.constants";

const localStorage = new LocalStorageProvider();

/*
 * Dataset Storage Helpers
 */
const DatasetIdStorageKey = authConstants.DATASET_ID;
const datasetIdStorage = localStorage;

/*
 * Custom event name for when a dataset id is changed
 */
export const DatasetCustomEventName = "local-storage-datasetid";

/*
 * Gets the dataset id from storage
 */
export const getDatasetIdFromStorage = (): number => datasetIdStorage.getItem<number>(DatasetIdStorageKey) || DefaultDatasetId;

/*
 * Sets the dataset id from storage
 */
export const setDatasetIdToStorage = (id: number): void => {
  datasetIdStorage.setItem(DatasetIdStorageKey, id);
};
