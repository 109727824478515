import { Bank } from "../../models/banking/bankModels";
import { BankingApi } from "../../common/api/payThemApi/bankingApi";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

/*
 * Gets the bank with the given Id
 */
export const getBank = async (id: string): Promise<Bank | undefined> => {
  const api = new BankingApi();
  try {
    const result = await api.getBank(id);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "bank.bankGet");
  }
  return undefined;
};

export default getBank;
