import lang from "i18next";
import NotificationsApi from "../../common/api/payThemApi/notificationsApi";
import { tCommon } from "../../i18n";
import { CreateNotificationUser, NotificationUser } from "../../models/notifications/notificationUser";
import { toastSuccess } from "./toastNotification.service";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";
import {
  notificationsUserIncludeNone,
  notificationsUserIncludeAll,
} from "../../common/api/payThemApi/notificationUserIncludeOptions";

const LangOptions = tCommon;

/*
 * Adds a new user group
 */
export const addNotificationUser = async (user: CreateNotificationUser): Promise<NotificationUser> => {
  try {
    const api = new NotificationsApi();
    const result = await api.addUser(user);
    toastSuccess(lang.t("toastMessages.notifications.userAddSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "notifications.userAdd");
    throw error;
  }
};

/*
 * Returns all notification users
 */
export const getAllNotificationUsers = async (includeSettings: boolean): Promise<NotificationUser[]> => {
  try {
    const api = new NotificationsApi();
    const result = await api.getAllUsers(includeSettings ? notificationsUserIncludeAll : notificationsUserIncludeNone);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "notifications.usersGet");
    throw error;
  }
};

/*
 * Returns the customer notification settings
 */
export const getCustomerNotifications = async (includeSettings: boolean): Promise<NotificationUser> => {
  try {
    const api = new NotificationsApi();
    const result = await api.getCustomerNotifications(
      includeSettings ? notificationsUserIncludeAll : notificationsUserIncludeNone
    );
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "notifications.customerGet");
    throw error;
  }
};

/*
 * Deletes a notification user
 */
export const deleteNotificationUser = async (id: string): Promise<void> => {
  try {
    const api = new NotificationsApi();
    await api.deleteUser(id);
    toastSuccess(lang.t("toastMessages.notifications.userDeleteSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, true, "notifications.usersDelete");
    throw error;
  }
};

/*
 * Updates a notification user
 */
export const updateNotificationUser = async (user: NotificationUser): Promise<NotificationUser> => {
  try {
    const api = new NotificationsApi();
    const result = await api.updateUser(user);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "notifications.usersUpdate");
    throw error;
  }
};
