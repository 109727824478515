import { ReactElement } from "react";
import DraycirSpinner from "../Spinners/DraycirSpinner";
import "./DataGridLoadingMask.scss";

interface RenderLoadMaskProps {
  visible: boolean;
  zIndex: number;
}

/*
 * Replacement loading spinner for the DataGrid
 */
const DataGridLoadingMask = ({ visible, zIndex }: RenderLoadMaskProps): ReactElement =>
  visible ? (
    <div className='loading-mask' style={{ zIndex }}>
      <DraycirSpinner />
    </div>
  ) : (
    <></>
  );

export default DataGridLoadingMask;
