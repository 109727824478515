import { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { tForms } from "../../../i18n";
import { ImportResults } from "../../../models/paymentTransactions/importResults";
import { importAllocationStatus } from "../../../services/paymentTransactions/paymentTransactions.service";
import ImportResultsDialog from "./ImportResultsDialog";
import usePermission from "../../../common/hooks/usePermission";
import UserPermission from "../../../models/users/userPermission";

/*
 * Import allocated status button
 */
const ImportAllocatedStatusButton = (): ReactElement => {
  const { t } = useTranslation([tForms.ns]);
  const [show, setShow] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileUpload = useRef<HTMLInputElement>(null);
  const [fileSelection, setFileSelection] = useState<string>("");
  const [importResults, setImportResults] = useState<ImportResults>();
  const disabled = !usePermission(UserPermission.ImportAllocatedStatus); 

  const handleShowDialog = () => {
    fileUpload?.current?.click();
  };

  const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setImportResults(undefined);
    setUploading(true);
    const file = event.currentTarget.files?.item(0);
    try {
      if (file) {
        const results = await importAllocationStatus(file);
        setImportResults(results);
      }
    } finally {
      setShow(true);
      setFileSelection("");
      setUploading(false);
    }
  };

  return (
    <>
      <SpinnerButton title={t("buttonImport")} showSpinner={uploading} onClick={handleShowDialog} disabled={disabled} />
      <input type='file' ref={fileUpload} onChange={handleUploadFile} value={fileSelection} style={{ display: "none" }} />
      <ImportResultsDialog importResults={importResults} show={show} onHide={() => setShow(false)} />
    </>
  );
};

ImportAllocatedStatusButton.defaultProps = {};

export default ImportAllocatedStatusButton;
