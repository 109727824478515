/* eslint-disable @typescript-eslint/no-unused-vars */
import GoCardlessWebApi from "../../common/api/payThemApi/goCardlessApi";
import ConnectResult from "../../models/paymentProviders/goCardless/connectResult";
import { UrlUtils } from "../../utils/urlUtils";

const GoCardlessAuthoriseUrl = "https://connect-sandbox.gocardless.com/oauth/authorize";
const GoCardlessAuthoriseSandboxUrl = "https://connect-sandbox.gocardless.com/oauth/authorize";

/*
 * Our callback URL we pass to the GoCardless Connect Url
 */
export const GoCardlessOAuthCallbackRoute = "/gocardless/connect/oauth";

/*
 * Type of connect we're performing
 */
export enum GoCardlessConnectType {
  Add,
  Edit,
}

export class GoCardlessAuthService {
  private api = new GoCardlessWebApi();

  /*
   * Returns our client id
   */
  public static get clientId(): string {
    return process.env.REACT_APP_GOCARDLESS_CLIENTID || "";
  }

  /*
   * Creates the URL to start the Stripe connection processes
   */
  private static createAuthorisationUrl(type: GoCardlessConnectType, state?: string, useSandbox: boolean = true): string {
    let redirectUrl = UrlUtils.getAbsoluteDomainUrl();

    redirectUrl = redirectUrl.replace(/\/?$/, "") + GoCardlessOAuthCallbackRoute;

    const params = new URLSearchParams();
    params.append("client_id", GoCardlessAuthService.clientId);
    params.append("scope", "read_write");
    params.append("response_type", "code");
    params.append("state", `${state ?? ""}:${type}`);
    params.append("redirect_uri", redirectUrl);
    return `${useSandbox ? GoCardlessAuthoriseUrl : GoCardlessAuthoriseSandboxUrl}?${params.toString()}`;
  }

  /*
   * Starts the GoCardless authroisation process by redirecting to GoCardless
   */
  public static startAuthorise(type: GoCardlessConnectType, configurationId?: string): void {
    const redirectUrl = GoCardlessAuthService.createAuthorisationUrl(type, configurationId, true);
    window.location.replace(redirectUrl);
  }

  /*
   * Completes the GoCardless authroisation process by creating a new Payment Provider or updating a current one
   */
  // eslint-disable-next-line class-methods-use-this
  public async completeConnection(state: string, code: string): Promise<ConnectResult> {
    const connectResult = await this.api.completeAuthorisation(code);
    const stateItems = state.split(":");
    if (stateItems.length < 2) throw new Error("State should contain 2 items");

    let id: string;
    const connectType: GoCardlessConnectType = parseInt(stateItems[1], 10);
    if (connectType === GoCardlessConnectType.Add) {
      id = "1";
      // id = await this.createConfigurationForStripeAccount(stripeAccountId);
    } else {
      // eslint-disable-next-line prefer-destructuring
      id = stateItems[0];
      // await StripeConnectService.updateConfigurationForStripeAccount(id, stripeAccountId);
    }
    return connectResult;
  }
}
