export enum PaymentMethodType {
  // Credit cards/debit cards
  Card = "Card",

  // Direct bank to bank transfer
  BankTransfer = "BankTransfer",

  // Direct Debit
  BankDebit = "BankDebit",
}

export const PaymentMethodTypeMap = new Map<PaymentMethodType, string>([
  [PaymentMethodType.Card, "Card"],
  [PaymentMethodType.BankTransfer, "Bank Transfer"],
  [PaymentMethodType.BankDebit, "Bank Debit"],
]);

export const PaymentMethodTypeArray = Array.from(PaymentMethodTypeMap.entries());
