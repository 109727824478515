import lang from "i18next";
import { Role } from "../../models/users/role";
import { User, UserMe, UserSetting, UserSettingMe } from "../../models/users/user";
import { UsersApi } from "../../common/api/payThemApi/usersApi";
import { toastSuccess, toastError } from "../notifications/toastNotification.service";
import * as regionalFormats from "../../assets/data/regional-formats.json";
import { tCommon } from "../../i18n";

const tOptions = tCommon;
const redirectUrl = (): string => process.env.REACT_APP_USER_REDIRECT_URL || "";

/*
 * Maps the user settings, converting the stored long date format into something JS can use
 */
const mapAndConvertUserSettings = (userSettings: UserSetting): UserSettingMe => {
  // Map the long date format to the JS version
  const format = regionalFormats.longDate.find((x) => x.format === userSettings.longDateFormat);
  const longDateFormat = format?.jsFormat ?? regionalFormats.longDate[0].jsFormat;

  const userSettingMe = {
    shortDateFormat: userSettings.shortDateFormat,
    longDateFormat,
    use24Hour: userSettings.use24Hour,
    timeZoneId: userSettings.timeZoneId,
    showAdvancedInfo: userSettings.showAdvancedInfo,
  } as UserSettingMe;
  return userSettingMe;
};

/*
 * Maps the User object to a UserMe
 */
export const mapUserToUserMe = (user: User): UserMe =>
({
  id: user.id,
  userName: user.userName,
  displayName: user.displayName,
  forename: user.forename,
  surname: user.surname,
  emailAddress: user.emailAddress,
  role: user.role,
  userSetting: mapAndConvertUserSettings(user.userSetting),
  permissions: user.permissions,
} as UserMe);

/*
 * Validates the user details before creating
 */
const validateNewUserDetails = (name: string, emailAddress: string, role: Role, users: User[]): boolean => {
  if (users.some((x) => x.emailAddress.localeCompare(emailAddress, undefined, { sensitivity: "accent" }) === 0)) {
    toastError(lang.t("User with the same email address already exists.", tOptions));
    return false;
  }
  if (users.some((x) => x.displayName.localeCompare(name, undefined, { sensitivity: "accent" }) === 0)) {
    toastError(lang.t("User with the same name already exists.", tOptions));
    return false;
  }
  return true;
};

/*
 * Validates the user details before updating
 */
const validateUpdateUserDetails = (id: string, name: string, role: Role, users: User[]): boolean => {
  if (users.some((x) => x.displayName.localeCompare(name, undefined, { sensitivity: "accent" }) === 0 && x.id !== id)) {
    toastError(lang.t("User with the same name already exists.", tOptions));
    return false;
  }
  return true;
};

/*
 * Returns all users
 */
export const getUsers = async (): Promise<User[]> => {
  const api = new UsersApi();
  try {
    const result = await api.getAllUsers();
    return result;
  } catch (error) {
    toastError("An error occurred while getting the users");
    throw error;
  }
};

/*
 * Creates and invites a new user
 */
export const createUser = async (name: string, emailAddress: string, role: Role): Promise<User | undefined> => {
  const api = new UsersApi();
  const users = await api.getAllUsers();

  if (!validateNewUserDetails(name, emailAddress, role, users)) return undefined;

  try {
    const newUser = await api.createUser(name, emailAddress, role, redirectUrl());
    toastSuccess("New user successfully created and invited");
    return newUser;
  } catch (error) {
    toastError(lang.t(`An error occurred while creating the user: ${error}`, tOptions));
    return undefined;
  }
};

/*
 * Updates an existing user
 */
export const updateUser = async (id: string, name: string, role: Role): Promise<void> => {
  const api = new UsersApi();
  const users = await api.getAllUsers();

  if (!validateUpdateUserDetails(id, name, role, users)) return undefined;

  try {
    await api.updateUserDetails(id, name);
    await api.updateUserRole(id, role);
    toastSuccess("User successfully updated");
  } catch (error) {
    toastError(lang.t(`An error occurred while updating the user: ${error}`, tOptions));
  }
  return undefined;
};

/*
 * Deletes an existing user
 */
export const deleteUser = async (id: string): Promise<void> => {
  const api = new UsersApi();
  try {
    await api.deleteUser(id);
    toastSuccess("User successfully deleted");
  } catch (error) {
    toastError(lang.t(`An error occurred while deleting the user: ${error}`, tOptions));
  }
};

/*
 * Updates the 'me' user settings
 */
export const updateMySettings = async (settings: UserSettingMe): Promise<User> => {
  const api = new UsersApi();
  try {
    const result = await api.updateMySettings(settings);
    toastSuccess("User settings successfully updated");
    return result;
  } catch (error) {
    toastError("An error occurred while updating the user settings");
    throw error;
  }
};
