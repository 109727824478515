/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { tForms } from "../../../../i18n";
import {
  CustomHostIdCreateOption,
  CustomHostIdCreateOptionTextIndexMap,
  CustomHostIdSettings,
  CustomHostIdUpdateOptionTextIndexMap,
} from "../../../../models/companySettings/customHostIdSettings";
import { FormSaveRef } from "../preferencesTypes";
import {
  getCustomHostIdSettings,
  testCustomHostIdSetting,
  updateCustomHostIdSettings,
} from "../../../../services/settings/settings.service";

import PageLoading from "../../../../components/loading/PageLoading";
import BooleanFormSelect from "../../PaymentProviders/ProvidersCommon/BooleanFormSelect";
import { SpinnerButton } from "../../../../components/buttons/SpinnerButton";
import { rebuildCustomHostId } from "../../../../services/transactions/transactions.service";
import { ConfirmationDialog } from "../../../../components/dialogs/ConfirmationDialog";
import "./CustomHostIdSettingsForm.scss";
import { isCustomHostIdBuilding } from "../../../../helpers/settings/customHostIdSettingsHelper";

const keyPrefix = "preferences.cusomtHostIdSettings";

const CustomHostIdType = "transaction";

interface FormModel {
  createOption: CustomHostIdCreateOption;
  updateOption: CustomHostIdCreateOption;
  parseValue: string;
  trimWhiteSpace: boolean;
}

const validationSchema = Yup.object({
  parseValue: Yup.string().label("Custom Host Id Value").required().max(500),
  trimWhiteSpace: Yup.boolean().required(),
});

/*
 * Edit the custom host id settings
 */
const CustomHostIdSettingsForm = forwardRef<FormSaveRef | undefined>((props, ref) => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const [customHostIdSettings, setCustomHostIdSettings] = useState<CustomHostIdSettings>();
  const [testResultMessage, setTestResultMessage] = useState<string>();
  const [rebuildSpinner, setRebuildSpinner] = useState<boolean>(false);
  const [showRebuildDialog, setShowRebuildDialog] = useState<boolean>(false);

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
  });

  // Called when the rebuild button is clicked and the user confirms
  const handleRebuild = async (): Promise<void> => {
    setShowRebuildDialog(false);
    setRebuildSpinner(true);
    await rebuildCustomHostId();
    setRebuildSpinner(false);
  };

  // Called when the user clicks the test button
  const handleTest = async (): Promise<boolean> => {
    setTestResultMessage(undefined);
    if (await trigger()) {
      const value = getValues("parseValue");
      const result = await testCustomHostIdSetting(CustomHostIdType, value);
      if (!result) return false;
      if (result.isValid === false) {
        setError("parseValue", { message: result.errorMessage });
        return false;
      }
      setTestResultMessage(result?.customHostId);
      return true;
    }
    handleSubmit(() => true)();
    return false;
  };

  useImperativeHandle(ref, () => ({
    validate: () => handleTest(),
    save: async () => {
      await handleSubmit(async (data) => {
        const settings = { ...data, id: CustomHostIdType } as CustomHostIdSettings;
        await updateCustomHostIdSettings(settings, true);
      })();
    },
  }));

  // Loads in the custom host Id settings
  useEffect(() => {
    getCustomHostIdSettings(CustomHostIdType).then((result) => {
      setCustomHostIdSettings(result);
      if (result) {
        const building = isCustomHostIdBuilding(result);
        setRebuildSpinner(building);
      }
    });
  }, []);

  if (!customHostIdSettings) return <PageLoading />;

  const building = isCustomHostIdBuilding(customHostIdSettings);
  const buttonName = building ? "buttonBuilding" : "buttonTriggerRebuild";

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t("titleCustomHostId")}</Card.Title>
        <div className='warning mt-2 mb-4'>{t("messageCustomHostIdWarning")}</div>
        <Card.Text as='div'>
          <Row>
            <Form.Group as={Col} sm='3'>
              <Form.Label>{t("labelCreation")}</Form.Label>
              <Form.Select defaultValue={customHostIdSettings.createOption} {...register("createOption")}>
                {Array.from(CustomHostIdCreateOptionTextIndexMap.entries()).map((option) => (
                  <option key={option[0]} value={option[0]}>
                    {t(option[1])}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} sm='3'>
              <Form.Label>{t("labelUpdate")}</Form.Label>
              <Form.Select defaultValue={customHostIdSettings.updateOption} {...register("updateOption")}>
                {Array.from(CustomHostIdUpdateOptionTextIndexMap.entries()).map((option) => (
                  <option key={option[0]} value={option[0]}>
                    {t(option[1])}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} sm='3'>
              <Form.Label>{t("labelTrimWhiteSpace")}</Form.Label>
              <Col xs='1'>
                <BooleanFormSelect
                  register={register}
                  fieldName='trimWhiteSpace'
                  trueOption={t("labelYes")}
                  falseOption={t("labelNo")}
                  defaultState={customHostIdSettings.trimWhiteSpace}
                />
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <ConfirmationDialog
                show={showRebuildDialog}
                title={t("titleRebuildCustomHostId")}
                body={<div className='multiline'>{t("confirmRebuildCustomHostId")}</div>}
                handleClose={() => setShowRebuildDialog(false)}
                handleConfirmation={() => handleRebuild()}
              />
              <SpinnerButton title={t(buttonName)} showSpinner={rebuildSpinner} onClick={() => setShowRebuildDialog(true)} />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Form.Group as={Col} sm='12'>
              <Form.Label>{t("labelParseValue")}</Form.Label>
              <div className='d-flex gap-2'>
                <Col sm='11'>
                  <Form.Control
                    size='sm'
                    type='text'
                    placeholder={t("placeholderParseValue")}
                    {...register("parseValue")}
                    className={`m-0${errors?.parseValue?.message ? " is-invalid" : ""}`}
                    defaultValue={customHostIdSettings.parseValue}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.parseValue?.message}</Form.Control.Feedback>
                </Col>
                <Col>
                  <Button variant='secondary' onClick={handleTest}>
                    {t("buttonTestParse")}
                  </Button>
                </Col>
              </div>
              {testResultMessage && (
                <div className='mt-3 example-result'>
                  <div className='title'>{t("labelExampleResult")}</div>
                  <div className='body'>{testResultMessage}</div>
                </div>
              )}
            </Form.Group>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
});
export default CustomHostIdSettingsForm;
