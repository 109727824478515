import { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DatasetDialog, DatasetFormModel } from "./DatasetDialog";
import { RegisterDataset } from "../../../models/datasets/registerDataset";
import { tDatasets } from "../../../i18n";
import { DatasetType } from "../../../models/datasets/datasetEnums";
import { getAllDatasets, registerDataset } from "../../../services/datasets/dataset.service";
import UserPermission from "../../../models/users/userPermission";
import usePermission from "../../../common/hooks/usePermission";

interface DefaultIds {
  hostId: string;
  customHostId: string;
}

/*
 * Creates the default hostId and customHostId for a new dataset
 */
const getDefaultIds = async (): Promise<DefaultIds> => {
  const pageResult = await getAllDatasets();

  let lastDatasetId = 1;
  if (pageResult && pageResult.items && pageResult.items.length > 0) {
    const datasets = pageResult.items;
    lastDatasetId = datasets.reduce((max, dataset) => (dataset.id > max ? dataset.id : max), datasets[0].id);
    let found = false;
    do {
      lastDatasetId += 1;
      const lastDatasetIdStr = lastDatasetId.toString();
      found = datasets.some((dataset) => dataset.hostId === lastDatasetIdStr && dataset.customHostId === lastDatasetIdStr);
    } while (found);
  }

  const hostId = lastDatasetId.toString();
  const customHostId = lastDatasetId.toString();

  return { hostId, customHostId };
};

interface AddDatasetButtonProps {
  onAdd: () => Promise<void>;
}

/*
 * Display an add dataset button which displays the add dataset dialog
 */
export const AddDatasetButton = ({ onAdd }: AddDatasetButtonProps): ReactElement => {
  const { t } = useTranslation([tDatasets.ns]);
  const [show, setShow] = useState<boolean>(false);
  const [enableDialog, setEnableDialog] = useState<boolean>(false);
  const [defaultIds, setDefaultIds] = useState<DefaultIds>({ hostId: "", customHostId: "" });
  const disabled = !usePermission(UserPermission.CreateCompanies);

  const handleOnSave = async (model: DatasetFormModel) => {
    setShow(false);

    const dataset: RegisterDataset = {
      type: model.demoDataset ? DatasetType.Demo : DatasetType.Production,
      name: model.name.trim(),
      shortName: model.shortName.trim(),
      description: model.description.trim(),
      hostSystemName: model.erp.trim(),
      hostId: model.hostId.trim(),
      customHostId: model.customHostId.trim(),
      hostDemoSystem: false,
      currency: model.currency,
      copyFromDatasetId: model.copyFromDatasetId,
    };
    await registerDataset(dataset);
    await onAdd();
  };

  const handleClick = () => {
    setShow(true);
    setEnableDialog(true);
    getDefaultIds().then((ids) => setDefaultIds(ids));
  };

  return (
    <>
      <Button variant='primary' onClick={handleClick} disabled={disabled}>
        {t("buttonAddDataset")}
      </Button>
      {enableDialog && (
        <DatasetDialog
          defaultHostId={defaultIds.hostId}
          defaultCustomHostId={defaultIds.customHostId}
          show={show}
          mode='add'
          onClose={() => setShow(false)}
          onClosed={() => setEnableDialog(false)}
          onSave={(model) => handleOnSave(model)}
          onResetData={() => true}
        />
      )}
    </>
  );
};

export default AddDatasetButton;
