import { ReactElement, ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getAccount } from "../../services/accounts/accounts.service";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { LoadingError } from "../../components/loading/LoadingError";
import { AccountLink } from "./AccountLink";
import "./AccountLink.scss";

interface AccountIdLinkProps {
  accountId: string | undefined | null;
  children?: ReactNode;
}

/*
 * Display a link to the account
 */
const AccountIdLink = ({ accountId, children }: AccountIdLinkProps): ReactElement => {
  const { data, isLoading, isError } = useQuery([QueryNameConsts.AccountItem, accountId], () =>
    accountId ? getAccount(accountId) : undefined
  );
  if (isLoading) return <Spinner animation='border' role='status' size='sm' />;
  if (isError) return <LoadingError />;
  return <AccountLink account={data}>{children}</AccountLink>;
};

AccountIdLink.defaultProps = {
  children: undefined,
};
export default AccountIdLink;
