import React, { ReactElement } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmailContact } from '../../models/contacts/emailContact';
import { textOrDefault } from '../../helpers/stringHelpers';
import { tPages } from '../../i18n';

const keyPrefix = 'accountPage.emailContacts';

// // For Testing
// const addresses: EmailContact[] = [
//   { type: "primary", emailAddress: "richard.reaveley@draycir.com", isDefault: true, isValid: true },
//   { type: "", emailAddress: "richard.reaveley@gmail.com", isDefault: false, isValid: true },
//   { type: "", emailAddress: "richard.reaveley@draycir.com", isDefault: false, isValid: true },
//   { type: "", emailAddress: "sales@draycir.com", isDefault: false, isValid: true },
// ];

export const EmailAddressesTable = ({
  emailContacts,
}: {
  emailContacts: EmailContact[];
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  return (
    <>
      <Table borderless striped className='panel-table'>
        <thead>
          <tr>
            <td>{t('emailType')}</td>
            <td>{t('emailAddress')}</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {emailContacts
            //              .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
            .map((item) => (
              <tr key={item.emailAddress}>
                <td>{textOrDefault(item.type)}</td>
                <td>{textOrDefault(item.emailAddress)}</td>
                <td align='center'>{item.isDefault && 'Default'}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

/*
 * Emails contacts details card
 */
export const EmailAddressesPanel = ({
  emailContacts,
}: {
  emailContacts: EmailContact[] | null | undefined;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title>{t('title')}</Card.Title>
          <Card.Text as='div'>
            {emailContacts && emailContacts.length > 0 ? (
              <Row>
                <Col>
                  <EmailAddressesTable emailContacts={emailContacts} />
                </Col>
              </Row>
            ) : (
              <div>{t('noEmailAddressesFound')}</div>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
