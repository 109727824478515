import lang from "i18next";
import { AccessKeysApi } from "../../common/api/payThemApi/accessKeysApi";
import { AccessKey } from "../../models/accessKeys/accessKey";
import { toastSuccess } from "../notifications/toastNotification.service";
import { AccessKeyAdd } from "../../models/accessKeys/accessKeyAdd";
import { NewAccessKey } from "../../models/accessKeys/newAccessKey";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = { ns: "common" };

/*
 * Returns all access keys
 */
export const getAccessKeyCount = async (): Promise<number> => {
  const api = new AccessKeysApi();
  try {
    const result = await api.countKeys();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "accessKeys.accessKeyGet");
    throw error;
  }
};

/*
 * Returns all access keys
 */
export const getMaximumAccessKeyCount = async (): Promise<number> => {
  const api = new AccessKeysApi();
  try {
    const result = await api.maximumNumberOfKeys();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "accessKeys.accessKeyGet");
    throw error;
  }
};

/*
 * Returns all access keys
 */
export const getAccessKeys = async (): Promise<AccessKey[]> => {
  const api = new AccessKeysApi();
  try {
    const result = await api.getAccessKeys();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "accessKeys.accessKeyGet");
    throw error;
  }
};

/*
 * Creates a new access key
 */
export const createAccessKey = async (accessKeyAdd: AccessKeyAdd): Promise<NewAccessKey | undefined> => {
  const api = new AccessKeysApi();

  try {
    const newAccessKey = await api.createAccessKey(accessKeyAdd);
    toastSuccess(lang.t("toastMessages.accessKeys.accessKeyAddSuccess", LangOptions));
    return newAccessKey;
  } catch (error) {
    handleApiErrorWithToast(error, true, "accessKeys.accessKeyCreate");
    return undefined;
  }
};

/*
 * Updates an existing access key
 */
export const updateAccessKey = async (id: string, name: string, validUntil?: Date): Promise<void> => {
  const api = new AccessKeysApi();

  try {
    await api.updateAccessKey(id, name, validUntil);
    toastSuccess(lang.t("toastMessages.accessKeys.accessKeyUpdateSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, true, "accessKeys.accessKeyUpdate");
  }
  return undefined;
};

/*
 * Deletes an existing user
 */
export const deleteAccessKey = async (id: string): Promise<void> => {
  const api = new AccessKeysApi();
  try {
    await api.deleteAccessKey(id);
    toastSuccess(lang.t("toastMessages.accessKeys.accessKeyDeleteSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, true, "accessKeys.accessKeyDelete");
  }
};