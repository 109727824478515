import SvixWebApi from "../../common/api/payThemApi/svixWebApi";
import { DashboardAccessResult } from "../../models/integrations/svix/svixModels";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

/*
 * Gets the url to access the svix dashboard
 */
// eslint-disable-next-line import/prefer-default-export
export const getDashboardUrl = async (): Promise<DashboardAccessResult> => {
  try {
    const api = new SvixWebApi();
    const result = await api.getDashboardUrl();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "integrations.svix.dashboardAccessGet");
    throw error;
  }
};
