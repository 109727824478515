import { Role } from "../../../models/users/role";
import { User, UserSettingMe } from "../../../models/users/user";
import { UserInvite } from "../../../models/users/userInvite";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";

export class UsersApi extends BasePayThemApi {
  private endPoint = "users";

  // Returns the country with the given id
  public async getById(userId: string): Promise<User> {
    const result = await this.getItem<User, string>(this.endPoint, userId);
    return result;
  }

  // Returns the country with the given id
  public async getMe(): Promise<User> {
    const result = await this.get<User>(`${this.endPoint}/me`);
    return result;
  }

  // Returns an array of all countries
  public async getAllUsers(): Promise<User[]> {
    const result = await this.get<PageResult<User>>(`${this.endPoint}/all`);
    return result.items;
  }

  // Create a new user
  public async createUser(fullName: string, emailAddress: string, role: Role, redirectUrl: string): Promise<User> {
    const invite = { name: fullName, email: emailAddress, role, redirectUri: redirectUrl } as UserInvite;
    const result = await this.postWithResponse<UserInvite, User>(this.endPoint, invite);
    return result;
  }

  // Update the users name
  public async updateUserDetails(id: string, name: string): Promise<User> {
    const dto = { id, displayName: name };
    const result = await this.putWithResponse<unknown, User>(`${this.endPoint}/${id}`, dto);
    return result;
  }

  // Update the users role
  public async updateUserRole(id: string, role: Role): Promise<void> {
    const roleName = Role[role];
    const url = `${this.endPoint}/${id}/role?role=${encodeURIComponent(roleName)}`;
    await this.put(url, null);
  }

  // Deletes the user
  public async deleteUser(userId: string): Promise<void> {
    await this.deleteItem(this.endPoint, userId);
  }

  /*
   * Updates 'Me' user settings
   */
  public async updateMySettings(userSettings: UserSettingMe): Promise<User> {
    const url = `${this.endPoint}/me/settings`;
    const result = await this.putWithResponse<UserSettingMe, User>(url, userSettings);
    return result;
  }
}
export default UsersApi;
