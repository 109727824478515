import lang from "i18next";
import { TodoResult } from "./todoResult";
import { SeverityLevel } from "../../common/severityLevel";
import { getConfigurations } from "../paymentProviders/paymentProviders.service";
import { getCompanySettings } from "../settings/settings.service";
import { SettingsRouteConsts } from "../../views/SettingsRouteConsts";
import { tTodos } from "../../i18n";
import UserPermission from "../../models/users/userPermission";

/*
 * Creates any required payment provider todos
 */
export async function paymentProvidersTodo(): Promise<TodoResult[]> {
  const results: TodoResult[] = [];
  const configurations = await getConfigurations(1, 1);
  if (configurations.totalItems === 0) {
    results.push({
      severity: SeverityLevel.Critical,
      title: lang.t("todos.paymentProviders.titleCofigurePaymentProvider", tTodos),
      message: lang.t("todos.paymentProviders.messageCofigurePaymentProvider", tTodos),
      url: SettingsRouteConsts.PaymentProviders,
      requiredPermissionToFix: UserPermission.ConfigurePaymentProvider
    });
    return results;
  }

  const settings = await getCompanySettings();
  if (!settings?.paymentProvider) {
    results.push({
      severity: SeverityLevel.Critical,
      title: lang.t("todos.paymentProviders.titleSelectDefaultPaymentProvider", tTodos),
      message: lang.t("todos.paymentProviders.messageSelectDefaultPaymentProvider", tTodos),
      url: SettingsRouteConsts.PaymentProviders,
      requiredPermissionToFix: UserPermission.ConfigurePaymentProvider
    });
  }

  return results;
}

export default paymentProvidersTodo;
