import { Address } from "../models/contacts/address";
import { EmailContact } from "../models/contacts/emailContact";
import { CustomerDetails } from "../models/customers/customerDetails";

const DefaultSeparator = ", ";

export class CustomerFormatter {
  /*
   * Returns currency with value
   */
  public static getFullName(firstName: string | null | undefined, lastName?: string | null | undefined): string {
    let fullName = firstName?.trim();
    const internalLastName = lastName?.trim();
    if (internalLastName) {
      fullName += ` ${internalLastName}`;
    }
    return fullName ?? "";
  }

  public static fullAddressToStringFromCustomerDetails(customerDetails: CustomerDetails, separator?: string): string | null {
    const address = {
      address1: customerDetails.address1,
      address2: customerDetails.address2,
      address3: customerDetails.address3,
      address4: customerDetails.address4,
      city: customerDetails.city,
      state: customerDetails.state,
      postCode: customerDetails.postCode,
      country: customerDetails.country,
    } as Address;
    return CustomerFormatter.fullAddressToString(address, separator);
  }

  public static fullAddressToString(address: Address | null | undefined, separator?: string): string | null {
    if (!address) return null;
    let fullAddress = "";
    const append = (next?: string) => {
      if (!next) return;
      const nextLine = next.trim();
      if (nextLine.length === 0) return;
      if (fullAddress?.length > 0) fullAddress += separator ?? DefaultSeparator;
      fullAddress += nextLine;
    };

    append(address.address1);
    append(address.address2);
    append(address.address3);
    append(address.address4);
    append(address.city);
    append(address.state);
    append(address.postCode);
    append(address.country);

    return fullAddress.length > 0 ? fullAddress : null;
  }
}

/*
 * Returns the first address from an address array
 */
export function getFirstAddress(addresses: Address[] | null | undefined): Address | undefined {
  if (!addresses || addresses.length === 0) return undefined;
  return addresses[0];
}

/*
 * Returns the first address from an address array
 */
export function getFirstEmailAddress(emailAddresses: EmailContact[] | null | undefined): EmailContact | undefined {
  if (!emailAddresses || emailAddresses.length === 0) return undefined;
  return emailAddresses[0];
}
