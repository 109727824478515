/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";
import { getPaymentAmountFilterConfiguration } from "../../utils/itemFilterUtils";
import { ItemFilterControlProps } from "./ItemFilterFormModel";
import keyPrefix from "./keyPrefixConst";
import MinMaxAmountFilterControl from "./MinMaxAmountFilterControl";

interface MinMaxPaymentAmountFilterControlProps extends ItemFilterControlProps {}

/*
 * Min/max amount of the payment to be made
 */
const MinMaxPaymentAmountFilterControl = ({
  register,
  formState,
  filterGroup,
}: MinMaxPaymentAmountFilterControlProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { errors } = formState;

  // Check if we have any current filter
  const filterConfiguration = getPaymentAmountFilterConfiguration(filterGroup?.filterConfigurations);

  return (
    <MinMaxAmountFilterControl
      minAmountFieldName='minimumPaymentValue'
      amountLabel={t("labelPaymentAmount")}
      minAmountPlaceholder={t("placeholderMinimumPaymentAmount")}
      minAmountDefaultValue={filterConfiguration?.minimumPaymentValue}
      minAmountFieldError={errors.minimumPaymentValue}
      maxAmountFieldName='maximumPaymentValue'
      maxAmountPlaceholder={t("placeholderMaximumPaymentAmount")}
      maxAmountDefaultValue={filterConfiguration?.maximumPaymentValue}
      maxAmountFieldError={errors.maximumPaymentValue}
      register={register}
      formState={formState}
      filterGroup={filterGroup}
    />
  );
};

MinMaxPaymentAmountFilterControl.defaultProps = {
  filterGroup: undefined,
};

export default MinMaxPaymentAmountFilterControl;
