class GridNamesConsts {
  public static readonly PaymentRequestGrid = "payment-request-grid";

  public static readonly PaymentTransactionGrid = "payment-transaction-grid";

  public static readonly AccountGrid = "account-grid";

  public static readonly TransactionGrid = "transaction-grid";

  public static readonly BankGrid = "bank-grid";

  public static readonly PaymentPlanGrid = "payment-plan-grid";

  public static readonly SettlementGrid = "settlement-grid";

  public static readonly LatePaymentGrid = "late-payment-grid";

  public static readonly PaymentProviderGrid = "payment-provider-grid";

  public static readonly UsersGrid = "users-grid";

  public static readonly CompaniesGrid = "companies-grid";
}

export default GridNamesConsts;
