const enum PayThemApiClientType {
  // Does not require any authentication (i.e. no dataset or tenant)
  NoAuthentication,

  // Only requires a tenant
  Tenant,

  // Requires both a dataset and tenant
  DatasetAndTeanant,
}

export default PayThemApiClientType;
