import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { MainRouteConsts } from "../MainRoutesConsts";
import { DefaultBlankText } from "../../common/commonConsts";
import { Account } from "../../models/accounts/account";
import "./AccountLink.scss";

function getAccountString(account: Account): string {
  if (account.accountReference?.length > 0) return account.accountReference;
  if (account.name?.length > 0) return account.name;
  return account.id;
}

/*
 * Display a link to the account
 */
export const AccountLink = ({
  account,
  children,
}: {
  account: Account | undefined | null;
  children?: ReactNode;
}): ReactElement => {
  let internalChildren = children;

  if (!children && account) {
    internalChildren = <>{getAccountString(account)}</>;
  }
  return account?.id ? (
    <Link className='account-link' to={`${MainRouteConsts.Accounts}/${account.id}`}>
      {internalChildren}
    </Link>
  ) : (
    <>{DefaultBlankText}</>
  );
};

AccountLink.defaultProps = {
  children: undefined,
};
export default AccountLink;
