import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";
import {
  StripeProviderName,
  StripeProviderDisplayName,
  StripeSettings,
  StripeCredentials,
  StripePaymentProviderConfiguration,
  AddStripePaymentProviderConfiguration,
} from "../../../../models/paymentProviders/stripe/stripeModels";
import {
  ProviderFormModel,
  mapToAddPaymentProviderConfiguration,
  mapToPaymentProviderConfiguration,
} from "../ProvidersCommon/Common";

export interface StripeFormModel extends ProviderFormModel {
  useStripeCustomer: boolean;
  storePayerPaymentMethod: boolean;
  saveCardOptionDefault: boolean;
  stripeAccountId: string | undefined;
}

export const CurrentProviderName = StripeProviderName;
export const CurrentProviderDisplayName = StripeProviderDisplayName;
export const CurrentPaymentMethodType = PaymentMethodType.Card;
export type CurrentSettings = StripeSettings;
export type CurrentCredentials = StripeCredentials;
export type CurrentPaymentProviderConfiguration = StripePaymentProviderConfiguration;
export type CurrentAddPaymentProviderConfiguration = AddStripePaymentProviderConfiguration;
export type CurrentFormModel = StripeFormModel;

export const mapToAddConfiguration = (model: CurrentFormModel): CurrentAddPaymentProviderConfiguration => {
  const configuration = mapToAddPaymentProviderConfiguration<CurrentAddPaymentProviderConfiguration>(
    model,
    CurrentProviderName
  );
  configuration.settings = {
    providerName: CurrentProviderName,
    useStripeCustomer: model.useStripeCustomer,
    storePayerPaymentMethod: model.storePayerPaymentMethod,
    saveCardOptionDefault: model.saveCardOptionDefault,
  } as CurrentSettings;
  configuration.credentials = {
    providerName: CurrentProviderName,
    stripeAccountId: model.stripeAccountId,
  } as CurrentCredentials;
  return configuration;
};

export const mapToUpdateConfiguration = (
  data: CurrentPaymentProviderConfiguration,
  model: CurrentFormModel
): CurrentPaymentProviderConfiguration => {
  const configuration = mapToPaymentProviderConfiguration(data, model);
  if (!configuration.settings) {
    configuration.settings = { providerName: CurrentProviderName } as CurrentSettings;
  }
  if (!configuration.credentials) {
    configuration.credentials = { providerName: CurrentProviderName } as CurrentCredentials;
  }
  configuration.credentials.stripeAccountId = model.stripeAccountId;
  configuration.settings.useStripeCustomer = model.useStripeCustomer;
  configuration.settings.storePayerPaymentMethod = model.storePayerPaymentMethod;
  configuration.settings.saveCardOptionDefault = model.saveCardOptionDefault;
  return configuration;
};
