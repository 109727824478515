import {
  OpayoProviderName,
  OpayoProviderDisplayName,
  OpayoProviderModelConsts,
  OpayoSettings,
  OpayoCredentials,
  OpayoPaymentProviderConfiguration,
  AddOpayoPaymentProviderConfiguration,
  OpayoIntegrationType,
  VendorTxCodeType,
} from "../../../../models/paymentProviders/opayo/opayoModels";
import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";
import { getNonFillerDisplayPasswordOrUndefined } from "../../../../utils/secureStringUtils";
import {
  ProviderFormModel,
  mapToAddPaymentProviderConfiguration,
  mapToPaymentProviderConfiguration,
} from "../ProvidersCommon/Common";

export interface OpayoFormModel extends ProviderFormModel {
  // Type of integration we are configured for
  integrationType: OpayoIntegrationType;

  // Opayo vendor (required for all integrations)
  vendorName: string;

  // Opayo user with admin rights (required for all integrations)
  adminUser: string;

  // Opayo user password (required for all integrations)
  adminPassword: string;

  /// Key required for Pi integration
  integrationKey: string;

  /// Password required for Pi integration
  integrationPassword: string;

  // true if this is a test configuration
  isTest: boolean;

  // Create human readable vendor code from payment request
  vendorTxCodeType: VendorTxCodeType;
}

export const CurrentProviderName = OpayoProviderName;
export const CurrentProviderDisplayName = OpayoProviderDisplayName;
export const CurrentPaymentMethodType = PaymentMethodType.Card;
export const CurrentProviderModelConsts = OpayoProviderModelConsts;
export type CurrentSettings = OpayoSettings;
export type CurrentCredentials = OpayoCredentials;
export type CurrentPaymentProviderConfiguration = OpayoPaymentProviderConfiguration;
export type CurrentAddPaymentProviderConfiguration = AddOpayoPaymentProviderConfiguration;
export type CurrentFormModel = OpayoFormModel;

export const mapToAddConfiguration = (model: CurrentFormModel): CurrentAddPaymentProviderConfiguration => {
  const configuration = mapToAddPaymentProviderConfiguration<CurrentAddPaymentProviderConfiguration>(
    model,
    CurrentProviderName
  );
  configuration.isTest = model.isTest;
  configuration.settings = {
    useTestServer: model.isTest,
    providerName: CurrentProviderName,
    integrationType: model.integrationType,
    vendorTxCodeType: model.vendorTxCodeType,
  } as CurrentSettings;
  configuration.credentials = {
    vendorName: model.vendorName,
    adminUser: model.adminUser,
    adminPassword: model.adminPassword,
    integrationKey: model.integrationKey,
    integrationPassword: model.integrationPassword,
    providerName: CurrentProviderName,
  } as CurrentCredentials;
  return configuration;
};

export const mapToUpdateConfiguration = (
  data: CurrentPaymentProviderConfiguration,
  model: CurrentFormModel
): CurrentPaymentProviderConfiguration => {
  const configuration = mapToPaymentProviderConfiguration(data, model);
  configuration.isTest = model.isTest;
  if (!configuration.settings) {
    configuration.settings = { providerName: CurrentProviderName } as CurrentSettings;
  }
  configuration.settings.integrationType = model.integrationType;
  configuration.settings.useTestServer = model.isTest;
  configuration.settings.vendorTxCodeType = model.vendorTxCodeType;
  if (!configuration.credentials) {
    configuration.credentials = { providerName: CurrentProviderName } as CurrentCredentials;
  }
  configuration.credentials.vendorName = model.vendorName;
  configuration.credentials.adminUser = model.adminUser;
  configuration.credentials.adminPassword = getNonFillerDisplayPasswordOrUndefined(model.adminPassword);
  configuration.credentials.integrationKey = model.integrationKey;
  configuration.credentials.integrationPassword = getNonFillerDisplayPasswordOrUndefined(model.integrationPassword);
  return configuration;
};
