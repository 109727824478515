import {
  TypeSingleSortInfo,
  TypeFilterValue,
  TypeSortInfo,
  TypeColumn,
  TypeComputedProps,
} from "@inovua/reactdatagrid-community/types";
import { MutableRefObject } from "react";
import PageResult from "../../common/api/PageResults";
import { createFilterString } from "./dataGridFilterHelpers";
import CursorPageResult from "../../common/api/CursorPageResult";

/*
 * Base settings for an Options column
 */
export const BaseOptionsColumn: TypeColumn = {
  name: "_options",
  header: null,
  defaultFlex: 1,
  maxWidth: 50,
  textAlign: "center",
  cellSelectable: false,
  sortable: false,
  lockable: false,
  hideable: false,
};

/*
 * The default page size for the data grid
 */
export const defaultPageSize = 10;

export const SortAscending = 1;
export const SortDesending = -1;

/*
 * The data grid sort direction values
 */
export declare type DataGridSortDirections = -1 | 0 | 1;

export interface DataGridPagination {
  skip?: number;
  limit?: number;
}

export interface DataGridState {
  name: string;
  filters?: TypeFilterValue;
  sortInfo?: TypeSortInfo;
  pagination?: DataGridPagination;
}

export const defaultDataGridPagination: DataGridPagination = {
  skip: 0,
  limit: defaultPageSize,
};

export declare type DataGridReadyItem = MutableRefObject<TypeComputedProps | null>;

/*
 * Type returned from a LoadFunction
 */
export type LoadDataResult<TType> = {
  data: TType[];
  count: number;
};

/*
 *  Defines page parameters
 */
export interface PageParameters {
  page: number;
  itemsPerPage: number;
  sort: string;
  filter: string;
}

/*
 *  Defines cursor parameters
 */
export interface CursorPageParameters {
  cursor: string;
  itemsPerPage: number;
  filter: string;
}

/*
 *  Default props for LoadData function
 */
export interface LoadDataProps {
  skip: number;
  limit: number;
  sortInfo: TypeSingleSortInfo | undefined;
  //  groupBy: any;
  filterValue: TypeFilterValue;
}

/*
 *  Default props for LoadData function
 */
export interface LoadCursorDataProps {
  cursor: string;
  limit: number;
  sortInfo: TypeSingleSortInfo | undefined;
  filterValue: TypeFilterValue;
}

/*
 *  Props for loadPageDataProps function
 */
export interface LoadPageDataProps<T> extends LoadDataProps {
  defaultSortColumn: string;
  defaultSortDirection: DataGridSortDirections;
  apiFunc: (pageParameters: PageParameters, filter?: string) => Promise<PageResult<T>>;
}

/*
 * Creates the Draycir api compatible sort string
 */
export const createSortString = (sortInfo?: TypeSingleSortInfo): string =>
  sortInfo?.name ? (sortInfo.dir === SortDesending ? "-" : "") + sortInfo.name : "";

/*
 * Creates the page parameters from the data grid details
 */
export function createPageParameters(
  skip: number,
  limit: number,
  sortInfo?: TypeSingleSortInfo,
  filterInfo?: TypeFilterValue
): PageParameters {
  const internalLimit = limit ?? defaultPageSize;
  const internalSkip = skip ?? 0;

  const page = internalSkip / internalLimit + 1;
  const sort = createSortString(sortInfo);
  const filter = filterInfo ? createFilterString(filterInfo) : "";

  return { page, itemsPerPage: limit, sort, filter } as PageParameters;
}

/*
 * Creates the page parameters from the data grid details
 */
export function createCursorPageParameters(
  cursor: string,
  limit: number,
  filterInfo?: TypeFilterValue
): CursorPageParameters {
  const filter = filterInfo ? createFilterString(filterInfo) : "";

  return { cursor, itemsPerPage: limit, filter } as CursorPageParameters;
}

/*
 * Creates the default TypeSingleSortInfo if the passed TypeSingleSortInfo is null or undefined
 */
export const createDefaultTypeSingleSortInfo = (
  sortInfo: TypeSingleSortInfo | undefined,
  columnName: string,
  direction: DataGridSortDirections
): TypeSingleSortInfo | undefined =>
  sortInfo ??
  ({
    dir: direction,
    name: columnName,
  } as TypeSingleSortInfo);

interface LoadPageApiOptions {
  filter?: string;
  skipReset?: boolean;
}

type LoadPageCallbackFunc<T> = (pageParameters: PageParameters, filter?: string) => Promise<PageResult<T>>;
type LoadCursorPageCallbackFunc<T> = (pageParameters: CursorPageParameters, filter?: string) => Promise<CursorPageResult<T>>;

type LoadPageWithOptionsCallbackFunc<T> = (
  pageParameters: PageParameters,
  options: LoadPageApiOptions
) => Promise<PageResult<T>>;

/*
 * Generic function for loading in page data
 */
export async function loadPageData<T>(
  skip: number,
  limit: number,
  sortInfo: TypeSingleSortInfo | undefined,
  defaultSortColumn: string,
  defaultSortDirection: DataGridSortDirections,
  filterValue: TypeFilterValue | undefined,
  apiFunc: LoadPageCallbackFunc<T>
): Promise<LoadDataResult<T>> {
  const processedSortInfo = createDefaultTypeSingleSortInfo(sortInfo, defaultSortColumn, defaultSortDirection);

  const pageParameters = createPageParameters(skip, limit, processedSortInfo, filterValue);

  const result = await apiFunc(pageParameters);

  return { data: result.items, count: result.totalItems };
}

/*
 * Generic function for loading in page data
 */
export async function loadPageDataWithOptions<T>(
  skip: number,
  limit: number,
  sortInfo: TypeSingleSortInfo | undefined,
  defaultSortColumn: string,
  defaultSortDirection: DataGridSortDirections,
  filterValue: TypeFilterValue | undefined,
  apiFunc: LoadPageWithOptionsCallbackFunc<T>
): Promise<LoadDataResult<T>> {
  const processedSortInfo = createDefaultTypeSingleSortInfo(sortInfo, defaultSortColumn, defaultSortDirection);

  let pageParameters = createPageParameters(skip, limit, processedSortInfo, filterValue);
  const options = {
    filter: filterValue,
  } as LoadPageApiOptions;

  let result = await apiFunc(pageParameters, options);

  if (result.totalItems < skip) {
    // total items returned has exceeded the skip so we should reset back to 0
    pageParameters = createPageParameters(0, limit, processedSortInfo, filterValue);
    options.skipReset = true;
    result = await apiFunc(pageParameters, options);
  }
  return { data: result.items, count: result.totalItems };
}

/*
 * Generic function for loading in cursor page data
 */
export async function loadCursorPageData<T>(
  cursor: string,
  limit: number,
  filterValue: TypeFilterValue | undefined,
  apiFunc: LoadCursorPageCallbackFunc<T>
): Promise<LoadDataResult<T>> {
  const pageParameters = createCursorPageParameters(cursor, limit, filterValue);

  const result = await apiFunc(pageParameters);

  return { data: result.items, count: result.items.length + 1 };
}
