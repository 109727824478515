/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useState } from "react";
import { BsPrefixProps } from "react-bootstrap/esm/helpers";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { tPages } from "../../../i18n";
import { CreateNotificationUser, NotificationUser } from "../../../models/notifications/notificationUser";
import { NotificationUserType } from "../../../models/notifications/notificationUserType";
import { User } from "../../../models/users/user";
import NewUserDialog, { FormModel } from "./NewUserDialog";

const keyPrefix = "notificationsPage";

interface DeleteUserButtonProps {
  onDelete: () => Promise<void>;
  disabled?: boolean;
}

/*
 * Delete notification user
 */
const DeleteUserButton = ({ onDelete, disabled }: DeleteUserButtonProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [show, setShow] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleOnDelete = async (): Promise<void> => {
    setShow(false);
    setDeleting(true);
    onDelete().finally(() => setDeleting(false));
  };

  return (
    <>
      <SpinnerButton
        disabled={disabled}
        size='sm'
        variant='danger'
        title={t("buttonRemoveUser")}
        showSpinner={deleting}
        onClick={() => setShow(true)}
      />

      {show && (
        <ConfirmationDialog
          show={show}
          title={t("titleDelete")}
          body={t("confirmDeleteMessage")}
          handleClose={() => setShow(false)}
          handleConfirmation={handleOnDelete}
        />
      )}
    </>
  );
};

DeleteUserButton.defaultProps = {
  disabled: false,
};

export default DeleteUserButton;
