/* eslint-disable react/jsx-props-no-spreading */
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";
import { IconButton } from "../icons/IconButton";
import { IdRouteIconButton } from "../route/RouteButtons";

export interface ItemViaRouteButtonProps {
  route: string;
  id: string;
  disabled?: boolean;
}

export interface ItemButtonProps {
  title?: string;
  disabled?: boolean;
  onClick: () => void;
}

/*
 * Edit icon button that set the route with and Id
 */
export function EditItemViaRouteButton({ route, id, disabled }: ItemViaRouteButtonProps): ReactElement {
  const { t } = useTranslation([tForms.ns]);
  return <IdRouteIconButton route={route} id={id} disabled={disabled} title={t("titleEdit")} icon={faEdit} />;
}

EditItemViaRouteButton.defaultProps = {
  disabled: false
}

/*
 * Edit icon button
 */
export function EditItemButton({ title, disabled, onClick }: ItemButtonProps): ReactElement {
  const { t } = useTranslation([tForms.ns]);
  const handleClick = (): void => {
    if (!disabled)
      onClick();
  }
  return <IconButton disabled={disabled} title={title ?? t("titleEdit")} icon={faEdit} onClick={handleClick} />;
}
EditItemButton.defaultProps = {
  title: undefined,
  disabled: undefined,
};

/*
 * View icon button that set the route with and Id
 */
export function ViewItemButton(props: ItemViaRouteButtonProps): ReactElement {
  const { t } = useTranslation([tForms.ns]);
  return <IdRouteIconButton {...props} title={t("titleView")} icon={faEye} />;
}

ViewItemButton.defaultProps = {
  disabled: false
}
