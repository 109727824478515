/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import DraycirSpinner from "../Spinners/DraycirSpinner";
import "./Loading.scss";

interface LoadingProps {
  message?: string;
}
/*
 * Displays a loading spinner
 */
const Loading = ({ message }: LoadingProps): ReactElement => (
  <div className='loading-container'>
    <div className='loading-message'>
      <div>{message}</div>
    </div>
    <DraycirSpinner />
  </div>
);

Loading.defaultProps = {
  message: "",
};

export default Loading;
