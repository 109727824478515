import lang from "i18next";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { ReactElement } from "react";
import { TypeColumnRender } from "../../../components/grid/DataGrid";
import { tPages } from "../../../i18n";
import { Payer } from "../../../models/payers/payer";
import { DateItem } from "../../../components/grid/DateItem";
import { DateOutputType } from "../../../utils/dateFormatter";
import { CustomerFormatter } from "../../../utils/customerFormatter";
import { Address } from "../../../models/contacts/address";
import { PayerDetails } from "../../../models/payers/payerDetails";
import { BaseOptionsColumn } from "../../../components/grid/dataGridHelpers";
import { ViewItemButton } from "../../../components/buttons/CommonIconButtons";

const LangOptions = tPages;

interface DetailsAddressProps {
  details: PayerDetails;
}

/*
 * Component to show the customer information in the table
 */
const DetailsAddress = ({ details }: DetailsAddressProps): ReactElement => (
  <>
    <div>{details.address1}</div>
    <div className='small'>
      {CustomerFormatter.fullAddressToString({
        address2: details.address2,
        address3: details.address3,
        address4: details.address4,
        city: details.city,
        state: details.state,
        country: details.country,
      } as Address)}
    </div>
  </>
);

export const payerCreatedOnColumn: TypeColumn = {
  name: "createdOn",
  header: lang.t("payersList.columCreatedOn", LangOptions),
  type: "number",
  render: ({ data }: TypeColumnRender<Payer>) => <DateItem date={data.createdOn} outputType={DateOutputType.ShortDate} />,
};

export const payerCompanyNameColumn: TypeColumn = {
  name: "details.companyName",
  header: lang.t("payersList.columCompanyName", LangOptions),
  sortable: false,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<Payer>) => data.details.companyName,
};

export const payerFullNameColumn: TypeColumn = {
  name: "details.firstnames",
  header: lang.t("payersList.columFullName", LangOptions),
  sortable: false,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<Payer>) => CustomerFormatter.getFullName(data.details.firstNames, data.details.surname),
};

export const payerEmailAddressColumn: TypeColumn = {
  name: "details.emailAddress",
  header: lang.t("payersList.columEmailAddress", LangOptions),
  sortable: false,
  defaultFlex: 1,
  render: ({ data }: TypeColumnRender<Payer>) => data.details.emailAddress,
};

export const payerAddressColumn: TypeColumn = {
  name: "details.address1",
  header: lang.t("payersList.columAddress", LangOptions),
  sortable: false,
  defaultFlex: 2,
  render: ({ data }: TypeColumnRender<Payer>) => <DetailsAddress details={data.details} />,
};

export const payerPostCodeColumn: TypeColumn = {
  name: "details.postCode",
  header: lang.t("payersList.columPostCode", LangOptions),
  sortable: false,
  render: ({ data }: TypeColumnRender<Payer>) => data.details.postCode,
};

export const OptionsColumn: TypeColumn = {
  ...BaseOptionsColumn,
  render: ({ data }: TypeColumnRender<Payer>) => <ViewItemButton route={`${data.accountId}/payers`} id={data.id} />,
};
