/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useState } from "react";
import { ButtonToolbar, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { tForms, tPages } from "../../i18n";
import { HtmlTitle } from "../common/HtmlTitle";
import { PageTitle } from "../common/PageTitle";
import * as regionalFormats from "../../assets/data/regional-formats.json";
import * as timeZoneInfo from "../../assets/data/timezone-info.json";
import { UserSettingMe } from "../../models/users/user";
import PageLoading from "../../components/loading/PageLoading";
import { formatDate, Default12HourTimeFormat, Default24HourTimeFormat } from "../../utils/dateFormatter";
// eslint-disable-next-line import/no-named-as-default
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getCompanyAccountInfo } from "../../services/settings/settings.service";
import { PanelItem } from "../../components/panels/Panels";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import { textOrDefault } from "../../helpers/stringHelpers";
import "../viewsCommon.scss";
import "./UserSettings.scss";
import useMe from "../../common/hooks/useMe";
import { updateMySettings } from "../../services/users/user.services";

const keyPrefix = "me";

const validationSchema = Yup.object({}).required();

const ExampleDate = new Date(2020, 3, 8, 15, 30, 0, 0);

const formatTimeExample = (use24Hour: boolean): string =>
  `Example: ${formatDate(ExampleDate, use24Hour ? Default24HourTimeFormat : Default12HourTimeFormat)}`;

/*
 * Configuration for currnt user
 */
const UserSettings = (): ReactElement => {
  const [saving, setSaving] = useState(false);
  const { me, refetch } = useMe();
  const [exampleTime, setExampleTime] = useState(me ? formatTimeExample(me.userSetting.use24Hour) : "");
  const { t } = useTranslation([tForms.ns, tPages.ns], { keyPrefix });
  const { t: tBasic } = useTranslation([tForms.ns, tPages.ns]);
  const { register, handleSubmit } = useForm<UserSettingMe>({ resolver: yupResolver(validationSchema) });

  const { data: companyAccountInfo } = useQuery([QueryNameConsts.CompanyAccountInfo], () => getCompanyAccountInfo());

  const saveSettings = (data: UserSettingMe) => {
    setSaving(true);
    updateMySettings(data)
      .then(() => refetch())
      .finally(() => setSaving(false));
  };

  const buildOptionTitle = (format: string): string => `${formatDate(ExampleDate, format)}`;

  if (!me) {
    return <PageLoading />;
  }

  const showAdvanced = (me?.userSetting?.showAdvancedInfo ?? false).toString();

  return (
    <section className='item-view-section current-user'>
      <HtmlTitle subTitle1={tBasic("pageTitleUserSettings", tPages)} />
      <PageTitle title='pageTitleUserSettings' />
      <Row>
        <Col className='panel-column'>
          <Card className='custom-detail-card'>
            <Card.Body>
              <Card.Title>{t("titleDetails")}</Card.Title>
              <Card.Text as='div'>
                <Row>
                  <Col md={6} sm={12}>
                    <PanelItem title={t("displayName")}>{me.displayName}</PanelItem>
                  </Col>
                  <Col md={6} sm={12}>
                    <PanelItem title={t("emailAddress")}>{me.emailAddress}</PanelItem>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <PanelItem title={t("companyId")}>
                      {textOrDefault(companyAccountInfo?.companyId)}
                      <CopyToClipboard text={textOrDefault(companyAccountInfo?.companyId)} />
                    </PanelItem>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='panel-column'>
          <Form noValidate onSubmit={handleSubmit((data) => saveSettings(data))}>
            <Card className='custom-detail-card'>
              <Card.Body>
                <Card.Text as='div'>
                  <Form.Group as={Col} md='8' lg='6'>
                    <Form.Label>{t("labelTimeZone")}</Form.Label>
                    <Form.Select defaultValue={me?.userSetting?.timeZoneId} {...register("timeZoneId")}>
                      {timeZoneInfo?.timeZones?.map((timeZone) => (
                        <option key={timeZone.id} value={timeZone.id}>
                          {timeZone.displayName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} md='8' lg='6' className='my-2'>
                      <Form.Check
                        type='checkbox'
                        {...register("use24Hour")}
                        label={`${t("labelUse24HourTime")} - ${exampleTime}`}
                        defaultChecked={me?.userSetting.use24Hour}
                        onChange={(e) => setExampleTime(formatTimeExample(!!e.target.checked))}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md='8' lg='6'>
                      <Form.Label>{t("labelShortDateFormat")}</Form.Label>
                      <Form.Select defaultValue={me?.userSetting?.shortDateFormat} {...register("shortDateFormat")}>
                        {regionalFormats.shortDate?.map((dateFormat) => (
                          <option key={dateFormat} value={dateFormat}>
                            {buildOptionTitle(dateFormat)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md='8' lg='6'>
                      <Form.Label>{t("labelLongDateFormat")}</Form.Label>
                      <Form.Select defaultValue={me?.userSetting?.longDateFormat} {...register("longDateFormat")}>
                        {regionalFormats.longDate?.map((dateFormat) => (
                          <option key={dateFormat.format} value={dateFormat.format}>
                            {buildOptionTitle(dateFormat.jsFormat)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>{t("labelShowAdvancedInformation")}</Form.Label>
                      <Col sm='1'>
                        <Form.Select
                          {...register("showAdvancedInfo", {
                            setValueAs: (v) => {
                              if (v === "true") return true;
                              return false;
                            },
                          })}
                          defaultValue={showAdvanced}
                        >
                          <option value='true'>{t("optionStatusEnabled")}</option>
                          <option value='false'>{t("optionStatusDisabled")}</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
            <ButtonToolbar style={{ marginTop: "10px" }}>
              <SpinnerButton type='submit' title={tBasic("buttonSave")} showSpinner={saving} />
            </ButtonToolbar>
          </Form>
        </Col>
      </Row>
    </section>
  );
};

export default UserSettings;
