import lang from "i18next";
import LatePaymentConfigurationsApi from "../../common/api/payThemApi/latePaymentConfigurationsApi";
import { PaymentFeeConfiguration, RegisterPaymentFeeConfiguration } from "../../models/paymentsFees/paymentFeeConfiguration";
import { toastSuccess } from "../notifications/toastNotification.service";
import { tCommon } from "../../i18n";
import { UkLatePaymentInfo } from "../../models/paymentsFees/ukLatePaymentInfo";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

/*
 * Returns all late payment configuration
 */
export const getAllLatePaymentConfigurations = async (): Promise<PaymentFeeConfiguration[]> => {
  const groups = new Array<PaymentFeeConfiguration>();
  const api = new LatePaymentConfigurationsApi();
  let page = 1;
  const itemsPerPage = 20;

  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      const result = await api.getLatePaymentConfigurations(page, itemsPerPage);
      if (result.items?.length > 0) {
        groups.push(...result.items);
        if (result.items.length < itemsPerPage) {
          break;
        }
        page += 1;
      } else {
        break;
      }
    } while (page !== 0);
    return groups;
  } catch (error) {
    handleApiErrorWithToast(error, true, "latePaymentsConfigurations.configurationsGet");
    throw error;
  }
};

/*
 * Returns a single late payment configuration
 */
export const getLatePaymentConfiguration = async (id: string): Promise<PaymentFeeConfiguration> => {
  try {
    const api = new LatePaymentConfigurationsApi();
    return await api.getLatePaymentConfiguration(id);
  } catch (error) {
    handleApiErrorWithToast(error, true, "latePaymentsConfigurations.configurationGet");
    throw error;
  }
};

/*
 * Adds a new late payment configuration
 */
export const addLatePaymentConfiguration = async (
  settlementConfiguration: RegisterPaymentFeeConfiguration
): Promise<PaymentFeeConfiguration> => {
  try {
    const api = new LatePaymentConfigurationsApi();
    const result = await api.addLatePaymentConfiguration(settlementConfiguration);
    toastSuccess(lang.t("toastMessages.latePaymentsConfigurations.configurationAddSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "latePaymentsConfigurations.configurationAdd");
    throw error;
  }
};

/*
 * Updates the late payment configuration
 */
export const updateLatePaymentConfiguration = async (
  settlementConfiguration: PaymentFeeConfiguration
): Promise<PaymentFeeConfiguration> => {
  try {
    const api = new LatePaymentConfigurationsApi();
    const result = await api.updateLatePaymentConfiguration(settlementConfiguration);
    toastSuccess(lang.t("toastMessages.latePaymentsConfigurations.configurationUpdateSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "latePaymentsConfigurations.configurationUpdate");
    throw error;
  }
};

/*
 * Deletes an existing late payment configuration
 */
export const deleteLatePaymentConfiguration = async (id: string): Promise<void> => {
  try {
    const api = new LatePaymentConfigurationsApi();
    const result = await api.deleteLatePaymentConfiguration(id);
    toastSuccess(lang.t("toastMessages.latePaymentsConfigurations.configurationDeleteSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "latePaymentsConfigurations.configurationDelete");
    throw error;
  }
};

/*
 * Returns information on uk late payments
 */
export const getUkLatePaymentInfo = async (): Promise<UkLatePaymentInfo> => {
  try {
    const api = new LatePaymentConfigurationsApi();
    return await api.getUkLatePaymentInfo();
  } catch (error) {
    handleApiErrorWithToast(error, true, "latePaymentsConfigurations.configurationGetInfo");
    throw error;
  }
};
