import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DualListBox from 'react-dual-listbox';
import { useTranslation } from 'react-i18next';
import { tForms } from '../../i18n';
import {
  CurrencyInfo,
  getSortedRealCurrencies,
} from '../../utils/currencyCodeUtils';
import { getCurrenciesFilterConfiguration } from '../../utils/itemFilterUtils';
import { ItemFilterControlProps } from './ItemFilterFormModel';
import './MultiCurrencyFilterControl.scss';
// const NoCurrencyOption = "anyCurrencySelection";

interface MultiCurrencySelectionFilterControlProps
  extends ItemFilterControlProps {
  label: string;
  message?: string;
  onSelectionChange: (items: string[]) => void;
  currencyOptions?: CurrencyInfo[];
}

/*
 * Multiple currency selection
 */
const MultiCurrencySelectionFilterControl = ({
  label,
  message,
  filterGroup,
  onSelectionChange,
  currencyOptions,
}: MultiCurrencySelectionFilterControlProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  const currencyList = useCallback(
    () => currencyOptions ?? getSortedRealCurrencies(),
    [currencyOptions]
  );
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const filterConfiguration = getCurrenciesFilterConfiguration(
      filterGroup?.filterConfigurations
    );
    const selectedCurrencies: string[] =
      filterConfiguration?.currencies?.length &&
      filterConfiguration?.currencies?.length > 0
        ? filterConfiguration?.currencies
        : [];

    setSelected(selectedCurrencies);
  }, [filterGroup]);

  // Called when selection changed
  const handleSelectionChange = (selection: string[]) => {
    setSelected(selection);
    onSelectionChange(selection);
  };

  const options = currencyList().map((currencyInfo) => ({
    label: currencyInfo.iso4217,
    value: currencyInfo.iso4217,
  }));

  return (
    <Col md='12' className='g-0'>
      <Form.Group className='multi-currency-form'>
        <Form.Label className='body-bold'>{label}</Form.Label>
        {message && <div className='message'>{message}</div>}
        <Row className='dual-list-title body-small-bold mb-2'>
          <Col>{t('filtersForm.currencyFilterTitleList')}</Col>
          <Col>{t('filtersForm.currencyFilterTitleSelectedList')}</Col>
        </Row>
        <DualListBox
          options={options}
          selected={selected}
          onChange={(e) => handleSelectionChange(e)}
          icons={{
            moveLeft: (
              <>
                <span className='fa fa-chevron-left' />
                <b className='move-button-label'>
                  {t('filtersForm.filterButtonRemove')}
                </b>
              </>
            ),
            moveAllLeft: [
              <span key={0} className='fa fa-chevron-left' />,
              <span key={1} className='fa fa-chevron-left' />,
              <>
                <b className='move-button-label'>
                  {t('filtersForm.filterButtonRemoveAll')}
                </b>
              </>,
            ],
            moveRight: (
              <>
                <b className='move-button-label'>
                  {t('filtersForm.filterButtonAdd')}
                </b>
                <span className='fa fa-chevron-right' />
              </>
            ),
            moveAllRight: [
              <>
                <b className='move-button-label'>
                  {t('filtersForm.filterButtonAddAll')}
                </b>
              </>,
              <span key={0} className='fa fa-chevron-right' />,
              <span key={1} className='fa fa-chevron-right' />,
            ],
          }}
        />
      </Form.Group>
    </Col>
  );
};

MultiCurrencySelectionFilterControl.defaultProps = {
  message: undefined,
  currencyOptions: undefined,
};

export default MultiCurrencySelectionFilterControl;
