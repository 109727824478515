import { ReactElement, useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faEdit, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitle } from "../../common/PageTitle";
import PageLoading from "../../../components/loading/PageLoading";
import { DeleteItemConfirmation } from "../../../components/icons/DeleteItemConfirmation";
import { SpinnerButton } from "../../../components/buttons/SpinnerButton";
import { HtmlTitle } from "../../common/HtmlTitle";
import { tForms, tPages } from "../../../i18n";
import AccessKeyDialog from "./AccessKeyDialog";
import { AccessKey } from "../../../models/accessKeys/accessKey";
import { NewAccessKey } from "../../../models/accessKeys/newAccessKey";
import CopyAccessKeyDialog from "./CopyAccessKeyDialog";
import useDateFormatter from "../../../common/hooks/useDateFormatter";
import { IconButton } from "../../../components/icons/IconButton";
import { AddOrEditMode } from "../../../components/dialogs/AddOrEditCommonDialog";
import { createAccessKey, deleteAccessKey, getAccessKeys, getMaximumAccessKeyCount, updateAccessKey } from "../../../services/accessKeys/accessKey.service";
import "./AccessKeys.scss";

const keyPrefix = "accessKeys";
const keyWithPrefix = (key:string): string => `${keyPrefix}.${key}`; 

/*
 * Access key page
*/
export const AccessKeys = (): ReactElement => {
  const { t } = useTranslation([tPages.ns, tForms.ns]);
  const dateFormatter = useDateFormatter();
  const [keys, setKeys] = useState<AccessKey[]>([]);
  const [maxKeys, setMaxKeys] = useState<number>(0);
  const [creating, setCreating] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [newKey, setNewKey] = useState<NewAccessKey | undefined>();
  const [dialogMode, setDialogMode] = useState<AddOrEditMode>('add');
  const [editingData, setEditingData] = useState<AccessKey | undefined>(undefined);

  // Store the maximum number of keys we can create
  useMemo(() => {
    const getMaximumCountAsync = async () => {
      const count = await getMaximumAccessKeyCount();
      setMaxKeys(count);
    }
    getMaximumCountAsync();
  }, []);

  // Get all of the keys currently existing
  useEffect(() => {
    const getAccessKeysAsync = async () => {
      const result = await getAccessKeys();
      setKeys(result);
    }
    getAccessKeysAsync();
  }, []);

  if (!keys) {
    return <PageLoading />;
  }

  // Creates a new key
  const handleOnSave = async (model: AccessKey) => {
    if (dialogMode === "add")
    {
      setCreating(true);
      try{
        const response = await createAccessKey(model);
        setShowDialog(false);
        setShowCopyDialog(true);
        setNewKey(response);
        if (response)
          setKeys((current) => [...current, response]);
      }
      finally {
        setCreating(false)
      }
    }
    else
    {
      await updateAccessKey(model.id, model.name, model.validUntil);
      const result = await getAccessKeys();
      setKeys(result);
      setShowDialog(false);
    }
  };

  // Deletes a key
  const handleDeleteKey = async (id: string): Promise<void> => {
    await deleteAccessKey(id)
    setKeys(keys.filter((x) => x.id !== id));
  };

  const showAddDialog = (): void => {
    setDialogMode('add');
    setEditingData(undefined); 
    setShowDialog(true);
  }

  const showEditDialog = (id: string): void => {
    setDialogMode('edit');
    setEditingData(keys.find(x => x.id === id));    
    setShowDialog(true);
  }

  return (
    <section className='access-key-container'>
      <HtmlTitle subTitle1={t("pageTitleAccessKeys", tPages)} />
      <PageTitle title='pageTitleAccessKeys' />
      <Row>
        <Col className='panel-column'>
          <Card>
            <Card.Body>
              <p>{t(keyWithPrefix("descriptionAccessKeys"))}</p>
              <Table striped borderless hover>
                {keys && keys.length > 0 ? <thead>
                  <tr>
                    <td>{t(keyWithPrefix("headerKeyName"))}</td>
                    <td>{t(keyWithPrefix("headerKeyPrefix"))}
                      <FontAwesomeIcon icon={faExclamationCircle} className="tooltip-icon" title={t(keyWithPrefix("tooltipPrefix"))} />                     
                    </td>
                    <td>{t(keyWithPrefix("headerKeyValidUntil"))}</td>
                    <td>{t(keyWithPrefix("headerKeyCreatedOn"))}</td>
                    <td width='75px' align='left' />
                  </tr>
                </thead> : null}
                <tbody>
                  {keys.map((sk) => (
                    <tr key={sk.id}>
                      <td className='small'>{sk.name}</td>
                      <td className='small'>{`${sk.prefix}...`}</td>
                      <td className='small'>{sk.validUntil ? dateFormatter.toShortDate(sk.validUntil) : t(keyWithPrefix("noExpirationDateCell"))}</td>
                      <td className='small'>{dateFormatter.toShortDate(sk.createdOn)}</td>
                      <td>
                        <IconButton title={t("titleEdit", { ns: 'forms' })} icon={faEdit} onClick={() => showEditDialog(sk.id)} />
                        <DeleteItemConfirmation<string> item={sk.id} onDelete={() => handleDeleteKey(sk.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div>
                {/* Show a create button if we can still generate keys, else a message if we're at our max */}
                {keys.length < maxKeys ? (
                  <SpinnerButton title={t(keyWithPrefix("buttonCreateKey"))} variant='primary' showSpinner={creating} onClick={() => showAddDialog() } />
                ) : (
                  <p className='text-info'>{t(keyWithPrefix("descriptionMaximumKeysReached"))}</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AccessKeyDialog show={showDialog} mode={dialogMode} onClose={() => setShowDialog(false)} onSave={(model) => handleOnSave(model)} data={editingData} />
      {newKey ? <CopyAccessKeyDialog newAccessKey={newKey} show={showCopyDialog} handleClose={() => setShowCopyDialog(false)} /> : null}
    </section>
  );
};

export default AccessKeys;
