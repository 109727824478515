import { AxiosError, AxiosResponse } from "axios";
import { PaymentTransaction } from "../../../models/paymentTransactions/paymentTransaction";
import { AllocatedStatus } from "../../../models/paymentTransactions/paymentTransactionEnums";
import PageResult from "../PageResults";
import BasePayThemApi from "./basePayThemApi";
import { getFilenameFromContentDisposition } from "../../../utils/httpUtilities";
import ExportOptions from "../../../models/paymentTransactions/exportOptions";
import { ImportResults } from "../../../models/paymentTransactions/importResults";
import { PaymentTransactionIncludeOptions } from "./paymentTransactionIncludeOptions";
import { buildExpandParameter } from "./includeOptions";
import { PendingResult } from "../../../models/paymentTransactions/pendingResult";
import { CountResult } from "../../../models/count/countResult";
import { ReserveCaptureRequest } from "../../../models/paymentTransactions/reservePayments/reserveCaptureRequest";
import { ReserveCancelRequest } from "../../../models/paymentTransactions/reservePayments/reserveCancelRequest";

const defaultExportFilename = "PaymentTransactions.csv";

export const TypeFilterName = "type";
export const TotalAmountFilterName = "totalAmount";
export const CustomerFilterName = "paymentRequest.customerName";
export const StatusFilterName = "status";

export class PaymentTransactionsApi extends BasePayThemApi {
  private endPoint = "payment-transactions";

  // Return a payment transaction
  public async getPaymentTransaction(
    id: string,
    includeOptions?: PaymentTransactionIncludeOptions
  ): Promise<PaymentTransaction> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.getItem<PaymentTransaction, string>(this.endPoint, id, params);
    return result;
  }

  // Returns a page of payment transactions
  public async getPaymentTransactions(
    page: number,
    itemsPerPage: number,
    sort?: string,
    filter?: string,
    includeOptions?: PaymentTransactionIncludeOptions
  ): Promise<PageResult<PaymentTransaction>> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.getPage<PaymentTransaction>(this.endPoint, page, itemsPerPage, sort, filter, params);
    return result;
  }

  /*
   * Returns the count of payment transactions matching the given filter
   */
  public async getPaymentTransactionCount(filter?: string): Promise<CountResult> {
    const params = new URLSearchParams();
    if (filter) params.append("filter", filter);
    const result = await this.get<CountResult>(`${this.endPoint}/count`, params);
    return result;
  }

  /*
   * Returns a page of failed payment transactions
   */
  public async getFailedStatusPaymentTransactions(
    page: number,
    itemsPerPage: number,
    sort?: string
  ): Promise<PageResult<PaymentTransaction>> {
    const url = `${this.endPoint}/status/all-failures`;
    const result = await this.getPage<PaymentTransaction>(url, page, itemsPerPage, sort);
    return result;
  }

  /*
   * Updates the allocated status for the given payment transaction
   */
  public async updateAllocatedStatus(id: string, status: AllocatedStatus) {
    const url = `${this.endPoint}/${id}/allocated-status`;
    const body = { status };
    await this.patch(url, body);
  }

  /*
   * Captures a pending payment transaction
   */
  public async capturePaymentTransaction(id: string, amountToCapture?: number, note?: string): Promise<PendingResult> {
    const url = `${this.endPoint}/${id}/pending/capture`;
    const dto = { amountToCapture, note } as ReserveCaptureRequest;
    return this.postWithResponse<ReserveCaptureRequest, PendingResult>(url, dto);
  }

  /*
   * Cancels a pending payment transaction
   */
  public async cancelPaymentTransaction(id: string, note?: string): Promise<PendingResult> {
    const url = `${this.endPoint}/${id}/pending/cancel`;
    const dto = { note } as ReserveCancelRequest;
    return this.postWithResponse<ReserveCancelRequest, PendingResult>(url, dto);
  }

  /*
   * Imports allocation status
   */
  public async importAllocationStatus(file: File): Promise<ImportResults> {
    const formData = new FormData();
    formData.append("file", file);
    const inst = this.getInstance();
    const url = `${this.endPoint}/allocated-status/import`;
    const response = await inst.post<FormData, AxiosResponse<ImportResults>>(url, formData).catch((error) => {
      this.processHttpError(error);
    });
    return response.data;
  }

  /*
   * Exports the transactions to an excel file
   */
  public async exportPaymentTransactions(options: ExportOptions): Promise<File> {
    const params = new URLSearchParams();
    if (options) {
      params.append("exportType", options.exportType);

      if (options.fromDate) {
        params.append("fromDate", options.fromDate.toISOString());
      }
      if (options.toDate) {
        params.append("toDate", options.toDate.toISOString());
      }
      if (options.paymentProvider) {
        params.append("paymentProviderId", options.paymentProvider);
      }

      let statuses: string = "";
      const appendStatus = (status: AllocatedStatus) => {
        statuses += statuses ? `,${status}` : status;
      };
      if (!options.allocatedStatuses.waitingToBeAllocated) appendStatus(AllocatedStatus.WaitingToBeAllocated);
      if (!options.allocatedStatuses.autoAllocated) appendStatus(AllocatedStatus.AutoAllocated);
      if (!options.allocatedStatuses.manuallyAllocated) appendStatus(AllocatedStatus.ManuallyAllocated);
      if (!options.allocatedStatuses.unallocated) appendStatus(AllocatedStatus.Unallocated);
      if (!options.allocatedStatuses.error) appendStatus(AllocatedStatus.Error);
      if (!options.allocatedStatuses.notApplicable) appendStatus(AllocatedStatus.NotApplicable);
      if (statuses) {
        params.append("excludeAllocatedStatus", statuses);
      }

      if (options.filters) {
        params.append("filter", options.filters.join(","));
      }
    }

    const url = `${this.endPoint}/export${this.createQueryFromParameters(params)}`;
    const inst = this.getInstance();
    const response = await inst.get<Blob>(url, { responseType: "blob" }).catch((error: Error | AxiosError) => {
      this.processHttpError(error);
    });

    const filename = getFilenameFromContentDisposition(response.headers["content-disposition"]) ?? defaultExportFilename;
    const contentType = response.headers["content-type"];

    return new File([response.data], filename, { type: contentType });
  }
}

export default PaymentTransactionsApi;
