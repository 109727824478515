import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getPaymentProviders } from "../../../services/paymentProviders/paymentProviders.service";
import PaymentProvideGroup from "./PaymentProviderGroup";
import { PaymentProvider } from "../../../models/paymentProviders/paymentProvider";
import { tPaymentProviders } from "../../../i18n";
import { QueryNameConsts } from "../../../common/queryNameConsts";

interface PaymentProviderSelectionDialogProps {
  show: boolean;
  onHide: () => void;
  onSelected: (provider: PaymentProvider) => void;
}

/*
 * Display an add user button which display the add user dialog
 */
const PaymentProviderSelectionDialog = ({ show, onHide, onSelected }: PaymentProviderSelectionDialogProps): ReactElement => {
  const { t } = useTranslation([tPaymentProviders.ns]);
  const { data } = useQuery(QueryNameConsts.PaymentProviderTypes, () => getPaymentProviders());
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("paymentProviders.titleSelectionPaymentProvider")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{data && <PaymentProvideGroup paymentProviders={data} onSelected={onSelected} />}</Modal.Body>
    </Modal>
  );
};

export default PaymentProviderSelectionDialog;
