import { ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { DisplayLoadingOrError } from "../../components/loading/DisplayLoadingOrError";
import { tPages } from "../../i18n";
import { Account } from "../../models/accounts/account";
import { getAccount } from "../../services/accounts/accounts.service";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import { HtmlTitle } from "../common/HtmlTitle";
import AccountTabs from "./AccountTabs";

const createTitle = (account: Account): string =>
  `${account.accountReference ? `${account.accountReference} - ` : ""}${account.name}`;

/*
 * Generates the title for the account item page
 */
const Title = ({ account }: { account: Account }): ReactElement => <div className='page-title'>{createTitle(account)}</div>;

export const AccountItem = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([tPages.ns]);

  const { data, isLoading, isError } = useQuery([QueryNameConsts.AccountItem, id], () => getAccount(id ?? ""));

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleAccount", tPages)} subTitle2={id} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !data}>
        <Breadcrumb>
          <CommonBreadcrumbs.Accounts />
        </Breadcrumb>
        {data && (
          <section className='item-view-section transaction common-form'>
            <HtmlTitle subTitle1={t("pageTitleAccount", tPages)} subTitle2={createTitle(data)} />
            <Title account={data} />
            <AccountTabs account={data} />
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default AccountItem;
