import { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { PaymentFeeTransaction } from "../../../models/paymentsFees/paymentFeeTransaction";
import LatePaymentFeesTable, { LatePaymentDisplayMode } from "./LatePaymentFeesTable";

const keyPrefix = "latePaymentsTab";

interface LatePaymentsTabProps {
  latePaymentTransactions: PaymentFeeTransaction[] | undefined;
  mode: LatePaymentDisplayMode;
}

/*
 * Displays the late payment tab
 */
const LatePaymentsTab = ({ latePaymentTransactions, mode }: LatePaymentsTabProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  const elements =
    !latePaymentTransactions || latePaymentTransactions.length === 0 ? (
      <p>{t("noLatePaymentsMessage")}</p>
    ) : (
      <LatePaymentFeesTable latePaymentTransactions={latePaymentTransactions} mode={mode} />
    );

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card className='custom-detail-card'>
            <Card.Body>{elements}</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='panel-column'> </Col>
      </Row>
    </>
  );
};

export default LatePaymentsTab;
