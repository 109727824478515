export enum HostSystemType {
  Default,
  Demo,
  Cloud,
  OnPremise,
}

export interface HostSystem {
  hostSystemName: string;
  displayName: string;
  hostSystemType: HostSystemType;
}
