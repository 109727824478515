import { format, formatISO } from "date-fns";
import { TypeGuard } from "./typeGuards";
import { UserMe } from "../models/users/user";
import { getValidDate } from "../helpers/dateHelpers";

/*
 * Default text to display for dateTimeOrString
 */
export const DefaultBlankText = "N/A";
const DefaultShortDateFormat = "yyyy/MM/dd";
const DefaultShortDateTimeFormat = "yyyy/MM/dd HH:mm:ss";
export const Default12HourTimeFormat = "h:mm:ss aaa";
export const Default24HourTimeFormat = "HH:mm:ss";

export enum DateOutputType {
  ShortDate,
  ShortDateTime,
}

/*
 * Formats the given date using the given string format
 */
export const formatDate = (date: Date, formatString: string): string => format(new Date(date), formatString);

export const formatISODate = (
  date?: Date,
  representation: "time" | "date" | "complete" | undefined = "date"
): string | undefined => {
  const validDate = getValidDate(date);
  if (!validDate) return undefined;
  return formatISO(validDate, { representation });
};

export class DateFormatter {
  private me: UserMe | undefined;

  constructor(me: UserMe | undefined) {
    this.me = me;
  }

  /*
   * Returns the short date format to use
   */
  public getShortDateFormat(): string {
    return this.me?.userSetting?.shortDateFormat ?? DefaultShortDateFormat;
  }

  /*
   * Returns the long date format to use
   */
  public getShortDateTimeFormat(): string {
    if (!this.me?.userSetting?.shortDateFormat) return DefaultShortDateTimeFormat;

    return `${this.me.userSetting.shortDateFormat} ${this.me.userSetting.use24Hour ? Default24HourTimeFormat : Default12HourTimeFormat}`;
  }

  /*
   * Returns format string based on type
   */
  public toOutputString(date: Date | string, outputType: DateOutputType): string {
    let output: string;

    switch (outputType) {
      case DateOutputType.ShortDate:
        output = TypeGuard.isString(date) ? this.toShortDateFromString(date) : this.toShortDate(date);
        break;
      case DateOutputType.ShortDateTime:
        output = TypeGuard.isString(date) ? this.toShortDateTimeFromString(date) : this.toShortDateTime(date);
        break;
      default:
        throw new Error("Unknown DateOutputType");
    }
    return output;
  }

  /*
   * Returns short date format string
   */
  public toShortDateFromString(dateAsString: string): string {
    return this.toShortDate(new Date(dateAsString));
  }

  /*
   * Returns short date format string
   */
  public toShortDate(date: Date): string {
    return format(new Date(date), this.getShortDateFormat());
  }

  /*
   * Returns short date/time format string
   */
  public toShortDateTimeFromString(dateAsString: string): string {
    return this.toShortDateTime(new Date(dateAsString));
  }

  /*
   * Returns short date/time format string
   */
  public toShortDateTime(date: Date): string {
    return format(new Date(date), this.getShortDateTimeFormat());
  }

  /*
   * Displays a date or given string if date is undefined
   */
  public dateTimeOrString(
    date: Date | string | undefined | null,
    blankText = DefaultBlankText,
    outputType: DateOutputType = DateOutputType.ShortDateTime
  ): string {
    if (!date) return blankText;
    return this.toOutputString(date, outputType);
  }
}
