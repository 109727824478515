import { ReactElement, useEffect, useRef, useState } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CompanySettingsApi } from "../../common/api/payThemApi/companySettingsApi";
import { CompanySettings } from "../../models/companySettings/companySettingsModels";
import { PageTitle } from "../common/PageTitle";
import PageLoading from "../../components/loading/PageLoading";
import { deleteLogo, uploadLogo } from "../../services/settings/settings.service";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { HtmlTitle } from "../common/HtmlTitle";
import { tForms, tPages } from "../../i18n";
import "../viewsCommon.scss";
import "./Customisation.scss";

const imageFileFilter = "image/jpeg, image/png, image/svg";

// Checks if we have a valid logo
const checkHasLogo = (logoUrl: string): boolean => !!(logoUrl && logoUrl !== "");

type LogoProps = {
  logoUrl: string;
};

/*
 * Displays the current logo or message stating no logo has been uploaded
 */
const Logo = ({ logoUrl }: LogoProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);
  if (!checkHasLogo(logoUrl)) return <p className='no-logo'>{t("descriptionNoLogo")}</p>;
  return (
    <Row>
      <Col>
        <img src={logoUrl} alt='My Company Logo' className='company-logo' />
      </Col>
    </Row>
  );
};

type ClearLogoButtonProps = {
  hasLogo: boolean;
  disabled: boolean;
  onClearLogo: () => void;
};

/*
 * Displays and handles clear logo button
 */
const ClearLogoButton = ({ onClearLogo, hasLogo, disabled }: ClearLogoButtonProps): ReactElement => {
  const { t } = useTranslation([tForms.ns]);
  const [show, setShow] = useState<boolean>(false);

  if (!hasLogo) return <></>;

  // Deletes the current logo
  const handleClearLogo = async () => {
    setShow(false);
    if (await deleteLogo()) {
      onClearLogo();
    }
  };

  return (
    <>
      <Button variant='outline-danger' onClick={() => setShow(true)} disabled={disabled}>
        {t("buttonClearLogo")}
      </Button>
      <ConfirmationDialog
        show={show}
        title={t("confirmClearLogoTitle")}
        body={t("confirmClearLogoBody")}
        handleClose={() => setShow(false)}
        handleConfirmation={() => handleClearLogo()}
      />
    </>
  );
};

export const Customisation = (): ReactElement => {
  const { t } = useTranslation([tForms.ns]);
  const [companySettings, setCompanySettings] = useState<CompanySettings>();
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [fileSelection, setFileSelection] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const fileUpload = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const api = new CompanySettingsApi();
    api.getCompanySettings().then((result) => {
      setCompanySettings(result);
      setLogoUrl(result.companyDetails.logoUrl || "");
    });
  }, []);

  if (!companySettings) {
    return <PageLoading />;
  }

  // On file select (from the pop up)
  const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    const logoFile = event.currentTarget.files?.item(0);
    const newLogoUrl = await uploadLogo(logoFile);
    setLogoUrl(newLogoUrl || "");
    setFileSelection("");
    setUploading(false);
  };

  // Deletes the current logo
  const handleClearLogo = () => {
    setLogoUrl("");
    setFileSelection("");
  };

  return (
    <section className='item-view-section'>
      <HtmlTitle subTitle1={t("pageTitleCustomisation", tPages)} />
      <PageTitle title='pageTitleCustomisation' />
      <Row>
        <Col className='panel-column'>
          <Card>
            <Card.Body>
              <Card.Title>Company Logo</Card.Title>
              <p>You can upload your company logo to display on the recipient payment website.</p>
              <Logo logoUrl={logoUrl} />
              <Row>
                <Col>
                  <SpinnerButton
                    title={t("buttonUploadLogo")}
                    showSpinner={uploading}
                    onClick={() => {
                      fileUpload?.current?.click();
                    }}
                  />{" "}
                  <ClearLogoButton hasLogo={checkHasLogo(logoUrl)} onClearLogo={handleClearLogo} disabled={uploading} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <input
        type='file'
        ref={fileUpload}
        onChange={handleUploadFile}
        accept={imageFileFilter}
        value={fileSelection}
        style={{ display: "none" }}
      />
    </section>
  );
};

export default Customisation;
