import { ExportType } from "../../models/paymentTransactions/exportOptions";
import ExportDialogOptions from "../../views/PaymentTransactions/Export/ExportDialogOptions";
import { LocalStorageProvider } from "../storage/localStorageProvider";
import useDatasetStorageName from "./useDatasetStorageName";

interface ExportOption {
  storeExportOptions: (options: ExportDialogOptions) => void;
  getExportOptions: () => ExportDialogOptions 
}

const ExportOptionsSettingsName = "payment-transactions-export-options";

const defaultExportOptions: ExportDialogOptions = {
  exportType: ExportType.PaymentsAndAllocations,
  allocatedStatuses: {
    waitingToBeAllocated: true,
    unallocated: false,
    autoAllocated: false,
    manuallyAllocated: false,
    notApplicable: false,
    error: true,
  },
};

const useExportOption = (): ExportOption => {
  const name = useDatasetStorageName(ExportOptionsSettingsName);

  /*
  * Stores options in local storage
  */
  const storeExportOptions = (options: ExportDialogOptions) => {
    const storageProvider = new LocalStorageProvider();
    storageProvider.setItem(name, options);
  };

  /*
  * Reads previously saved options, or returns defaults
  */
  const getExportOptions = (): ExportDialogOptions => {
    const storageProvider = new LocalStorageProvider();
    const options = storageProvider.getItem(name);
    return (options ?? defaultExportOptions) as ExportDialogOptions;
  };

  return {
    storeExportOptions,
    getExportOptions
  }
}

export default useExportOption;