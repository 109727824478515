/* eslint-disable react/no-danger */
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { tPages } from '../../i18n';
import ItemFilterGroup from '../../models/itemFiltering/itemFilterGroup';
import TransactionDateType from '../../models/transactions/transactionDateTypeEnum';
import {
  getCurrenciesFilterConfiguration,
  getTransactionAmountFilterConfiguration,
} from '../../utils/itemFilterUtils';

const keyPrefix = 'settlementConfigurationGroupPage';
/*
 * Returns the due date text based on the type
 */
const getDateText = (
  type: TransactionDateType
): { dateTypeText: string; whenText: string } => {
  let dateTypeText = '';
  let whenText = '';

  switch (type) {
    case TransactionDateType.DueDate:
      dateTypeText = 'dateTypeDueDate';
      whenText = 'whenBefore';
      break;
    case TransactionDateType.TransactionDate:
      dateTypeText = 'dateTypeTransactionDate';
      whenText = 'whenAfter';
      break;
    default:
      throw Error('Unknown transaction date type');
  }
  return { dateTypeText, whenText };
};

interface ShowTranslationProps {
  translation: string;
  numberOfDays: number;
  dateType: TransactionDateType;
  discount: string;
  filterGroup?: ItemFilterGroup;
}

/*
 * 'Translates' the data into an English explaination
 */
const ShowTranslation = ({
  translation,
  dateType,
  numberOfDays,
  discount,
  filterGroup,
}: ShowTranslationProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const { dateTypeText, whenText } = getDateText(dateType);
  let text = t(translation, {
    numberOfDays,
    when: t(whenText),
    dateType: t(dateTypeText),
    discount,
  });

  const filterStartText = t('translationFilterStart');

  const append = (destination: string, source: string): string =>
    `${
      destination ? `${destination}, ${source}` : `${filterStartText}${source}`
    }`;

  let filterText = '';
  if (filterGroup) {
    const currenciesFilterConfiguration = getCurrenciesFilterConfiguration(
      filterGroup.filterConfigurations
    );

    if (
      currenciesFilterConfiguration?.currencies &&
      currenciesFilterConfiguration.currencies?.length > 0
    ) {
      const currency = currenciesFilterConfiguration.currencies[0];
      const currencyText = t('translationCurrencyFilter', {
        currency,
      });
      filterText = append(filterText, currencyText);
    }

    const transactionAmountFilterConfiguration =
      getTransactionAmountFilterConfiguration(filterGroup.filterConfigurations);
    if (transactionAmountFilterConfiguration?.minimumTransactionValue) {
      filterText = append(
        filterText,
        t('translationMinTransactionValueFilter', {
          amount: transactionAmountFilterConfiguration.minimumTransactionValue,
        })
      );
    }
    if (transactionAmountFilterConfiguration?.maximumTransactionValue) {
      filterText = append(
        filterText,
        t('translationMaxTransactionValueFilter', {
          amount: transactionAmountFilterConfiguration.maximumTransactionValue,
        })
      );
    }
  }

  if (filterText) {
    text = `${text}, ${filterText}`;
  }
  text += '.';

  return (
    <div className='main-item m-0'>
      <div className='title'>Translation</div>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

ShowTranslation.defaultProps = {
  filterGroup: undefined,
};

export default ShowTranslation;
