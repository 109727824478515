/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tPages } from '../../../i18n';
import { SettlementOffer } from '../../../models/settlements/settlementOffer';
import { Transaction } from '../../../models/transactions/transaction';
import SettlementOffersEntry from './SettlementOffersEntry';
import {
  addSettlementOffer,
  deleteSettlementOffer,
  updateSettlementOffer,
} from '../../../services/transactions/transactions.service';
import AddSettlementOfferButton from './AddSettlementOfferButton';

const keyPrefix = 'settlementOffersTab';

interface SettlementOffersTabProbs {
  transaction: Transaction;
  onTransactionChanged: () => void;
}

/*
 * The settlement offers tab
 */
const SettlementOffersTab = ({
  transaction,
  onTransactionChanged,
}: SettlementOffersTabProbs): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });

  const handleDelete = async (id: string) => {
    await deleteSettlementOffer(transaction.id, id);
    onTransactionChanged();
  };

  const handleAdd = async (settlementOffer: SettlementOffer) => {
    await addSettlementOffer(transaction.id, settlementOffer);
    onTransactionChanged();
  };

  const handleEdit = async (settlementOffer: SettlementOffer) => {
    await updateSettlementOffer(transaction.id, settlementOffer);
    onTransactionChanged();
  };

  const { settlementOffers } = transaction;

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card className='custom-detail-card'>
            <Card.Body>
              {settlementOffers && settlementOffers.length > 0
                ? settlementOffers.map((settlementOffer, index) => (
                    <div key={settlementOffer.id}>
                      {index > 0 ? <hr /> : <></>}
                      <SettlementOffersEntry
                        settlementOffer={settlementOffer}
                        transaction={transaction}
                        onDelete={(id) => handleDelete(id)}
                        onEdit={(editedSettlementOffer) =>
                          handleEdit(editedSettlementOffer)
                        }
                      />
                    </div>
                  ))
                : t('noSettlementOffersFound')}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddSettlementOfferButton
        sourceTransaction={transaction}
        onSave={(offer) => handleAdd(offer)}
      />
    </>
  );
};

export default SettlementOffersTab;
