/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { GeoDataApi } from "../../../../common/api/payThemApi/geoDataApi";
import { tForms } from "../../../../i18n";
import { CompanySettings } from "../../../../models/companySettings/companySettingsModels";
import { Country } from "../../../../models/geoData/Country";
import { getCompanySettings, getPreferences, updatePreferences } from "../../../../services/settings/settings.service";
import { FormSaveRef } from "../preferencesTypes";
import { enumToArray } from "../../../../utils/enumUtils";
import { EditPaymentStatus } from "../../../../models/companySettings/editPaymentStatus";
import GeneralCardPlaceholder from "../../../../components/placeholders/GeneralCardPlaceholder";
import { UpdateTransactionChangeStatus } from "../../../../models/companySettings/updateTransactionChangeStatus";

// Default to the UK for now
const defaultCountryCode = "GB";

const keyPrefix = "preferences.general";

type FormModel = {
  defaultCountryId: number;
  defaultEditPaymentStatus: EditPaymentStatus;
  allowTransactionCurrencyChange: UpdateTransactionChangeStatus;
};

const validationSchema = Yup.object({
  defaultCountryId: Yup.number().required(),
});

/*
 * General settings form
 */
const GeneralSettingsForm = forwardRef<FormSaveRef | undefined>((props, ref) => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const [companySettings, setCompanySettings] = useState<CompanySettings>();
  const [countries, setCountries] = useState<Country[]>();

  const { register, trigger, handleSubmit } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
  });

  useImperativeHandle(ref, () => ({
    validate: () => trigger(),
    save: async () => {
      await handleSubmit(async (data) => {
        getPreferences().then((result) => {
          if (result === undefined) throw new Error("No preferences found");
          const prefs = { ...result };
          prefs.defaultCountryId = data.defaultCountryId;
          prefs.defaultEditPaymentStatus = data.defaultEditPaymentStatus;
          prefs.allowTransactionCurrencyChange = data.allowTransactionCurrencyChange;
          updatePreferences(prefs, false);
        });
      })();
    },
  }));

  useEffect(() => {
    getCompanySettings().then((result) => {
      setCompanySettings(result);
    });
  }, []);

  useMemo(() => {
    const api = new GeoDataApi();
    api.getAllCountries().then((result) => {
      setCountries(result);
    });
  }, []);

  if (!countries || !companySettings) {
    return <GeneralCardPlaceholder />;
  }
  // Find the selected country or the default if none provided
  const selectedCountry =
    countries.find((x) => x.id === companySettings.preferences.defaultCountryId)?.id ??
    countries.find((x) => x.alpha2Code === defaultCountryCode)?.id;

  return (
    <Form noValidate>
      <Card>
        <Card.Body>
          <Card.Title>{t("titleGeneral")}</Card.Title>
          <Card.Text as='div'>
            <Row>
              <Col md='8' sm='12'>
                <Row>
                  <Form.Group as={Col} xs='10' sm='8' md='12' lg='8'>
                    <Form.Label>{t("labelDefaultCountry")}</Form.Label>
                    <Form.Select defaultValue={selectedCountry} {...register("defaultCountryId")}>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.displayName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className='item-description'>
                  <p>{t("descriptionDefaultCountry")}</p>
                </Row>
                <Row>
                  <Form.Group as={Col} xs='10' sm='8' md='12' lg='4'>
                    <Form.Label>{t("labelEditPayment")}</Form.Label>
                    <Form.Select
                      size='sm'
                      defaultValue={companySettings.preferences.defaultEditPaymentStatus}
                      {...register("defaultEditPaymentStatus")}
                    >
                      {enumToArray(EditPaymentStatus).map((item) => (
                        <option key={item} value={item}>
                          {t(`editPaymentStatus${item}`)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className='item-description'>
                  <p>{t("descriptionEditPayment")}</p>
                </Row>
                <Row>
                  <Form.Group as={Col} xs='10' sm='8' md='8' lg='4'>
                    <Form.Label>{t("labelUpdateTransactionCurreny")}</Form.Label>
                    <Form.Select
                      size='sm'
                      defaultValue={companySettings.preferences.allowTransactionCurrencyChange}
                      {...register("allowTransactionCurrencyChange")}
                    >
                      {enumToArray(UpdateTransactionChangeStatus).map((item) => (
                        <option key={item} value={item}>
                          {t(`allowTransactionCurrencyChange${item}`)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className='item-description'>
                  <p>{t("descriptionAllowTransactionCurrencyChange")}</p>
                </Row>
                <Row className='item-description'>
                  <div>{t("descriptionTakeEffectWarning")}</div>
                </Row>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Form>
  );
});

export default GeneralSettingsForm;
