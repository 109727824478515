import {
  PaymentProviderCredentials,
  PaymentProviderSettings,
  AddPaymentProviderTypeConfiguration,
  PaymentProviderTypeConfiguration,
  initAddPaymentProviderTypeConfiguration,
} from "../paymentProviderConfiguration";
import PaymentProviderModelConsts from "../paymentProviderModelConsts";

export const TranSafeProviderName = "TranSafe";
export const TranSafeProviderDisplayName = "TranSafe";

export interface TranSafeSettings extends PaymentProviderSettings {
  gateway?: string;
  apiUrl?: string;
  useTestSite?: boolean;
}

export interface TranSafeCredentials extends PaymentProviderCredentials {
  userName: string;
  password?: string;
}

export const TranSafeTestSiteUserName = "test_retail:public";
export const TranSafeTestSitePassword = "publ1ct3st";

export class TranSafeProviderModelConsts extends PaymentProviderModelConsts {
  public static readonly UserNameMinLength: number = 1;

  public static readonly UserNameMaxLength: number = 100;

  public static readonly PasswordMinLength: number = 1;

  public static readonly PasswordMaxLength: number = 100;
}

export type TransafePaymentProviderConfiguration = PaymentProviderTypeConfiguration<TranSafeSettings, TranSafeCredentials>;
export type AddTransafePaymentProviderConfiguration = AddPaymentProviderTypeConfiguration<
  TranSafeSettings,
  TranSafeCredentials
>;

/*
 * Constructor for AddTransafePaymentProviderConfiguration
 */
export const initAddTransafePaymentProviderConfiguration = (name: string): AddTransafePaymentProviderConfiguration => {
  const data = {
    ...initAddPaymentProviderTypeConfiguration(TranSafeProviderName, name),
    settings: { providerName: TranSafeProviderName } as TranSafeSettings,
    credentials: { providerName: TranSafeProviderName } as TranSafeCredentials,
  };
  return data;
};
