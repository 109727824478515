import { ReactElement } from 'react';
import { Card, Row, Col, NavLink } from 'react-bootstrap';
import { TFunction, useTranslation } from 'react-i18next';
import { buildProviderImageUrl } from '../../../helpers/assetsHelper';
import { PaymentProvider } from '../../../models/paymentProviders/paymentProvider';
import './PaymentProviderSelectionCard.scss';

interface PaymentProviderSelectionCardProps {
  paymentProvider: PaymentProvider;
  onSelected: (provider: PaymentProvider) => void;
}

function getDescription(
  providerName: string,
  t: TFunction<'paymentProviders'>
): string {
  const descriptionKey = `paymentProviders.providerDescription${providerName}`;
  const description = t(descriptionKey);
  return description !== descriptionKey
    ? description
    : t('paymentProviders.providerDescriptionNotAvailable');
}

const PaymentProviderSelectionCard = ({
  paymentProvider,
  onSelected,
}: PaymentProviderSelectionCardProps): ReactElement => {
  const { t } = useTranslation('paymentProviders');
  const imageUrl = buildProviderImageUrl(paymentProvider.providerName);
  const description = getDescription(paymentProvider.providerName, t);

  return (
    <>
      <Card className='payment-provider-card'>
        <Row className='p-3 pb-0 g-0 align-items-center'>
          <Col md='10'>
            <Card.Img
              variant='top'
              src={imageUrl}
              alt={`${paymentProvider.displayName} Logo`}
            />
          </Col>
          <Col className='d-flex justify-content-end' md='2'>
            <NavLink onClick={() => onSelected(paymentProvider)}>
              Select
            </NavLink>
          </Col>
        </Row>
        <Card.Body>
          <Card.Text as='div'>{description}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentProviderSelectionCard;
