import lang from "i18next";
import { Transaction } from "../../models/transactions/transaction";
import { TransactionsApi } from "../../common/api/payThemApi/transactionsApi";
import { toastSuccess } from "../notifications/toastNotification.service";
import { Document } from "../../models/documents/document";
import { SettlementOffer } from "../../models/settlements/settlementOffer";
import { tCommon } from "../../i18n";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

// import { toastErrorObject, toastSuccess, toastError } from "../notifications/toastNotification.service";
// import lang from "i18next";
// const LangOptions = { ns: "common" };

export const getTransaction = async (id: string): Promise<Transaction | undefined> => {
  const api = new TransactionsApi();
  const result = await api.getTransaction(id);
  return result;
};
export default getTransaction;

/*
 * Returns the number of transactions matching the given filter
 */
export const countTransactions = async (filter: string): Promise<number> => {
  try {
    const api = new TransactionsApi();
    const result = await api.getTransactionCount(filter);
    return result.totalItems;
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    throw error;
  }
};

/*
 * Uploads the document and attaches it to the transaction
 */
export const uploadTransactionDocument = async (id: string, file: File): Promise<Document | undefined> => {
  const api = new TransactionsApi();
  try {
    const result = await api.uploadDocument(id, file);
    toastSuccess(lang.t("toastMessages.transactions.documentUploadSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.documentUpload");
  }
  return undefined;
};

/*
 * Gets the data for the given transaction document
 */
export const getDocumentData = async (transactionId: string, documentId: string): Promise<File | undefined> => {
  const api = new TransactionsApi();
  try {
    const result = await api.getDocumentData(transactionId, documentId);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.documentGet");
  }
  return undefined;
};

/*
 * Deletes the document associated to the transaction
 */
export const deleteDocument = async (transactionId: string, documentId: string): Promise<void> => {
  const api = new TransactionsApi();
  try {
    await api.deleteDocument(transactionId, documentId);
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.documentDelete");
  }
};

/*
 * Adds a new settlement offer to the transaction
 */
export const addSettlementOffer = async (
  transactionId: string,
  settlementOffer: SettlementOffer
): Promise<Transaction | undefined> => {
  const api = new TransactionsApi();
  try {
    const result = await api.addSettlementOffer(transactionId, settlementOffer);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.settlementOfferAdd");
  }
  return undefined;
};

/*
 * Updates a new settlement offer to the transaction
 */
export const updateSettlementOffer = async (
  transactionId: string,
  settlementOffer: SettlementOffer
): Promise<Transaction | undefined> => {
  const api = new TransactionsApi();
  try {
    const result = await api.updateSettlementOffer(transactionId, settlementOffer);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.settlementOfferUpdate");
  }
  return undefined;
};

/*
 * Deletes the settlement offer associated to the transaction
 */
export const deleteSettlementOffer = async (transactionId: string, settlementOfferId: string): Promise<void> => {
  const api = new TransactionsApi();
  try {
    await api.deleteSettlementOffer(transactionId, settlementOfferId);
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.settlementOfferDelete");
  }
};

/*
 * Triggers a rebuild of the custom host id
 */
export const rebuildCustomHostId = async (): Promise<void> => {
  const api = new TransactionsApi();
  try {
    await api.rebuildCustomHostId();
  } catch (error) {
    handleApiErrorWithToast(error, false, "transactions.rebuildCustomHostId");
  }
};
