import { ReactElement } from "react";
import Lottie from "lottie-react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import animationGeneralError from "../../../../assets/data/anim/general-error.json";
import { SettingsRouteConsts } from "../../../SettingsRouteConsts";
import { tPaymentProviders } from "../../../../i18n";

/*
 * Displays an error message when the Stripe connection process fails
 */
const StripeConnectionErrorPage = ({ errorMessage }: { errorMessage: string }): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);
  return (
    <Container fluid style={{ paddingTop: "0", marginTop: "0" }}>
      <Row>
        <Col className='p-0'>
          <div className='d-flex flex-column align-items-center' style={{ paddingTop: "0", marginTop: "0" }}>
            <h4 className='error-message mt-4'>{errorMessage}</h4>
            <div style={{ width: "55%", marginTop: "0px", marginBottom: "0px", zIndex: 0 }}>
              <Lottie animationData={animationGeneralError} title='title' />
            </div>
            <Link to={SettingsRouteConsts.PaymentProviders}>{t("paymentProviders.labelBackToProvider")}</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StripeConnectionErrorPage;
