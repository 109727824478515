import { ReactElement } from "react";
import { BadgeSize } from "../../models/misc/badgeSize";
import { PaymentMethodType, PaymentMethodTypeMap } from "../../models/paymentProviders/paymentMethodType";

interface PaymentMethodBadgeProps {
  paymentMethodType: PaymentMethodType;
  size?: BadgeSize;
  className?: string;
}

/*
 * Generates payment method badge
 */
const PaymentMethodBadge = ({ paymentMethodType, size, className }: PaymentMethodBadgeProps): ReactElement => {
  let newClassName = className ? `${className} ` : "";
  newClassName += `common-badge`;
  if (size) newClassName += ` ${size}-size`;
  return <div className={newClassName}>{PaymentMethodTypeMap.get(paymentMethodType)?.toLocaleUpperCase()}</div>;
};

PaymentMethodBadge.defaultProps = {
  size: BadgeSize.Medium,
  className: "",
};

export default PaymentMethodBadge;
