import { useQuery } from "react-query";
import { getDatasetIdFromStorage } from "../../helpers/storageHelpers";
import { getCompanyAccountInfo } from "../../services/settings/settings.service";
import { QueryNameConsts } from "../queryNameConsts";

/*
 * Creates a name to use to save settings based on the dataset currently in use
 */
const buildDatasetStorageName = (tenantId: string, name: string): string => `${tenantId}_ds${getDatasetIdFromStorage()}_${name}`;

/*
 * Returns the Dataset storage key name for local storage usage
 */
const useDatasetStorageName = (name: string): string => {  
  const { data: companyAccountInfo } = useQuery([QueryNameConsts.CompanyAccountInfo], () => getCompanyAccountInfo());
  const companyId = companyAccountInfo?.companyId ?? "";

  return buildDatasetStorageName(companyId, name);
}

export default useDatasetStorageName;