import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Card } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { PageTitle } from "../common/PageTitle";
import { CompanySettings, CompanySettingsConsts } from "../../models/companySettings/companySettingsModels";
import { FormGroupControl } from "../../components/forms/FormGroupControl";
import { getCompanySettings, updateCompanySettings } from "../../services/settings/settings.service";
import PageLoading from "../../components/loading/PageLoading";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";
import "../viewsCommon.scss";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

type Inputs = {
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  website: string;
  taxNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
};

// Maps the values from Inputs into the CompanySettings instance
const MapInputsToCompanySettings = (source: Inputs, currentCompanySettings: CompanySettings): CompanySettings => {
  if (source === null) throw new Error("Source cannot be null");
  if (currentCompanySettings === null) throw new Error("Destination cannot be null");

  const result = { ...currentCompanySettings };
  result.name = source.companyName;
  result.companyDetails = {
    name: source.companyName,
    website: source.website,
    taxCode: source.taxNumber,
    mainAddress: {
      firstNames: source.firstName,
      surname: source.lastName,
      email: source.emailAddress,
      telephone: source.phoneNumber,
      address1: source.address1,
      address2: source.address2,
      city: source.city,
      state: source.state,
      postCode: source.postCode,
      country: source.country,
    },
  };
  return result;
};

const validationSchema = Yup.object({
  companyName: Yup.string()
    .label("Company name")
    .required()
    .min(CompanySettingsConsts.CompanyNameMinLength)
    .max(CompanySettingsConsts.CompanyNameMaxLength),
  firstName: Yup.string().label("First name").required().max(CompanySettingsConsts.FirstNamesMaxLength),
  lastName: Yup.string().label("Last name").required().max(CompanySettingsConsts.SurnameMaxLength),
  emailAddress: Yup.string().label("Email address").required().email().max(CompanySettingsConsts.EmailAddressMaxLength),
  phoneNumber: Yup.string().label("Phone number").max(CompanySettingsConsts.TelephoneMaxLength),
  website: Yup.string()
    .label("Website")
    .url("Website must be a valid URL and begin with https://")
    .max(CompanySettingsConsts.WebsiteMaxLength),
  taxNumber: Yup.string().label("Tax/VAT number").max(CompanySettingsConsts.TaxCodeMaxLength),
  address1: Yup.string().label("Address").required().max(CompanySettingsConsts.Address1MaxLength),
  address2: Yup.string().label("Address 2").max(CompanySettingsConsts.Address2MaxLength),
  city: Yup.string().label("City").required().max(CompanySettingsConsts.CityMaxLength),
  state: Yup.string().label("State/County").required().max(CompanySettingsConsts.StateMaxLength),
  postCode: Yup.string().label("Zip/Postal Code").required().max(CompanySettingsConsts.PostCodeMaxLength),
  country: Yup.string().label("Country").required().max(CompanySettingsConsts.CountryMaxLength),
}).required();

export const CompanyDetails = (): ReactElement => {
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation(["forms", "pages"]);
  const [companySettings, setCompanySettings] = useState<CompanySettings>();
  const disabled = !usePermission(UserPermission.ConfigureSystemSettings);

  useEffect(() => {
    getCompanySettings().then((result) => {
      setCompanySettings(result);
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(validationSchema) });

  const saveSettings = (data: Inputs) => {
    setSaving(true);
    getCompanySettings()
      .then((result) => {
        if (result) {
          const newCompanySettings = MapInputsToCompanySettings(data, result);
          updateCompanySettings(newCompanySettings);
        }
      })
      .finally(() => setSaving(false));
  };

  if (!companySettings) {
    return <PageLoading />;
  }

  const { companyDetails } = companySettings;
  return (
    <section className='item-view-section'>
      <HtmlTitle subTitle1={t("pageTitleCompanyDetails", tPages)} />
      <PageTitle title='pageTitleCompanyDetails' />
      <Row>
        <Col className='panel-column'>
          <Form noValidate onSubmit={handleSubmit((data) => saveSettings(data))}>
            <Card>
              <Card.Body>
                <Row>
                  <FormGroupControl
                    labelName={t("labelCompanyName")}
                    controlPlaceholder={t("placeholderCompanyName")}
                    controlDefaultValue={companyDetails?.name}
                    registerReturn={register("companyName")}
                    errorMessage={errors?.companyName?.message}
                    as={Col}
                    sm='12'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    labelName={t("labelFirstName")}
                    controlPlaceholder={t("placeholderFirstName")}
                    controlDefaultValue={companyDetails?.mainAddress?.firstNames}
                    registerReturn={register("firstName")}
                    errorMessage={errors?.firstName?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    labelName={t("labelLastName")}
                    controlPlaceholder={t("placeholderLastName")}
                    controlDefaultValue={companyDetails?.mainAddress?.surname}
                    registerReturn={register("lastName")}
                    errorMessage={errors?.lastName?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    type='email'
                    labelName={t("labelEmailAddress")}
                    controlPlaceholder={t("placeholderEmailAddress")}
                    controlDefaultValue={companyDetails?.mainAddress?.email}
                    registerReturn={register("emailAddress")}
                    errorMessage={errors?.emailAddress?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    labelName={t("labelPhoneNumber")}
                    controlPlaceholder={t("placeholderPhoneNumber")}
                    controlDefaultValue={companyDetails?.mainAddress?.telephone}
                    registerReturn={register("phoneNumber")}
                    errorMessage={errors?.phoneNumber?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    labelName={t("labelWebsite")}
                    controlPlaceholder={t("placeholderWebsite")}
                    controlDefaultValue={companyDetails?.website}
                    registerReturn={register("website")}
                    errorMessage={errors?.website?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    labelName={t("labelTaxNumber")}
                    controlPlaceholder={t("placeholderTaxNumber")}
                    controlDefaultValue={companyDetails?.taxCode}
                    registerReturn={register("taxNumber")}
                    errorMessage={errors?.taxNumber?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                </Row>
                <hr />
                <Row>
                  <FormGroupControl
                    labelName={t("labelAddress1")}
                    controlPlaceholder={t("placeholderAddress1")}
                    controlDefaultValue={companyDetails?.mainAddress?.address1}
                    registerReturn={register("address1")}
                    errorMessage={errors?.address1?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                </Row>
                <Row>
                  <FormGroupControl
                    labelName={t("labelAddress2")}
                    controlPlaceholder={t("placeholderAddress2")}
                    controlDefaultValue={companyDetails?.mainAddress?.address2}
                    registerReturn={register("address2")}
                    errorMessage={errors?.address2?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                  <FormGroupControl
                    labelName={t("labelCity")}
                    controlPlaceholder={t("placeholderCity")}
                    controlDefaultValue={companyDetails?.mainAddress?.city}
                    registerReturn={register("city")}
                    errorMessage={errors?.city?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />

                  <FormGroupControl
                    labelName={t("labelState")}
                    controlPlaceholder={t("placeholderState")}
                    controlDefaultValue={companyDetails?.mainAddress?.state}
                    registerReturn={register("state")}
                    errorMessage={errors?.state?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />

                  <FormGroupControl
                    labelName={t("labelPostCode")}
                    controlPlaceholder={t("placeholderPostCode")}
                    controlDefaultValue={companyDetails?.mainAddress?.postCode}
                    registerReturn={register("postCode")}
                    errorMessage={errors?.postCode?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />

                  <FormGroupControl
                    labelName={t("labelCountry")}
                    controlPlaceholder={t("placeholderCountry")}
                    controlDefaultValue={companyDetails?.mainAddress?.country}
                    registerReturn={register("country")}
                    errorMessage={errors?.country?.message}
                    as={Col}
                    sm='6'
                    disabled={disabled}
                  />
                </Row>
                <SpinnerButton type='submit' title={t("buttonSave")} showSpinner={saving} disabled={disabled} />
              </Card.Body>
            </Card>
          </Form>
        </Col>
      </Row>
    </section>
  );
};

export default CompanyDetails;
