export enum LinkSize {
  Long = "Long",
  Short = "Short",
}

export const LinkSizeMap = new Map<LinkSize, string>([
  [LinkSize.Long, "Long"],
  [LinkSize.Short, "Short"],
]);

export const LinkSizeArray = Array.from(LinkSizeMap.entries());
