import { DefaultBlankText } from "../common/commonConsts";

/*
 * True if the string is lowercase
 */
export function isLowerCase(str: string): boolean {
  return str === str.toLowerCase() && str !== str.toUpperCase();
}

/*
 * True if the string is uppercase
 */
export function isUpperCase(str: string): boolean {
  return !isLowerCase(str);
}

/*
 * Adds spaces between words starting with capitals
 */
export function addSpacesToSentence(text: string, preserveAcronyms = true): string {
  if (!text) return "";

  let newText = "";

  newText += text[0];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < text.length; i++) {
    if (isUpperCase(text[i])) {
      if (
        (text[i - 1] !== " " && !isUpperCase(text[i - 1])) ||
        (preserveAcronyms && isUpperCase(text[i - 1]) && i < text.length - 1 && !isUpperCase(text[i + 1]))
      ) {
        newText += " ";
      }
    }
    newText += text[i];
  }
  return newText;
}

/*
 * Returns the text or the default text if string is null or undefined
 */
export function textOrDefault(text: string | null | undefined, defaultText: string = DefaultBlankText): string {
  return text || defaultText;
}
