import { AddPaymentMethodConfiguration, PaymentMethodConfiguration } from "./paymentMethodConfiguration";

export interface PaymentProviderName {
  providerName: string;
}

export type PaymentProviderSettings = PaymentProviderName;

export type PaymentProviderCredentials = PaymentProviderName;

export interface PaymentProviderConfiguration {
  //  Unique id for this payment provider configuration
  id: string;

  // User given name of this configuration
  name: string;

  // Name of the payment provider
  providerName: string;

  // Description (optional)
  description?: string;

  // True if this provider configuration is enabled, false if not
  enabled: boolean;

  // Priority on how to display this configuration (0 being the lowest)
  displayPriority: number;

  // Settings for the payment provider
  settings: PaymentProviderSettings | undefined;

  // Credentials for the payment provider
  credentials: PaymentProviderCredentials | undefined;

  // Configurations for each payment method type this provider supports
  paymentMethodConfigurations: PaymentMethodConfiguration[];

  // True if this is for testing
  isTest: boolean;

  // True if the record has been deleted
  isDeleted: boolean;
}

export interface PaymentProviderTypeConfiguration<
  TSettings extends PaymentProviderSettings | undefined,
  TCredentials extends PaymentProviderCredentials | undefined
> extends PaymentProviderConfiguration {
  settings: TSettings;
  credentials: TCredentials;
}

export interface AddPaymentProviderConfiguration {
  name: string;
  providerName: string;
  description?: string;
  displayPriority: number;
  enabled: boolean;
  settings: PaymentProviderSettings | undefined;
  credentials: PaymentProviderCredentials | undefined;
  paymentMethodConfigurations: AddPaymentMethodConfiguration[];
  isTest: boolean;
}

export interface AddPaymentProviderTypeConfiguration<
  TSettings extends PaymentProviderSettings | undefined,
  TCredentials extends PaymentProviderCredentials | undefined
> extends AddPaymentProviderConfiguration {
  settings: TSettings;
  credentials: TCredentials;
}

/*
 * Constructor for AddPaymentProviderConfiguration
 */
export const initAddPaymentProviderTypeConfiguration = (
  providerName: string,
  name: string
): AddPaymentProviderConfiguration => {
  const data: AddPaymentProviderConfiguration = {
    providerName,
    name,
    enabled: false,
    displayPriority: 0,
    isTest: false,
    settings: undefined,
    credentials: undefined,
    paymentMethodConfigurations: [],
  };
  return data;
};
