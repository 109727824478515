import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Placeholder } from "react-bootstrap";
import { StripeWebApi } from "../../../../../common/api/payThemApi/stripeWebApi";
import { QueryNameConsts } from "../../../../../common/queryNameConsts";
import { tPaymentProviders } from "../../../../../i18n";
import StripeConnectButton from "./StripeConnectButton";
import StripeAccountInformationBody from "./StripeAccountInformationBody";
import { StripeAccountInformation } from "../../../../../models/paymentProviders/stripe/stripeModels";

const keyPrefix = "stripeForm";

interface StripeConnectionCardBodyProps {
  providerId?: string;
  stripeAccountId?: string;
  onConnect: () => void;
  onDisconnect: () => void;
  onStripeAccountInformation: (account: StripeAccountInformation) => void;
  hasConnection: boolean;
}

/*
 * Works out what to display in the card body
 */
const StripeConnectionCardBody = ({
  providerId,
  stripeAccountId,
  onConnect,
  onDisconnect,
  onStripeAccountInformation,
  hasConnection,
}: StripeConnectionCardBodyProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  // Load in the information
  const { isLoading, error, data } = useQuery(
    [QueryNameConsts.StripeAccountInformation, stripeAccountId, providerId],
    async () => {
      if (!stripeAccountId || !providerId) return undefined;
      const api = new StripeWebApi();
      const result = await api.getAccountInformationFromProviderConfiguration(providerId);
      onStripeAccountInformation(result);
      return result;
    }
  );

  // Return the connect button if we don't have a connection
  if (!hasConnection) {
    return (
      <>
        <p>{t("messageStripeConnect")}</p>
        <StripeConnectButton hasConnection={hasConnection} onConnect={onConnect} onDisconnect={onDisconnect} />
      </>
    );
  }

  if (isLoading) {
    return (
      <Placeholder animation='glow'>
        <p aria-hidden='true'>
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={6} />
        </p>
        <Placeholder.Button xs={4} aria-hidden='true' />
      </Placeholder>
    );
  }

  if (error) {
    return <p>{t("messageStripeAccountNotValid")}</p>;
  }

  const connection = {
    onConnect,
    onDisconnect,
    hasConnection,
  };

  return <StripeAccountInformationBody account={data} connection={connection} />;
};

StripeConnectionCardBody.defaultProps = {
  providerId: undefined,
  stripeAccountId: undefined,
};

export default StripeConnectionCardBody;
