import { ReactElement } from "react";
import { Card, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import GoCardlessWebApi from "../../../../common/api/payThemApi/goCardlessApi";
import { QueryNameConsts } from "../../../../common/queryNameConsts";
import { tPaymentProviders } from "../../../../i18n";
import CreditorInfo from "../../../../models/paymentProviders/goCardless/creditorInfo";
import { GoCardlessPaymentProviderConfiguration } from "../../../../models/paymentProviders/goCardless/goCardlessModels";
import { VerificationStatus, VerificationStatusMap } from "../../../../models/paymentProviders/goCardless/verificationStatus";

const keyPrefix = "goCardlessForm";

interface DetailsBodyProps {
  organisationId: string | undefined;
  creditorInfo: CreditorInfo;
}

/*
 *  Displays details of the creditor info
 */
const DetailsBody = ({ organisationId, creditorInfo }: DetailsBodyProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  const actionRequired = creditorInfo.status === VerificationStatus.ActionRequired;
  const statusText = VerificationStatusMap.get(creditorInfo.status);

  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{t("labelOrganisationId")}</Form.Label>
            <Form.Control type='text' className='form-control' defaultValue={organisationId} disabled />
          </Form.Group>
        </Col>
        <Col md='4'>
          <Form.Group>
            <Form.Label>{t("labelName")}</Form.Label>
            <Form.Control type='text' className='form-control' defaultValue={creditorInfo.name} disabled />
          </Form.Group>
        </Col>
        <Col md='6'>
          <Form.Group>
            <Form.Label>{t("labelStatus")}</Form.Label>
            <Form.Control type='text' className='form-control' defaultValue={statusText} disabled />
          </Form.Group>
          {actionRequired && (
            <Alert variant='danger'>
              <div>{t("messageVerifyAccount")}</div>
              <a href={creditorInfo.verifyUrl}>{t("linkContinueOnboarding")}</a>
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

interface GoCardlessCreditorDetailsProps {
  paymentProvider: GoCardlessPaymentProviderConfiguration | undefined;
}

/*
 * Reads creditor information and displays in a card
 */
const GoCardlessCreditorDetails = ({ paymentProvider }: GoCardlessCreditorDetailsProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  // Load in the information
  const { isLoading, data } = useQuery([QueryNameConsts.GoCardlessCreditorInformation, paymentProvider?.id], () => {
    if (!paymentProvider?.id) return undefined;
    const api = new GoCardlessWebApi();
    const result = api.getCreditorInfoFromProvider(paymentProvider?.id);
    return result;
  });

  if (isLoading) return <Spinner animation='border' />;

  return (
    <Card className='custom-detail-card mb-3'>
      <Card.Body>
        <Card.Title>{t("titleGoCardlessInformation")}</Card.Title>
        {data && <DetailsBody organisationId={paymentProvider?.credentials?.organisationId} creditorInfo={data} />}
      </Card.Body>
    </Card>
  );
};

export default GoCardlessCreditorDetails;
