import { ReactElement, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import CustomToggle from "../../components/misc/CustomToggle";
import { tPages } from "../../i18n";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { PaymentTransactionStatus } from "../../models/paymentTransactions/paymentTransactionEnums";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "paymentTransactionPage.details.menu";

interface PaymentTransactionItemMenuProps {
  paymentTransaction: PaymentTransaction;
  onCapturePending: (id: string) => void;
  onCancelPending: (id: string) => void;
  // onDelete: (id: string) => void;
}

type DialogType = "capture" | "cancel";

/*
 * Triple dot menu options
 */
const PaymentTransactionItemMenu = ({
  paymentTransaction,
  onCapturePending,
  onCancelPending,
}: PaymentTransactionItemMenuProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<DialogType>("cancel");
  const disabledCapturePayment = !usePermission(UserPermission.CapturePendingPayment);
  const disabledCancelPayment = !usePermission(UserPermission.CancelPendingPayment);

  const isPending = paymentTransaction.status === PaymentTransactionStatus.Pending;
  if (!isPending) return <></>;

  const showConfirmationDialog = (type: DialogType) => {
    setDialogType(type);
    setShowDialog(true);
  };

  const handlePositiveConfirmation = (type?: DialogType) => {
    if (type === "capture") onCapturePending(paymentTransaction.id);
    if (type === "cancel") onCancelPending(paymentTransaction.id);
    setShowDialog(false);
  };

  return (
    <div id={paymentTransaction.id}>
      <Dropdown align='end' className='three-dots-menu actionmenu-dropdown-title'>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu title=''>
          <Dropdown.Item
            //   disabled={settlementOffer.status === SettlementOfferStatus.Used}
            disabled={disabledCapturePayment}
            onClick={() => showConfirmationDialog("capture")}
          >
            {t("menuItemCapturePending")}
          </Dropdown.Item>
          <Dropdown.Item
            //   disabled={settlementOffer.status === SettlementOfferStatus.Used}
            disabled={disabledCancelPayment}
            onClick={() => showConfirmationDialog("cancel")}
          >
            {t("menuItemCancelPending")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmationDialog
        show={showDialog}
        data={dialogType}
        title={t(dialogType === "capture" ? "menuItemCapturePending" : "menuItemCancelPending")}
        body={t(dialogType === "capture" ? "confirmCapturePendingMessage" : "confirmCancelPendingMessage")}
        isPositiveConfirmation={dialogType === "capture"}
        handleClose={() => setShowDialog(false)}
        handleConfirmation={handlePositiveConfirmation}
      />
    </div>
  );
};

export default PaymentTransactionItemMenu;
