/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { DialogSecondaryButton, DialogPrimaryButton } from "../../../components/dialogs/DialogButtons";
import { tForms, tPages } from "../../../i18n";
import { TransactionAllocationInfo } from "../../../models/paymentRequests/paymentRequest";
import NullBooleanFormSelect from "../../../components/Selects/NullBooleanFormSelect";
import { Transaction } from "../../../models/transactions/transaction";

export interface EditFormModel {
  // The amount of the payment to allocate to this transaction (optional)
  amount: number | null;

  // True (default) if this transaction is required and cannot be uselected. False if not
  required?: boolean;

  // True if the pay is allowed to edit the amount that can paid (optional)
  canEditAmount?: boolean;

  // If editable, the minimum that can be paid against this transaction (optional)
  minimumAmount?: number;

  // If editable, the maximum that can be paid against this transaction (optional)
  maximumAmount?: number;
}
const keyPrefix = "paymentRequestPage.transactionEditDialog";

// Our validation schema
const validationSchema = Yup.object({
  amount: Yup.number()
    .label("Amount To Collect")
    .nullable(true)
    .moreThan(0)
    .transform((_, val) => (val ? Number(val) : null)),
  minimumAmount: Yup.number()
    .label("Minimum Amount")
    .nullable(true)
    .moreThan(0)
    .transform((_, val) => (val ? Number(val) : null)),
  maximumAmount: Yup.number()
    .label("Maximum Amount")
    .nullable(true)
    .moreThan(Yup.ref("minimumAmount"), "Maximum Amount must be greater than 0 and/or Minimum Amount")
    .transform((_, val) => (val ? Number(val) : null)),
}).required();

interface EditTransactionAllocationInfoDialogProps {
  transaction: Transaction;
  transactionAllocationInfo: TransactionAllocationInfo;
  show: boolean;
  onSave: (model: EditFormModel) => Promise<void>;
  onClose: () => void;
  onClosed: () => void;
}

/*
 * Dialog for editing a transaction allocation info item
 */
const EditTransactionAllocationInfoDialog = ({
  transaction,
  transactionAllocationInfo,
  show,
  onSave,
  onClose,
  onClosed,
}: EditTransactionAllocationInfoDialogProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const { t: tF } = useTranslation(tForms.ns);
  const [amountEditable, setAmountEditable] = useState<boolean>(transactionAllocationInfo.canEditAmount === true);
  const [saving, setSaving] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<EditFormModel>({ resolver: yupResolver(validationSchema) });

  const canEditAmountWatch = watch("canEditAmount", transactionAllocationInfo.canEditAmount === true);

  // Disable or enable the min and max amount fields based on the canEditAmount field
  useEffect(() => {
    setAmountEditable(canEditAmountWatch === true);
  }, [canEditAmountWatch]);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show, reset]);

  const handleSave = async (saveData: EditFormModel) => {
    try {
      const updatedModel = { ...saveData };
      setSaving(true);
      await onSave(updatedModel);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal size='lg' show={show} onHide={() => onClose()} onExited={onClosed}>
      <Modal.Header closeButton>
        <Modal.Title>{t("titleEditTransactionAllocationInfo")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id='editTransactionAllocationInfoForm'
          className='dialog-form'
          noValidate
          onSubmit={handleSubmit((submitData) => handleSave(submitData))}
        >
          <div className='dialog-card'>
            <Row>
              <Col md='6'>
                <Form.Group>
                  <Form.Label>{t("labelAmount")}</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder={t("placeHolderAmount")}
                    {...register("amount")}
                    className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                    defaultValue={transactionAllocationInfo.amount ?? undefined}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.amount?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <Form.Label>{t("labelRequired")}</Form.Label>
                  <Col xs='12'>
                    <NullBooleanFormSelect
                      register={register}
                      fieldName='required'
                      nullOption={t("labelRequiredDefaultOption")}
                      trueOption={t("labelRequiredTrueOption")}
                      falseOption={t("labelRequiredFalseOption")}
                      defaultState={transactionAllocationInfo.required}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </div>{" "}
          <div className='dialog-card last'>
            <Row>
              <Col md='6'>
                <Form.Group>
                  <Form.Label>{t("labelCanEditAmount")}</Form.Label>
                  <Col xs='12'>
                    <NullBooleanFormSelect
                      register={register}
                      fieldName='canEditAmount'
                      nullOption={t("labelCanEditDefaultOption")}
                      trueOption={t("labelCanEditTrueOption")}
                      falseOption={t("labelCanEditFalseOption")}
                      defaultState={transactionAllocationInfo.canEditAmount}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Form.Group>
                  <Form.Label>{t("labelMinimumAmount")}</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder={t("placeHolderMinimumAmount")}
                    {...register("minimumAmount")}
                    className={`form-control ${errors.minimumAmount ? "is-invalid" : ""}`}
                    defaultValue={transactionAllocationInfo.minimumAmount ?? undefined}
                    disabled={!amountEditable}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.minimumAmount?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md='6'>
                <Form.Group>
                  <Form.Label>{t("labelMaximumAmount")}</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder={t("placeHolderMaximumAmount")}
                    {...register("maximumAmount")}
                    className={`form-control ${errors.maximumAmount ? "is-invalid" : ""}`}
                    defaultValue={transactionAllocationInfo.maximumAmount ?? undefined}
                    disabled={!amountEditable}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.maximumAmount?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className='editing-warning'>
              <Col>{t("messageEditing")}</Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={() => onClose()} disabled={saving} />
        <DialogPrimaryButton
          form='editTransactionAllocationInfoForm'
          type='submit'
          label={tF("buttonUpdate")}
          disabled={saving}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EditTransactionAllocationInfoDialog;
