/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useState } from "react";
import { UseFormRegister, FieldValues, FieldErrors, UseFormSetValue } from "react-hook-form";
import { Row, Col, Card, Form, FormControl } from "react-bootstrap";
import { CurrentFormModel, CurrentPaymentMethodType, CurrentPaymentProviderConfiguration } from "./stripeConfiguration";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import {
  CommonProviderFormControls,
  ProviderFilterFormControl,
  ProviderSupportedMethodsFormControl,
  hasSupportedCurrencies,
  ProviderSupportedCurrenciesFormControl,
} from "../ProvidersCommon/CommonControls";
import { StripeConnectionDetails } from "./Connection/StripeConnectionDetails";
import { isAllowParentRedirect } from "../../../../components/websiteComms/WebsiteViewHelper";
import useWebsiteView from "../../../../context/websiteView/WebsiteViewContext";
import { StripeConnectService, StripeConnectType } from "../../../../services/stripe/stripeConnect.service";

interface StripeGeneralSettingsTabProps {
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  itemFilterGroup: ItemFilterGroup | undefined;
  onShowFilterDialog: () => void;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

/*
 * General settings for Stripe
 */
const StripeGeneralSettingsTab = ({
  data,
  paymentProvider,
  itemFilterGroup,
  onShowFilterDialog,
  register,
  setValue,
  errors,
}: StripeGeneralSettingsTabProps): ReactElement => {
  const [stripeAccountId, setStripeAccountId] = useState(data?.credentials?.stripeAccountId);
  const wv = useWebsiteView();

  //  Connect to Stripe button pressed
  const handleConnect = async () => {
    if (data) {
      if (isAllowParentRedirect(wv)) {
        await StripeConnectService.startParentStripeConnection(wv.parentUrl!, StripeConnectType.Edit, data.id);
      } else {
        await StripeConnectService.startLocalStripeConnection(StripeConnectType.Edit, data.id);
      }
    }
  };

  // Disconnect from Stripe button pressed
  const handleDisconnect = async () => {
    const service = new StripeConnectService();
    if (data && stripeAccountId) {
      await service.disconnectStripeAccount(data.id);
      setStripeAccountId(undefined);
      setValue("stripeAccountId", undefined);
    }
  };

  // For now Stripe only supports one payment method type
  const paymentMethodTypeInfo = paymentProvider?.paymentMethodTypesInfo.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <CommonProviderFormControls data={data} register={register} errors={errors} />
            <hr />
            <Row className='mb-2'>
              <Col>
                <ProviderFilterFormControl filterGroup={itemFilterGroup} onClick={onShowFilterDialog} />
              </Col>
              <ProviderSupportedMethodsFormControl
                supportedPaymentMethodGroups={paymentProvider?.supportedPaymentMethodGroups}
              />
              {hasSupportedCurrencies(paymentMethodTypeInfo) && (
                <>
                  <Form.Group as={Col} sm='6'>
                    {" "}
                  </Form.Group>
                  <ProviderSupportedCurrenciesFormControl supportedCurrencies={paymentMethodTypeInfo!.supportedCurrencies} />
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
        <FormControl type='hidden' {...register("stripeAccountId")} defaultValue={stripeAccountId} />
        <StripeConnectionDetails
          stripeAccountId={stripeAccountId}
          providerId={data?.id}
          isTestMode={data?.isTest ?? false}
          onConnect={handleConnect}
          onDisconnect={handleDisconnect}
        />
      </Col>
    </Row>
  );
};

StripeGeneralSettingsTab.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default StripeGeneralSettingsTab;
