import { IncludeOptions } from "./includeOptions";

export interface PaymentProviderConfigurationIncludeOptions extends IncludeOptions {
  paymentMethodConfigurations: boolean;
}

export const paymentProviderConfigurationIncludeAll: PaymentProviderConfigurationIncludeOptions = {
  paymentMethodConfigurations: true,
};

export const paymentProviderConfigurationIncludeNone: PaymentProviderConfigurationIncludeOptions = {
  paymentMethodConfigurations: true,
};
