import { ReactElement } from "react";
import { Row, Col, Alert, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPaymentProviders } from "../../../../../i18n";
import CommonBadge from "../../ProvidersCommon/CommonBadge";
import StripeConnectButton from "./StripeConnectButton";
import { StripeAccountInformation } from "../../../../../models/paymentProviders/stripe/stripeModels";

const keyPrefix = "stripeForm";

/*
 * Displays the Stripe account information
 */
const StripeAccountInformationBody = ({
  account,
  connection,
}: {
  account?: StripeAccountInformation;
  connection: {
    onConnect: () => void;
    onDisconnect: () => void;
    hasConnection: boolean;
  };
}): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  return (
    <>
      {account?.isLegacyAccount && <Alert variant='danger'>{t("stripeWarningLegacyAccount")}</Alert>}
      <Row>
        <Col>
          {account?.capabilities.cardPayments ? null : <Alert variant='danger'>{t("stripeWarningSupportedCard")}</Alert>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{t("labelAccountName")}</Form.Label>
            <Form.Control type='text' className='form-control' defaultValue={account?.displayName} disabled />
          </Form.Group>
        </Col>
        <Col md='6'>
          <Form.Group>
            <Form.Label>{t("labelDefaultCurrency")}</Form.Label>
            <div className='d-flex gap-1'>
              {account?.defaultCurrency ? <CommonBadge text={account?.defaultCurrency.toUpperCase()} /> : ""}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <Form.Group>
            <Form.Label>{t("labelAction")}</Form.Label>
            <div>
              <StripeConnectButton
                hasConnection={connection?.hasConnection}
                onConnect={connection?.onConnect}
                onDisconnect={connection?.onDisconnect}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

StripeAccountInformationBody.defaultProps = {
  account: undefined,
};

export default StripeAccountInformationBody;
