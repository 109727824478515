/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useRef, useState } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddOrEditItemProps,
  AddOrEditMode,
  ProviderFormValidationCommon,
  mapToAddPaymentMethodConfiguration,
  mapToPaymentMethodConfiguration,
} from "../ProvidersCommon/Common";
import { PageTitle } from "../../../common/PageTitle";

import { addConfiguration, updateConfiguration } from "../../../../services/paymentProviders/paymentProviders.service";
import { DefaultProvider } from "../DefaultProvider";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import PaymentProviderFiltersDialog from "../ProvidersCommon/PaymentProviderFiltersDialog";
import { tPaymentProviders } from "../../../../i18n";
import {
  CurrentFormModel,
  CurrentPaymentMethodType,
  CurrentPaymentProviderConfiguration,
  CurrentProviderDisplayName,
  CurrentProviderModelConsts,
  mapToAddConfiguration,
  mapToUpdateConfiguration,
} from "./tranSafeConfiguration";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import TranSafeGeneralSettingsTab from "./TranSafeGeneralSettingsTab";
import "../ProvidersCommon/Connect.scss";
import { TabArray, selectTabFromValidationErrors } from "../../../../helpers/hookForms/hookFormHelpers";
import TabRef from "../../../../components/Tabs/TabWithReference";
import ProviderButtonToolbar from "../ProvidersCommon/ProviderButtonToolbar";

const EventKeyGeneralSettings = "general";

interface CurrentItemProps extends AddOrEditItemProps<CurrentPaymentProviderConfiguration, CurrentFormModel> {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  onSave: () => void;
  paymentProvider?: PaymentProvider;
}

/*
 * Form validation schema
 */
const validationSchema = (isChecked: () => boolean) =>
  Yup.object({
    ...ProviderFormValidationCommon,
    isTest: Yup.boolean().required(),
    userName: Yup.lazy(() =>
      !isChecked()
        ? Yup.string()
            .label("User name")
            .required()
            .min(CurrentProviderModelConsts.UserNameMinLength)
            .max(CurrentProviderModelConsts.UserNameMaxLength)
        : Yup.string().notRequired()
    ),
    password: Yup.lazy(() =>
      !isChecked()
        ? Yup.string()
            .label("Password")
            .required()
            .min(CurrentProviderModelConsts.PasswordMinLength)
            .max(CurrentProviderModelConsts.PasswordMaxLength)
        : Yup.string().notRequired()
    ),
  }).required();

/*
 * The TranSafe add/edit component
 */
const TranSafeItem = ({ mode, data, paymentProvider, onSave }: CurrentItemProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns);
  const [disableUserDetails, setDisableUserDetails] = useState(data?.settings?.useTestSite ?? false);
  const [selectedTab, setSelectedTab] = useState<string | null>(EventKeyGeneralSettings);

  // For now TranSafe only supports one payment method type
  const paymentMethodConfiguration = data?.paymentMethodConfigurations.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  const [filterGroup, setFilterGroup] = useState<ItemFilterGroup | undefined>(paymentMethodConfiguration?.filterGroup);
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const tabRefs = useRef<TabArray>([]);

  const addMode = mode === "add";
  const providerId = data?.id;

  const schema = validationSchema(() => disableUserDetails);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>({ resolver: yupResolver(schema) });

  // Saves the form data
  const handleSave = async (model: CurrentFormModel): Promise<void> => {
    setSaving(true);
    if (!data) {
      const addConfig = mapToAddConfiguration(model);
      const addPaymentMethod = mapToAddPaymentMethodConfiguration(CurrentPaymentMethodType, model.enabled, filterGroup);
      addConfig.paymentMethodConfigurations = [addPaymentMethod];
      if (await addConfiguration(addConfig).finally(() => setSaving(false))) {
        onSave();
      }
    } else {
      const configuration = mapToUpdateConfiguration(data, model);
      const paymentMethod = mapToPaymentMethodConfiguration(paymentMethodConfiguration!, model.enabled, filterGroup);
      configuration.paymentMethodConfigurations = [paymentMethod];
      await updateConfiguration(configuration).finally(() => setSaving(false));
      onSave();
    }
  };

  // Handles validation errors on the form, and selects the tab with the first error
  const handleValidationErrors = (errorData: FieldErrors<CurrentFormModel>): void => {
    selectTabFromValidationErrors(tabRefs, errorData, setSelectedTab);
  };

  return (
    <section className='custom-connect-page'>
      <DefaultProvider paymentProviderId={providerId} allowSetDefault>
        <PageTitle
          title={t(addMode ? "paymentProviders.titleAddConfiguration" : "paymentProviders.titleEditConfiguration", {
            providerName: CurrentProviderDisplayName,
          })}
        />
      </DefaultProvider>
      <Form
        id='transafe-form'
        className='mt-2'
        noValidate
        onSubmit={handleSubmit(
          (submitData) => handleSave(submitData as CurrentFormModel),
          (errorData) => handleValidationErrors(errorData)
        )}
      >
        <Tabs
          defaultActiveKey={EventKeyGeneralSettings}
          className='mb-2'
          activeKey={selectedTab ?? EventKeyGeneralSettings}
          onSelect={(tab) => setSelectedTab(tab)}
        >
          <Tab eventKey={EventKeyGeneralSettings} title={t("commonTableTitles.generalSettingsTabTitle")}>
            <TabRef eventKey={EventKeyGeneralSettings} index={0} tabRefs={tabRefs}>
              <TranSafeGeneralSettingsTab
                mode={mode}
                data={data}
                paymentProvider={paymentProvider}
                itemFilterGroup={filterGroup}
                disableUserDetails={disableUserDetails}
                setDisableUserDetails={setDisableUserDetails}
                onShowFilterDialog={() => setShowFilterDialog(true)}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </TabRef>
          </Tab>
        </Tabs>
        <ProviderButtonToolbar saving={saving} />
      </Form>
      <PaymentProviderFiltersDialog
        filterGroup={filterGroup}
        show={showFilterDialog}
        onUpdate={(group) => {
          setShowFilterDialog(false);
          setFilterGroup(group);
        }}
        onClose={() => setShowFilterDialog(false)}
      />
    </section>
  );
};
TranSafeItem.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default TranSafeItem;
