/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ReactElement, useState } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SpinnerButton } from "../../components/buttons/SpinnerButton";
import { SettlementType } from "../../models/settlements/settlementEnums";
import { SettlementConfigurationGroup } from "../../models/settlements/settlementConfigurationGroup";
import { tForms } from "../../i18n";
import SettlementConfigurationGroupModelConsts from "../../models/settlements/settlementConfigurationGroupModelConsts";
import StandardTooltip from "../../components/tooltips/StandardTooltip";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "settlementConfigurationGroupForm";

export type SettlementConfigurationGroupFormModel = {
  type?: SettlementType;
  name: string;
  description: string;
  customerEnabled: boolean;
  transactionEnabled: boolean;
};

const validationSchema = Yup.object({
  name: Yup.string().label("Name").required().max(SettlementConfigurationGroupModelConsts.NameMaxLength),
  description: Yup.string().label("Description").max(SettlementConfigurationGroupModelConsts.DescriptionMaxLength),
}).required();

interface SettlementConfigurationGroupFormProps {
  configurationGroup: SettlementConfigurationGroup;
  onSave: (model: SettlementConfigurationGroupFormModel) => Promise<void>;
}

/*
 * For editing a the top level settlement configuration
 */
const SettlementConfigurationGroupForm = ({
  configurationGroup,
  onSave,
}: SettlementConfigurationGroupFormProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });
  const { t: tBase } = useTranslation(tForms.ns);
  const [saving, setSaving] = useState(false);

  const disabled = !usePermission(UserPermission.EditSettlementConfigurations);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SettlementConfigurationGroupFormModel>({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (model: SettlementConfigurationGroupFormModel) => {
    setSaving(true);
    const newModel = { ...model };
    newModel.name = model.name.trim();
    newModel.description = model.description.trim();
    await onSave(model);
    setSaving(false);
  };

  return (
    <Row>
      <Col className='panel-column'>
        <Form noValidate onSubmit={handleSubmit((model) => onSubmit(model))}>
          <Card>
            <Card.Body>
              <div className='d-flex justify-content-between'>
                <Row className='flex-grow-1'>
                  <Col className=''>
                    <Form.Group as={Col} sm='8'>
                      <Form.Label>{t("labelName")}</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={t("placeholderName")}
                        {...register("name")}
                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        defaultValue={configurationGroup.name}
                        disabled={disabled}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.name?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>{}</Form.Label>
                    <StandardTooltip text={t("tooltipEnableCustomers")} placement='left-start'>
                      <Form.Check
                        type='switch'
                        {...register("customerEnabled")}
                        label={t("labelEnableCustomers")}
                        defaultChecked={configurationGroup.customerEnabled}
                        disabled={disabled}
                      />
                    </StandardTooltip>
                  </Col>
                </Row>
              </div>
              <Row>
                <Form.Group>
                  <Form.Label>{t("labelDescription")}</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder={t("placeholderDescription")}
                    {...register("description")}
                    className={`form-control ${errors.description ? "is-invalid" : ""}`}
                    defaultValue={configurationGroup.description}
                    disabled={disabled}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.description?.message}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <SpinnerButton type='submit' title={tBase("buttonSave")} showSpinner={saving} disabled={disabled} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default SettlementConfigurationGroupForm;
