import { useLocation } from "react-router-dom";
import { SettingsRouteConsts } from "../../views/SettingsRouteConsts";

const tenantScopePathNames: string[] = [
  SettingsRouteConsts.AccessKeys,
  SettingsRouteConsts.Datasets,
  SettingsRouteConsts.Users,
  SettingsRouteConsts.WebHooks
];

/*
 * Verify if it is the path for tenant scope (no dataset related page)
 * Returns true when the path is for tenant scope page
 */
export function useTenantScopePage(): boolean {
  const location = useLocation();

  if (tenantScopePathNames.some(path => path.localeCompare(location.pathname, 'en-US', { sensitivity: 'base' }) === 0))
    return true;

  return false;
}
export default useTenantScopePage;
