export enum PaymentMethodGroup {
  // Credit cards/debit cards
  Card = "Card",

  // Direct bank to bank transfer
  BankTransfer = "BankTransfer",

  // GoCardless, Direct Debit
  BankDebit = "BankDebit",

  // Bank redirects
  BankRedirects = "BankRedirects",

  // Apple Pay, Google Pay etc.
  Wallets = "Wallets",

  // Klarna, Afterpay
  BuyNowPayLater = "BuyNowPayLater",
}

export const PaymentMethodGroupMap = new Map<PaymentMethodGroup, string>([
  [PaymentMethodGroup.Card, "Card"],
  [PaymentMethodGroup.BankTransfer, "Bank Transfer"],
  [PaymentMethodGroup.BankDebit, "Bank Debit"],
  [PaymentMethodGroup.BankRedirects, "Bank Redirects"],
  [PaymentMethodGroup.Wallets, "Wallets"],
  [PaymentMethodGroup.BuyNowPayLater, "Buy Now, Pay Later"],
]);

export const PaymentMethodGroupArray = Array.from(PaymentMethodGroupMap.entries());
