import { useQuery } from "react-query";
import { HostSystem } from "../../models/datasets/hostSystem";
import { DatasetsApi } from "../api/payThemApi/datasetsApi";
import { QueryNameConsts } from "../queryNameConsts";

export function useHostSystems(): HostSystem[] {
  const { data } = useQuery(QueryNameConsts.HostSystemsItems, () => {
    const api = new DatasetsApi();
    return api.getHostSystems();
  });
  return data ?? [];
}
export default useHostSystems;
