import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Breadcrumb } from "react-bootstrap";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { getTransaction } from "../../services/transactions/transactions.service";
import { DisplayLoadingOrError } from "../../components/loading/DisplayLoadingOrError";
import { CurrencyFormatter } from "../../utils/currencyFormatter";
import { Transaction } from "../../models/transactions/transaction";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import TransactionTabs from "./TransactionTabs";

/*
 * Generates the title for the transaction item page
 */
const Title = ({ transaction }: { transaction: Transaction }): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const title = t("pageTitleTransaction", {
    amount: CurrencyFormatter.toCurrencyStringWithoutSymbol(transaction.currency, transaction.transactionAmount),
  });
  return <div className='page-title'>{title}</div>;
};

export const TransactionItem = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([tPages.ns]);

  const { data, isLoading, isError, refetch } = useQuery([QueryNameConsts.TransactionItem, id], () => getTransaction(id ?? ""));

  const handleTransactionChanged = () => {
    refetch();
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentTransactions", tPages)} subTitle2={id} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !data}>
        <Breadcrumb>
          <CommonBreadcrumbs.Transactions />
        </Breadcrumb>
        {data && (
          <section className='item-view-section transaction'>
            <Title transaction={data} />
            <TransactionTabs transaction={data} onTransactionChanged={handleTransactionChanged} />
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default TransactionItem;
