import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";
import {
  AddTotalProcessingPaymentProviderConfiguration,
  TotalProcessingCredentials,
  TotalProcessingPaymentProviderConfiguration,
  TotalProcessingProviderDisplayName,
  TotalProcessingProviderModelConsts,
  TotalProcessingProviderName,
  TotalProcessingSettings,
} from "../../../../models/paymentProviders/totalProcessing/totalProcessingModels";
import { getNonFillerDisplayPasswordOrUndefined } from "../../../../utils/secureStringUtils";
import {
  ProviderFormModel,
  mapToAddPaymentProviderConfiguration,
  mapToPaymentProviderConfiguration,
} from "../ProvidersCommon/Common";

export interface TotalProcessingFormModel extends ProviderFormModel {
  entityId: string;
  accessToken: string;
  useSandbox: boolean;
  storePayerPaymentMethod: boolean;
  saveCardOptionDefault: boolean;
  brands: {
    AMEX: boolean;
    MASTERCARD: boolean;
    VISA: boolean;
    DINERS: boolean;
    DISCOVER: boolean;
    JCB: boolean;
    UNIONPAY: boolean;
    MASTERDEBIT: boolean;
    MAESTRO: boolean;
  };
}

export const CurrentProviderName = TotalProcessingProviderName;
export const CurrentProviderDisplayName = TotalProcessingProviderDisplayName;
export const CurrentPaymentMethodType = PaymentMethodType.Card;
export const CurrentProviderModelConsts = TotalProcessingProviderModelConsts;
export type CurrentSettings = TotalProcessingSettings;
export type CurrentCredentials = TotalProcessingCredentials;
export type CurrentPaymentProviderConfiguration = TotalProcessingPaymentProviderConfiguration;
export type CurrentAddPaymentProviderConfiguration = AddTotalProcessingPaymentProviderConfiguration;
export type CurrentFormModel = TotalProcessingFormModel;

/*
 * Creates the brands array from the form model
 */
export const buildBrandsArray = (model: CurrentFormModel): string[] => {
  const brands = Object.keys(model.brands).filter((key) => model.brands[key as keyof boolean]);
  return brands;
};

/*
 * Map the data from the form to a new configuration object
 */
export const mapToAddConfiguration = (model: CurrentFormModel): CurrentAddPaymentProviderConfiguration => {
  const configuration = mapToAddPaymentProviderConfiguration<CurrentAddPaymentProviderConfiguration>(
    model,
    CurrentProviderName
  );
  configuration.isTest = model.useSandbox;
  configuration.settings = {
    providerName: CurrentProviderName,
    storePayerPaymentMethod: model.storePayerPaymentMethod,
    saveCardOptionDefault: model.saveCardOptionDefault,
    brands: buildBrandsArray(model),
  } as CurrentSettings;

  configuration.credentials = {
    providerName: CurrentProviderName,
    useSandbox: model.useSandbox,
    entityId: model.entityId,
    accessToken: model.accessToken,
  } as CurrentCredentials;

  return configuration;
};

/*
 * Map the data from the form to an existing configuration object
 */
export const mapToUpdateConfiguration = (
  data: CurrentPaymentProviderConfiguration,
  model: CurrentFormModel
): CurrentPaymentProviderConfiguration => {
  const configuration = mapToPaymentProviderConfiguration(data, model);
  configuration.isTest = model.useSandbox;
  if (!configuration.settings) {
    configuration.settings = { providerName: CurrentProviderName } as CurrentSettings;
  }
  if (!configuration.credentials) {
    configuration.credentials = { providerName: CurrentProviderName } as CurrentCredentials;
  }
  configuration.settings.brands = buildBrandsArray(model);
  configuration.settings.storePayerPaymentMethod = model.storePayerPaymentMethod;
  configuration.settings.saveCardOptionDefault = model.saveCardOptionDefault;
  configuration.credentials.useSandbox = model.useSandbox;
  configuration.credentials.entityId = model.entityId;
  configuration.credentials.accessToken = getNonFillerDisplayPasswordOrUndefined(model.accessToken);
  return configuration;
};
