import { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AllowCustomerNotificationsAlert from "../../components/tenantSettingAlerts/AllowCustomerNotificationsAlert";
import AllowInternalNotificationsAlert from "../../components/tenantSettingAlerts/AllowInternalNotificationsAlert";
import { tPages } from "../../i18n";
import { HtmlTitle } from "../common/HtmlTitle";
import { PageTitle } from "../common/PageTitle";
import { RecentPaymentFailures } from "./RecentPaymentFailures";
import { RecentPayments } from "./RecentPayments";
import { TodosList } from "./Todos/TodosList";

export const Dashboard = (): ReactElement => {
  const { t } = useTranslation(["pages"]);
  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleHome", tPages)} />
      <section>
        <PageTitle title='Dashboard' />
        <AllowCustomerNotificationsAlert />
        <AllowInternalNotificationsAlert />
        <Row>
          <Col className='panel-column'>
            <TodosList />
          </Col>
        </Row>
        <Row>
          <Col className='panel-column' md={6} sm={12}>
            <RecentPayments />
          </Col>
          <Col className='panel-column' md={6} sm={12}>
            <RecentPaymentFailures />
          </Col>
        </Row>
      </section>
    </>
  );
};
export default Dashboard;
