import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { getHtmlSubPageTitle } from "../../helpers/titleHelpers";

export const HtmlTitle = ({ subTitle1, subTitle2 }: { subTitle1: string; subTitle2?: string }): ReactElement => {
  const title = getHtmlSubPageTitle(subTitle1, subTitle2);
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

HtmlTitle.defaultProps = {
  subTitle2: undefined,
};
export default HtmlTitle;
