import { ReactElement, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPaymentProviders } from "../../../../../i18n";
import StripeConnectionCardBody from "./StripeConnectionCardBody";
import { StripeAccountInformation } from "../../../../../models/paymentProviders/stripe/stripeModels";
import "./StripeConnectionDetails.scss";

const keyPrefix = "stripeForm";

/*
 * Generates a test status tag if needed
 */
const TestModeTag = ({ isTest }: { isTest: boolean }): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return isTest ? <div className='tag status-badge-warning tag-status test-tag-status'>{t("tagTestMode")}</div> : <></>;
};

/*
 * Generates a legacy status tag if needed
 */
const LegacyTag = ({ isLegacy }: { isLegacy: boolean }): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  return isLegacy ? (
    <div className='tag status-badge-warning tag-status legacy-tag-status'>{t("tagLegacyAccount")}</div>
  ) : (
    <></>
  );
};

export interface StripeConnectionDetailsProps {
  providerId?: string;
  stripeAccountId?: string;
  isTestMode: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
}

/*
 * Displays the Stripe account connection details
 */
export const StripeConnectionDetails = ({
  providerId,
  stripeAccountId,
  isTestMode,
  onConnect,
  onDisconnect,
}: StripeConnectionDetailsProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  const [stripeAccountInformation, setStripeAccountInformation] = useState<StripeAccountInformation>();

  // True if there is a stripe account associated
  const hasConnection = !!stripeAccountId;

  return (
    <Card className='custom-detail-card'>
      <Card.Body>
        <Card.Title className='details-title'>
          <span>{t("titleStripeAccountInformation")}</span>
          <div className='tag-items'>
            {stripeAccountInformation && <LegacyTag isLegacy={stripeAccountInformation.isLegacyAccount} />}
            <TestModeTag isTest={isTestMode} />
          </div>
        </Card.Title>
        <StripeConnectionCardBody
          providerId={providerId}
          stripeAccountId={stripeAccountId}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          onStripeAccountInformation={(value) => {
            setStripeAccountInformation(value);
          }}
          hasConnection={hasConnection}
        />
      </Card.Body>
    </Card>
  );
};

StripeConnectionDetails.defaultProps = {
  providerId: undefined,
  stripeAccountId: undefined,
};
