import lang from "i18next";
import PageResult from "../../common/api/PageResults";
import { PaymentProvidersApi } from "../../common/api/payThemApi/paymentProvidersApi";
import { PaymentProvider } from "../../models/paymentProviders/paymentProvider";
import {
  PaymentProviderConfiguration,
  AddPaymentProviderConfiguration,
} from "../../models/paymentProviders/paymentProviderConfiguration";
import { toastSuccess } from "../notifications/toastNotification.service";
import { tCommon } from "../../i18n";
import { CompanySettingsApi } from "../../common/api/payThemApi/companySettingsApi";
import { getAllPageItems } from "../../utils/pagingUtils";
import { PaymentProviderConfigurationIncludeOptions } from "../../common/api/payThemApi/paymentProviderConfigurationIncludeOptions";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

/*
 * Validates the user details before creating
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateNewPaymentProviderConfiguration(configuration: AddPaymentProviderConfiguration): boolean {
  // var this.providersApi.getConfigurationByName(configuration.name)
  // configuration;

  return true;
}

/*
 * Returns all payment provider types
 */
export const getPaymentProviders = async (): Promise<PaymentProvider[]> => {
  try {
    const api = new PaymentProvidersApi();
    return await api.getPaymentProviders();
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providersGet");
    throw error;
  }
};

/*
 * Returns a payment provider configuration
 */
export const getConfiguration = async (
  id: string,
  includeOptions?: PaymentProviderConfigurationIncludeOptions
): Promise<PaymentProviderConfiguration> => {
  try {
    const api = new PaymentProvidersApi();
    return await api.getConfiguration(id, includeOptions);
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providersGet");
    throw error;
  }
};

/*
 * Returns all payment provider configurations
 */
export const getAllConfigurations = async (
  includeOptions?: PaymentProviderConfigurationIncludeOptions
): Promise<PaymentProviderConfiguration[]> => {
  try {
    const api = new PaymentProvidersApi();
    const items = await getAllPageItems((page: number, itemsPerPage: number) =>
      api.getConfigurations(page, itemsPerPage, undefined, undefined, includeOptions)
    );
    return items;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providersGet");
    throw error;
  }
};

/*
 * Returns all payment provider configurations
 */
export const getConfigurations = async (
  page: number,
  itemsPerPage: number,
  sort?: string,
  filter?: string,
  includeOptions?: PaymentProviderConfigurationIncludeOptions
): Promise<PageResult<PaymentProviderConfiguration>> => {
  try {
    const api = new PaymentProvidersApi();
    return await api.getConfigurations(page, itemsPerPage, sort, filter, includeOptions);
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providerGet");
    throw error;
  }
};

/*
 * Add a new payment provider configuration
 */
export const addConfiguration = async (
  configuration: AddPaymentProviderConfiguration
): Promise<PaymentProviderConfiguration | undefined> => {
  if (!validateNewPaymentProviderConfiguration(configuration)) return undefined;

  try {
    const api = new PaymentProvidersApi();
    const result = await api.addConfiguration(configuration);

    // If there isn't a default provider, then set this one to be it
    const companySettingsApi = new CompanySettingsApi();
    const defaultProvider = await companySettingsApi.getDefaultPaymentProvider();
    if (!defaultProvider) await companySettingsApi.setDefaultPaymentProvider(result.id);

    toastSuccess(lang.t("toastMessages.paymentProviders.providerAddSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providerAdd");
    throw error;
  }
};

/*
 * Add a new payment provider configuration
 */
export const updateConfiguration = async (configuration: PaymentProviderConfiguration): Promise<void> => {
  if (!validateNewPaymentProviderConfiguration(configuration)) return;

  try {
    const api = new PaymentProvidersApi();
    await api.updateConfiguration(configuration);
    toastSuccess(lang.t("toastMessages.paymentProviders.providerUpdateSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providerUpdate");
    throw error;
  }
};

/*
 * Deletes the payment provider configuration with the id
 */
export const deleteConfiguraiton = async (id: string): Promise<void> => {
  try {
    const api = new PaymentProvidersApi();
    const result = await api.deleteConfiguration(id);
    toastSuccess(lang.t("toastMessages.paymentProviders.providerDeleteSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.providerDelete");
    throw error;
  }
};

/*
 * Checks if a duplicate payment provider already exists with this name
 */
export const duplicateExists = async (name: string, id?: string): Promise<boolean> => {
  const api = new PaymentProvidersApi();
  const configurations = await api.getConfigurationByName(name);
  const result = configurations.some((x) => x.id !== id);
  return result;
};

/*
 * Sets the payment provider configuration as the default
 */
export const setDefaultPaymentProvider = async (id: string): Promise<void> => {
  try {
    const api = new CompanySettingsApi();
    const result = await api.setDefaultPaymentProvider(id);
    toastSuccess(lang.t("toastMessages.paymentProviders.defaultProviderUpdateSuccess", LangOptions));
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.defaultProviderUpdate");
    throw error;
  }
};

/*
 * Gets the current default payment provider
 */
export const getDefaultPaymentProvider = async (): Promise<PaymentProviderConfiguration | undefined> => {
  try {
    const api = new CompanySettingsApi();
    const result = await api.getDefaultPaymentProvider();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.defaultProviderGet");
    throw error;
  }
};

/*
 * Checks if the given provider id is the current default payment provider
 */
export const isDefaultPaymentProvider = async (id: string): Promise<boolean> => {
  try {
    const result = await getDefaultPaymentProvider();
    return result?.id === id;
  } catch (error) {
    handleApiErrorWithToast(error, true, "paymentProviders.defaultProviderGet");
    throw error;
  }
};
