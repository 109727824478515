import { ReactElement } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PaymentRequestsApi } from "../../common/api/payThemApi/paymentRequestsApi";
import { DisplayLoadingOrError } from "../../components/loading/DisplayLoadingOrError";
import { PaymentRequest } from "../../models/paymentRequests/paymentRequest";
import { CurrencyFormatter } from "../../utils/currencyFormatter";
import { QueryNameConsts } from "../../common/queryNameConsts";
import "../viewsCommon.scss";
import { tPages } from "../../i18n";
import { HtmlTitle } from "../common/HtmlTitle";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import PaymentRequestTabs from "./PaymentRequestTabs";

/*
 * Generates the title for the payment request item page
 */
const Title = ({ paymentRequest }: { paymentRequest: PaymentRequest }): ReactElement => {
  const { t } = useTranslation([tPages.ns]);
  const value =
    paymentRequest && CurrencyFormatter.toCurrencyStringWithoutSymbol(paymentRequest?.currency, paymentRequest?.amountToPay);
  const title = t("pageTitlePaymentRequest", { transactionValue: value });
  return <div className='page-title'>{title}</div>;
};

/*
 * Displays the payment request info
 */
export const PaymentRequestItem = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([tPages.ns]);

  const { data, isLoading, isError, refetch } = useQuery([QueryNameConsts.PaymentRequestItem, id], () => {
    const api = new PaymentRequestsApi();
    return api.getPaymentRequest(id ?? "", true);
  });

  const handleRefresh = async () => {
    await refetch();
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentRequests", tPages)} subTitle2={id} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !data}>
        <Breadcrumb>
          <CommonBreadcrumbs.PaymentRequests />
        </Breadcrumb>
        {data && (
          <section className='item-view-section payment-request'>
            <Title paymentRequest={data} />
            <PaymentRequestTabs paymentRequest={data} onUpdate={handleRefresh} />
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default PaymentRequestItem;
