import { initCurrenciesFilterConfiguration } from "../../models/itemFiltering/currenciesFilterConfiguration";
import ItemFilterConfiguration from "../../models/itemFiltering/itemFilterConfiguration";
import ItemFilterGroup from "../../models/itemFiltering/itemFilterGroup";
import { initTransactionAmountFilterConfiguration } from "../../models/itemFiltering/transactionAmountFilterConfiguration";
import TransactionAmountFilterType from "../../models/itemFiltering/transactionAmountFilterType";
import { SettlementConfiguration } from "../../models/settlements/settlementConfiguration";
import { SettlementType } from "../../models/settlements/settlementEnums";
import { getCurrenciesFilterConfiguration, getTransactionAmountFilterConfiguration } from "../../utils/itemFilterUtils";
import { SettlementConfigurationFormModel } from "./settlementConfigurationFormModel";

/*
 * Maps the fields from a SettlementConfigurationFormModel to an existing SettlementConfiguration
 */
export const mapModelToExistingConfiguration = (
  currentConfiguration: SettlementConfiguration,
  model: SettlementConfigurationFormModel
): SettlementConfiguration => {
  const newConfiguration = { ...currentConfiguration };
  newConfiguration.description = model.description;
  newConfiguration.dateType = model.dateType;
  newConfiguration.numberOfDays = model.numberOfDays;

  if (!newConfiguration.filterGroup) {
    newConfiguration.filterGroup = { filterConfigurations: [] };
  }

  let transactionAmountFilterConfiguration = getTransactionAmountFilterConfiguration(
    newConfiguration.filterGroup.filterConfigurations
  );

  // Lets add or update the transaction filter
  if (!transactionAmountFilterConfiguration) {
    transactionAmountFilterConfiguration = initTransactionAmountFilterConfiguration(
      TransactionAmountFilterType.TransactionAmount,
      model.minimumTransactionValue,
      model.maximumTransactionValue
    );
    newConfiguration.filterGroup.filterConfigurations.push(transactionAmountFilterConfiguration);
  } else {
    transactionAmountFilterConfiguration.minimumTransactionValue = model.minimumTransactionValue;
    transactionAmountFilterConfiguration.maximumTransactionValue = model.maximumTransactionValue;
  }

  let currenciesFilterConfiguration = getCurrenciesFilterConfiguration(newConfiguration.filterGroup.filterConfigurations);
  if (!currenciesFilterConfiguration) {
    currenciesFilterConfiguration = initCurrenciesFilterConfiguration(undefined);
    newConfiguration.filterGroup.filterConfigurations.push(currenciesFilterConfiguration);
  }

  if (newConfiguration.type === SettlementType.PercentageDiscount) {
    newConfiguration.percentageDiscount = model.percentageDiscount;
    currenciesFilterConfiguration.currencies = model.filterCurrency ? [model.filterCurrency] : [];
  } else if (newConfiguration.type === SettlementType.AbsoluteDiscount) {
    newConfiguration.currency = model.currency;
    newConfiguration.amount = model.amount;
    currenciesFilterConfiguration.currencies = model.currency ? [model.currency] : [];
  }
  return newConfiguration;
};

/*
 * Maps the fields from a SettlementConfigurationFormModel to a new SettlementConfiguration
 */
export const mapModelToNewConfiguration = (model: SettlementConfigurationFormModel): SettlementConfiguration => {
  const filterConfigurations: ItemFilterConfiguration[] = [];

  const newConfiguration = {
    type: model.type,
    description: model.description,
    dateType: model.dateType,
    numberOfDays: model.numberOfDays,
  } as SettlementConfiguration;

  // Build transaction filter
  const transactionAmountFilterConfiguration = initTransactionAmountFilterConfiguration(
    TransactionAmountFilterType.TransactionAmount,
    model.minimumTransactionValue,
    model.maximumTransactionValue
  );
  filterConfigurations.push(transactionAmountFilterConfiguration);

  if (newConfiguration.type === SettlementType.PercentageDiscount) {
    newConfiguration.percentageDiscount = model.percentageDiscount;
    if (model.filterCurrency) {
      filterConfigurations.push(initCurrenciesFilterConfiguration([model.filterCurrency]));
    }
  } else if (newConfiguration.type === SettlementType.AbsoluteDiscount) {
    newConfiguration.currency = model.currency;
    newConfiguration.amount = model.amount;
    if (model.currency) {
      filterConfigurations.push(initCurrenciesFilterConfiguration([model.currency]));
    }
  }

  // Create filter group
  const filters: ItemFilterGroup = {
    filterConfigurations,
  };

  newConfiguration.filterGroup = filters;

  return newConfiguration;
};
