class FeatureFlagConsts {
  public static readonly Capitalise = "capitalise";

  public static readonly PermissionPolicy = "permissionPolicy";

  public static readonly NewAccessKeyPage = "newAccessKeyPage";

  public static readonly OpayoPi = "opayoPi";
}

export default FeatureFlagConsts;
