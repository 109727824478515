/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import DraycirSpinner from "../Spinners/DraycirSpinner";
import "./SpinnerButton.scss";

interface SpinnerButtonProps extends ButtonProps {
  title: string;
  showSpinner: boolean;
  disabled?: boolean;
}

export const SpinnerButton = ({ title, showSpinner, disabled, ...rest }: SpinnerButtonProps): ReactElement => {
  if (!showSpinner) return <Button disabled={disabled} {...rest}>{title}</Button>;

  const className = rest.size !== "sm" ? "button-saving" : "button-saving-small";

  return (
    <Button disabled={showSpinner || disabled} {...rest} className={className}>
      <div className='d-flex flex-row align-items-center'>
        <div className='spinner-container'>
          <DraycirSpinner />
        </div>
        <div className='ms-2'>{title}</div>
      </div>
    </Button>
  );
};

SpinnerButton.defaultProps = {
  variant: "primary",
  disabled: false
};

export default SpinnerButton;
