/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { findValidTransactionAllocationInfo } from "../../helpers/paymentRequests/paymentRequestHelper";
import { PaymentRequest } from "../../models/paymentRequests/paymentRequest";
import { Transaction } from "../../models/transactions/transaction";
import TransactionTableEntry from "../Transactions/TransactionTableEntry";
import EditTransactionAllocationInfoButton from "./Editing/EditTransactionAllocationInfoButton";

interface TransactionsTabProps {
  transactions: Transaction[];
  paymentRequest: PaymentRequest;
}

const TransactionsTab = ({ paymentRequest, transactions }: TransactionsTabProps): ReactElement => (
  <Row>
    <Col className='panel-column'>
      <Card className='custom-detail-card'>
        <Card.Body>
          {transactions.map((transaction) => (
            <div className='d-flex' key={transaction.id}>
              <TransactionTableEntry transaction={transaction} />
              <EditTransactionAllocationInfoButton
                paymentRequest={paymentRequest}
                transaction={transaction}
                transactionAllocationInfo={findValidTransactionAllocationInfo(transaction.id, paymentRequest)}
              />
            </div>
          ))}
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default TransactionsTab;
