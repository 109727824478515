import { ReactElement, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDatasetId } from "../../common/hooks/useDatasetId";
import { MainRouteConsts } from "../MainRoutesConsts";
import { tCommon } from "../../i18n";

const EntityTypeParam = "et";
const EntityIdParam = "eid";
const DatasetParam = "ds";

const EntityTypePaymentTransaction = "pt";
const EntityTypeTransaction = "tr";
const EntityTypeAccount = "ac";

const AllEntityTypes = [EntityTypePaymentTransaction, EntityTypeTransaction, EntityTypeAccount];

export const PaymentTransactionStatusMap = new Map<string, string>([
  [EntityTypePaymentTransaction, MainRouteConsts.PaymentTransactions],
  [EntityTypeTransaction, MainRouteConsts.Transactions],
  [EntityTypeAccount, MainRouteConsts.Accounts],
]);

/*
 * Handles a redirection url request, usually from a link originating from a notification email
 */
const RedirectUrlPage = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const [, setDatasetId] = useDatasetId();
  const { t } = useTranslation(tCommon.ns);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const entityType = params.get(EntityTypeParam);
    const entityId = params.get(EntityIdParam);
    const datasetIdAsString = params.get(DatasetParam);

    const convertedDatasetId = parseInt(datasetIdAsString || "", 10);
    setDatasetId(convertedDatasetId);

    // Only dealing with payment transactions right now, so bugger off it not one of those
    if (!datasetIdAsString || !entityId || entityType === null || !AllEntityTypes.includes(entityType)) {
      history.replace(MainRouteConsts.Home);
      return;
    }

    // Build and replace the url
    const route = PaymentTransactionStatusMap.get(entityType);
    const url = `${route}/${entityId}`;
    history.replace(url);
  }, [history, location.search, setDatasetId]);

  return <>{t("messageRedirecting")}</>;
};

export default RedirectUrlPage;
