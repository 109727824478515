import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { ReactElement, useCallback } from "react";
import lang from "i18next";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { DataGrid, TypeColumnRender } from "../../components/grid/DataGrid";
import {
  SortAscending,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  BaseOptionsColumn,
  defaultDataGridPagination,
} from "../../components/grid/dataGridHelpers";
import { Bank } from "../../models/banking/bankModels";
import { PageTitle } from "../common/PageTitle";
import { ViewItemButton } from "../../components/buttons/CommonIconButtons";
import { MainRouteConsts } from "../MainRoutesConsts";
import { HtmlTitle } from "../common/HtmlTitle";
import { tPages } from "../../i18n";
import { BankingApi } from "../../common/api/payThemApi/bankingApi";
import GridNamesConsts from "../GridNamesConsts";
import PageLoading from "../../components/loading/PageLoading";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";

const LangOptions = tPages;

const i18n = {
  ...ReactDataGrid.defaultProps.i18n,
};

const columns: TypeColumn[] = [
  {
    name: "accountName",
    header: lang.t("banksList.columAccountName", LangOptions),
    minWidth: 200,
    defaultFlex: 2,
  },
  {
    name: "currency",
    header: lang.t("banksList.columnCurrency", LangOptions),
    minWidth: 100,
    defaultFlex: 1,
  },
  {
    name: "accountNumber",
    header: lang.t("banksList.columnAccountNumber", LangOptions),
    minWidth: 200,
    defaultFlex: 2,
  },
  {
    name: "bankName",
    header: lang.t("banksList.columnBankName", LangOptions),
    minWidth: 150,
    defaultFlex: 2,
  },
  {
    name: "source",
    header: lang.t("banksList.columnSource", LangOptions),
    minWidth: 100,
    defaultFlex: 1,
  },
  {
    ...BaseOptionsColumn,
    render: ({ data }: TypeColumnRender<Bank>) => <ViewItemButton route={MainRouteConsts.Banks} id={data.id} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [
  { name: "accountName", type: "string", operator: "contains", value: "" },
  { name: "currency", type: "string", operator: "contains", value: "" },
  { name: "accountNumber", type: "string", operator: "contains", value: "" },
  { name: "bankName", type: "string", operator: "contains", value: "" },
  { name: "source", type: "string", operator: "contains", value: "" },
];

/*
 * Default sort column
 */
const DefaultSortColumnName = "accountName";
const DefaultSortDirection = SortAscending;

const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

/*
 * Callback function for loading in a page of data
 */
const loadData = async ({ skip, limit, sortInfo }: LoadDataProps): Promise<LoadDataResult<Bank>> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, undefined, async (pageParameters) => {
    const api = new BankingApi();
    const result = await api.getBanks(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter
    );
    return result;
  });

/*
 * Our grid name
 */
const GridName = GridNamesConsts.BankGrid;

/*
 * The list
 */
export const BankList = (): ReactElement => {
  const dataSource = useCallback(loadData, []);
  const { t } = useTranslation(tPages.ns);

  const { dataGridState, setFilters, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleBanks", tPages)} />
      {dataGridState ? (
        <>
          <PageTitle title='pageTitleBanks' />
          <section className='data-grid-page'>
            <DataGrid<Bank>
              idProperty='id'
              columns={columns}
              dataSource={dataSource}
              i18n={i18n}
              defaultFilterValue={dataGridState.filters}
              enableFiltering
              onFilterValueChange={(filterValue) => setFilters(filterValue)}
              defaultSortInfo={dataGridState.sortInfo}
              onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
              defaultSkip={dataGridState.pagination?.skip}
              onSkipChange={(skip) => setPaginationSkip(skip)}
              defaultLimit={dataGridState.pagination?.limit}
              onLimitChange={(limit) => setPaginationLimit(limit)}
            />
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default BankList;
