import {
  GoCardlessProviderName,
  GoCardlessProviderDisplayName,
  GoCardlessProviderModelConsts,
  GoCardlessSettings,
  GoCardlessCredentials,
  GoCardlessPaymentProviderConfiguration,
  AddGoCardlessPaymentProviderConfiguration,
} from "../../../../models/paymentProviders/goCardless/goCardlessModels";
import { PaymentMethodType } from "../../../../models/paymentProviders/paymentMethodType";
import {
  ProviderFormModel,
  mapToAddPaymentProviderConfiguration,
  mapToPaymentProviderConfiguration,
} from "../ProvidersCommon/Common";

export interface GoCardlessFormModel extends ProviderFormModel {}

export const CurrentProviderName = GoCardlessProviderName;
export const CurrentProviderDisplayName = GoCardlessProviderDisplayName;
export const CurrentPaymentMethodTypes = [PaymentMethodType.BankDebit, PaymentMethodType.BankTransfer];
export const CurrentProviderModelConsts = GoCardlessProviderModelConsts;
export type CurrentSettings = GoCardlessSettings;
export type CurrentCredentials = GoCardlessCredentials;
export type CurrentPaymentProviderConfiguration = GoCardlessPaymentProviderConfiguration;
export type CurrentAddPaymentProviderConfiguration = AddGoCardlessPaymentProviderConfiguration;
export type CurrentFormModel = GoCardlessFormModel;

export const mapToAddConfiguration = (model: CurrentFormModel): CurrentAddPaymentProviderConfiguration => {
  const configuration = mapToAddPaymentProviderConfiguration<CurrentAddPaymentProviderConfiguration>(
    model,
    CurrentProviderName
  );

  configuration.settings = {
    providerName: CurrentProviderName,
  } as CurrentSettings;

  configuration.credentials = {
    providerName: CurrentProviderName,
  } as CurrentCredentials;

  return configuration;
};

export const mapToUpdateConfiguration = (
  data: CurrentPaymentProviderConfiguration,
  model: CurrentFormModel
): CurrentPaymentProviderConfiguration => {
  const configuration = mapToPaymentProviderConfiguration(data, model);

  configuration.credentials = { providerName: CurrentProviderName } as CurrentCredentials;
  configuration.settings = { providerName: CurrentProviderName } as CurrentSettings;

  return configuration;
};
