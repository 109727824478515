import { CreateNotificationUser, NotificationUser } from "../../../models/notifications/notificationUser";
import BasePayThemApi from "./basePayThemApi";
import { buildExpandParameter } from "./includeOptions";
import { NotificationUserIncludeOptions } from "./notificationUserIncludeOptions";

class NotificationsApi extends BasePayThemApi {
  private endPoint = "notifications";

  private usersEndPoint = "notifications/users";

  /*
   * Returns a list of all users
   */
  public async getAllUsers(includeOptions?: NotificationUserIncludeOptions): Promise<NotificationUser[]> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.get<NotificationUser[]>(this.usersEndPoint, params);
    return result;
  }

  /*
   * Gets the customer notification settings
   */
  public async getCustomerNotifications(includeOptions?: NotificationUserIncludeOptions): Promise<NotificationUser> {
    const params = buildExpandParameter(includeOptions);
    const result = await this.get<NotificationUser>(`${this.endPoint}/customer`, params);
    return result;
  }

  /*
   * Adds a new user
   */
  public async addUser(user: CreateNotificationUser): Promise<NotificationUser> {
    const result = await this.postWithResponse<CreateNotificationUser, NotificationUser>(this.usersEndPoint, user);
    return result;
  }

  /*
   * Updates a user
   */
  public async updateUser(user: NotificationUser): Promise<NotificationUser> {
    const result = await this.putWithResponse<NotificationUser, NotificationUser>(this.usersEndPoint, user);
    return result;
  }

  /*
   * Deletes the user
   */
  public async deleteUser(id: string): Promise<void> {
    await this.deleteItem(this.usersEndPoint, id);
  }

  // public async updateNotificationSetting
}

export default NotificationsApi;
