import { ReactElement, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import { SettlementConfiguration } from "../../models/settlements/settlementConfiguration";
import { SettlementType } from "../../models/settlements/settlementEnums";
import { SettlementConfigurationDialog } from "./SettlementConfigurationDialog";
import { SettlementConfigurationFormModel } from "./settlementConfigurationFormModel";
import { mapModelToNewConfiguration } from "./settlementUtils";
import "./AddSettlementConfigurationButton.scss";
import UserPermission from "../../models/users/userPermission";
import usePermission from "../../common/hooks/usePermission";

const keyPrefix = "settlementConfigurationGroupPage";

interface SettlementTypeMenuEntry {
  type: SettlementType;
  name: string;
}

/*
 * Items to appear in our New Settlement drop down button
 */
const settlementTypesMenu = [
  { type: SettlementType.PercentageDiscount, name: "menuPercentage" },
  { type: SettlementType.AbsoluteDiscount, name: "menuAbsolute" },
] as SettlementTypeMenuEntry[];

interface AddSettlementConfigurationButtonProps {
  onSave: (settlementConfiguration: SettlementConfiguration) => Promise<void>;
}

/*
 * Button for handling adding and editing of settlement offers
 */
const AddSettlementConfigurationButton = ({ onSave }: AddSettlementConfigurationButtonProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<SettlementType>(SettlementType.None);
  const disabled = !usePermission(UserPermission.CreateSettlementConfigurations);

  async function handleOnSave(model: SettlementConfigurationFormModel) {
    setShow(false);
    const configuration = mapModelToNewConfiguration(model);
    setSaving(true);
    await onSave(configuration);
    setSaving(false);
  }

  // Menu a settlement offer type is chosen from the menu
  const handleMenuItemSelected = (type: SettlementType) => {
    setSelectedType(type);
    setShow(true);
  };

  return (
    <>
      <Dropdown onSelect={(eventKey) => handleMenuItemSelected(eventKey as SettlementType)} className='add-button'>
        <Dropdown.Toggle disabled={saving || disabled} variant='primary' id='new-settlement-offer-button'>
          <Spinner as='span' animation='border' size='sm' role='status' hidden={!saving} style={{ marginRight: "5px" }} />
          {t("buttonNewSettlementConfiguration")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <>
            {settlementTypesMenu.map((entry) => (
              <Dropdown.Item key={entry.type} eventKey={entry.type}>
                {t(entry.name)}
              </Dropdown.Item>
            ))}
          </>
        </Dropdown.Menu>
      </Dropdown>
      <SettlementConfigurationDialog
        type={selectedType}
        show={show}
        mode='add'
        onClose={() => setShow(false)}
        onSave={(model) => handleOnSave(model)}
      />
    </>
  );
};

export default AddSettlementConfigurationButton;
