/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { DisplayLoadingOrError } from "../../components/loading/DisplayLoadingOrError";
import { tPages } from "../../i18n";
import {
  getSettlementConfigurationGroup,
  updateSettlementConfigurationGroup,
} from "../../services/settlements/settlementConfiguration.service";
import { CommonBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import { HtmlTitle } from "../common/HtmlTitle";
import { PageTitle } from "../common/PageTitle";
import DefaultSettlement from "./DefaultSettlement";
import SettlementConfigurationGroupForm, { SettlementConfigurationGroupFormModel } from "./SettlementConfigurationGroupForm";
import SettlementConfigurationList from "./SettlementConfigurationList";

const SettlementConfigurationItem = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const { id } = useParams<{ id: string }>();

  const {
    data: configurationGroup,
    isLoading,
    isError,
    refetch,
  } = useQuery([QueryNameConsts.SettlementConfigurationItem, id], () => getSettlementConfigurationGroup(id ?? ""));

  const handleFormSave = async (model: SettlementConfigurationGroupFormModel): Promise<void> => {
    const configuration = await getSettlementConfigurationGroup(id);
    configuration.name = model.name;
    configuration.description = model.description;
    configuration.customerEnabled = model.customerEnabled;
    configuration.transactionEnabled = model.transactionEnabled;
    await updateSettlementConfigurationGroup(configuration);
    refetch();
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleSettlement")} subTitle2={id} />
      <DisplayLoadingOrError isLoading={isLoading} isError={isError || !configurationGroup}>
        <Breadcrumb>
          <CommonBreadcrumbs.SettlementConfigurations />
        </Breadcrumb>
        {configurationGroup && (
          <section className='item-view-section'>
            <DefaultSettlement settlementConfigurationGroupId={configurationGroup.id}>
              <PageTitle title='pageTitleSettlement' />
            </DefaultSettlement>
            <SettlementConfigurationGroupForm configurationGroup={configurationGroup} onSave={handleFormSave} />
            <SettlementConfigurationList configurationGroup={configurationGroup} />
          </section>
        )}
      </DisplayLoadingOrError>
    </>
  );
};

export default SettlementConfigurationItem;
