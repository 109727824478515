/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import LocationProvider from "../../common/providers/locationProvider";
import { useAuth } from "../../context/auth/AuthContext";

import useWebsiteView from "../../context/websiteView/WebsiteViewContext";
import RouteLoader from "./RouteLoader";
import UserPermission from "../../models/users/userPermission";
import useMe from "../../common/hooks/useMe";
import usePermission from "../../common/hooks/usePermission";

export type PrivateRouteComponent = React.ComponentType<RouteComponentProps<never>> | React.ComponentType<unknown> | undefined;

/*
 * Wraps a div container around the component
 */
const withContainer = (WrappedComponent: any, isFullWidth: boolean): any => {
  const x = (props: any) => (
    <div className={`container${isFullWidth ? "-fluid" : ""} p-2 m-2`}>
      <WrappedComponent {...props} />
    </div>
  );
  return x;
};

type PrivateRouteProps = {
  path: string;
  parameters?: string;
  exact?: boolean;
  component?: PrivateRouteComponent;
  noContainer?: boolean; // If true, don't wrap the component in a container
  fullWidth?: boolean; // If true, the container will be full width
  requiredPermissions: UserPermission[];
};

const forbiddenErrorRoute = '/error/403';

const PrivateRoute = ({ path, parameters, exact, component, noContainer, fullWidth, requiredPermissions }: PrivateRouteProps): ReactElement => {
  const { authService } = useAuth();
  const websiteView = useWebsiteView();
  const { isLoading } = useMe();
  const hasPermission = usePermission(requiredPermissions); 

  if (authService.isRequiresAuthentication()) {
    const redirectUrl = authService.authorize();
    const locationProvider = new LocationProvider();
    locationProvider.replace(redirectUrl);
  }

  let fullPath = path.trimEnd();
  if (parameters) {
    if (fullPath.endsWith("/") && parameters.startsWith("/")) {
      fullPath += parameters.slice(1);
    } else if (fullPath.endsWith("/") || parameters.startsWith("/")) {
      fullPath += parameters;
    } else fullPath += `/${parameters}`;
  }

  // If we haven't authenticated the user yet, show the route loader
  if (authService.isPending() || isLoading) {
    return <RouteLoader />;
  }

  if (!hasPermission) {
    window.location.replace(forbiddenErrorRoute);
  }

  const parentUrl = websiteView.parentUrl ?? "";
  const hasParent = parentUrl.trim() !== "";

  const routeComponent = noContainer ? component : withContainer(component, hasParent || fullWidth === true);

  return <Route path={fullPath} exact={exact} component={routeComponent} />;
};

PrivateRoute.defaultProps = {
  parameters: undefined,
  component: undefined,
  exact: false,
  noContainer: false,
  fullWidth: false
};

export default PrivateRoute;
