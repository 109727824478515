/* eslint-disable no-param-reassign */
import { ProviderEventsApi } from "../common/api/payThemApi/providerEventsApi";
import { ProviderEvent } from "../models/events/providerEvent";
import { TranSafeProviderName } from "../models/paymentProviders/transafe/transafeModels";
import { OpayoProviderName } from "../models/paymentProviders/opayo/opayoModels";

export interface EventPayload {
  event?: ProviderEvent;
  data: string;
}

/*
 * Due to a bug that wasn't storing the provider name, this function extracts it from the payload filename
 */
export function getProviderNameFromEvent(event: ProviderEvent): string {
  if (event.providerName) return event.providerName;
  const index = event.payloadFileName.indexOf("/");
  if (index > 0) {
    return event.payloadFileName.substr(0, index);
  }
  return event.providerName;
}

/*
 * Formats the payload data so it can be displayed correctly
 */
function formatPayloadData(payload: string, event: ProviderEvent) {
  if (event.providerName === TranSafeProviderName) {
    const obj = JSON.parse(payload);
    return JSON.stringify(obj, null, 4);
  }
  if (event.providerName === OpayoProviderName) {
    const items = payload.split("&");
    return items.join("\n");
  }
  return payload;
}

/*
 * Creates a EventPayload object from the ProviderEvent and payload data
 */
export async function getProviderEventPayload(event: ProviderEvent): Promise<EventPayload> {
  const api = new ProviderEventsApi();
  const payload = await api.getAssociatedPayloadAsync(event.id);

  event.providerName = getProviderNameFromEvent(event);

  const eventPayload: EventPayload = {
    event,
    data: formatPayloadData(payload, event),
  };
  return eventPayload;
}
