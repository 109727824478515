// eslint-disable-next-line import/prefer-default-export
export enum RequestTypes {
  Transaction = "transaction",
  Account = "account",
}

export const RequestTypesMap = new Map<RequestTypes, string>([
  [RequestTypes.Transaction, "Transaction"],
  [RequestTypes.Account, "Account"],
]);

export const RequestTypesArray = Array.from(RequestTypesMap.entries());
