import { ReactElement } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { DatasetsApi } from "../../common/api/payThemApi/datasetsApi";
import { useDatasetId } from "../../common/hooks/useDatasetId";
import { SettingsRouteConsts } from "../../views/SettingsRouteConsts";
import { DatasetTitle } from "../../components/datasets/DatasetTitle";
import { Dataset } from "../../models/datasets/dataset";
import { isErrorDatasetStatus, isProcessingDatasetStatus } from "../../services/datasets/dataset.service";
import UserPermission from "../../models/users/userPermission";
import "./TopNavigationMenu.scss";
import usePermission from "../../common/hooks/usePermission";
import { isValidGUID } from "../../utils/stringUtils";
import { UrlUtils } from "../../utils/urlUtils";


const LoadingDropDown = (): ReactElement => (
  <NavDropdown title='Loading' id='company-selection-nav-dropdown' className='dataset-dropdown-title'>
    <NavDropdown.Item>
      <FontAwesomeIcon icon={faSpinner} className='fa-spin' />
    </NavDropdown.Item>
  </NavDropdown>
);

// eslint-disable-next-line arrow-body-style
const LoadingErrorDropDown = (): ReactElement => {
  return (
    <NavDropdown title='Failed to load' id='company-selection-nav-dropdown' className='dataset-dropdown-title'>
      <NavDropdown.Item />
    </NavDropdown>
  );
};

/*
 * Renders a dataset menu item for the dropdown menu
 */
const DatasetMenuItem = ({
  dataset,
  selectedDataset,
  handleDatasetChange,
}: {
  dataset: Dataset;
  selectedDataset: Dataset | undefined;
  handleDatasetChange: (id: number) => void;
}): ReactElement => {
  if (selectedDataset === dataset) {
    return (
      <NavDropdown.Header className='dataset-dropdown-header-nonselectable'>
        <DatasetTitle dataset={dataset} showStatus={false} />
      </NavDropdown.Header>
    );
  }

  if (isProcessingDatasetStatus(dataset.status) || isErrorDatasetStatus(dataset.status)) {
    return (
      <NavDropdown.Header className='dataset-dropdown-header-nonselectable dataset-dropdown-header-disabled'>
        <DatasetTitle dataset={dataset} showStatus={false} />
      </NavDropdown.Header>
    );
  }

  return (
    <>
      <NavDropdown.Item
        className={selectedDataset === dataset ? "dropdown-item-highlight" : ""}
        onClick={() => handleDatasetChange(dataset.id)}
      >
        <DatasetTitle dataset={dataset} showStatus={false} />
      </NavDropdown.Item>
    </>
  );
};

/*
 * Dataset Drop-down list
 */
const DatasetNavDropDown = ({ onDatasetChange }: { onDatasetChange: (datasetId: number) => void }): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const disabled = !usePermission(UserPermission.EditCompanies);
  const [datasetId, setDatasetId] = useDatasetId();
  const { data, isLoading, isError } = useQuery("datasetDropdown", () => {
    const api = new DatasetsApi();
    return api.getAllDatasets();
  });
  const currentDatasetId = datasetId;

  const redirectResourcePageToParent = (): boolean => {
    const pathTokens = location.pathname.split('/');
    if (isValidGUID(pathTokens[pathTokens.length - 1])) {
      const pathName = pathTokens
        .filter((_value: string, index: number) => index !== pathTokens.length - 1)
        .join('');

      const newUri = UrlUtils.getComposedUrlWithPath(pathName);
      window.location.href = newUri;
      return true;
    }
    return false;
  }

  const handleDatasetChange = (id: number) => {
    if (datasetId === id) return;
    
    setDatasetId(id);
    if (redirectResourcePageToParent())
      return;
    
    onDatasetChange(id);
  };

  if (isLoading) return <LoadingDropDown />;
  if (isError || !data) return <LoadingErrorDropDown />;

  const datasets = data.items;
  const selectedDataset = datasets.find((x) => x.id === currentDatasetId);

  return (
    <NavDropdown title={selectedDataset?.name} id='company-selection-nav-dropdown' className='dataset-dropdown-title'>
      {datasets.map((dataset) => (
        <DatasetMenuItem
          key={dataset.id}
          dataset={dataset}
          selectedDataset={selectedDataset}
          handleDatasetChange={handleDatasetChange}
        />
      ))}
      <NavDropdown.Divider />
      <NavDropdown.Item disabled={disabled} href={SettingsRouteConsts.Datasets}>{t("Edit Companies")}</NavDropdown.Item>
    </NavDropdown>
  );
};

export default DatasetNavDropDown;