import { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { BasePaymentRequestTabProps } from "./common";
import PaymentContactDetails from "./PaymentContactDetails";
import RequestDetails from "./RequestDetails";

/*
 * Info tab
 */
const PaymentRequestInfoTab = ({ paymentRequest, onUpdate }: BasePaymentRequestTabProps): ReactElement => (
  <>
    <Row>
      <Col className='panel-column'>
        <RequestDetails paymentRequest={paymentRequest} onUpdate={onUpdate} />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <PaymentContactDetails type='billing' onUpdate={onUpdate} paymentRequest={paymentRequest} />
      </Col>
    </Row>
    <Row>
      <Col className='panel-column'>
        <PaymentContactDetails type='delivery' onUpdate={onUpdate} paymentRequest={paymentRequest} />
      </Col>
    </Row>
  </>
);

PaymentRequestInfoTab.defaultProps = {
  onUpdate: undefined,
};

export default PaymentRequestInfoTab;
