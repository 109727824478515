import { ReactElement, useEffect, useState } from "react";
import { Card, Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import TodoItem from "./TodoItem";
import { TodoResult } from "../../../services/todos/todoResult";
import { buildTodoResults } from "../../../services/todos/todos.service";
import { tTodos } from "../../../i18n";
import FeatureFlagConsts from "../../../FeatureFlagConsts";

interface TodoItemsProps {
  todoItems: TodoResult[]
}

/*
 * Renders the todo items
 */
const TodoItems = ({ todoItems }: TodoItemsProps): ReactElement => {
  const listItems = todoItems.map((item) => <TodoItem key={item.message} todoResult={item} />);
  return <>{listItems}</>;
};

export const TodosList = (): ReactElement => {
  const { t } = useTranslation(tTodos.ns);
  const [todoItems, setTodoItems] = useState<TodoResult[]>();
  const enabledNewAccessKeyPage = useFeature(FeatureFlagConsts.NewAccessKeyPage) as boolean;

  useEffect(() => {
    buildTodoResults(enabledNewAccessKeyPage).then((result) => {
      setTodoItems(result);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const body = todoItems && todoItems.length > 0 ? <TodoItems todoItems={todoItems} /> : <>{t("todos.statusTodosComplete")}</>;

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <div className='heading-title-20 font-bold'>{t("todos.titleToDoTasks")}</div>
        </Card.Title>
        {!todoItems && (
          <div aria-hidden='true'>
            <Placeholder as={Card.Text} animation='glow'>
              <Placeholder xs={6} /> <Placeholder xs={2} />
            </Placeholder>
          </div>
        )}
        {todoItems && <Card.Text as='div'>{body}</Card.Text>}
      </Card.Body>
    </Card>
  );
};

export default TodosList;
