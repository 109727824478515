/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tPages } from "../../../i18n";
import { HtmlTitle } from "../../common/HtmlTitle";
import { PageTitle } from "../../common/PageTitle";
import NotificationsTab from "./NotificationsTabs";
import "../../viewsCommon.scss";
import "./NotificationsSettingsPage.scss";

/*
 * Allows editing of notifications
 */
const NotificationSettingsPage = (): ReactElement => {
  const { t: tBase } = useTranslation(tPages.ns);

  return (
    <section className='item-view-section'>
      <HtmlTitle subTitle1={tBase("pageTitleNotifications", tPages)} />
      <PageTitle title='pageTitleNotifications' />
      <NotificationsTab />
    </section>
  );
};

export default NotificationSettingsPage;
