export enum RequestCaptureType {
  Immediate = "Immediate",
  Reserve = "Reserve",
}

export const RequestCaptureTypeMap = new Map<RequestCaptureType, string>([
  [RequestCaptureType.Immediate, "Immediate"],
  [RequestCaptureType.Reserve, "Reserve"],
]);

export const RequestCaptureTypeArray = Array.from(RequestCaptureTypeMap.entries());
