import { FormEvent, ReactElement, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CurrencyItem } from "../../components/misc/CurrencyItem";
import { PanelItem } from "../../components/panels/Panels";
import { tPages } from "../../i18n";
import { CopyToClipboard } from "../../components/icons/CopyToClipboard";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import {
  PaymentTransactionType,
  AllocatedStatusArray,
  AllocatedStatus,
  AllocatedStatusMap,
} from "../../models/paymentTransactions/paymentTransactionEnums";
import { AccountLink } from "../Accounts/AccountLink";
import { PaymentRequestLink } from "../PaymentRequests/PaymentRequestLink";
import { PaymentTransactionStatusTag } from "./PaymentTransactionStatusTag";
import { PaymentTransactionTypeTag } from "./PaymentTransactionTypeTag";
import {
  cancelPaymentTransaction,
  capturePaymentTransaction,
  updateAllocatedStatus,
} from "../../services/paymentTransactions/paymentTransactions.service";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { BadgeSize } from "../../models/misc/badgeSize";
import { CustomerFormatter } from "../../utils/customerFormatter";
import PaymentTransactionItemMenu from "./PaymentTransactionItemMenu";
import "./PaymentTransactionDetails.scss";
import useDateFormatter from "../../common/hooks/useDateFormatter";

const keyPrefix = "paymentTransactionPage.details";

/*
 * Checks if we should ask confirmation before changing allocation status
 */
const shouldConfirmNewAllocationStatusSelection = (currentStatus: AllocatedStatus, newStatus: AllocatedStatus): boolean => {
  if (currentStatus === newStatus) return false;
  if (currentStatus === AllocatedStatus.AutoAllocated) {
    return true;
  }
  return false;
};

/*
 * The main details card
 */
const PaymentTransactionDetails = ({
  paymentTransaction,
  refreshData,
}: {
  paymentTransaction: PaymentTransaction;
  refreshData: () => {};
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const { id } = useParams<{ id: string }>();
  const [allocatedStatus, setAllocatedStatus] = useState(paymentTransaction.allocatedStatus);
  const [newAllocatedStatus, setNewAllocatedStatus] = useState(paymentTransaction.allocatedStatus);
  const [show, setShow] = useState<boolean>(false);
  const dateFormatter = useDateFormatter();

  // Default to true (showing the PayThem reference) if the id is the same as the payment transaction id
  // We do this because the id on the url can either be the GUID or the PayThem reference. This way we
  // display the other one by default.
  const [displayReference, setDisplayReference] = useState<boolean>(id.toLowerCase() === paymentTransaction.id.toLowerCase());

  // Updates the allocation status and refreshes the payment transaction record
  const updateStatus = async (status: AllocatedStatus) => {
    await updateAllocatedStatus(paymentTransaction.id, status);
    setAllocatedStatus(status);
    refreshData();
  };

  // Handles the event when the country selection changes
  const handleAllocationStatusChange = async (e: FormEvent<HTMLSelectElement>) => {
    const newStatus = e.currentTarget.value as AllocatedStatus;
    setNewAllocatedStatus(newStatus);
    if (newStatus === allocatedStatus) {
      return;
    }
    if (shouldConfirmNewAllocationStatusSelection(allocatedStatus, newStatus)) {
      setShow(true);
    } else {
      await updateStatus(newStatus);
    }
  };

  // Handles the positive confirmation dialog by saving the new status
  const handleConfirmStatus = async (newStatus?: AllocatedStatus) => {
    setShow(false);
    if (newStatus) {
      await updateStatus(newStatus);
    }
  };

  const handleCancelPending = async (ptId: string) => {
    await cancelPaymentTransaction(ptId);
    refreshData();
  };

  const handleCapturePending = async (ptId: string) => {
    await capturePaymentTransaction(ptId);
    refreshData();
  };

  const toggleDisplayReference = () => {
    setDisplayReference(!displayReference);
  };

  const referenceOrIdValue = displayReference ? paymentTransaction.payThemReference : paymentTransaction.id;

  return (
    <>
      <Card className='custom-detail-card'>
        <Card.Body>
          <Card.Title className='details-title'>
            <span>{t("title")}</span>
            <div className='tag-items'>
              <PaymentTransactionTypeTag className='tag' type={paymentTransaction.type} />
              <PaymentTransactionItemMenu
                paymentTransaction={paymentTransaction}
                onCancelPending={handleCancelPending}
                onCapturePending={handleCapturePending}
              />
            </div>
          </Card.Title>
          <Card.Text as='div'>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <div className='main-item'>
                  <div className='title'>
                    {t(paymentTransaction.type === PaymentTransactionType.Payment ? "amountPaid" : "amountRefunded")}
                  </div>
                  <div className='amount-to-pay'>
                    <CurrencyItem
                      currency={paymentTransaction.currency}
                      value={paymentTransaction.totalAmount}
                      status={paymentTransaction.status}
                    />
                  </div>
                  <PaymentTransactionStatusTag className='tag-end' status={paymentTransaction.status} size={BadgeSize.Small} />
                </div>
                <div className='main-item'>
                  <div className='title'>{t("customerName")}</div>
                  <div className='content'>
                    {CustomerFormatter.getFullName(
                      paymentTransaction.customerDetails.firstNames,
                      paymentTransaction.customerDetails.surname
                    )}
                  </div>
                </div>
                <div className='main-item'>
                  <div className='title'>{t(displayReference ? "payThemReference" : "paymentTransactionId")}</div>
                  <span className='paythem-reference'>
                    <div
                      className='content clickable'
                      role='button'
                      tabIndex={0}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") toggleDisplayReference();
                      }}
                      onClick={toggleDisplayReference}
                    >
                      {referenceOrIdValue}
                    </div>
                    <CopyToClipboard text={referenceOrIdValue} />
                  </span>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <PanelItem title={t("account")}>
                  <AccountLink account={paymentTransaction.account} />
                </PanelItem>
                <PanelItem title={t("providerFee")}>
                  <CurrencyItem currency={paymentTransaction.feeCurrency} value={paymentTransaction.fee} />
                </PanelItem>
                <PanelItem title={t("transactionDate")}>
                  {dateFormatter.toShortDateTime(paymentTransaction.createdOn)}
                </PanelItem>
                <PanelItem title={t("paymentRequestId")}>
                  <PaymentRequestLink id={paymentTransaction.paymentRequestId} />
                </PanelItem>
                <PanelItem title={t("allocatedStatus")}>
                  <Form.Select
                    className='m-0'
                    size='sm'
                    value={allocatedStatus}
                    onChange={(e) => handleAllocationStatusChange(e)}
                  >
                    {AllocatedStatusArray.map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </PanelItem>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
      <ConfirmationDialog
        show={show}
        title={t("confirmChangeAllocatedStatusTitle")}
        body={t("confirmChangeAllocatedStatusBody", {
          currentStatus: AllocatedStatusMap.get(allocatedStatus),
          newStatus: AllocatedStatusMap.get(newAllocatedStatus),
        })}
        data={newAllocatedStatus}
        handleClose={() => setShow(false)}
        handleConfirmation={(data) => handleConfirmStatus(data)}
      />
    </>
  );
};

export default PaymentTransactionDetails;
