import { createContext, ReactElement, useCallback, useContext, useState } from "react";
import lang from "i18next";
import { useTranslation } from "react-i18next";
import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import {
  BaseOptionsColumn,
  DataGridReadyItem,
  defaultDataGridPagination,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  SortAscending,
} from "../../components/grid/dataGridHelpers";
import { DataGrid, DataGridContextCustomDataType, TypeColumnRender } from "../../components/grid/DataGrid";
import { tPages } from "../../i18n";
import { SettlementConfigurationGroup } from "../../models/settlements/settlementConfigurationGroup";
import { HtmlTitle } from "../common/HtmlTitle";
import { PageTitle } from "../common/PageTitle";
import { EditItemViaRouteButton } from "../../components/buttons/CommonIconButtons";
import { DeleteItemConfirmation } from "../../components/icons/DeleteItemConfirmation";
import { MainRouteConsts } from "../MainRoutesConsts";
import SettlementConfigurationsApi from "../../common/api/payThemApi/settlementConfigurations";
import { deleteSettlementConfigurationGroup } from "../../services/settlements/settlementConfiguration.service";
import { CompanyPreferences } from "../../models/companySettings/companySettingsModels";
import { getPreferences } from "../../services/settings/settings.service";
import EnabledTag from "../../components/tags/EnabledTag";
import TextWithDefaultTag from "../../components/TextWithDefaultTag/TextWithDefaultTag";
import GridNamesConsts from "../GridNamesConsts";
import PageLoading from "../../components/loading/PageLoading";
import UserPermission from "../../models/users/userPermission";
import { QueryNameConsts } from "../../common/queryNameConsts";
import usePermission from "../../common/hooks/usePermission";
import useDataGridStorage from "../../common/hooks/useDataGridStorage";
import "./SettlementsList.scss";

const LangOptions = tPages;

type MyLoadDataResult = LoadDataResult<SettlementConfigurationGroup>;
const DataGridContext = createContext<DataGridContextCustomDataType<CompanyPreferences | undefined>>(undefined);

/*
 * Column buttons to edit/delete
 */
const ColumnButtons = ({ configuration }: { configuration: SettlementConfigurationGroup }): ReactElement => {
  const context = useContext(DataGridContext);
  const { t } = useTranslation(tPages.ns);
  const disabledEdit = !usePermission(UserPermission.EditSettlementConfigurations);
  const disabledDelete = !usePermission(UserPermission.DeleteSettlementConfigurations);

  // Deletes a configuration
  const handleDeleteConfiguration = async (id: string): Promise<void> => {
    await deleteSettlementConfigurationGroup(id);
    await context?.refreshDataSource();
  };
  return (
    <>
      <EditItemViaRouteButton route={MainRouteConsts.Settlements} id={configuration.id} disabled={disabledEdit} />
      <DeleteItemConfirmation<string>
        item={configuration.id}
        disabled={disabledDelete}
        onDelete={handleDeleteConfiguration}
        body={t("settlementConfigurationPage.confirmDeleteConfiguration", { name: configuration.name })}
      />
    </>
  );
};

/*
 * Creates the settlement name plus shows if it's the default
 */
const SettlementName = ({ configuration }: { configuration: SettlementConfigurationGroup }): ReactElement => {
  const context = useContext(DataGridContext);
  const isDefault = context?.customData?.defaultSettlementConfigurationGroupId === configuration.id;

  return <TextWithDefaultTag text={configuration.name} isDefault={isDefault} />;
};

const columns: TypeColumn[] = [
  {
    name: "name",
    header: lang.t("settlementsList.columName", LangOptions),
    minWidth: 100,
    defaultFlex: 2,
    render: ({ data }: TypeColumnRender<SettlementConfigurationGroup>) => <SettlementName configuration={data} />,
  },
  { name: "description", header: lang.t("settlementsList.columDescription", LangOptions), minWidth: 200, defaultFlex: 3 },
  {
    name: "_configurationCount",
    header: lang.t("settlementsList.columConfigurationCount", LangOptions),
    textAlign: "center",
    defaultFlex: 1,
    sortable: false,
    filterable: false,
    render: ({ data }: TypeColumnRender<SettlementConfigurationGroup>) => data.configurations.length,
  },
  {
    name: "customerEnabled",
    header: lang.t("settlementsList.columEnabled", LangOptions),
    textAlign: "center",
    defaultFlex: 1,
    render: ({ data }: TypeColumnRender<SettlementConfigurationGroup>) => <EnabledTag enabled={data.customerEnabled} />,
  },
  {
    ...BaseOptionsColumn,
    maxWidth: 75,
    render: ({ data }: TypeColumnRender<SettlementConfigurationGroup>) => <ColumnButtons configuration={data} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [
  // { name: "customerName", type: "string", operator: "contains", value: "" },
  // { name: "amountToPay", operator: "gte", type: "number", value: "" },
  // { name: "requestStatus", operator: "eq", type: "select", value: "" },
];

/*
 * Default sort column
 */
const DefaultSortColumnName = "name";
const DefaultSortDirection = SortAscending;

const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

const loadData = async ({ skip, limit, sortInfo }: LoadDataProps): Promise<MyLoadDataResult> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, undefined, async (pageParameters) => {
    const api = new SettlementConfigurationsApi();
    const result = await api.getSettlementConfigurationGroups(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter
    );
    return result;
  });

/*
 * Our grid name
 */
const GridName = GridNamesConsts.SettlementGrid;

/*
 * The list
 */
const SettlementConfigurationsList = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const dataSource = useCallback(loadData, []);
  const { dataGridState, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );

  const [gridRef, setGridRef] = useState<DataGridReadyItem>();
  const disabledNewSettlement = !usePermission(UserPermission.CreateSettlementConfigurations);
  const history = useHistory();
  const { data: preferences } = useQuery([QueryNameConsts.CompanyPreferences], () => getPreferences());

  const handleNewSettlement = () => {
    history.push(`${MainRouteConsts.Settlements}/new`);
  };

  const readLoad = async () => gridRef?.current?.reload();

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitleSettlements", tPages)} />
      {dataGridState ? (
        <>
          <PageTitle title='pageTitleSettlements' />
          <section className='data-grid-page'>
            <DataGridContext.Provider value={{ refreshDataSource: readLoad, customData: preferences }}>
              <DataGrid<SettlementConfigurationGroup>
                idProperty='id'
                columns={columns}
                dataSource={dataSource}
                defaultSortInfo={dataGridState.sortInfo}
                onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
                defaultSkip={dataGridState.pagination?.skip}
                onSkipChange={(skip) => setPaginationSkip(skip)}
                defaultLimit={dataGridState.pagination?.limit}
                onLimitChange={(limit) => setPaginationLimit(limit)}
                onReady={setGridRef}
              />
            </DataGridContext.Provider>
            <div className='bottom-button-group'>
              <Button disabled={disabledNewSettlement} onClick={handleNewSettlement}>
                {t("settlementConfigurationGroupPage.buttonAddSettlementConfigurationGroup")}
              </Button>
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default SettlementConfigurationsList;
