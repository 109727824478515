import { createContext, useContext } from "react";
import { AuthService } from "../../services/auth/auth.service";

export type AuthContextProps = {
  authService: AuthService;
};

export type AuthContextType = AuthContextProps | undefined;

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
