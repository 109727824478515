import { ReactElement } from "react";
import { BasicTag } from "../../components/tags/BasicTag";
import { TransactionType } from "../../models/transactions/transactionEnums";
import "./TransactionTypeTag.scss";

/*
 * Generates Transaction Type tag
 */
const TransactionTypeTag = ({
  type,
  name,
  className,
}: {
  type: TransactionType;
  name?: string;
  className?: string;
}): ReactElement => <BasicTag tagName={type} tagText={name} className={className} tagCssEnd='-type' />;

TransactionTypeTag.defaultProps = {
  className: undefined,
  name: undefined,
};
export default TransactionTypeTag;
