interface ProductInfo {
  productName: string;
  productShortName: string;
  supportUrl: string;
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  documentationUrl: string;
  allowDevFeatures: string;
}

const getProductInfo = (): ProductInfo => ({
  productName: process.env.REACT_APP_PRODUCT_NAME || "",
  productShortName: process.env.REACT_APP_PRODUCT_SHORTNAME || "",
  supportUrl: process.env.REACT_APP_SUPPORT_URL || "",
  termsOfServiceUrl: process.env.REACT_APP_TERMSOFSERVICE_URL || "",
  privacyPolicyUrl: process.env.REACT_APP_PRIVACYPOLICY_URL || "",
  documentationUrl: process.env.REACT_APP_DOCUMENTATION_URL || "",
  allowDevFeatures: process.env.REACT_APP_ALLOW_DEV_FEATURES || "",
});

export default getProductInfo;
