import { forwardRef, useImperativeHandle, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { FormSaveRef, TabSaveRef } from "../preferencesTypes";
import GeneralSettingsForm from "./GeneralSettingsForm";
import PaymentRequestSettingsForm from "./PaymentRequestSettingsForm";

/*
 * General settings
 */
const GeneralSettingsTab = forwardRef<TabSaveRef>((props, ref) => {
  const generalSettingsRef = useRef<FormSaveRef>(null);
  const paymentRequestSettingsRef = useRef<FormSaveRef>(null);

  useImperativeHandle(ref, () => ({
    save: async () => {
      if (!(await generalSettingsRef.current?.validate()) || !(await paymentRequestSettingsRef.current?.validate())) {
        return false;
      }
      try {
        await generalSettingsRef.current?.save();
        await paymentRequestSettingsRef.current?.save();
        return true;
      } catch {
        return false;
      }
    },
  }));

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <GeneralSettingsForm ref={generalSettingsRef} />
        </Col>
      </Row>
      <Row>
        <Col className='panel-column'>
          <PaymentRequestSettingsForm ref={paymentRequestSettingsRef} />
        </Col>
      </Row>
    </>
  );
});

export default GeneralSettingsTab;
