import { DashboardAccessResult } from "../../../models/integrations/svix/svixModels";
import BasePayThemApi from "./basePayThemApi";

/*
 * Api for accessing the svix integration
 */
class SvixWebApi extends BasePayThemApi {
  private endPoint = "integrations/svix";

  /*
   * Returns the url to access the dashboard
   */
  public async getDashboardUrl(): Promise<DashboardAccessResult> {
    const result = await this.get<DashboardAccessResult>(`${this.endPoint}/dashboard-access`);
    return result;
  }
}

export default SvixWebApi;
