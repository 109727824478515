import { ReactElement } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { QueryNameConsts } from "../../common/queryNameConsts";
import { LoadingError } from "../../components/loading/LoadingError";
import { getTransaction } from "../../services/transactions/transactions.service";
import { TransactionLink } from "./TransactionLink";

const TransactionReferenceLink = ({ transactionId }: { transactionId: string }): ReactElement => {
  const { data, isLoading, isError } = useQuery([QueryNameConsts.TransactionItem, transactionId], () =>
    getTransaction(transactionId)
  );
  if (isLoading) return <Spinner animation='border' role='status' size='sm' />;
  if (isError) return <LoadingError />;
  return <TransactionLink id={transactionId}>{data?.transactionReference ?? transactionId} </TransactionLink>;
};

export default TransactionReferenceLink;
