import { ReactElement } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

interface StandardTooltipProps {
  children: ReactElement;
  text: string | JSX.Element;
  popoverClass?: string;
  placement?: Placement | undefined;
  delayShow?: number;
  delayHide?: number;
}

const DefaultDelayShow: number = 250;
const DefaultDelayHide: number = 400;

/*
 * Displays our standard tooltip around component
 */
const StandardTooltip = ({
  children,
  text,
  popoverClass,
  placement,
  delayShow,
  delayHide,
}: StandardTooltipProps): ReactElement => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: delayShow ?? DefaultDelayShow, hide: delayHide ?? DefaultDelayHide }}
    overlay={
      <Popover className={popoverClass}>
        <Popover.Body>{text}</Popover.Body>
      </Popover>
    }
  >
    <span>{children}</span>
  </OverlayTrigger>
);

StandardTooltip.defaultProps = {
  popoverClass: "",
  delayShow: DefaultDelayShow,
  delayHide: DefaultDelayHide,
  placement: undefined,
};

export default StandardTooltip;
