import { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../../../../components/dialogs/ConfirmationDialog";
import { tPaymentProviders } from "../../../../../i18n";

const keyPrefix = "stripeForm";

export interface StripeConnectButtonProps {
  hasConnection: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
}

/*
 * Display connect or disconnect button
 */
const StripeConnectButton = ({ hasConnection, onConnect, onDisconnect }: StripeConnectButtonProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });
  const [show, setShow] = useState<boolean>(false);

  if (hasConnection)
    return (
      <>
        <Button variant='danger' onClick={() => setShow(true)}>
          {t("buttonDisconnectFromStripe")}
        </Button>
        <ConfirmationDialog
          show={show}
          title={t("confirmStripeDisconectTitle")}
          body={t("confirmStripeDisconnectBody")}
          handleClose={() => setShow(false)}
          handleConfirmation={() => onDisconnect()}
        />
      </>
    );
  return <Button onClick={onConnect}>{t("buttonConnectToStripe")}</Button>;
};

export default StripeConnectButton;
