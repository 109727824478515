/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { UserConsts } from "../../../models/users/userModelConsts";
import { Role, RoleArray } from "../../../models/users/role";
import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../../components/dialogs/AddOrEditCommonDialog";
import { User } from "../../../models/users/user";
import { tUsers } from "../../../i18n";

export type UserFormModel = {
  name: string;
  emailAddress: string;
  role: Role;
};

export interface UserDialogProps extends AddOrEditDialogProps<User, UserFormModel> {
  disableRole?: boolean;
}

/*
 * The user dialog
 */
export function UserDialog({ show, mode, data, disableRole, onSave, onClose }: UserDialogProps): ReactElement {
  const { t } = useTranslation([tUsers.ns]);
  const addMode = mode === "add";

  const validationSchema = Yup.object({
    name: Yup.string().label("Name").required().min(UserConsts.NameMinLength).max(UserConsts.NameMaxLength),
    emailAddress: Yup.lazy(() =>
      addMode
        ? Yup.string().label("Email address").required().email().max(UserConsts.EmailAddressMaxLength)
        : Yup.string().notRequired()
    ),
  }).required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserFormModel>({ resolver: yupResolver(validationSchema) });

  // Reset the model if the data object is difference
  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSave = (model: UserFormModel) => {
    const modelToSave = { ...model };
    modelToSave.name = model.name.trim();
    onSave(modelToSave);
    reset();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <AddOrEditModelDialog
      mode={mode}
      title={t(addMode ? "titleAddUser" : "titleEditUser")}
      show={show}
      onHide={handleClose}
      formId='userForm'
      size={undefined}
    >
      <Form id='userForm' className='dialog-form' noValidate onSubmit={handleSubmit((submitData) => handleSave(submitData))}>
        <Row className='dialog-row last'>
          <Form.Group>
            <Form.Label>{t("labelUserName")}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t("placeholderUserName")}
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              defaultValue={!addMode ? data?.displayName : undefined}
              // isValid={!!errors.name}
            />
            <Form.Control.Feedback type='invalid'>{errors?.name?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group aria-disabled={!addMode}>
            <Form.Label>{t("labelUserEmailAddress")}</Form.Label>
            <Form.Control
              type='email'
              placeholder={t("placeholderUserEmailAddress")}
              {...register("emailAddress")}
              className={`form-control ${errors.emailAddress ? "is-invalid" : ""}`}
              defaultValue={!addMode ? data?.emailAddress : undefined}
              disabled={!addMode}
              // isValid={!!errors.emailAddress}
            />
            <Form.Control.Feedback type='invalid'>{errors?.emailAddress?.message}</Form.Control.Feedback>
          </Form.Group>

          {disableRole ? (
            <Form.Control type='hidden' {...register("role")} defaultValue={data?.role} />
          ) : (
            <Form.Group>
              <Form.Label>{t("labelUserRole")}</Form.Label>
              <Form.Select defaultValue={!addMode ? data?.role : undefined} disabled={disableRole} {...register("role")}>
                {RoleArray.map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Row>
      </Form>
    </AddOrEditModelDialog>
  );
}

UserDialog.defaultProps = {
  disableRole: false,
};
