/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import AccessKeyConsts from "../../../models/accessKeys/accessKeyConsts";
import { AddOrEditDialogProps, AddOrEditModelDialog } from "../../../components/dialogs/AddOrEditCommonDialog";
import { AccessKey } from "../../../models/accessKeys/accessKey";
import { convertDatePartToUtcDate, isGreaterThanOrEqual } from "../../../helpers/dateHelpers";
import { tForms } from "../../../i18n";
import useDateFormatter from "../../../common/hooks/useDateFormatter";

interface AccessKeyDialogProps extends AddOrEditDialogProps<AccessKey, AccessKey> {
}

const keyPrefix = "accessKeyForm";
const dateValidationTestName = "Date";

const getSchema = (dateValidationTestErrorMessage: string): Yup.AnyObjectSchema => Yup.object({
    id: Yup.string().notRequired(),
    name: Yup.string().required().max(AccessKeyConsts.NameMaxLength),
    validUntil: Yup.date()
      .nullable()
      .transform((current, original) => (original === "" ? null : current))
      .notRequired()      
      .test(dateValidationTestName, dateValidationTestErrorMessage, (toDate) => {
        const validFrom = new Date();
        return isGreaterThanOrEqual(validFrom, toDate);
      }),
  }).required();

/*
 * Access key dialog for creating and editing data
 */ 
const AccessKeyDialog = ({ 
  show,
  data,
  mode,
  onSave,
  onClose, 
}: AccessKeyDialogProps): ReactElement => {
  const { t } = useTranslation(tForms.ns, { keyPrefix });  
  const dateFormatter = useDateFormatter();
  const addMode = mode === "add";
  const { register,
    handleSubmit,
    reset,
    control,
    formState: { errors } } = useForm<AccessKey>({
      resolver: yupResolver(getSchema(t('dateValidationTestErrorMessage'))),
  });
  
  // Reset the model if the data object is difference
  useEffect(() => {
    reset();
  }, [data, reset]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSave = (model: AccessKey) => {
    const modelToSave = { ...model };
    modelToSave.name = model.name.trim();
    modelToSave.validUntil = convertDatePartToUtcDate(model.validUntil);
    onSave(modelToSave);
    reset();
  };

  return (
    <AddOrEditModelDialog
        mode={mode}
        title={t(addMode ? "titleAddAccessKey" : "titleEditAccessKey")}
        show={show}
        onHide={handleClose}
        formId='accessKeyForm'
        size={undefined}
      >
      <Modal.Body>
        <Form id='accessKeyForm' noValidate onSubmit={handleSubmit((submitData) => handleSave(submitData))}>
        <Row className="d-none">
            <Col>
              <Form.Group>
                <Form.Control
                  type='text'
                  {...register("id")}
                  defaultValue={!addMode ? data?.id : undefined}
                />
              </Form.Group>
            </Col>
          </Row>  
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelName")}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("placeholderName")}
                  {...register("name")}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  defaultValue={!addMode ? data?.name : undefined}
                />
                <Form.Control.Feedback type='invalid'>{errors?.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>                
                <Form.Label>{t("labelValidUntil")}</Form.Label>                
                <Controller
                  control={control}
                  name="validUntil"
                  rules={{ required: false, validate: (value) => isGreaterThanOrEqual(new Date(), value) }}
                  defaultValue={!addMode ? data?.validUntil : undefined}
                  shouldUnregister
                  render={({ field: { onChange, onBlur, value } }) => (
                    <DatePicker
                      dateFormat={dateFormatter.getShortDateFormat()}
                      placeholderText={dateFormatter.getShortDateFormat()}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      className={`form-control ${errors.validUntil ? "is-invalid" : ""}`}
                    />
                  )}
                />
                <div className={`invalid-feedback ${errors.validUntil ? "d-block" : ""}`}>{t('dateValidationTestErrorMessage')}</div>
              </Form.Group>
            </Col>
          </Row>          
        </Form>        
      </Modal.Body>      
    </AddOrEditModelDialog>
  );
}

export default AccessKeyDialog;