import { ReactElement, ReactNode } from "react";
import "./panels.scss";

interface PanelItemProps {
  title: string;
  children: ReactNode;
}

/*
 * Panel item with title and body
 */
export const PanelItem = ({ title, children }: PanelItemProps): ReactElement => (
  <div className='panel-item'>
    <div className='panel-item-title'>{title}</div>
    <div className='panel-item-body'>{children}</div>
  </div>
);

interface OverDuePanelItemProps extends PanelItemProps {
  date: Date | null | undefined;
  comparisonDate?: Date | null | undefined;
}

export const OverDuePanelItem = ({ title, date, children, comparisonDate }: OverDuePanelItemProps): ReactElement => {
  const overdue = date && date < (comparisonDate ?? new Date());
  return (
    <div className='panel-item'>
      <div className={`panel-item-title${overdue ? " overdue" : ""}`}>{title}</div>
      <div className='panel-item-body'>{children}</div>
    </div>
  );
};

OverDuePanelItem.defaultProps = {
  comparisonDate: undefined,
};
