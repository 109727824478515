import { ReactElement } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PaymentTransaction } from '../../models/paymentTransactions/paymentTransaction';
import { CurrencyItem } from '../../components/misc/CurrencyItem';
import { tPages } from '../../i18n';
import PaymentTransactionLink from './PaymentTransactionLink';
import useDateFormatter from '../../common/hooks/useDateFormatter';

const keyPrefix = 'paymentTransactionPage.paymentTransactionsTable';

const PaymentTransactionTable = ({
  paymentTransactions,
}: {
  paymentTransactions: PaymentTransaction[];
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  const dateFormatter = useDateFormatter();
  return (
    <>
      <Table borderless className='panel-table'>
        <thead>
          <tr>
            <td>{t('createdOn')}</td>
            <td>{t('paymentTransactionId')}</td>
            <td>{t('status')}</td>
            <td align='right'>{t('transactionAmount')}</td>
          </tr>
        </thead>
        <tbody>
          {paymentTransactions
            .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
            .map((paymentTransaction) => (
              <tr key={paymentTransaction.id}>
                <td>
                  {dateFormatter.toShortDateTime(paymentTransaction.createdOn)}
                </td>
                <td>
                  <PaymentTransactionLink id={paymentTransaction.id} />
                </td>
                <td>{paymentTransaction.status}</td>
                <td align='right' className='font-bold'>
                  <CurrencyItem
                    currency={paymentTransaction.currency}
                    value={paymentTransaction.totalAmount}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default PaymentTransactionTable;
