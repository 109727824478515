import {
  PaymentProviderCredentials,
  PaymentProviderSettings,
  AddPaymentProviderTypeConfiguration,
  PaymentProviderTypeConfiguration,
  initAddPaymentProviderTypeConfiguration,
} from "../paymentProviderConfiguration";
import PaymentProviderModelConsts from "../paymentProviderModelConsts";

export const TrueLayerProviderName = "TrueLayer";
export const TrueLayerProviderDisplayName = "TrueLayer";

export interface TrueLayerSettings extends PaymentProviderSettings {
  beneficiaryName?: string;
  beneficiaryReference?: string;
  remitterReference?: string;
  beneficiaryBankAccount: BankAccountDetails;
}

export interface BankAccountDetails {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  iban?: string;
  currency: string;
}

export interface TrueLayerCredentials extends PaymentProviderCredentials {
  clientId: string;
  clientSecret?: string;
  useSandbox: boolean;
}

export class TrueLayerProviderModelConsts extends PaymentProviderModelConsts {
  public static readonly ClientIdNameMinLength: number = 1;

  public static readonly ClientIdNameMaxLength: number = 100;

  public static readonly ClientSecretMinLength: number = 1;

  public static readonly ClientSecretMaxLength: number = 100;

  public static readonly AccountNameMinLength: number = 1;

  public static readonly AccountNameMaxLength: number = 30;

  public static readonly AccountNumberMinLength: number = 8;

  public static readonly AccountNumberMaxLength: number = 8;

  public static readonly SortCodeMinLength: number = 6;

  public static readonly SortCodeMaxLength: number = 6;

  public static readonly IbanMaxLength: number = 34;

  public static readonly BeneficiaryNameMinLength: number = 1;

  public static readonly BeneficiaryNameMaxLength: number = 30;

  public static readonly BeneficiaryReferenceMinLength: number = 1;

  //  public static readonly BeneficiaryReferenceMaxLength: number = 18;
  public static readonly BeneficiaryReferenceMaxLength: number = 60;

  public static readonly RemitterReferenceMinLength: number = 1;

  //  public static readonly RemitterReferenceMaxLength: number = 18;
  public static readonly RemitterReferenceMaxLength: number = 60;
}

export type TrueLayerPaymentProviderConfiguration = PaymentProviderTypeConfiguration<TrueLayerSettings, TrueLayerCredentials>;
export type AddTrueLayerPaymentProviderConfiguration = AddPaymentProviderTypeConfiguration<
  TrueLayerSettings,
  TrueLayerCredentials
>;

/*
 * Constructor for AddTrueLayerPaymentProviderConfiguration
 */
export const initAddTrueLayerPaymentProviderConfiguration = (name: string): AddTrueLayerPaymentProviderConfiguration => {
  const data = {
    ...initAddPaymentProviderTypeConfiguration(TrueLayerProviderName, name),
    settings: { providerName: TrueLayerProviderName } as TrueLayerSettings,
    credentials: { providerName: TrueLayerProviderName } as TrueLayerCredentials,
  };
  return data;
};
