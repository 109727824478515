/* eslint-disable import/prefer-default-export */
import lang from "i18next";
import HttpStatusCode from "../common/httpStatusCode";
import { isProblemDetails } from "../common/problemDetails";
import { tCommon } from "../i18n";
import { toastError, toastErrorObject } from "../services/notifications/toastNotification.service";

const LangOptions = tCommon;

/*
  * Handles errors from API by using Toast
  */
export const handleApiErrorWithToast = (error: unknown,
  throwOnError: boolean,
  prefixErrorKey: string): void => {

  const regularMessageKey = `toastMessages.${prefixErrorKey}Error`;
  const forbiddenMessageKey = `toastMessages.${prefixErrorKey}ForbiddenError`;

  if (isProblemDetails(error) && error.status === HttpStatusCode.Forbidden) {
    toastError(lang.t(forbiddenMessageKey, LangOptions));
  }
  else {
    toastErrorObject(error, lang.t(regularMessageKey, LangOptions));
    if (throwOnError) throw error;
  }
}