import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyItem } from '../../../components/misc/CurrencyItem';
import { PanelItem } from '../../../components/panels/Panels';
import { tPages } from '../../../i18n';
import {
  SettlementTypeMap,
  SettlementType,
} from '../../../models/settlements/settlementEnums';
import { SettlementOffer } from '../../../models/settlements/settlementOffer';

const keyPrefix = 'settlementOffersTab';

const PercentageDiscountEntry = ({
  settlementOffer,
}: {
  settlementOffer: SettlementOffer;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  return (
    <>
      <PanelItem title={t('type')}>
        {SettlementTypeMap.get(settlementOffer.type)}
      </PanelItem>
      <PanelItem title={t('percentageDiscount')}>
        {settlementOffer.percentageDiscount}%
      </PanelItem>
    </>
  );
};

const AbsoluteDiscountEntry = ({
  settlementOffer,
}: {
  settlementOffer: SettlementOffer;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  return (
    <>
      <PanelItem title={t('type')}>
        {SettlementTypeMap.get(settlementOffer.type)}
      </PanelItem>
      <PanelItem title={t('absoluteDiscount')}>
        <CurrencyItem
          currency={settlementOffer.currency}
          value={settlementOffer.amount}
        />
      </PanelItem>
    </>
  );
};

const ReplacementAmountEntry = ({
  settlementOffer,
}: {
  settlementOffer: SettlementOffer;
}): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });
  return (
    <>
      <PanelItem title={t('type')}>
        {SettlementTypeMap.get(settlementOffer.type)}
      </PanelItem>
      <PanelItem title={t('replacementAmount')}>
        <CurrencyItem
          currency={settlementOffer.currency}
          value={settlementOffer.amount}
        />
      </PanelItem>
    </>
  );
};

const SettlementTypeDiscountEntry = ({
  settlementOffer,
}: {
  settlementOffer: SettlementOffer;
}): ReactElement => {
  let typeDiscount: ReactElement;
  switch (settlementOffer.type) {
    case SettlementType.PercentageDiscount:
      typeDiscount = (
        <PercentageDiscountEntry settlementOffer={settlementOffer} />
      );
      break;
    case SettlementType.AbsoluteDiscount:
      typeDiscount = (
        <AbsoluteDiscountEntry settlementOffer={settlementOffer} />
      );
      break;
    case SettlementType.ReplacementAmount:
      typeDiscount = (
        <ReplacementAmountEntry settlementOffer={settlementOffer} />
      );
      break;
    default:
      typeDiscount = <></>;
  }
  return typeDiscount;
};

export default SettlementTypeDiscountEntry;
