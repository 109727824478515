// Name of the folder containing the assets
const AssetsFolder = "assets";

// The logo file name
const ProviderLogoFileName = "provider-logo.png";

/*
 * Returns the assets folder
 */
export const assetsFolder = (): string => `/${AssetsFolder}`;

export const buildProviderImageUrl = (providerName: string): string =>
  `${assetsFolder()}/${providerName}/${ProviderLogoFileName}`;
