import { ReactElement } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tForms } from "../../i18n";

interface FormLabelProps {
  label: string;
  subLabel: string;
}

export const FormLabel = ({ label, subLabel }: FormLabelProps): ReactElement => (
  <Form.Label>
    {label}
    <span className='optional'>{subLabel}</span>
  </Form.Label>
);

interface FormLabelOptionalProps {
  label: string;
}

const FormLabelOptional = ({ label }: FormLabelOptionalProps): ReactElement => {
  const { t } = useTranslation(tForms.ns);

  return <FormLabel label={label} subLabel={t("labelOptional")} />;
};

export default FormLabelOptional;
