import { ReactElement } from 'react';
import { BadgeSize } from '../../../../models/misc/badgeSize';
import './CommonBadge.scss';

interface CommonBadgeProps {
  text?: string;
  size?: BadgeSize;
  className?: string;
}

/*
 * Generates common badge
 */
const CommonBadge = ({ text, size, className }: CommonBadgeProps): ReactElement => {
  if (!text) return <></>;
  const buttonText = text ? text.toUpperCase() : '';
  let newClassName = className ? `${className} ` : '';
  newClassName += `common-badge`;
  newClassName += `${size ? ` ${size}-size` : ''}`;
  return <div className={newClassName}>{buttonText}</div>;
};

CommonBadge.defaultProps = {
  text: '',
  size: BadgeSize.Medium,
  className: '',
};

export default CommonBadge;
