import { ReactElement } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { PaymentTransaction } from "../../models/paymentTransactions/paymentTransaction";
import { ProviderEventsApi } from "../../common/api/payThemApi/providerEventsApi";
import Loading from "../../components/loading/Loading";
import { LoadingError } from "../../components/loading/LoadingError";
import { TranSafeProviderName } from "../../models/paymentProviders/transafe/transafeModels";
import { OpayoProviderName } from "../../models/paymentProviders/opayo/opayoModels";
import { StripeProviderName } from "../../models/paymentProviders/stripe/stripeModels";

import { getProviderEventPayload, EventPayload } from "../../helpers/providerEventsHelper";
import { tPages, tForms } from "../../i18n";
import "./ProviderDataDialog.scss";

interface ProviderDataDialogProps {
  show: boolean;
  paymentTransaction: PaymentTransaction;
  onHide: () => void;
}

type ValidDialogSizes = undefined | "sm" | "lg" | "xl";

const getProviderDialogSize = (provider: EventPayload | undefined): ValidDialogSizes => {
  switch (provider?.event?.providerName) {
    case StripeProviderName:
      return "xl";
    case OpayoProviderName:
      return "lg";
    case TranSafeProviderName:
      return "lg";
    default:
      return undefined;
  }
};

/*
 * Gets the payload data to display
 */
async function getPayload(id: string): Promise<EventPayload> {
  const api = new ProviderEventsApi();
  const events = await api.getAllAssociatedEvents(id);

  let eventPayload: EventPayload = {
    event: undefined,
    data: "",
  };

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < events.items.length; i++) {
    const event = events.items[i];
    // eslint-disable-next-line no-await-in-loop
    eventPayload = await getProviderEventPayload(event);
  }
  return eventPayload;
}

/*
 * The controls for a standard add/edit modal dialog
 */
const ProviderDataDialog = ({ show, paymentTransaction, onHide }: ProviderDataDialogProps): ReactElement => {
  const { t } = useTranslation([tPages.ns, tForms.ns]);

  const { data, isLoading, isError } = useQuery(["provider-event-item", paymentTransaction.id], () =>
    getPayload(paymentTransaction.id)
  );

  const size = getProviderDialogSize(data);

  return (
    <Modal scrollable size={size} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("paymentTransactionPage.providerDataDialog.dialogTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loading />}
        {isError && <LoadingError message='Error loading data' />}
        {data && (
          <div>
            <Table>
              <thead className='table-header'>
                <tr className='table-header-row'>
                  <td>{t("paymentTransactionPage.providerDataDialog.providerHeader")}</td>
                  <td>{t("paymentTransactionPage.providerDataDialog.eventTypeHeader")}</td>
                  <td>{t("paymentTransactionPage.providerDataDialog.recordTypeHeader")}</td>
                  <td>{t("paymentTransactionPage.providerDataDialog.recordIdHeader")}</td>
                  <td>{t("paymentTransactionPage.providerDataDialog.statusHeader")}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.event?.providerName}</td>
                  <td>{data.event?.eventType}</td>
                  <td>{data.event?.recordType}</td>
                  <td>{data.event?.recordId}</td>
                  <td>{data.event?.status}</td>
                </tr>
              </tbody>
            </Table>
            <pre>{data.data}</pre>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t("buttonOk", tForms)}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderDataDialog;
