/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, ReactElement, useState } from "react";
import { FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { CurrentFormModel, CurrentPaymentMethodType, CurrentPaymentProviderConfiguration } from "./tranSafeConfiguration";
import { AddOrEditMode } from "../ProvidersCommon/Common";
import { PaymentProvider } from "../../../../models/paymentProviders/paymentProvider";
import ItemFilterGroup from "../../../../models/itemFiltering/itemFilterGroup";
import { tPaymentProviders, tForms } from "../../../../i18n";
import {
  CommonProviderFormControls,
  ProviderFilterFormControl,
  ProviderSupportedMethodsFormControl,
  hasSupportedCurrencies,
  ProviderSupportedCurrenciesFormControl,
} from "../ProvidersCommon/CommonControls";
import BooleanFormSelect from "../ProvidersCommon/BooleanFormSelect";
import {
  TranSafeTestSitePassword,
  TranSafeTestSiteUserName,
} from "../../../../models/paymentProviders/transafe/transafeModels";
import { FillerDisplayPassword, isFillerDisplayPassword } from "../../../../utils/secureStringUtils";
import { testTransafeConnection } from "../../../../services/transafe/transafe.service";

const keyPrefix = "tranSafeForm";

interface TranSafeGeneralSettingsTabProps {
  mode: AddOrEditMode;
  data?: CurrentPaymentProviderConfiguration;
  paymentProvider?: PaymentProvider;
  itemFilterGroup: ItemFilterGroup | undefined;
  onShowFilterDialog: () => void;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  disableUserDetails: boolean;
  setDisableUserDetails: (disabled: boolean) => void;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<CurrentFormModel>;
}

/*
 * General settings for TranSafe
 */
const TranSafeGeneralSettingsTab = ({
  mode,
  data,
  paymentProvider,
  itemFilterGroup,
  onShowFilterDialog,
  handleSubmit,
  disableUserDetails,
  setDisableUserDetails,
  setValue,
  register,
  errors,
}: TranSafeGeneralSettingsTabProps): ReactElement => {
  const { t } = useTranslation([tPaymentProviders.ns, tForms.ns], { keyPrefix });
  const [testingConnection, setTestingConnection] = useState(false);

  const handleUseTestSite = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setValue("userName", TranSafeTestSiteUserName);
    setValue("password", TranSafeTestSitePassword);
    const disabled: boolean = e.target?.value === "true";
    setDisableUserDetails(disabled);
  };

  const addMode = mode === "add";
  const defaultPassword = addMode ? undefined : FillerDisplayPassword;

  // For now TranSafe only supports one payment method type
  const paymentMethodTypeInfo = paymentProvider?.paymentMethodTypesInfo.find(
    (x) => x.paymentMethodType === CurrentPaymentMethodType
  );

  const handleTestConnection = async () => {
    handleSubmit(async (formData) => {
      try {
        setTestingConnection(true);

        const model = formData as CurrentFormModel;

        if (!addMode && isFillerDisplayPassword(model.password)) {
          await testTransafeConnection(model.userName, undefined, model.isTest, data?.id);
        } else {
          await testTransafeConnection(model.userName, model.password, model.isTest);
        }
      } finally {
        setTestingConnection(false);
      }
    })();
  };

  return (
    <Row>
      <Col className='panel-column'>
        <Card className='custom-detail-card mb-3'>
          <Card.Body>
            <CommonProviderFormControls data={data} register={register} errors={errors} />
            <hr />
            <Row className='mb-2'>
              <Col>
                <ProviderFilterFormControl filterGroup={itemFilterGroup} onClick={() => onShowFilterDialog()} />
              </Col>
              <ProviderSupportedMethodsFormControl
                supportedPaymentMethodGroups={paymentProvider?.supportedPaymentMethodGroups}
              />
              {hasSupportedCurrencies(paymentMethodTypeInfo) && (
                <>
                  <Form.Group as={Col} sm='6'>
                    {" "}
                  </Form.Group>
                  <ProviderSupportedCurrenciesFormControl supportedCurrencies={paymentMethodTypeInfo!.supportedCurrencies} />
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Card className='custom-detail-card'>
          <Card.Body>
            <Card.Title>{t("titleCredentials")}</Card.Title>
            <Row>
              <Col>
                <Row>
                  <Form.Group as={Col} sm='2'>
                    <Form.Label>{t("labelUseTestSite")}</Form.Label>
                    <BooleanFormSelect
                      register={register}
                      fieldName='isTest'
                      trueOption={t("optionTestServer")}
                      falseOption={t("optionLiveServer")}
                      reverseOrder
                      defaultState={data?.isTest}
                      onChange={handleUseTestSite}
                    />
                  </Form.Group>
                  <Form.Control.Feedback type='invalid'>{errors?.isTest?.message}</Form.Control.Feedback>
                </Row>
                <Row>
                  <Form.Group as={Col} sm='6'>
                    <Form.Label>{t("labelUserName")}</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("placeholderUserName")}
                      {...register("userName")}
                      className={`form-control ${errors?.userName?.message ? "is-invalid" : ""}`}
                      defaultValue={data?.credentials?.userName}
                      disabled={disableUserDetails}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.userName?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} sm='6'>
                    <Form.Label>{t("labelPassword")}</Form.Label>

                    <div className='d-flex gap-2'>
                      <Col>
                        <Form.Control
                          type='password'
                          placeholder={t("placeholderPassword")}
                          {...register("password")}
                          className={`m-0 form-control ${errors?.password?.message ? "is-invalid" : ""}`}
                          defaultValue={defaultPassword}
                          disabled={disableUserDetails}
                        />
                        <Form.Control.Feedback type='invalid'>{errors?.password?.message}</Form.Control.Feedback>
                      </Col>

                      <Button disabled={testingConnection} variant='secondary' size='sm' onClick={handleTestConnection}>
                        {t("buttonTestConnection")}
                      </Button>
                    </div>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

TranSafeGeneralSettingsTab.defaultProps = {
  data: undefined,
  paymentProvider: undefined,
};

export default TranSafeGeneralSettingsTab;
