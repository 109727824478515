export enum DatasetStatus {
  Initialise = "Initialise",
  Provisioning = "Provisioning",
  Error = "Error",
  Deleting = "Deleting",
  Inactive = "Inactive",
  Active = "Active",
  Migrating = "Migrating",
  Resetting = "Resetting",
}

export enum SupportedErps {
  Sage100 = "Sage100",
  Sage200Pro = "Sage200Pro",
  Custom = "Custom",
}

export enum DatasetRebuildStatus {
  Normal = "Normal",
  Initialising = "Initialising",
  Processing = "Processing",
  Error = "Error",
}

export enum DatasetType {
  Production = "Production",
  Demo = "Demo",
}
