import lang from "i18next";
import { Account } from "../../models/accounts/account";
import { AccountsApi } from "../../common/api/payThemApi/accountsApi";
import { toastSuccess } from "../notifications/toastNotification.service";
import { AccountIncludeOptions } from "../../common/api/payThemApi/accountIncludeOptions";
import { AccountSettings } from "../../models/accounts/accountSettings";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";
import { PayerIncludeOptions } from "../../common/api/payThemApi/payerIncludeOptions";
import { Payer } from "../../models/payers/payer";

const LangOptions = { ns: "common" };

/*
 *  Gets the account with the given Id
 */
export const getAccount = async (id: string): Promise<Account | undefined> => {
  const api = new AccountsApi();
  try {
    const includeOptions = { contacts: true, accountSettings: true } as AccountIncludeOptions;
    const result = await api.getAccount(id, includeOptions);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, true, "accounts.accountGet");
  }
  return undefined;
};

/*
 * Returns a payer
 */
export const getAccountPayer = async (accountId: string, id: string, includeOptions?: PayerIncludeOptions): Promise<Payer> => {
  try {
    const api = new AccountsApi();
    return await api.getAccountPayer(accountId, id, includeOptions);
  } catch (error) {
    handleApiErrorWithToast(error, true, "accounts.payerGet");
    throw error;
  }
};

/*
 * Returns the number of payers associated to the account
 */
export const countAccountPayers = async (accountId: string): Promise<number> => {
  try {
    const api = new AccountsApi();
    const result = await api.getAccountPayerCount(accountId);
    return result.totalItems;
  } catch (error) {
    handleApiErrorWithToast(error, true, "generalHttp");
    throw error;
  }
};

/*
 * Updates the account settings for the given account
 */
export const updateAccountSettings = async (
  accountId: string,
  accountSettings: AccountSettings,
  showSuccessMessage?: boolean
): Promise<void> => {
  const api = new AccountsApi();
  try {
    await api.updateAccountSettings(accountId, accountSettings);
    if (showSuccessMessage) {
      toastSuccess(lang.t("toastMessages.accounts.accountSettingsUpdatedSuccess", LangOptions));
    }
  } catch (error) {
    handleApiErrorWithToast(error, true, "accounts.accountSettingsUpdate");
  }
};

// Commented out until we're happy with the API
// /*
//  * Updates the settlement configuration group on the account
//  */
// export const updateAccountSettlementConfigurationGroup = async (
//   accountId: string,
//   disableAutoApplySettlements: boolean,
//   settlementConfigurationGroupId: string | null | undefined,
//   showSuccessMessage?: boolean
// ): Promise<Account> => {
//   const api = new AccountsApi();
//   try {
//     const result = await api.updateAccountSettlementConfigurationGroup(
//       accountId,
//       disableAutoApplySettlements,
//       settlementConfigurationGroupId
//     );
//     if (showSuccessMessage) {
//       toastSuccess(lang.t("toastMessages.accounts.accountUpdatedSuccess", LangOptions));
//     }
//     return result;
//   } catch (error) {
//     toastErrorObject(error, lang.t("toastMessages.accounts.settlementIdUpdateError", LangOptions));
//     throw error;
//   }
// };

// /*
//  * Updates the late payment configuration group on the account
//  */
// export const updateAccountLatePaymentConfiguration = async (
//   accountId: string,
//   disableLatePayment: boolean,
//   latePaymentConfigurationId?: string,
//   showSuccessMessage?: boolean
// ): Promise<Account> => {
//   const api = new AccountsApi();
//   try {
//     const result = await api.updateAccountLatePaymentConfiguration(accountId, disableLatePayment, latePaymentConfigurationId);
//     if (showSuccessMessage) {
//       toastSuccess(lang.t("toastMessages.accounts.accountUpdatedSuccess", LangOptions));
//     }
//     return result;
//   } catch (error) {
//     toastErrorObject(error, lang.t("toastMessages.accounts.latePaymentIdUpdateError", LangOptions));
//     throw error;
//   }
// };
