import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tPages } from "../../i18n";
import "./PageTitle.scss";
import useWebsiteView from "../../context/websiteView/WebsiteViewContext";

interface PageTitleProps {
  title: string;
  children?: React.ReactNode;
}

// Component to show the page title
export const PageTitle = ({ title, children }: PageTitleProps): ReactElement => {
  const { t } = useTranslation([tPages.ns]);
  const webView = useWebsiteView();

  if (webView?.removeTitle === true) return <></>;

  return (
    <div className='page-title'>
      {t(title)}
      {children}
    </div>
  );
};

PageTitle.defaultProps = {
  children: undefined,
};
export default PageTitle;
