import { ReactElement } from 'react';
import { isOverdue } from '../../helpers/dateHelpers';
import { DateOutputType } from '../../utils/dateFormatter';
import './OverdueDate.scss';
import useDateFormatter from '../../common/hooks/useDateFormatter';

const DefaultBlankText = '-';

interface OverdueDateProps {
  date?: Date | undefined | null;
  blankText?: string;
  outputType?: DateOutputType;
}

/*
 * Displays a date in bold red if it is overdue
 */
const OverdueDate = ({
  date,
  blankText,
  outputType,
}: OverdueDateProps): ReactElement => {
  const dateFormatter = useDateFormatter();
  const overdueClass = isOverdue(date) ? 'overdue' : '';
  const formattedDate = dateFormatter.dateTimeOrString(
    date,
    blankText,
    outputType
  );
  return <div className={overdueClass}>{formattedDate}</div>;
};

OverdueDate.defaultProps = {
  date: undefined,
  blankText: DefaultBlankText,
  outputType: DateOutputType.ShortDateTime,
};

export default OverdueDate;
