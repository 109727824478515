import { ReactElement, useCallback, useState } from "react";
import { TypeColumn, TypeDataSource, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { PaymentRequest } from "../../../models/paymentRequests/paymentRequest";
import {
  DataGridReadyItem,
  LoadDataProps,
  LoadDataResult,
  SortDesending,
  loadPageData,
} from "../../../components/grid/dataGridHelpers";
import { PaymentRequestsApi } from "../../../common/api/payThemApi/paymentRequestsApi";
import { DataGrid } from "../../../components/grid/DataGrid";
import { DataGridStorage } from "../../../common/hooks/useDataGridStorage";

export const DefaultSortColumnName = "createdOn";
export const DefaultSortDirection = SortDesending;

export const PaymentRequestSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

export const PaymentRequestFilters: TypeSingleFilterValue[] = [
  { name: "customerName", type: "string", operator: "contains", value: "" },
  { name: "amountToPay", operator: "gte", type: "number", value: "" },
  { name: "requestStatus", operator: "eq", type: "select", value: "" },
  { name: "captureType", operator: "eq", type: "select", value: "" },
  { name: "requestType", operator: "eq", type: "select", value: "" },
];

/*
 * Callback function for loading in a page of data
 */
const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps): Promise<LoadDataResult<PaymentRequest>> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, filterValue, async (pageParameters) => {
    const api = new PaymentRequestsApi();
    const result = await api.getPaymentRequests(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter,
      false
    );
    return result;
  });

interface PaymentRequestGridProps {
  columns: TypeColumn[];
  dataSource?: TypeDataSource;
  gridStorage?: DataGridStorage;
  onReady?: (computedPropsRef: DataGridReadyItem) => void;
}

/*
 * The payment request grid, used by various list components
 */
const PaymentRequestGrid = ({ columns, dataSource, gridStorage, onReady }: PaymentRequestGridProps): ReactElement => {
  const defaultDataSource = useCallback(loadData, []);
  const [gridColumns] = useState(columns);

  // Grid state is optional
  const gridState = gridStorage?.dataGridState;
  const haveGridData = gridState !== undefined;

  return (
    <DataGrid<PaymentRequest>
      idProperty='id'
      columns={gridColumns}
      dataSource={dataSource ?? defaultDataSource}
      defaultFilterValue={gridState?.filters ?? PaymentRequestFilters}
      enableFiltering
      onFilterValueChange={(filterValue) => (haveGridData ? gridStorage?.setFilters(filterValue) : true)}
      defaultSortInfo={gridState?.sortInfo}
      onSortInfoChange={(sortInfo) => (haveGridData ? gridStorage?.setSortInfo(sortInfo) : true)}
      defaultSkip={gridState?.pagination?.skip}
      onSkipChange={(skip) => (haveGridData ? gridStorage?.setPaginationSkip(skip) : true)}
      defaultLimit={gridState?.pagination?.limit}
      onLimitChange={(limit) => (haveGridData ? gridStorage?.setPaginationLimit(limit) : true)}
      onReady={onReady}
    />
  );
};

PaymentRequestGrid.defaultProps = {
  dataSource: undefined,
  gridStorage: undefined,
  onReady: undefined,
};

export default PaymentRequestGrid;
