import { TenantSetting } from "../../../models/tenantSettings/tenantSetting";
import TenantSettingKey from "../../../models/tenantSettings/tenantSettingEnums";
import BasePayThemApi from "./basePayThemApi";

class TenantSettingsApi extends BasePayThemApi {
  private endPoint = "tenant-settings";

  /*
   * Returns any value associated to the tenant
   */
  public async getTenantSetting(key: TenantSettingKey): Promise<TenantSetting | undefined> {
    const url = `${this.endPoint}/${key}`;
    const result = await this.getOrNotFound<TenantSetting>(url);
    return result;
  }
}

export default TenantSettingsApi;
