import { createContext, ReactElement, useCallback, useContext, useState } from "react";
import lang from "i18next";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import { TypeColumn, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { PageTitle } from "../../common/PageTitle";
import { PaymentProviderConfiguration } from "../../../models/paymentProviders/paymentProviderConfiguration";
import { DataGrid, TypeColumnRender, DataGridContextType } from "../../../components/grid/DataGrid";
import {
  SortAscending,
  LoadDataProps,
  LoadDataResult,
  loadPageData,
  BaseOptionsColumn,
  defaultDataGridPagination,
  DataGridReadyItem,
} from "../../../components/grid/dataGridHelpers";
import { EditItemViaRouteButton } from "../../../components/buttons/CommonIconButtons";
import AddProviderButton from "./AddProviderButton";
import { SettingsRouteConsts } from "../../SettingsRouteConsts";
import { DeleteItemConfirmation } from "../../../components/icons/DeleteItemConfirmation";
import {
  getConfigurations,
  deleteConfiguraiton,
  getDefaultPaymentProvider,
} from "../../../services/paymentProviders/paymentProviders.service";
import { HtmlTitle } from "../../common/HtmlTitle";
import { tPages, tPaymentProviders } from "../../../i18n";
import { ProviderLogo } from "./ProviderLogo";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import PageResult from "../../../common/api/PageResults";
import EnabledTag from "../../../components/tags/EnabledTag";
import GridNamesConsts from "../../GridNamesConsts";
import PageLoading from "../../../components/loading/PageLoading";
import { paymentProviderConfigurationIncludeAll } from "../../../common/api/payThemApi/paymentProviderConfigurationIncludeOptions";
import useDataGridStorage from "../../../common/hooks/useDataGridStorage";
import "./PaymentProvidersList.scss";

const LangOptions = tPages;

const DataGridContext = createContext<DataGridContextType>(undefined);

const keyPrefix = "paymentProviders";

/*
 * Column buttons to edit/delete
 */
const ColumnButtons = ({ configuration }: { configuration: PaymentProviderConfiguration }): ReactElement => {
  const context = useContext(DataGridContext);
  const { t } = useTranslation(tPages.ns);

  // Deletes a configuration
  const handleDeleteConfiguration = async (id: string): Promise<void> => {
    await deleteConfiguraiton(id);
    await context?.refreshDataSource();
  };

  return (
    <>
      <EditItemViaRouteButton route={SettingsRouteConsts.PaymentProviders} id={configuration.id} />
      <DeleteItemConfirmation<string>
        item={configuration.id}
        onDelete={handleDeleteConfiguration}
        body={t("paymentProvidersList.confirmDeleteConfiguration", { name: configuration.name })}
      />
    </>
  );
};

const columns: TypeColumn[] = [
  {
    name: "providerName",
    header: lang.t("paymentProvidersList.columnProviderName", LangOptions),
    minWidth: 175,
    defaultFlex: 1,
    render: ({ data }: TypeColumnRender<PaymentProviderConfiguration>) => <ProviderLogo configuration={data} height={40} />,
  },
  {
    name: "name",
    header: lang.t("paymentProvidersList.columnName", LangOptions),
    minWidth: 200,
    defaultFlex: 2,
  },
  {
    name: "description",
    header: lang.t("paymentProvidersList.columnDescription", LangOptions),
    minWidth: 200,
    defaultFlex: 3,
  },
  {
    name: "displayPriority",
    header: lang.t("paymentProvidersList.columnDisplayPriority", LangOptions),
    type: "number",
    textAlign: "right",
    headerAlign: "end",
    maxWidth: 92,
  },
  {
    name: "filterGroup.filterConfigurations",
    header: lang.t("paymentProvidersList.columnFilterCount", LangOptions),
    textAlign: "right",
    type: "number",
    headerAlign: "end",
    maxWidth: 85,
    sortable: false,
    render: ({ data }: TypeColumnRender<PaymentProviderConfiguration>) =>
      data.paymentMethodConfigurations.reduce((a, b) => a + (b.filterGroup?.filterConfigurations?.length ?? 0), 0),
  },
  {
    name: "enabled",
    header: lang.t("paymentProvidersList.columEnabled", LangOptions),
    textAlign: "center",
    headerAlign: "center",
    maxWidth: 98,
    defaultFlex: 1,
    render: ({ data }: TypeColumnRender<PaymentProviderConfiguration>) => <EnabledTag enabled={data.enabled} />,
  },
  {
    ...BaseOptionsColumn,
    maxWidth: 75,
    render: ({ data }: TypeColumnRender<PaymentProviderConfiguration>) => <ColumnButtons configuration={data} />,
  },
];

const defaultFilters: TypeSingleFilterValue[] = [];

type MyLoadDataResult = LoadDataResult<PaymentProviderConfiguration>;

/*
 * Default sort column
 */
const DefaultSortColumnName = "name";
const DefaultSortDirection = SortAscending;
const defaultSortInfo = { name: DefaultSortColumnName, dir: DefaultSortDirection } as TypeSingleSortInfo;

const loadData = async ({ skip, limit, sortInfo }: LoadDataProps): Promise<MyLoadDataResult> =>
  loadPageData(skip, limit, sortInfo, DefaultSortColumnName, DefaultSortDirection, undefined, async (pageParameters) => {
    const result = await getConfigurations(
      pageParameters.page,
      pageParameters.itemsPerPage,
      pageParameters.sort,
      pageParameters.filter,
      paymentProviderConfigurationIncludeAll
    );
    return result;
  });

interface DisplayNeedDefaultPaymentProviderMessageProps {
  defaultPaymentProvider: PaymentProviderConfiguration | undefined;
}

/*
 * Checks if any provider is selected / available and displays a message if not
 */
const DisplayNeedDefaultPaymentProviderMessage = ({
  defaultPaymentProvider,
}: DisplayNeedDefaultPaymentProviderMessageProps): ReactElement => {
  const { t } = useTranslation(tPaymentProviders.ns, { keyPrefix });

  const {
    data: paymentProviders,
    isLoading: isPaymentProvidersLoading,
    isError: isPaymentProvidersError,
  } = useQuery<PageResult<PaymentProviderConfiguration> | undefined>(
    [QueryNameConsts.PaymentProvidersList, defaultPaymentProvider],
    () => getConfigurations(1, 1)
  );

  if (isPaymentProvidersLoading) return <></>;
  if (isPaymentProvidersError) return <>{t("errorLoadingData")}</>;

  if (paymentProviders?.items.length === 0)
    return (
      <>
        <Alert variant='danger'>
          <div className='alert-title'>{t("titleNoPaymentProvidersConfigured")}</div>
          <div>{t("descriptionNoPaymentProvidersConfigured")}</div>
        </Alert>
      </>
    );

  if (defaultPaymentProvider === undefined)
    return (
      <>
        <Alert variant='danger'>
          <div className='alert-title'>{t("titleNoDefaultPaymentProviderSelected")}</div>
          <div>{t("descriptionNoDefaultPaymentProviderSelected")}</div>
        </Alert>
      </>
    );

  return <></>;
};

/*
 * Our grid name
 */
const GridName = GridNamesConsts.PaymentProviderGrid;

/*
 * The list
 */
export const PaymentProviders = (): ReactElement => {
  const { t } = useTranslation(tPages.ns);
  const dataSource = useCallback(loadData, []);
  const { dataGridState, setSortInfo, setPaginationSkip, setPaginationLimit } = useDataGridStorage(
    GridName,
    defaultFilters,
    defaultSortInfo,
    defaultDataGridPagination,
    columns
  );
  const [gridRef, setGridRef] = useState<DataGridReadyItem>();

  // Load in the default payment provider
  const {
    data: defaultPaymentProvider,
    isLoading: isDefaultPaymentProviderLoading,
    isError: isDefaultPaymentProviderError,
    refetch: refetchDefaultPaymentProvider,
  } = useQuery<PaymentProviderConfiguration | undefined>([QueryNameConsts.PaymentProviderGetDefault], () =>
    getDefaultPaymentProvider()
  );

  const readLoad = async () => {
    gridRef?.current?.reload();
    await refetchDefaultPaymentProvider();
  };

  return (
    <>
      <HtmlTitle subTitle1={t("pageTitlePaymentProviders", tPages)} />
      {dataGridState ? (
        <>
          <PageTitle title='pageTitlePaymentProviders' />
          {!isDefaultPaymentProviderLoading && !isDefaultPaymentProviderError && (
            <DisplayNeedDefaultPaymentProviderMessage defaultPaymentProvider={defaultPaymentProvider} />
          )}
          <section className='data-grid-page'>
            <DataGridContext.Provider value={{ refreshDataSource: readLoad }}>
              <DataGrid<PaymentProviderConfiguration>
                idProperty='id'
                columns={columns}
                dataSource={dataSource}
                defaultSortInfo={dataGridState.sortInfo}
                onSortInfoChange={(sortInfo) => setSortInfo(sortInfo)}
                defaultSkip={dataGridState.pagination?.skip}
                onSkipChange={(skip) => setPaginationSkip(skip)}
                defaultLimit={dataGridState.pagination?.limit}
                onLimitChange={(limit) => setPaginationLimit(limit)}
                onReady={setGridRef}
              />
            </DataGridContext.Provider>
            <div className='bottom-button-group'>
              <AddProviderButton />
            </div>
          </section>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default PaymentProviders;
