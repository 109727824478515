import { ReactElement, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import UserNavDropDown from "./UserNavDropDown";
import DatasetNavDropDown from "./DatasetNavDropDown";
import { CollapseNavItems } from "./CollapseNavItems";
import getProductInfo from "../../common/productInfo";
import "./TopNavigationMenu.scss";
import useMe from "../../common/hooks/useMe";
import { useTenantScopePage } from "../../common/hooks/useTenantScopePage";

/*
 * Component to display the top panel navigation menu
 */
const TopNavigationMenu = (): ReactElement => {
  const { t } = useTranslation();
  const { me } = useMe();
  const [, setExpanded] = useState(true);
  const tenantScopePage = useTenantScopePage();

  const handleDatasetChange = () => {
    window.location.reload();
  };

  const productInfo = getProductInfo();

  const collapseSize = "md";
  const email = me?.emailAddress ?? "";
  const name = me?.displayName ?? "";

  const renderDataSetDropdown = (): ReactElement => {
    if (tenantScopePage) {
      return <></>;
    }
    return <>
      <Navbar.Text className={`nav-divider d-none d-${collapseSize}-block`} />
      <DatasetNavDropDown onDatasetChange={handleDatasetChange} />
    </>;
  }

  return (
    <Navbar
      collapseOnSelect
      expand={collapseSize}
      className='top-nav-header shadow-sm'
      variant='light'
      onToggle={(state) => setExpanded(state)}
    >
      <Navbar.Brand href='/'>
        <img src='/assets/images/paythem-logo-mono.svg' className='header-logo' alt='PayThem by Draycir' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ms-auto'>
          <CollapseNavItems className={`d-${collapseSize}-none`} />

          <NavDropdown align='end' title={t("Help")}>
            <NavDropdown.Item href={productInfo.documentationUrl} target='_blank'>
              <FontAwesomeIcon icon={faBook} />
              {t("Documentation")}
            </NavDropdown.Item>
          </NavDropdown>

          {renderDataSetDropdown()}
          <Navbar.Text className={`nav-divider d-none d-${collapseSize}-block`} />

          {/* <SettingsNavDropDown /> */}
          <UserNavDropDown email={email} name={name} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavigationMenu;
