import { ReactElement, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { tPages } from "../../../i18n";
import { NotificationSetting } from "../../../models/notifications/notificationSetting";
import { CreateNotificationUser, NotificationUser } from "../../../models/notifications/notificationUser";
import { NotificationUserType } from "../../../models/notifications/notificationUserType";
import {
  addNotificationUser,
  deleteNotificationUser,
  getAllNotificationUsers,
  updateNotificationUser,
} from "../../../services/notifications/notification.service";
import { getUsers } from "../../../services/users/user.services";
import AddUserButton from "./AddUserButton";
import DeleteUserButton from "./DeleteUserButton";
import { NotificationItem, getDefaultUserNotificationItems } from "./NotificationItems";
import NotificationSwitchList from "./NotificationSwitchList";
import "./NotificationsSettingsPage.scss";
import "../../viewsCommon.scss";
import AllowInternalNotificationsAlert from "../../../components/tenantSettingAlerts/AllowInternalNotificationsAlert";

const keyPrefix = "notificationsPage";

/*
 * The tab for configuring PayThem user notifications
 */
const UserNotificationsTab = (): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [selectedNotificationUser, setSelectedNotificationUser] = useState<string>();
  const [notificationItems, setNotificationItems] = useState<NotificationItem[]>(getDefaultUserNotificationItems());

  // Rebuilds the notification switches using the given user
  const buildNotificationItemsFromUser = (user: NotificationUser | undefined) => {
    const items = getDefaultUserNotificationItems().map((defaultItem) => {
      const item: NotificationItem = { ...defaultItem };
      const result = user?.notificationSettings?.find((y) => y.notificationName === item.notificationName);
      if (result) {
        item.enabled = result.isEnabled;
      } else {
        item.enabled = false;
      }
      return item;
    });
    setNotificationItems(items);
  };

  // Reads in all of the notification users
  const {
    data: notificationUsers,
    isLoading: isNotificationUsersLoading,
    refetch: refetchNotificationUsers,
  } = useQuery([QueryNameConsts.NotificationUsersList], async () => {
    const results = await getAllNotificationUsers(true);
    let selectedUser = results.find((x) => x.id === selectedNotificationUser);
    if (!selectedUser) {
      selectedUser = results.length > 0 ? results[0] : undefined;
      setSelectedNotificationUser(selectedUser?.id);
    }
    buildNotificationItemsFromUser(selectedUser);
    return results;
  });

  // Get all PayThem users
  const { data: users, isLoading: isUsersLoading } = useQuery([QueryNameConsts.UsersList], async () => getUsers());

  const getCurrentNotificationUser = (): NotificationUser | undefined =>
    notificationUsers?.find((x) => x.id === selectedNotificationUser);

  // Rebuilds notification list when the user selection has changed
  const handleUserChange = (id: string) => {
    const user = notificationUsers?.find((x) => x.id === id);
    setSelectedNotificationUser(user?.id);
    buildNotificationItemsFromUser(user);
  };

  // Handles the creation of a new user
  const handleNewUser = async (newUser: CreateNotificationUser): Promise<void> => {
    const result = await addNotificationUser(newUser);
    await refetchNotificationUsers();
    setSelectedNotificationUser(result.id);
    buildNotificationItemsFromUser(result);
  };

  // Handles deleting a user
  const handleDeleteUser = async (): Promise<void> => {
    if (selectedNotificationUser) {
      await deleteNotificationUser(selectedNotificationUser);
    }
    refetchNotificationUsers();
  };

  // Handles a notification check box changing
  const handleNotificationCheck = async (notificationName: string, isEnabled: boolean): Promise<void> => {
    // First lets set the item in the user settings
    const user = getCurrentNotificationUser();
    if (user) {
      if (!user.notificationSettings) user.notificationSettings = [];

      let setting = user.notificationSettings.find((x) => x.notificationName === notificationName);
      if (!setting) {
        setting = {
          notificationUserId: selectedNotificationUser,
          notificationName,
          isEnabled,
        } as NotificationSetting;
        user.notificationSettings.push(setting);
      } else {
        setting.isEnabled = isEnabled;
      }

      // Now update the user
      await updateNotificationUser(user);
      refetchNotificationUsers();
    }

    setNotificationItems((items) => {
      const item = items.find((x) => x.notificationName === notificationName);
      if (item) {
        item.enabled = isEnabled;
      }
      return items.map((x) => x);
    });
  };

  // Builds the drop down display name
  const getDisplayName = (notificationUser: NotificationUser): string => {
    if (notificationUser.type === NotificationUserType.EmailAddress) {
      return notificationUser.emailAddress ?? "Unknown";
    }

    const user = users?.find((x) => x.id === notificationUser.userId);
    if (!user) return "Unknown";

    return `${user.displayName} - (${user.emailAddress})`;
  };

  const haveNotificationUsers = notificationUsers && notificationUsers.length > 0;
  const panelEnabled = !isNotificationUsersLoading && notificationUsers && notificationUsers.length > 0;
  const stillLoading = isNotificationUsersLoading && isUsersLoading;

  return (
    <>
      <Row>
        <Col className='panel-column'>
          <Card aria-disabled>
            <Card.Title>
              <AllowInternalNotificationsAlert />
            </Card.Title>
            <Card.Body>
              <Row>
                <p>{t("labelUserInstructions1")} </p>
                <p>{t("labelUserInstructions2")} </p>
                <Form.Group as={Col}>
                  <Form.Label>{t("labelNotificationUsers")}</Form.Label>
                  <div className='d-flex justify-content-start gap-3'>
                    <Col sm='6'>
                      <Form.Select value={selectedNotificationUser} onChange={(e) => handleUserChange(e.currentTarget.value)}>
                        {notificationUsers &&
                          users &&
                          notificationUsers
                            .sort((a, b) => (getDisplayName(a) < getDisplayName(b) ? 1 : -1))
                            .map((user) => (
                              <option key={user.id} value={user.id}>
                                {getDisplayName(user)}
                              </option>
                            ))}
                      </Form.Select>
                    </Col>
                    <div>
                      <div className='d-flex justify-content-start gap-2'>
                        <AddUserButton
                          notificationUsers={notificationUsers ?? []}
                          users={users ?? []}
                          onSave={(newUser) => handleNewUser(newUser)}
                        />
                        <DeleteUserButton
                          disabled={stillLoading === true || haveNotificationUsers === false}
                          onDelete={() => handleDeleteUser()}
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row className={`${panelEnabled ? "" : "panel-disabled"}`}>
                <Col>
                  <NotificationSwitchList
                    notificationItems={notificationItems}
                    onNotificationChanged={handleNotificationCheck}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserNotificationsTab;
