import lang from "i18next";
import { toastError } from "../notifications/toastNotification.service";

const MinimumImageFileSize = 50;
const MaximumImageFileSize = 4 * 1024 * 1024;
const ValidImageExtensions = ["jpg", "jpeg", "png", "svg"];

/*
 * Returns the file name extension if exists or undefined if not
 */
function getExtenstion(filename: string): string | undefined {
  const items = filename.split(".");
  if (items.length <= 1) return undefined;
  return items.pop();
}

/*
 * Some quick pre-post validation on the file upload
 */
export function validateImage(filename: string, fileSize: number): boolean {
  let errorMessage: string | undefined;
  if (fileSize < MinimumImageFileSize) errorMessage = `File size is too small, minimum allowed ${MinimumImageFileSize} bytes`;
  if (fileSize > MaximumImageFileSize) errorMessage = `File size is too large, maximum allowed ${MaximumImageFileSize} bytes`;
  if (errorMessage) {
    toastError(lang.t(errorMessage));
    return false;
  }

  const extension = getExtenstion(filename)?.toLocaleLowerCase();
  if (extension) {
    if (!ValidImageExtensions.find((x) => x === extension)) {
      errorMessage = `File with the extension ${extension} is not supported.`;
    }
  } else {
    errorMessage = "Invalid file extension.";
  }

  if (errorMessage) {
    toastError(lang.t(errorMessage));
    return false;
  }

  return true;
}

export default validateImage;
