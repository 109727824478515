import { FormEvent, ReactElement, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { tPages } from "../../../i18n";
import { AccountSettings } from "../../../models/accounts/accountSettings";
import { getAllSettlementConfigurationGroups } from "../../../services/settlements/settlementConfiguration.service";

const NoSettlementConfigurationOption = "noSettlementConfigurationSelection";
const UseDefaultSettlementConfigurationOption = "useDefaultSelectionConfigurationSelection";

const keyPrefix = "accountPage.settings";

interface SettlementConfigurationSelectionProps {
  accountSettings: AccountSettings;
  saving: boolean;
  onUpdate: (settlementId: string | undefined, disableAutoApplySettlements: boolean) => Promise<void>;
}

/*
 * Settlement configuration selection dropdown
 */
const SettlementConfigurationSelection = ({
  accountSettings,
  saving,
  onUpdate,
}: SettlementConfigurationSelectionProps): ReactElement => {
  const { t } = useTranslation([tPages.ns], { keyPrefix });

  // Work out state of dropdown
  let selectedState = UseDefaultSettlementConfigurationOption;
  if (accountSettings.disableAutoApplySettlements === true) {
    selectedState = NoSettlementConfigurationOption;
  } else if (accountSettings.settlementConfigurationGroupId) {
    selectedState = accountSettings.settlementConfigurationGroupId;
  }
  const [selectedSettlementConfigurationGroup, setSelectedSettlementConfigurationGroup] = useState(selectedState);

  const { data: settlementGroups, isLoading } = useQuery([QueryNameConsts.SettlementConfigurationsList], () =>
    getAllSettlementConfigurationGroups()
  );

  // Saves the new settlement configuration group
  const handleSettlementConfigurationChange = async (e: FormEvent<HTMLSelectElement>) => {
    const selectionValue = e.currentTarget.value;

    let newSettlementId: string | undefined;
    let disableAutoApplySettlements = false;

    if (selectionValue === NoSettlementConfigurationOption) {
      newSettlementId = undefined;
      disableAutoApplySettlements = true;
    } else if (selectionValue === UseDefaultSettlementConfigurationOption) {
      newSettlementId = undefined;
    } else {
      newSettlementId = selectionValue;
    }
    await onUpdate(newSettlementId, disableAutoApplySettlements);
    setSelectedSettlementConfigurationGroup(selectionValue);
  };

  // Build the options from the available configurations
  const SettlemetConfigurationOptions = settlementGroups ? (
    settlementGroups.map((configurationGroup) => (
      <option key={configurationGroup.id} value={configurationGroup.id}>
        {configurationGroup.name}
      </option>
    ))
  ) : (
    <></>
  );

  return (
    <Form.Group as={Col} md='6'>
      <Form.Label>{t("settlementConfigurationSelection")}</Form.Label>

      <Form.Select
        disabled={saving || isLoading}
        size='sm'
        value={selectedSettlementConfigurationGroup}
        onChange={(e) => handleSettlementConfigurationChange(e)}
      >
        <option key={NoSettlementConfigurationOption} value={NoSettlementConfigurationOption}>
          {t(NoSettlementConfigurationOption)}
        </option>
        <option key={UseDefaultSettlementConfigurationOption} value={UseDefaultSettlementConfigurationOption}>
          {t(UseDefaultSettlementConfigurationOption)}
        </option>
        {SettlemetConfigurationOptions}
      </Form.Select>

      <div>{t("descriptionSettlementConfigurationSelection")}</div>
    </Form.Group>
  );
};

export default SettlementConfigurationSelection;
