import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tCommon } from "../../i18n";
import { BasicTag } from "./BasicTag";
import "./EnabledTag.scss";

const EnabledTag = ({ enabled }: { enabled: boolean }): ReactElement => {
  const { t } = useTranslation(tCommon.ns);
  const text = t(enabled ? "commonTags.enabledTag" : "commonTags.disabledTag");
  const css = enabled ? "enabled" : "disabled";
  return (
    <div className='d-flex justify-content-center'>
      <BasicTag tagName={css} tagText={text} tagCssEnd='' />
    </div>
  );
};

export default EnabledTag;
