import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tCommon } from "../../i18n";
import "./FundingPage.scss";

const keyPrefix = "funding";

const getCapitaliseUrl = (): string => {
  const url = process.env.REACT_APP_CAPITALISE_URL || "";
  const introductorKey = process.env.REACT_APP_CAPITALISE_INTRODUCER_KEY || "";

  const params = new URLSearchParams();
  params.append("introducer", introductorKey);
  params.append("external-site", "true");

  return `${url}?${params.toString()}`;
};

/*
 * Shows the Capitalise funding wizard
 */

const FundingPage = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const url = getCapitaliseUrl();

  return (
    <div className='funding-container'>
      <div className='title'>{t("title")}</div>
      <iframe
        className='capitalise-frame'
        title={t("iframeTitle")}
        src={url}
        allow='encrypted-media'
        width='100%'
        height='100%'
      />
    </div>
  );
};

export default FundingPage;
