import { AxiosResponse } from "axios";
import { CompanyAccountInfo, CompanyPreferences, CompanySettings } from "../../../models/companySettings/companySettingsModels";
import BasePayThemApi from "./basePayThemApi";
import { PaymentProviderConfiguration } from "../../../models/paymentProviders/paymentProviderConfiguration";
import { PaymentRequestSettings } from "../../../models/companySettings/paymentRequestSettings";
import { CustomHostIdSettings, ExampleParseResult } from "../../../models/companySettings/customHostIdSettings";

const convertToFormData = (originalFilename: string, imageFile: File): FormData => {
  const formData = new FormData();
  formData.append("imageFile", imageFile);
  formData.append("originalFilename", originalFilename);

  return formData;
};

export class CompanySettingsApi extends BasePayThemApi {
  private endPoint = "company-settings";

  private logoEndPoint = `${this.endPoint}/logo`;

  private paymentProviderEndPoint = `${this.endPoint}/payment-provider`;

  private preferencesEndPoint = `${this.endPoint}/preferences`;

  private paymentRequestEndPoint = `${this.endPoint}/payment-request`;

  private accountInfoEndPoint = `${this.endPoint}/account-info`;

  private customHostIdSettingsEndPoint = `${this.endPoint}/custom-host-id`;

  /*
   * Returns the company account info
   */
  public async getCompanyAccountInfo(): Promise<CompanyAccountInfo> {
    const result = await this.get<CompanyAccountInfo>(this.accountInfoEndPoint);
    return result;
  }

  // Returns the company settings
  public async getCompanySettings(): Promise<CompanySettings> {
    const result = await this.get<CompanySettings>(this.endPoint);
    return result;
  }

  // Sets the company settings
  public async setCompanySettings(companySettings: CompanySettings): Promise<void> {
    const result = await this.put<CompanySettings>(this.endPoint, companySettings);
    return result;
  }

  /*
   * Deletes the company logo
   */
  public async deleteLogo(): Promise<void> {
    await this.deleteItem(this.endPoint, "logo");
  }

  /*
   * Uploads the image for the company logo
   */
  public async uploadLogo(file: File, filename: string): Promise<CompanySettings> {
    const inst = this.getInstance();
    const formData = convertToFormData(filename, file);
    const response = await inst.put<FormData, AxiosResponse<CompanySettings>>(this.logoEndPoint, formData).catch((error) => {
      this.processHttpError(error);
    });
    return response.data;
  }

  /*
   * Gets the default payment provider currently stored
   */
  public async getDefaultPaymentProvider(): Promise<PaymentProviderConfiguration | undefined> {
    const result = await this.getOrNotFound<PaymentProviderConfiguration>(this.paymentProviderEndPoint);
    return result;
  }

  /*
   * Sets the default payment provider to use
   */
  public async setDefaultPaymentProvider(paymentProviderId: string): Promise<void> {
    const model = { paymentProviderId };
    await this.put(this.paymentProviderEndPoint, model);
  }

  /*
   * Gets the company preferences
   */
  public async getPreferences(): Promise<CompanyPreferences> {
    const result = await this.get<CompanyPreferences>(this.preferencesEndPoint);
    return result;
  }

  /*
   * Updates the company preferences
   */
  public async updatePreferences(preferences: CompanyPreferences): Promise<void> {
    await this.put(this.preferencesEndPoint, preferences);
  }

  /*
   * Gets the payment request preferences
   */
  public async getPaymentRequestSettings(): Promise<PaymentRequestSettings> {
    const result = await this.get<PaymentRequestSettings>(this.paymentRequestEndPoint);
    return result;
  }

  /*
   * Updates the payment request preferences
   */
  public async updatePaymentRequestSettings(settings: PaymentRequestSettings): Promise<void> {
    const result = await this.put(this.paymentRequestEndPoint, settings);
    return result;
  }

  /*
   * Gets the custom host id settings
   */
  public async getCustomHostIdSettings(id: string): Promise<CustomHostIdSettings> {
    const result = await this.get<CustomHostIdSettings>(`${this.customHostIdSettingsEndPoint}/${id}`);
    return result;
  }

  /*
   * Updates the custom host id settings
   */
  public async updateCustomHostIdSettings(settings: CustomHostIdSettings): Promise<void> {
    await this.put<CustomHostIdSettings>(this.customHostIdSettingsEndPoint, settings);
  }

  /*
   * Tests the given parse value
   */
  public async testCustomHostIdValue(id: string, parseValue: string): Promise<ExampleParseResult> {
    const params = new URLSearchParams();
    params.append("parseValue", parseValue);
    const result = await this.get<ExampleParseResult>(`${this.customHostIdSettingsEndPoint}/${id}/example`, params);
    return result;
  }
}

export default CompanySettingsApi;
