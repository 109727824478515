import lang from "i18next";
import { CompanySettingsApi } from "../../common/api/payThemApi/companySettingsApi";
import { toastSuccess } from "../notifications/toastNotification.service";
import { validateImage } from "./imageFileValidatio.service";
import { CompanyAccountInfo, CompanyPreferences, CompanySettings } from "../../models/companySettings/companySettingsModels";
import { tCommon } from "../../i18n";
import { PaymentRequestSettings } from "../../models/companySettings/paymentRequestSettings";
import { CustomHostIdSettings, ExampleParseResult } from "../../models/companySettings/customHostIdSettings";
import { handleApiErrorWithToast } from "../../utils/toastErrorUtils";

const LangOptions = tCommon;

export const getCompanyAccountInfo = async (): Promise<CompanyAccountInfo | undefined> => {
  const api = new CompanySettingsApi();
  try {
    const result = await api.getCompanyAccountInfo();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.accountInfoGet");
  }
  return undefined;
};

export const getPreferences = async (): Promise<CompanyPreferences | undefined> => {
  const api = new CompanySettingsApi();
  try {
    const result = await api.getPreferences();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.preferencesGet");
  }
  return undefined;
};

/*
 * Updates the company preferences
 */
export const updatePreferences = async (preferences: CompanyPreferences, showSuccessMessage: boolean = true): Promise<void> => {
  const api = new CompanySettingsApi();
  try {
    await api.updatePreferences(preferences);
    if (showSuccessMessage) toastSuccess(lang.t("toastMessages.settings.preferencesUpdateSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.preferencesUpdate");
  }
};

export const updatePreferencesDefaultSettlementConfigurationGroup = async (defaultSettlementId?: string): Promise<void> => {
  const api = new CompanySettingsApi();
  try {
    const result = await api.getPreferences();
    result.defaultSettlementConfigurationGroupId = defaultSettlementId;
    api.updatePreferences(result);
    toastSuccess(lang.t("toastMessages.settings.settlementGroupUpdatedSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.settlementGroupUpdated");
  }
};

/*
 * Updates the default country value
 */
export const updatesPreferencesDefaultCountry = async (
  defaultCountryId: number,
  showSuccessMessage: boolean = true,
  throwOnError: boolean = false
): Promise<void> => {
  const api = new CompanySettingsApi();
  try {
    await api.getCompanySettings().then((result) => {
      // eslint-disable-next-line no-param-reassign
      result.preferences.defaultCountryId = defaultCountryId;
      api.setCompanySettings(result);
      if (showSuccessMessage) toastSuccess(lang.t("toastMessages.settings.preferencesUpdatedSuccess", LangOptions));
    });
  } catch (error) {
    handleApiErrorWithToast(error, throwOnError, "settings.preferencesUpdated");
    if (throwOnError) throw error;
  }
};

/*
 * Returns the company settings
 */
export const getCompanySettings = async (): Promise<CompanySettings | undefined> => {
  const api = new CompanySettingsApi();
  try {
    return await api.getCompanySettings();
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.companySettingsGet");
  }
  return undefined;
};

/*
 * Updates the company settings
 */
export const updateCompanySettings = async (companySettings: CompanySettings): Promise<void> => {
  const api = new CompanySettingsApi();
  try {
    await api.setCompanySettings(companySettings);
    toastSuccess(lang.t("toastMessages.settings.companySettingsUpdateSuccess", LangOptions));
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.companySettingsUpdate");
  }
};

/*
 * Returns the url to the logo, else undefined if no logo set
 */
export const getLogoUrl = async (): Promise<string | undefined> => {
  const settings = await getCompanySettings();
  return settings?.companyDetails?.logoUrl;
};

/*
 * Uploads a logo to the api
 */
export async function uploadLogo(file: File | null | undefined): Promise<string | undefined> {
  const api = new CompanySettingsApi();

  if (!file) {
    const result = await getLogoUrl();
    return result;
  }

  try {
    if (validateImage(file.name, file.size)) {
      const result = await api.uploadLogo(file, file.name);
      toastSuccess(lang.t("toastMessages.settings.logoUploadSuccess", LangOptions));
      return result.companyDetails.logoUrl;
    }
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.logoUpload");
  }
  const result = await getLogoUrl();
  return result;
}

/*
 * Deletes the logo
 */
export async function deleteLogo(): Promise<boolean> {
  const api = new CompanySettingsApi();
  try {
    await api.deleteLogo();
    return true;
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.logoDelete");
    return false;
  }
}

/*
 * Gets the payment request settings
 */
export const getPaymentRequestSettings = async (): Promise<PaymentRequestSettings | undefined> => {
  const api = new CompanySettingsApi();
  try {
    const result = await api.getPaymentRequestSettings();
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.paymentRequestGet");
    return undefined;
  }
};

/*
 * Updates the payment request settings
 */
export const updatePaymentRequestSettings = async (
  settings: PaymentRequestSettings,
  throwOnError: boolean = false
): Promise<void> => {
  const api = new CompanySettingsApi();
  try {
    await api.updatePaymentRequestSettings(settings);
  } catch (error) {
    handleApiErrorWithToast(error, throwOnError, "settings.paymentRequestUpdate");
    if (throwOnError) throw error;
  }
};

/*
 * Gets the custom host id settings
 */
export const getCustomHostIdSettings = async (id: string): Promise<CustomHostIdSettings | undefined> => {
  const api = new CompanySettingsApi();
  try {
    const result = await api.getCustomHostIdSettings(id);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.customHostIdGet");
    return undefined;
  }
};

/*
 * Updates the custom host id settings
 */
export const updateCustomHostIdSettings = async (
  settings: CustomHostIdSettings,
  throwOnError: boolean = false
): Promise<void> => {
  const api = new CompanySettingsApi();
  try {
    await api.updateCustomHostIdSettings(settings);
  } catch (error) {
    handleApiErrorWithToast(error, throwOnError, "settings.customHostIdUpdate");
    if (throwOnError) throw error;
  }
};

/*
 * Tests the given parse value
 */
export const testCustomHostIdSetting = async (id: string, parseValue: string): Promise<ExampleParseResult | undefined> => {
  const api = new CompanySettingsApi();
  try {
    const result = await api.testCustomHostIdValue(id, parseValue);
    return result;
  } catch (error) {
    handleApiErrorWithToast(error, false, "settings.customHostIdTest");
    return undefined;
  }
};
