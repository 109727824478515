/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { tPages } from "../../../i18n";
import {
  AllocatedStatus,
  AllocatedStatusMap,
  PaymentTransactionStatusArray,
  PaymentTransactionTypeArray,
} from "../../../models/paymentTransactions/paymentTransactionEnums";
import { QueryNameConsts } from "../../../common/queryNameConsts";
import { getAllConfigurations } from "../../../services/paymentProviders/paymentProviders.service";
import TableFiltersBox from "./TableFiltersBox";
import ExportDialogOptions from "./ExportDialogOptions";
import { StatusFilterName, TypeFilterName } from "./tableFiltersHelper";
import { ExportType } from "../../../models/paymentTransactions/exportOptions";
import { DialogPrimaryButton, DialogSecondaryButton } from "../../../components/dialogs/DialogButtons";
import { hasActiveFilters } from "../../../components/grid/dataGridFilterHelpers";
import { formatISODate } from "../../../utils/dateFormatter";
import "./ExportDialog.scss";
import ClearButton from "../../../components/buttons/ClearButton";

const keyPrefix = "paymentTransactionPage.exportDialog";

const AllProvidersOption = "allPaymentProvidersSelection";
const AllStatusesOption = "allStatusesSelection";
const AllTransactionTypesOption = "allTransactionTypesSelection";

const dateValidationTestName = "Date";
const dateValidationTestErrorMessage = "From Date must be less than To Date";

const validateDates = (fromDate?: Date | null, toDate?: Date | null): boolean => {
  if (!fromDate || !toDate) {
    return true;
  }
  return fromDate <= toDate;
};

const validationSchema = Yup.object({
  fromDate: Yup.date()
    .label("From Date")
    .nullable()
    .transform((current, original) => (original === "" ? null : current))
    .notRequired(),
  toDate: Yup.date()
    .label("To Date")
    .nullable()
    .transform((current, original) => (original === "" ? null : current))
    .notRequired()
    .test(dateValidationTestName, dateValidationTestErrorMessage, (toDate, items) => {
      const { fromDate }: { fromDate: Date } = items.parent;
      return validateDates(fromDate, toDate);
    }),
  paymentProvider: Yup.string().label("Payment Provider Configuration").nullable().notRequired(),
}).required();

interface ExportDialogProps {
  show: boolean;
  defaultOptions?: ExportDialogOptions;
  tableFilters?: TypeFilterValue;
  onHide: () => void;
  onExport: (options: ExportDialogOptions) => void;
}

/*
 * Allows setting of options for when exporting payment transactions
 */
const ExportDialog = ({ show, defaultOptions, tableFilters, onHide, onExport }: ExportDialogProps): ReactElement => {
  const { t } = useTranslation(tPages.ns, { keyPrefix });
  const [enableComponent, setEnableComponent] = useState<boolean>(false);

  const { data: paymentProviderConfigurations } = useQuery(
    [QueryNameConsts.AllPaymentProvidersList, show],
    () => getAllConfigurations(),
    { enabled: show }
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ExportDialogOptions>({ resolver: yupResolver(validationSchema) });

  const includeTableFiltersWatch = watch("includeTableFilters", defaultOptions?.includeTableFilters);
  const hasStatusTableFilter = tableFilters?.some((x) => x.name === StatusFilterName && x.value);
  const hasTransactionTypeTableFilter = tableFilters?.some((x) => x.name === TypeFilterName && x.value);

  useEffect(() => {
    if (includeTableFiltersWatch === true) {
      setValue("status", tableFilters?.find((x) => x.name === StatusFilterName)?.value);
      setValue("transactionType", tableFilters?.find((x) => x.name === TypeFilterName)?.value);
    }
  }, [includeTableFiltersWatch, setValue, tableFilters]);

  // We do this so that the modal is created and show
  useEffect(() => {
    if (show) setEnableComponent(true);
  }, [show]);

  // We do this to destory the modal component, which resets all the dialog settings
  const handleExited = () => {
    setEnableComponent(false);
    reset();
  };

  // Saves the dialog settings
  const handleSave = (model: ExportDialogOptions) => {
    const updatedModel = {
      ...model,
      status: (model.status as String) ? model.status : undefined,
      transactionType: (model.transactionType as String) ? model.transactionType : undefined,
    };
    if (model.fromDate) {
      const date = model.fromDate;
      updatedModel.fromDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    if (model.toDate) {
      const date = model.toDate;
      updatedModel.toDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    if (model.paymentProvider === AllProvidersOption) {
      updatedModel.paymentProvider = undefined;
    }
    onExport(updatedModel);
  };

  if (!enableComponent) return <></>;

  const fromDate = formatISODate(defaultOptions?.fromDate, "date");
  const toDate = formatISODate(defaultOptions?.toDate, "date");

  return (
    <Modal show={show} onHide={onHide} onExited={handleExited}>
      <Modal.Header closeButton>
        <Modal.Title>{t("title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id='exportPaymentTransactionsForm'
          className='dialog-form'
          noValidate
          onSubmit={handleSubmit((submitData) => handleSave(submitData))}
        >
          <Row className='dialog-row'>
            <Col md='12'>
              <Form.Group>
                <Form.Label>{t("labelExportType")}</Form.Label>
                <Form.Select {...register("exportType")}>
                  <>
                    <option key={ExportType.PaymentsAndAllocations} value={ExportType.PaymentsAndAllocations}>
                      {t("optionPaymentsAndAllocations")}
                    </option>
                    <option key={ExportType.Allocations} value={ExportType.Allocations}>
                      {t("optionAllocations")}
                    </option>
                  </>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelFromDate")}</Form.Label>
                <Form.Control
                  type='date'
                  placeholder={t("placeholderFromDate")}
                  {...register("fromDate")}
                  defaultValue={fromDate}
                  className={`mb-0 form-control ${errors.fromDate ? "is-invalid" : ""}`}
                />
                <ClearButton onClick={() => setValue("fromDate", undefined)} />
                <Form.Control.Feedback type='invalid'>{errors?.fromDate?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelToDate")}</Form.Label>
                <Form.Control
                  type='date'
                  placeholder={t("placeholderToDate")}
                  {...register("toDate")}
                  defaultValue={toDate}
                  className={`mb-0 form-control ${errors.toDate ? "is-invalid" : ""}`}
                />
                <ClearButton onClick={() => setValue("toDate", undefined)} />
                <Form.Control.Feedback type='invalid'>{errors?.toDate?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='12'>
              <Form.Group>
                <Form.Label>{t("labelPaymentProvider")}</Form.Label>
                <Form.Select {...register("paymentProvider")}>
                  <option key={AllProvidersOption} value={AllProvidersOption}>
                    {t(AllProvidersOption)}
                  </option>
                  {paymentProviderConfigurations &&
                    paymentProviderConfigurations.map((configuration) => (
                      <option key={configuration.id} value={configuration.id}>
                        {`${configuration.name} (${configuration.providerName})`}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelTransactionType")}</Form.Label>
                <Form.Select
                  {...register("transactionType")}
                  disabled={includeTableFiltersWatch && hasTransactionTypeTableFilter}
                >
                  <option key={AllTransactionTypesOption} value=''>
                    {t(AllTransactionTypesOption)}
                  </option>
                  {PaymentTransactionTypeArray.map((status) => (
                    <option key={status[0]} value={status[0]}>
                      {status[1]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelStatus")}</Form.Label>
                <Form.Select {...register("status")} disabled={includeTableFiltersWatch && hasStatusTableFilter}>
                  <option key={AllStatusesOption} value=''>
                    {t(AllStatusesOption)}
                  </option>
                  {PaymentTransactionStatusArray.map((status) => (
                    <option key={status[0]} value={status[0]}>
                      {status[1]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className={`dialog-row ${!hasActiveFilters(tableFilters) ? "last" : ""}`}>
            <Col>
              <Form.Group>
                <Form.Label>{t("labelSelectAllocatedStatus")}</Form.Label>
                <Row className='body-small mt-2'>
                  <Col sm={6}>
                    <Form.Check
                      inline
                      type='checkbox'
                      id={AllocatedStatus.WaitingToBeAllocated}
                      label={AllocatedStatusMap.get(AllocatedStatus.WaitingToBeAllocated)}
                      {...register("allocatedStatuses.waitingToBeAllocated")}
                      defaultChecked={defaultOptions?.allocatedStatuses.waitingToBeAllocated}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      inline
                      type='checkbox'
                      id={AllocatedStatus.Error}
                      label={AllocatedStatusMap.get(AllocatedStatus.Error)}
                      {...register("allocatedStatuses.error")}
                      defaultChecked={defaultOptions?.allocatedStatuses.error}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      inline
                      type='checkbox'
                      id={AllocatedStatus.Unallocated}
                      label={AllocatedStatusMap.get(AllocatedStatus.Unallocated)}
                      {...register("allocatedStatuses.unallocated")}
                      defaultChecked={defaultOptions?.allocatedStatuses.unallocated}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      inline
                      type='checkbox'
                      id={AllocatedStatus.AutoAllocated}
                      label={AllocatedStatusMap.get(AllocatedStatus.AutoAllocated)}
                      {...register("allocatedStatuses.autoAllocated")}
                      defaultChecked={defaultOptions?.allocatedStatuses.autoAllocated}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      inline
                      type='checkbox'
                      id={AllocatedStatus.ManuallyAllocated}
                      label={AllocatedStatusMap.get(AllocatedStatus.ManuallyAllocated)}
                      {...register("allocatedStatuses.manuallyAllocated")}
                      defaultChecked={defaultOptions?.allocatedStatuses.manuallyAllocated}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      inline
                      type='checkbox'
                      id={AllocatedStatus.NotApplicable}
                      label={AllocatedStatusMap.get(AllocatedStatus.NotApplicable)}
                      {...register("allocatedStatuses.notApplicable")}
                      defaultChecked={defaultOptions?.allocatedStatuses.notApplicable}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          {hasActiveFilters(tableFilters) && (
            <Row className='dialog-row last'>
              <Col>
                <Form.Group className='table-filters'>
                  <Form.Check
                    label={t("labelIncludeTableFilters")}
                    {...register("includeTableFilters")}
                    type='checkbox'
                    defaultChecked={defaultOptions?.includeTableFilters}
                  />
                  <TableFiltersBox tableFilters={tableFilters} filtersSelected={!!includeTableFiltersWatch} />
                </Form.Group>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <DialogSecondaryButton onClick={onHide} />
        <DialogPrimaryButton form='exportPaymentTransactionsForm' type='submit' label={t("buttonExport")} />
      </Modal.Footer>
    </Modal>
  );
};

ExportDialog.defaultProps = {
  defaultOptions: undefined,
  tableFilters: undefined,
};

export default ExportDialog;
